import {toast} from "react-toastify";
import {toastConfig} from "../config/toast_config";

function uploadImportFile(that, fileRef, file, callback) {
    that.props.firebase.storage.ref(fileRef).put(file).then(function () {
        toast.success(`Uploaded file for processing: ${file.name}`, {...toastConfig, autoClose: 2000});
        if(callback) {
            callback(file)
        }

    });
}
const sortBy = [
    {
        prop:'last_active',
        direction: 1
    },{
        prop:'total',
        direction: -1
    }, {
        prop: 'code',
        direction: 1,
        string: true
    }];
const  sort_bays = (a,b) => {
    let i = 0, result = 0;
    while(i < sortBy.length && result === 0) {
        let a_val = a[ sortBy[i].prop ];
        let b_val = b[ sortBy[i].prop ];
        if(sortBy[i].string) {
            result = sortBy[i].direction*(a_val < b_val ? -1 : (a_val > b_val ? 1 : 0));
        } else {
            a_val = parseInt(a_val);
            b_val = parseInt(b_val);
            result = sortBy[i].direction*(a_val - b_val);
        }
        i++;
    }
    return result;
};

export {uploadImportFile, sort_bays};