import React from 'react';
// noinspection ES6CheckImport
import ReactDOM from 'react-dom';
import {IntlProvider} from "react-intl";
import './bootstrap.custom.scss';
import Firebase, {FirebaseContext} from './components/Firebase';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import messages_de from "./translations/de.json";
import * as serviceWorker from "./serviceWorker";
import messages_en from "./translations/en.json";
import './helpers/init_font_awesome';
import {ToastContainer} from "react-toastify";

const messages = {
    'de': messages_de,
    'en': messages_en
};
const language = navigator.language.split(/[-_]/)[0];  // language without region code

ReactDOM.render(
    (<IntlProvider locale={language} messages={messages[language]}>
        <ToastContainer/>
        <FirebaseContext.Provider value={new Firebase()}>
        <App />
    </FirebaseContext.Provider></IntlProvider>), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

