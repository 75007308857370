import React from "react";
import * as PropTypes from 'prop-types';
import {Button, ButtonGroup, Modal} from "react-bootstrap";
import storage_refs from '../../../constants/storage_refs';
import DATABASE_REFS from '../../../constants/db_refs';

import {RowCell} from "../../../elements/Common";
import SearchableTable from "../../../elements/SearchableTable";
import {STATUS_LABELS} from "../../../components/ContainerMaster/withOutturns";
import {
    ContainerMasterOutturnsContext
} from "../../../components/ContainerMaster/ContainerMaster";
import {AdvancedFilter, AdvancedFilterPanel, AdvancedRadioFilter} from "../../../elements/AdvancedFiltering";
import {toast} from "react-toastify";
import {toastConfig} from "../../../config/toast_config";

const CollectionRow = ({collection, headers, printing}) => <tr className={'col-12 w-100 m-1 product item-row mb-1 print-block'}>
    {headers.map((h, i) =>  <RowCell  key={`table_row_${collection.id}_${h.key}`}  col={3} title={h.label} alignment={'center'}
                                      header={!printing && i===0} noprint={h.noprint}>{h.content(collection)}</RowCell>)}
</tr>;


class Table extends SearchableTable {
    has_export = 'Export Outturns';
    key = 'outturns';
    // noinspection JSUnusedGlobalSymbols
    CMContext = ContainerMasterOutturnsContext;

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState,
            page: 0,
            num_per_page: 10,
            noDetails: true,
        };
        this.fileInput = [];
    }

    getReportTitle() {
        return 'Outturns';
    }

    attachPOD(collection_id) {
        // Open file dialog
        console.log("heheh")
        this.fileInput[collection_id].click()

    }

    uploadPOD(event, {id, container_number, customer}) {
        const files = event.currentTarget.files;
        if (files.length === 0) {
            toast.warn("No file selected", toastConfig)
        }
        const file = files[0];
        const {firebase, organisation_id} = this.props;
        const fileRef = storage_refs.outturn_pod(organisation_id, customer, id, container_number)
        firebase.storage.ref(fileRef).put(file).then(function () {
            const outturn = DATABASE_REFS.organisations.stock_movement.stock_in(organisation_id);
            firebase.db.ref(outturn).child(id).child("pod").set(true)
            toast.success(`Uploaded POD file: ${file.name}`, {...toastConfig, autoClose: 2000});


        });
        // Save file to storage
        // Save returned file url against collection
    }

    viewPOD({id, container_number, customer}) {

        const {firebase, organisation_id} = this.props;
        const fileRef = storage_refs.outturn_pod(organisation_id, customer, id, container_number)
        firebase.storage.ref(fileRef).getDownloadURL().then(url=>{
            this.setState({
                pod: url
            })
        })
        // Save file to storage
        // Save returned file url against collection
    }

    setFilter(filter, callback) {
        const {status_filters} = this.state;
        this.props.setFilter(filter.target.value, status_filters, [], callback)
    }
    getAdditionalHeaders() {
        return [ {
            label: '',
            key: false,
            size: 3,
            noprint: true,
            content: (product) => {
                const p = product;
                return <div>
                    {this.state.pod && this.state.pod.indexOf(product.id) > -1 && <Modal size={"lg"} show={true} onHide={()=>this.setState({pod: null})}>
                        <Modal.Header>
                            <h3>POD for {product.container_number}</h3>
                        </Modal.Header>
                        <Modal.Body className={'text-center'}>
                            <iframe width={"90%"} height={800} src={this.state.pod}/>
                        </Modal.Body>
                    </Modal> }
                    <input data-item={product} accept={'application/pdf'} ref={(ref)=>this.fileInput[product.id] = ref} type={"file"} onChange={(event)=>{
                        console.log(p)
                        this.uploadPOD(event, p)
                    }} style={{
                        visibility: "hidden",
                        height: 0,
                        width: 0
                    }}/>
                    <ButtonGroup>
                    <Button size={'sm'} onClick={()=>{
                        const {
                            organisation_id
                        } = this.props;
                        this.props.firebase.functions.httpsCallable('summaries-outturns-getOutturnSummary')({
                            organisation_id, outturn_id: product.id
                        }).then(()=>{
                        })
                    }}>Rebuild</Button>
                    {product.status === 'Completed' && <Button className={'m-auto'} size={'sm'} variant={'success'}  onClick={() => {this.props.editOutturn(product.id)}}>View</Button>}
                    {product.status !== 'Completed' && !(product.status === 'Cancelled') && <Button
                        className={'m-auto'}
                        onClick={() => {this.props.editOutturn(product.id)}}
                        key={`edit_button_${product.parent}_${product.id}`}
                        title={'Edit?'}
                        data-item={product} variant={product.cls} size={'sm'}>{product.button_label}</Button>
                    }
                    {product.status !== 'Completed' && product.picking_list_id && <Button
                        className={'m-auto'}
                        onClick={() => {this.props.editBulk(product.picking_list_id)}}
                        key={`view_bulk_button_${product.parent}_${product.id}`}
                        title={'Edit Bulk List?'}
                        data-item={product} variant={'info'} size={'sm'}>View Picking List</Button>
                    }


                        <Button
                            className={'m-auto'}
                            onClick={() => {this.attachPOD(product.id)}}
                            key={`attach_button_${product.parent}_${product.id}`}
                            title={'Attach POD?'}
                            disabled={true}
                            data-item={product} variant={'info'} size={'sm'}>

                            {!product.pod  && "Attach POD"}
                            {product.pod  && "Change POD"}
                        </Button>

                        {product.pod  && <Button
                            className={'m-auto'}
                            onClick={() => {this.viewPOD(product)}}
                            key={`view_button_${product.parent}_${product.id}`}
                            title={'View POD?'}
                            data-item={product} variant={'info'} size={'sm'}>

                            View POD</Button>
                        }


                </ButtonGroup>
                </div>
            }
        }]
    }

    getAdvancedFilters({updateStatusLabels, status_filters, selectAll, selectNone, notificationStates, changeNotificationFilters, currentNotificationState}) {
        return <AdvancedFilterPanel><AdvancedFilter
            legend_text={'Outturn Status'}
            onClick={updateStatusLabels}
            selectAll={selectAll}
            deselectAll={selectNone}
            original_list={STATUS_LABELS.filter((sl, i) => i > 0)}
            current_list={status_filters}
        />
            <AdvancedRadioFilter
                legend_text={"Notification Status"}
                list={notificationStates}
                onClick={changeNotificationFilters}
                selection={currentNotificationState}
            /></AdvancedFilterPanel>
    }


    getContent(data, headers) {
        return data.map(
            (collection) =><CollectionRow
                headers={headers}
                printing={this.state.print_processing}
                key={collection.id}
                collection={collection}
            />)
    }
}

Table.propTypes = {
    firebase: PropTypes.object.isRequired,
    organisation_id: PropTypes.string.isRequired,
    editCollection: PropTypes.func
};
export {CollectionRow};
export default Table;