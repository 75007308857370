import React from "react";
import * as PropTypes from 'prop-types';

import db_refs from '../../../constants/db_refs';

import InlineInput from "../../../elements/InlineInput";
import Button from "../../../elements/Button";
import Error from "../../../elements/Error";
import {toast} from "react-toastify";
import {toastConfig} from "../../../config/toast_config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const INITIAL_STATE = () => {
    return {
        code: '',
        name: '',
        error: false,
        showForm: false
    }
};

class CustomerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE();
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.errors = React.createRef();
        this.toggleForm = this.toggleForm.bind(this);
    }

    toggleForm() {
        this.setState({showForm: !this.state.showForm});
    }

    handleChange(event) {
        let newState = {};
        newState[event.target.name] = event.target.value;
        this.setState(newState)
    }

    onSubmit() {
        if(this.isInvalid()) {
            this.errors.current.setError({message: "You must complete the fields!"});
            return
        }
        if(this.props.customerKeys.indexOf(this.state.code) > -1) {
            this.errors.current.setError({message: "A customer with this code already exists!"});
            return
        }
        if(this.props.customerNames.indexOf(this.state.name) > -1) {
            this.errors.current.setError({message: "A customer with this name already exists!"});
            return
        }
         this.props.firebase.db.ref(db_refs.organisations.customers(this.props.organisation_id)).push({
            code: this.state.code,
            name: this.state.name
        }).then((s) => {
            toast.success(`Created customer ${this.state.name}!`, {...toastConfig, autoClose: 2000});
             this.props.firebase.db.ref(db_refs.organisations.tracking.totals(this.props.organisation_id)).child("customers").child(s.key).child("totals").set({
                current: 0,
                pending: 0,
                stocking_in: 0,
                stocking_out: 0,
                promised: 0
            });
            this.setState(INITIAL_STATE());
            this.errors.current.setError(false);
        }).catch((err) => {
            this.errors.current.setError(err);
        })
    }

    isInvalid() {
        return !(this.state.code.length > 0 && this.state.name.length > 0)
    }

    render() {
        const {showForm, error, code, name} = this.state;
        return (
            <div className={'row'}>
                <div className={'col-xs-12 col-sm-12 bg-tertiary p-0'}>
                    <div onClick={this.toggleForm} className={'col-12 text-right pt-3 pr-3 text-light'}>
                        <FontAwesomeIcon icon={showForm ? 'minus-circle' : 'plus-circle'} size={'1x'}/>
                    </div>
                    {showForm &&
                    <div className={'row m-0 p-2 pb-0 text-center'}>
                      <div className={'col-12'}>
                          <Error ref={this.errors} error={error}/>
                      </div>
                    <div className={'col-xs-12 col-lg-5'}>
                        <InlineInput
                            value={code}
                            required={true}
                            label_id={"app.customer_code"}
                            handleChange={this.handleChange}
                            name={'code'}
                            prefix={'customer_form'}
                        />
                    </div>
                    <div className={'col-xs-12 col-lg-5'}>
                        <InlineInput
                            value={name}
                            required={true}
                            label_id={"app.customer_name"}
                            handleChange={this.handleChange}
                            name={'name'}
                            prefix={'customer_form'}
                        />
                    </div>
                    <div className={'col-xs-12 col-lg-2'}>
                        <Button
                            testid={'add_customer'}
                            onClick={this.onSubmit}
                            disabled={this.isInvalid()}
                            title_id={'app.add_customer'}
                            className={'btn btn-primary text-light'}
                            icon={<FontAwesomeIcon icon={'plus-circle'}/>}
                        />
                    </div>
                    </div>}
                </div>
            </div>)
    }
}

CustomerForm.propTypes = {
  organisation_id: PropTypes.string.isRequired,
  firebase: PropTypes.object.isRequired,
  customerKeys: PropTypes.array.isRequired,
  customerNames: PropTypes.array.isRequired
};

export default CustomerForm;