const productObjectFromArray = (products, additional_items) => {
    const formatted_products = {};

    if (products) {
        products.map((product) => {
            if (!formatted_products[product.code]) {
                formatted_products[product.code] = {
                    description: product.description,
                    crates: [product.crate]
                };
            } else {
                formatted_products[product.code].crates.push(product.crate)
            }
            return true;
        });
    }
    const missing = Object.keys(additional_items || {}).filter(ai=>Object.keys(formatted_products).indexOf(ai)<0);
    missing.map(m=>formatted_products[m] = additional_items[m]);
    return formatted_products;
};
const productArrayFromObject = (products) => {
    const final_products = [];
    Object.values(products).map((product) => {
        product.crates.map((crate) => {
            final_products.push({
                code: product.code,
                description: product.description,
                crate: crate
            });
            return true;
        });
        return true;
    });
    return final_products
};
export {productObjectFromArray, productArrayFromObject}