import {
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faArrowUp,
    faBan,
    faInfoCircle,
    faBox,
    faBoxes,
    faCamera,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCheck,
    faClock,
    faCog,
    faCopy,
    faEdit,
    faPlayCircle,
    faEllipsisV,
    faExclamation,
    faExclamationTriangle,
    faFastBackward,
    faFastForward,
    faFile,
    faLevelDownAlt,
    faMinus,
    faMinusCircle,
    faPlusCircle,
    faQuestion,
    faSearch,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faTimes,
    faTimesCircle,
    faTrash,
    faUndo,
    faUserPlus,
    faList,
    faShip,
    faTruck,
    faCircle, faDownload, faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import {faCheckCircle, faSquare} from "@fortawesome/free-regular-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import _ from "lodash";

library.add(
    faList,
    faInfoCircle,
    faExclamation,
    faCopy,
    faQuestion,
    faArrowUp,
    faTimesCircle,
    faCaretDown,
    faCaretUp,
    faCheckCircle,
    faExclamationTriangle,
    faPlusCircle,
    faMinusCircle,
    faBox,
    faBoxes,
    faEllipsisV,
    faUndo,
    faMinus,
    faCaretLeft,
    faDownload,
    faShip, faTruck, faCircle,
    faSquare, faCamera, faSpinner, faSort, faSortUp, faSortDown, faFastBackward, faFastForward, faCaretRight, faEdit,
    faTrash, faBan, faUserPlus, faTimes, faCheck, faArrowAltCircleUp, faArrowAltCircleDown, faCog, faSearch, faClock, faFile, faLevelDownAlt, faPlayCircle, faEnvelope);

Object.filter = (obj_base, predicate, options) => {
    const obj = _.cloneDeep(obj_base);
    const valid = Object.keys(obj || {})
        .filter( key => {
            return predicate(obj[key], options, key)
        } );
    if(valid.length === 0) {
        return {}
    }
    return Object.assign(...valid
        .map( key => {
            return{ [key]: obj[key] }
        }) );
};

Object.hasMatching = (obj_base, predicate, options) => {
    const obj = _.cloneDeep(obj_base);
    const valid = Object.keys(obj)
        .filter( key => {
            return predicate(obj[key], options, key)
        } );
    return valid.length !== 0;

};

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};
