import React from 'react';
import * as PropTypes from 'prop-types';

class ToggleButton extends React.Component {
    render() {
        return (
            <div className={`toggle-outer${ this.props.on ? "" : " off"}`} onClick={this.props.toggle}>
            <div className={`toggle-inner`}>
                <div className={'toggle'}>&nbsp;</div>
            </div>
            </div>

        );
    }
}

ToggleButton.propTypes = {
    toggle: PropTypes.func,
    on: PropTypes.bool
};

export default ToggleButton;