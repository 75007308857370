import React from "react";
import * as PropTypes from "prop-types";

/**
 * Base class for content pages
 */
class DashboardContent extends React.Component {
    constructor(props) {
        super(props);
        this.goToPageWithEntity = this.goToPageWithEntity.bind(this);
        this.setFilter = this.setFilter.bind(this);
    }
    goToPageWithEntity(page, entity) {
        this.props.updatePageWithEntity(page, entity)
    }
    setFilter(event) {
        this.setState({filter:event.target.value})
    }
}


DashboardContent.propTypes = {
    updatePage: PropTypes.func.isRequired,
    updatePageWithEntity: PropTypes.func.isRequired,
    user: PropTypes.string.isRequired,
    organisation: PropTypes.object,
    organisations: PropTypes.object,
    organisation_id: PropTypes.string,
    entity: PropTypes.string
};

export default DashboardContent;