import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    ButtonGroup,
    FormControl,
} from "react-bootstrap"
import React, {useState} from "react"
import RangeSlider from 'react-bootstrap-range-slider';
import ModalHeader from "react-bootstrap/ModalHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {toast} from "react-toastify";

const ManualMovement = ({show, bay_id, bays, cancelMovementItem, products, firebase, organisation_id}) =>
{
    const [isBays, setIsBays] = useState(false);
    const [isIn, setIsIn] = useState(true);
    const [quantity, setQuantity] = useState(0);
    const [proceeding, setProceeding] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [custom, setCustom] = useState('');
    const [target_bay, setTargetBay] = useState(false);

    const bay = bays[bay_id];
    const [product, setProduct] = useState(bay.product || false);
    const doMovement = () => {
      setProcessing(true);
      const source = !isIn ? bay.id : isBays ? target_bay : custom;
      const destination = isIn ? bay.id : isBays ? target_bay : custom;
      const actual_product = Object.values(products).find(p=>p.code===(product));
      firebase.functions.httpsCallable('makeStockMovement')({
          organisation_id,
          isBays,
          isIn,
          source,
          destination,
          quantity,
          product: actual_product
      }).then(()=>{

              setProcessing(false);
              toast.success(`Moved ${quantity} of ${actual_product.code} from ${isIn ? isBays ? bays[source].code : custom : bay.code } to ${!isIn ? isBays ? bays[destination].code : custom : bay.code}` )


      }).catch(e=>toast.error(e.message))
    };
    let valid_bays = [];
    if(isIn) {
        valid_bays = Object.values(bays).filter(b=>{
            return (b.product===bay.product || !bay.product) && b.id!==bay.id
        })
    } else {
        valid_bays = Object.values(bays).filter(b=>{
            return (b.product===bay.product || !b.product) && b.id!==bay.id
        })
    }
    let currentMax = isIn && target_bay ? parseInt(bays[target_bay].current) : parseInt(bay.current);
    if(!isBays && isIn) {
        currentMax = 50000;
    }
    return <Modal show={show} size={'lg'} onHide={()=>cancelMovementItem()}>
        <ModalHeader className={'bg-primary text-light'}><h3 className={'bg-primary text-light'}>
            Manual Movement
        </h3></ModalHeader>
        {!processing && <ModalBody>
            <div className={'w-100 text-center'}>
                <ButtonGroup>
                    <Button onClick={()=>{
                        setIsIn(true);
                        setQuantity(0);
                        setTargetBay(false);
                        setProceeding(false);
                        setProduct( false)
                    }} variant={isIn ? 'primary' : 'secondary'} >In<FontAwesomeIcon icon={'level-down-alt'}/></Button>
                    <Button onClick={()=>{
                        setIsIn(false);
                        setQuantity(0);
                        setTargetBay(false);
                        setProceeding(false);
                        setProduct(bay.product || false)
                    }} variant={!isIn ? 'primary' : 'secondary'} disabled={parseInt(bay.current) < 1}>Out <FontAwesomeIcon icon={'level-down-alt'} className={'out-arrow'}/></Button>
                </ButtonGroup>
            </div>
            <div className={'row'}>
                <div className={'col-8 offset-2 bg-primary text-light rounded mt-2 p-2'}>
                    <FontAwesomeIcon icon={'info-circle'} className={'mr-1'} size={'2x'}/>
                   <h3  className={'text-light'} style={{display: 'inline-block'}}>{isIn ? `Moving stock into bay ${bay.code}` : `Moving stock out of bay ${bay.code}`}
                    <br />
                       { `Choose a ${isIn ? 'source' : 'destination'}`}</h3>
                </div>
                <div className={"col-12 mr-0"}>
                    {//from/to
                    }
                    <ButtonGroup className={'mt-5 mb-4'}>
                        <Button variant={!isBays ? 'primary' : 'secondary'} onClick={()=>{
                            setIsBays(false);
                            setQuantity(0);
                            setTargetBay(false);
                            setProceeding(false);
                        }}>Custom</Button>
                        <Button variant={isBays ? 'primary' : 'secondary'} onClick={()=>{
                            setIsBays(true);
                            setQuantity(0);
                            setTargetBay(false);
                            setProceeding(false);
                        }}>Bay</Button>
                    </ButtonGroup>
                    {!isBays && <FormControl value={custom} placeholder={'Enter the reference for this movement'} onChange={(event)=>{
                        setCustom(event.target.value);
                    }}/>}
                    {!!isBays && <FormControl as={'select'} value={target_bay} onChange={(event)=>{
                        setTargetBay(event.target.value);
                        if(isIn && isBays) {
                            setProduct(bays[event.target.value].product)
                        }
                    }}>

                        {valid_bays.map(vb=><option key={`bay_option_${vb.id}`} value={vb.id}>{vb.code}</option>)}
                        <option value={false}>-</option>
                    </FormControl>}

                    {(target_bay || custom) && <div className={'col-8 offset-2 bg-primary text-light rounded mt-2 p-2'}>
                        {!isBays && isIn && custom && !product && <h3 className={'text-light'}>Choose Product</h3>}
                        {!isBays && isIn && custom && !product && <Row>
                            <Col>
                                <FormControl as={'select'} value={target_bay} onChange={(event)=>{
                                    setProduct(event.target.value);
                                }}>
                                    {Object.values(products).map(vb=><option key={`bay_option_${vb.id}`} value={vb.code}>{vb.code}: {vb.description}</option>)}
                                    <option value={false}>-</option>
                                </FormControl>
                            </Col>
                        </Row>}
                        {!isBays && isIn && custom && product && <Row>
                            <Col><h5 className={'text-light'}>Stocking in {product}</h5></Col></Row>}
                        <h3 className={'text-light'}>Choose Quantity</h3>
                        {(target_bay || !isIn) && <h5 className={'text-light'}>{currentMax} available to move {isIn ? 'in' : 'out'}</h5>}
                        {!(target_bay || !isIn) && <h5 className={'text-light'}>How many are you stocking in?</h5>}
                        <Row>
                            {(target_bay || !isIn) && <Col xs="12">
                            <RangeSlider
                                className={'w-100'}
                                tooltipLabel={()=>''}
                                max={currentMax}
                                value={quantity}
                                onChange={e => setQuantity(Number(e.target.value))}
                            />
                        </Col>}
                        <Col xs="6" className={'offset-3'}>
                            <FormControl type={'number'} onChange={(event)=>{
                                if(event.target.value )
                                setQuantity(event.target.value > currentMax ? currentMax : Number(event.target.value) )
                            }} size={'lg'} value={quantity}/>
                        </Col>
                        </Row>

                        {quantity > 0 && (target_bay || custom) && <Row className={'mt-2'}>
                            <Col className={proceeding && (!custom || (custom && product) || !isIn) ? 'text-right' : 'text-left'}>
                                {!proceeding && <ButtonGroup size={'sm'} className={'proceedButtons'}>
                                    <Button variant={'success'} onClick={()=>setProceeding(true)}>Proceed</Button>
                                    <Button variant={'danger'} onClick={()=>{
                                        setCustom('');
                                        setQuantity(0);
                                        setTargetBay(false);
                                        setProceeding(false);
                                    }}>Clear</Button>
                                </ButtonGroup>}
                                {proceeding && <h4 className={'text-light'}>Moving {quantity} of {product}</h4>}
                                {proceeding && <ButtonGroup size={'sm'} className={'moveButtons'}>
                                    <Button variant={'success'} onClick={()=>doMovement()}>Move stock</Button>
                                    <Button onClick={()=>setProceeding(false)} variant={'danger'}>Cancel</Button>
                                </ButtonGroup>}
                            </Col>
                        </Row>}
                    </div> }
                </div>
            </div>
        </ModalBody>}
        {processing && <ModalBody>
                <h3>Processing Movement</h3>
            <p> {proceeding && <h4 className={'text-light'}>Moving {quantity} of {bay.product}</h4>}</p>
            <LoadingScreen/>
        </ModalBody>}
        <ModalFooter>
            <Button variant={'secondary'} onClick={cancelMovementItem}>
                Close
            </Button>
        </ModalFooter>
    </Modal>
};

export default ManualMovement;