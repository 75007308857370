import React from 'react';
import {injectIntl} from "react-intl";

import splash from './img/CM3-Splash-Photo-Light.jpg';
import Image from "../../elements/Image";
import SignInForm from "../../components/SignInForm/SignInForm";

class Landing extends React.Component {
    render() {

        return (
            <div data-testid={'landing'} className={'row row-eq-height'}>
                <SignInForm/>
                <div className={'col-12 col-lg-8'}>
                    <h1>Container Master</h1>
                    <Image className={"img img-fluid"} alt_id={"app.name"} src={splash}/>
                    <p>
                        Welcome to ContainerMaster the powerful, simple to use, fully functional automated warehousing control application.
                    </p>
                    <p>

                        To register please contact <a href={"mailto:info@containermaster.co.uk"}>info@containermaster.co.uk</a>
                    </p>

                </div>

            </div>
        );
    }
}

Landing.propTypes = {

};

export default injectIntl(Landing);