import React from 'react';

import {withFirebase} from '../Firebase';
import {DATABASE_REFS} from '../../constants/db_refs'

import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {ContainerMasterForecastContext} from "./ContainerMaster";
import moment from "moment";

import _ from "lodash";
import {Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Requests
const ForecastingHeaders = [
    {
        label: 'Date',
        key: 'eventDate',
        filterValue: (product) => {
                return new Date(product.eventDate).toLocaleString()
        },
        filter: true,
        content: (product) => {
            return new Date(product.eventDate).toLocaleString()
        }
    },
    {
        label: 'Customer',
        key: 'customer_name',
        filter: true,
        content: (product) => {
            return product.customer_name
        }
    }, {
        label: 'Product',
        key: 'product_code',
        size: 1,
        filter: true,
        content: (product) => {
            return Object.values(product.products || {}).map(p=>p.code).join(', ')
        }
    }, {
        label: 'Description',
        key: 'product_description',
        size: 2,
        filter: true,
        content: (product) => {

            return Object.values(product.products || {}).map(p=>p.description).join(', ')
        }
    }, {
        label: 'Ship Name',
        key: 'ship_name',
        size: 1,
        filter: true,
        content: (product) => {
            return product.ship_name || "-"
        }
    }, {
        label: 'Source',
        key: 'source',
        filter: true,
        filterValue: (product) => product.source,
        content: (product) => {
            return product.source === product.reference ? <Badge style={{fontSize: '1.1em'}}  size={'lg'} variant={'primary'}>{product.source}</Badge> : product.source
        }
    }, {
        label: 'Destination',
        key: 'destination',
        filter: true,
        filterValue: (product) => product.destination,
        content: (product) => {
            return product.destination === product.reference ? <Badge style={{fontSize: '1.1em'}} variant={'primary'}>{product.destination}</Badge> : product.destination
        }
    }, {
        label: 'Qty',
        key: 'quantity',
        filter: true,
        content: (product) => {
            return product.quantity
        }
    }, {
        label: 'Event',
        key: 'event',
        filter: true,
        content: (product) => {
            return product.event
        }
    }, {
        label: 'Status',
        key: 'status',
        size: 1,
        filter: true,
        content: (product) => {

            return product.status
        }
    }
];
const CompletedHeaders = [
    {
        label: 'Date',
        key: 'eventDate',
        size: 1.2,
        filterValue: (product) => {
            return new Date(product.eventDate).toLocaleString()
        },
        filter: true,
        content: (product) => {
            return <span>
            <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={"envelope"}/>
                {product.notified && <FontAwesomeIcon icon={"check"} className={"text-success"} size={"sm"}/>}
                {!product.notified && <FontAwesomeIcon icon={"times"} className={"text-danger"} size={"sm"}/>}
                    </span>
                {new Date(product.eventDate).toLocaleString()}</span>

        }
    },
    {
        label: 'Customer',
        key: 'customer_name',
        filter: true,
        content: (product) => {
            return product.customer_name
        }
    }, {
        label: 'Product',
        key: 'product_code',
        size: 1,
        filter: true,
        filterValue: (product) => {

            return Object.values(product.products || {}).map(p=>p.code).join(', ')
        },
        content: (product) => {
            return Object.values(product.products || {}).map(p=>p.code).join(', ')
        }
    }, {
        label: 'Description',
        key: 'product_description',
        size: 2,
        filter: true,
        filterValue: (product) => {

            return Object.values(product.products || {}).map(p=>p.description).join(', ')
        },
        content: (product) => {

            return Object.values(product.products || {}).map(p=>p.description).join(', ')
        }
    }, {
        label: 'Ship Name',
        key: 'ship_name',
        size: 1,
        filter: true,
        content: (product) => {
            return product.ship_name || "-"
        }
    }, {
        label: 'Source',
        key: 'source',
        filter: true,
        content: (product) => {
            return product.source === product.reference ? <Badge style={{fontSize: '1.1em'}}  size={'lg'} variant={'primary'}>{product.source}</Badge> : product.source
        }
    }, {
        label: 'Destination',
        key: 'destination',
        filter: true,
        content: (product) => {
            return product.destination === product.reference ? <Badge style={{fontSize: '1.1em'}} variant={'primary'}>{product.destination}</Badge> : product.destination
        }
    }, {
        label: 'Pass',
        key: 'pass_number',
        filter: true,
        content: (product) => {
            return product.pass_number
        }
    }, {
        label: 'Qty',
        key: 'quantity',
        filter: true,
        content: (product) => {
            return product.quantity
        }
    }, {
        label: 'Event',
        key: 'event',
        filter: true,
        content: (product) => {
            return product.event.capitalize().split('_').join(' ')
        }
    }, {
        label: 'Status',
        key: 'status',
        size: 1,
        filter: true,
        content: (product) => {
            return product.status
        }
    }, {
        label: 'Operative',
        key: 'user',
        filter: true,
        content: (product) => {

            return product.user ? product.user.displayName : '-'
        }
    }
];

export const STATUS_LABELS =  [
    'collection',
    'outturn',
    'import',
    'internal_movement',
    'movement_in',
    'movement_out',
];
export const STATUS_LABELS2 =  [
    'collection',
    'outturn'
];

export const MOVEMENT_STATUS_LABELS = [
    'Pending Review',
    'Awaiting outturn',
    'Awaiting collection',
    'Outturn in progress',
    'Collection in progress',
    'Stocked In',
    'Stocked Out',
    'Verification Underway'
];
export const MOVEMENT_STATUS_LABELS2 = [
    'Stocked In',
    'Stocked Out',
    'Verification Underway'
];
const withForecast = Component => {
    class WithForecast extends React.Component {
        constructor(props) {
            super(props);
            this.applyFilter = this.applyFilter.bind(this);
            this.state = {
                start:  moment().subtract(2,'week').toDate().getTime(),
                end:  moment().add(1,'week').toDate().getTime(),
                loading_requests: true,
                loading_outturns: true,
                forecast: {
                    status_key: 'event',
                    status_filters: [
                        'collection',
                        'outturn'
                    ],
                    selectAll: () => {
                        const {forecast} = this.state;
                        forecast.status_filters = [...STATUS_LABELS2];
                        this.setState({forecast: {...forecast}}, () => this.filterForecast(forecast, () => {
                        }))
                    },
                    selectNone: () => {
                        const {forecast} = this.state;
                        forecast.status_filters = [];
                        this.setState({forecast: {...forecast}}, () => this.filterForecast(forecast, () => {
                        }))
                    },
                    updateStatusLabels: (sl) => {
                        const {forecast} = this.state;
                        const {status_filters} = forecast;
                        if (status_filters.indexOf(sl) > -1) {
                            forecast.status_filters = status_filters.filter(s => s !== sl)
                        } else {
                            forecast.status_filters.push(sl);
                        }
                        this.setState({forecast: {...forecast}}, () => this.filterForecast(forecast, () => {
                        }))

                    },
                    movement_status_filters:  [...MOVEMENT_STATUS_LABELS],
                    selectAllMovement: () => {
                        const {forecast} = this.state;
                        forecast.movement_status_filters = [...MOVEMENT_STATUS_LABELS];
                        this.setState({forecast: {...forecast}}, () => this.filterForecast(forecast, () => {
                        }))
                    },
                    selectNoneMovement: () => {
                        const {forecast} = this.state;
                        forecast.movement_status_filters = [];
                        this.setState({forecast: {...forecast}}, () => this.filterForecast(forecast, () => {
                        }))
                    },
                    updateMovementStatusLabels: (sl) => {
                        const {forecast} = this.state;
                        const {movement_status_filters} = forecast;
                        if (movement_status_filters.indexOf(sl) > -1) {
                            forecast.movement_status_filters = movement_status_filters.filter(s => s !== sl)
                        } else {
                            forecast.movement_status_filters.push(sl);
                        }
                        this.setState({forecast: {...forecast}}, () => this.filterForecast(forecast, () => {
                        }))

                    },
                    all:{},
                    sorted: [],
                    data:[],
                    exportData: [],
                    extra_date_filters: {
                        issued_on: {
                            filter: 'any',
                            filter_options: ['never', 'any', 'today', 'this_week', 'custom'],
                            between_start: moment().subtract(4,'week').toDate().getTime(),
                            between_end:  moment().add(1,'week').toDate().getTime(),
                            setDateFilter: ({filter, start, end}) => {
                                const {devan_gate_passes} = this.state;
                                devan_gate_passes.extra_date_filters.issued_on.between_start = start;
                                devan_gate_passes.extra_date_filters.issued_on.between_end = end;
                                devan_gate_passes.extra_date_filters.issued_on.filter = filter;
                                this.setState({devan_gate_passes: devan_gate_passes}, ()=>this.filterGatePasses(devan_gate_passes, null, false))
                            }
                        },
                        revoked_on: {
                            filter: 'never',
                            filter_options: ['never', 'any', 'today', 'this_week', 'custom'],
                            between_start: moment().subtract(4,'week').toDate().getTime(),
                            between_end:  moment().add(1,'week').toDate().getTime(),
                            setDateFilter: ({filter, start, end}) => {
                                const {devan_gate_passes} = this.state;
                                devan_gate_passes.extra_date_filters.revoked_on.between_start = start;
                                devan_gate_passes.extra_date_filters.revoked_on.between_end = end;
                                devan_gate_passes.extra_date_filters.revoked_on.filter = filter;
                                this.setState({devan_gate_passes: devan_gate_passes}, ()=>this.filterGatePasses(devan_gate_passes, null, false))
                            }
                        }
                    },
                    filter: '',
                    loading: true,
                    headers: ForecastingHeaders,
                    sort: 'eventDate',
                    setSort: (sort) => {
                        const requests = {...this.state.forecast};
                        if (requests.sort !== sort) {
                            requests.sort = sort;
                        } else {
                            requests.reverse *= -1;
                        }
                        requests.sorted = this.getSortedData(Object.values(requests.all), requests.sort, requests.reverse);

                        this.setState({forecast: requests}, () => {
                            this.filterForecast(requests);
                        })

                    },
                    reverse: 1,
                    setDate: (start, end, callback) => this.setState(
                        {
                            start: start,
                            end: end
                        }, () => {
                            this.unmountDatedSnapshot();
                            this.fetchDatedSnapshot(callback);
                        }
                    )
                },
                completed: {
                    extra_date_filters: {
                        eventDate: {

                        label: 'Collected or Delivered On',
                            filter: 'any',
                            filter_options: ['never', 'any', 'today', 'last_week', 'this_week', 'custom'],
                            between_start: moment().subtract(4,'week').toDate().getTime(),
                            between_end:  moment().add(1,'week').toDate().getTime(),
                            setDateFilter: ({filter, start, end}) => {
                                const {completed} = this.state;
                                completed.extra_date_filters.eventDate.between_start = start;
                                completed.extra_date_filters.eventDate.between_end = end;
                                completed.extra_date_filters.eventDate.filter = filter;
                                this.setState({completed: completed}, ()=>this.filterForecast(completed, null, true))
                            }
                        }},
                    status_key: 'event',
                    status_filters: [
                        'collection',
                        'outturn',
                        'import'
                    ],
                    selectAll: () => {
                        const {completed} = this.state;
                        completed.status_filters = [...STATUS_LABELS];
                        this.setState({completed: {...completed}}, () => this.filterForecast(completed, () => {
                        }, true))
                    },
                    selectNone: () => {
                        const {completed} = this.state;
                        completed.status_filters = [];
                        this.setState({completed: {...completed}}, () => this.filterForecast(completed, () => {
                        }, true))
                    },
                    updateStatusLabels: (sl) => {
                        const {completed} = this.state;
                        const {status_filters} = completed;
                        if (status_filters.indexOf(sl) > -1) {
                            completed.status_filters = status_filters.filter(s => s !== sl)
                        } else {
                            completed.status_filters.push(sl);
                        }
                        this.setState({completed: {...completed}}, () => this.filterForecast(completed, () => {
                        }, true))

                    },
                    notificationStates: [
                        "Notified",
                        "Unnotified",
                        "Both"
                    ],
                    notificationState: "Both",
                    changeNotificationFilters: filter=>{
                        const {completed} = this.state;
                        completed.notificationState = filter;
                        this.setState({
                            completed: {...completed}
                        }, () => this.filterForecast(completed, () => {
                        }, true))
                    },
                    movement_status_filters:  [...MOVEMENT_STATUS_LABELS2],
                    selectAllMovement: () => {
                        const {completed} = this.state;
                        completed.movement_status_filters = [...MOVEMENT_STATUS_LABELS2];
                        this.setState({completed: {...completed}}, () => this.filterForecast(completed, () => {
                        }, true))
                    },
                    selectNoneMovement: () => {
                        const {completed} = this.state;
                        completed.movement_status_filters = [];
                        this.setState({completed: {...completed}}, () => this.filterForecast(completed, () => {
                        }, true))
                    },
                    updateMovementStatusLabels: (sl) => {
                        const {completed} = this.state;
                        const {movement_status_filters} = completed;
                        if (movement_status_filters.indexOf(sl) > -1) {
                            completed.movement_status_filters = movement_status_filters.filter(s => s !== sl)
                        } else {
                            completed.movement_status_filters.push(sl);
                        }
                        this.setState({completed: {...completed}}, () => this.filterForecast(completed, () => {
                        }, true))

                    },
                    all:{},
                    sorted: [],
                    data:[],
                    exportData: [],
                    filter: '',
                    loading: true,
                    headers: CompletedHeaders,
                    sort: 'eventDate',
                    setSort: (sort) => {
                        const requests = {...this.state.completed};
                        if (requests.sort !== sort) {
                            requests.sort = sort;
                        } else {
                            requests.reverse *= -1;
                        }
                        requests.sorted = this.getSortedData(Object.values(requests.all), requests.sort, requests.reverse);

                        this.setState({completed: requests}, () => {
                            this.filterForecast(requests, null, true);
                        })

                    },
                    reverse: -1,
                    setDate: (start, end, callback) => this.setState(
                        {
                            start: start,
                            end: end
                        }, () => {
                            this.unmountDatedSnapshot();
                            this.fetchDatedSnapshot(callback);
                        }
                    )
                }
            };
            this.filterForecast = this.filterForecast.bind(this)
        }

        async updateNotificationStatus(item) {
            if (item.notified == null && !!item.referenceKey) {
                // update the value
                let ref = this.props.firebase.db.ref(
                    DATABASE_REFS.organisations.summaries.collection(this.props.organisation_id))
                    .child(item.referenceKey).child("notified");
                if (item.event.toLowerCase() === "outturn") {
                    ref = this.props.firebase.db.ref(
                        DATABASE_REFS.organisations.summaries.outturn(this.props.organisation_id))
                        .child(item.referenceKey).child("notified");
                }
                const notifySnap = await ref.once("value");
                if (notifySnap.val()) {
                    await this.props.firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(this.props.organisation_id, 'completed'))
                        .child(item.referenceKey).child("notified")
                       .set(true);
                } else {
                    await this.props.firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(this.props.organisation_id, 'completed'))
                        .child(item.referenceKey).child("notified")
                        .set(false);
                }
            }
        }

        applyFilter(item, {headers, filter, status_filters, movement_status_filters, status_key, extra_date_filters, notificationState}) {
            if (!item.event) {
                item.event = item.movementType
            }
            this.updateNotificationStatus(item);

            let matched = headers.filter(h => !!h.filter).find(h => {
                const {key} = h;
                if(h.filterValue) {
                    return(h.filterValue(item).toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
                }
                return item[key] && (item[key].toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
            });
            if(!status_filters) {
                return !!matched
            }
            let matched2 = true;
            if(extra_date_filters) {
                matched2 = Object.keys(extra_date_filters).map(k => {
                    let matches_date_filters = false;
                    if(extra_date_filters[k].filter === 'any') {
                        matches_date_filters = true;
                    } else if(extra_date_filters[k].filter === 'never') {
                        return !item[k]
                    } else if(extra_date_filters[k].filter === 'today'){
                        const start = moment().startOf('day').toDate().getTime();
                        const end = moment().endOf('day').toDate().getTime();
                        return parseInt(item[k]) >= start && parseInt(item[k]) <= end
                    } else if(extra_date_filters[k].filter === 'this_week'){
                        const start = moment().startOf('week').toDate().getTime();
                        const end = moment().endOf('week').toDate().getTime();
                        return parseInt(item[k]) >= start && parseInt(item[k]) <= end
                    } else if (extra_date_filters[k].filter === 'custom') {
                        return parseInt(item[k]) >= extra_date_filters[k].between_start && parseInt(item[k]) <= extra_date_filters[k].between_end
                    } else if (extra_date_filters[k].filter === 'last_week') {
                        const start = moment().startOf('week').subtract(1, 'week').toDate().getTime();
                        const end = moment().endOf('week').subtract(1, 'week').toDate().getTime();
                        return parseInt(item[k]) >= start && parseInt(item[k]) <= end
                    }
                    return matches_date_filters;
                }).reduce((a,b)=>a&&b, true)
            }
            const matched3 = matched2 && !!matched && (status_filters.indexOf(item[status_key]) > -1) && (!(item.event === 'collection' || item.event === 'outturn') ||movement_status_filters.indexOf(item['status']) > -1);
            return matched3 && (!notificationState || notificationState === "Both" || ((notificationState === "Notified") === !!item.notified));
        }

        fetchDatedSnapshot(callback) {
            const {firebase, organisation_id} = this.props;
            const {start, end} = this.state;
            if (!organisation_id)
                this.setState({uid: null, loading: false});
            else {

                    const ref2 = firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed')).orderByChild('eventDate').startAt(start).endAt(end);
                    ref2.once('value').then((result_snap) => {
                        this.updateCompletedData(result_snap, ()=> {
                            if(callback) {
                                callback();
                            }
                            ref2.on('value',(result_snap) => {
                                this.updateCompletedData(result_snap, ()=>console.debug("Updated"));
                            });
                        });
                    });


            }
        }

        async checkForecastForDuplicates(forecasted_movement) {
            forecasted_movement.id = forecasted_movement.referenceKey;
            const {firebase, organisation_id} = this.props;
            const duplicate_record = await firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed')).child(forecasted_movement.id).once('value');
            if(duplicate_record.val()) {
                forecasted_movement.has_duplicate = true;
            }
            //const reference_seen_on = firebase.functions().httpsCallable('forecasting-getLastSeenOn')({
              //  forecasted_movement: forecasted_movement
          //  });
           // if (reference_seen_on && reference_seen_on < forecasted_movement.eventDate) {
            //    forecasted_movement.last_seen_on = reference_seen_on
         //   }
            return forecasted_movement;
        }

        async updateData(result_snap, callback){
            const forecast = _.cloneDeep(this.state.forecast);
            forecast.all = result_snap.val() || {};

            const checkedData = await Promise.all(
                Object.values(forecast.all).map(this.checkForecastForDuplicates.bind(this))
            );

            forecast.sorted = this.getSortedData(checkedData, forecast.sort, forecast.reverse);
            forecast.data = this.getFilteredData(forecast.sorted, forecast.filter,this.applyFilter, forecast);
            forecast.exportData = this.getExportData(forecast.data, forecast.headers);

            this.setState({
                forecast: forecast
            }, callback)
        }
        updateCompletedData(result_snap, callback){
            const forecast = _.cloneDeep(this.state.completed);
            forecast.all = result_snap.val() || {};
            forecast.sorted = this.getSortedData(Object.values(forecast.all), forecast.sort, forecast.reverse);
            forecast.data = this.getFilteredData(forecast.sorted, forecast.filter,this.applyFilter, forecast);
            forecast.exportData = this.getExportData(forecast.data, forecast.headers);

            this.setState({
                completed: forecast
            }, callback)
        }

        getExportData(data) {
            const exportData = [];
            data.map(d => {


                Object.values(d.products || {}).map((p) => {

                    const {customer_name, haulier, driver, vehicle_reg, event, notes,
                        pass_number, source, destination, status, processed_on, ship_name} = d;
                    const res = {};
                    res.eventDate = new Date(d.eventDate).toLocaleString();
                    res.movementType = event;
                    res.customer = customer_name;
                    res.product = p.code;
                    res.description = p.description;
                    res.quantity = p.change;
                    res.source = source;
                    res.ship_name = ship_name;
                    res.destination = destination;
                    if(res.movementType === 'outturn') {
                        res.destination = p.bays;
                    } else {
                        res.source = p.bays;
                    }
                    res.status = status;
                    res.pass_number = pass_number;
                    res.driver = driver;
                    res.haulier = haulier;
                    res.notes = notes;
                    res.processed_on = processed_on;
                    res.vehicle_reg = vehicle_reg;
                    res.operative = d.user ? d.user.displayName ? d.user.displayName : d.user.email : '';
                    return exportData.push(res);
                });
            });
                return exportData;
        }

        setForecast(filter, callback, completed) {
            const existing_collections = {...this.state[completed ? 'completed' : 'forecast']};
            existing_collections.filter = filter;
            this.setState({[completed ? 'completed' : 'forecast']: existing_collections}, () => {
                this.filterForecast({}, callback)
            })
        }
        setCompleted(filter, callback) {
            const existing_collections = {...this.state.completed };
            existing_collections.filter = filter;
            this.setState({['completed']: existing_collections}, () => {
                this.filterForecast({}, callback, true)
            })
        }

        getSortedData(data_to_use, sort, reverse) {

            return data_to_use.sort((a, b) => {

                const split_sort = sort.split(',');
                if (split_sort.length > 1) {
                    const a_val = a[split_sort[0]] === '-' || a[split_sort[0]] === '' ? a[split_sort[1]] : a[split_sort[0]];
                    const b_val = b[split_sort[0]] === '-' || a[split_sort[0]] === '' ? b[split_sort[1]] : b[split_sort[0]];
                    return ((a_val < b_val || !b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
                }
                const a_val = typeof (a[sort]) === 'string' ? a[sort].toLowerCase() : a[sort];
                const b_val = typeof (b[sort]) === 'string' ? b[sort].toLowerCase() : b[sort];
                if(a_val && !b_val) {
                    return -1 * reverse;
                }
                if(b_val && !a_val) {
                    return 1 * reverse;
                }
                if(!b_val && !a_val) {
                    return 0;
                }

                return ((a_val < b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
            });

        }

        getFilteredData(data_to_filter, filter, filter_function, options) {
            return data_to_filter.filter((a) => filter_function(a, options))
        }

        filterForecast(options, callback, completed) {
            const existing_collections = {...this.state[completed ? 'completed' : 'forecast']};
            existing_collections.data = this.getFilteredData( existing_collections.sorted, existing_collections.filter, this.applyFilter, existing_collections);
            existing_collections.exportData = this.getExportData(existing_collections.data, existing_collections.headers);
            this.setState({[completed ? 'completed' : 'forecast']: existing_collections}, callback)
        }

        componentDidMount() {
            const {firebase, organisation_id} = this.props;
            if (!organisation_id)
                this.setState({uid: null, loading: false});
            else {

                const ref = firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'pending'));
                ref.once('value').then((result_snap) => {
                    this.updateData(result_snap, ()=> {
                        ref.on('value',(result_snap) => {
                            this.updateData(result_snap, ()=>console.debug("Updated")).then(()=>{});
                        });
                    }).then(()=>{});
                });


            }
            this.fetchDatedSnapshot();
        }

        unmountDatedSnapshot() {
            const {firebase, organisation_id} = this.props;
            if (!organisation_id) return;
            firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'pending')).off('value');
            firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed')).off('value');
        }

        componentWillUnmount() {
            this.unmountDatedSnapshot();
        }

        render() {
            const loading = this.state.loading || this.state.loading;
            if (loading) {
                return <LoadingScreen/>
            }

            return (
                <ContainerMasterForecastContext.Provider value={this.state}>
                    <Component {...this.props} filters={{forecast:this.state.forecast.filter, completed:this.state.completed.filter}}
                                               setCompleted={this.setCompleted.bind(this)}
                               setForecast={this.setForecast.bind(this)}

                    />
                </ContainerMasterForecastContext.Provider>
            );
        }
    }

    return withFirebase(WithForecast);
};


export {CompletedHeaders, ForecastingHeaders};
export default withForecast;