import {ButtonGroup} from "react-bootstrap";
import React from "react";
import customers from '../icons/customers.png';
import bays from '../icons/bays.png';
import menu from '../icons/menu.png';
import collection_request from '../icons/collection_request.png';
import collections from '../icons/collections.png';
import products from '../icons/products.png';
import devan_request from '../icons/devan_request.png';
import users from '../icons/users.png';
import bulk from '../icons/bulk.png';
import gate_pass from '../icons/gate_pass.png';
import forecast from '../icons/forecast.png';
import reports from '../icons/reports.png';
import outturns from '../icons/outturns.png';
import archive from '../icons/archive.png';
import Image from "react-bootstrap/Image";
import {faShip, faTruck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const pages = [{
    label: 'Menu',
    icon: menu,
    page: 'menu'
},{
    label: 'Devan Requests',
    icon: devan_request,
    page: 'devan_requests'
},
    {
        label: 'Stock In',
        icon: outturns,
        page: 'outturns'
    },
    {
        label: 'Collection Requests',
        icon: collection_request,
        page: 'collection_requests'
    },
    {
        label: 'Stock Out',
        icon: collections,
        page: 'collections'
    },
    {
        label: 'Bulk Picking Lists',
        icon: bulk,
        page: 'bulk'
    },
    {
        label: 'Gate Passes',
        icon: gate_pass,
        page: 'reports'
    },
    {
        label: 'Reporting',
        icon: reports,
        page: 'reporting',
        customerShow: (customer) => {
            return !!customer.reporting
        }
    },
    {
        label: 'Live Tracker',
        composite_icon: {ship: faShip, truck: faTruck},
        page: 'livetracker',
        customerShow: (customer) => {
            return !!customer
        }
    },
    {
        label: 'Forecast',
        icon: forecast,
        page: 'tracking',
        customerShow: (customer) => {
            return !!customer.forecasting
        }
    },
    {
        label: 'Customers',
        icon: customers,
        page: 'customers'
    },
    {
        label: 'Products',
        icon: products,
        page: 'products',
        customerShow: (customer) => {
            const {product_view} = customer;
            return !!product_view
        }
    },
    {
        label: 'Bays',
        icon: bays,
        page: 'bays'
    },
    {
        label: 'Users',
        icon: users,
        page: 'users'
    },
    {
        label: 'Archives',
        icon: archive,
        page: 'archives',
        customerShow: (customer) => {
            return !!customer.archive
        }
    }
    ];
const Hotbar = ({updatePage, page, customer}) => {
    let pages_to_show = pages;
    if (customer)
        pages_to_show = pages.filter(page => {
            if(!page.customerShow) {
                return false;
            }
            return page.customerShow(customer)
        })
    return  <ButtonGroup size={'lg'} vertical={true} style={{position: "fixed", top: "115px", left: "15px", zIndex: 100}}>
        {pages_to_show.map(p =>
            <button key={`hotbar-button-${p.page}`} className={`no-print btn btn-${page===p.page ? 'primary' : 'secondary'} not-mobile text-left show-text-on-hover`}  data-page={p.page} onClick={updatePage}>
                {!p.composite_icon && <React.Fragment><Image style={{height: 'auto', width: 'auto', maxWidth: '1.2em', margin: 0}} src={p.icon}/> <span>{p.label}</span></React.Fragment>}
                {p.composite_icon && <React.Fragment>
                    <div className="fa-layers fa-fw">

  <FontAwesomeIcon icon={'circle'}  className={'text-tertiary'} size='lg' />
  <FontAwesomeIcon className={' mr-2 mb-2 text-light'} icon={p.composite_icon.truck} size='sm' />
                        <FontAwesomeIcon className={`text-${page!==p.page ? 'primary' : 'secondary'} ml-2 mt-2`}  icon={p.composite_icon.ship} size='sm' />
</div> <span>{p.label}</span>
                </React.Fragment>}
            </button>)
        }
    </ButtonGroup>
};

export default Hotbar;