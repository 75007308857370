import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import Button from "./Button";


class ConfirmPrompt extends React.Component {
    render() {
        const { confirm_title_id, cancel_title_id, onConfirmClick, onCancelClick, message_id, intl} = this.props;
        const message = intl.formatMessage({id: message_id});

        return (
            <div className={'banner'}> {message}
                <Button testid={'confirm_prompt'} title_id={confirm_title_id} className={"btn-primary text-light ml-2 mr-2"} onClick={onConfirmClick}/>
                <Button testid={'cancel_prompt'} title_id={cancel_title_id} className={"btn-danger text-light mr-2"} onClick={onCancelClick}/>
            </div>
        );
    }
}

ConfirmPrompt.propTypes = {
    confirm_title_id: PropTypes.string.isRequired,
    cancel_title_id: PropTypes.string.isRequired,
    onConfirmClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    message_id: PropTypes.string.isRequired
};

export default injectIntl(ConfirmPrompt);