import React, {useEffect, useRef, useState} from "react";
import {CompletedTable} from "../../pages/authenticatedPages/Reports";
import {
    CompletedHeaders as headers,
    MOVEMENT_STATUS_LABELS,
    MOVEMENT_STATUS_LABELS2,
    STATUS_LABELS2
} from "../ContainerMaster/withForecast";
import DATABASE_REFS from "../../constants/db_refs";
import moment from "moment";
import {AdvancedDateFilter, AdvancedFilter, AdvancedFilterPanel} from "../../elements/AdvancedFiltering";
import {TrackingTable} from "../../pages/authenticatedPages/Tracking";
import {Badge} from "react-bootstrap";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const fheaders = [
    {
        label: 'Date',
        key: 'eventDate',
        filterValue: (product) => {
            return new Date(product.eventDate).toLocaleString()
        },
        filter: true,
        content: (product) => {
            return new Date(product.eventDate).toLocaleString()
        }
    },
    {
        label: 'Customer',
        key: 'customer_name',
        filter: true,
        content: (product) => {
            return product.customer_name
        }
    }, {
        label: 'Product',
        key: 'product_code',
        size: 1,
        filter: true,
        content: (product) => {
            return Object.values(product.products || {}).map(p=>p.code).join(', ')
        }
    }, {
        label: 'Description',
        key: 'product_description',
        size: 2,
        filter: true,
        content: (product) => {

            return Object.values(product.products || {}).map(p=>p.description).join(', ')
        }
    }, {
        label: 'Source',
        key: 'source',
        filter: true,
        filterValue: (product) => product.source,
        content: (product) => {
            return product.source === product.reference ? <Badge style={{fontSize: '1.1em'}}  size={'lg'} variant={'primary'}>{product.source}</Badge> : product.source
        }
    }, {
        label: 'Destination',
        key: 'destination',
        filter: true,
        filterValue: (product) => product.destination,
        content: (product) => {
            return product.destination === product.reference ? <Badge style={{fontSize: '1.1em'}} variant={'primary'}>{product.destination}</Badge> : product.destination
        }
    }, {
        label: 'Qty',
        key: 'quantity',
        filter: true,
        content: (product) => {
            return product.quantity
        }
    }, {
        label: 'Event',
        key: 'event',
        filter: true,
        content: (product) => {
            return product.event
        }
    }, {
        label: 'Status',
        key: 'status',
        size: 1,
        filter: true,
        content: (product) => {

            return product.status
        }
    }
];
export const STATUS_LABELS =  [
    'collection',
    'outturn'
];
const CustomerContext = React.createContext({
    completed: {},
    pending: {}
});

class CustomerReportingTable extends CompletedTable {
    key = 'completed';
    CMContext = CustomerContext;
    getAdvancedFilters({updateStatusLabels: onClick, status_filters, selectAll, selectNone, extra_date_filters, updateMovementStatusLabels: onClickMovement, selectAllMovement, selectNoneMovement, movement_status_filters}) {
        return <AdvancedFilterPanel><AdvancedFilter
            legend_text={'Movement Type'}
            onClick={onClick}
            selectAll={selectAll}
            deselectAll={selectNone}
            original_list={[...STATUS_LABELS]}
            current_list={status_filters}
        />
            <AdvancedFilter
                legend_text={'Movement Status'}
                onClick={onClickMovement}
                selectAll={selectAllMovement}
                deselectAll={selectNoneMovement}
                original_list={[...MOVEMENT_STATUS_LABELS2]}
                current_list={movement_status_filters}
            />
            {Object.values(extra_date_filters).map((edf,i)=><AdvancedDateFilter key={`${Object.keys(extra_date_filters || {})[i]}_adf`} {...edf} legend_text={edf.label}/>)}
        </AdvancedFilterPanel>
    }
}

const CustomerReporting = ({firebase, organisation_id, customer}) => {
    let timeout = null;
    let loading_timeout = null;
    const [filter, setFilter] = useState('');
    const [sort, setSortKey] = useState('eventDate');
    const [reverse, setReverse] = useState(-1);
    const [dates, setDate] = useState({
        start: moment().startOf( 'week').toDate().getTime(),
        end: moment().endOf('week').toDate().getTime(),
    });

    const [completed, setCompleted] = useState({});
    const [sorted, setSorted] = useState([]);
    const [data, setData] = useState([]);
    const [exportData, setExportData] = useState([]);
    const inner = useRef();
    const [loading, setLoading] = useState(false);
    const [status_filters, setStatusFilter] = useState( [
        ...STATUS_LABELS
    ]);
    const [extra_date_filters, setExtraDateFilters] = useState({
        eventDate: {

            label: 'Collected or Delivered On',
                filter: 'any',
                filter_options: ['never', 'any', 'today', 'last_week', 'this_week', 'custom'],
                between_start: moment().subtract(4,'week').toDate().getTime(),
                between_end:  moment().add(1,'week').toDate().getTime(),
                setDateFilter: ({filter, nstart,nend}) => {
                setExtraDateFilters(edf=>{

                    edf.eventDate.filter = filter;

                    if (filter === 'custom' && moment(new Date(parseInt(nstart))) < dates.start) {
                        edf.eventDate.between_start = nstart;
                        edf.eventDate.between_end = nend;
                        setDate({
                            start: nstart,
                            end: dates.end
                        })
                    }
                    if (filter === 'this_week' && moment(new Date()).startOf('week') < dates.start) {
                        setDate({
                            start: moment(new Date()).startOf('week').toDate().getTime(),
                            end: dates.end
                        })
                    }
                    if (filter === 'last_week' && moment(new Date()).startOf('week').subtract(1, 'week') < dates.start) {
                        setDate({
                            start: moment(new Date()).startOf('week').subtract(1, 'week').toDate().getTime(),
                            end: dates.end
                        })
                    }
                    return {...edf}
                })

            }
        }});
    const statusSelection = {
        status_key: 'event',
        selectAll: () => {
            setStatusFilter( [...STATUS_LABELS]);
        },
        selectNone: () => {
            setStatusFilter( []);
        },
        updateStatusLabels: (sl) => {
            if (status_filters.indexOf(sl) > -1) {
              setStatusFilter([...status_filters.filter(s => s !== sl)])
            } else {
               setStatusFilter([...status_filters, sl]);
            }
        },
    };
    const [movement_status_filters, setMovementStatusFilter] = useState( [
        ...MOVEMENT_STATUS_LABELS2
    ]);
    const movementSelection = {
        selectAllMovement: () => {
            setMovementStatusFilter([...MOVEMENT_STATUS_LABELS2])
        },
        selectNoneMovement: () => {
            setMovementStatusFilter([]);
        },
        updateMovementStatusLabels: (sl) => {
            if (movement_status_filters.indexOf(sl) > -1) {
                setMovementStatusFilter([...movement_status_filters.filter(s => s !== sl)])
            } else {
                setMovementStatusFilter([...movement_status_filters, sl]);
            }
        }
    };

    const setDates = (start, end, cb) => {
       setDate({
           start, end
       });
       if(timeout) clearTimeout(timeout)
        timeout = setTimeout(cb, 500)

    };

    const setSort = (new_sort) => {
        if (sort !== new_sort) {
            setSortKey(new_sort);
        } else {
            setReverse(reverse*-1)
        }
    };

    const applyFilter = (item) => {

        let matched = headers.filter(h => !!h.filter).find(h => {
            const {key} = h;
            if(h.filterValue) {
                return(h.filterValue(item).toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
            }
            return item[key] && (item[key].toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
        });
        if(!status_filters) {
            return !!matched
        }
        let matched2 = true;
        if(extra_date_filters) {
            matched2 = Object.keys(extra_date_filters).map(k => {
                let matches_date_filters = false;
                if(extra_date_filters[k].filter === 'any') {
                    matches_date_filters = true;
                } else if(extra_date_filters[k].filter === 'never') {
                    return !item[k]
                } else if(extra_date_filters[k].filter === 'today'){
                    const start = moment().startOf('day').toDate().getTime();
                    const end = moment().endOf('day').toDate().getTime();
                    return parseInt(item[k]) >= start && parseInt(item[k]) <= end
                } else if(extra_date_filters[k].filter === 'this_week'){
                    const start = moment().startOf('week').toDate().getTime();
                    const end = moment().endOf('week').toDate().getTime();
                    return parseInt(item[k]) >= start && parseInt(item[k]) <= end
                } else if (extra_date_filters[k].filter === 'custom') {
                    return parseInt(item[k]) >= extra_date_filters[k].between_start && parseInt(item[k]) <= extra_date_filters[k].between_end
                } else if (extra_date_filters[k].filter === 'last_week') {
                    const start = moment().startOf('week').subtract(1, 'week').toDate().getTime();
                    const end = moment().endOf('week').subtract(1, 'week').toDate().getTime();
                    return parseInt(item[k]) >= start && parseInt(item[k]) <= end
                }
                return matches_date_filters;
            }).reduce((a,b)=>a&&b, true)
        }
        return matched2 && !!matched && (status_filters.indexOf(item[statusSelection.status_key]) > -1) && (!(item.event === 'collection' || item.event === 'outturn') ||movement_status_filters.indexOf(item['status']) > -1);
    }


    const addCompleted = (completed_snap) => {
        const completed = completed_snap.val();
        if (completed.customer_id !== customer.id) return;
        console.log("found")
        setCompleted((completed=>{
            completed[completed_snap.key] = completed_snap.val();
            if (loading_timeout) {
                clearTimeout(loading_timeout)
            }
            loading_timeout = setTimeout(()=>setLoading(false), 500)
            return {...completed}
        }))

    };

    const getExportData = () => {
        const exports = [];
       data.map(d => {


           return Object.values(d.products || {}).map((p) => {

                const {customer_name, haulier, driver, vehicle_reg, event, notes,
                    pass_number, source, destination, status, processed_on} = d;
                const res = {};
                res.eventDate = new Date(d.eventDate).toLocaleString();
                res.movementType = event;
                res.customer = customer_name;
                res.product = p.code;
                res.description = p.description;
                res.quantity = p.change;
                res.source = source;
                res.destination = destination;
                if(res.movementType === 'outturn') {
                    res.destination = p.bays;
                } else {
                    res.source = p.bays;
                }
                res.status = status;
                res.pass_number = pass_number;
                res.driver = driver;
                res.haulier = haulier;
                res.notes = notes;
                res.processed_on = processed_on;
                res.vehicle_reg = vehicle_reg;
                res.operative = d.user ? d.user.displayName ? d.user.displayName : d.user.email : '';
               exports.push(res)
            });
        });
        return exports

    };
    const updateCompleted = (completed_snap) => {
        setCompleted((completed=>{
            completed[completed_snap.key] = completed_snap.val();
            if (loading_timeout) {
                clearTimeout(loading_timeout)
            }
            loading_timeout = setTimeout(()=>setLoading(false), 500)
            return {...completed}
        }))
    };
    const removeCompleted = (completed_snap) => {
        setCompleted((completed=>{
            const new_completed = {};
            Object.keys(completed).map(ckey=>{
                if (ckey !== completed_snap.key) {
                    new_completed[ckey] = completed[ckey]
                }
            });
            return new_completed
        }))
    };
    const fetchCompleted = () => {
        setLoading(true);
        setCompleted(()=>{

            const ref = firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed'))
                .orderByChild('eventDate')
                .startAt(dates.start)
                .endAt(dates.end);
            ref.off();
            ref.on('child_added', addCompleted);
            ref.on('child_changed', updateCompleted);
            ref.on('child_removed', removeCompleted);
            if (loading_timeout) {
                clearTimeout(loading_timeout)
            }
            loading_timeout = setTimeout(()=>setLoading(false), 500)
            return {};
        })

    };

    useEffect(()=>{
        fetchCompleted();
        return () => firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed')).off();
    }, [customer, organisation_id, dates]);

    useEffect(() => {
            setSorted(() => {
            return Object.values(completed).sort((a, b) => {

                const split_sort = sort.split(',');
                if (split_sort.length > 1) {
                    const a_val = a[split_sort[0]] === '-' || a[split_sort[0]] === '' ? a[split_sort[1]] : a[split_sort[0]];
                    const b_val = b[split_sort[0]] === '-' || a[split_sort[0]] === '' ? b[split_sort[1]] : b[split_sort[0]];
                    return ((a_val < b_val || !b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
                }
                const a_val = typeof (a[sort]) === 'string' ? a[sort].toLowerCase() : a[sort];
                const b_val = typeof (b[sort]) === 'string' ? b[sort].toLowerCase() : b[sort];
                if (a_val && !b_val) {
                    return -1 * reverse;
                }
                if (b_val && !a_val) {
                    return reverse;
                }
                if (!b_val && !a_val) {
                    return 0;
                }

                return ((a_val < b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
            })
        })
    }, [completed, sort, reverse])
    useEffect(() => {
        setData(() => {
            return sorted.filter(applyFilter)
        })
    }, [sorted, filter, movement_status_filters, status_filters, extra_date_filters]);
    useEffect(() => {
        setExportData(() => {
            return getExportData()
        })
    }, [data])
    return <div ref={inner}>
    <CustomerContext.Provider value={{...dates, completed: {all: completed, extra_date_filters, sorted, reverse, data, movement_status_filters, status_filters, exportData,  filter, sort, setFilter, ...movementSelection, ...statusSelection, setSort, setDate: setDates,  headers}}}>
        {!loading  && <CustomerReportingTable setFilter={setFilter} firebase={firebase}/>}
        {loading && <LoadingScreen/>}
    </CustomerContext.Provider>
    </div>
};


const CustomerForecastContext = React.createContext({
    pending: {}
});

class CustomerForecastTable extends TrackingTable {
    key = 'pending';
    CMContext = CustomerForecastContext;
    getAdditionalHeaders() {
        return [];
    }
    getAdvancedFilters({updateStatusLabels: onClick, status_filters, selectAll, selectNone, extra_date_filters, updateMovementStatusLabels: onClickMovement, selectAllMovement, selectNoneMovement, movement_status_filters}) {
        return <AdvancedFilterPanel><AdvancedFilter
            legend_text={'Movement Type'}
            onClick={onClick}
            selectAll={selectAll}
            deselectAll={selectNone}
            original_list={[...STATUS_LABELS2]}
            current_list={status_filters}
        />
            <AdvancedFilter
                legend_text={'Movement Status'}
                onClick={onClickMovement}
                selectAll={selectAllMovement}
                deselectAll={selectNoneMovement}
                original_list={[...MOVEMENT_STATUS_LABELS]}
                current_list={movement_status_filters}
            />
        </AdvancedFilterPanel>
    }
}

const CustomerForecasting = ({firebase, organisation_id, customer}) => {
    const [status_filters, setStatusFilter] = useState( [
        ...STATUS_LABELS
    ]);
    const statusSelection = {
        status_key: 'event',
        selectAll: () => {
            setStatusFilter( [...STATUS_LABELS]);
        },
        selectNone: () => {
            setStatusFilter( []);
        },
        updateStatusLabels: (sl) => {
            if (status_filters.indexOf(sl) > -1) {
                setStatusFilter([...status_filters.filter(s => s !== sl)])
            } else {
                setStatusFilter([...status_filters, sl]);
            }
        },
    };
    const [movement_status_filters, setMovementStatusFilter] = useState( [
        ...MOVEMENT_STATUS_LABELS
    ]);
    const movementSelection = {
        selectAllMovement: () => {
            setMovementStatusFilter([...MOVEMENT_STATUS_LABELS])
        },
        selectNoneMovement: () => {
            setMovementStatusFilter([]);
        },
        updateMovementStatusLabels: (sl) => {
            if (movement_status_filters.indexOf(sl) > -1) {
                setMovementStatusFilter([...movement_status_filters.filter(s => s !== sl)])
            } else {
                setMovementStatusFilter([...movement_status_filters, sl]);
            }
        }
    };

    const setSort = (new_sort) => {
        if (sort !== new_sort) {
            setSortKey(new_sort);
        } else {
            setReverse(reverse*-1)
        }
    };

    const applyFilter = (item) => {

        let matched = headers.filter(h => !!h.filter).find(h => {
            const {key} = h;
            if(h.filterValue) {
                return(h.filterValue(item).toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
            }
            return item[key] && (item[key].toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
        });

        return !!matched && (status_filters.indexOf(item[statusSelection.status_key]) > -1) && (!(item.event === 'collection' || item.event === 'outturn') ||movement_status_filters.indexOf(item['status']) > -1);

    };
    const [filter, setFilter] = useState('');
    const [sort, setSortKey] = useState('eventDate');
    const [reverse, setReverse] = useState(-1);


    const [pending, setPending] = useState({});
    const [sorted, setSorted] = useState([]);
    const [data, setData] = useState([]);
    const [exportData, setExportData] = useState([]);
    const inner = useRef();

    const addPending = (completed_snap) => {
        const completed = completed_snap.val();
        if (completed.customer_id !== customer.id) return;
        setPending((pending=>{
            pending[completed_snap.key] = completed_snap.val();
            return {...pending}
        }))

    };

    const getExportData = () => {
        const exports = [];
        data.map(d => {


            return Object.values(d.products || {}).map((p) => {

                const {customer_name, haulier, driver, vehicle_reg, event, notes,
                    pass_number, source, destination, status, processed_on} = d;
                const res = {};
                res.eventDate = new Date(d.eventDate).toLocaleString();
                res.movementType = event;
                res.customer = customer_name;
                res.product = p.code;
                res.description = p.description;
                res.quantity = p.change;
                res.source = source;
                res.destination = destination;
                if(res.movementType === 'outturn') {
                    res.destination = p.bays;
                } else {
                    res.source = p.bays;
                }
                res.status = status;
                res.pass_number = pass_number;
                res.driver = driver;
                res.haulier = haulier;
                res.notes = notes;
                res.processed_on = processed_on;
                res.vehicle_reg = vehicle_reg;
                res.operative = d.user ? d.user.displayName ? d.user.displayName : d.user.email : '';
                exports.push(res)
            });
        });
        return exports

    };
    const updatePending = (completed_snap) => {
        setPending((pending=>{
            pending[completed_snap.key] = completed_snap.val();
            return {...pending}
        }))
    };
    const removePending = (completed_snap) => {
        setPending((pending=>{
            const new_pending = {};
            Object.keys(pending).map(ckey=>{
                if (ckey !== completed_snap.key) {
                    new_pending[ckey] = pending[ckey]
                }
            });
            return new_pending
        }))
    };
    const fetchCompleted = () => {
        setPending(()=>{
            const ref = firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'pending'))
                .orderByChild('customer_id').equalTo(customer.id);
            ref.off();
            ref.on('child_added', addPending);
            ref.on('child_changed', updatePending);
            ref.on('child_removed', removePending);
            return {};
        })

    };

    useEffect(()=>{
        fetchCompleted();
        return () => firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'pending')).off();
    }, [customer, organisation_id]);

    useEffect(() => {
        setSorted(() => {
            return Object.values(pending).sort((a, b) => {

                const split_sort = sort.split(',');
                if (split_sort.length > 1) {
                    const a_val = a[split_sort[0]] === '-' || a[split_sort[0]] === '' ? a[split_sort[1]] : a[split_sort[0]];
                    const b_val = b[split_sort[0]] === '-' || a[split_sort[0]] === '' ? b[split_sort[1]] : b[split_sort[0]];
                    return ((a_val < b_val || !b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
                }
                const a_val = typeof (a[sort]) === 'string' ? a[sort].toLowerCase() : a[sort];
                const b_val = typeof (b[sort]) === 'string' ? b[sort].toLowerCase() : b[sort];
                if (a_val && !b_val) {
                    return -1 * reverse;
                }
                if (b_val && !a_val) {
                    return reverse;
                }
                if (!b_val && !a_val) {
                    return 0;
                }

                return ((a_val < b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
            })
        })
    }, [pending, sort, reverse])
    useEffect(() => {
        setData(() => {
            return sorted.filter(applyFilter)
        })
    }, [sorted, filter, movement_status_filters, status_filters]);
    useEffect(() => {
        setExportData(() => {
            return getExportData()
        })
    }, [data]);
    return <div ref={inner}>
        <h3 className={'w-100 text-center'}>Forecasting</h3>
        <CustomerForecastContext.Provider value={{ pending: {all: pending, sorted, reverse, data, movement_status_filters, status_filters, exportData,  filter, sort, setFilter, ...movementSelection, ...statusSelection, setSort,  headers: fheaders}}}>
            <CustomerForecastTable setFilter={setFilter} firebase={firebase}/>
        </CustomerForecastContext.Provider>
    </div>
};

export {CustomerReporting, CustomerForecasting}