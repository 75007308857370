import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";

class Input extends React.Component {
    constructor(props) {
        super(props);
        this._id =`${props.prefix}_${props.name}`;
    }
    render() {
        const {intl, name, required, className, label_id, mustMatch, value, handleChange, type, readOnly} = this.props;

        const label =  intl.formatMessage({ id: label_id });
        let classes = className;
        let statusColor = "text-success";
        let statusIcon = <span>&#10003;</span>;
        if ((mustMatch !== "-1" && value !== mustMatch) || (required && value === "")) {
            classes+=" error";
            statusColor = "text-danger";
            statusIcon = <span>&#10005;</span>;
        }
        return (
            <div className={'form-group'}>
                {readOnly && <p>juvbujde</p>}
                <label htmlFor={this._id}>{label}</label>
                <div className="input-group mb-3">
                    <input disabled={Boolean(readOnly)} id={this._id} aria-label={label} onChange={handleChange} name={name} value={value} type={type} className={classes}/>
                        <div className="input-group-append">
                            <span className={"input-group-text "+statusColor}>{statusIcon}</span>
                        </div>
                </div>
            </div>
        );
    }
}

Input.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label_id: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    mustMatch: PropTypes.string,
    type: PropTypes.string,
    prefix: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool
};
Input.defaultProps = {
    mustMatch: "-1",
    type: "text",
    className: "form-control",
    required: false,
    prefix: "form_input"
};

export default injectIntl(Input);