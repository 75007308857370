import React from "react";

import PageTemplate from "../PageTemplate";
import CustomerForm from "./CustomerForm";
import CustomerTable from "./CustomerTable";
import {ContainerMasterDataContext} from "../../../components/ContainerMaster/ContainerMaster";
import Hotbar from "../../../elements/Hotbar";

class Customers extends PageTemplate {
    constructor(props) {
        super(props);
        this.state = {
            filter: ''
        };
    }

    applyFilter(customers) {
        return customers.filter((customer) => {
            const {filter} = this.state;
            return customer.code.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                customer.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
        });
    }

    render() {
        const {updatePage, organisation_id, firebase} = this.props;
        return (
            <div>
                <Hotbar updatePage={updatePage} page={'customers'}/>


                    <ContainerMasterDataContext.Consumer>
                        {({customers, products}) => {
                            const customer_list = Object.keys(customers || {}).map(customer => {
                                return {
                                    id: customer,
                                    ...customers[customer]
                                }
                            });
                            return <div className={'col-xs-12 col-sm-11 mr-auto ml-auto bg-primary pt-2 pb-2 mt-1'}>
                                    <h3 className={'text-light text-center'}>Customers</h3>

                                    <CustomerForm customerNames={customer_list.map(c=> {
                                        const {name} = c;
                                        return name;
                                    })} customerKeys={customer_list.map(c=> {
                                        const {code} = c;
                                        return code;
                                    })} organisation_id={organisation_id} firebase={firebase}/>
                                    <CustomerTable products={products} setFilter={this.setFilter} organisation_id={organisation_id} firebase={firebase} total={customer_list.length} customers={this.applyFilter(customer_list)}/>

                                </div>
                        }}
                    </ContainerMasterDataContext.Consumer>
            </div>)
    }
}

export default Customers;