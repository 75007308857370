import * as React from "react";
import {Button, Form, FormControl} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormCheck from "react-bootstrap/FormCheck";
import * as PropTypes from "prop-types";
import _ from 'lodash';

class WizardProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: props.product_code,
            description: props.product.description,
            crates: props.product.crates,
            warnings: props.product.warnings,
            delete: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.setDelete = this.setDelete.bind(this);
        this.isInvalid = this.isInvalid.bind(this);
        this.setToSimilar = this.setToSimilar.bind(this);
        this.addCrate = this.addCrate.bind(this);
        this.setCrates = this.setCrates.bind(this);
        this.removeCrate = this.removeCrate.bind(this);
    }
    setToSimilar(event) {
        this.setState({
            code: event.target.dataset.code,
            description: event.target.dataset.description,
            total: event.target.dataset.total
        })
    }

    handleChange(event) {
        const newState = this.state;
        newState[event.target.name] = event.target.value;
        this.setState(newState)
    }

    productChanged() {
        const {code, description} = this.state;
        const saved_description = this.props.product.description;
        const saved_product_code = this.props.product_code;

        let codeChanged = false;
        let descChanged = false;
        if(saved_product_code.indexOf('new product') < 0) {
            if (code !== saved_product_code) {
                codeChanged = true;
            }
            if (description !== saved_description) {
                descChanged = true;
            }
        }
        return {
            code: codeChanged ? `Product code changed from ${saved_product_code}` : "",
            description: descChanged ? `Product description changed from ${saved_description}` : ""
        }
    }
    getProduct() {
        return {
            delete: this.state.delete,
            create: this.state.create,
            code: this.state.code,
            description: this.state.description,
            crates: this.state.crates
        }
    }
    isInvalid() {
        const {code} = this.state;
        return (this.isInsufficientStock() || code.length === 0 || (!this.exists() && !this.state.create)) && !this.state.delete
    }


    exists() {
        return this.props.existing_products.filter((existing) => {
            return existing.code === this.state.code
        }).length > 0
    }
    getProductDetails() {
        if (!this.exists()) {
            return null;
        }
        return this.props.existing_products.filter((existing) => {
            return existing.code === this.state.code
        })[0]
    }
    setDelete(event) {
        this.setState({delete: event.target.checked})
    }
    similar() {
        if(this.exists()) {
            return [];
        }
        return this.props.existing_products.filter((existing) => {
            return (this.state.code.toLowerCase().indexOf(existing.code.toLowerCase()) > -1 ||  existing.code.toLowerCase().indexOf(this.state.code.toLowerCase()) >-1)
        })
    }
    addCrate() {
        this.setState({crates:this.state.crates + 1})
    }
    setCrates(event) {
        this.setState({crates: parseInt(event.target.value)})
    }
    removeCrate() {

        this.setState({crates:Math.max(this.state.crates - 1, 0)})
    }
    handleFocus = (event) => event.target.select();

    isInsufficientStock() {
        console.log("checking stock");
        let insufficient_stock = false;
        if(this.props.completed) return false;
        const product = this.getProductDetails();
        if (product) {
            let crates = 0;
            if (this.state.crates) {
                crates = parseInt(this.state.crates);
            }
            let total =  (this.getProductDetails().current|| 0) - ((this.getProductDetails().promised || 0)+this.getProductDetails().stocking_out || 0);
            if(product.outstanding[this.props.collection_id]) {
                total+=parseInt(product.outstanding[this.props.collection_id].change)
            }
            insufficient_stock = crates > total;
        }
        return insufficient_stock
    }

    componentDidUpdate(prevProps) {
        if(_.isEqual(prevProps.product, this.props.product)) return;
        this.setState({
            code: this.props.product_code,
            description: this.props.product.description,
            crates: this.props.product.crates,
            warnings: this.props.product.warnings,
            delete: false
        });
    }

    render() {
        const {code, description, warnings} = this.state;
        let product_stock = 0;
        try {
            product_stock = (this.getProductDetails().current || 0) - ((this.getProductDetails().promised || 0) + this.getProductDetails().stocking_out || 0);
            if(this.getProductDetails().outstanding[this.props.collection_id]) {
                product_stock+=parseInt(this.getProductDetails().outstanding[this.props.collection_id].change)
            }
        } catch (err) {
            console.error(err)
        }
        const insufficient_stock = this.isInsufficientStock();

        return <div className={'row ml-0 mr-0'}>
            {warnings && <div className={'col-12 rounded bg-warning'}><FontAwesomeIcon icon={'exclamation-triangle'}/> { warnings}</div>}
            <div className={'col-12 col-sm-3'}>
                <Form>
                    <Form.Group>
                        <Form.Control disabled={this.state.delete || this.props.completed} onFocus={this.handleFocus} autoComplete={"off"}
                                      isInvalid={(insufficient_stock && !this.props.completed) || code.length === 0 || (!this.exists() && !this.state.create)}
                                      isValid={!insufficient_stock && code.length > 0 &&  (this.exists() || this.state.create)} type="text" required
                                      placeholder="Product Code" name={'code'} value={code}
                                      onChange={this.handleChange}  />
                        {!this.state.delete && <Form.Control.Feedback>
                            {this.productChanged().code}
                        </Form.Control.Feedback>}
                        {!this.state.delete && <Form.Control.Feedback type={'invalid'}>
                            {!insufficient_stock && <span>{code.length === 0 ? "You cannot have a blank product code" : "You cannot collect an unknown product."}</span>}
                            {insufficient_stock && !this.props.completed ? "Insufficient Stock" : ""}
                        </Form.Control.Feedback>}
                        {!this.state.delete && <Form.Control.Feedback type={'invalid'}>
                            <span className={'text-success'}> {this.productChanged().code} </span>
                        </Form.Control.Feedback>}
                    </Form.Group>
                </Form>

            </div>
            <div className={'col-12 col-sm-6'}>
                <Form>
                    <Form.Group>
                        <Form.Control
                            disabled={this.state.delete || this.props.completed}
                            isValid={true}
                            type="text"
                            required
                            placeholder="Product Description" name={'description'} value={description}
                            onChange={this.handleChange}/>
                        {!this.state.delete && <Form.Control.Feedback>
                            {this.productChanged().description}
                        </Form.Control.Feedback>}
                    </Form.Group>
                </Form>
            </div>
            <div className={'col-12 col-sm-3'}>
               <h3 className={'col-12 col-sm-3 noselect'} style={{display: 'inline'}}>

                   { !this.props.completed && <FontAwesomeIcon className={'mr-1 text-danger'} icon={'minus-circle'} size={"1x"} onClick={this.removeCrate}/>}
                    <FontAwesomeIcon icon={'boxes'} size={"1x"}/>
                    <FormControl disabled={this.props.completed} className={'ml-1 m2-1'} style={{width: '100px', display: 'inline', textAlign: 'center', fontSize: '0.9em'}} type={'number'} value={this.state.crates} onChange={this.setCrates}/>
                   { !this.props.completed && <FontAwesomeIcon className={'ml-1 text-success'} icon={'plus-circle'} size={"1x"} onClick={this.addCrate}/> }
                </h3>

            </div>
            <div className={'col-10 text-justify'}>
                {this.exists(code) && !this.state.delete && <div>Product found! {product_stock} in stock.</div>}

                {this.state.delete && <span className={'mr-2 text-danger'}>Product will be removed from the list on save </span>}
                <FormCheck
                    type="switch"
                    id={"custom-switch_delete"+code}
                    variant={'success'}
                    label="Delete? "
                    inline={true}
                    name={'delete'}
                    checked={this.state.delete}
                    onChange={this.setDelete}
                />
                {
                    this.similar().length > 0 && <div className={'col-10 row m-0 text-light bg-primary p-2 pt-0 rounded'}>
                        <h5 className={'text-light col-12'}>Did you mean...?</h5><br/>
                        {this.similar().slice(0, 9).map((sim, i) => {
                            let product_stock = 0;
                            try {
                                product_stock = (sim.current || 0) - ((sim.promised || 0) + sim.stocking_out || 0);
                            } catch (err) {
                                console.error(err)
                            }
                            return <div key={sim.code+'_sim_'+i} className={'text-justify m-0 row p-2 col-4'}>
                                <div className={'col-12'}>
                                    <Button onClick={this.setToSimilar} data-code={sim.code} data-description={sim.description} data-total={sim.total} className={'btn-sm btn btn-tertiary mr-2'}>Select</Button>
                                    {sim.code}<br/><sub>{sim.description}</sub><br/><sub>{product_stock} in stock</sub>
                                </div>

                            </div>
                        })}
                        {
                            this.similar().length > 9 && <div className={'col-12 text-light text-right footer-text'}>
                                {
                                    this.similar().length - 9
                                } more...
                            </div>
                        }
                    </div>
                }
            </div>
            <div className={'col-2 text-center not-mobile'}>
                <p className={'mb-0'}>Collection contents</p>
            </div>
            <hr className={'w-100 bg-primary'}/>
        </div>
    }
}

WizardProduct.propTypes = {
    existing_products: PropTypes.array.isRequired,
    product: PropTypes.object.isRequired,
    product_index: PropTypes.number.isRequired,
    product_code: PropTypes.string,
    updateProduct: PropTypes.func
};

export default WizardProduct;