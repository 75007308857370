import React from 'react';

import {withFirebase} from '../Firebase';
import {DATABASE_REFS} from '../../constants/db_refs'

import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {ContainerMasterGatePassContext} from "./ContainerMaster";
import moment from "moment";

import _ from "lodash";


// Requests
const CollectionGatePassHeaders = [
    {
        label: 'Pass Number',
        key: 'pass_number',
        filter: true,
        size: 2,
        content: (product) => {
            return product.pass_number
        }
    },
    {
        label: 'Customer',
        key: 'customer_name',
        filter: true,
        size: 2,
        content: (product) => {
            return product.customer_name
        }
    }, {
        label: 'Issued on',
        key: 'issued_on',
        filter: true,
        filterValue: (product) => {
            return product.issued_on ? new Date(product.issued_on).toLocaleString() : '-'
        },
        size: 2,
        content: (product) => {
            return product.issued_on ? new Date(product.issued_on).toLocaleString() : '-'
        }
    }, {
        label: 'Revoked On',
        key: 'revoked_on',
        size: 2,
        filter: true,
        filterValue: (product) => {
            return product.revoked_on ? new Date(product.revoked_on).toLocaleString() : '-'
        },
        content: (product) => {
            return product.revoked_on ? new Date(product.revoked_on).toLocaleString() : '-'
        }
    },{
        label: 'Collections',
        key: 'collections',
        filter: true,
        filterValue:  pass => Object.values(pass.collections || {}).map(p=>p.collection_ref).join(','),
        size: 2,
        content: (pass) => {
            const collections = Object.values(pass.collections || {}).map(p=>p.collection_ref).join(',').split(',');
            if (collections.length === 0 || collections[0] === "") {
                return '-'
            }
            return <span className={'bg-primary m-1 p-1 text-light rounded'}
                         title={Object.values(pass.collections || {}).map(p=>p.collection_ref).join('\n')}>
                    {collections.pop()}
                {collections.length > 0 && <span style={{
                    fontSize: "0.8em",
                    fontWeight: 100,
                    marginLeft: "0.4em"
                }}>+{collections.length} more</span>}
                </span>
            //return <div style={{maxWidth: "200px", display: "grid"}}>{Object.values(product.collections||{}).map(c => <span key={`collection_cell_${c.id}`} className={'bg-primary m-1 p-1 text-light rounded'}>{c.collection_ref}</span>)}</div>
        }
    }, {
        label: 'Picking Lists',
        key: 'picking_lists',
        filter: true,
        size: 2,
        filterValue:  pass => Object.values(pass.picking_lists || {}).map(pl=>Object.values(pl.collections || {}).map(p=>p.collection_ref).join(',')).join(','),
        content: (product) => {
            return Object.values(product.picking_lists || {}).map(c => <span key={`pl_cell_${c.id}`}
                                                                             title={Object.values(c.collections || {}).map(p=>p.collection_ref).join("\n")}
                                                                             className={'bg-primary m-1 p-1 text-light rounded'}>{c.picking_list_number}</span>)
        }
    }
];
const DevanGatePassHeaders = [
    {
        label: 'Pass Number',
        key: 'pass_number',
        filter: true,
        size: 2,
        content: (product) => {
            return product.pass_number
        }
    },
    {
        label: 'Customer',
        key: 'customer_name',
        filter: true,
        size: 2,
        content: (product) => {
            return product.customer_name
        }
    }, {
        label: 'Issued on',
        key: 'issued_on',
        filter: true,
        filterValue: (product) => {
            return product.issued_on ? new Date(product.issued_on).toLocaleString() : '-'
        },
        size: 2,
        content: (product) => {
            return product.issued_on ? new Date(product.issued_on).toLocaleString() : '-'
        }
    }, {
        label: 'Revoked On',
        key: 'revoked_on',
        filter: true,
        filterValue: (product) => {
            return product.revoked_on ? new Date(product.revoked_on).toLocaleString() : '-'
        },
        size: 2,
        content: (product) => {
            return product.revoked_on ? new Date(product.revoked_on).toLocaleString() : '-'
        }
    },{
        label: 'Outturns',
        key: 'outturns',
        filter: true,
        filterValue:  pass => Object.values(pass.outturns || {}).map(p=>p.container_number).join(','),
        size: 2,
        content: (pass) => {
            const collections = Object.values(pass.outturns || {}).map(p=>p.container_number).join(',').split(',');
            if (collections.length === 0 || collections[0] === "") {
                return '-'
            }
            return <span className={'bg-primary m-1 p-1 text-light rounded'}
                         title={Object.values(pass.outturns || {}).map(p=>p.container_number).join('\n')}>
                    {collections.pop()}
                {collections.length > 0 && <span style={{
                    fontSize: "0.8em",
                    fontWeight: 100,
                    marginLeft: "0.4em"
                }}>+{collections.length} more</span>}
                </span>
            //return <div style={{maxWidth: "200px", display: "grid"}}>{Object.values(product.collections||{}).map(c => <span key={`collection_cell_${c.id}`} className={'bg-primary m-1 p-1 text-light rounded'}>{c.collection_ref}</span>)}</div>
        }
    }
];
const withGatePasses = Component => {
    class WithGatePasses extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                start:  moment().subtract(4,'week').toDate().getTime(),
                end:  moment().add(1,'week').toDate().getTime(),
                loading_requests: true,
                loading_outturns: true,
                devan_gate_passes: {
                    all:{},
                    sorted: [],
                    data:[],
                    exportData: [],
                    filter: '',
                    loading: true,
                    headers: DevanGatePassHeaders,
                    customer_filter: {
                        filter: ['all'],
                        filter_options: ['all'],
                        updateCustomerFilter: (filter) => {
                            const {devan_gate_passes} = this.state;
                            if (devan_gate_passes.customer_filter.filter.indexOf(filter) > -1) {
                                devan_gate_passes.customer_filter.filter = devan_gate_passes.customer_filter.filter.filter(s => s !== filter)
                            } else {
                                devan_gate_passes.customer_filter.filter.push(filter);
                            }
                            this.setState({devan_gate_passes: devan_gate_passes}, ()=>this.filterGatePasses(devan_gate_passes, null, false))
                        },
                        selectAll: () => {
                            const {devan_gate_passes} = this.state;

                            devan_gate_passes.customer_filter.filter = devan_gate_passes.customer_filter.filter_options;

                            this.setState({devan_gate_passes: devan_gate_passes}, ()=>this.filterGatePasses(devan_gate_passes, null, false))
                        },
                        deselectAll: () => {
                            const {devan_gate_passes} = this.state;

                            devan_gate_passes.customer_filter.filter = [];

                            this.setState({devan_gate_passes: devan_gate_passes}, ()=>this.filterGatePasses(devan_gate_passes, null, false))
                        }
                    },
                    extra_date_filters: {
                        issued_on: {
                            filter: 'any',
                            filter_options: ['never', 'any', 'today', 'last_week', 'this_week', 'custom'],
                            between_start: moment().subtract(4,'week').toDate().getTime(),
                            between_end:  moment().add(1,'week').toDate().getTime(),
                            setDateFilter: ({filter, start, end}) => {
                                const {devan_gate_passes} = this.state;
                                devan_gate_passes.extra_date_filters.issued_on.between_start = start;
                                devan_gate_passes.extra_date_filters.issued_on.between_end = end;
                                devan_gate_passes.extra_date_filters.issued_on.filter = filter;
                                this.setState({devan_gate_passes: devan_gate_passes}, ()=>this.filterGatePasses(devan_gate_passes, null, false))
                            }
                        },
                        revoked_on: {
                            filter: 'never',
                            filter_options: ['never', 'any', 'today', 'last_week', 'this_week', 'custom'],
                            between_start: moment().subtract(4,'week').toDate().getTime(),
                            between_end:  moment().add(1,'week').toDate().getTime(),
                            setDateFilter: ({filter, start, end}) => {
                                const {devan_gate_passes} = this.state;
                                devan_gate_passes.extra_date_filters.revoked_on.between_start = start;
                                devan_gate_passes.extra_date_filters.revoked_on.between_end = end;
                                devan_gate_passes.extra_date_filters.revoked_on.filter = filter;
                                this.setState({devan_gate_passes: devan_gate_passes}, ()=>this.filterGatePasses(devan_gate_passes, null, false))
                            }
                        }
                    },
                    sort: 'pass_number',
                    setSort: (sort) => {
                        const requests = {...this.state.devan_gate_passes};
                        if (requests.sort !== sort) {
                            requests.sort = sort;
                        } else {
                            requests.reverse *= -1;
                        }
                        requests.sorted = this.getSortedData(Object.values(requests.all), requests.sort, requests.reverse);

                        this.setState({devan_gate_passes: requests}, () => {
                            this.filterGatePasses(requests);
                        })

                    },
                    reverse: 1,
                    setDate: (start, end, callback) => this.setState(
                        {
                            start: start,
                            end: end
                        }, () => {
                            this.unmountDatedSnapshot();
                            this.fetchDatedSnapshot(callback);
                        }
                    )
                },
                gate_passes: {
                    all:{},
                    sorted: [],
                    data:[],
                    exportData: [],
                    filter: '',
                    loading: true,
                    headers: CollectionGatePassHeaders,
                    customer_filter: {
                        filter: ['all'],
                        filter_options: ['all'],
                        updateCustomerFilter: (filter) => {
                            const {gate_passes} = this.state;
                            if (gate_passes.customer_filter.filter.indexOf(filter) > -1) {
                                gate_passes.customer_filter.filter = gate_passes.customer_filter.filter.filter(s => s !== filter)
                            } else {
                                gate_passes.customer_filter.filter.push(filter);
                            }
                            this.setState({gate_passes: gate_passes}, ()=>this.filterGatePasses(gate_passes, null, true))
                        },
                        selectAll: () => {
                            const {gate_passes} = this.state;

                            gate_passes.customer_filter.filter = gate_passes.customer_filter.filter_options;

                            this.setState({gate_passes: gate_passes}, ()=>this.filterGatePasses(gate_passes, null, true))
                        },
                        deselectAll: () => {
                            const {gate_passes} = this.state;

                            gate_passes.customer_filter.filter = [];

                            this.setState({gate_passes: gate_passes}, ()=>this.filterGatePasses(gate_passes, null, true))
                        }
                    },
                    extra_date_filters: {
                      issued_on: {
                          filter: 'any',
                          filter_options: ['never', 'any', 'today', 'last_week', 'this_week', 'custom'],
                          between_start: moment().subtract(4,'week').toDate().getTime(),
                          between_end:  moment().add(1,'week').toDate().getTime(),
                          setDateFilter: ({filter, start, end}) => {
                              const {gate_passes} = this.state;
                              gate_passes.extra_date_filters.issued_on.between_start = start;
                              gate_passes.extra_date_filters.issued_on.between_end = end;
                              gate_passes.extra_date_filters.issued_on.filter = filter;
                              this.setState({gate_passes: gate_passes}, ()=>this.filterGatePasses(gate_passes, null, true))
                          }
                      },
                        revoked_on: {
                            filter: 'never',
                            filter_options: ['never', 'any', 'today', 'last_week', 'this_week', 'custom'],
                            between_start: moment().subtract(4,'week').toDate().getTime(),
                            between_end:  moment().add(1,'week').toDate().getTime(),
                            setDateFilter: ({filter, start, end}) => {
                                const {gate_passes} = this.state;
                                gate_passes.extra_date_filters.revoked_on.between_start = start;
                                gate_passes.extra_date_filters.revoked_on.between_end = end;
                                gate_passes.extra_date_filters.revoked_on.filter = filter;
                                this.setState({gate_passes: gate_passes}, ()=>this.filterGatePasses(gate_passes, null, true))
                            }
                        }
                    },
                    sort: 'pass_number',
                    setSort: (sort) => {
                        const requests = {...this.state.gate_passes};
                        if (requests.sort !== sort) {
                            requests.sort = sort;
                        } else {
                            requests.reverse *= -1;
                        }
                        requests.sorted = this.getSortedData(Object.values(requests.all), requests.sort, requests.reverse);

                        this.setState({gate_passes: requests}, () => {
                            this.filterGatePasses(requests, null, true);
                        })

                    },
                    reverse: 1,
                    setDate: (start, end, callback) => this.setState(
                        {
                            start: start,
                            end: end
                        }, () => {
                            this.unmountDatedSnapshot();
                            this.fetchDatedSnapshot(callback);
                        }
                    )
                },

            };
            this.filterGatePasses = this.filterGatePasses.bind(this)
        }

        applyFilter(item, {headers, filter, status_filters, status_key, extra_date_filters, customer_filter}) {

            let matched = headers.filter(h => !!h.filter).find(h => {
                const {key} = h;
                if(h.filterValue) {
                    return(h.filterValue(item).toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
                }
                return item[key] && (item[key].toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
            });
            let status_matched = true;
            if(status_filters) {
                status_matched = status_filters.indexOf(item[status_key]) > -1
            }
            let matched2 = true;
            if(extra_date_filters) {
                matched2 = Object.keys(extra_date_filters).map(k => {
                    let matches_date_filters = false;
                    if(extra_date_filters[k].filter === 'any') {
                        matches_date_filters = true;
                    } else if(extra_date_filters[k].filter === 'never') {
                        return !item[k]
                    } else if(extra_date_filters[k].filter === 'today'){
                        const start = moment().startOf('day').toDate().getTime();
                        const end = moment().endOf('day').toDate().getTime();
                        return parseInt(item[k]) >= start && parseInt(item[k]) <= end
                    } else if(extra_date_filters[k].filter === 'this_week'){
                        const start = moment().startOf('week').toDate().getTime();
                        const end = moment().endOf('week').toDate().getTime();
                        return parseInt(item[k]) >= start && parseInt(item[k]) <= end
                    } else if (extra_date_filters[k].filter === 'custom') {
                        return parseInt(item[k]) >= extra_date_filters[k].between_start && parseInt(item[k]) <= extra_date_filters[k].between_end
                    }
                    else if (extra_date_filters[k].filter === 'last_week') {
                        const start = moment().startOf('week').subtract(1, 'week').toDate().getTime();
                        const end = moment().endOf('week').subtract(1, 'week').toDate().getTime();
                        return parseInt(item[k]) >= start && parseInt(item[k]) <= end
                    }
                    return matches_date_filters;
                }).reduce((a,b)=>a&&b, true)
            }
            let matched3 = true;
            if(customer_filter) {
                if(!(customer_filter.filter.indexOf(item['customer_name']) > -1)) {
                    matched3 = false;
                }
            }
            return matched3 && matched2 && !!(matched) && status_matched;
        }

        fetchDatedSnapshot(callback) {
            const {firebase, organisation_id} = this.props;
            const {start, end} = this.state;
            this.unmountDatedSnapshot();
            if (!organisation_id)
                this.setState({uid: null, loading: false});
            else {
                    const customerRef = firebase.db.ref(DATABASE_REFS.organisations.customers(organisation_id));
                customerRef.once('value').then((result_snap) => {
                    this.setState({customers: result_snap.val()})
                    const {devan_gate_passes, gate_passes} = this.state;
                    const customer_names = Object.values(result_snap.val() || {}).map(customer=>customer.name);
                    devan_gate_passes.customer_filter.filter = [ ...customer_names];
                    devan_gate_passes.customer_filter.filter_options = [ ...customer_names];
                    gate_passes.customer_filter.filter = [ ...customer_names];
                    gate_passes.customer_filter.filter_options = [ ...customer_names];
                    this.setState({devan_gate_passes: devan_gate_passes}, ()=>this.filterGatePasses(devan_gate_passes, null, false))
                    this.setState({gate_passes: gate_passes}, ()=>this.filterGatePasses(gate_passes, null, true))
                });
                customerRef.on('value',(result_snap) => {
                    this.setState({customers: result_snap.val()})
                    const {devan_gate_passes, gate_passes} = this.state;
                    const customer_names = Object.values(result_snap.val() || {}).map(customer=>customer.name);
                    devan_gate_passes.customer_filter.filter = ['all', ...customer_names];
                    devan_gate_passes.customer_filter.filter_options = ['all', ...customer_names];
                    gate_passes.customer_filter.filter = ['all', ...customer_names];
                    gate_passes.customer_filter.filter_options = ['all', ...customer_names];
                    this.setState({devan_gate_passes: devan_gate_passes}, ()=>this.filterGatePasses(devan_gate_passes, null, false))
                    this.setState({gate_passes: gate_passes}, ()=>this.filterGatePasses(gate_passes, null, true))
                });

                    const ref = firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).orderByChild('created_on').startAt(start).endAt(end);
                    ref.once('value').then((result_snap) => {
                        this.updateData(result_snap, ()=> {
                            if(callback) {
                                callback();
                            }
                            ref.on('value',(result_snap) => {
                                this.updateData(result_snap, ()=>console.debug("Updated"));
                            });
                        });
                    });
                    const ref2 = firebase.db.ref(DATABASE_REFS.organisations.devan_gate_passes(organisation_id)).orderByChild('created_on').startAt(start).endAt(end);
                    ref2.once('value').then((result_snap) => {
                        this.updateData(result_snap, ()=> {
                            if(callback) {
                                callback();
                            }
                            ref2.on('value',(result_snap) => {
                                this.updateData(result_snap, ()=>console.debug("Updated"));
                            });
                        });
                    });


            }
        }

        async updateData(result_snap, callback){
            const {firebase, organisation_id} = this.props;
            const forecast = _.cloneDeep(this.state[result_snap.key]);
            forecast.all = result_snap.val() || {};
            for(let k of Object.keys(forecast.all)) {
                forecast.all[k].id=k;
                const collections = forecast.all[k].collections || {};
                const picking_lists = forecast.all[k].picking_lists || {};
                const outturns = forecast.all[k].outturns || {};
                const collection_promises = Object.keys(collections || {}).map(o=>firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).child(o).once('value'));
                const results = await Promise.all(collection_promises);
                let total = 0;
                let can_revoke = true;
                results.forEach(r=>{
                    if(r.val()) {
                        forecast.all[k].collections[r.key] = r.val();
                        total+=parseInt(r.val().crates_val);
                        const coll = r.val();
                        if(coll.stocked_out) {
                            can_revoke = false;
                        }
                    }
                });
                const pl_promises = Object.keys(picking_lists || {}).map(o=>firebase.db.ref(DATABASE_REFS.organisations.bulk.picking(organisation_id)).child(o).once('value'));
                const results2 = await Promise.all(pl_promises);
                for(let r of results2) {
                    forecast.all[k].picking_lists[r.key] = {...r.val(), id: r.key};
                    const cres = r.val()

                    const collection_promises = Object.keys((cres || {}).collections || {}).map(o=>firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).child(o).once('value'));
                    const results = await Promise.all(collection_promises);

                    results.forEach(r2=>{
                        if(r2.val()) {
                            forecast.all[k].picking_lists[r.key].collections[r2.key] = r2.val();
                            total+=parseInt(r2.val().crates_val);
                            if(r2.val().stocked_out) {
                                can_revoke = false;
                            }
                        }
                    });
                }
                const outturn_promises = Object.keys(outturns || {}).map(o=>firebase.db.ref(DATABASE_REFS.organisations.summaries.outturn(organisation_id)).child(o).once('value'));
                const results3 = await Promise.all(outturn_promises);
                results3.forEach(r=>{
                    if(r.val()) {
                        forecast.all[k].outturns[r.key] = r.val();
                        total+=parseInt(r.val().crates_val);
                        if(r.val().stocked_in) {
                            can_revoke = false;
                        }
                    }
                });
                forecast.all[k].can_revoke = can_revoke;
                forecast.total = total;
            }
            forecast.sorted = this.getSortedData(Object.values(forecast.all), forecast.sort, forecast.reverse);
            forecast.data = this.getFilteredData(forecast.sorted, forecast.filter,this.applyFilter, forecast);
            forecast.exportData = this.getExportData(forecast.data, forecast.headers);
            forecast.loading = false;

            if(this._is_mounted)
                this.setState({
                [result_snap.key]: forecast
            }, callback)
        }


        getExportData(data) {
            const exportData = [];
            data.map(d => {
                    const {issued_on, revoked_on, haulier, driver, vehicle_reg, notes,
                        pass_number, collections, picking_lists, outturns, total, customer_name, customer_code} = d;
                    const res = {};

                    res.pass_number = pass_number;
                    res.customer = customer_name;
                    res.customer_code = customer_code;
                    res.crates = total;
                    res.driver = driver;
                    res.haulier = haulier;
                    res.notes = notes;
                    res.issued_on = issued_on ? new Date(parseInt(issued_on)).toLocaleString() : '-';
                    res.revoked_on = revoked_on ? new Date(parseInt(revoked_on)).toLocaleString() : '-';
                    res.vehicle_reg = vehicle_reg;
                    if(collections || picking_lists) {
                        res.collections = Object.values(collections || {}).map(c => c.collection_ref).join(",");
                        res.picking_lists = Object.values(picking_lists || {}).map(pl => `${pl.picking_list_number}(${Object.values(pl.collections || {}).map(c=>c.collection_ref).join(',')})`).join(',')
                    }
                    if(outturns) {
                        res.outturns = Object.values(outturns || {}).map(c => c.container_number).join(",")
                    }
                    return exportData.push(res);

            });
                return exportData;
        }

        setDevanGatePassFilter(filter, callback) {
            const existing_collections = {...this.state.devan_gate_passes};
            existing_collections.filter = filter;
            this.setState({devan_gate_passes: existing_collections}, () => {
                this.filterGatePasses(existing_collections, callback, false)
            })
        }
        setCollectionGatePassFilter(filter, callback) {
            const existing_collections = {...this.state.gate_passes };
            existing_collections.filter = filter;
            this.setState({['gate_passes']: existing_collections}, () => {
                this.filterGatePasses(existing_collections, callback, true)
            })
        }

        getSortedData(data_to_use, sort, reverse) {

            return data_to_use.sort((a, b) => {

                const split_sort = sort.split(',');
                if (split_sort.length > 1) {
                    const a_val = a[split_sort[0]] === '-' || a[split_sort[0]] === '' ? a[split_sort[1]] : a[split_sort[0]];
                    const b_val = b[split_sort[0]] === '-' || a[split_sort[0]] === '' ? b[split_sort[1]] : b[split_sort[0]];
                    return ((a_val < b_val || !b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
                }
                const a_val = typeof (a[sort]) === 'string' ? a[sort].toLowerCase() : a[sort];
                const b_val = typeof (b[sort]) === 'string' ? b[sort].toLowerCase() : b[sort];
                if(a_val && !b_val) {
                    return -1 * reverse;
                }
                if(b_val && !a_val) {
                    return 1 * reverse;
                }
                if(!b_val && !a_val) {
                    return 0;
                }

                return ((a_val < b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
            });

        }

        getFilteredData(data_to_filter, filter, filter_function, options) {
            return data_to_filter.filter((a) => {
                if(this.state.customers && this.state.customers[a.customer]) {
                    a.customer_name = this.state.customers[a.customer].name;
                    a.customer_code = this.state.customers[a.customer].code;
                }
                return filter_function(a, options);
            })
        }

        filterGatePasses(options, callback, collections) {
            const existing_collections = {...this.state[collections ? 'gate_passes' : 'devan_gate_passes']};
            existing_collections.data = this.getFilteredData( existing_collections.sorted, existing_collections.filter, this.applyFilter, existing_collections);
            existing_collections.exportData = this.getExportData(existing_collections.data, existing_collections.headers);
            this.setState({[collections ? 'gate_passes' : 'devan_gate_passes']: existing_collections}, callback)
        }

        componentDidMount() {
            this._is_mounted = true;
            this.fetchDatedSnapshot();
        }

        unmountDatedSnapshot() {
            const {firebase, organisation_id} = this.props;
            const {start, end} = this.state;
            if (!organisation_id) return;
            firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).orderByChild('created_on').startAt(start).endAt(end).off('value', this.updateData);
            firebase.db.ref(DATABASE_REFS.organisations.devan_gate_passes(organisation_id)).orderByChild('created_on').startAt(start).endAt(end).off('value', this.updateData);
        }

        componentWillUnmount() {
            this._is_mounted = false;
            this.unmountDatedSnapshot();
        }

        render() {
            const loading = this.state.gate_passes.loading || this.state.devan_gate_passes.loading;
            if (loading) {
                return <LoadingScreen/>
            }

            return (
                <ContainerMasterGatePassContext.Provider value={this.state}>
                    <Component {...this.props} filters={{devan_custom_filter: this.state.devan_gate_passes.customer_filter.filter, devan_gate_passes:this.state.devan_gate_passes.filter,gate_passes:this.state.gate_passes.filter}}
                               setCollectionGatePassFilter={this.setCollectionGatePassFilter.bind(this)}
                               setDevanGatePassFilter={this.setDevanGatePassFilter.bind(this)}

                    />
                </ContainerMasterGatePassContext.Provider>
            );
        }
    }

    return withFirebase(WithGatePasses);
};

export default withGatePasses;