import React from "react";
import PageTemplate from "../PageTemplate";
import ErrorMessage from "../../../elements/ErrorMessage";

import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import ProductForm from "../Products/ProductForm";
import ProductTable from "../Products/ProductTable";
import {ContainerMasterDataContext} from "../../../components/ContainerMaster/ContainerMaster";
import {DATABASE_REFS} from "../../../constants/db_refs";
import {Button} from "react-bootstrap";
import Hotbar from "../../../elements/Hotbar";

class Products extends PageTemplate {
    constructor(props) {
        super(props);
        this.state = {
            filter: ''
        };
        this.getBaysForProduct = this.getBaysForProduct.bind(this);
        this.clearOldBays = this.clearOldBays.bind(this)
    }

    downloadAllProductData(customers, customer_filter) {
        const {firebase, organisation_id} = this.props;
        const products_ref = DATABASE_REFS.organisations.forecasting.record(organisation_id, 'products');
        firebase.db.ref(products_ref).once('value', (result) => {

            let output = [['status', "date", 'event', 'source', 'destination', 'change', 'product']];
            const items = result.val();
            Object.keys(items).map(product_id => {
                return Object.keys(items[product_id]).filter(k=>k!=="totals").map(status=>{
                    return Object.keys(items[product_id][status]).map(movement_id => {


                        const {eventDate, event, source, destination, change, product_code, customer} = items[product_id][status][movement_id];
                        if(customer && customers[customer]) {
                            if(customer_filter.filter.indexOf(customers[customer].name) === -1) {
                                return;
                            }
                        }

                        output.push( [status, new Date(eventDate).toISOString(), event, source.replace(/,/g, ":"), destination, change, product_code].join(',').replace('\n', ''))
                    })
                })
            })
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(output.join('\n')));
            element.setAttribute('download', 'product_dump_'+(new Date().toISOString())+'.csv');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        })
    }


    getBaysForProduct(bays, product_id, customer_id) {
        return Object.values(bays).filter(bay => bay.product === product_id && bay.customer === customer_id).map(bay => bay.code)
    }

    applyFilter(products, customers, customer_filter) {
        const {filter} = this.state;
        const filteredProducts =  Object.values(products).filter((product) => {

            const {code,description,customer,last_reference} = product;
            return code.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                description.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                customers[customer].name.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                (!!last_reference && last_reference.toLowerCase().indexOf(filter.toLowerCase()) > -1 )
        });
        return filteredProducts.filter(product => {
            const customer_name = customers[product.customer].name;
            return customer_filter.filter.indexOf(customer_name) > -1;
        });
    }

    clearOldBays() {
        const archiveFunc = this.props.firebase.functions.httpsCallable('clearMissingBaysFromProducts');
        archiveFunc({"organisation_id": this.props.organisation_id})
    }

    render() {

        let is_loading = true;
        const {updatePage, organisation_id, firebase} = this.props;

        return <div>
            <Hotbar updatePage={updatePage} page={'products'}/>
            <ContainerMasterDataContext.Consumer>
            {({customers, products, bays, products_loaded, product_filters}) => {
                is_loading = !products_loaded;
                const customer_list = Object.keys(customers || {}).map(customer => {
                    return {
                        id: customer,
                        ...customers[customer]
                    }
                });
                const product_list = Object.keys(products || {}).map(product => {
                    return {
                        id: product,
                        ...products[product]
                    }
                });
                return <div className={'col-xs-12 col-sm-11 ml-auto mr-auto bg-primary pt-2 pb-2 mt-1'}>

                        <h3 className={'col-xs-9 text-light text-center'}>Products {customer_list && customer_list.length > 0 && !is_loading && <Button className={'no-print float-right'} variant={"info"} onClick={()=>this.downloadAllProductData.bind(this)(customers, product_filters.customer_filter)}>Download All Data</Button>}</h3>
                        {(!customer_list || customer_list.length === 0) && !is_loading && <ErrorMessage
                            message={"Whoops, no customers"}
                            details={"You must create a customer before you can create any products."}
                            link={
                                <button
                                    className={'btn btn-tertiary'}
                                    data-page='customers'
                                    onClick={updatePage}
                                >
                                    Create one now!
                                </button>}

                        />}

                        {is_loading && <LoadingScreen/>}
                        {customer_list && customer_list.length > 0  && !is_loading && <ProductForm
                            customers={customers}
                            products={product_list}
                            organisation_id={organisation_id}
                            firebase={firebase}
                        />}

                        {customer_list && customer_list.length > 0 && !is_loading && <ProductTable
                            setFilter={this.setFilter}
                            organisation_id={organisation_id}
                            bays={bays}
                            firebase={firebase}
                            total={product_list.length}
                            filter={this.state.filter}
                            customer_filter={product_filters.customer_filter}
                            customers={customers}
                            all_products={products}
                            products={this.applyFilter(product_list, customers, product_filters.customer_filter)}
                           />
                        }
                    </div>


            }}
            </ContainerMasterDataContext.Consumer>
        </div>

    }
}


export default Products;