import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import * as PropTypes from 'prop-types';

const SortButton = ({reverse, sort, current_sort}) => {
    const upstyleClass = (current_sort === sort && reverse > 0) ? ' text-primary' : ' text-secondary';
    const downstyleClass = (current_sort === sort && reverse < 0) ? ' text-primary' : ' text-secondary';
  return <div
      className={'interactive ml-3 float-right no-print'}
  >
      <span style={{pointerEvents: 'none'}} className="fa-layers fa-fw">
          <FontAwesomeIcon className={upstyleClass} icon={'sort-up'}/>
          <FontAwesomeIcon className={downstyleClass} style={{pointerEvents: 'none'}} icon={'sort-down'}/>
      </span>
  </div>
};

SortButton.propTypes = {
    sort: PropTypes.oneOfType([PropTypes.string,PropTypes.array]),
    current_sort: PropTypes.string,
    reverse: PropTypes.number
};
export default SortButton;