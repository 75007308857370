import React from 'react';

import ConfirmPrompt from "../../elements/ConfirmPrompt";


class InstallationPrompt extends React.Component {
    constructor(props) {
        super(props);
        this.state = { installButton: false };
        this.onClick = this.onClick.bind(this);
        this.cancelInstall = this.cancelInstall.bind(this);
    }
    installPrompt = null;
    componentDidMount(){
        window.addEventListener('beforeinstallprompt',e=>{
            // For older browsers
            e.preventDefault();
            this.installPrompt = e;
            // See if the app is already installed, in that case, do nothing
            // noinspection JSUnresolvedVariable
            if((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true){
                return false;
            }
            // Set the state variable to make button visible
            this.setState({
                installButton:true
            })
        })
    }
    onClick() {
        // noinspection JSUnresolvedFunction
        this.installPrompt.prompt();
        this.setState({installButton: false});
    }
    cancelInstall() {
        this.setState({installButton: false});
    }
    render() {
        return (
            <section data-testid={'installation_prompt'}>
                {this.state.installButton && process.env.NODE_ENV === 'production' && <ConfirmPrompt message_id={"app.install_message"} onConfirmClick={this.onClick} onCancelClick={this.cancelInstall} cancel_title_id={"app.decline"} confirm_title_id={"app.install"}/> }
            </section>

        );
    }
}

InstallationPrompt.propTypes = {

};

export default InstallationPrompt;