import React from "react";

const capacity_units = {
    "square-meters": <span>m&sup2;</span>,
    "square-centimeters": <span>cm&sup2;</span> ,
    "kg": <span>kg</span>,
    "crates": <span>crates</span>
};

export {capacity_units}

