
import React, {useEffect, useRef, useState} from "react";
import {Badge, Button, Image} from "react-bootstrap";
import {ContainerMasterDataContext} from "../../../components/ContainerMaster/ContainerMaster";
import ReactToPrint from "react-to-print";
import DATABASE_REFS from "../../../constants/db_refs";

const PickingList = ({productView, collections, picking_list, firebase, organisation_id, products, compactView}) => {

    const printRef = useRef();
    return <div>
        <ReactToPrint content={()=>printRef.current} trigger={()=><Button variant={'info'} size={'sm'}>Print</Button>}/>
        <div style={{display: 'none'}}>
        {productView && <div id="bulk_list" ref={printRef}>
            {<ElectronicPickingList
                picking_list={picking_list}
                collections={collections}
                compactView={compactView}
                organisation_id={organisation_id}
                firebase={firebase}
                productView={productView}
                photos={null}
                total={Object.values(collections).reduce((a, b) => a + (b.crates ? b.crates : b.projected), 0)}


            />}
        </div>}
        {!productView && <div  id="bulk_list" ref={printRef}>
           <ElectronicPickingList
                picking_list={picking_list}
                collections={collections}
                organisation_id={organisation_id}
                firebase={firebase}
                products={products}
                photos={null}
                total={Object.values(collections || {}).reduce((a, b) => a +  parseInt((b.crates_val ? b.crates_val : b.projected)), 0)}
            />
        </div>}
    </div>
    </div>
};

const ElectronicPickingList = ({total, photos, collections, productView, picking_list, firebase, organisation_id, products, compactView}) => {
    const collection_refs = Object.values(collections || {}).map(c => c.collection_ref);

    return <React.Fragment>
        <h1 className={'mt-0 w-100 text-center'}>Electronic Collection</h1>
        <ElectronicPickingListTable collections={collections} picking_list={picking_list} collection_refs={collection_refs} firebase={firebase} organisation_id={organisation_id}/>
        {!productView && <PickingListCollectionView is_bulk={true} collections={collections} requests={collections} products={products}/>}
        {productView && <PickingListProductView collections={collections} compactView={compactView}/>}
        {photos && <h3>Photos</h3>}
        {photos && photos.map((photo) => {
            return <div className={'col-12'}><Image className={'img img-fluid m-1'} src={photo}/></div>
        })}
        <table className={'table table-sm table-bordered '}>
            <tfoot className={"table table-bordered table-sm print-footer"}>
            <tr>
                <td className={'bg-secondary text-center'}>Loaded by</td>
                <td className={'w-25 text-center'}>&nbsp;</td>
                <td className={'bg-secondary text-center'}>Checked by</td>
                <td className={'w-25 text-center'}>&nbsp;</td>
            </tr>
            <tr>
                <td className={'bg-secondary text-center'}>Haulier Crate Qty agreed*:</td>
                <td className={'w-25 text-center'}><span className={'small'} style={{verticalAlign: 'super'}}>Sign</span>&nbsp;</td>
                <td className={'w-25 text-center'}><span className={'small'} style={{verticalAlign: 'super'}}>Print</span>&nbsp;</td>

                <td className={'w-25 text-center'}><h1 className={'m-0'}>Crate Total: {total}</h1></td>
            </tr>
            <tr><td style={{border: 'none !important', fontSize:'8pt !important'}} colSpan={4}>* Hauliers please ensure you check the crate quantity of your load, you are signing for the stated amount, once signed and lorry left the compound you have taken responsibility of the exact quantity of crates.</td></tr>
            </tfoot>
       </table>
    </React.Fragment>
};
const ElectronicPickingListTable = ({picking_list, collections, firebase, organisation_id}) => {
    const {pass_id} = (picking_list ? picking_list : Object.values(collections).length > 0 ? Object.values(collections)[0] : {});
    const [gate_pass, setGatePass] = useState(null)
    const fetchPass = async () => {
        if (!pass_id) return;
        const pass_snap = await firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).child(pass_id).once('value');
        if(pass_snap.val()) {
            const pass = pass_snap.val();
           setGatePass(pass)
        }
    }
    useEffect(()=>{
        if(pass_id ) {
            fetchPass();
        }
    }, [pass_id])
    return <table id={"ec-table1"} className={'table-bordered table table-sm text-center'} style={{pageBreakInside: 'auto'}}>
                    <tbody>
                    <tr>
                        <td className={'w-50 bg-secondary text-center'}>Customer</td>
                        <td className={'w-50 text-center'}>{(picking_list || {}).customer_name}</td>
                    </tr>
                    {picking_list && <tr>
                        <td className={'bg-secondary text-center'}>Picking List
                        </td>
                        <td className={'w-50 text-center'}>{picking_list.picking_list_number}</td>
                    </tr>}
                    {picking_list && <tr>
                        <td className={'bg-secondary text-center'}>Creation Date</td>
                        <td className={'w-50 text-center'}>

                            <span>{new Date(parseInt(picking_list.updated_on)).toLocaleString()}</span>

                        </td>
                    </tr>}

                    {gate_pass && <tr>
                        <td className={'bg-secondary text-center'}>Collection Date</td>
                        <td className={'w-50 text-center'}>

                            <span>{new Date(parseInt(gate_pass.issued_on)).toLocaleString()}</span>

                        </td>
                    </tr>}
                    {gate_pass && <tr>
                        <td className={'bg-secondary text-center'}>Pass Number</td>
                        <td className={'w-50 text-center'}>
                            {gate_pass && <span>{gate_pass.pass_number}</span>}

                        </td>
                    </tr>}
                    </tbody>
                </table>
};

const PickingListCollectionView = ({collections, requests, is_bulk, products}) => {
    const getTotalForBays = (bays) => {
        return Object.values(bays || {}).reduce((a, b) => a + parseInt(b.qty), 0);
    };
    const all_products_var = products;

    return Object.keys(collections||{}).map(c => {

        return <ContainerMasterDataContext.Consumer key={`picking_view_${c}`}>
            {
                (data) => {
                    const products = collections[c].products.actual;

                    const all_products = all_products_var || data.products;
                    const bays = data.bays;
                    if(!all_products) return <div>Not loaded</div>;
                    return <table key={`print_table_product_${c}`}  style={{ pageBreakInside: 'avoid'}}
                                  className={'text-center table table-sm table-bordered print-body mb-2'}>
                        <tbody key={'picking_list_product_'}>
                       <tr>
                            <td colSpan={8}><Badge style={{fontSize: '1.3em'}}>{collections[c].collection_ref}</Badge>
                            </td>
                        </tr>

                        {Object.keys(products || {}).map((product) => {
                            const num_bays = Object.keys(products[product].crates || {}).length;
                            let filtered_bays = Object.keys(bays || {}).filter((bkey) => {
                                return (bays[bkey].product === product) || (collections[c].verified)
                            });
                            if (Object.keys(products[product].crates || {}).length > 0) {
                                filtered_bays = filtered_bays.filter(b => {
                                    return Object.keys(products[product].crates).indexOf(b.id) === -1
                                })
                            }

                            if (num_bays > 0 && (getTotalForBays(products[product].crates) >= collections[c].products.expected.find(p=>p.code===product) ? requests[c].products.expected.find(p=>p.code===product).crates  :-1)) {
                                filtered_bays = filtered_bays.filter(b => (!!products[product].crates) && (!!products[product].crates[b]))
                            }
                            if (!is_bulk)
                                return [<tr className={'text-center'}>

                                    <td colSpan={5} className={'w-50 emphasize text-left bg-secondary'}>{(Object.values(all_products || {}).find(ap => {
                                        return ap.code === product
                                    }) || {}).description}</td>




                                    <td style={{width: "7%"}} className={'emphasize text-center bg-secondary'}>{num_bays > 0 ? getTotalForBays(products[product].crates || {}):  requests[c].products.expected.find(p=>p.code===product) ? requests[c].products.expected.find(p=>p.code===product).crates : 0}</td>

                                </tr>,
                                <tr className={'bay-row'}>
                                    {filtered_bays.sort((a, b) => {
                                        return a.last_change - b.last_change
                                    }).slice(0, 3).map((b) => {
                                        const bay = bays[b];
                                        return [<td style={{width: "7%", fontSize: '1.5em !important'}} key={`${bay.id}_printed_box_label`}
                                                    className={'bg-secondary'}> <span className={'smaller-span'} style={{fontSize: '0.7em !important'}}>{bay.code}</span></td>,
                                            <td style={{width: "7%"}} key={`${bay.id}_printed_box_entry`}>
                                                {products[product].crates && products[product].crates[b] &&
                                                <span  className={'smaller-span'} style={{fontSize: '0.7em !important'}}>{products[product].crates[b].qty}</span>}
                                            </td>]
                                    })
                                    }
                                    {filtered_bays.length < 2 && [<td style={{width: "7%", fontSize: '1.5em !important'}}
                                                                      key={`1dummy_printed_box_label`}
                                                                      className={'bg-secondary'}>&nbsp;</td>,
                                        <td style={{width: "7%", fontSize: '1.5em !important'}} key={`1dummy_printed_box_entry`}>&nbsp;</td>]}
                                    {filtered_bays.length < 3 && [<td style={{width: "7%", fontSize: '1.5em !important'}}
                                                                      key={`2dummy_printed_box_label`}
                                                                      className={'bg-secondary'}>&nbsp;</td>,
                                        <td style={{width: "7%", fontSize: '1.5em !important'}} key={`2dummy_printed_box_entry`}>&nbsp;</td>]}
                                </tr>]
                            const product_record = Object.values(all_products || {}).find(ap => {
                                return ap.code === product
                            });
                            return  <tr key={`${c}_product_${product_record.code}`} className={'text-center'}>

                                <td className={'w-50 emphasize text-left'}>{product_record ? product_record.description : '-'}</td>

                                {filtered_bays.sort((a, b) => {
                                    return a.last_change - b.last_change
                                }).slice(0, 3).map((b) => {
                                    const bay = bays[b];
                                    return [<td style={{width: "7%"}} key={`${bay.id}_printed_box_label`}
                                                className={'bg-secondary'}>{bay.code}</td>,
                                        <td style={{width: "7%"}} key={`${bay.id}_printed_box_entry`}>
                                            {products[product].crates && products[product].crates[b] &&
                                            <span>{products[product].crates[b].qty}</span>}
                                        </td>]
                                })
                                }
                                {filtered_bays.length < 2 && [<td style={{width: "7%"}}
                                                                  key={`1dummy_printed_box_label`}
                                                                  className={'bg-secondary'}>&nbsp;</td>,
                                    <td style={{width: "7%"}} key={`1dummy_printed_box_entry`}>&nbsp;</td>]}
                                {filtered_bays.length < 3 && [<td style={{width: "7%"}}
                                                                  key={`2dummy_printed_box_label`}
                                                                  className={'bg-secondary'}>&nbsp;</td>,
                                    <td style={{width: "7%"}} key={`2dummy_printed_box_entry`}>&nbsp;</td>]}


                                <td style={{width: "7%"}} className={'emphasize text-center'}>{num_bays > 0 ? getTotalForBays(products[product].crates || {}):  requests[c].products.expected.find(p=>p.code===product) ? requests[c].products.expected.find(p=>p.code===product).crates : 0}</td>

                            </tr>

                        })}
                        </tbody></table>
                }

            }

        </ContainerMasterDataContext.Consumer>


    });
};

const PickingListProductView = ({collections, requests, compactView}) => {
    const getTotalForBays = (bays) => {
        return Object.values(bays || {}).reduce((a, b) => a + parseInt(b.qty), 0);
    };

    const products = {};
    Object.values(collections).map(c => {
        const list_products = c.products.actual ? c.products.actual : c.products.expected;
        Object.keys(list_products).map((p)=>{
            if(!products[p]) {
                products[p] = {collections:{},
                    customer: c.customer, total: 0}
            }
            products[p].collections[c.id] = {crates:list_products[p].crates, collection_ref: c.collection_ref};
            const qty = getTotalForBays(list_products[p].crates || {});
            products[p].total+= qty > 0 ? qty : list_products[p].crates;
            return true;
        });
        return true;
    });
    return Object.keys(products).map(p => {

        return <ContainerMasterDataContext.Consumer>
            {
                (data) => {
                    const {collections, customer} = products[p];

                    const all_products = data.products;
                    const bays = data.bays;
                    return <table key={`print_table_product_${p}`}
                                  className={'text-center table table-sm table-bordered mb-1'}>
                        <tr>
                            <td className={'emphasize text-left'} colSpan={2}>
                                {Object.values(all_products).find(ap => ap.code === p && ap.customer === customer).description}
                            </td>
                            <td className={"big-letters"} style={{maxWidth: '150px', width: '150px'}}>
                                <span>{compactView && "x"}{!compactView && "Total: "}{products[p].total}</span>
                            </td>
                        </tr>

                        {!compactView && Object.keys(collections).map((c) => {
                            const num_bays = Object.keys(collections[c].crates || {}).length;
                            let filtered_bays = Object.keys(bays).filter((bkey) => {
                                return bays[bkey].product === p
                            });
                            if (Object.keys(collections[c].crates || {}).length > 0) {
                                filtered_bays = filtered_bays.filter(b => {
                                    return Object.keys(collections[c].crates).indexOf(b.id) === -1
                                })
                            }

                            if (num_bays > 0 && (getTotalForBays(collections[c].crates) >=products[p].crates)) {
                                filtered_bays = filtered_bays.filter(b => (!!collections[c].crates) && (!!collections[c].crates[b]))
                            }

                            return <tbody>
                            <tr>
                                <td className={'w-25 text-left'}>{p}</td>
                                <td className={'emphasize text-left'}>{collections[c].collection_ref}</td>
                                <td className={'emphasize text-center'}>{num_bays > 0 ? getTotalForBays(collections[c].crates):  requests[c].products.find(prod=>prod.code===p).crates}</td>
                            </tr>
                            <tr>
                                <td colSpan={4} className={'p-0'}>
                                    <table className={'table table-sm m-0 no-border'}>
                                        <tbody>
                                        <tr>
                                            {filtered_bays.sort((a, b) => {
                                                return a.total - b.total
                                            }).slice(0, 4).map((b) => {
                                                const bay = bays[b];
                                                return [<td style={{width: "8%"}} key={`${bay.id}_printed_box_label`}
                                                            className={'bg-secondary'}>{bay.code}</td>,
                                                    <td style={{width: "8%"}} key={`${bay.id}_printed_box_entry`}>
                                                        {collections[c].crates && collections[c].crates[b] &&
                                                        <span>{collections[c].crates[b].qty}</span>}
                                                    </td>]
                                            })
                                            }
                                            {filtered_bays.length < 2 && [<td style={{width: "8%"}}
                                                                              key={`1dummy_printed_box_label`}
                                                                              className={'bg-secondary'}>&nbsp;</td>,
                                                <td style={{width: "8%"}} key={`1dummy_printed_box_entry`}>&nbsp;</td>]}
                                            {filtered_bays.length < 3 && [<td style={{width: "8%"}}
                                                                              key={`2dummy_printed_box_label`}
                                                                              className={'bg-secondary'}>&nbsp;</td>,
                                                <td style={{width: "8%"}} key={`2dummy_printed_box_entry`}>&nbsp;</td>]}
                                            {filtered_bays.length < 4 && [<td style={{width: "8%"}}
                                                                              key={`3dummy_printed_box_label`}
                                                                              className={'bg-secondary'}>&nbsp;</td>,
                                                <td style={{width: "8%"}} key={`3dummy_printed_box_entry`}>&nbsp;</td>]}
                                            <td className={'w-25 text-right'}>Checked</td>
                                            <td style={{width: '5%'}}>&nbsp;</td>

                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        })}
                    </table>
                }

            }

        </ContainerMasterDataContext.Consumer>


    });
}

export default PickingList;