import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import InstallationPrompt from "./components/InstallationPrompt/InstallationPrompt";
import ContainerMaster from "./components/ContainerMaster/ContainerMaster";


function App() {
  return (
    <div className="App bg-light" data-testid={"app-main"}>

    <div>

      <Router>
          <InstallationPrompt/>
          <ContainerMaster  />
      </Router>

    </div>
    </div>
  );
}
export { App };
export default App;
