import React from 'react';
import AuthUserContext from './context';
import {withFirebase} from '../Firebase';
import {DATABASE_REFS} from '../../constants/db_refs'
import {toast} from "react-toastify";
import {toastConfig} from "../../config/toast_config";
import organisations from "../../__fixtures__/organisations";

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
                settings: {},
                settings_loading: true,
                details_loading: true,
                organisation_id: null
            };
            this.logInWithUser = this.logInWithUser.bind(this)
            this.updateUserData = this.updateUserData.bind(this)
            this.changeOrganisation = this.changeOrganisation.bind(this)
        }

        getToken(currentToken, authUser) {
            const {firebase} = this.props;
            if (currentToken) {
                console.debug("Got a token, so do something with it");
                const token = localStorage.getItem('messaging_token');
                    if(token !== currentToken && !!token) {
                        firebase.db.ref('registrations').child(authUser.uid).child(token).set(null).then(() => {})
                    }

                this.setState({messaging_token: currentToken});
                firebase.db.ref('registrations').child(authUser.uid).child(currentToken).once('value').then((existing_snapshot) => {
                    if (!existing_snapshot.val() || !existing_snapshot.val().name) {
                        firebase.db.ref('registrations').child(authUser.uid).child(currentToken).set({
                            name: 'Unknown Device',
                            userAgent: navigator.userAgent,
                            registered_on: new Date().getTime(),
                            accessed_on: new Date().getTime()
                        }).then(() => {
                            localStorage.setItem('messaging_token', currentToken);
                            toast.success('Registered device for notifications', {...toastConfig})
                        });
                    } else {
                        firebase.db.ref('registrations').child(authUser.uid).child(currentToken).update({
                            accessed_on: new Date().getTime()
                        }).then(() => {

                        });
                    }

                })

            } else {
                // Show permission request.
                console.debug('No Instance ID token available. Request permission to generate one.');
                // Show permission UI.
            }

        }
        changeOrganisation(oid) {
            if(oid !== this.state.organisation_id) {

            }
            this.setState({organisation_id: oid}, this.updateUserData)

        }

        async updateUserData  () {
            // Fetch user details

            /*
            1. User Type:  Staff/Customer
            2. Default Organisation
            3. Current Role
            4. Organisations
             */
            const {firebase} = this.props;
            const authUser = firebase.auth.currentUser;
            const user_organisation_ref = DATABASE_REFS.user.organisations(authUser.uid);
            firebase.db.ref(user_organisation_ref).off();
            const user_organisations = await firebase.db.ref(user_organisation_ref).once("value");
            const user_settings_ref = DATABASE_REFS.user.settings(authUser.uid);
            const user_settings = await firebase.db.ref(user_settings_ref).once("value");
            const user_details_ref = DATABASE_REFS.user.profile(authUser.uid);
            const user_details = await firebase.db.ref(user_details_ref).once("value");
            const settings = user_settings.val() || {};
            const details = user_details.val() || {};
            const organisations = user_organisations.val();
            let {default_organisation} = settings;
            if (!default_organisation) {
                if (Object.keys(organisations || {}).length > 0) {
                    default_organisation = Object.keys(organisations)[0]
                }
            }

            if (default_organisation) {
                if(!organisations) {
                    firebase.db.ref(user_organisation_ref).on('value', () => this.updateUserData())
                }
                const role = (organisations||{})[this.state.organisation_id || default_organisation]
                const uid = authUser.uid;
                const {displayName, email} = details;
                let organisation = null;
                if (role) {
                    const organisation_snap = await firebase.db.ref(DATABASE_REFS.organisations.details(this.state.organisation_id || default_organisation)).once('value');
                    organisation = organisation_snap.val();
                }
                this.setState({
                    changeOrganisation: this.changeOrganisation, updateUserData: this.updateUserData, role, uid, displayName, email, organisation_id: this.state.organisation_id || default_organisation, default_organisation, organisation, loading: false
                })
            } else {
                this.setState({changeOrganisation: this.changeOrganisation, updateUserData: this.updateUserData, uid: authUser.uid, loading: false, settings: user_settings.val(), default_organisation, displayName: user_details.val().displayName});
            }
    }

        async logInWithUser(authUser) {
            const {firebase} = this.props;
            if(!authUser)
                this.setState({uid: null, loading: false, settings: {}, displayName: '', organisation_id:null, default_organisation: null, organisation: null});
            else {
                if(firebase.token) {
                   // this.getToken(firebase.token, authUser)
                }
               await this.updateUserData()

            }
        }

        componentDidMount() {
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                this.logInWithUser
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state}>
                   <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }
    return withFirebase(WithAuthentication);
};
export default withAuthentication;