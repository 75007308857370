import React, {useEffect, useState} from "react";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import ErrorMessage from "../../elements/ErrorMessage";

import db_refs from "../../constants/db_refs";
import Hotbar from "../../elements/Hotbar";
import {Col} from "react-bootstrap";
import Menu from "../../pages/authenticatedPages/Menu";
import 'react-clock/dist/Clock.css';
import moment from "moment";

import ProductView from "./products";
import LiveTracker from "./livetracker";
import {CustomerForecasting, CustomerReporting} from "./context";
import {ArchiveList} from "../Archives";


const pages = {
    menu: Menu,
    products: ProductView,
    reporting: CustomerReporting,
    tracking: CustomerForecasting,
    livetracker: LiveTracker,
    archives: (props)=><div>
        <Hotbar updatePage={props.updatePage} page={'archives'}/>
        <h2 className={'w-100 text-center'}>{props.organisation.name}@ContainerMaster Archives</h2>
        <ArchiveList {...props}/>
    </div>,
};


const CustomerApp = ({firebase, uid, role, organisation_id, organisation}) => {
    const [customer, setCustomer] = useState(null)
    const [customers, setCustomers] = useState({})
    const [error, setError] = useState(null)
    const [page, updatePage] = useState('livetracker')
    const [loading, setLoading] = useState({products: true})
    const [products, setProducts] = useState([])

    useEffect(() => {
        if (!organisation_id) return;
        const fetchData = async ()=>{
        const customer_id = await firebase.db.ref(db_refs.user.customers(uid)).child(organisation_id).once('value')
        if (!customer_id.val()) {
            setError("No customer found")
        } else {
            const customer = await firebase.db.ref(db_refs.organisations.customers(organisation_id)).child(customer_id.val()).once('value')
            setCustomer({id: customer.key, ...customer.val()})
            const customers = await firebase.db.ref(db_refs.organisations.customers(organisation_id)).once('value')
            setCustomers(customers.val())
        }};
        fetchData().then(() => {});

    }, [organisation_id]);

    const updateProductDate = (product_key, record) => {
        const last_activity = record.val();
        let last_activity_text = "Never";
        if (last_activity) {
            const eventDate = Object.values(last_activity).pop().eventDate;
            last_activity_text = moment(new Date(eventDate)).fromNow()
        }
        setProducts((products) => {
            products.find(product => product.id === product_key).last_active = last_activity_text;
            return [...products]
        })
    };

    const updateProductTotals = (product_key, total_snap) => {
        const totals = total_snap.val() || {
            promised: 0,
            pending: 0,
            stocking_in: 0,
            stocking_out: 0,
            current: 0,
        };

        setProducts((products) => {
            products.find(product => product.id === product_key).current = (totals.current+totals.stocking_in) - totals.stocking_out;
            products.find(product => product.id === product_key).in = totals.pending;
            products.find(product => product.id === product_key).out = totals.promised;
            return [...products]
        })
    };

    const addOrUpdateProduct = async (product) => {
        const product_data = product.val();
        if (product_data.customer !== customer.id) return
        const product_key = product.key;
        firebase.db.ref(db_refs.organisations.forecasting.totals(organisation_id, 'products', product_key)).off();
        firebase.db.ref(db_refs.organisations.forecasting.completed(organisation_id, 'products', product_key)).orderByChild('eventDate').limitToLast(1).off()


        const new_product = {
            id: product.key,
            code: product_data.code,
            description: product_data.description,
            current: 0,
            in: 0,
            out:0,
            last_active: 'Loading...'
        };
        setProducts((products) => {
            firebase.db.ref(db_refs.organisations.forecasting.totals(organisation_id, 'products', product_key)).on('value', (totals) => updateProductTotals(product_key, totals))

            firebase.db.ref(db_refs.organisations.forecasting.completed(organisation_id, 'products', product_key)).orderByChild('eventDate').limitToLast(1).on('value', (record)=>updateProductDate(product_key, record))

            return [...products.filter(p=>p.id!==product.key), new_product]
        })
    };

    const fetchProducts = () => {
        firebase.db.ref(db_refs.organisations.products(organisation_id)).on('child_added', addOrUpdateProduct)
        firebase.db.ref(db_refs.organisations.products(organisation_id)).on('child_changed', addOrUpdateProduct)
        firebase.db.ref(db_refs.organisations.products(organisation_id)).on('child_removed', (product) => {
            setProducts((products) => [...products.filter(p=>p.id!==product.key)])
        })

    };

    const unsubscribe = () => {
        firebase.db.ref(db_refs.organisations.products(organisation_id)).off();
        products.map((product => {
            firebase.db.ref(db_refs.organisations.forecasting.totals(organisation_id, 'products', product.id)).off();
            firebase.db.ref(db_refs.organisations.forecasting.completed(organisation_id, 'products', product.id)).orderByChild('eventDate').limitToLast(1).off()
        }))
    }



    useEffect(() => {
        if (!customer) return;
        fetchProducts();
        setLoading((loading => {
            loading.products = false;
            return {...loading}
        }))
        return unsubscribe

    }, [customer]);

    if (error) {
        return <ErrorMessage
            details={"It looks like you don't have access to any customer services for this organisation"}
            message={'No customer access found'}
        />
    }
    if (!customer) {
        return <LoadingScreen/>
    }
    const Component = pages[page]
    return <div className={'row'}>
        <Hotbar page={page} customer={customer} updatePage={(event)=>updatePage(event.target.dataset.page)}/>
       <Col xs={{span: 10, offset: 1}} >
           <h1 className={'text-center w-100 mt-1'}>{customer.name}@ContainerMaster</h1>
           {!loading.products && <Component organisation={organisation} customers={customers} role={role} products={products} firebase={firebase} organisation_id={organisation_id} customer={customer}/>}
       </Col>

    </div>
};

export default CustomerApp;