import React from "react";
import * as PropTypes from 'prop-types';
import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";

class StepProgressBar extends React.Component {
    render() {
        return (
            <ProgressBar
                percent={Math.floor(Math.min(100, (this.props.current/(this.props.steps.length-1))*100+1))}
                filledBackground="#18135c"
            >
                {
                    this.props.steps.map((step, key) => <Step key={`step${key}${step.page}`} style={{width: "33%"}} transition="scale">
                        {({accomplished}) => (
                            <div className={'text-center p-0 mb-4'}>
                            <span className={(step.complete ? 'font-weight-bold text-success' : 'text-light')+' small'} style={{filter: `opacity(${accomplished ? 100 : 30}%)`}}>
                                {step.label}
                            </span><br/>
                            <img
                                className={'mt-1'}
                                style={{filter: `opacity(${accomplished ? 100 : 30}%)`}}
                                height={accomplished && !step.complete ? 60 : 50}
                                src={step.src}
                                alt={"step"}/></div>

                        )}
                    </Step>
                    )
                }

            </ProgressBar>
        );
    }
}

StepProgressBar.propTypes = {
    steps: PropTypes.array.isRequired,
    current: PropTypes.number
};
StepProgressBar.defaultProps = {
    current: 0
};

export default StepProgressBar;