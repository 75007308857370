const firebaseConfig = {
    apiKey: "AIzaSyD5lv-Ykj9T7VtE-F2Aqr6ceZONYTKas0U",
    authDomain: "container-master-app.firebaseapp.com",
    databaseURL: "https://container-master-app.firebaseio.com",
    projectId: "container-master-app",
    storageBucket: "container-master-app.appspot.com",
    messagingSenderId: "274869227312",
    appId: "1:274869227312:web:0f5bd702a6fcb67853c2f5",
    measurementId: "G-H3RJYNQVMJ"
};

export default firebaseConfig;