import React from 'react';

import * as PropTypes from 'prop-types';
import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Form,
    FormCheck,
    FormControl,
    FormGroup,
    FormLabel, Modal,
    Row
} from "react-bootstrap";

import db_refs from "../../../constants/db_refs";
import {toast} from "react-toastify";
import {toastConfig} from "../../../config/toast_config";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SearchInput} from "../../../elements/SearchInput";
import ReactToPrint from "react-to-print";
import PrintedGatePass from "./PrintedGatePass";

import _ from "lodash";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";

const FORM_STATE = () =>({
    id: '',
    vehicle_reg: '',
    haulier: '',
    driver: '',
    pass_number: '',
    collections: {},
    picking_lists: {},
    outturns: {},
    revoked: false,
    total: 0,
    issued_on: null

});
const FormHeader = ({issued, revoked, revoked_on, issued_on, customers, customer, customer_id, vehicle_reg, handleChange, haulier, driver, pass_number, total, pass_id, organisation_id, incoming, firebase}) => {
    return <Row>
        <Col>
            {issued && !revoked && <span
                className={'text-success'}>Issued on {new Date(parseInt(issued_on)).toLocaleString()}({moment(new Date(parseInt(issued_on))).fromNow()})</span>}
            {revoked &&
            <span className={'text-danger'}>Revoked {moment(new Date(parseInt(revoked_on))).fromNow()}</span>}
            {issued && <Button onClick={
                ()=>{
                    firebase.functions.httpsCallable('resyncGatePass')({
                        organisation_id,
                        pass_id,
                        incoming: !!incoming
                    }).then(()=>console.debug('Synced')).catch((e)=>console.error(e));
                }
            }>Resync</Button>}
            {!revoked && <FormGroup>
                <FormLabel column={false}>Select Customer</FormLabel>
                <FormControl required readOnly={revoked || issued} name={'customer'} defaultValue={customer_id}
                             as={'select'}
                             value={customer_id}
                             size={'sm'} onChange={handleChange}>
                    {Object.values(customers).map(customer => <option key={`customer_option_gate_pass_${customer.id}`}
                                                                      value={customer.id}>{customer.name}</option>)}
                    <option key={`customer_option_gate_pass_no_customer`}
                            value={'x'}>-</option>
                </FormControl>
            </FormGroup>}
            {revoked && <FormGroup>
                <FormLabel name={'customer'} column={false}>Customer</FormLabel>
                <FormControl readOnly={revoked || issued} size={'sm'} value={customer.name}/>
            </FormGroup>
            }
            <FormGroup>
                <FormLabel name={'vehicle_reg'} column={false}>Vehicle Reg.</FormLabel>
                <FormControl isInvalid={!vehicle_reg || vehicle_reg.length === 0} readOnly={revoked || issued}
                             size={'sm'} value={vehicle_reg} onChange={handleChange}
                             name={'vehicle_reg'}/>
            </FormGroup>
            <FormGroup>
                <FormLabel column={false}>Haulier</FormLabel>
                <FormControl isInvalid={!haulier || haulier.length === 0} readOnly={revoked || issued} size={'sm'}
                             name={'haulier'} value={haulier}
                             onChange={handleChange}/>
            </FormGroup></Col><Col>
        <FormGroup>
            <FormLabel column={false}>Driver Name</FormLabel>
            <FormControl isInvalid={!driver || driver.length === 0} readOnly={revoked || issued} size={'sm'}
                         name={'driver'} value={driver}
                         onChange={handleChange}/>
        </FormGroup>
        <FormGroup>
            <FormLabel column={false}>Pass Number</FormLabel>
            <FormControl readOnly={true} size={'sm'} name={'pass_number'} defaultValue={pass_number}/>
        </FormGroup>
        <FormGroup>
            <FormLabel column={false}>Total Crates</FormLabel>
            <FormControl size={'sm'} readOnly name={'pass_number'} value={total}/>
        </FormGroup>

    </Col>
    </Row>
};

class CollectionGatePassForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...FORM_STATE, loading: true, filter: ''};
        this.handleChange = this.handleChange.bind(this);
        this.updateOpenCollections = this.updateOpenCollections.bind(this);
        this.updateOpenPickingLists = this.updateOpenPickingLists.bind(this);
    }

    validateFields() {
        const {driver, haulier, vehicle_reg} = this.state;
        return Object.values({driver, haulier, vehicle_reg}).reduce((a, b) => a && b && b.length > 0, true)
    }

    cleanAndSetDates(issued, pass) {
        pass.filter = null;
        if (!this.props.pass) {
            pass.created = new Date().getTime()
        }
        if (issued) {
            pass.issued = true;
            pass.issued_on = new Date().getTime();

        } else {
            pass.issued = false;
            pass.issued_on = null;
        }
        return pass;
    }

    handleChange(event) {
        const newState = {};
        newState[event.target.name] = event.target.name === "customer" ? event.target.value : event.target.value.toUpperCase();
        this.setState(newState)
    }

    reissuePass(id, pass) {
        const {firebase} = this.props;
        firebase.functions.httpsCallable('reissueGatePass')(
            {
                organisation_id: this.props.organisation_id,
                gate_pass_id: id,
                picking_lists: pass.picking_lists,
                collections: pass.collections,
                gate_pass: pass
            }).then((res) => {
            toast.success('Pass reissued', {...toastConfig});
            this.props.edit(res.data)
        }).catch(err => toast.error(err.message, {...toastConfig}));
    }

    submit(should_issued) {
        const {firebase, pass_id} = this.props;
        const pass = this.cleanAndSetDates(should_issued, {...this.state});
        const {driver, haulier, vehicle_reg, collections, picking_lists, created, issued, issued_on, customer, total}  = pass;
        firebase.db.ref(db_refs.organisations.gate_passes(this.props.organisation_id)).child(pass_id).update({total, customer, created: created ? created : null, driver, haulier, vehicle_reg, collections, picking_lists, issued, issued_on}).then(() => {
            toast.success('Saved gate pass', {...toastConfig});
            this.props.cancel()
        });
    }

    updateOpenPickingLists(res) {
        const result = res.val() || {};
        Object.keys(result).forEach(k=>{
            result[k].id = k;
        });
        this.setState({open_picking_lists: Object.filter(result, r=>!r.provisional_pass_id)})
    }
    updateOpenCollections(res) {
        this.setState({open_collections: res.val()})
    }

    componentDidMount() {
        const {firebase, organisation_id, pass_id, customers} = this.props;
        firebase.db.ref(db_refs.organisations.gate_passes(organisation_id)).child(pass_id).on('value', async (snapshot) => {
            if(snapshot.val()) {
                const {customer, pass_number, collections, picking_lists, issued_on, issued_by, revoked_on, revoked, issued, driver, haulier, vehicle_reg} = snapshot.val();
                const collection_promises = Object.keys(collections || {}).map(o=>firebase.db.ref(db_refs.organisations.summaries.collection(organisation_id)).child(o).once('value'));
                const results = await Promise.all(collection_promises);
                let total = 0;
                let can_revoke = true;
                results.forEach(r=>{
                    if(r.val()) {
                        collections[r.key] = r.val();
                        total+=parseInt(r.val().crates_val);
                        const coll = r.val();
                        if(coll.stocked_out) {
                            can_revoke = false;
                        }
                    }
                });
                const pl_promises = Object.keys(picking_lists || {}).map(o=>firebase.db.ref(db_refs.organisations.bulk.picking(organisation_id)).child(o).once('value'));
                const results2 = await Promise.all(pl_promises);
                for(let r of results2) {
                    picking_lists[r.key] = {...r.val(), id: r.key};
                    const collection_promises = Object.keys(r.val().collections || {}).map(o=>firebase.db.ref(db_refs.organisations.summaries.collection(organisation_id)).child(o).once('value'));
                    const results = await Promise.all(collection_promises);

                    results.forEach(r2=>{
                        if(r2.val()) {
                            picking_lists[r.key].collections[r2.key] = r2.val();
                            total+=parseInt(r2.val().crates_val);
                            if(r2.val().stocked_out) {
                                can_revoke = false;
                            }
                        }
                    });
                }
                const actual_customer = customer ? customer : Object.keys(customers).length > 0 ? customers[Object.keys(customers)[0]].id : null;
                console.log(actual_customer)
                console.log(customers)
                this.setState( {can_revoke, customer: actual_customer, pass_number, picking_lists: picking_lists || {}, saved_picking_lists: _.cloneDeep(picking_lists || {}), collections: collections || {}, saved_collections: _.cloneDeep(collections || {}), issued_on, issued_by, revoked_on, revoked, issued, driver, haulier, vehicle_reg, total, loading: false} );
                if(!issued && !revoked) {
                    firebase.db.ref(db_refs.organisations.summaries.collection(organisation_id))
                        .orderByChild("can_be_issued")
                        .equalTo(true).on('value', this.updateOpenCollections);
                    firebase.db.ref(db_refs.organisations.bulk.picking(organisation_id))
                        .orderByChild("pass_id")
                        .equalTo(null).on('value', this.updateOpenPickingLists);
                }
                else
                    this.setState({open_collections: {}, open_picking_lists: {}})
            } else {
                console.log("No customer")
            }
        });

    }

    componentWillUnmount() {
        const {firebase, organisation_id, pass_id} = this.props;
        firebase.db.ref(db_refs.organisations.gate_passes(organisation_id)).child(pass_id).off();
        firebase.db.ref(db_refs.organisations.summaries.collection(organisation_id)).off('value', this.updateOpenCollections);
        firebase.db.ref(db_refs.organisations.bulk.picking(organisation_id)).off('value', this.updateOpenPickingLists);
    }

    render() {
        const {firebase, customers, edit, pass_id, organisation_id} = this.props;
        const {can_revoke, vehicle_reg, haulier, driver, pass_number, customer, revoked, issued, issued_on, revoked_on, collections, open_collections, saved_collections, picking_lists, open_picking_lists, filter, loading, saved_picking_lists} = this.state;
        const total = this.state.total;
        const customer_name = Object.values(customers).find((c) => c.id === customer);
        const collections_to_show = Object.values({...open_collections, ...collections, ...saved_collections})
            .sort((o1, o2)=>o1.collection_ref < o2.collection_ref ? -1 : o1.collection_ref > o2.collection_ref ? 1 : 0)
            .filter(o => o.customer===customer && ((saved_collections && saved_collections[o.id]) || (collections && collections[o.id]) || o.collection_ref.toLowerCase().indexOf(filter.toLowerCase()) > -1));
        const picking_lists_to_show = Object.values({...open_picking_lists, ...picking_lists, ...saved_picking_lists})
            .sort((o1, o2)=>o1.picking_list_number < o2.picking_list_number ? -1 : o1.picking_list_number > o2.picking_list_number ? 1 : 0)
            .filter(o => o.customer===customer && ((saved_picking_lists && saved_picking_lists[o.id]) || (picking_lists && picking_lists[o.id]) || o.picking_list_number.toLowerCase().indexOf(filter.toLowerCase()) > -1));

        const pending = !issued && !revoked;
        const currently_issued = issued && !revoked;
        const added_picking_lists = Object.keys(picking_lists || {}).length + Object.keys(collections || {}).length;
        if(loading || !pass_number) return <LoadingScreen/>;
        return <Form key={Object.keys(picking_lists_to_show || {}).join('-')}>
            Customer: {customer}
            <FormHeader
                firebase={firebase}
                organisation_id={organisation_id}
                pass_id={pass_id}
                customer_id={customer}
                customer={customer_name}
                customers={customers}
                handleChange={this.handleChange}
                haulier={haulier}
                driver={driver}
                vehicle_reg={vehicle_reg}
                pass_number={pass_number}
                total={total}
                issued={issued}
                issued_on={issued_on}
                revoked={revoked}
                revoked_on={revoked_on}
            />
            <SearchInput setFilter={(e) => this.setState({filter: e.target.value})}/>
            {
                (issued && !revoked) && <div style={{display: 'none'}}>
                    <div ref={ref => this.componentRef = ref}>
                        <PrintedGatePass
                            gate_pass={this.state}
                            customer={customers[customer].code}
                            picking_lists={picking_lists}
                            logo={this.props.logo}
                        />
                    </div>

                </div>
            }
            <h5>Collections</h5>

            <Row>


                {
                    collections_to_show.filter(c => (saved_collections && saved_collections[c.id]) || c.collection_ref.indexOf(this.state.filter) > -1).map(collection => {

                        return <FormLabel className={'badge-lg badge badge-primary m-1'} style={{fontSize: '1.1em'}}
                                          key={`${collection.customer}_${collection.id}`} column={false}>
                            <FormCheck
                                checked={!!(!!this.state.collections && this.state.collections[collection.id])}
                                disabled={issued || revoked}
                                onChange={() => {
                                    const {total} = this.state;
                                    const collections = this.state.collections || {};

                                    let newTotal = 0;

                                    if (collections[collection.id]) {
                                        newTotal -= collection.crates ? collection.crates : collection.projected;
                                        this.setState({
                                            collections: {...Object.filter(collections, c => c.id !== collection.id, {})},
                                            total: total + newTotal
                                        })
                                    } else {
                                        const {id, collection_ref, products, customer} = collection;
                                        collections[collection.id] = {
                                            id,
                                            collection_ref,
                                            crates: collection.crates && collection.crates > 0 ? collection.crates : collection.projected,
                                            products: products,
                                            customer
                                        };
                                        newTotal += collection.crates && collection.crates > 0 ? collection.crates : collection.projected;
                                        this.setState({collections: collections, total: total + newTotal})
                                    }

                                }}
                            />
                            {collection.collection_ref} <br/><Badge variant={'light'} className={'text-dark m-1'}>
                            <FontAwesomeIcon className={'mr-1'} icon={'boxes'}/>
                            {collection.crates}</Badge>
                        </FormLabel>
                    })

                }

            </Row>
            <h5>Picking Lists</h5>
            <Row>

                {
                    picking_lists_to_show
                        .filter(pl => (saved_picking_lists && saved_picking_lists[pl.id]) || (picking_lists && picking_lists[pl.id])
                            || pl.picking_list_number.indexOf(this.state.filter) > -1
                            || !!Object.values(pl.collections || {}).find(c => c.collection_ref.indexOf(this.state.filter) > -1))
                        .map(pl => {

                            let total_on_list = Object.values(pl.collections || {}).map(collection => {
                                return collection.grand_total
                            }).reduce((a,b)=>a+b, 0);
                            if(isNaN(total_on_list)) {
                                total_on_list = Object.values(pl.collections || {}).map(collection => {
                                    return collection.crates
                                }).reduce((a,b)=>a+b, 0)
                            }



                            return <FormLabel className={'badge-lg badge badge-primary m-1'} style={{fontSize: '1.1em'}}
                                              key={`${pl.customer}_${pl.id}`} column={false}>
                                <FormCheck
                                    disabled={issued || revoked}
                                    checked={(this.state.picking_lists && this.state.picking_lists[pl.id])}
                                    onChange={() => {
                                        const {total} = this.state;
                                        const pls = this.state.picking_lists || {};

                                        let newTotal = 0;

                                        if (pls[pl.id]) {
                                            newTotal -= total_on_list;
                                            this.setState({
                                                picking_lists: _.cloneDeep(Object.filter(pls, p => p.id !== pl.id)),
                                                total: parseInt(total) + newTotal
                                            });
                                            return
                                        } else {
                                            const {id, picking_list_number, collections} = pl;
                                            pls[pl.id] = {id, picking_list_number, collections, customer};
                                            newTotal += total_on_list
                                        }
                                        this.setState({picking_lists: pls, total: parseInt(total) + newTotal})
                                    }}
                                />
                                {pl.picking_list_number} <br/><Badge variant={'light'} className={'text-dark m-1'}>
                                <FontAwesomeIcon className={'mr-1'} icon={'boxes'}/>
                                {total_on_list}
                            </Badge><br/>
                                {Object.values(pl.collections || {}).map(plc => [
                                    <Badge key={`${plc.id}_badge_${pl.id}`} variant={'info'}
                                           className={'text-dark m-1'}>{plc.collection_ref} <FontAwesomeIcon
                                        className={'mr-1'} icon={'boxes'}/> {plc.grand_total}

                                    </Badge>,
                                    <br key={`${plc.id}_break_${pl.id}`}/>]
                                )}
                            </FormLabel>
                        })
                }

            </Row>
            <Row>
                <Col className={'text-right'}>
                    <ButtonGroup className={'float-right'}>
                        {pending && added_picking_lists > 0 &&
                        <Button variant={'primary'} size={'sm'}
                                onClick={() => this.submit(false)}>{!pass_id ? "Create" : 'Save'}</Button>
                        }
                        {pending && added_picking_lists === 0 &&
                        <Button variant={'primary'} size={'sm'}
                                title={`You can't save a gate pass with no picking_lists`}
                                disabled={true}
                                onClick={() => this.submit(false)}>{!pass_id ? "Create" : 'Save'}</Button>
                        }


                        {
                            currently_issued && <ReactToPrint
                                trigger={() => <Button

                                    key={`print_button_${pass_id}`}
                                    title={'Print?'}
                                    data-item={pass_id}
                                    variant={'primary'}
                                    size={'sm'}>Print</Button>}
                                content={() => this.componentRef}
                            />
                        }

                        { pending  && this.validateFields() && <Button disabled={!customer}
                                                                      title={`${!!customer ? '' : 'You must select a customer!'}`}
                                                                      variant={'success'} size={'sm'} onClick={() => this.submit(true)}>{"Issue"}</Button> }
                        { pending && added_picking_lists === 0 && <Button disabled={true}
                                                                     title={`You can't issue a gate pass with no picking_lists`}
                                                                     variant={'success'} size={'sm'} onClick={() => this.submit(true)}>{"Issue"}</Button> }
                        { pending && added_picking_lists > 0 && !this.validateFields() && <Button disabled={true} title={`You must complete all of the fields`} variant={'success'}
                                                                                             size={'sm'} onClick={() => this.submit(true)}>{"Issue"}</Button> }
                        { can_revoke && !revoked && issued && <Button
                            onClick={() => {
                                this.reissuePass(pass_id, this.state)
                            }}
                            key={`reissue_button_${pass_id}`}
                            title={'Reissue?'}
                            data-item={pass_id}
                            variant={'tertiary'}
                            size={'sm'}>Reissue</Button> }
                        <Button variant={'secondary'} size={'sm'}
                                onClick={() => edit(null)}>{'Close'}</Button>

                    </ButtonGroup>
                </Col>
            </Row>
        </Form>

    }
}

CollectionGatePassForm.propTypes = {
    pass_id: PropTypes.string.isRequired,
    firebase: PropTypes.object.isRequired,
    customers: PropTypes.object.isRequired,
    logo: PropTypes.string.isRequired
};

class DevanGatePassForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...FORM_STATE, loading: true, filter: ''};
        this.handleChange = this.handleChange.bind(this);
        this.updateOpenOutturns = this.updateOpenOutturns.bind(this);
    }

    validateFields() {
        const {driver, haulier, vehicle_reg} = this.state;
        return Object.values({driver, haulier, vehicle_reg}).reduce((a, b) => a && b && b.length > 0, true)
    }

    cleanAndSetDates(issued, pass) {
        pass.filter = null;
        if (!this.props.pass) {
            pass.created = new Date().getTime()
        }
        if (issued) {
            pass.issued = true;
            pass.issued_on = new Date().getTime();

        } else {
            pass.issued = false;
            pass.issued_on = null;
        }
        return pass;
    }

    handleChange(event) {
        const newState = {};
        newState[event.target.name] = event.target.name === "customer" ? event.target.value : event.target.value.toUpperCase();
        this.setState(newState)
    }

    reissuePass() {
        const {firebase, organisation_id, pass_id} = this.props;
        const {driver, haulier, vehicle_reg, outturns, created, issued, issued_on, customer, total}  = this.state;
        const outturns_to_save = {};
        Object.keys(outturns).forEach(oid=>{
            const {container_number, crates, products} = outturns[oid];
            outturns_to_save[oid] = {
                id: oid,
                container_number,
                crates: crates,
                products: products
            };
        });
        firebase.functions.httpsCallable('reissueDevanGatePass')(
            {
                organisation_id: organisation_id,
                gate_pass_id: pass_id,
                outturns: outturns_to_save,
                gate_pass: {total, customer, created: created ? created : null, driver, haulier, vehicle_reg, outturns_to_save, issued, issued_on}
            }).then((res) => {
            toast.success('Pass reissued', {...toastConfig});
            this.props.edit(res.data)
        }).catch(err => toast.error(err.message, {...toastConfig}));
    }

    submit(should_issued) {
        const {firebase, pass_id} = this.props;
        const request_id = pass_id;
        const pass = this.cleanAndSetDates(should_issued, {...this.state});
        const {driver, haulier, vehicle_reg, outturns, created, issued, issued_on, customer, total}  = pass;
        firebase.db.ref(db_refs.organisations.devan_gate_passes(this.props.organisation_id))
            .child(request_id)
            .update({total, customer, created: created ? created : null, driver, haulier, vehicle_reg, outturns, issued, issued_on})
            .then(() => {
            toast.success('Saved gate pass', {...toastConfig});
            this.props.cancel()
        });
    }

    updateOpenOutturns(res) {
        this.setState({open_outturns: res.val()})
    }

    componentDidMount() {
        const {firebase, organisation_id, pass_id, customers} = this.props;
        firebase.db.ref(db_refs.organisations.devan_gate_passes(organisation_id)).child(pass_id).on('value', async (snapshot) => {
            if(snapshot.val()) {
                const {customer, pass_number, outturns, issued_on, issued_by, revoked_on, revoked, issued, driver, haulier, vehicle_reg} = snapshot.val();
                const outturn_promises = Object.keys(outturns || {}).map(o=>firebase.db.ref(db_refs.organisations.summaries.outturn(organisation_id)).child(o).once('value'));
                const results = await Promise.all(outturn_promises);
                let total = 0;
                let can_revoke = true;
                results.forEach(r=>{
                    if(r.val()) {
                        outturns[r.key] = r.val();
                        total+=parseInt(r.val().crates_val);
                        if(r.val().stocked_in) {
                            can_revoke = false;
                        }
                    }
                });
                const actual_customer = customer ? customer : customers.length > 0 ? customers[0].id : null;
                this.setState( {customer: actual_customer, pass_number, outturns: outturns || {}, saved_outturns: _.cloneDeep(outturns || {}), can_revoke, issued_on, issued_by, revoked_on, revoked, issued, driver, haulier, vehicle_reg, total, loading: false} );
                if(!issued && !revoked)
                    firebase.db.ref(db_refs.organisations.summaries.outturn(organisation_id))
                        .orderByChild("can_be_issued")
                        .equalTo(true).on('value', this.updateOpenOutturns);
                else
                    this.setState({open_outturns: {}})
            }
        });

    }

    componentWillUnmount() {
        const {firebase, organisation_id, pass_id} = this.props;
        firebase.db.ref(db_refs.organisations.devan_gate_passes(organisation_id)).child(pass_id).off();
        firebase.db.ref(db_refs.organisations.summaries.outturn(organisation_id)).off('value', this.updateOpenOutturns);
    }

    render() {
        const {firebase, organisation_id, customers, edit, pass_id} = this.props;
        const {vehicle_reg, haulier, driver, pass_number, customer, revoked, issued, issued_on, revoked_on, outturns, open_outturns, filter, loading, saved_outturns, can_revoke} = this.state;
        if(loading || !pass_number) return <LoadingScreen/>;
        const total = this.state.total;
        const customer_name = Object.values(customers).find((c) => c.id === customer);
        const outturns_to_show = Object.values({...open_outturns, ...outturns, ...saved_outturns})
            .sort((o1, o2)=>o1.container_number < o2.container_number ? -1 : o1.container_number > o2.container_number ? 1 : 0)
            .filter(o => o.customer===customer && ((saved_outturns && saved_outturns[o.id]) || (outturns && outturns[o.id]) || o.container_number.toLowerCase().indexOf(filter.toLowerCase()) > -1));
        const pending = !issued && !revoked;
        const currently_issued = issued && !revoked;
        const added_outturns = Object.keys(outturns || {}).length;
        if(loading || !pass_number) return <LoadingScreen/>;
        return <Form key={Object.keys(outturns || {}).join('-')}>
            <FormHeader
                firebase={firebase}
                organisation_id={organisation_id}
                pass_id={pass_id}
                customer_id={customer}
                customer={customer_name}
                customers={customers}
                handleChange={this.handleChange}
                haulier={haulier}
                driver={driver}
                vehicle_reg={vehicle_reg}
                pass_number={pass_number}
                total={total}
                issued={issued}
                issued_on={issued_on}
                revoked={revoked}
                incoming={true}
                revoked_on={revoked_on}
            />
            <SearchInput setFilter={(e) => this.setState({filter: e.target.value})}/>
            {
                (issued && !revoked) && <div style={{display: 'none'}}>
                    <div ref={ref => this.componentRef = ref}>
                        <PrintedGatePass
                            gate_pass={this.state}
                            customer={customers.find(c => c.id === customer).code}
                            outturns={outturns}
                            logo={this.props.logo}
                        />
                    </div>

                </div>
            }
            <h5>Outturns</h5>
            <Row>
                {
                    outturns_to_show
                        .map(outturn =><FormLabel className={'badge-lg badge badge-primary m-1'} style={{fontSize: '1.1em'}}
                                          key={`${outturn.customer}_${outturn.id}`} column={false}>
                            <FormCheck
                                checked={outturns && outturns[outturn.id]}
                                disabled={issued || revoked}
                                onChange={() => {
                                    const {total, outturns} = this.state;
                                    const new_outturns = _.cloneDeep(outturns || {});
                                    let newTotal = 0;
                                    let change = outturn.crates_val ? outturn.crates_val : outturn.crates ? outturn.crates : outturn.projected;
                                    if (new_outturns[outturn.id]) {
                                        newTotal -= change;
                                        this.setState({
                                            outturns: {...Object.filter(new_outturns, c => c.id !== outturn.id, {})},
                                            total: total + newTotal
                                        })
                                    } else {
                                        const {id, container_number, products, customer} = outturn;
                                        new_outturns[id] = {
                                            id,
                                            container_number,
                                            crates: change,
                                            products: products,
                                            customer
                                        };
                                        newTotal += change;
                                        this.setState({outturns: new_outturns, total: total + newTotal})
                                    }

                                }}
                            />
                            {outturn.container_number} <br/><Badge variant={'light'} className={'text-dark m-1'}>
                            <FontAwesomeIcon className={'mr-1'} icon={'boxes'}/>
                            {outturn.crates_val ? outturn.crates_val : outturn.crates ? outturn.crates : outturn.projected}</Badge>
                        </FormLabel>)
                }

            </Row>
            <Row>
                <Col className={'text-right'}>
                    <ButtonGroup className={'float-right'}>
                        {pending && added_outturns > 0 &&
                        <Button variant={'primary'} size={'sm'}
                                onClick={() => this.submit(false)}>{!pass_id ? "Create" : 'Save'}</Button>
                        }
                        {pending && added_outturns === 0 &&
                        <Button variant={'primary'} size={'sm'}
                                title={`You can't save a gate pass with no outturns`}
                                disabled={true}
                                onClick={() => this.submit(false)}>{!pass_id ? "Create" : 'Save'}</Button>
                        }


                        {
                            currently_issued && <ReactToPrint
                                trigger={() => <Button

                                    key={`print_button_${pass_id}`}
                                    title={'Print?'}
                                    data-item={pass_id}
                                    variant={'primary'}
                                    size={'sm'}>Print</Button>}
                                content={() => this.componentRef}
                            />
                        }

                        { pending && this.validateFields() && <Button disabled={!customer}
                                title={`${!customer ? '' : 'You must select a customer!'}`}
                                variant={'success'} size={'sm'} onClick={() => this.submit(true)}>{"Issue"}</Button> }
                        { pending && added_outturns === 0 && <Button disabled={true}
                                title={`You can't issue a gate pass with no outturns`}
                                variant={'success'} size={'sm'} onClick={() => this.submit(true)}>{"Issue"}</Button> }
                        { pending && added_outturns > 0 && !this.validateFields() && <Button disabled={true} title={`You must complete all of the fields`} variant={'success'}
                                size={'sm'} onClick={() => this.submit(true)}>{"Issue"}</Button> }
                        { can_revoke && !revoked && issued && <Button
                            onClick={() => {
                                this.reissuePass(pass_id, this.state)
                            }}
                            key={`reissue_button_${pass_id}`}
                            title={'Reissue?'}
                            data-item={pass_id}
                            variant={'tertiary'}
                            size={'sm'}>Reissue</Button> }
                        <Button variant={'secondary'} size={'sm'}
                                onClick={() => edit(null)}>{'Close'}</Button>

                    </ButtonGroup>
                </Col>
            </Row>
        </Form>

    }
}

DevanGatePassForm.propTypes = {
    edit: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    pass_id: PropTypes.string.isRequired,
    firebase: PropTypes.object.isRequired,
    customers: PropTypes.object.isRequired,
    logo: PropTypes.string.isRequired
};

const GatePassModal = ({onHide, issuing, children}) => <Modal show={!!issuing} onHide={onHide} size={'lg'} >
    <Modal.Header className={'bg-primary text-light'}>
        <h3 className={'text-light w-100 text-center'}>Gate Pass</h3>
    </Modal.Header>
    <Modal.Body className={'bg-light'}>
        {children}
    </Modal.Body>
</Modal>;

export {DevanGatePassForm, GatePassModal};
export default CollectionGatePassForm;