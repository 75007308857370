import PageTemplate from "../PageTemplate";
import React from "react";
import {Col, Image, Modal, Nav, Tab} from "react-bootstrap";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import {ContainerMasterDataContext} from "../../../components/ContainerMaster/ContainerMaster";
import ErrorMessage from "../../../elements/ErrorMessage";
import {CollectionGatePassTable, DevanGatePassTable} from "./GatePassTable";
import GatePassForm, {DevanGatePassForm} from "./GatePassForm";
import Hotbar from "../../../elements/Hotbar";
import outturns_img from '../../../icons/outturns.png';
import collections_img from '../../../icons/collections.png';

class GatePassIssuer extends PageTemplate {

    constructor(props) {
        super(props);
        this.state = {
            editing: props.entity,
            filter: '',
            devan_filter: '',
            loading: false,
            key: 'second'
        };
        this.edit = this.edit.bind(this);
        this.editDevan = this.editDevan.bind(this);
        this.delete = this.delete.bind(this);
    }


    edit(id) {
        this.setState({editing: id})
    }
    editDevan(id) {
        this.setState({editing_devan: id})
    }

    delete(id) {
        this.setState({deleting: id})
    }


    render() {
        const {editing, editing_devan, loading, collections, all_collections, all_outturns} = this.state;
        const {firebase} = this.props;
        return (
            <div>
               <Hotbar updatePage={this.props.updatePage} page={'reports'}/>
                <div className={'col-xs-12 col-sm-11 ml-auto mr-auto bg-primary pt-2 pb-2 mt-1'}>

                        <Nav variant="pills" size={'sm'} className={'text-center w-100 mb-1'} activeKey={this.state.key}>
                            <Nav.Item className={'ml-auto'}>
                                <Nav.Link className={`bg-${this.state.key!=='first' ? 'tertiary' : 'info'} text-light mr-5`} onClick={()=>this.setState({key: 'first'})} eventKey="first">Outturns</Nav.Link>
                            </Nav.Item>  <h3 className={'w-25 text-light text-center no-print'}>
                            <Image style={{maxHeight: '1.2em', marginRight: '1em'}} src={this.state.key==='first' ? outturns_img  : collections_img}/>
                            {this.state.key==='first' ? 'Outturn' : 'Collection'} Gate Passes
                        </h3>
                            <Nav.Item className={'mr-auto'}>
                                <Nav.Link className={`bg-${this.state.key==='first' ? 'tertiary' : 'info'} text-light ml-5`} onClick={()=>this.setState({key: 'second'})} eventKey="second">Collections</Nav.Link>
                            </Nav.Item>
                        </Nav>


                            <ContainerMasterDataContext.Consumer>
                                {
                                    ({customers, products, bulk_picking_lists}) => {
                                        if (!customers || loading )
                                            return <LoadingScreen/>;
                                        return  <Tab.Container id="left-tabs-example" defaultActiveKey="second" activeKey={this.state.key}>


                                                    <Col>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="first">
                                                                <div className={''}>
                                                                    {
                                                                        Object.values(customers || {}).length === 0 && <ErrorMessage
                                                                            message={"Whoops, no customers"}
                                                                            details={"You must create a customer before you can start devanning."}
                                                                            link={
                                                                                <button
                                                                                    className={'btn btn-tertiary'}
                                                                                    data-page='customers'
                                                                                    onClick={this.props.updatePage}
                                                                                >
                                                                                    Create one now!
                                                                                </button>}

                                                                        />
                                                                    }
                                                                    {editing_devan && <Modal show={!!editing_devan} onHide={()=>this.setState({editing_devan: null})} size={'lg'} >
                                                                        <Modal.Header className={'bg-primary text-light'}>
                                                                            <h3 className={'text-light w-100 text-center'}>
                                                                                Editing Gate Pass
                                                                            </h3>
                                                                        </Modal.Header>
                                                                        <Modal.Body className={'bg-light'}>
                                                                            <DevanGatePassForm
                                                                                logo={this.props.organisation.logo || ''}
                                                                                key={editing}
                                                                                edit={this.editDevan}
                                                                                cancel={()=>{this.setState({editing: null})}}
                                                                                customers={Object.values(customers || {})}
                                                                                firebase={firebase}
                                                                                pass_id={editing_devan}
                                                                                organisation_id={this.props.organisation_id}
                                                                            />
                                                                        </Modal.Body>
                                                                    </Modal>}

                                                                    {
                                                                        customers  && <div className={'text-right'}>
                                                                            <DevanGatePassTable customers={customers}
                                                                                                filter={this.state.filters}
                                                                                           picking_lists={bulk_picking_lists}
                                                                                           user_name={this.props.user_name}
                                                                                           organisation={this.props.organisation}
                                                                                           products={products}
                                                                                           delete={this.delete}
                                                                                           edit={this.editDevan}
                                                                                           collections={collections}
                                                                                                all_outturns={all_outturns}
                                                                                           setFilter={this.props.setDevanGatePassFilter}

                                                                                           firebase={this.props.firebase}
                                                                                           organisation_id={this.props.organisation_id}
                                                                                           editOutturn={this.edit}/>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="second">
                                                                <div className={''}>
                                                                    {
                                                                        Object.values(customers || {}).length === 0 && <ErrorMessage
                                                                            message={"Whoops, no customers"}
                                                                            details={"You must create a customer before you can start devanning."}
                                                                            link={
                                                                                <button
                                                                                    className={'btn btn-tertiary'}
                                                                                    data-page='customers'
                                                                                    onClick={this.props.updatePage}
                                                                                >
                                                                                    Create one now!
                                                                                </button>}

                                                                        />
                                                                    }
                                                                    {(editing) && <Modal show={!!editing} onHide={()=>this.setState({ editing: null})} size={'lg'} >
                                                                        <Modal.Header className={'bg-primary text-light'}>
                                                                            <h3 className={'text-light w-100 text-center'}>
                                                                                {editing && `Gate Pass`}
                                                                            </h3>
                                                                        </Modal.Header>
                                                                        <Modal.Body className={'bg-light'}>
                                                                            <GatePassForm
                                                                                logo={this.props.organisation.logo || ''}
                                                                                key={editing}
                                                                                picking_lists={bulk_picking_lists}
                                                                                organisation={this.props.organisation}
                                                                                all_collections={all_collections}
                                                                                edit={this.edit}
                                                                                cancel={()=>{this.setState({editing: null})}}
                                                                                customers={customers || {}}
                                                                                collections={collections}
                                                                                firebase={firebase}
                                                                                pass_id={editing}
                                                                                organisation_id={this.props.organisation_id}
                                                                            />
                                                                        </Modal.Body>
                                                                    </Modal>}

                                                                    {
                                                                        customers && <div className={'text-right'}>
                                                                            <CollectionGatePassTable customers={customers}
                                                                                                     setFilter={this.props.setCollectionGatePassFilter}
                                                                                           picking_lists={bulk_picking_lists}
                                                                                           user_name={this.props.user_name}
                                                                                           organisation={this.props.organisation}
                                                                                           products={products}
                                                                                           delete={this.delete}
                                                                                           edit={this.edit}
                                                                                                     filter={this.state.filter}
                                                                                           collections={collections}

                                                                                           firebase={this.props.firebase}
                                                                                           organisation_id={this.props.organisation_id}
                                                                                           editOutturn={this.edit}/>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Col>

                                            </Tab.Container>

                                    }
                                }</ContainerMasterDataContext.Consumer>



                </div>
            </div>
        )
    }
}

export default GatePassIssuer;