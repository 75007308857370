const prefix =  "prototype2";
export default {
    profile: (id) => `${prefix}/users/${id}/profilePictures`,
    organisation: (id) => `${prefix}/organisations/${id}/files`,
    product: (org_id, customer_id, product_id) => `${prefix}/products/${org_id}/${customer_id}/${product_id}`,
    packing_list_to_process: (user_id, organisation_id, customer_id, devan_request_id, revision, fileName) => {
        return `imported_lists/${user_id}/${organisation_id}/${customer_id}/devan/default/${devan_request_id}/${revision}/${fileName}`
    },
    picking_list_to_process: (user_id, organisation_id, customer_id, collection_request_id, revision, fileName) => {
        return `imported_lists/${user_id}/${organisation_id}/${customer_id}/collection/default/${collection_request_id}/${revision}/${fileName}`
    },
    packing_list_to_store: (user_id, organisation_id, customer_id, devan_request_id, revision, fileName) => {
        return `stored_lists/${user_id}/${organisation_id}/${customer_id}/devan/default/${devan_request_id}/${revision}/${fileName}`
    },
    picking_list_to_store: (user_id, organisation_id, customer_id, collection_request_id, revision, fileName) => {
        return `stored_lists/${user_id}/${organisation_id}/${customer_id}/collection/default/${collection_request_id}/${revision}/${fileName}`
    },
    outturn_image: (organisation_id, outturn_id, filename) => `organisations/${organisation_id}/outturns/${outturn_id}/photos/${filename}`,
    pickup_image: (organisation_id, outturn_id, filename) => `organisations/${organisation_id}/outturns/${outturn_id}/photos/${filename}`,
    stock_import: (user_id, organisation_id, filename) => `stock_imports/${user_id}/${organisation_id}/${filename}`,
    collection_pod: (organisation_id, customer_id, collection_request_id, collection_ref) => {
        return `collection_pods/${organisation_id}/${customer_id}/collection/${collection_request_id}/${collection_ref}.pdf`
    },
    outturn_pod: (organisation_id, customer_id, collection_request_id, collection_ref) => {
        return `outturn_pods/${organisation_id}/${customer_id}/outturn/${collection_request_id}/${collection_ref}.pdf`
    }
}