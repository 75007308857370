import * as React from "react";
import {Button, Form, FormControl} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormCheck from "react-bootstrap/FormCheck";
import * as PropTypes from "prop-types";

class WizardProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: props.product_code,
            description: props.product.description,
            crates: props.product.crates.map((crate) => {
                return {
                    crate_no: crate,
                    new: false,
                    deleted: false
                }
            }),
            showCrates: false,
            create: false,
            new_crate_no: 1,
            delete: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.setCreate = this.setCreate.bind(this);
        this.setDelete = this.setDelete.bind(this);
        this.toggleCrates = this.toggleCrates.bind(this);
        this.addCrate = this.addCrate.bind(this);
        this.setCrates = this.setCrates.bind(this);
        this.updateCrateNumber = this.updateCrateNumber.bind(this);
        this.removeCrate = this.removeCrate.bind(this);
        this.readdCrate = this.readdCrate.bind(this);
        this.isInvalid = this.isInvalid.bind(this);
        this.setToSimilar = this.setToSimilar.bind(this);
    }
    setToSimilar(event) {
        this.setState({
            code: event.target.dataset.code,
            description: event.target.dataset.description
        })
    }

    handleChange(event) {
        const newState = this.state;
        newState[event.target.name] = event.target.value;
        this.setState(newState)
    }

    productChanged() {
        const {code, description} = this.state;
        const saved_description = this.props.product.description;
        const saved_product_code = this.props.product_code;

        let codeChanged = false;
        let descChanged = false;
        if(saved_product_code.indexOf('new product') < 0) {
            if (code !== saved_product_code) {
                codeChanged = true;
            }
            if (description !== saved_description) {
                descChanged = true;
            }
        }
        return {
            code: codeChanged ? `Product code changed from ${saved_product_code}` : "",
            description: descChanged ? `Product description changed from ${saved_description}` : ""
        }
    }
    getProduct() {
        return {
            delete: this.state.delete,
            create: this.state.create,
            code: this.state.code,
            description: this.state.description,
            crates: this.getCrates()
        }
    }
    getCrates() {
        return this.state.crates.filter((crate) => !crate.deleted).map((crate)=> {
            return crate.crate_no
        })
    }
    isInvalid() {
        const {code} = this.state;
        return (code.length === 0 || (!this.exists() && !this.state.create)) && !this.state.delete
    }

    toggleCrates() {
        this.setState({showCrates: !this.state.showCrates});
    }

    exists() {
        return this.props.existing_products.find((existing) => {
            return existing.code === this.state.code
        });
    }
    setCreate(event) {
        this.setState({create: event.target.checked})
    }
    setDelete(event) {
        this.setState({delete: event.target.checked})
    }
    similar() {
        if(this.exists()) {
            return [];
        }
        return this.props.existing_products.filter((existing) => {
            return this.state.code.toLowerCase().indexOf(existing.code.toLowerCase()) > -1 ||  existing.code.toLowerCase().indexOf(this.state.code.toLowerCase()) >-1
        })
    }

    addCrate() {
        const {new_crate_no, crates} = this.state;
        crates.push({
            crate_no: `CRATE${new_crate_no}`,
            new: true,
            deleted: false
        });
        this.setState({
            crates: crates,
            new_crate_no: new_crate_no+1
        })
    }
    setCrates(event) {
        const {crates, new_crate_no} = this.state;
        const new_number_of_crates = parseInt(event.target.value || 0);
        let crate_no = new_crate_no;
        const original_num_crates = crates.length;
        if(new_number_of_crates < original_num_crates) {
            for(let i = 0; i > (new_number_of_crates - original_num_crates); i--) {
                crates.pop();
            }
        } else  if(new_number_of_crates > original_num_crates) {
            for(let i = 0; i < new_number_of_crates - original_num_crates; i++) {
                crates.push({
                    crate_no: `CRATE${crate_no}`,
                    new: true,
                    deleted: false
                });
                crate_no++;
            }
        }
        this.setState({crates: crates, new_crate_no: crate_no})
    }

    removeCrate() {
        const {crates} = this.state;
        crates.pop();
        this.setState({
            crates: crates,
        })

    }

    readdCrate(event) {
        const {crates} = this.state;
        const index = event.target.dataset.index;
        crates[index].deleted = false;
        this.setState({crates})
    }

    cratesChanged() {
        const {crates} = this.state;
        if(!crates) {
            return {
                new: 0,
                deleted: 0
            }
        }
        return {
            new: crates.filter((crate) => crate.new).length,
            deleted: crates.filter((crate) => crate.deleted).length
        }
    }

    updateCrateNumber(event) {
        const index = event.target.dataset.index;
        const {crates} = this.state;
        crates[index].crate_no = event.target.value;
        this.setState(crates);
    }

    handleFocus = (event) => event.target.select();

    render() {
        const {code, description, crates} = this.state;
        const cratesChanged = this.cratesChanged();
        const existing = this.exists();
        let product_stock = 0;
        try {
            product_stock = (this.exists().current || 0) - ((this.exists().promised || 0) + this.exists().stocking_out || 0);
        } catch (err) {
            console.error(err)
        }
        return <div className={'row'}>
            <div className={'col-3'}>
                <Form>
                    <Form.Group>
                        <Form.Control  size={'sm'} disabled={this.state.delete || this.props.status} onFocus={this.handleFocus} autoComplete={"off"} isInvalid={code.length === 0 || (!existing && !this.state.create)} isValid={code.length > 0 &&  (!!existing || this.state.create)} type="text" required
                                       placeholder="Product Code" name={'code'} value={code}
                                       onChange={this.handleChange}  />
                        {!this.state.delete && <Form.Control.Feedback>
                            {this.productChanged().code}
                        </Form.Control.Feedback>}
                        {!this.state.delete && <Form.Control.Feedback type={'invalid'}>
                            {code.length === 0 ? "You cannot have a blank product code" : "You must check create to add new products"}
                        </Form.Control.Feedback>}
                        {!this.state.delete && <Form.Control.Feedback type={'invalid'}>
                            <span className={'text-success'}> {this.productChanged().code} </span>
                        </Form.Control.Feedback>}
                    </Form.Group>
                </Form>

            </div>
            <div className={'col-5'}>
                <Form>
                    <Form.Group>
                        <Form.Control
                            size={'sm'}
                            disabled={this.state.delete || this.props.status}
                            isValid={true}
                            type="text"
                            required
                            placeholder="Product Description" name={'description'} value={existing ?  existing.description : description}
                            onChange={this.handleChange}/>
                        {!this.state.delete && <Form.Control.Feedback>
                            {this.productChanged().description}
                        </Form.Control.Feedback>}
                    </Form.Group>
                </Form>
            </div>
            <div className={'col-2 text-justify'}>
                {this.exists(code) && !this.state.delete && !this.props.status && <div>Product found! {product_stock} in stock!</div>}

                {
                    !this.exists(code) && code.length > 0 && <div className={'row m-0'}>
                        {!this.state.delete && <div className={'mr-2 col-12'}>It looks like this is a new product. </div>}
                        {!this.state.delete && <FormCheck
                            type="switch"
                            id={"custom-switch"+code}
                            variant={'success'}
                            label="Create? "
                            inline={true}
                            name={'create'}
                            checked={this.state.create}
                            onChange={this.setCreate}
                        />}

                    </div>
                }
                {(this.state.delete && !this.props.status) && <span className={'mr-2 text-danger'}>Product will be removed from the list on save </span>}
                {!this.props.status && <FormCheck
                    type="switch"
                    id={"custom-switch_delete"+code}
                    variant={'success'}
                    label="Delete? "
                    inline={true}
                    name={'delete'}
                    checked={this.state.delete}
                    onChange={this.setDelete}
                />}

            </div>
            <div className={'col-2'}>
                <h3 className={'w-25'} style={{display: 'inline'}}>
                    {!this.props.status && <FontAwesomeIcon className={'mr-1 text-danger'} icon={'minus-circle'} size={"1x"} onClick={this.removeCrate}/>}
                    <FontAwesomeIcon icon={'boxes'} size={"1x"}/>
                    <FormControl readOnly={this.props.status} className={'ml-1 m2-1'} style={{width: '65px', display: 'inline', textAlign: 'center', fontSize: '0.9em'}} type={'number'} value={this.state.crates.length} onChange={this.setCrates}/>

                    {!this.props.status && <FontAwesomeIcon className={'ml-1 text-success'} icon={'plus-circle'} size={"1x"} onClick={this.addCrate}/>}
                </h3>
                {cratesChanged.new > 0  && <div style={{display: 'inline'}} className={'ml-2 text-success'}><FontAwesomeIcon icon={'boxes'} size={"1x"}/> +{cratesChanged.new}</div>}
                {cratesChanged.new < 0  && <div style={{display: 'inline'}} className={'ml-2 text-danger'}><FontAwesomeIcon icon={'boxes'} size={"1x"}/> {cratesChanged.new}</div>}

            </div>
            {
                !this.state.create && this.similar().length > 0 && <div className={'col-10 row m-0 text-light bg-primary p-2 pt-0 rounded'}>
                    <h5 className={'text-light col-12'}>Did you mean...?</h5><br/>
                    {this.similar().slice(0, 9).map((sim, i) => {
                        return <div key={sim.code+'_sim_'+i} className={'text-justify m-0 row p-2 col-4'}>
                            <div className={'col-12'}>
                                <Button onClick={this.setToSimilar} data-code={sim.code} data-description={sim.description} className={'btn-sm btn btn-tertiary mr-2'}>Select</Button> {sim.code}<br/><sub>{sim.description}</sub>
                            </div>

                        </div>
                    })}
                    {
                        this.similar().length > 9 && <div className={'col-12 text-light text-right footer-text'}>
                            {
                                this.similar().length - 9
                            } more...
                        </div>
                    }
                </div>
            }

            {this.state.showCrates && <div className={'row m-3'} style={{transform: "rotate(180deg) scaleX(-1)"}}>
                {
                    crates.map((crate, i) => {
                        return <div key={`${code}_crate_${i}`} className={'col-3 small'} style={{transform: "rotate(180deg) scaleX(-1)"}}>
                            <div className={`${crate.new ? 'bg-success' : crate.deleted ? 'bg-danger' : 'bg-primary'}  position-relative p-4 m-1`}>
                                {
                                    crate.deleted &&
                                    <span onClick={this.readdCrate}
                                          data-index={i}
                                          className={' crate-delete'}
                                    >
                                    <FontAwesomeIcon
                                        style={{ pointerEvents: 'none'}}
                                        className={'text-primary interactive'}
                                        icon={'undo'}
                                        size={"2x"}
                                    /></span>
                                }
                                {
                                    !crate.deleted &&
                                    <span onClick={this.removeCrate}
                                          className={' crate-delete'}
                                          data-index={i}>
                                        <FontAwesomeIcon
                                            style={{transform: "rotate(180deg) scaleX(-1)", pointerEvents: 'none'}}
                                            className={'text-danger interactive'}
                                            icon={'minus-circle'}
                                            size={"2x"}
                                        />
                                    </span>
                                }
                                <input data-index={i}
                                       className={'form-control input-sm crate-input text-center'}
                                       name={'crate_' + i}
                                       value={crate.crate_no}
                                       onChange={this.updateCrateNumber}
                                />
                            </div>
                        </div>
                    })
                }
                <div className={'col-3 small'} style={{transform: "rotate(180deg) scaleX(-1)"}}>
                    <div className={"text-light bg-primary p-4 m-1"}>
                        <FontAwesomeIcon className={'interactive'} onClick={this.addCrate} icon={'plus-circle'} size={"3x"}/>
                    </div>
                </div>

            </div>}
            <hr className={'w-100 bg-primary'}/>
        </div>
    }
}

WizardProduct.propTypes = {
    existing_products: PropTypes.array.isRequired,
    product: PropTypes.object.isRequired,
    product_index: PropTypes.number.isRequired,
    product_code: PropTypes.string,
    updateProduct: PropTypes.func
};

export default WizardProduct;