import {
    Badge,
    Button,
    ButtonGroup, Col,
    FormCheck,
    FormControl, FormGroup,
    FormLabel,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter, Row, Table,
    ToggleButton
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {toast} from "react-toastify";
import * as PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";
import {SearchInput} from "../../../elements/SearchInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DATABASE_REFS from "../../../constants/db_refs";

import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import Error from "../../../elements/Error";
import {BayQuantitySelector} from "../../../elements/BayQuantitySelector";
import organisations from "../../../__fixtures__/organisations";
import {ContainerMaster, ContainerMasterDataContext} from "../../../components/ContainerMaster/ContainerMaster";
import PickingList from "./picking_list";
import Labels, {PrintListPass} from "./labels";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import {toastConfig} from "../../../config/toast_config";

function titleCase(str) {
    var sentence = str.toLowerCase().split(" ");
    for(var i = 0; i< sentence.length; i++){
        sentence[i] =  sentence[i].length === 0 ? '' : sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join(" ");
}
const BulkPickingList = ({show, customers, products, onHide, picking_list_id, firebase, organisation_id, user_name, organisation}) => {
    const [productView, setProductView] = useState(false);
    const [resend, isResend] = useState(false);
    const [notifying, setNotifying] = useState(false);
    const [tab, setTab] = useState('adding');

    const [compactView, setCompactView] = useState(true);
    const [picking_list, setList] = useState(!!picking_list_id ? false : {});
    const [customer, setCustomer] = useState(customers[0].id);
    const [recipient, setRecipient] = useState({});
    const [collections, setCollections] = useState({});
    const [filter, setFilter] = useState('');
    const [open_collections, setOpenCollections] = useState({});
    const updateOpenCollection = (collection_snap) => {
        const collection = collection_snap.val();
        const {pass_id, provisional_pass_id, picking_list_id, cancelled} = collection;

        if(pass_id) return;
        if(provisional_pass_id) return;
        if(picking_list_id) return;
        if(cancelled) return;
        setOpenCollections(colls => {


            colls[collection_snap.key] = collection;

            return {...colls}
        })

    };

    const deleteOpenCollection = (collection_snap) => {
        setOpenCollections(colls=>{

                const new_colls = {};
                Object.keys(colls).map((c) => {
                    if (c !== collection_snap.key) {
                        new_colls[collection_snap.key] = colls[c];
                    }
                });

                return {...new_colls}

        })
    };


    useEffect(() => {
        const ref = firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).orderByChild('pass_id').startAt(null).endAt(null);

        ref.on('child_added', updateOpenCollection);
        ref.on('child_changed', updateOpenCollection);
        ref.on('child_removed', deleteOpenCollection);
        return ()=>{
            ref.off('child_changed', updateOpenCollection);
            ref.off('child_added', updateOpenCollection);
            ref.off('child_removed', deleteOpenCollection);
        }
    }, [organisation_id, picking_list_id]);

    useEffect(()=>{
        if(!picking_list_id) return;
        const ref = firebase.db.ref(DATABASE_REFS.organisations.bulk.picking(organisation_id)).child(picking_list_id);

        ref.on('value', async (pl)=>{
            ref.child('pass_id').off()
            if(!pl.val()) return;
            const picking = pl.val();
            picking.id = pl.key;
            if(picking.customer) {
                setCustomer(picking.customer)
            }
            if(picking.recipient) {
                setRecipient(picking.recipient)
            }
            if(picking.collections) {
                for (let collection of Object.keys(picking.collections)) {

                    firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).child(collection).on('value', async (updated_coll_snap)=>{
                        const url_snap = await firebase.db.ref(DATABASE_REFS.organisations.request.collection(organisation_id)).child(updated_coll_snap.key).child("picking_list").once('value');

                            const url = url_snap.val() ? await firebase.storage.ref(url_snap.val()).getDownloadURL() : null;
                        setCollections( colls=>{
                            colls[updated_coll_snap.key] = updated_coll_snap.val()
                            colls[updated_coll_snap.key].url = url


                            if(tab==='verified') {
                                if (Object.values(colls).filter(c=>!c.locked).length === 0) {
                                    setTab('adding')
                                }
                            }
                            return {...colls}
                        })
                    })
                }

            }
            setList(old_picking=>{
                ref.child('pass_id').on('value', (new_pass_snap)=>{
                    const pass_id = new_pass_snap.val();
                    if(!pass_id) return;
                    setList(picking=>{
                        picking.pass_id = pass_id
                        return {...picking}
                    })
                })
                return picking
            })
        })
        return ref.off();
    }, [picking_list_id])

    if ((!(picking_list) && picking_list_id) || !open_collections) {
        return <Modal size={'lg'} show={show} onHide={onHide}>
            <ModalHeader className={'bg-primary text-light'}>
                <h3 className={'bg-primary text-light'}>Bulk Picking List {picking_list && picking_list.pass_id &&
                <span className={'text-danger ml-2'}>Gate pass Issued</span>}</h3>
            </ModalHeader>
            <ModalBody className={'bg-light'}><LoadingScreen/></ModalBody>
        </Modal>
    }
    const recipients = [...new Set(Object.values(Object.assign(open_collections, collections)).filter(c => c.customer === customer && c.recipient_ref).map(c => c.recipient_ref))];
    const needs_starting = Object.values(collections).filter(c=>!c.started).length > 0;
    const needs_verification = Object.values(collections).filter(c=>!c.locked).length > 0;
    return <Modal size={'xl'} show={show} onHide={onHide}>
        <ModalHeader className={'bg-primary text-light'}>
            <h3 className={'bg-primary text-light'}>Bulk Picking List {picking_list && picking_list.pass_id &&
            <span className={'text-danger ml-2'}>Gate pass Issued</span>}</h3>
        </ModalHeader>
        {<ModalBody className={'bg-light'}>

            <div>

                <Badge title={'Total collections in order'} className={'m-1 p-1 col-2 float-right'}
                       variant={'tertiary'}><h3 className={'m-0 text-light'}><FontAwesomeIcon className={'mr-1'}
                                                                                              icon={'file'}/>{Object.values(collections || {}).length}
                </h3></Badge>
                <Badge title={'Total crates in order'} className={'m-1 p-1 col-2 float-right'} variant={'secondary'}><h3
                    className={'m-0'}><FontAwesomeIcon className={'mr-1'}
                                                       icon={'boxes'}/> {Object.values(collections || {}).reduce((a, b) => a + (b.crates ? parseInt(b.crates) : parseInt(b.projected)), 0)}
                </h3></Badge>
                <h3 className={'w-25 mb-3'}>List ID: {picking_list ? picking_list.picking_list_number : 'Not saved'}</h3>
                <hr className={'w-100 bg-primary'}/>
                <div className={'row'}>


                <h3 className={'col-3'}><span>Group By:  </span><ButtonGroup className={'mb-1'} toggle size={'sm'}>
                    <ToggleButton variant={'info'} onChange={() => setProductView(true)} type="radio"
                                  checked={productView} name="radio" defaultChecked value="1">
                        {productView && <FontAwesomeIcon icon={'check'} className={'mr-1'}/>} Product
                    </ToggleButton>
                    <ToggleButton variant={'info'} onChange={() => setProductView(false)} type="radio"
                                  checked={!productView} defaultChecked name="radio" value="3">
                        {!productView && <FontAwesomeIcon icon={'check'} className={'mr-1'}/>} Collection Ref
                    </ToggleButton>
                </ButtonGroup>
                    {productView && <div>
                        <span>View Style:  </span>
                        <ButtonGroup className={'mb-1'} toggle size={'sm'}>
                            <ToggleButton variant={'info'} onChange={() => setCompactView(true)} type="radio"
                                          checked={compactView} name="radio" defaultChecked value="1">
                                {compactView && <FontAwesomeIcon icon={'check'} className={'mr-1'}/>} Compact
                            </ToggleButton>
                            <ToggleButton variant={'info'} onChange={() => setCompactView(false)} type="radio"
                                          checked={!compactView} name="radio" defaultChecked value="0">
                                {!compactView && <FontAwesomeIcon icon={'check'} className={'mr-1'}/>} Full
                            </ToggleButton>
                        </ButtonGroup>
                    </div> }
                </h3>

            <InputGroup size={'sm'} className="col-4 mb-3">
                <InputGroup.Prepend style={{minWidth: '15%'}}>
                    <InputGroup.Text style={{width: '-webkit-fill-available'}}
                                     id="basic-addon1">Customer</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl as={'select'} defaultValue={customer} onChange={(e) => {
                    setCustomer(e.target.value);
                    setRecipient('false');
                    setCollections({})
                }}>
                    <option className={'text-danger border-danger'} value={null}>-</option>
                    {customers.map(customer => <option key={`${customer.id}`}
                                                       value={customer.id}>{customer.name}</option>)}
                </FormControl>
            </InputGroup>
            <InputGroup size={'sm'} className="col-4 mb-3">
                <InputGroup.Prepend style={{minWidth: '15%'}}>
                    <InputGroup.Text style={{width: '-webkit-fill-available'}}
                                     id="basic-addon1">Recipient</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl as={'select'} defaultValue={recipient} onChange={(e) => {
                    setRecipient(e.target.value);
                    setCollections({})
                }}>
                    <option value={false}>-</option>
                    {recipients.map(recipient => <option key={`${recipient}`} value={recipient}>{recipient}</option>)}
                </FormControl>
            </InputGroup>
                    {picking_list_id && <ButtonGroup className={'col-1 mb-1'} toggle size={'sm'}><Button size={'sm'} onClick={() => {
                        firebase.functions.httpsCallable('resyncPickingList')({
                            organisation_id,
                            picking_id: picking_list_id
                        }).then(() => console.debug('Synced')).catch((e) => console.error(e));
                    }}>Resync</Button></ButtonGroup>}
                </div></div>
            {picking_list_id && <Col xs={12} className={'text-center'}><ButtonGroup size={'sm'} className={'m-auto'}>
                <Button onClick={()=>setTab('adding')} variant={'success'}> {tab === 'adding' && <FontAwesomeIcon className={'text-primary'} icon={'circle'}/>} Add Collections</Button>
                 {needs_verification && <Button onClick={()=>setTab('verifying')}  disabled={needs_starting} variant={'warning'}> {tab === 'verifying' && <FontAwesomeIcon className={'text-primary'} icon={'circle'}/>} Verify Collections</Button>}
                <Button disabled={Object.values(collections).filter(c=>!c.locked).length > 0} onClick={()=>setTab('issuing')}  variant={'info'}> {tab === 'issuing' && <FontAwesomeIcon className={'text-primary'} icon={'circle'}/>}{picking_list.pass_id ? 'View' : 'Issue'} Gate Pass</Button>
                <Button disabled={!picking_list.pass_id} onClick={()=>setTab('stocking')}  variant={'danger'}> {tab === 'stocking' && <FontAwesomeIcon className={'text-primary'} icon={'circle'}/>} Stock Out Collections</Button>
            </ButtonGroup></Col>}
            {needs_starting && <Col className={'rounded bg-danger text-light'}>One or more unstarted collections added, save to auto start collections.</Col> }
            <h3>Collections</h3>
            {(tab ==='adding' || (tab === 'verifying' && Object.values(collections).filter(c=>!c.locked).length === 0)) && <Col xs={12}>
            <div className={'row'}>
                <div className={'no-print col-12'}>
                    <span style={{float: 'left', minWidth: '10%'}}>Filter: </span>
                    <SearchInput setFilter={(e) => setFilter(e.target.value)}/>
                </div>
                {/* Date filter */}

            </div>

            {Object.values(Object.assign((picking_list.pass_id ? {} : open_collections), collections)).filter(oc=>oc.collection_ref.toLowerCase().indexOf(filter.toLowerCase()) > -1).map((vc) =>
                <FormLabel className={`badge-lg badge m-1 ${!!vc.started ? 'badge-primary' : 'badge-danger'}`} style={{fontSize: '1.1em'}}
                           key={`${vc.customer}_${vc.id}`} column={false}>
                    <FormCheck
                        disabled={(picking_list && picking_list.pass_id) || vc.unknown_products}
                        checked={!!collections[vc.id]}
                        onChange={() => {
                            if (!collections[vc.id]) {
                                firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).child(vc.id).on('value', (updated_coll_snap)=>{
                                    setCollections(colls=>{
                                        colls[updated_coll_snap.key] = updated_coll_snap.val()
                                        if(tab==='verified') {
                                            if (Object.values(colls).filter(c=>!c.locked).length === 0) {
                                                setTab('adding')
                                            }
                                        }
                                        return {...colls}
                                    })
                                })
                                return;

                            }
                            setCollections(collections => {

                                firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).child(vc.id).off()
                                return {...Object.filter(collections, c => c.id !== vc.id)}
                            })

                        }}
                    />
                    {vc.collection_ref} <br/><Badge variant={'light'} className={'text-dark m-1'}>
                    <FontAwesomeIcon className={'mr-1'} icon={'boxes'}/>
                    {vc.crates ? vc.crates : vc.projected}</Badge>
                    {vc.invalid_products && <span title={ `Unknown products: \n${vc.invalid_products.join('\n')}`} className="fa-layers fa-fw mr-1">
                            <FontAwesomeIcon className={'text-secondary'} size={'sm'} icon={'box'}/>
                            <FontAwesomeIcon className={'text-light'} icon={'question'}/>
                        </span>}

                    <br/><Badge variant={'info'}
                                                                              className={'text-dark m-1'}>
                    <FontAwesomeIcon className={'mr-1'} icon={'clock'}/>
                    {new Date(parseInt(vc.expected)).toLocaleDateString()}</Badge>
                    <br/>{vc.url && <a className={'text-light float-right'} href={vc.url} target={'_blank'} download={`${vc.collection_ref}.pdf`}> <FontAwesomeIcon className={'mr-1'} icon={'download'}/></a>}
                </FormLabel>

            )}
            </Col>}
            {tab ==='verifying' && Object.values(collections).filter(c=>!c.locked).length > 0 && <Col xs={12}>
                {Object.values(collections).filter(c=>!c.locked).length === 0  && <span>All verified!!</span>}
                 {Object.values(collections).filter(c=>!c.locked).map(collection => {

                     return <ContainerMasterDataContext.Consumer>
                         { ({products, bays})=><CollectionVerifier callback={()=>{
                            if(tab==='verified') {
                                if (Object.values(collections).filter(c=>!c.locked).length === 0) {
                                    setTab('adding')
                                }
                            }
                         }
                         } products={products} bays={bays} collection_id={collection.id} firebase={firebase} organisation_id={organisation_id}/>}</ContainerMasterDataContext.Consumer>
                })}
            </Col>}
            {tab === 'issuing' && <Col xs={12}>
            <BulkGatePassIssuer user_name={user_name} organisation={organisation} customer_name={customers.find(c=>c.id===customer).name} collections={collections} customer={customer} key={picking_list_id+picking_list.pass_id} picking_list={picking_list} firebase={firebase} organisation_id={organisation_id}/>
        </Col>}
            {tab === 'stocking' && <Col xs={12}>
                <Row className={'text-left'}>
                {Object.values(collections).filter(coll=>!coll.stocked_out).length > -1 && <Col size={6} className={'bg-primary text-light text-center p-2 rounded'}>
                    <h3 className={'w-50 text-light'}>Stock out all collections</h3>
                    <Button variant={'danger'} onClick={()=>{
                        Object.values(collections).filter(c=>!c.stocked_out).map(c=>firebase.db.ref(DATABASE_REFS.organisations.stock_movement.stock_out(organisation_id)).child(c.id).update({
                            verified: true,
                            bay_error: null,
                            completed: true,
                            processed_on: new Date().getTime(),
                            processed_by: {
                                name: user_name,
                                displayName: user_name,
                                uid: firebase.auth.currentUser.uid,
                                email: firebase.auth.currentUser.email
                            }
                        }))
                        Object.values(collections).filter(c=>c.stocked_out && c.collection_status==="Ready To Stock Out").map(c=>firebase.db.ref(DATABASE_REFS.organisations.stock_movement.stock_out(organisation_id)).child(c.id).update({
                            completed: true,
                        }))
                        Object.values(collections).map(c=>firebase.db.ref(DATABASE_REFS.organisations.request.collection(organisation_id)).child(c.id).update({
                            verified: true,

                        }))

                    }
                    }>Stock Out</Button>
                </Col> }
                {Object.values(collections).filter(coll=>!coll.notified).length > -1 && <Col size={6} className={'bg-primary text-light text-center p-2 rounded'}>
                    <h3 className={'w-100 text-light'}>Send notifications for all collections</h3>
                    <FormCheck onChange={()=>isResend(!resend)} checked={resend} name={'resend'} label={'Resend Previously Notified'}/>
                    <Button variant={'info'} onClick={()=>{
                        setNotifying(true)
                        firebase.functions.httpsCallable('bulkNotifyContactsCollection')({
                            customer_id: customer, organisation_id, picking_list_id, resend
                        }).then((res)=>{
                            if (res.data.error) {
                                toast.error(res.data.error, toastConfig);
                            }
                            if (res.data.success) {
                                toast.success(`${res.data.success} notifications sent`, toastConfig);
                            }
                            setNotifying(false)
                        })


                    }
                    } disabled={(Object.values(collections).filter(coll=>!coll.notified).length === 0 && !resend) || notifying}>Notify ({Object.values(collections).filter(coll=>!coll.notified || resend).length})</Button>
                </Col> }
                </Row>
                <Table size={'sm'}>
                    <thead className={'bg-primary text-light'}>
                    <tr>
                        <th>Reference</th>
                        <th>Stocked Out</th>
                        <th>Notified</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.values(collections).map(coll=>{
                        console.log(coll)
                        return  <tr key={`stock_row_${coll.id}`}>
                            <td>{coll.collection_ref}</td>
                            <td>{coll.stocked_out && coll.collection_status !== "Ready To Stock Out" ? <FontAwesomeIcon icon={'check'}/> : <FontAwesomeIcon icon={'times'}/>}</td>
                            <td>{coll.notified ? <FontAwesomeIcon icon={'check'}/> : <FontAwesomeIcon icon={'times'}/>}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </Col>}
        </ModalBody>}
        <ModalFooter className={'bg-primary text-light'}>
            <ButtonGroup size={'sm'}>
                {(!needs_starting) && picking_list_id && customer && <PickingList productView={productView} compactView={compactView} user_name={user_name} firebase={firebase} organisation_id={organisation_id} collections={collections} picking_list={{...picking_list, customer_name: customers.find(c=>c.id===customer).name}}/>}
                {(!needs_starting) && picking_list_id && customer &&  <Labels collections={collections} picking_list={picking_list} />}
                {!picking_list.pass_id && <Button variant={'tertiary'} onClick={() => {
                    const collections_to_save = {};
                    Object.values(collections).map(c => {
                        const {id, products, crates, projected, collection_ref, locked, expected} = c;
                        return collections_to_save[c.id] = {
                            id,
                            products,
                            grand_total: crates ? crates : projected,
                            collection_ref,
                            completed: locked,
                            expected
                        }
                    });
                    if(picking_list_id) {
                        firebase.db.ref(DATABASE_REFS.organisations.bulk.picking(organisation_id)).child(picking_list_id).update({
                            customer,
                            recipient,
                            collections: collections_to_save
                        }).then(() => onHide())
                    } else {
                        firebase.db.ref(DATABASE_REFS.organisations.bulk.picking(organisation_id)).push({
                            customer,
                            recipient,
                            collections: collections_to_save
                        }).then(() => onHide())
                    }
                }}>Save</Button>}
                <Button variant={'danger'} onClick={onHide}>Cancel</Button>
            </ButtonGroup>


        </ModalFooter>
    </Modal>
};

const CollectionVerifier = ({customer, callback, collection_id, firebase, organisation_id, products, bays}) => {
    const [loading, setLoading] = useState(true);
    const [showPDF, setShowPDF] = useState(false);
    const [collection, setCollection] = useState(null);
    const verifyCollection = () =>{
        const actual_result = collection.products.actual;
        const ref =  firebase.db.ref(DATABASE_REFS.organisations.stock_movement.stock_out(organisation_id)).child(collection_id);
        ref.update({
            products: actual_result,
            completed: true,

        }).then(() => {
           toast.success("Verified collection: "+collection.collection_ref)
            callback();
        });
    }
    const updateBays = (old_bay, product_code, new_bay, quantity ) => {
        setCollection(old_coll=>{
            if(!old_coll.products.actual) {
                old_coll.products.actual = {}
            }
            if(!old_bay) {
                if(!old_coll.products.actual[product_code]) {
                    old_coll.products.actual[product_code] = {crates: {}}
                }
                if(!old_coll.products.actual[product_code].crates) {
                    old_coll.products.actual[product_code].crates = {}
                }
                old_coll.products.actual[product_code].crates[new_bay] = {
                    code: bays[new_bay].code,
                    qty: quantity
                }
            } else if (old_bay === new_bay) {
                old_coll.products.actual[product_code].crates[new_bay].qty = quantity;
            } else {
                const new_coll_crates = Object.filter(old_coll.products.actual[product_code].crates, (b, bkey)=>bkey!==old_bay)
                new_coll_crates[new_bay] = {
                    code: bays[new_bay].code,
                    qty: quantity
                }
                old_coll.products.actual[product_code].crates = new_coll_crates;
            }
            return {...old_coll}
        })
    };
    useEffect(()=>{
        const ref = firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).child(collection_id);
        ref.on('value', async (res)=>{
            if(res.val()) {
                const url_snap = await firebase.db.ref(DATABASE_REFS.organisations.request.collection(organisation_id)).child(collection_id).child("picking_list").once('value');

               const collection = res.val()
                if(url_snap.val())
                    collection.url = await firebase.storage.ref(url_snap.val()).getDownloadURL()
                setCollection(collection);
                setLoading(false)
            }
        })
        return ()=>ref.off();
    }, [collection_id])
    if (loading) return <LoadingScreen/>
    const {actual, expected} = collection.products;
    const requested_products = expected.map(p=>p.code)
    const allocated_products = Object.keys(actual);
    const all_products = [...new Set([...requested_products, ...allocated_products])]
    return  <div id="printarea" className={'row bg-light h-auto'}>

        <Col xs={3}><h3>{collection.collection_ref}</h3></Col>
        <Col xs={9}><ButtonGroup size={'sm'}>
            {collection.url &&  <Button onClick={()=>setShowPDF(!showPDF)}>{showPDF ? "Hide Original Picking Order" : "View Original Picking Order"}</Button>}
            <Button onClick={verifyCollection} variant={'success'}>Verify</Button>
        </ButtonGroup></Col>
        {collection.url && showPDF && <Col xs={12}>
            <iframe width={"100%"} height={300} src={collection.url}/>
        </Col>}
        <hr className={'w-100 bg-primary'}/>
        {all_products.map((pkey) => {
            const expected_p = expected.find(p=>p.code===pkey);
            const product_record = Object.values(products).find(p=>p.code===pkey)
            if(!product_record) return <span>&nbsp;</span>
            const actual_p = actual[pkey] || {};
            const product_data = {
                code: pkey,
                expected: expected_p ? parseInt(expected_p.crates) : 0,
                description: product_record.description,
                allocated: {
                    bays: actual_p.crates,
                    total: Object.values(actual_p.crates || {}).reduce((a, b)=>a+parseInt(b.qty), 0)
                }
            }
            return (
                <div key={`product_${pkey}`} className={'row col-12 col-xl-6 m-0 print-product'}>
                    <div className={'col-6 p-1'}>
                        {pkey}<br className={'no-print'}/>
                        <sub
                            className={'no-print'}>{product_data.description}</sub>
                        <p className={'m-0 print-only'}>{product_data.description}</p>
                    </div>
                    <div className={'col-3 p-1 text-center no-print'}>
                        {product_data.expected}
                    </div>
                    <div className={'col-6 p-1 pl-3 text-right print-only'}>
                        <h1 className={'text-center print-only'}>Requested:  {product_data.expected}</h1>
                    </div>
                    <div className={'col-3 p-1 no-print'}>
                        <FormControl
                            className={'no-print'}
                            type={'number'}
                            name={'actual/' + pkey + '/total_crates'}
                            value={product_data.allocated.total}
                            readOnly={true}
                            onChange={()=>{}}
                        />

                    </div>
                    <div className={'row no-print w-100'}>
                        {
                            Object.keys(product_data.allocated.bays || {}).map(crate_bay_key => {

                                return (
                                    <BayQuantitySelector
                                        key={`${pkey}_${crate_bay_key}`}
                                        selector
                                        qty={parseInt(product_data.allocated.bays[crate_bay_key].qty)}
                                        onChange={updateBays} bays={Object.filter(bays, b=>b.product===pkey)}
                                        adjustments={{}}
                                        current_bay={crate_bay_key} pkey={pkey}/>
                                );

                            })
                        }
                        {
                            parseInt(product_data.allocated.total) !== parseInt(product_data.expected) &&
                            <BayQuantitySelector selector qty={0} onChange={updateBays}
                                                 adjustments={collection.adjustments}
                                                 bays={Object.filter(bays, b=>b.product===pkey)} current_bay={''}
                                                 pkey={pkey}/>

                        }
                    </div>



                    <hr className={'bg-primary w-100'}/>
                </div>)

        })}

    </div>
}

const BulkGatePassIssuer = ({user_name, customer_name, picking_list, firebase, organisation_id, customer, collections, organisation}) => {
    const {pass_id, provisional_pass_id} = picking_list;
    const [pass_number, setPassNumber] = useState('');
    const [driver, setDriver] = useState('');
    const [vehicle_reg, setVehicleReg] = useState('');
    const [haulier, setHaulier] = useState('');
    const [issued_on, setIssuedOn] = useState(null);

    const fetchPass = async () => {
        const pass_snap = await firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).child(pass_id || provisional_pass_id).once('value');
        if(pass_snap.val()) {
            const pass = pass_snap.val();
            setPassNumber(pass.pass_number)
            setDriver(pass.driver)
            setVehicleReg(pass.vehicle_reg)
            setHaulier(pass.haulier)
            setIssuedOn(pass.issued_on)
        }
    }
    useEffect(()=>{
        if(pass_id || provisional_pass_id) {
            fetchPass();
        }
    }, [pass_id, provisional_pass_id])

    return <Col xs={12}>
        <h5>Issued on: {issued_on ? new Date(parseInt(issued_on)).toLocaleString() : 'Never'}</h5>
        <FormGroup>
            <FormLabel name={'vehicle_reg'} column={false}>Vehicle Reg.</FormLabel>
            <FormControl isInvalid={!vehicle_reg || vehicle_reg.length === 0}
                         size={'sm'} value={vehicle_reg} onChange={e=>setVehicleReg(e.target.value.toUpperCase())}
                         disabled={!!pass_id}
                         name={'vehicle_reg'}/>
        </FormGroup>
        <FormGroup>
            <FormLabel column={false}>Haulier</FormLabel>
            <FormControl isInvalid={!haulier || haulier.length === 0} size={'sm'}
                         disabled={!!pass_id}
                         name={'haulier'} value={haulier}
                         onChange={(e)=>setHaulier(e.target.value.toUpperCase())}/>
        </FormGroup>
    <FormGroup>
    <FormLabel column={false}>Driver Name</FormLabel>
    <FormControl isInvalid={!driver || driver.length === 0} size={'sm'}
                 disabled={!!pass_id}
                 name={'driver'} value={driver}
                 onChange={(e)=>setDriver(e.target.value.toUpperCase())}/>
    </FormGroup>
    <FormGroup>
        <FormLabel column={false}>Pass Number</FormLabel>
        <FormControl readOnly={true} size={'sm'} name={'pass_number'} defaultValue={pass_number}/>
    </FormGroup>
        {!pass_id && <Button onClick={()=>{
            if(pass_id || provisional_pass_id) {
                firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).child(pass_id || provisional_pass_id).update({
                    driver, vehicle_reg, haulier, issued: true, issued_on: new Date().getTime()
                })
            } else {
                const lists = {};
                lists[picking_list.id] = picking_list
                const issued_by = {
                    name: user_name,
                    displayName: user_name,
                    email: firebase.auth.currentUser.email,
                    uid: firebase.auth.currentUser.uid
                }
                firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).push({
                    issued_by, customer, driver, vehicle_reg, haulier, issued: true, issued_on: new Date().getTime(), created: new Date().getTime(), picking_lists: lists
                })
            }

        }} disabled={(!haulier || haulier.length === 0) || (!driver || driver.length === 0) || (!vehicle_reg || vehicle_reg.length === 0)}>Issue</Button>}
        {pass_id && <PrintListPass user_name={user_name} organisation={organisation} list_total={Object.values(collections).reduce((a,b)=>a+parseInt(b.crates_val),0)} picking_list={{...picking_list, collections}} firebase={firebase} organisation_id={organisation_id} customer={customer_name} pass_id={pass_id || provisional_pass_id}/> }
    </Col>
};
BulkPickingList.propTypes = {
    show: PropTypes.bool.isRequired,
    customers: PropTypes.array.isRequired,
    onHide: PropTypes.func.isRequired,
    existing: PropTypes.object,
    firebase: PropTypes.object.isRequired,
    organisation_id: PropTypes.string.isRequired
};
export default BulkPickingList;