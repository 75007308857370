import React from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Landing from "../../Landing";
import {AuthUserContext} from "../../../components/Session";
import * as PropTypes from 'prop-types';
import profileImg from './img/silhouette.jpg'
import Image from "../../../elements/Image";
import {withFirebase} from "../../../components/Firebase";
import {Button, ButtonGroup, FormControl, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import moment from "moment";


class Profile extends React.Component {
    render() {
        return (
            <div data-testid={"profile"}>
                <AuthUserContext.Consumer>
                    {
                        authUser => {
                            return authUser ? <ProfilePage user={authUser} firebase={this.props.firebase}/> :
                                <Landing/>;
                        }
                    }
                </AuthUserContext.Consumer>
            </div>
        );
    }
}

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const {user, firebase} = this.props;
        firebase.db.ref("registrations").child(user.uid).on('value', (registrations_snap) => {
            this.setState({
                original_registrations: registrations_snap.val()
            });
            if(!this.state.registrations) {
                this.setState({
                    registrations: registrations_snap.val()
                })
            }
        })
    }

    isRegistrationChanged(orig, changed) {
        return !_.isEqual(orig, changed)
    }

    componentWillUnmount() {
        const {user, firebase} = this.props;
        firebase.db.ref("registrations").child(user.uid).off();
    }

    render() {
        const {user, firebase} = this.props;
        const {registrations} = this.state;
        return (
            <div data-testid={"profilePage"}>
                <div className={'offset-lg-2 col-12 col-lg-8 bg-primary text-light p-5'}>
                    <h3 className={'text-light'}>
                        <FormattedMessage
                            id={"app.profile_heading"}
                            defaultMessage={"Profile"}
                        />
                    </h3>
                    {
                        user &&
                        <div className={'row'}>

                            <div className={'col-xs-8 col-sm-9'}>
                                <p>Email: {user.email}</p>
                                <p>Name: {user.displayName}</p>
                            </div>
                            <div className={'col-xs-4 col-sm-3'}>
                                <Image alt_id={'app.profile'} className={'img-thumbnail img-fluid rounded-circle'}
                                       src={profileImg}/>
                            </div>
                        </div>
                    }
                    {
                        registrations && <Table size={'sm'} responsive={false} variant={'primary'}>
                            <thead>
                            <tr>
                                <th colSpan={3}>Devices registered for notifications</th>
                            </tr>
                            <tr>
                                <td className={'text-center'}>This device</td>
                                <td>Name</td>
                                <td>Registered On</td>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(registrations).map((registration) => {
                                const {name, registered_on, accessed_on} = registrations[registration];
                                return <tr key={registration}>
                                    <td className={'text-center'}>{registration === user.messaging_token ?
                                        <FontAwesomeIcon className={'text-success'} icon={['far', 'check-circle']}/> :
                                        <FontAwesomeIcon className={'text-danger'} icon={'times-circle'}/>}
                                    </td>
                                    <td><FormControl name={`${registration}/name`} value={name} onChange={(e) => {
                                            const [reg, key] = e.target.name.split('/');
                                            const new_registrations = this.state.registrations;
                                            new_registrations[reg][key] = e.target.value;
                                            this.setState({registrations: registrations})
                                    }
                                    }/></td>
                                    <td>{new Date(parseInt(registered_on)).toLocaleString()} <span className={'small'}>Active {moment(new Date(parseInt(accessed_on))).fromNow()}</span></td>
                                    <td>
                                        <ButtonGroup size={'sm'}>
                                            {this.isRegistrationChanged(this.state.original_registrations[registration], registrations[registration]) &&
                                            <Button onClick={()=>{
                                                firebase.db.ref('registrations').child(user.uid).child(registration).child('name').set(registrations[registration].name).then(() => {});
                                            }}>Save</Button>}
                                            <Button variant={'danger'} onClick={() => {
                                                this.setState({registrations: null}, ()=> {
                                                    firebase.db.ref('registrations').child(user.uid).child(registration).set(null).then(()=>{

                                                    })
                                                })

                                            }}>Delete</Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
        );
    }
}

ProfilePage.propTypes = {
    firebase: PropTypes.object.isRequired
};

export {ProfilePage, Profile}
export default injectIntl(withFirebase(Profile));