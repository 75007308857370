import React from 'react';

import Input from "../../elements/Input";
import Button from "../../elements/Button";
import {withFirebase} from "../Firebase";
import Error from "../../elements/Error";
import {toast} from "react-toastify";
import {toastConfig} from "../../config/toast_config";

const INITIAL_STATE = () => {
    return {
        email: '',
        password: '',
        error: false
    }
};

class SignInForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE();
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.errors = React.createRef();
    }

    handleChange(event) {
        let newState = {};
        newState[event.target.name] = event.target.value;
        this.setState(newState)
    }
    onSubmit() {
        const {email, password} = this.state;
        return this.props.firebase.doSignInWithEmailAndPassword(email, password).then((authUser) => {
            toast.success(`Logged in as ${authUser.email}!`, {...toastConfig, autoClose: 2000});
        }).catch((error) => {
            this.errors.current.setError(error);
        })
    }

    render() {
        const {error} = this.state;
        return (
            <div data-testid="sign_in_form" className={'col-12 col-lg-4 bg-primary text-light p-5'}>
                <h3 className={'text-light'}>Sign in to your account</h3>

                <Error ref={this.errors} error={error}/>

                <Input
                    name={"email"}
                    label_id={"app.email_label"}
                    handleChange={this.handleChange}
                    value={this.state.email}
                    required={true}
                    type={'email'}
                />
                <Input
                    name={"password"}
                    label_id={"app.password"}
                    type={"password"}
                    handleChange={this.handleChange}
                    value={this.state.password}
                    required={true}
                />

                <Button onClick={this.onSubmit} title_id={"app.login"} className={"btn-tertiary text-light form-control"}/>
            </div>

        );
    }
}

SignInForm.propTypes = {

};

export { SignInForm }
export default withFirebase(SignInForm);