import React from 'react';
import {Dropdown, DropdownButton, InputGroup} from 'react-bootstrap';
import * as PropTypes from 'prop-types';
import {capacity_units} from '../constants/options';

class UnitInput extends React.Component {
    constructor(props) {
        super(props);
        this.updateUnit = this.updateUnit.bind(this);
        this.value = React.createRef();
        this.unit = React.createRef();
    }

    updateUnit(eventKey) {
        this.props.handleChange({
            target: {
                name: this.props.unitName,
                value: eventKey
            }
        });
    }

    render() {
        const {value, unit, units, unitName, valueName} = this.props;
        return (
                <InputGroup size={'sm'}>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-sm">Capacity</InputGroup.Text>
                    </InputGroup.Prepend>
                    <input ref={this.value} placeholder={"label"} id={this._id} aria-label={""} step={"0.01"} onChange={this.props.handleChange} name={valueName} value={value} type={"number"} className={"form-control"}/>

                    <DropdownButton
                        id={unitName}
                        className={'bg-primary text-dark'}
                        as={InputGroup.Append}
                        title={units[unit]}
                       ref={this.unit}
                        >
                        { Object.keys(units).map((u) => <Dropdown.Item key={u} onSelect={this.updateUnit} eventKey={u}>{units[u]}</Dropdown.Item>)}
                </DropdownButton>
                </InputGroup>
        );
    }
}

UnitInput.propTypes = {
    units: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string,PropTypes.number]).isRequired,
    unit: PropTypes.string.isRequired,
    unitName: PropTypes.string,
    valueName: PropTypes.string,
    handleChange: PropTypes.func.isRequired
};
UnitInput.defaultProps = {
    units: capacity_units,
    valueName: "capacity",
    unitName: "capacity_units"
};

export default UnitInput;