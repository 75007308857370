import {Button, Image, Modal, Tab, Table} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import Tabs from "react-bootstrap/Tabs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import DATABASE_REFS from "../../constants/db_refs";
import storage_refs from "../../constants/storage_refs";
import PickingList from "../../pages/authenticatedPages/BulkPickingList/picking_list";

import ReactToPrint from "react-to-print";


const MovementViewer = ({role, movement, onHide, firebase, organisation_id, setViewing, products, customers}) => {


    const [details, setDetails] = useState(movement || {});
    const [picking_list, setPickingList] = useState(null);
    const [picking_list_id, setPickingListId] = useState(null);
    const [url, setUrl] = useState(null);
    const [podUrl, setPodUrl] = useState(null);
    const [pass, setPass] = useState(null);

    const {referenceKey: movement_id, event, eventDate, pass_id, status} = details || {};
    const stocked = role !== 'customer' && status && status.startsWith('Stocked');
    const movementStage = (!pass_id || (status && (status.startsWith('Collection') || status.startsWith('Outturn')))) ? 'pending' : stocked ? 'stocked' : 'completed';
    const setPassData = (res) => {
        setPass(res.val())
    };

    const setListData = (res) => {
        setPickingList(res.val())
    };

    const fetchPDFUrl = async () => {
        const list_location = event === 'collection' ? await firebase.db.ref(DATABASE_REFS.organisations.request.collection(organisation_id)).child(movement_id).child('picking_list').once('value')
            : await firebase.db.ref(DATABASE_REFS.organisations.request.devan(organisation_id)).child(movement_id).child('packing_list').once('value');
        if (list_location.val()) {
            firebase.storage.ref(list_location.val()).getDownloadURL().then(url => setUrl(url))
        }
    };

    const fetchPodUrl = async () => {
        const list_location = event === 'collection' ? await firebase.db.ref(DATABASE_REFS.organisations.stock_movement.stock_out(organisation_id)).child(movement_id).child('pod').once('value')
            : await firebase.db.ref(DATABASE_REFS.organisations.stock_movement.stock_in(organisation_id)).child(movement_id).child('pod').once('value');
        const ref = event === 'collection' ? storage_refs.collection_pod(organisation_id, movement.customer_id, movement.referenceKey, movement.reference) :
            storage_refs.outturn_pod(organisation_id, movement.customer_id, movement.referenceKey, movement.reference);
        if (list_location.val()) {
            firebase.storage.ref(ref).getDownloadURL().then(url => {
                setPodUrl(url)
            })
        }
    };

    const updateDetails = (res) => {
        setDetails(res.val());
    };
    const updatePickingListId = (res) => {
        setPickingListId(res.val())
    };

    const trackMovement = () => {
        const movementRef = movementStage === 'pending' ? firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'pending')).child(movement_id)
            : firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed')).child(movement_id);
        const bulkPickingListRef = firebase.db.ref(DATABASE_REFS.organisations.stock_movement.stock_out(organisation_id)).child(movement_id).child('picking_list_id');
        movementRef.on('value', updateDetails);
        bulkPickingListRef.on('value', updatePickingListId);
        return () => {
            movementRef.off('value', updateDetails);
            bulkPickingListRef.off('value', updatePickingListId)
        }
    };

    useEffect(() => {
        fetchPDFUrl().then(() => {
        });
        return trackMovement();

    }, [movement]);

    useEffect(() => {
        fetchPodUrl().then(() => {
        });

    }, [movement]);

    useEffect(() => {
        if (details === null) {
            onHide();
            return;
        }

        const passRef = details.pass_id ? event === 'collection' ? firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).child(details.pass_id) : firebase.db.ref(DATABASE_REFS.organisations.devan_gate_passes(organisation_id)).child(details.pass_id) : null;
        if (passRef)
            passRef.on('value', setPassData);
        return () => {
            if (passRef)
                passRef.off('value', setPassData)
        }

    }, [details]);
    useEffect(() => {
        const listRef = picking_list_id ? firebase.db.ref(DATABASE_REFS.organisations.bulk.picking(organisation_id)).child(picking_list_id) : null;
        if (listRef)
            listRef.on('value', setListData);
        return () => {
            if (listRef)
                listRef.off('value', setListData)
        }

    }, [picking_list_id]);
    const headerBGClass = movementStage === 'pending' ? 'bg-info' : movementStage === 'completed' ? 'bg-success' : 'bg-danger';

    return <Modal show={true} size={'lg'} onHide={onHide}>

        <Modal.Header className={`text-light ${headerBGClass}`}>
            {event === 'outturn' && <FontAwesomeIcon icon={'ship'} size={'2x'}/>}
            {event === 'collection' && <FontAwesomeIcon icon={'truck'} size={'2x'}/>}
            {details && <h3 className={'text-light'}>{details.reference}</h3>}
        </Modal.Header>
        <Modal.Body>
            <Tabs defaultActiveKey="details" variant={'pills'}>
                {url && <Tab eventKey="list" title="Original Request">
                    <iframe src={url} style={{width: "100%", height: "500px"}}/>
                </Tab>}
                {podUrl && <Tab eventKey="pod" title="POD">
                    <iframe src={podUrl} style={{width: "100%", height: "500px"}}/>
                </Tab>}
                {details && <Tab eventKey="details" title="Movement Details">
                    <Table bordered={true} className={'mb-2'}>
                        <tbody>
                        <tr>
                            <th>Reference</th>
                            <td>{details.reference}</td>
                        </tr>
                        <tr>
                            <th>Date {movementStage === 'pending' ? 'Requested' : 'Collected'}</th>
                            <td>{new Date(parseInt(eventDate)).toLocaleString()}</td>
                        </tr>
                        <tr>
                            <th colSpan={4}>Products</th>
                        </tr>
                        {details.products && <ProductList products={details.products} all_products={products || {}}/>}
                        </tbody>
                    </Table>
                    {picking_list &&
                    <PickingListView firebase={firebase} products={products} organisation_id={organisation_id} setViewing={setViewing}
                                     movementStage={movementStage} movement_id={movement_id} picking_list={picking_list || {}}
                                     eventDate={eventDate}/>}
                                     {(!picking_list && event === 'collection') &&
                                     <ElectronicCollectionItem customers={customers} firebase={firebase} organisation_id={organisation_id}
                                                            collection_id={movement_id} products={products}/>}
                    {event === 'outturn' && <ElectronicOutturnItem customers={customers} firebase={firebase} organisation_id={organisation_id}
                                                                   outturn_id={movement_id} products={products}/>}
                </Tab>}

                {pass && <Tab eventKey="pass" title="Gate Pass">
                    <Table bordered={true} className={'mb-2'}>
                        <tbody>
                        <tr>
                            <th>Pass Number</th>
                            <td>{pass.pass_number}</td>
                        </tr>
                        <tr>
                            <th>Driver</th>
                            <td>{pass.driver}</td>
                        </tr>
                        <tr>
                            <th>Haulier</th>
                            <td>{pass.haulier}</td>
                        </tr>
                        <tr>
                            <th>Vehicle Reg.</th>
                            <td>{pass.vehicle_reg}</td>
                        </tr>
                        {pass.issued_on && <tr>
                            <th>Issued On</th>
                            <td>{new Date(pass.issued_on).toLocaleString()}</td>
                        </tr>}
                        {pass.issued_by && <tr>
                            <th>Issued By</th>
                            <td>{pass.issued_by.displayName}</td>
                        </tr>}
                        </tbody>
                    </Table>
                    <GatePassRelatedView setViewing={setViewing} movementStage={movementStage} eventDate={eventDate}
                                         movement_id={movement_id} collections={pass.collections || {}}
                                         firebase={firebase} organisation_id={organisation_id}
                                         picking_lists={pass.picking_lists || {}} pass_number={pass.pass_number}
                                         outturns={pass.outturns || {}}/>
                </Tab>}
            </Tabs>
        </Modal.Body>
        <Modal.Footer><Button onClick={onHide} variant={'secondary'}>Close</Button></Modal.Footer>
    </Modal>
};

const PickingListView = ({picking_list, firebase, organisation_id, movement_id, setViewing, movementStage, sublist, hidePrint, products}) => {
    const [collections, setCollections] = useState(null);
    const fetchCollections = async () => {
        const new_collections = {};
        const cks = Object.keys(picking_list.collections || {});
        for (let ck of cks) {
            new_collections[ck] = (await firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).child(ck).once('value')).val();
        }
        setCollections(new_collections)
    };
    useEffect(() => {
        fetchCollections().then(()=>{});
    }, []);
    return <div className={'w-100'}>
        <h4>{sublist ? 'Picking Lists' : 'Related'}</h4>
        <fieldset className={'w-100 livetracker-popup'} >
            <legend><h4>{picking_list.picking_list_number}</h4></legend>
            {collections && (!hidePrint) && <PickingList products={products} collections={collections} picking_list={picking_list} firebase={firebase} organisation_id={organisation_id}/>}
            {Object.keys(picking_list.collections || {}).map(ck => {
                return <Button key={ck + "related_button_bulk"}
                               className={'m-1 w-25'}
                               style={{display: 'inline'}}
                               disabled={ck === movement_id}
                               variant={'primary'} size={'lg'}
                               onClick={() => setViewing(() => {
                                   return {...picking_list.collections[ck], movementStage, referenceKey: ck}
                               })}
                >
                    {picking_list.collections[ck].collection_ref}

                </Button>
            })
            }
        </fieldset>
    </div>
};

const GatePassRelatedView = ({firebase, organisation_id, pass_number, outturns, collections, picking_lists, movement_id, setViewing, movementStage, eventDate}) => {
    return <div>
        <h4>Related</h4>
        <fieldset className={'w-100 livetracker-popup'} >
            <legend><h4>{pass_number}</h4></legend>
            {Object.keys(collections || {}).map(ck => {
                return <Button key={ck + "related_button_bulk"}
                               className={'m-1 w-25'}
                               style={{display: 'inline'}}
                               disabled={ck === movement_id}
                               variant={'primary'} size={'lg'}
                               onClick={() => setViewing(() => {
                                   return {...collections[ck], movementStage, referenceKey: ck}
                               })}
                >
                    {collections[ck].collection_ref}

                </Button>
            })
            }
            {Object.keys(outturns || {}).map(ck => {
                return <Button key={ck + "related_button_bulk"}
                               className={'m-1 w-25'}
                               style={{display: 'inline'}}
                               disabled={ck === movement_id}
                               variant={'primary'} size={'lg'}
                               onClick={() => setViewing(() => {
                                   return {...outturns[ck], movementStage, referenceKey: ck}
                               })}
                >
                    {outturns[ck].container_number}

                </Button>
            })
            }
            {Object.keys(picking_lists).map(plk => <PickingListView hidePrint={true} firebase={firebase}
                                                                    organisation_id={organisation_id} sublist={true}
                                                                    eventDate={eventDate}
                                                                    picking_list={picking_lists[plk]}
                                                                    movement_id={movement_id}
                                                                    movementStage={movementStage}
                                                                    setViewing={setViewing}/>)}
        </fieldset>
    </div>
};

const ProductList = ({products, all_products}) => {
    return [...Object.keys(products).map((pid) => {
        let product = all_products[pid];
        if(!product) {
            try {
                product = all_products.find(p=>p.id===pid)
            } catch(e) {
                console.log(e)
            }
        }
        return <tr key={`${pid}_product_row`}>
            <td>
                {product ? product.code : products[pid].code}
            </td>
            <td>
                {product ? product.description : 'Not found'}
            </td>
            <td>
                {products[pid].bays}
            </td>
            <td>
                {products[pid].change}
            </td>
        </tr>;
    })]
};

const ElectronicOutturnItem = ({outturn_id, firebase, organisation_id, products, customers}) => {
    const [outturn, setOutturn] = useState(null);

    const printRef = useRef();
    const fetchOutturn = async () => {
        const outturnsnap = await firebase.db.ref(DATABASE_REFS.organisations.summaries.outturn(organisation_id)).child(outturn_id).once('value');
        const photos = await firebase.db.ref(DATABASE_REFS.organisations.stock_movement.stock_in(organisation_id)).child(outturn_id).child("photos").once('value');
        const outturn = outturnsnap.val();
        outturn.photos = Object.values(photos.val() || {})
        setOutturn(outturn);
    };
    useEffect(() => {
        fetchOutturn().then(()=>{})
    }, [outturn_id]);

    if (!outturn) return <span>&nbsp;</span>;
    return <div>
        <ReactToPrint content={() => printRef.current}
                      trigger={() => <Button variant={'info'} size={'sm'}>Print</Button>}/>
        <div style={{display: 'none'}}>
            <div ref={printRef}>
                <ElectronicOutturn
                    issued_on={outturn.delivered_on}
                    ship_name={outturn.ship_name}
                    existing_products={products}
                    actual={outturn.products.actual}
                    expected_products={outturn.products.expected}
                    photos={outturn.photos}
                    completed={!!outturn.completed}
                    container_number={outturn.container_number}
                    total={outturn.crates_val || outturn.projected}
                    customer_code={customers && outturn.customer ? customers[outturn.customer].code : null}
                    include_images={outturn.photos.length > 0}
                />
            </div>
        </div>
    </div>
};

const ElectronicOutturn = ({customer_code, ship_name, container_number, total, issued_on, actual, completed, expected_products, include_images, photos, existing_products}) =>
    <div>
        <h1 className={'mt-0 w-100 text-center'}>Electronic Outturn</h1>
        <table id={"eo-table1"} className={'table-bordered table table-sm text-center'}>
            <tbody>
            {customer_code && <tr>
                <td className={'w-50 bg-secondary text-center'}>Customer</td>
                <td className={'w-50 text-center'}>{customer_code}</td>
            </tr>}
            <tr>
                <td className={'bg-secondary text-center'}>Container No.</td>
                <td className={'w-50 text-center'}>{container_number}</td>
            </tr>
            <tr>
                <td className={'bg-secondary text-center'}>Ship Name</td>
                <td className={'w-50 text-center'}>{ship_name}</td>
            </tr>
            <tr>
                <td className={'bg-secondary text-center'}>Total Crates</td>
                <td className={'w-50 text-center'}>{total}</td>
            </tr>
            <tr>
                <td className={'bg-secondary text-center'}>Devan Date</td>
                <td className={'w-50 text-center'}>{issued_on ? new Date(issued_on).toLocaleString() : ''}</td>
            </tr>
            </tbody>
        </table>

        {existing_products && (actual ? Object.keys(actual || {}) : [...new Set(Object.values(expected_products || {}).map(p=>p.code))]).map((product) => {
            const total_expected = Object.values(expected_products || {}).filter(p=>p.code===product).length;
            const crates = actual ? Object.values(actual[product].crates || {}) : Object.values(expected_products || {}).filter(p=>p.code===product).length;
            const existing_product = Object.values(existing_products || {}).find(p => p.code === product);
            const product_description = existing_product ? existing_product.description : expected_products[product] && expected_products[product].description ? expected_products[product].description : '';
            return <table style={{tableLayout: 'fixed'}} key={`complete_printed_product_${product}`}
                          className={'text-center table table-sm table-bordered mb-1'}>
                <tbody>
                <tr>
                    <td colSpan={4} className={'text-left'}>{product}</td>
                    <td colSpan={12} className={'emphasize text-left'}>
                        {product_description}
                    </td>
                    <td className={'emphasize text-center'}>{completed ? Object.values(actual[product].crates).reduce((a, b) => a + parseInt(b.qty), 0) : total_expected}</td>
                </tr>

                <tr>
                    {(actual ? crates : []).map((crate) => {
                        return [
                            <td colSpan={2} key={`outturn_${product}_${crate.code}_code`}
                                className={'bg-secondary'}>{crate.code}</td>,
                            <td colSpan={2}
                                key={`outturn_${product}_${crate.code}_qty`}>{actual && crate.qty}&nbsp;</td>
                        ]
                    })}
                    {
                        Array.from(new Array(Math.max(3 - (actual ? crates.length : 0), 0))).map((x, i) => [
                            <td key={`${product}_crate_${i}_0`} colSpan={2} className={'bg-secondary'}>&nbsp;</td>,
                            <td key={`${product}_crate_${i}_1`} colSpan={2}>&nbsp;</td>
                        ])
                    }
                    <td colSpan={4} className={'text-right'}>Checked</td>
                    <td>&nbsp;</td>
                </tr>
                {actual && actual[product].reason.length > 0 &&
                <tr>
                    <td className={'text-left'} colSpan={17 + (4 * Math.max(crates.length - 3, 0))}>
                        {actual[product].reason}
                    </td>
                </tr>}

                </tbody>
            </table>
        })}
        <div style={{breakInside: "avoid"}}>
        {include_images && photos.length && <h1>Photos</h1>}
        {include_images && photos && photos.map((photo, i) => {
            return <div key={`photo_${i}`} className={'collection-image col-12'}><Image className={'img img-fluid m-1'} src={photo}/>
            </div>
        })}
        </div>
        <table className={'table table-sm table-bordered print-footer'}>
            <tbody>
            <tr>
                <td className={'bg-secondary text-center'}>Unloaded by</td>
                <td className={'w-25 text-center'}>&nbsp;</td>
                <td className={'bg-secondary text-center'}>Checked by</td>
                <td className={'w-25 text-center'}>&nbsp;</td>
            </tr>
            <tr>
                <td className={'bg-secondary text-center'}>Total Quantity:</td>
                <td className={'w-25 text-center'}>&nbsp;</td>

                <td colSpan={2} className={'w-50 text-center'}><h1>{total}</h1></td>
            </tr>
            <tr>
                <td colSpan={4}>* Warehouse operatives please ensure the contents of the container match the outturn
                    exactly, any discrepancies or damaged to be noted on the outturn and a supervisor informed
                    immediately.
                </td>
            </tr>
            </tbody>
        </table>
    </div>;

const ElectronicCollectionItem = ({collection_id, firebase, organisation_id, products, customers}) => {
    const [collection, setCollection] = useState(null);

    const printRef = useRef();
    const fetchCollection = async () => {
        const collectionsnap = await firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).child(collection_id).once('value');
        const photos = await firebase.db.ref(DATABASE_REFS.organisations.stock_movement.stock_out(organisation_id)).child(collection_id).child("photos").once('value');
        const collection = collectionsnap.val();
        collection.photos = Object.values(photos.val() || {})
        setCollection(collection);
    };
    useEffect(() => {
        fetchCollection().then(()=>{})
    }, [collection_id]);

    if (!collection) return <span>&nbsp;</span>;
    return <div>
        <ReactToPrint content={() => printRef.current}
                      trigger={() => <Button variant={'info'} size={'sm'}>Print</Button>}/>
        <div style={{display: 'none'}}>
            <div ref={printRef}>
                <ElectronicCollection
                    issued_on={collection.collected_on}
                    existing_products={products}
                    actual={collection.products.actual}
                    expected_products={collection.products.expected}
                    photos={collection.photos}
                    completed={!!collection.completed}
                    collection_ref={collection.collection_ref}
                    total={collection.crates_val || collection.projected}
                    customer_code={customers && collection.customer ? customers[collection.customer].code : null}
                    include_images={collection.photos.length > 0}
                />
            </div>
        </div>
    </div>
};

const ElectronicCollection = ({customer_code, collection_ref, total, issued_on, actual, completed, expected_products, include_images, photos, existing_products}) =>
    <div>
        <h1 className={'mt-0 w-100 text-center'}>Electronic Collection</h1>
        <table id="ec-table1" className={'table-bordered table table-sm text-center'}>
            <tbody>
            {customer_code && <tr>
                <td className={'w-50 bg-secondary text-center'}>Customer</td>
                <td className={'w-50 text-center'}>{customer_code}</td>
            </tr>}
            <tr>
                <td className={'bg-secondary text-center'}>Collection Ref.</td>
                <td className={'w-50 text-center'}>{collection_ref}</td>
            </tr>
            <tr>
                <td className={'bg-secondary text-center'}>Total Crates</td>
                <td className={'w-50 text-center'}>{total}</td>
            </tr>
            <tr>
                <td className={'bg-secondary text-center'}>Collection Date</td>
                <td className={'w-50 text-center'}>{issued_on ? new Date(issued_on).toLocaleString() : ''}</td>
            </tr>
            </tbody>
        </table>

        {existing_products && (actual ? Object.keys(actual || {}) : [...new Set(Object.values(expected_products || {}).map(p=>p.code))]).map((product) => {
            const total_expected = Object.values(expected_products || {}).filter(p=>p.code===product).length;
            const crates = actual ? Object.values(actual[product].crates || {}) : Object.values(expected_products || {}).filter(p=>p.code===product).length;
            const existing_product = Object.values(existing_products || {}).find(p => p.code === product);
            const product_description = existing_product ? existing_product.description : expected_products[product] && expected_products[product].description ? expected_products[product].description : '';
            return <table style={{tableLayout: 'fixed'}} key={`complete_printed_product_${product}`}
                          className={'text-center table table-sm table-bordered mb-1'}>
                <tbody>
                <tr>
                    <td colSpan={4} className={'text-left'}>{product}</td>
                    <td colSpan={12} className={'emphasize text-left'}>
                        {product_description}
                    </td>
                    <td className={'emphasize text-center'}>{completed ? Object.values(actual[product].crates).reduce((a, b) => a + parseInt(b.qty), 0) : total_expected}</td>
                </tr>

                <tr>
                    {(actual ? crates : []).map((crate) => {
                        return [
                            <td colSpan={2} key={`collection_${product}_${crate.code}_code`}
                                className={'bg-secondary'}>{crate.code}</td>,
                            <td colSpan={2}
                                key={`collection_${product}_${crate.code}_qty`}>{actual && crate.qty}&nbsp;</td>
                        ]
                    })}
                    {
                        Array.from(new Array(Math.max(3 - (actual ? crates.length : 0), 0))).map((x, i) => [
                            <td key={`${product}_crate_${i}_0`} colSpan={2} className={'bg-secondary'}>&nbsp;</td>,
                            <td key={`${product}_crate_${i}_1`} colSpan={2}>&nbsp;</td>
                        ])
                    }
                    <td colSpan={4} className={'text-right'}>Checked</td>
                    <td>&nbsp;</td>
                </tr>
                {actual && actual[product].reason.length > 0 &&
                <tr>
                    <td className={'text-left'} colSpan={17 + (4 * Math.max(crates.length - 3, 0))}>
                        {actual[product].reason}
                    </td>
                </tr>}

                </tbody>
            </table>
        })}
        <div style={{breakInside: "avoid"}}>
        {include_images && photos.length && <h1>Photos</h1>}
        {include_images && photos && photos.map((photo, i) => {
            return <div key={`photo_${i}`} className={'col-12 collection-photo'}><Image className={'img img-fluid m-1'} src={photo}/>
            </div>
        })}
        </div>
        <table className={'table table-sm table-bordered print-footer'}>
            <tbody>
            <tr>
                <td className={'bg-secondary text-center'}>Loaded by</td>
                <td className={'w-25 text-center'}>&nbsp;</td>
                <td className={'bg-secondary text-center'}>Checked by</td>
                <td className={'w-25 text-center'}>&nbsp;</td>
            </tr>
            <tr>
                <td className={'bg-secondary text-center'}>Total Quantity:</td>
                <td className={'w-25 text-center'}>&nbsp;</td>

                <td colSpan={2} className={'w-50 text-center'}><h1>{total}</h1></td>
            </tr>
            <tr><td style={{border: 'none !important', fontSize:'8pt !important'}} colSpan={4}>* Hauliers please ensure you check the crate quantity of your load, you are signing for the stated amount, once signed and lorry left the compound you have taken responsibility of the exact quantity of crates.</td></tr>

            </tbody>
        </table>
    </div>;

export default MovementViewer;