import React from 'react';
import * as PropTypes from 'prop-types';

import Menu from "../Menu";
import Products from "../Products";
import Customers from "../Customers";
import Bays from "../Bays";
import Reports from "../GatePassIssuer";
import DevanRequests from "../Devans/Requests";
import Outturns from "../Devans";
import CollectionRequests from "../Collections/Requests";
import Collections from "../Collections";
import Users from "../Users";
import Tracking from "../Tracking";
import Reporting from "../Reports";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LiveTracker from "../../../components/CustomerApp/livetracker";
import Hotbar from "../../../elements/Hotbar";
import Bulk from "../BulkPickingList";
import {ContainerMasterDataContext} from "../../../components/ContainerMaster/ContainerMaster";
import {ArchiveList} from "../../../components/Archives";

const pages = {
    menu: Menu,
    products: Products,
    customers: Customers,
    bays: Bays,
    reports: Reports,
    devan_requests: DevanRequests,
    outturns: Outturns,
    collection_requests: CollectionRequests,
    collections: Collections,
    users: Users,
    bulk: Bulk,
    reporting: Reporting,
    tracking: Tracking,
    archives: (props)=><div>
        <Hotbar updatePage={props.updatePage} page={'archives'}/>
        <h2 className={'w-100 text-center'}>{props.organisation.name}@ContainerMaster Archives</h2>
        <ArchiveList {...props}></ArchiveList>
    </div>,
    livetracker: (props)=><ContainerMasterDataContext.Consumer>
        { ({products, customers}) =><React.Fragment>

        <Hotbar updatePage={props.updatePage} page={'livetracker'}/>


            <div id={'printarea'} className={'col-11 ml-auto mr-auto bg-light row m-0 p-3 bays print-block'}>
                <h2 className={'w-100 text-center'}>{props.organisation.name}@ContainerMaster</h2>
        <LiveTracker customers={customers} products={products} {...props}/>

            </div>
    </React.Fragment>}
    </ContainerMasterDataContext.Consumer>,
};

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.updatePage = this.updatePage.bind(this);
        this.updatePageWithEntity = this.updatePageWithEntity.bind(this);

        this.state = {
            filter: '',
            page: 'livetracker'
        };
    }

    /*
    NAVIGATION
     */
    updatePage(event) {
        if(this.state.page !== event.target.dataset.page)
            this.setState({page: event.target.dataset.page, entity: null});
    }
    updatePageWithEntity(page, entity) {
        if(this.state.page !== page || this.state.entity !== entity)
            this.setState({page: page, entity: entity});
    }
    render() {
        const {uid, firebase, organisation_id, organisation, loading, settings_loading, role} = this.props;
        const Content = pages[this.state.page];
        return (
            <div data-testid={"dashboard"}>
                {!organisation_id && !loading && !settings_loading && <div className={'mr-5 ml-5 p-5 bg-tertiary'}><div className={'mr-5 ml-5 p-5 bg-light'}><h1>You don't appear to have an organisation selected, you can manage your organisations and invites at the top right

                </h1></div><span className={'rotate-45-right'}><FontAwesomeIcon icon={'arrow-up'} size={'5x'}/></span></div>}

                {
                    organisation_id && <Content
                        entity={this.state.entity}
                        setOutturnsFilter={this.props.setDevansFilter}
                        setCollectionsFilter={this.props.setCollectionsFilter}
                        setCollectionGatePassFilter={this.props.setCollectionGatePassFilter}
                        setDevanGatePassFilter={this.props.setDevanGatePassFilter}
                        setCompleted={this.props.setCompleted}
                        setForecast={this.props.setForecast}
                        setCollectionRequestsFilter={this.props.setCollectionRequestsFilter}
                        setDevanRequestsFilter={this.props.setDevanRequestsFilter}
                        filter={this.props.filter}
                        filters={this.props.filters}
                        updatePageWithEntity={this.updatePageWithEntity}
                        updatePage={this.updatePage}
                        firebase={firebase}
                        organisation_id={organisation_id}
                        organisation={organisation}
                        user={uid}
                        role={role}
                        user_name={this.props.displayName ? this.props.displayName: ''}
                    /> }

            </div>
        );
    }
}


Dashboard.propTypes = {
    user: PropTypes.object
};


export {Dashboard};
export default Dashboard;