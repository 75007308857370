import React, {useEffect, useState} from "react";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge, Button, Col, FormControl, InputGroup, Row} from "react-bootstrap";
import DATABASE_REFS from "../../constants/db_refs";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import Clock from "react-clock";
import MovementViewer from "./popup";


const entities = [{l: 'Containers', i: 'ship', key: 'outturn', final: 'Stocked In'}, {l: 'Collections', i: 'truck', key: 'collection', final: 'Stocked Out'}];

const LiveTracker = ({firebase, organisation_id, customer, role, organisation, products, customers}) => {

    const [currentDate, setDate] = useState(moment(new Date().getTime()));
    const [pending, setPending] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState({collection: '', outturn: ''});
    const [viewing, setViewing] = useState(null);

    const addPending = (pending_snap) => {

        setPending((pending => {

            return [...pending.filter(p => p.referenceKey !== pending_snap.key), {
                ...pending_snap.val(),
                added: new Date().getTime()
            }]
        }))

    };
    const updatePending = (pending_snap) => {
        setPending((pending => {

            return [...pending.filter(p => p.referenceKey !== pending_snap.key), {
                ...pending_snap.val(),
                updated: new Date().getTime()
            }]
        }))
    };
    const removePending = (pending_snap) => {
        setPending((pending => {

            return [...pending.filter(p => p.referenceKey !== pending_snap.key)]
        }))
    };


    const fetchPending = () => {
        let ref = firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'pending'));
        if (role === 'customer')
            ref = ref.orderByChild('customer_id').equalTo(customer.id)
        ref.on('child_added', addPending);
        ref.on('child_changed', updatePending);
        ref.on('child_removed', removePending);
    };
    const [completed, setCompleted] = useState([]);
    const addCompleted = (completed_snap) => {
        const completed = completed_snap.val()
        if (role === 'customer' && completed.customer_id !== customer.id) return;
        setCompleted((completed => {

            return [...completed.filter(p => p.referenceKey !== completed_snap.key), {
                ...completed_snap.val(),
                added: new Date().getTime()
            }]
        }))

    };

    const updateCompleted = (completed_snap) => {
        setCompleted((completed => {

            return [...completed.filter(p => p.referenceKey !== completed_snap.key), {
                ...completed_snap.val(),
                updated: new Date().getTime()
            }]
        }))
    };
    const removeCompleted = (completed_snap) => {
        setCompleted((completed => {

            return [...completed.filter(p => p.referenceKey !== completed_snap.key)]
        }))
    };

    const isToday = currentDate.isSame(moment(), 'day')

    const fetchCompleted = () => {
        const ref = firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed'))
            .orderByChild('eventDate')
            .startAt(currentDate.startOf('day').toDate().getTime())
            .endAt(currentDate.endOf('day').toDate().getTime());
        ref.on('child_added', addCompleted);
        ref.on('child_changed', updateCompleted);
        ref.on('child_removed', removeCompleted);
        setLoading(true)
    };

    const setToday = () => {
        if (isToday) return;
        const ref = firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed'))
            .orderByChild('eventDate')
            .startAt(currentDate.startOf('day').toDate().getTime())
            .endAt(currentDate.endOf('day').toDate().getTime());


        ref.off()
        setCompleted([])
        setDate(currentDate => moment())

    };

    const dateUp = () => {
        const ref = firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed'))
            .orderByChild('eventDate')
            .startAt(currentDate.startOf('day').toDate().getTime())
            .endAt(currentDate.endOf('day').toDate().getTime());


        ref.off()
        setCompleted([])
        setDate(currentDate => moment(currentDate.add(1, 'days').toDate()))

    };
    const dateDown = () => {
        const ref = firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed'))
            .orderByChild('eventDate')
            .startAt(currentDate.startOf('day').toDate().getTime())
            .endAt(currentDate.endOf('day').toDate().getTime());
        ref.off()
        setCompleted([])
        setDate(currentDate => moment(currentDate.subtract(1, 'days').toDate()))

    };
    useEffect(fetchPending, [organisation_id, customer]);
    useEffect(() => {
        setTimeout(() => setLoading(false), 500)
    }, [organisation_id, customer, loading]);
    useEffect(fetchCompleted, [organisation_id, customer, currentDate]);
    if (loading) return <LoadingScreen/>;

    return <div key={currentDate.toDate().getTime()} className={'row justify-content-md-center'}>
        {viewing && <MovementViewer customers={customers} role={role} customer={customers} movement_id={viewing.referenceKey} setViewing={(view)=>setViewing(view)} products={products} key={viewing.referenceKey} movement={viewing} movementStage={viewing.movementStage} onHide={()=>setViewing(null)} firebase={firebase} organisation_id={organisation_id}/>}
        <Col xs={12} key={currentDate.toDate().getTime() + "header"}>
            <Row>
                <ClockItem/>
                <Col onClick={() => dateDown()} xs={1} className={'text-right pointer'}><FontAwesomeIcon
                    icon={'caret-left'} size={'3x'}/></Col>
                <Col xs={4}><h3 title={'Click to go to today'} onClick={setToday}
                                className={'text-center pointer'}>{isToday ? 'Today' : currentDate.toDate().toLocaleDateString()}</h3>
                </Col>
                {!isToday &&
                <Col className={'pointer'} onClick={() => dateUp()} xs={1}><FontAwesomeIcon icon={'caret-right'}
                                                                                            size={'3x'}/></Col>}
            </Row>
        </Col>
        {entities.map(entity => {
            const filteredCompleted = completed.filter(p => {
                const matched = p.event === entity.key && p.reference.toLowerCase().indexOf(filter[entity.key].toLowerCase()) > -1;
                if (role === 'customer') {
                    return matched
                }

                return matched && !(p.status.startsWith('Stocked'))
            });
            const filteredPending = pending.filter(p => {
                const ref = p.reference || "";
                return p.event === entity.key && ref.toLowerCase().indexOf(filter[entity.key].toLowerCase()) > -1
            });
            const pendingLength = pending.filter(p => {
                return p.event === entity.key
            }).length;
            const completedLength = completed.filter(p => {
                const matched = p.event === entity.key;
                if (role === 'customer') {
                    return matched
                }

                return matched && !(p.status.startsWith('Stocked'))
            }).length;

            let stocked_in = [];
            if (role !== 'customer') {
                stocked_in = completed.filter(p => {
                    const matched = p.event === entity.key && p.reference.toLowerCase().indexOf(filter[entity.key].toLowerCase()) > -1;


                    return matched && (p.status.startsWith('Stocked'))
                });
            }
            const stockedInLength = completed.filter(p => {
                const matched = p.event === entity.key;
                if (role === 'customer') {
                    return matched
                }

                return matched && (p.status.startsWith('Stocked'))
            }).length;
            let columns = 1;
            if (isToday) {
                columns += 1;
            }
            if (role!== 'customer' || !role) {
                columns += 1
            }
            return <div key={entity.key} className={'outer col text-light rounded'}>
                <fieldset style={{borderRadius: "15px"}} key={currentDate.toDate().getTime() + entity.key}>
                    <legend className={'text-left'}><h3><FontAwesomeIcon className={'ml-3 mr-3'}
                                                                         icon={entity.i}/>{entity.l}</h3>
                    </legend>
                    <Row className={'w-100 text-center'}>

                        <Col xs={12}><InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><FontAwesomeIcon icon={'search'}/></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl value={filter[entity.key]} type={'text'}
                                         placeholder={`Search ${entity.key} movements`} onChange={(e) => {
                                const value = e.target.value;
                                setFilter((filter => {
                                    const new_filter = Object.assign({}, filter);
                                    new_filter[entity.key] = value
                                    return new_filter
                                }))

                            }}/>
                            <InputGroup.Append onClick={() => {
                                setFilter((filter => {
                                    const new_filter = Object.assign({}, filter);
                                    new_filter[entity.key] = '';
                                    return new_filter
                                }));
                            }}>
                                <InputGroup.Text>&#10005;</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        </Col>

                        {isToday && <Col xs={12/columns} className={'bg-info text-center p-1'}>
                            <h3>Pending ({filteredPending.length}{filteredPending.length !== pendingLength &&
                            <span>/{pendingLength}</span>})</h3>
                            {filteredPending.sort((p1, p2) => p2.added - p1.added).map((pending_movement) => {
                                return <Button key={pending_movement.referenceKey + currentDate.toDate().getTime()}
                                               className={"fade-in"} variant={'primary'}
                                               onClick={()=>setViewing({...pending_movement, movementStage: 'pending'})}
                                               size={'lg'}>{pending_movement.reference}<br/><small>{new Date(parseInt(pending_movement.eventDate)).toLocaleString()}</small>

                                </Button>
                            })}
                        </Col>}
                        <Col xs={12/columns} className={'bg-success text-center p-1'}>
                            <h3>Completed ({filteredCompleted.length}{filteredCompleted.length !== completedLength &&
                            <span>/{completedLength}</span>})</h3>
                            {filteredCompleted.sort((p1, p2) => p2.added - p1.added).map((pending_movement) => {
                                return <Button key={pending_movement.referenceKey + currentDate.toDate().getTime()}
                                               className={"fade-in"} variant={'primary'} size={'lg'}
                                               onClick={()=>setViewing({...pending_movement, movementStage: 'completed'})}
                                >
                                    {pending_movement.reference}
                                    <br/><small>{new Date(parseInt(pending_movement.eventDate)).toLocaleString()}</small>

                                </Button>
                            })}
                        </Col>
                        {role !== 'customer' && <Col xs={12/columns} className={'bg-danger text-center p-1'}>
                            <h3>{entity.final} ({stocked_in.length}{stocked_in.length !== stockedInLength &&
                            <span>/{stockedInLength}</span>})</h3>
                            {stocked_in.sort((p1, p2) => p2.added - p1.added).map((pending_movement) => {
                                return <Button key={pending_movement.referenceKey + currentDate.toDate().getTime()}
                                               className={"fade-in"} variant={'primary'}
                                               onClick={()=>setViewing({...pending_movement, movementStage: 'stocked'})}
                                               size={'lg'}>{pending_movement.reference}<br/><small>{new Date(parseInt(pending_movement.eventDate)).toLocaleString()}</small>

                                </Button>
                            })}
                        </Col>}
                    </Row>
                </fieldset>
            </div>
        })}
    </div>
};

const ClockItem = () => {
    const [value, setValue] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(
            () => setValue(new Date()),
            1000
        );

        return () => {
            clearInterval(interval);
        }
    }, []);
    return <Col offset-xs={1} xs={3} style={{marginTop: '-50px', textAlign: 'center'}}> <Clock className={'m-auto'}
                                                                                               value={value}
                                                                                               renderNumbers={true}
                                                                                               size={120}/></Col>
}
export default LiveTracker