import React from "react";
import {ButtonGroup} from "react-bootstrap";
import {Button, InputGroup} from "react-bootstrap";
import DatePicker from "react-datepicker/es";
import moment from "moment";

const AdvancedFilter = ({legend_text, onClick, selectAll, deselectAll, original_list, current_list}) => {
    return <fieldset style={{borderRadius: "15px", display: 'inline', margin: '0.2em', width: "20%"}}>
        <legend>{legend_text}:</legend>
        <div className={'text-right'}>
            {original_list.length === current_list.length ?
                <span>Deselect all <input type={'checkbox'} onChange={deselectAll}/></span> :
                <span>Select All <input type={'checkbox'} onChange={selectAll}/></span>}
        </div>
        <div className={'text-left'}>
            {original_list.map(sl=><div key={`${sl}_filter`}><span><input onChange={() => onClick(sl)} checked={current_list.indexOf(sl) > -1} type={'checkbox'}/>  {sl.capitalize().split('_').join(' ')}</span></div>)}

        </div>
        </fieldset>
};
const AdvancedCustomerFilter = ({onClick, original_list, current_list, deselectAll, selectAll}) => {
    return <fieldset style={{borderRadius: "15px", display: 'inline', margin: '0.2em', width: "20%"}}>
        <legend>Customer:</legend>
        <div className={'text-right'}>
            {original_list.length === current_list.length ?
                <span>Deselect all <input type={'checkbox'} onChange={deselectAll}/></span> :
                <span>Select All <input type={'checkbox'} onChange={selectAll}/></span>}
        </div>
        <div className={'text-left'}>
            {original_list.map(sl=><div key={`${sl}_filter`}><span><input onChange={() => onClick(sl)} checked={current_list.indexOf(sl) > -1} type={'checkbox'}/>  {sl.capitalize().split('_').join(' ')}</span></div>)}

        </div>
        </fieldset>
};

const AdvancedRadioFilter = ({legend_text, onClick, list, selection}) => {
    return <fieldset style={{borderRadius: "15px", display: 'inline', margin: '0.2em', width: "20%"}}>
        <legend>{legend_text}:</legend>

        <div className={'text-left'}>
            {list.map(sl=><div key={`${sl}_filter`}>
                <span>
                    <input onChange={
                        () =>
                        onClick(sl)
                    }
                           checked={sl === selection}
                           type={'radio'}/>  {sl.capitalize().split('_').join(' ')}</span></div>)}

        </div>
    </fieldset>
};

const AdvancedDateFilter = (props) => {
    const {legend_text, setDateFilter, filter, filter_options, between_start, between_end} = props;
    const handleChange = (is_start, date) => {
        setDateFilter({
            filter, start: is_start ? moment(date).startOf('day').toDate().getTime() : between_start,
            end: !is_start ? moment(date).endOf('day').toDate().getTime() : between_end
        })
    };
    return <fieldset style={{borderRadius: "15px", display: 'inline', margin: '0.2em'}}>
        <legend>{legend_text.capitalize().replace('_', ' ')}:</legend>
        <ButtonGroup size={'sm'} className={'mb-1 small'}>
            {filter_options.map(fo=><Button key={`${legend_text}_${fo}_filter_option`} className={'small'} style={{fontSize: '0.8rem !important'}} onClick={()=>setDateFilter({filter: fo, start: between_start, end: between_end})} variant={filter===fo?'info':'primary'}>{fo.capitalize().replace('_', ' ')}</Button>)}
        </ButtonGroup>
        <ButtonGroup size={'sm'} className={'mt-auto mb-auto ml-1'} style={filter!=='custom' ? {opacity: 0.5} : {opacity: 1}}>



            <DatePicker
                disabled={filter!=='custom'}
                dateFormat="dd/MM/yyyy"
                selectsStart
                maxDate={new Date(parseInt(between_end))}
                className={'form-control form-control-sm not-rounded'}
                selected={new Date(parseInt(between_start))}
                onChange={(date)=>handleChange(true, date)}
                onSelect={(date)=>handleChange(true, date)}
            />

            <InputGroup.Prepend className={'pl-1 pr-1 no-print small m-auto text-center border-tertiary bg-tertiary text-light'}
                                style={{borderWidth: '1px 0 1px 1px', borderStyle: 'solid', lineHeight: '2.3em'}} column={'12'} >And</InputGroup.Prepend>
            <DatePicker
                disabled={filter!=='custom'}
                className={'form-control form-control-sm not-rounded-left'} name={'endDate'}
                dateFormat="dd/MM/yyyy"
                selectsStart
                minDate={new Date(parseInt(between_start))}
                selected={new Date(parseInt(between_end))}
                onChange={(date)=>handleChange(false, date)}
                onSelect={(date)=>handleChange(false, date)}
            />


        </ButtonGroup>

    </fieldset>
};

const AdvancedFilterPanel = ({children}) => {
    return  <div className={'no-print col-12 mt-1 small bg-tertiary text-light p-2 rounded text-left'} style={{verticalAlign: 'top'}}>
        {children}

    </div>
};

export {AdvancedFilter, AdvancedFilterPanel, AdvancedDateFilter, AdvancedRadioFilter, AdvancedCustomerFilter}