import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: props.error };
    }
    setError(error) {
        this.setState({error: error});
    }
    render() {
        const {error} = this.state;
        return (
           <span className={'errors'}>
               {
                   error && <p>
                       <span aria-label="Close" onClick={() => this.setState({error: false })} className={'float-right'}>
                           <FontAwesomeIcon icon="times-circle" />
                       </span>
                       {error.message}
                   </p>
               }
           </span>
        );
    }
}

Error.propTypes = {
    error: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool])

};

export default Error;