import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";


class Image extends React.Component {
    render() {
        const {intl, alt_id, src} = this.props;
        const alt =  intl.formatMessage({ id: alt_id });
        return (
            <img className={this.props.className} data-testid={'image'} alt={alt} src={src}/>
        );
    }
}

Image.propTypes = {
    alt_id: PropTypes.string,
    src: PropTypes.string,
    className: PropTypes.string
};

export default injectIntl(Image);