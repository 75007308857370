import error from './error.png';
import Image from "./Image";
import React from "react";
import * as PropTypes from 'prop-types';

const ErrorMessage = ({message, details, link}) => {
    return (
        <div className={'error_message col-xs-12 col-md-6 offset-md-3'}>
            <h3>{message}</h3>
            <Image alt_id={'app.error'} className={'img img-fluid'} src={error}/>
            <span><p>{details}</p> {link}</span>
        </div>
    )
};

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
    link: PropTypes.element
};

export default ErrorMessage;