import React from 'react';

import * as ROUTES from '../../constants/routes';
import Image from "../../elements/Image";
import SignOutButton from "../SignoutButton/SignOutButton";
import {NavLink} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class NonAuthed extends React.Component {
    render() {
        return (

            <Nav className="mr-auto" activeKey="/">
                <NavLink  activeClassName={'selected'} className={'nav-link text-light'} to={ROUTES.HOME}>Sign In</NavLink>
                <NavLink  activeClassName={'selected'} className={'nav-link text-light'} to={ROUTES.SIGN_UP}>Register</NavLink>
            </Nav>

        );
    }
}

class Authed extends React.Component {
    render() {
        return (
            <Nav className="mr-auto">
                <SignOutButton/>
                <Nav.Item>
                    <NavLink exact={true} activeClassName={'selected'} className={'nav-link text-light'}  to={ROUTES.HOME}>Dashboard</NavLink>
                </Nav.Item>
                <Nav.Item>
                    <NavLink activeClassName={'selected'} className={'nav-link text-light'}  to={ROUTES.PROFILE}>Profile</NavLink>
                </Nav.Item>
            </Nav>
        );
    }
}

class Navigation extends React.Component {
    render() {
        const {uid} = this.props;
        return <Navbar color={'light'} bg="primary" variant={"dark"} expand="lg" fixed={"top"}>
            <Navbar.Brand href={ROUTES.HOME}>
                <Image className={'img img-thumbnail'} src={'/logo.jpg'} alt_id={'app.name'}/>
            </Navbar.Brand>
            <Navbar.Toggle id="navbar-toggle" aria-controls="basic-navbar-nav">
                <FontAwesomeIcon className={'text-light'} size={"1x"} icon={'ellipsis-v'}/>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">

                {uid && <Authed/>}
                {!uid && <NonAuthed/>}

            </Navbar.Collapse>

        </Navbar>;
    }
}


export {Authed, NonAuthed}
export default Navigation;