import {Table} from "react-bootstrap";
import React from "react";

const col1_style = {
    fontSize: '26pt !important'
};
const col2_style = {
    fontSize: '2em !important'
};
export default ({gate_pass, customer, logo, picking_lists, outturns, list_total, user_name, generating}) => {
    const {collections: collections1, driver, issued_on, picking_lists: picking_lists1, customer: customer1, total, outturns: outturns1, issued_by, haulier, pass_number, vehicle_reg} = gate_pass;
    return <div className={'police-gate-pass'}>
        <h3>Police Gate Pass</h3>
        <Table bordered={true} size={'lg'}>
            <tbody>
            <tr>
                <td ><p style={col1_style}>Vehicle Reg.</p></td>
                <td style={col2_style}>{vehicle_reg}</td>
            </tr>
            <tr>
                <td style={col1_style}>Haulier</td>
                <td>{haulier}</td>
            </tr>
            <tr>
                <td style={col1_style}>Driver Name</td>
                <td>{driver}</td>
            </tr>
            <tr>
                <td style={col1_style}>Pass Number</td>
                <td>{pass_number}</td>
            </tr>
            <tr>
                <td style={col1_style}>Gate Pass Date</td>
                <td>{new Date(issued_on).toLocaleString()}</td>
            </tr>
            <tr>
                <td style={col1_style}>Customer</td>
                <td>{customer1 && customer}</td>
            </tr>
            <tr>
                <td style={col1_style}>Total</td>
                <td>{total || list_total}</td>
            </tr>
            </tbody>
        </Table>
        {(collections1 || picking_lists1) && <Table size={'sm'} className={'middle police-gate-pass'} bordered={true}>
            <thead>
            <tr>
                <th  style={col1_style} className={'pb-1'}>References</th>
                <th  style={col1_style} className={'pb-1'}>Picking List</th>
                <th  style={col1_style} className={'pb-1'}>Crates</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(picking_lists1 || {}).map(plkey=> {
                const pl = picking_lists[plkey] ? picking_lists1[plkey]  : gate_pass.picking_lists[plkey];
                return Object.values(pl.collections || {}).map(c=> {
                    const collection =  c;
                    const col1_style = {
                        fontSize: generating ? "1em" : '1.6em'
                    };

                    return <tr key={`printed_pass_${c.id}`}>
                        <td  style={col1_style} className={'w-50'}>{collection.collection_ref}</td>
                        <td  style={col1_style} className={'w-50'}>{pl.picking_list_number}</td>
                        <td  style={col1_style} className={'w-50'}> {collection.crates_val || collection.grand_total || collection.crates}</td>
                    </tr>
                })})}
            {Object.values(collections1 || {}).map(c=> {
                const collection =  c;
                const col1_style = {
                    fontSize:  generating ? "1em" : '1.6em'
                };


                return <tr key={`printed_pass_${c.id}`}>
                    <td  style={col1_style} className={'w-50'}>{collection.collection_ref}</td>
                    <td  style={col1_style} className={'w-50'}>-</td>
                    <td  style={col1_style} className={'w-50'}> {collection.crates_val || collection.crates}</td>
                </tr>
            })}
            </tbody>
        </Table>}
        {outturns &&  <Table size={'sm'} className={'middle police-gate-pass'} bordered={true}>
            <thead>
            <tr>
                <th  style={col1_style} className={'pb-1'}>Containers</th>
                <th  style={col1_style} className={'pb-1'}>Crates</th>
            </tr>
            </thead>
            <tbody>
            {Object.values(outturns1 || {}).map(c=> {
                const outturn = c;
                const col1_style = {
                    fontSize: '1.6em'
                };

                return <tr key={`printed_pass_${c.id}`}>
                    <td  style={col1_style} className={'w-50'}>{outturn.container_number}</td>
                    <td  style={col1_style} className={'w-50'}> {outturn.crates_val}</td>
                </tr>
            })}
            </tbody>
        </Table>}


        <div style={{ textAlign: 'left !important', position: 'fixed', width: '100%', bottom: '15px', left: '15px'}}>
            <p className={'w-100 text-left'}>Authorised by</p>
            <Table className={'police-gate-pass footer'} bordered={true} size={'md'}>
                <tbody>
                <tr>
                    <td   className={'w-50'}>Sign</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td >Print</td>
                    <td  style={col1_style} className={'w-50'}>{issued_by ? issued_by.displayName : user_name}</td>
                </tr>
                <tr >
                    <td>Company Stamp<br/><br/></td>
                    <td className={'text-center'}>{logo &&<img alt={'Company Stamp'} className={'m-auto img img-fluid'} style={{maxWidth: '350px', maxHeight: '200px'}} src={logo}/>}</td>
                </tr>
                </tbody>
            </Table>
        </div>
    </div>
}