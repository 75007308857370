import React from "react";
import {FormControl, Button, Tab} from "react-bootstrap";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Tabs from "react-bootstrap/Tabs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
const BayButton = ({changeBay, current_bay, bay, showTotal, adjustments}) => <div className={'col-xs-6 col-md-3 p-1 pl-0 pr-0'}><Button onClick={() => {
    changeBay(bay.id);
}} className={'w-100'+(current_bay===bay.id?' active-bay': '')} size={'lg'}>{bay.code} <br />
    { showTotal && <span className={'small'}>({(bay.current - (bay.stocking_out + bay.promised)) + (adjustments[bay.id] ? adjustments[bay.id].adjustment : 0)})</span>}
    {bay.id === current_bay && <FontAwesomeIcon icon={'check'} className={'ml-1 text-light'}/>}
    <br />
    <sub style={{fontSize: '0.6em', margin: '-10px'}} className={'small'}>Used: {bay.last_active ? moment(new Date(parseInt(bay.last_active))).fromNow() : 'Never'}</sub>
</Button>
</div>;
export class BayQuantitySelector extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            filter: ''
        }
    }

    onChange() {
        this.props.onChange(this.props.current_bay, this.props.pkey, this.props.current_bay, this.qty.value)
    }
    changeBay(new_bay) {
        this.props.onChange(this.props.current_bay, this.props.pkey, new_bay, this.qty.value)
    }
    filterBays(bays) {
        let used_bays = [];
        if (this.props.expected) {
            Object.keys(this.props.expected).map(pkey => {
                used_bays.push(...Object.keys(this.props.expected[pkey].crates || {}));

                return true;
            })

        }
        if (!bays) return [];
        return Object.keys(bays).filter((bkey) => {
            if (bays[bkey].disabled) return false;
            return (!bays[bkey].product || bays[bkey].product === this.props.pkey) && (used_bays.indexOf(bkey) === -1 || bkey === this.props.current_bay)
        });
    }

    getBayQtys(bay) {
        const adjustments = this.props.adjustments;
        const bay_qtys = [
            <option key={this.props.current_bay + "_none"} value={0}>0</option>
        ];
        if (bay) {
            let available = (bay.current || 0) - ((bay.promised || 0) + bay.stocking_out || 0);
            if (adjustments && adjustments[bay.id]) {
                available += adjustments[bay.id].adjustment;
            }
            for (let i = 1; i <= parseInt(available || 0); i++) {
                bay_qtys.push(<option key={this.props.current_bay + "_" + i} value={i}>{i}</option>)
            }
        }
        return bay_qtys;
    }

    render() {
        const {bays, pkey, current_bay, qty, selector} = this.props;
        const {filter} = this.state;
        const sortedBays = Object.values(bays).filter(bay=>!(bay.disabled)).sort((a, b)=>{
            return (parseInt(b.last_active) || 0) - (parseInt(a.last_active) || 0)
        });
        const bays_with_products = sortedBays.filter(p => p.product === pkey && p.code.toLowerCase().indexOf(filter.toLowerCase()) > -1);
        const bays_without_products = sortedBays.filter(p => !p.product && p.code.toLowerCase().indexOf(filter.toLowerCase()) > -1);

        return <div className={'col-12 col-md-6 row'}>
            <div className={'col-6'}>
                Bay: <OverlayTrigger className={'bg-primary'} trigger={"click"} placement="right" rootClose
                                overlay={(props) => <BayPopover props={props} current_bay={current_bay} bays={bays}
                                                                changeBay={this.changeBay.bind(this)} adjustments={this.props.adjustments}
                                                                setFilter={(val)=>this.setState({filter: val})}
                                                                filter={this.state.filter}
                                                                bays_with_products={bays_with_products}
                                                                bays_without_products={bays_without_products}/>}>
                    <Button title={'Change bay?'} variant={"primary"} size={'lg'}>{current_bay && bays[current_bay] ? bays[current_bay].code : 'Choose'}</Button>
                </OverlayTrigger>
            </div>
            {selector && current_bay && <div className={'col-6'}>
                Qty: <select value={qty} onChange={this.onChange} ref={ref => this.qty = ref}
                             className={'form-control'}>
                {this.getBayQtys(bays[current_bay])}
            </select>
            </div>}
            {(!current_bay || !selector) && <div className={'col-6'}>
                Qty: <FormControl size={'lg'} value={qty} onChange={this.onChange} ref={ref => this.qty = ref}
                            className={'form-control'}/>
            </div>}

        </div>
    }
}

const BayPopover = ({props, bays, current_bay, bays_with_products, bays_without_products, changeBay, adjustments, setFilter, filter})=>{
    return <Popover style={{maxWidth: "600px"}} id="popover-basic" {...props}>
        <Popover.Title as="div"><h3 className={'w-100 text-center text-light'}
                                    style={{minWidth: "200px"}}>Choose a bay</h3>
        </Popover.Title>
        <Popover.Content className={'p-1'}>
            <FormControl style={{minWidth: "400px"}} placeholder={'Start typing to filter'}
                         value={filter}
                         onChange={e => setFilter(e.target.value)}/>
            <Tabs style={{display: 'flex',  flexWrap: 'nowrap',
                alignItems: 'stretch'}} className={'mt-1'} defaultActiveKey="product" id="uncontrolled-tab-example">
                <Tab className={'success'} eventKey={'product'} title={`Contains product (${bays_with_products.length})`}>
                    <div className={'row mt-1 ml-0 mr-0'}>
                        {bays_with_products.slice(0, 12).map((bay) => {
                            return <BayButton current_bay={current_bay} bays={bays} bay={bay} showTotal={true} adjustments={adjustments || {}} changeBay={()=>changeBay(bay.id)}/>
                        })}
                        {bays_with_products.length - 12 > 0 && <span>{bays_with_products.length - 12} more... Try filtering...</span>}
                    </div>
                </Tab>
                { bays_without_products.length > 0  && <Tab className={'empty'} eventKey={'empty'} title={`Empty (${bays_without_products.length})`}>
                    <div className={'row mt-1 ml-0 mr-0'}>
                        {bays_without_products.slice(0, 12).map((bay) => {
                            return <BayButton current_bay={current_bay} bays={bays} bay={bay} adjustments={adjustments || {}} changeBay={()=>changeBay(bay.id)}/>
                        })}
                        {bays_without_products.length - 12 > 0 && <span>{bays_without_products.length - 12} more... Try filtering...</span>}
                    </div>
                </Tab>}

            </Tabs>

        </Popover.Content>
    </Popover>
}
export {BayPopover}