// external
import * as React from "react";
import {Button, ButtonGroup, FormControl, Modal, ModalBody} from "react-bootstrap";
import {toast} from "react-toastify";
import * as PropTypes from 'prop-types';
// Images
import customers from '../../../../icons/customers.png';
import products from '../../../../icons/products.png';
import collection_request from '../../../../icons/collection_request.png';
// Constants
import db_refs from '../../../../constants/db_refs';
import storage_refs from '../../../../constants/storage_refs';
import {toastConfig} from "../../../../config/toast_config"
//Components
import StepProgressBar from "../../../../elements/StepProgressBar";
import {MenuButton} from "../../Menu";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
import Input from "../../../../elements/InlineInput";
import WizardProduct from "./WizardProduct";
import Error from "../../../../elements/Error";
import {uploadImportFile} from "../../../../helpers/wizards";
import _ from 'lodash';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormCheck from "react-bootstrap/FormCheck";


class Wizard extends React.Component {
    constructor(props) {
        super(props);
        const {collection_request_id, request} = props;
        const {projected, collection_ref, products, customer, expected, completed, error, redirected_to, picking_list, locked, started} = request || {};

        if (error) {
            this.state ={invalid: true, error: error, timer: 60,
                collection_request: collection_request_id, picking_list};
        }
        if (redirected_to) {
            this.state ={
                invalid: true,
                redirected_to: redirected_to,
                timer: 60,
                collection_request: collection_request_id
            };
        }
        this.state ={
            redirected_to: redirected_to,
            invalid: !!error || !!redirected_to,
            error: error,
            crates: projected,
            customer: customer,
            collection_ref: collection_ref,
            products: Object.values(products ? products.expected || {} : {}),
            import: !!props.request,
            skipping: !!completed,
            completed: !!completed || !!started,
            locked: !!locked,
            started: started,
            expected: expected ? expected : new Date().getTime(),
            collection_request: collection_request_id,
            newProduct: 0,
            including: true
        };

        this.setCustomer = this.setCustomer.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.togglePickingList = this.togglePickingList.bind(this);
        this.skipImport = this.skipImport.bind(this);
        this.updateRequest = this.updateRequest.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.errors = React.createRef();
        this.product_refs = [];
    }

    setCustomer(event) {
        this.setState({customer: event.target.dataset.page})
    }

    addProduct() {
        const {products, newProduct} = this.state;
        products.push({
            description: '',
            new: true,
            crates: 0,
            code: `${newProduct + 1} new product `
        });
        this.setState({newProduct: newProduct + 1, products: products})
    }

    togglePickingList() {
        this.setState({showPickingList: !this.state.showPickingList});
    }

    getPickingListUrl() {
        this.props.firebase.db.ref(db_refs.organisations.request.collection(this.props.organisation_id)).child(this.state.collection_request).child("picking_list").on('value', (snap) => {
            if (snap.val()) {
                this.props.firebase.storage.ref(snap.val()).getDownloadURL().then(url => {
                    this.setState({
                        url: url
                    })
                })
            } else {
                this.setState({url: null})
            }
        })
    }


    updateRequest(go_to_page) {
        const errors = [];
        const products = [];
        const no_products_exist = this.product_refs.length === 0;
        this.product_refs.map(p => {
            if (p.isInvalid()) {
                errors.push(p.state.code)
            } else {
                if (p.productChanged().code) {
                    delete products[p.props.product_code];
                }
                const updatedProduct = p.getProduct();
                if (updatedProduct.delete) {
                    delete products[updatedProduct.code];
                } else {
                    if (products[p.state.code]) {
                        products[p.state.code].crates += updatedProduct.crates
                    } else {
                        products[p.state.code] = updatedProduct;
                    }
                }
            }
            return true;
        });
        if (no_products_exist) {
            this.errors.current.setError({message: "You cannot save a collection request with no products"})
        } else if (errors.length > 0) {
            this.errors.current.setError({message: `Product form has ${errors.length} errors!`})
        } else {
            this.uploadNewRequestRevision(products, go_to_page);
        }
    }

    uploadNewRequestRevision(products, go_to_page) {
        const {firebase, organisation_id} = this.props;
        const {collection_request, customer, collection_ref} = this.state;
        const final_products = [];
        Object.values(products).map((product) => {
            final_products.push({
                code: product.code,
                description: product.description,
                crates: parseInt(product.crates)
            });
            return true;
        });
        const newRevision = {
            collection_ref: collection_ref,
            customer: customer,
            products: final_products,
            expected: new Date(this.state.expected).getTime(),
            has_warnings: false,
            verified: true,
            updated: false,
            error: null
        };
        const isNew = !collection_request;
        const ref = db_refs.organisations.request.collection(organisation_id);
        const actual_request_id = collection_request ? collection_request : firebase.db.ref(ref).push().key;
        if(!this.state.locked) {
            this.props.firebase.db.ref(ref).child(actual_request_id)
                .update(newRevision).then(() => {
                if (isNew) {
                    toast.success(`Request created`, {...toastConfig, autoClose: 2000});
                } else {
                    toast.success(`Request saved`, {...toastConfig, autoClose: 2000});
                }

                this.props.closeWizard();
                if (go_to_page) {
                    this.props.updatePageWithEntity('collections', actual_request_id)
                }

            }).catch(err => console.error(err));
        }
        else {
            this.props.closeWizard();
            if (go_to_page) {
                this.props.updatePageWithEntity('collections', actual_request_id)
            }
        }

    }

    skipImport() {
        this.setState({import: 'Using existing data', skipping: true})
    }

    handleChange(event) {
        let newState = {};
        newState[event.target.name] = event.target.value;
        this.setState(newState)
    }

    handleDateChange(event) {
        let newState = {};
        newState[event.target.name] = new Date(event.target.value).getTime();
        this.setState(newState)
    }

    handleFile(event) {
        this.setState({import: null, products: false, collection_ref_checked: false});
        const {firebase, organisation_id} = this.props;
        const {customer, collection_request,revision} = this.state;
        let actual_revision;
        if(!revision) {
            actual_revision = 0;
        } else {
            actual_revision = revision;
        }
        const file = event.target.files[0];
        const actual_request_id = collection_request ? collection_request : firebase.db.ref(db_refs.organisations.request.collection(organisation_id)).push().key;
        let fileRef = storage_refs.picking_list_to_process(
            firebase.auth.currentUser.uid,
            organisation_id,
            customer,
            actual_request_id,
            parseInt(actual_revision) + 1,
            file.name);
        if (!this.state.including) {
            fileRef = storage_refs.picking_list_to_store(
                firebase.auth.currentUser.uid,
                organisation_id,
                customer,
                actual_request_id,
                parseInt(actual_revision) + 1,
                file.name);
        }
        uploadImportFile(this, fileRef, file, () => {
            this.props.editCollection(actual_request_id);
            this.props.closeWizard()
        });
    }

    componentDidUpdate(prevProps) {
        if(_.isEqual(prevProps.request, this.props.request)) return;

        const {projected, collection_ref, products, customer, expected, completed, error, redirected_to, locked, started} = this.props.request || {};

       const state ={
            redirected_to: redirected_to,
            invalid: !!error || !!redirected_to,
            error: error,
            crates: projected,
            customer: customer,
            collection_ref: collection_ref,
            products: Object.values(products ? products.expected || {} : {}),
            import: !!this.props.request,
            skipping: !!completed,
            completed: !!completed || !!started,
            started: started,
           locked: locked,
            expected: expected ? expected : new Date().getTime(),
            collection_request: this.props.collection_request_id
        };
        this.setState(state, ()=>this.state.customer && this.getPickingListUrl(this.state.customer))

    }

    componentDidMount() {
        this.state.customer && this.getPickingListUrl(this.state.customer);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        clearInterval(this.interval)
    }

    render() {
        let current = 2;
        if (!this.state.customer) {
            current = 0;
        } else if (!this.state.import) {
            current = 1;
        }
        const steps = [
            {
                src: customers,
                label: this.state.customer ?this.props.customers[this.state.customer].code : "Choose customer",
                complete: !!this.state.customer
            },
            {
                src: collection_request,
                label: this.state.import ? this.state.import : "Upload Picking List",
                complete: !!this.state.import
            },
            {
                src: products,
                label: "Check Products",
                complete: false
            }
        ];
        return (
            <div className={'row'}>
                <div className={'col-xs-12 col-sm-10 offset-sm-1 bg-tertiary p-2 pb-0 pt-1'}>
                    {this.state.error && <Error error={{message:this.state.error}}/>}
                    <div className={'col-12 col-lg-8 offset-lg-2 p-3 mt-5 mb-3'}>
                        <StepProgressBar steps={steps} current={current}/>
                    </div>
                    {!this.state.invalid && <div className={'bg-light col-12 col-lg-12 p-3 mt-5 mb-3 text-center small'}>
                        {!this.state.customer && <div>
                            <h3 className={'col-6'}>Choose a customer</h3>
                            {Object.values(this.props.customers || {}).map((customer) => {
                                return <MenuButton key={customer.id} page={customer.id} updatePage={this.setCustomer}
                                                   color={''} icon={false} label={customer.name}/>
                            })}

                        </div>}
                        {
                            this.state.customer && <div className={'text'}>
                                {!this.state.import && <div>
                                    <h3>Choose a picking list</h3>
                                    {this.props.collection_request && <p className={'text-danger'}>
                                        Warning: uploading a new picking list will replace the product list!
                                    </p>
                                    }
                                    <FormControl variant={'primary'} type={'file'} onChange={this.handleFile}/>
                                    <FormCheck checked={this.state.including} variant={'primary'} type={'checkbox'}
                                               onChange={() => this.setState({including: !this.state.including})} label={"Automatically read list?"}/>
                                    <Button onClick={this.skipImport} variant={'primary'}>Skip</Button>
                                </div>}
                                {this.state.import &&
                                <div>{!this.state.collection_ref && !this.state.skipping && <div>
                                    <h3>Picking list is being processed</h3>
                                    <LoadingScreen/>

                                </div>}
                                    <div className={'col-12 text-right'}>
                                        {this.state.url &&
                                        <div>
                                            { this.state.showPickingList &&
                                            <Modal onHide={() => this.setState({showPickingList: false})} show={this.state.showPickingList} size={'lg'}>
                                                <ModalBody style={{maxWidth: '1440px'}} className={'w-100'}>
                                                    <iframe title={'Container Master Packing List Viewer'} height={"500"} className={'w-100'} src={this.state.url}/>
                                                </ModalBody>
                                            </Modal>}
                                        </div>}
                                        <Error ref={this.errors} error={false}/>
                                        <ButtonGroup size={'sm'}>
                                            {/* eslint-disable-next-line react/jsx-no-undef */}
                                            <Button size={'sm'} className={'btn btn-danger text-light no-print'} onClick={this.props.closeWizard}>
                                                <span className={'not-mobile'}>Return to List</span>
                                                <FontAwesomeIcon className={'mobile-only'} icon={'caret-left'}/>
                                            </Button>
                                            {!this.state.started &&<Button size={'sm'} className={'not-mobile btn btn-success text-light no-print'} onClick={()=>this.setState({import: null})}>Import Picking List</Button>}
                                            {this.state.url &&<Button variant={'primary'} onClick={this.togglePickingList}>{this.state.showPickingList ? 'Hide Picking List' : 'Show Picking List'}</Button>}
                                            {!this.props.status && !this.state.started && <Button  variant={'tertiary'} onClick={this.addProduct}>Add
                                                product</Button>}
                                            {!this.props.status && !this.state.started && <Button variant={'primary'}
                                                                                                  className={'not-mobile'}
                                                                                                  disabled={!this.state.collection_ref}
                                                                                                  onClick={()=>this.updateRequest(false)}>Save</Button>}
                                            <Button className={'mr-1'} variant={'success'}
                                                    disabled={!this.state.collection_ref}
                                                    onClick={()=>this.updateRequest(true)}>{this.props.status || this.state.started  ? 'View Collection' : 'Save and Collect'}</Button>
                                        </ButtonGroup>
                                    </div>
                                    <div className={'row'}>
                                        <div className={'col-12 col-sm-4'}>
                                            {!this.state.completed && <h3>Confirm detected collection ref</h3>}
                                            {this.state.completed && <h3>Collection ref</h3>}
                                            {!this.state.completed && <Input readOnly={this.state.locked} handleChange={this.handleChange} name={'collection_ref'}
                                                                             label_id={'app.collection_ref'} required={true}
                                                                             value={this.state.collection_ref ? this.state.collection_ref : ''}/>}
                                            {this.state.completed && <FormControl value={this.state.collection_ref} readOnly={true}/>}
                                        </div>


                                        <div className={'col-12 col-sm-4'}>
                                            {!this.state.completed &&  <h3>Confirm expected date</h3>}
                                            {this.state.completed && <h3>Expected Date</h3>}
                                            <FormControl readOnly={this.state.locked} type={'date'} onChange={this.handleDateChange} name={'expected'}
                                                         value={new Date(this.state.expected).toLocaleDateString('en-CA')}/>

                                        </div>
                                        <div className={'col-12 col-sm-4'}>
                                            <h3>Total crates</h3>
                                            <h3>{this.state.crates}</h3>
                                        </div>
                                    </div>
                                    <div>

                                        <h3>Confirm products</h3>


                                        {Object.keys(this.state.products || {}).sort((p1, p2) => {
                                            return this.state.products[p1].new ? -1 : this.state.products[p2].new ? 1 : 0
                                        }).map((product, i) => {
                                            return <WizardProduct key={product + "_wizard"}
                                                                  completed={this.state.locked}
                                                                  ref={(ref) => this.product_refs[i] = ref}
                                                                  product_index={i}
                                                                  product_code={this.state.products[product].code}
                                                                  existing_products={Object.values(this.props.products||{}).filter(p => p.customer===this.state.customer)}
                                                                  product={this.state.products[product]}
                                                                  collection_id={this.state.collection_request}
                                            />
                                        })}

                                    </div>
                                </div>}
                            </div>
                        }
                    </div>}
                    {this.state.invalid && <div className={'bg-light col-12 col-lg-12 p-3 mt-5 mb-3 text-center small'}>
                        {this.state.error && <div>
                            <p>
                                {this.state.error}
                                <br/>
                                Download the associated picking list <a rel="noopener noreferrer" target={"_blank"} href={this.state.url} download={"picking_list.pdf"}>here</a>.
                            </p>
                            <Button variant={'danger'} size={'sm'} onClick={()=>{
                                clearInterval(this.interval);
                                clearTimeout(this.timeout);
                                this.props.firebase.db.ref(db_refs.organisations.request.collection(this.props.organisation_id)).child(this.state.collection_request).set(null).then(()=>console.debug('deleted'));
                                this.props.closeWizard()
                            }}>Discard</Button>
                        </div>}
                        {this.state.redirected_to && <div>
                            <p>
                                This collection reference has already been requested, you will be redirected in {this.state.timer} seconds.
                            </p>
                            <Button variant={'success'} size={'sm'} onClick={()=>{
                                clearInterval(this.interval);
                                clearTimeout(this.timeout);
                                this.props.firebase.db.ref(db_refs.organisations.request.collection(this.props.organisation_id)).child(this.state.collection_request).set(null).then(()=>console.debug('deleted'));
                                this.props.closeWizard();
                                this.props.editCollection(this.state.redirected_to)
                            }}>Continue</Button>
                        </div>}
                    </div>}
                </div>
            </div>
        );
    }
}

Wizard.propTypes = {
    customers: PropTypes.object.isRequired,
    organisation_id: PropTypes.string.isRequired,
    existing_products: PropTypes.object,
    firebase: PropTypes.object.isRequired,
    collection_request: PropTypes.string
};

export default Wizard;