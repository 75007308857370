import React, {useRef, useState} from "react";
import * as PropTypes from 'prop-types';
import {DateSortableContent, RowCell, TableButtons} from "../../../elements/Common";
import {
    Button,
    Col,
    FormCheck,
    FormControl,
    FormGroup,
    FormLabel,
    InputGroup,
    ModalBody,
    Row,
    Table
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import db_refs from '../../../constants/db_refs';
import {toast} from "react-toastify";
import {toastConfig} from "../../../config/toast_config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {labelMappings, StockLevelGraph} from "../../../elements/EventTable";
import _ from 'lodash';
import { DATABASE_REFS } from "../../../constants/db_refs";

class EditCustomerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: props.editing
        };
        this.editField = this.editField.bind(this);
        this.editFieldChecks = this.editFieldChecks.bind(this);
        this.editItem = this.editItem.bind(this);
    }
    editField(event) {
        const {editing} = this.state;
        editing[event.target.name] = event.target.value;
        this.setState({editing: {...editing}});
    }
    editItem() {
        this.props.editItem(this.state.editing)
    }
    editFieldChecks(event) {
        const {editing} = this.state;
        editing[event.target.name] = event.target.checked;
        this.setState({editing: {...editing}});
    }


    render() {
        const {setEditItem, editing: {archive, attachments, code, forecasting, name, product_view, reporting}, onHide} = this.props;
        return <Modal show={true} onHide={onHide}>
            <ModalHeader className={'bg-primary'}><h3 className={'text-light'}>{`Editing customer ${code}`}</h3></ModalHeader>
            <ModalBody className={'bg-light'}>
                <FormGroup>
                    <FormLabel column={true}>Customer Code</FormLabel>
                    <FormControl onChange={this.editField} value={code} name={'code'}/>
                </FormGroup>
                <FormGroup>
                    <FormLabel column={true}>Customer Name</FormLabel>
                    <FormControl onChange={this.editField} value={name} name={'name'}/>
                </FormGroup>
                <Row>
                    <Col xs={6}>
                        <FormGroup>
                            <FormLabel column={true}>Enabled Views</FormLabel>
                            <FormCheck onChange={this.editFieldChecks} checked={reporting} name={'reporting'} label={'Reporting'}/>
                            <FormCheck onChange={this.editFieldChecks} checked={forecasting} name={'forecasting'} label={'Forecasting'}/>
                            <FormCheck onChange={this.editFieldChecks} checked={product_view} name={'product_view'} label={'Product View'}/>
                            <FormCheck onChange={this.editFieldChecks} checked={archive} name={'archive'} label={'Archive'}/>
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <FormLabel column={true}>Other Settings</FormLabel>
                            <FormCheck onChange={this.editFieldChecks} checked={attachments} name={'attachments'} label={'Include Attachments'}/>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className={'bg-light'}>
                <Button onClick={()=>setEditItem(null)} variant={'secondary'}>Cancel</Button>
                <Button variant={'success'} onClick={this.editItem}>Save</Button>
            </ModalFooter>
        </Modal>
    }
}

class CustomerRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totals: {}
        }
    }
    generateEndpoint(op_type, existing) {
        const {firebase, customer, organisation_id} = this.props;
        firebase.functions.httpsCallable('generateEmailEndpoint')({
            organisation_id,
            customer_id: customer.id,
            endpoint_type: op_type,
            existing: existing
        }).then(()=>{
            toast.success(`Created endpoint for ${customer.name}`, {...toastConfig})
        })
    }
    componentDidMount() {
        this.setState({loading: true});
        const {organisation_id, firebase, customer} = this.props;
        firebase.db.ref(db_refs.organisations.tracking.totals(organisation_id)).child('customers')
            .child(customer.id).child('totals').on('value',
            r => {
                const result = r.val();
                if(!result)
                    this.setState({forecasting: {}});
                else {
                    result.available = result.current - (result.promised+result.stocking_out);
                    this.setState({totals: result})
                }
            }
        );

    }

    render() {
        const {customer : cust, headers, details, products, firebase, organisation_id} = this.props;
        const customer = _.cloneDeep(cust);
        const totals = {
            current: get_total_for_key('current', products),
            pending: get_total_for_key('pending', products),
            promised: get_total_for_key('promised', products),
            stocking_in: get_total_for_key('stocking_in', products),
            stocking_out: get_total_for_key('stocking_out', products),
        };
        totals.available = totals.current - (totals.promised+totals.stocking_out);
        const graphData = Object.keys(totals).sort().map(k=>({x: labelMappings[k], label: labelMappings[k], y: totals[k]}));
        if(Object.keys(this.state.totals).length > 0)
            customer.totals = this.state.totals;
        return [

            <tr key={`table_row_${customer.id}`} className={'col-12 w-100 m-1 product item-row mb-1 print-block'}
                style={{boxShadow: '-1px 1px 1px lightgray'}}>
                {headers.map((h, i) =>  <RowCell  key={`table_row_${customer.id}_${h.key}`}  col={3} title={h.key} alignment={'center'}
                                                  header={!this.props.printing && i===0} noprint={h.noprint}>{h.content(customer)}</RowCell>)}
            </tr>,<tr className={'no-print'} key={`table_row_${customer}_toggle`}>
                <td colSpan={headers.length} style={{position: 'relative'}}>
                    <div onClick={() => this.setState({details: !this.state.details})}
                         style={{position: 'absolute', right: '-15px', top: '-40px'}}>
                        {details && !this.props.details && <FontAwesomeIcon size={'2x'} icon={'caret-down'}/>}
                        {this.props.details && <FontAwesomeIcon title={'Details are locked open'} icon={'ban'}/>}
                        {!details && <FontAwesomeIcon size={'2x'} icon={'caret-up'}/>}
                    </div>
                </td>
            </tr>,
            <tr  key={`table_row_${customer.id}_details`}>
                {(details || this.state.details) && <td colSpan={headers.length}>
                    <CustomerDetail organisation_id={organisation_id} firebase={firebase} products={products} graphData={graphData} generateEndpoint={this.generateEndpoint.bind(this)} customer={customer} />

                </td>}

            </tr>]
    }
}
const CustomerDetail = ({firebase, customer, generateEndpoint, graphData, organisation_id}) => {
    const spanRef = useRef(null);
    const spanRef2 = useRef(null);
    const [numberOfInvites, setNumberOfInvites] = useState(5)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [enabled, setEnabled] = useState(true)
    const [deletingContact, setDeletingContact] = useState(null)
    const [devans, setDevans] = useState(true)
    const [collections, setCollections] = useState(true)
    const  {collection_endpoint_id, devan_endpoint_id, invites, contacts} = customer;
    const updateContactPreferences = (prefType, contactKey, value) => {
        firebase.db.ref(`/organisations/${organisation_id}/customers/${customer.id}/contacts/${contactKey}/${prefType}`).set(value);
    }
    console.log(contacts);
    return <div className={'row col-12 details bg-secondary'}>
        <div className={'col-8'}>
            <h3>Email Endpoints</h3>
        <Table variant={'primary'} bordered={true}>
            <thead>
            <tr>
                <th>Type</th>
                <th>Email</th>
                <th>
                    &nbsp;
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Collections</td>
                <td>
                    <InputGroup className="mb-3">

                        <FormControl readOnly={true} ref={spanRef} value={collection_endpoint_id}/>
                        <InputGroup.Append>
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon onClick={(e)=>{
                                    const {value} = spanRef.current;
                                    navigator.clipboard.writeText(value)
                                    e.target.focus();
                                    toast.success('Copied collection endpoint!', {...toastConfig})
                                }} icon={'copy'} title={'Copy to clipboard'}/>
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </td>

                <td>
                    <Button disabled={!!collection_endpoint_id} onClick={() => generateEndpoint('collection', collection_endpoint_id)} size={'sm'}>
                        {collection_endpoint_id ? 'Regenerate' : 'Generate'}
                    </Button>
                </td>
            </tr>
            <tr>
                <td>Devans</td>
                <td>
                    <InputGroup className="mb-3">

                        <FormControl readOnly={true} ref={spanRef2} value={devan_endpoint_id}/>
                        <InputGroup.Append>
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon onClick={(e)=>{
                                    const {value} = spanRef2.current;
                                    navigator.clipboard.writeText(value)
                                    e.target.focus();
                                    toast.success('Copied devan endpoint!', {...toastConfig})
                                }} icon={'copy'} title={'Copy to clipboard'}/>
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </td>

                <td>
                    <Button disabled={!!devan_endpoint_id} onClick={() => generateEndpoint('devan', devan_endpoint_id)} size={'sm'}>
                        {devan_endpoint_id ? 'Regenerate' : 'Generate'}
                    </Button>
                </td>
            </tr>
            </tbody>
        </Table>
        </div>
        <div className={'col-4'}>
            <h3>Stock Levels</h3>
            { graphData.length > 0 &&  <StockLevelGraph graphData={graphData}/> }
        </div>
        <div className={'col-8'}>
            <h3>Customer Invites</h3>
            <Table variant={'primary'} bordered={true}>
                <thead>
                <tr>
                    <th>Created On</th>
                    <th>Usage</th>
                    <th>Link</th>
                    <th>
                        &nbsp;
                    </th>
                </tr>
                </thead>
                <tbody>
                { Object.keys(invites || {}).filter(ik=>!invites[ik].invalid).map(inviteKey => {
                    const invite = invites[inviteKey];
                    return <tr>
                        <td>{new Date(invite.created_on).toLocaleString()}</td>
                        <td>{invite.used}/{invite.total}</td>
                        <td>
                            <InputGroup className="mb-3">

                                <FormControl readOnly={true} ref={spanRef} value={invite.link}/>
                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon1">
                                        <FontAwesomeIcon onClick={(e)=>{
                                            const {value} = spanRef.current;
                                            navigator.clipboard.writeText(value)
                                            e.target.focus();
                                            toast.success('Copied invite link!', {...toastConfig})
                                        }} icon={'copy'} title={'Copy to clipboard'}/>
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </td>

                        <td>
                            <Button onClick={async () => {
                                await firebase.db.ref(DATABASE_REFS.organisations.customers(organisation_id)).child(customer.id).child('invites').child(inviteKey).update({invalid: true})
                                toast.error("Link invalidated")
                            }} variant={'danger'} size={'sm'}>
                                Invalidate
                            </Button>
                        </td>
                    </tr>
                }) }

                {Object.keys(invites || {}).filter(ik=>!invites[ik].invalid).length === 0 && <tr><td style={{textAlign: 'center'}} colSpan={4}>No invites found</td> </tr>}

                </tbody>
            </Table>
        </div>
        <div className={'col-4'}>
            <h3>Create Customer Invite</h3>
            <Row>
                <Col>
                    <FormLabel column={4}>Number of uses</FormLabel>
                </Col>
                <Col>
                    <FormControl value={numberOfInvites} type={'number'} onChange={(event) => setNumberOfInvites(event.target.value)}/>
                </Col>
                <Col>
                    <Button onClick={() => {

                        firebase.functions.httpsCallable('createCustomerInvite')({
                            organisation_id, customer: customer.id, total: numberOfInvites
                        }).then((result)=>{
                            toast.success(result.data)
                        })
                    }}>Create Invite Link</Button>
                </Col>

            </Row>



        </div>
        <div className={'col-8'}>
            <h3>Customer Contacts</h3>
            {
                Object.values(contacts || {}).length > 0 && <Table variant={'primary'} bordered={true}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Enabled</th>
                    <th>Devans</th>
                    <th>Collections</th>
                    <th>
                        &nbsp;
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    Object.keys(contacts || {}).filter(con => !contacts[con].invalid).map(contactKey => {
                        const contact = contacts[contactKey];
                        return <tr>
                            <td>{contact.name}</td>
                            <td>{contact.email}</td>
                            <td>
                                <FormCheck defaultChecked={contact.enabled} onChange={(event) => updateContactPreferences('enabled', contactKey, event.target.checked)}/>
                            </td>
                            <td>
                                <FormCheck defaultChecked={contact.devans} onChange={(event) => updateContactPreferences('devans', contactKey, event.target.checked)}/>
                            </td>
                            <td>
                                <FormCheck defaultChecked={contact.collections} onChange={(event) => updateContactPreferences('collections', contactKey, event.target.checked)}/>
                            </td>

                            <td>
                                <Button onClick={async () => {
                                    setDeletingContact(deletingContact !== contactKey ? contactKey : null)
                                }} variant={deletingContact === contactKey ? 'secondary' : 'danger'} size={'sm'}>
                                    {deletingContact === contactKey ? 'Cancel' : 'Delete'}
                                </Button>
                                { deletingContact === contactKey && <span className={'small ml-3 mr-3'}>Deletion is permanent, are you sure?  <Button onClick={async () => {
                                    firebase.db.ref(`/organisations/${organisation_id}/customers/${customer.id}/contacts/${contactKey}`).set(null);
                                    toast.error("Deleted")
                                }} variant={'danger'} size={'sm'}>
                                    Delete
                                </Button></span>}
                            </td>
                        </tr>
                    })
                }

                </tbody>
            </Table>
            }
            {
                Object.values(contacts || {}).length === 0 && <p>No contacts added</p>
            }
        </div>
        <div className={'col-4 mb-3'}>
            <h3>Create Customer Invite</h3>
            <Col>
                <Row>
                    <Col xs={3}>
                        <FormLabel column={3}>Name</FormLabel>
                    </Col>
                    <Col xs={9}>
                        <FormControl value={name} type={'text'} onChange={(event) => setName(event.target.value)}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <FormLabel column={3}>Email</FormLabel>
                    </Col>
                    <Col xs={9}>
                        <FormControl value={email} type={'email'} onChange={(event) => setEmail(event.target.value)}/>
                    </Col>
                </Row>
<Row>
                <Col xs={4}>
                    <FormCheck defaultChecked={enabled}
                               onChange={(event) => setEnabled(event.target.value)}
                               label={"Enabled"}/>
                </Col>
                <Col xs={4}>
                    <FormCheck defaultChecked={devans}
                               onChange={(event) => setDevans(event.target.value)}
                               label={"Devans"}/>
                </Col>
                <Col xs={4}>
                    <FormCheck defaultChecked={collections}
                               onChange={(event) => setCollections(event.target.value)}
                               label={"Collections"}/>
                </Col>
</Row>
                <Row className={"text-right"}>
                    <Button onClick={() => {
                        firebase.functions.httpsCallable('createCustomerContact')({
                            organisation_id, customer: customer.id, name, email, enabled, devans, collections
                        }).then((result)=>{
                            toast.success(result.data)
                        })
                    }}>Create Contact</Button>

                </Row>
            </Col>
        </div>
        <hr/>
    </div>;

};
const get_total_for_key = (key, products) => {

    return products.reduce((a, b)=>{
        if(b.stocking_out > 0) {

        }
        return a + (b[key] || 0)
    }, 0)
};
class CustomerTable extends DateSortableContent {
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState,
            key: 'customers',
            sort: 'code',
            sorted_data: [],
            print_processing: false,
            details: false,
            index: 0,
            page: 0,
            num_per_page: 12,
            hide_date: true,
            noDetails: false
        };
    }

    getHeaders() {
        return [{
            label: 'Customer Code',
            key: 'code',
            size: 2,
            content: (product) => {
                return product.code
            }
        }, {
            label: 'Name',
            key: 'name',
            size: 3,
            content: (product) => {
                return product.name
            }
        }, {
            label: 'Products',
            key: 'total',
            size: 2,
            content: (product) => {
                return product.total
            }
        },  {
            label: 'In Stock',
            key: 'current',
            size: 1,
            content: (product) => {
                const {products} = this.props;
                const customerProducts = Object.values(products || {}).filter(p=>p.customer===product.id);
                return get_total_for_key('current', customerProducts)
            }
        },{
            label: 'Available',
            key: 'available',
            size: 1,
            content: (product) => {
                const {products} = this.props;
                const customerProducts = Object.values(products || {}).filter(p=>p.customer===product.id);
                const current = get_total_for_key('current', customerProducts);
                const promised = get_total_for_key('promised', customerProducts);
                const stocking_out = get_total_for_key('stocking_out', customerProducts);
                return current - (promised+stocking_out)
            }
        },{
            label: 'Incoming',
            key: 'pending',
            size: 1,
            content: (product) => {
                const {products} = this.props;
                const customerProducts = Object.values(products || {}).filter(p=>p.customer===product.id);
                const promised = get_total_for_key('pending', customerProducts);
                const stocking_out = get_total_for_key('stocking_in', customerProducts);
                return (promised+stocking_out)
            }
        },{
            label: 'Outgoing',
            key: 'promised',
            size: 1,
            content: (product) => {
                const {products} = this.props;
                const customerProducts = Object.values(products || {}).filter(p=>p.customer===product.id);
                const promised = get_total_for_key('promised', customerProducts);
                const stocking_out = get_total_for_key('stocking_out', customerProducts);

                return (promised+stocking_out)
            }
        },{
            label: '',
            key: false,
            size: 2,
            noprint: true,
            content: (product) => {
                return  <TableButtons product={product} setDeleteItem={this.setDeleteItem} setEditItem={this.setEditItem}/>
            }
        }]
    }

    getReportTitle() {
        return 'Customer Report'
    }

    getDeletionModal() {
        if (this.state.deleting) {
            return <Modal show={true}>
                <ModalHeader className={'bg-primary'}><h3 className={'text-light'}> {`Deleting customer ${this.state.deleting.code}`}</h3></ModalHeader>
                <ModalFooter className={'bg-light'}>
                    <Button onClick={()=>this.setDeleteItem(null)} variant={'secondary'}>Cancel</Button>
                    <Button variant={'danger'} onClick={this.deleteItem}>Delete</Button>
                </ModalFooter>
            </Modal>
        }
    }

    getEditingModal() {
        if (this.state.editing) {
            return <EditCustomerForm onHide={()=>this.setState({editing: null})} editing={this.state.editing} editItem={this.editItem} setEditItem={this.setEditItem}/>
        }
    }

    deleteItem() {
        this.props.firebase.db.ref(db_refs.organisations.customers(this.props.organisation_id)).child(this.state.deleting.id).set(null).then(() => this.setState({deleting: null}));
    }
    editItem(editing) {
        this.props.firebase.db.ref(db_refs.organisations.customers(this.props.organisation_id)).child(this.state.editing.id).update(editing).then(() => this.setState({editing: null}));
    }

    getContent() {
        const {products} = this.props;
        return this.state.sorted_data.map(
            (customer) => <CustomerRow
                organisation_id={this.props.organisation_id}
                firebase={this.props.firebase}
                printing={this.state.print_processing}
                details={this.state.details}
                headers={this.getHeaders()}
                key={customer.id}
                products={Object.values(products || {}).filter(p=>p.customer===customer.id)}
                customer={customer}
            />
        );
    }
}

CustomerTable.propTypes = {
    customers: PropTypes.array.isRequired,
    total: PropTypes.number
};
export {CustomerRow, CustomerDetail};
export default CustomerTable;