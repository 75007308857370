import PageTemplate from "../PageTemplate";
import React from "react";
import {
    Button,
    ButtonGroup,
    CloseButton,
    Col,
    Form,
    FormControl,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    Row
} from "react-bootstrap";
import Error from "../../../elements/Error";
import {DateSortableContent, RowCell} from "../../../elements/Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalHeader from "react-bootstrap/ModalHeader";
import {DATABASE_REFS} from "../../../constants/db_refs";
import {toast} from "react-toastify";
import {toastConfig} from "../../../config/toast_config";
import Hotbar from "../../../elements/Hotbar";
import CustomerImportTool from "../../../components/CustomerImportTool";
import {ContainerMasterDataContext} from "../../../components/ContainerMaster/ContainerMaster";

class UserTable extends DateSortableContent {
    has_export = 'User Report';
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState,
            key: 'users',
            sort: 'name',
            sorted_data: [],
            print_processing: false,
            details: false,
            index: 0,
            page: 0,
            noDetails: true,
            num_per_page: 12,
            hide_date: true
        };
        this.details = {}


    }

    getReportTitle() {
        return "Users"
    }

    getCustomButtonPanel() {
        return <ButtonGroup>
            <Button onClick={() => this.props.setInviting()} variant={'success'} size={'sm'} className={'m-auto no-print'}>
                <FontAwesomeIcon icon={'user-plus'}/>
            </Button>
            <Button onClick={() => this.props.setImporting()} variant={'info'} size={'sm'} className={'m-auto no-print'}>
                Import
            </Button>
        </ButtonGroup>
    }

    getData() {
        this.setState({data: this.state.sorted_data.map((user) => {
            return user
        })});
    }
    
    getHeaders() {
        const {firebase, organisation_id} = this.props;
        return [{
            label: 'Name',
            key: 'displayName',
            size: 3,
            content: (bay) => bay.displayName
        }, {
            label: 'Email',
            key: 'email',
            size: 5,
            content: (bay) => {
                return bay.email
            }
        }, {
            label: 'Role',
            key: 'role',
            size: 2,
            content: (bay) => {
                return bay.role
            }
        }, {
            label: '',
            key: false,
            size: 2,
            noprint: true,
            content: (product) => {
                if(product.id === firebase.auth.currentUser.uid) {
                    return ""
                }
                return <ButtonGroup size={'sm'}>
                    {product.role === 'member' && <Button title={'promote'} onClick={()=>{
                        const promote = firebase.functions.httpsCallable('promote');
                        promote({user_id: product.id, organisation_id: organisation_id}).then(() => {
                            const {sorted_data} = this.state;
                            sorted_data.map((user) => {
                                if(user.id===product.id)
                                    user.role = 'admin';
                                return true;
                            });

                            this.setState({sorted_data: [...sorted_data]})
                    }).catch((e) => {
                            toast.error(e.message)
                        })}} variant={'success'}><FontAwesomeIcon icon={'arrow-alt-circle-up'}/></Button>}
                    {product.role === 'admin' && <Button title={'demote'} onClick={()=>{
                        const promote = firebase.functions.httpsCallable('demote');
                        promote({user_id: product.id, organisation_id: organisation_id}).then(() => {
                            const {sorted_data} = this.state;
                            sorted_data.map((user) => {
                                if(user.id===product.id)
                                    user.role = 'member';
                                return true
                            });

                            this.setState({sorted_data: [...sorted_data]})
                        }).catch((e) => {
                            toast.error(e.message)
                        })}} variant={'danger'}><FontAwesomeIcon icon={'arrow-alt-circle-down'}/></Button>}
                   <Button title={'remove'} onClick={()=>{
                        const promote = firebase.functions.httpsCallable('removeUser');
                        promote({user_id: product.id, organisation_id: organisation_id}).then(() => {
                            toast.error("User deleted")
                        }).catch((e) => {
                            toast.error(e.message)
                        })}} variant={'danger'}><FontAwesomeIcon icon={'trash'}/></Button>
                </ButtonGroup>
            }
        }]
    }

    getContent() {
        const {sorted_data} = this.state;
        return sorted_data.map((user) => <tr key={`user_row_${user.id}`}>
            {this.getHeaders().map((header, i) => {
                return <RowCell title={header.label} key={`${user.id}_${header.key}`} header={i===0}>
                    {header.content(user)}
                </RowCell>
            })}
            </tr>);
    }
}

class Users extends PageTemplate {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            email: '',
            role: 'member',
            filter: "",
            importing: false
        }
    }

    applyFilter(users) {
        const {filter} = this.state;
        return Object.values(users).filter((user) => {
            return user.displayName.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                user.role.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                user.email.toLowerCase().indexOf(filter.toLowerCase()) > -1
        });
    }
    componentDidMount() {
        const {firebase, organisation_id} = this.props;
        firebase.db.ref(DATABASE_REFS.organisations.members(organisation_id)).once('value').then((data)=>{
            firebase.db.ref(DATABASE_REFS.organisations.members(organisation_id)).on('value',
                (data)=>{
                    let users = [];
                    if(data.val()) {
                        users = Object.keys(data.val()).map(dk =>({
                                id: dk,
                                ...data.val()[dk]
                            }))
                    }
                    this.setState({users: users})
            });
            let users = [];
            if(data.val()) {
                users = Object.keys(data.val()).map(dk =>({
                    id: dk,
                    ...data.val()[dk]
                }))
            }
            this.setState({users: users})
        }).catch((err) => {
            if(err.code === "PERMISSION_DENIED") {
                if (this.errors)
                this.errors.setError({message: "You don't have permission to manage users for this organisation"});
                return;
            }
            this.errors.setError(err);
        })
    }
    componentWillUnmount() {
        this.props.firebase.db.ref(`members/${this.props.organisation_id}`).off('value');
    }

    render() {
        const {updatePage, organisation, firebase, customers, organisation_id} = this.props;
        const users = this.state.users || [];
        return (
            <div>
                <Hotbar updatePage={updatePage} page={'users'}/>

                <div className={'col-xs-12 col-sm-11 ml-auto mr-auto bg-primary pt-2 pb-2'}>

                    {this.state.importing && <ContainerMasterDataContext.Consumer>{({customers})=><CustomerImportTool firebase={firebase} organisation_id={organisation_id} show={this.state.importing}
                                         setImporting={()=>this.setState({importing: !this.state.importing})}
                                         organisation={organisation} customerList={customers}/> }</ContainerMasterDataContext.Consumer>}
                    <Modal size={'lg'} show={this.state.inviting}>
                        <ModalHeader className={'bg-primary'} ><h3 className={'text-light'}>Invite User to {organisation.name}</h3>
                            <CloseButton className={'text-light'} onClick={()=>this.setState({inviting: false})}/>
                        </ModalHeader>
                        <ModalBody className={'bg-light'} >
                            <Error ref={ref=>this.invite_errors=ref}/>
                            <Form className={'bg-light p-5'}>
                                <Row>
                                    <Col>Create a user invite</Col>
                                </Row>
                                <Row>
                            <Col sm={8}>
                                <FormGroup>
                            <FormControl
                                isInvalid={!(/^[a-zA-Z0-9-+]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(this.state.email))}
                                isValid={/^[a-zA-Z0-9-+]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(this.state.email)}
                                value={this.state.email} onChange={(event)=>{
                                const newState = {};
                                newState[event.target.name] = event.target.value;
                                this.setState(newState)
                            }} name={'email'}/>
                                <Form.Control.Feedback type={'invalid'}>
                                    Invalid email format
                                </Form.Control.Feedback></FormGroup>
                            </Col>
                            <Col sm={4}>
                            <select name={'role'} defaultValue={this.state.role} onChange={(event)=>{
                                event.preventDefault();
                                const newState = {};
                                newState[event.target.name] = event.target.value;
                                this.setState(newState)
                            }} className={'form-control'}>
                                <option value={'admin'}>Admin</option>
                                <option value={'member'}>Member</option>
                            </select>
                            </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                        <ModalFooter className={'bg-light'}>
                            <Button variant={'secondary'} size={'sm'} onClick={()=>this.setState({inviting: false})}>Cancel</Button>
                            <Button disabled={!(/^[a-zA-Z0-9+]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(this.state.email))} onClick={
                                () => {
                                    firebase.functions.httpsCallable('inviteUser')({
                                        organisation_id: organisation_id,
                                        email: this.state.email,
                                        role: this.state.role
                                    }).then((data) => {
                                        toast.success(data.data, {...toastConfig, autoClose: 5000});
                                        this.setState({inviting: false})
                                    }).catch(err=>this.invite_errors.setError(err));
                                }
                            } variant={'primary'} size={'sm'} >Invite</Button>
                        </ModalFooter>
                    </Modal>
                    <h3 className={'text-light text-center'}>Users</h3>
                    <Error ref={ref=>this.errors=ref}/>
                    <UserTable
                               setFilter={this.setFilter}
                               setInviting={()=>this.setState({inviting: true})}
                               setImporting={()=>this.setState({importing: !this.state.importing})}
                               organisation_id={organisation_id}
                               firebase={firebase}
                               customers={customers}
                               users={this.applyFilter(users)}
                               total={users.length}
                    />

                </div>
            </div>
        )
    }
}

export default Users;