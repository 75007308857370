import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import {InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SearchInput extends React.Component {


    componentDidMount() {
        if(this.input) this.input.focus()
    }

    componentDidUpdate() {
        this.setState({value: this.props.value}, () => {
            try {

                if (this.input) this.input.current.focus()
            } catch(e) {

            }
        })

    }

    shouldComponentUpdate(nextProps) {
        return nextProps.value !== this.props.value
    }

    render() {
        const {intl, className, setFilter} = this.props;

        let label =  'Search';
        if(intl) {
            label = intl.formatMessage({ id: 'app.search' });
        }
        return (
            <div className="input-group mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={'search'}/></InputGroup.Text>
                </InputGroup.Prepend>
                <input autoComplete="off" value={this.props.value} ref={ref=>this.input = ref} id={this._id} aria-label={label} onChange={e=>{e.persist();setFilter(e)}} placeholder={label} type={'text'} className={className}/>
                <div className="input-group-append search-input-append">
                    <span onClick={() => {
                        this.props.setFilter({
                        target: {
                        value: ''
                    }
                    })
                    }}  aria-label={'clear search'} className={"input-group-text interactive clear-button"}><span>&#10005;</span></span>
                </div>
            </div>
        );
    }
}

SearchInput.propTypes = {
    setFilter: PropTypes.func.isRequired,
    className: PropTypes.string,
    prefix: PropTypes.string
};
SearchInput.defaultProps = {
    className: "form-control search-input",
    prefix: "form_input"
};

export {SearchInput};
export default injectIntl(SearchInput);