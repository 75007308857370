// external
import * as React from "react";
import {Button, ButtonGroup, Col, FormControl, Modal, ModalBody, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import * as PropTypes from 'prop-types';
// Images
import customers_img from '../../../../icons/customers.png';
import products_img from '../../../../icons/products.png';
import devan_request_img from "../../../../icons/devan_request.png";
// Constants
import db_refs from '../../../../constants/db_refs';
import storage_refs from '../../../../constants/storage_refs';
import {toastConfig} from "../../../../config/toast_config"
//Components
import StepProgressBar from "../../../../elements/StepProgressBar";
import {MenuButton} from "../../Menu";
import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
import Error from "../../../../elements/Error";
import {uploadImportFile} from "../../../../helpers/wizards";
import WizardProduct from "./WizardProduct";
import {productArrayFromObject, productObjectFromArray} from "../utils";
import {useState} from "react";
import FormCheck from "react-bootstrap/FormCheck";

const ImportPage = ({devan_request, skipImport, handleFile}) => {
    const [including, setIncluding] = useState(false);
    return <div>
        <h3>Choose a packing list</h3>
        {devan_request && <p className={'text-danger'}>
            Warning: uploading a new packing list will replace the product list!
        </p>
        }
        <FormControl variant={'primary'} type={'file'} onChange={(event)=>handleFile(event, including)}/>

        <FormCheck checked={including} variant={'primary'} type={'checkbox'}
                     onChange={() => setIncluding(!including)} label={"Automatically read list?"}/>
        <Button onClick={skipImport} variant={'primary'}>Skip</Button>
    </div>;
}

const ChooseCustomer = ({customers, setCustomer}) => <div>
    <h3 className={'col-6'}>Choose a customer</h3>
    {Object.values(customers).map((customer) => {
        return <MenuButton key={customer.id} page={customer.id} updatePage={setCustomer}
                           color={''} icon={false} label={customer.name}/>
    })}
</div>;

const FileModal = ({showPackingList, onHide, url}) => <div>
    {showPackingList &&
    <Modal onHide={onHide}
           show={showPackingList} size={'lg'}>
        <ModalBody style={{maxWidth: '1440px'}} className={'w-100'}>
            <iframe title={'Container Master Packing List Viewer'}
                    height={"500"} className={'w-100'}
                    src={url}/>
        </ModalBody>
    </Modal>}
</div>;

const ButtonPanel = ({url, status, closeWizard, togglePackingList, showPackingList, addProduct, updateRequest, saveAndView}) => <ButtonGroup size={'sm'}>
        <Button size={'sm'} className={'btn btn-danger text-light no-print'}
                onClick={closeWizard}>Return to list</Button>
        {url && <Button variant={'primary'}
                        onClick={togglePackingList}>{showPackingList ? 'Hide Packing List' : 'Show Packing List'}</Button>}
        {!status &&
        <Button variant={'tertiary'} onClick={addProduct}>Add
            product</Button>}
        {!status && <Button variant={'primary'}
                            onClick={updateRequest}>Save</Button>}
        <Button className={'mr-1'} variant={'tertiary'}
                onClick={saveAndView}>{status ? 'View outturn' : 'Save and Outturn'}</Button>
    </ButtonGroup>;

const FormHeader = ({status, container_number, handleChange, ship_name, handleShipNameChange, handleDateChange, expected}) => <Row>
    <Col sm={4}><h3>Ship Name</h3>
        <FormControl size={'sm'} readOnly={status}
                     onChange={handleShipNameChange} name={'ship_name'}
                     value={ship_name ? ship_name : ''}/>
    </Col>
    <Col sm={4}><h3>Container number</h3>
        <FormControl size={'sm'} readOnly={status}
                     onChange={handleChange} name={'container_number'}
                     value={container_number ? container_number : ''}/>
    </Col>
    <Col sm={4}><h3>Expected date</h3>
        <FormControl readOnly={status} size={'sm'} type={'date'}
                     onChange={handleDateChange} name={'expected'}
                     value={new Date(expected).toLocaleDateString('en-CA')}/>

    </Col>
</Row>;

const ErrorPage = ({url, error, redirected_to, resolve}) => {

    return <div className={'bg-light col-12 col-lg-12 p-3 mt-5 mb-3 text-center small'}>
        {
            error && <div>
                <p>
                    {error}
                    <br/>
                    Download the associated picking list <a rel="noopener noreferrer"
                                                            target={"_blank"}
                                                            href={url}
                                                            download={"picking_list.pdf"}>here</a>.
                </p>
                <Button variant={'danger'} size={'sm'} onClick={resolve}>Discard</Button>
            </div>
        }
        {
            redirected_to && <div>
                <p>
                    This container number has already been requested.
                </p>
                <Button variant={'success'} size={'sm'} onClick={resolve}>Continue to original</Button>
            </div>
        }
    </div>;
}

class Wizard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: false,
            container_number: false,
            products: [],
            imported: false,
            container_checked: false,
            expected_checked: false,
            expected: new Date().getTime(),
            revision: 0,
            newProduct: 0,
            skipping: props.status,
            devan_request: props.devan_request,
            showPackingList: false,
            notified: false
        };
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleShipNameChange = this.handleShipNameChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.processData = this.processData.bind(this);
        this.skipImport = this.skipImport.bind(this);
        this.updateRequest = this.updateRequest.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.updatePageWithEntity = this.updatePageWithEntity.bind(this);
        this.product_refs = [];
        this.errors = React.createRef();
    }

    getPackingListUrl() {
        if (!this.state.devan_request) return;
        this.props.firebase.db.ref(db_refs.organisations.request.devan(this.props.organisation_id)).child(this.state.devan_request).child("packing_list").on('value', (snap) => {
            if (snap.val()) {
                this.props.firebase.storage.ref(snap.val()).getDownloadURL().then(url => {
                    this.setState({
                        url: url
                    })
                })
            } else {
                this.setState({url: null})
            }
        })
    }

    addProduct() {
        const {products, newProduct} = this.state;
        products[`${newProduct + 1} new product `] = {
            description: '',
            new: true,
            crates: []
        };
        this.setState({newProduct: newProduct + 1, products: products})
    }

    fetchRequest(request_id) {
        this.endRequest();
        const {firebase, organisation_id} = this.props;
        const ref = db_refs.organisations.request.devan(organisation_id);

        this.setState({devan_request: request_id, current_ref: ref});
        firebase.db.ref(ref).child(request_id).on('value', this.processData);
    }

    endRequest() {
        const {firebase} = this.props;
        if (this.state.current_ref) {
            firebase.db.ref(this.state.current_ref).orderByKey().limitToLast(1).off('value', this.processData);
        }
    }



    processData(snapshot) {
        if (snapshot.val()) {
            const {container_number, ship_name, products, customer, expected, error, redirected_to, packing_list, blocked, started, status} = snapshot.val();
            const formatted_products = productObjectFromArray(products);
            const invalid = (blocked && error) || !!redirected_to;
            this.setState({
                packing_list,
                invalid,
                redirected_to,
                request_id: snapshot.key,
                error,
                crates: products ? products.length : 0,
                customer,
                container_number,
                products: formatted_products,
                ship_name: ship_name || "",
                imported: !!status || !!packing_list,
                skipping: !!status,
                completed: !!status || !!started,
                container_checked: status,
                expected_checked: status,
                started,
                status,
                expected: expected ? expected : new Date().getTime()
            });
            this.getPackingListUrl(customer);
        }
    }

    updateRequest() {
        this.doUpdateRequest(true, false)
    }

    validateProducts() {
        const errors = [];
        const products = [];
        const products_to_create = [];
        this.product_refs.map(p => {
            if (p.isInvalid()) {
                errors.push(p.state.code)
            } else {
                if (p.productChanged().code) {
                    delete products[p.props.product_code];
                }
                const updatedProduct = p.getProduct();
                if (updatedProduct.delete) {
                    delete products[updatedProduct.code];
                } else {
                    if (products[p.state.code]) {
                        products[p.state.code].crates.push(...updatedProduct.crates)
                    } else {
                        products[p.state.code] = updatedProduct;
                    }
                    if (updatedProduct.create) {
                        products_to_create.push({
                            code: updatedProduct.code,
                            description: updatedProduct.description
                        })
                    }
                }
            }
            return true;
        });
        return {errors, products, products_to_create};
    }

    doUpdateRequest(verify, go_to_request) {
        const {errors, products, products_to_create} = this.validateProducts();
        if (errors.length > 0) {
            this.errors.current.setError({message: `Product form has ${errors.length} errors!`})
        } else {
            if (products_to_create.length > 0)
                Promise.all(this.props.firebase.doCreateProducts(this.props.organisation_id, this.state.customer, products_to_create)).then(
                    () => {
                        toast.success(`Added ${products_to_create.length} products`, {...toastConfig, autoClose: 2000});
                    }
                ).catch((err) => console.error(err));
            this.uploadNewRequestRevision(products, verify, go_to_request);
        }
    }



    uploadNewRequestRevision(products, verify, go_to_request) {
        const {firebase, organisation_id, status, updatePageWithEntity} = this.props;
        const {devan_request, customer, container_number, ship_name, notified} = this.state;
        if (status) {
            if (go_to_request) {
                if (devan_request) {
                    updatePageWithEntity('outturns', devan_request)
                }
            }
            return;
        }
        const final_products = productArrayFromObject(products);
        if (!container_number || container_number.trim().length === 0) {
            this.errors.current.setError({message: `You must provide a container number!`});
            return
        }
        if (final_products.length === 0) {
            this.errors.current.setError({message: `You can't request unloading of no products!`});
            return
        }

        const newRevision = {
            container_number: container_number,
            ship_name: ship_name,
            customer: customer,
            products: final_products,
            error: null,
            expected: new Date(parseInt(this.state.expected)).getTime(),
            verified: verify,
            notified
        };
        const isNew = !devan_request;
        const actual_request_id = devan_request ? devan_request : firebase.db.ref(db_refs.organisations.request.devan(organisation_id)).push().key;
        this.props.firebase.db.ref(db_refs.organisations.request.devan(this.props.organisation_id)).child(actual_request_id).update(newRevision)
            .then(() => {
                if (isNew) {
                    toast.success(`Request created`, {...toastConfig, autoClose: 2000});
                } else {
                    toast.success(`Request saved`, {...toastConfig, autoClose: 2000});
                }
                if (go_to_request) {
                    if (actual_request_id) {
                        this.props.updatePageWithEntity('outturns', actual_request_id)
                    }
                }
                this.props.closeWizard();

            }).catch(err => console.error(err));
    }

    skipImport() {
        this.setState({imported: 'Using existing data', skipping: true})
    }

    handleChange(event) {
        let newState = {};
        newState[event.target.name] = event.target.value;
        this.setState(newState)
    }

    handleShipNameChange(event) {
        let newState = {};
        newState[event.target.name] = event.target.value.toUpperCase();
        this.setState(newState)
    }


    handleDateChange(event) {
        let newState = {};
        newState[event.target.name] = new Date(event.target.value).getTime();
        this.setState(newState)
    }


    handleFile(event, including_parsing) {
        this.setState({imported: null, products: false, container_number_checked: false});
        const {firebase, organisation_id} = this.props;
        const {customer, revision, devan_request} = this.state;
        const file = event.target.files[0];
        const actual_request_id = devan_request ? devan_request : firebase.db.ref(db_refs.organisations.request.devan(organisation_id)).push().key;

        let fileRef = storage_refs.packing_list_to_process(
            firebase.auth.currentUser.uid,
            organisation_id,
            customer,
            actual_request_id,
            parseInt(revision) + 1,
            file.name);
        if (!including_parsing) {
            fileRef = storage_refs.packing_list_to_store(
                firebase.auth.currentUser.uid,
                organisation_id,
                customer,
                actual_request_id,
                parseInt(revision) + 1,
                file.name);
        }
        uploadImportFile(this, fileRef, file, (file) => {
            this.fetchRequest(actual_request_id);
            this.setState({imported: file.name, collection_request: actual_request_id});
        });
    }

    updatePageWithEntity() {
        this.doUpdateRequest(true, true)
    }

    componentDidMount() {
        const {devan_request} = this.props;
        if (devan_request) {
            this.fetchRequest(devan_request);
        }
    }

    componentWillUnmount() {
        this.endRequest();
        clearTimeout(this.timeout);
        clearInterval(this.interval)
    }

    getSteps() {
        const {customer, imported} = this.state;
        const {customers} = this.props;
        return [
            {
                src: customers_img,
                label: customer ? customers[customer].code : "Choose customer",
                complete: !!customer
            },
            {
                src: devan_request_img,
                label: imported ? imported : "Upload Packing List",
                complete: imported
            },
            {
                src: products_img,
                label: "Check Products",
                complete: false
            }
        ]
    }

    getCurrent() {
        let current = 2;
        if (!this.state.customer) {
            current = 0;
        } else if (!this.state.imported && !this.state.skipping) {
            current = 1;
        }
        return current;
    }

    getSortedProducts(products) {
        return Object.keys(products).sort((p1, p2) => {
            return products[p1].new ? -1 : products[p2].new ? 1 : 0
        })
    }

    render() {
        const {
            invalid, customer, imported, container_number,
            skipping, showPackingList, url, products, status,
            expected, redirected_to, error, ship_name
        } = this.state;
        const {devan_request, closeWizard, firebase, organisation_id, editDevan, existing_products, customers} = this.props;
        const {updatePageWithEntity, addProduct, updateRequest, handleDateChange, handleChange, handleShipNameChange} = this;
        const ref = devan_request ? firebase.db.ref(db_refs.organisations.request.devan(organisation_id)).child(devan_request) : null;
        let current = this.getCurrent();
        const steps = this.getSteps();
        return (
            <div className={'row'}>
                <div className={'col-xs-12 col-sm-10 offset-sm-1 bg-tertiary p-2 pb-0 pt-1'}>
                    <div className={'col-12 col-lg-8 offset-lg-2 p-3 mt-5 mb-3'}>
                        <StepProgressBar steps={steps} current={current}/>
                    </div>
                    {
                        !invalid &&
                        <div className={'bg-light col-12 col-lg-12 p-3 mt-5 mb-3 text-center small'}>
                            {!customer && <ChooseCustomer customers={customers} setCustomer={(event)=>this.setState({customer: event.target.dataset.page})}/>}
                            {
                                customer && <div className={'text'}>
                                    {!(imported || status) && <ImportPage devan_request={devan_request}
                                                                          skipImport={this.skipImport}
                                                                          handleFile={this.handleFile}/>}
                                    {(imported || status) && <div>
                                        {
                                            !container_number && !skipping && <div>
                                                <h3>Packing list is being processed</h3>
                                                <LoadingScreen/>
                                            </div>
                                        }
                                        {(container_number || skipping) && <div>
                                            <div className={'col-12 text-right'}>
                                                {url && <FileModal
                                                    showPackingList={showPackingList}
                                                    url={url}
                                                    onHide={() => this.setState({showPackingList: false})}
                                                />}
                                                <Error ref={this.errors} error={false}/>
                                                <ButtonPanel
                                                    saveAndView={() => updatePageWithEntity('outturns', devan_request)}
                                                    updateRequest={updateRequest}
                                                    addProduct={addProduct}
                                                    togglePackingList={() => this.setState({showPackingList: !showPackingList})}
                                                    closeWizard={closeWizard}
                                                    showPackingList={showPackingList}
                                                    url={url}
                                                    status={status}
                                                />
                                            </div>
                                            <FormHeader container_number={container_number} expected={expected}
                                                        handleChange={handleChange}
                                                        handleShipNameChange={handleShipNameChange}
                                                        ship_name={ship_name}
                                                        status={status}
                                                        handleDateChange={handleDateChange}/>
                                            <h3>Confirm products</h3>
                                            {
                                                this.getSortedProducts(products).map((product, i) => {
                                                    return <WizardProduct
                                                        status={status}
                                                        key={product + "_wizard"}
                                                        ref={(ref) => this.product_refs[i] = ref}
                                                        product_index={i}
                                                        product_code={product}
                                                        existing_products={Object.values(existing_products).filter(p => p.customer === customer)}
                                                        product={products[product]}/>
                                                })
                                            }

                                        </div>}
                                    </div>}
                                </div>
                            }

                        </div>
                    }
                    {
                        invalid && <ErrorPage
                            closeWizard={closeWizard}
                            error={error}
                            url={url}
                            editDevan={editDevan}
                            redirected_to={redirected_to}
                            ref={ref}
                            resolve={() => {
                                closeWizard();

                                ref.set(null).then(() => {
                                    toast.error("Duplicate removed");
                                    editDevan(redirected_to);
                                });
                            }}
                        />
                    }
                </div>
            </div>
        );
    }
}

Wizard.propTypes = {
    customers: PropTypes.object.isRequired,
    organisation_id: PropTypes.string.isRequired,
    existing_products: PropTypes.object,
    firebase: PropTypes.object.isRequired,
    collection_request: PropTypes.string
};
export {FileModal, FormHeader};
export default Wizard;