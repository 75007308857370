import React from "react";
import * as PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InlineInput from "../../../elements/InlineInput";
import Button from "../../../elements/Button";
import Error from "../../../elements/Error";
import ToggleButton from "../../../elements/ToggleButton";
import UnitInput from "../../../elements/UnitInput";
import multi from './multi.svg';
import single from './single.svg';
import Image from "../../../elements/Image";
import db_refs from '../../../constants/db_refs';
import storage_refs from '../../../constants/storage_refs';
import {toastConfig} from "../../../config/toast_config";
import {toast} from "react-toastify";
import {FormControl} from "react-bootstrap";
import ManualMovement from "../../../components/ManualMovement/ManualMovement";

const INITIAL_STATE = () => {
    return {
        code: '',
        capacity: 0,
        capacity_unit: "kg",
        range: false,
        showForm: false,
        prefix: '',
        from: 1,
        to: 20,
        invalid: true,
        import_file: '',
        import_customer: ''
    }
};

class BayForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE();
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
        this.errors = React.createRef();
        this.toggleRange = this.toggleRange.bind(this);
        this.importFile = this.importFile.bind(this);
    }

    toggleRange() {
        this.setState({range: !this.state.range})
    }

    handleChange(event) {
        let newState = {};
        newState[event.target.name] = event.target.value;
        this.setState(newState);

    }

    toggleForm() {
        this.setState({showForm: !this.state.showForm});
    }

    onSubmit() {
        const isInvalid = this.isInvalid();
        this.errors.current.setError(isInvalid);
        const {range, code, to, from, capacity, prefix, capacity_unit} = this.state;
        if (!isInvalid) {
            if (range) {
                let promises = [];
                for (let i = from; i <= to; i++) {
                    const bay = {
                        code: `${prefix}${i}`,
                        capacity: capacity,
                        capacity_unit: capacity_unit
                    };
                    bay.last_active = new Date().getTime();
                    promises.push(this.props.firebase.db.ref(db_refs.organisations.bays(this.props.organisation_id)).push(bay));
                }
                Promise.all(promises).then(
                    (results) => {
                        toast.success(`Added ${results.length} bay(s) - ${prefix}${from}-${to}`, toastConfig)
                    }
                ).catch((errs) => {
                    this.errors.current.setError(errs);
                })
            } else {
                const bay = {
                    code: `${code}`,
                    capacity: capacity,
                    capacity_unit: capacity_unit
                };
                this.props.firebase.db.ref(db_refs.organisations.bays(this.props.organisation_id)).push(bay).then(
                    () => {
                        toast.success(`Added bay ${code}`, toastConfig)
                    }).catch((err) => {
                    this.errors.current.setError(err);
                });
            }
        }
    }

    importFile() {
        const fileRef = storage_refs.stock_import(this.props.firebase.auth.currentUser.uid, this.props.organisation_id, this.import.files[0].name);
        // const fileStatus = db_refs.stock_import_status(this.props.organisation_id, this.state.import_customer, this.import.files[0].name);

        this.props.firebase.storage.ref(fileRef).put(this.import.files[0]);
        this.setState({
            import_customer: '',
            import_file: ''
        })
    }

    isInvalid() {
        const {bayKeys} = this.props;
        const {code, from, to, prefix, range} = this.state;
        let duplicates = [];
        if (range) {
            if (prefix.length < 1) {
                return {message: "Prefix must be at least 1 character"};
            }
            if (to < 1 || to < from || from < 0) {
                return {message: "Invalid range"};
            }

            for (let i = from; i <= to; i++) {
                if (bayKeys.indexOf(`${prefix}${i}`) > -1) {
                    duplicates.push(`${prefix}${i}`);
                }
            }
        } else {
            if (code.length < 1) {
                return {message: "You must give the bay an identifier"};
            }
            if (bayKeys.indexOf(code) > -1) {
                duplicates.push(code);
            }
        }
        if (duplicates.length > 0) {
            return {message: duplicates.length === 1 ? `${duplicates.join(',')} already exists` : `${duplicates.join(',')} already exist`}
        }

        return false
    }

    render() {
        return (
            <div className={'row'}>
                <div className={'col-xs-12 col-sm-12 bg-tertiary p-0 pb-0 pt-0'}>
                    <div onClick={this.toggleForm} className={'col-12 text-right pt-3 pr-3 text-light'}>
                        Create Bays or Import Stock <FontAwesomeIcon
                        icon={this.state.showForm ? 'minus-circle' : 'plus-circle'} size={'1x'}/>
                    </div>
                    {this.state.showForm && <div className={'row m-0 pr-2 pl-2 pb-0 pt-0 text-center'}>
                        <div className={'col-12'}>
                            <Error ref={this.errors} error={this.state.error}/>
                        </div>

                        {!this.state.range &&
                        <div className={'small text-left text-light font-weight-bold col-xs-12 col-lg-4'}>
                            <br/>
                            <InlineInput
                                value={this.state.code}
                                required={true}
                                label_id={"app.bay_name"}
                                handleChange={this.handleChange}
                                name={'code'}
                                prefix={'bay_form'}
                                className={'input-sm form-control'}
                            />
                        </div>}
                        {!this.state.range &&
                        <div className={'small text-left text-light font-weight-bold col-xs-12 col-lg-4'}>
                            <br/>
                            <UnitInput
                                value={this.state.capacity}
                                unit={this.state.capacity_unit}
                                handleChange={this.handleChange}
                            />
                        </div>}
                        {this.state.range &&
                        <div className={'small text-left text-light font-weight-bold col-xs-12 col-lg-3'}>
                            <br/>
                            <InlineInput
                                value={this.state.prefix}
                                required={true}
                                label_id={"app.bay_prefix"}
                                handleChange={this.handleChange}
                                name={'prefix'}
                                prefix={'bay_form'}
                                className={'input-sm form-control'}
                            />
                        </div>}
                        {this.state.range &&
                        <div className={'small text-left text-light font-weight-bold col-xs-12 col-lg-2'}>
                            From
                            <InlineInput
                                value={this.state.from}
                                required={true}
                                label_id={"app.from"}
                                handleChange={this.handleChange}
                                name={'from'}
                                prefix={'bay_form'}
                                className={'input-sm form-control'}
                                type={'number'}
                            />

                            To
                            <InlineInput
                                value={this.state.to}
                                required={true}
                                label_id={"app.to"}
                                handleChange={this.handleChange}
                                name={'to'}
                                prefix={'bay_form'}
                                className={'input-sm form-control'}
                                type={'number'}
                            />
                        </div>}
                        {this.state.range &&
                        <div className={'small text-left text-light font-weight-bold col-xs-12 col-lg-3'}>
                            <br/>
                            <UnitInput
                                value={this.state.capacity}
                                unit={this.state.capacity_unit}
                                handleChange={this.handleChange}
                            />
                        </div>}
                        <div className={'small text-left text-light font-weight-bold col-xs-12 col-lg-2 text-right'}>
                            <br/>
                            <Button
                                testid={'add_bay'}
                                onClick={this.onSubmit}
                                disabled={false}
                                title_id={this.state.range ? 'app.add_range' : 'app.add_customer'}
                                className={'btn btn-primary text-light btn-sm'}
                                icon={<FontAwesomeIcon icon={'plus-circle'}/>}
                            />
                        </div>
                        <div className={'small text-left text-light font-weight-bold col-xs-12 col-lg-2 text-center'}>
                            <br/>
                            <Image
                                className={'img img-fluid w-25 mr-1 image_icon'}
                                src={single}
                                alt_id={"app.bay_add_single"}/>
                            <ToggleButton on={this.state.range} toggle={this.toggleRange}/>
                            <Image
                                className={'img img-fluid w-25 ml-2 image_icon'}
                                src={multi}
                                alt_id={"app.bay_add_multi"}/>
                        </div>
                        <hr className={'bg-secondary w-75 mb-2'}/>
                        <div className={'text-center col-12 bg-tertiary p-0 pb-0 pt-0 row m-0'}>

                            <div className={'col-6'}>
                                <FormControl
                                    ref={ref => this.import = ref}
                                    value={this.state.import_file}
                                    onChange={this.handleChange}
                                    name={'import_file'}
                                    className={'btn btn-primary text-light'}
                                    variant={'primary'}
                                    type={'file'}
                                    accept={'.xlsx, .csv'}
                                />
                            </div>
                            <div className={'col-4'}>
                                <Button onClick={this.importFile}
                                        disabled={this.state.import_file.length === 0}
                                        className={'btn btn-primary'} title_id={'app.import'}>Import</Button>
                                <a href={"/download_template.xlsx"}><Button title_id={'app.download_template'} className={'btn btn-info'}>Download Template</Button></a>
                            </div>
                            <div className={'col-2'}>

                                <Button className={'btn btn-primary'} onClick={()=>{this.setState({movement:true})}} title_id={'app.movement'}>Manual Movement</Button>
                                {!!this.state.movement && <ManualMovement show={this.state.movement} onHide={()=>{this.setState({movement:false})}}/>}
                            </div>
                        </div>
                    </div>}
                </div>
            </div>)
    }
}

BayForm.propTypes = {
    organisation_id: PropTypes.string.isRequired,
    firebase: PropTypes.object.isRequired,
    customers: PropTypes.array,
    bayKeys: PropTypes.array.isRequired
};

export {BayForm};
export default BayForm;