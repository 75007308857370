import * as React from "react";
import {ContainerMasterForecastContext} from "../../../components/ContainerMaster/ContainerMaster";
import {RowCell} from "../../../elements/Common";
import SearchableTable from "../../../elements/SearchableTable";
import db_refs from '../../../constants/db_refs';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, ButtonGroup, Modal, ModalBody, ModalFooter} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {toast} from "react-toastify";
import {AdvancedFilter, AdvancedFilterPanel} from "../../../elements/AdvancedFiltering";
import {MOVEMENT_STATUS_LABELS, STATUS_LABELS2} from "../../../components/ContainerMaster/withForecast";
import Hotbar from "../../../elements/Hotbar";

class Tracking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totals: null
        }

    }

    componentDidMount() {
        const {firebase, organisation_id} = this.props;
        firebase.db.ref(db_refs.organisations.tracking.totals(organisation_id))

    }

    render() {
        const {updatePage, firebase} = this.props;

        return <div className={'col-xs-12 col-sm-11 ml-auto mr-auto bg-primary pt-2 pb-2 mt-1'}>
            <Hotbar updatePage={updatePage} page={'tracking'}/>
            <h3 className={'text-light text-center'}>Forecast</h3>
<div className={'col-12 m-0'}>
               <TrackingTable organisation_id={this.props.organisation_id} filter={this.props.filter.forecast}
                              setFilter={(value, callback) => {
                                  this.setState({loading: true}, () => {
                                      this.props.setForecast(value, () => this.setState({loading: false}, callback))
                                  });
                              }} small={true} firebase={firebase} />
</div>
            <div className={'col-6'}>

            </div>
        </div>
    }
}

// noinspection JSUnusedGlobalSymbols
class TrackingTable extends SearchableTable {
    key = 'forecast';
    has_export = 'Export Movements';
    CMContext = ContainerMasterForecastContext;
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState,
            page: 0,
            num_per_page: 10,
            noDetails: true,
            hide_date: true
        };

    }
    getReportTitle() {
        return 'Forecast';
    }

    getAdditionalHeaders() {
        return [{
            label: 'Admin',
            size: 1,
            content: (product) => {

                if(product.has_duplicate) {
                    return <React.Fragment>
                        <div style={{border: '1px solid'}} className={'small border-danger rounded border-1 bg-warning text-dark'}>
                            <FontAwesomeIcon className={'text-danger'} size={'2x'} icon={'exclamation-triangle'}/>
                            It looks like this record is outdated, the correct record can be found in reporting
                            <br/> <Button onClick={()=>{
                            this.setState({deleting_forecast: product.referenceKey})}
                        } size={'sm'} variant={'danger'}>Delete</Button> </div>
                    </React.Fragment>
                }
                return '-'
            }
        }]
    }
    setFilter(filter, callback) {
        this.props.setFilter(filter.target.value, callback)
    }

    getAdvancedFilters({updateStatusLabels: onClick, status_filters, selectAll, selectNone, extra_date_filters, updateMovementStatusLabels: onClickMovement, selectAllMovement, selectNoneMovement, movement_status_filters}) {
        return <AdvancedFilterPanel><AdvancedFilter
            legend_text={'Movement Type'}
            onClick={onClick}
            selectAll={selectAll}
            deselectAll={selectNone}
            original_list={[...STATUS_LABELS2]}
            current_list={status_filters}
        />
            <AdvancedFilter
                legend_text={'Movement Status'}
                onClick={onClickMovement}
                selectAll={selectAllMovement}
                deselectAll={selectNoneMovement}
                original_list={[...MOVEMENT_STATUS_LABELS]}
                current_list={movement_status_filters}
            />
        </AdvancedFilterPanel>
    }
    getContent(data, headers) {

        const rows = data.map(collection=><tr title={collection.referenceKey} key={collection.referenceKey} className={'text-left small col-12 w-100 m-1 product item-row mb-1 print-block'}>
            {headers.map((h, i) =>  <RowCell  key={`table_row_${collection.referenceKey}_${h.key}`}  col={3} title={h.label} alignment={'center'}
                                              header={i===0} noprint={h.noprint}>{h.content(collection)}</RowCell>)}
        </tr>);
        if(this.state.deleting_forecast) {
            const forecast = data.find(x=>x.referenceKey===this.state.deleting_forecast);
            return [
                <Modal show={true} onHide={()=>this.setState({deleting_forecast: null})}>
                    <ModalHeader className={'bg-primary text-light'}><h3 className={'text-light'}>Remove outdated forecast item</h3> </ModalHeader>
                    <ModalBody>Reference: {forecast.reference}
                    <br/>
                    Bays may need to be checked if they do not update automatically.
                    </ModalBody>
                    <ModalFooter className={'bg-primary'}><ButtonGroup>
                        <Button onClick={()=>this.setState({deleting_forecast: null})} variant={'secondary'}>Cancel</Button>
                        <Button variant={'danger'} onClick={()=>{
                            const {firebase, organisation_id} = this.props;
                            const ref = firebase.db.ref(db_refs.organisations.tracking.by_status(organisation_id, "pending")).child(forecast.referenceKey);
                            this.setState({deleting_forecast: null}, ()=>ref.set(null).then(()=>toast.success("Removed entry")));
                        }}>Delete</Button>
                    </ButtonGroup></ModalFooter>
                </Modal>,
                ...rows
            ]
        }
        return [...rows]
    }
}

export {TrackingTable};

export default Tracking;