import React, {useState} from "react"
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Modal} from "react-bootstrap";
import DATABASE_REFS from '../../constants/db_refs'
import {toast} from "react-toastify";
const ProductRemoval = ({organisation_id, firebase, movement_id, product_code}) => {
    const [showConfirm, setShowConfirm] = useState(false)
    const [removing, setRemoving] = useState(false)

    const removeFromMovement = () => {
        firebase.db.ref(DATABASE_REFS.organisations.stock_movement.stock_in(organisation_id))
            .child(movement_id).child("products").child(product_code).set(null).then(()=>{
            toast.error("Product deleted")
            setRemoving(false)
        })
    }
    return <React.Fragment>
        <FontAwesomeIcon onClick={()=>setShowConfirm(true)} icon={faTrash} className={"text-danger"}/>
        <Modal show={showConfirm} onHide={()=>setShowConfirm(false)}>
            <Modal.Header>Remove product {product_code} from outturn?</Modal.Header>
            <Modal.Body>Are you sure you wish to remove this product from this outturn?</Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={()=>setShowConfirm(false)}>Cancel</Button>
                <Button disabled={removing} variant={"danger"} onClick={()=>removeFromMovement()}>Remove</Button>
            </Modal.Footer>
        </Modal>
    </React.Fragment>
}

export default ProductRemoval;