import React from 'react';
import Button from "../../elements/Button";
import {withFirebase} from "../Firebase";
import ConfirmPrompt from "../../elements/ConfirmPrompt";

const INITIAL_STATE = () => {
    return {
        error: false,
        show_logout_prompt: false
    }
};

class SignOutButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE();
        this.onSubmit = this.onSubmit.bind(this);
        this.showLogoutPrompt = this.showLogoutPrompt.bind(this);
        this.hideLogoutPrompt = this.hideLogoutPrompt.bind(this);
    }

    showLogoutPrompt() {
        this.setState({show_logout_prompt: true});
    }
    hideLogoutPrompt() {
        this.setState({show_logout_prompt: false});
    }

    onSubmit() {
        return this.props.firebase.doSignOut().then(() => {

        }).catch((error) => {
            console.error(error);
            this.setState({error: error});
        })
    }

    render() {
        return (
            <span>
                {this.state.show_logout_prompt  && <ConfirmPrompt
                    message_id={'app.logout_prompt'}
                    cancel_title_id={"app.cancel"}
                    confirm_title_id={"app.logout"}
                    onCancelClick={this.hideLogoutPrompt}
                    onConfirmClick={this.onSubmit}
                />}
                <Button className={'btn-primary text-light'} onClick={this.showLogoutPrompt} testid={"sign-out-button"} title_id={"app.logout"}/>
            </span>
        );
    }
}

SignOutButton.propTypes = {

};
export {SignOutButton};
export default withFirebase(SignOutButton);