import PageTemplate from "../../PageTemplate";
import React from "react";
import Table from "./Table";


import LoadingScreen from "../../../../components/LoadingScreen/LoadingScreen";
import ErrorMessage from "../../../../elements/ErrorMessage";
import Wizard from "./Wizard";
import {
    ContainerMasterCollectionsContext,
    ContainerMasterDataContext
} from "../../../../components/ContainerMaster/ContainerMaster";
import Hotbar from "../../../../elements/Hotbar";

class Collections extends PageTemplate {

    constructor(props) {
        super(props);
        this.state = {
            editing: props.entity,
            filter: '',
            loading: false
        };
        this.edit = this.edit.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);
    }

    startCreation() {
        this.setState({creating: true})
    }

    cancelCreation() {
        this.setState({creating: false})
    }

    edit(id) {
        this.setState({editing: id})
    }

    cancelEditing() {
        this.setState({editing: false})
    }

    render() {
        const {creating, editing, loading} = this.state;
        return (
            <div>
                <Hotbar updatePage={this.props.updatePage} page={'collection_requests'}/>

                <div className={'col-xs-12 col-sm-11 bg-primary pt-2 pb-2 mt-1 ml-auto mr-auto'}>
                    <h3 className={'text-light text-center no-print'}>Collection Requests</h3>
                    <ContainerMasterDataContext.Consumer>
                        {
                            ({customers, products, gate_passes, bulk_picking_lists}) => {

                                if (!customers || loading)
                                    return <LoadingScreen/>;
                                return <div>
                                    {loading && <LoadingScreen/>}
                                    {!loading && <div className={''}>
                                        {
                                            customers.length === 0 && <ErrorMessage
                                                message={"Whoops, no customers"}
                                                details={"You must create a customer before you can start devanning."}
                                                link={
                                                    <button
                                                        className={'btn btn-tertiary'}
                                                        data-page='customers'
                                                        onClick={this.props.updatePage}
                                                    >
                                                        Create one now!
                                                    </button>}

                                            />
                                        }
                                        {
                                            customers && !creating && !editing && <div className={'text-right'}>
                                                <Table
                                                    editBulk={(id) => this.setState({bulk_edit: id})}
                                                    customers={customers}
                                                    gate_passes={gate_passes}
                                                    startCreation={this.startCreation.bind(this)}
                                                    firebase={this.props.firebase}

                                                    organisation_id={this.props.organisation_id}

                                                    editDevan={this.edit}
                                                    filter={this.props.filter.requests}
                                                    setFilter={(value, callback) => {
                                                        this.setState({loading: false}, () => {
                                                            this.props.setCollectionRequestsFilter(value, () => this.setState({loading: false}, callback))
                                                        });
                                                    }}
                                                    picking_lists={bulk_picking_lists}
                                                 />
                                            </div>
                                        }
                                        <ContainerMasterCollectionsContext.Consumer>
                                            {({requests}) => {
                                                let edited_request = requests.all[editing];

                                                return <div className={'text-right'}>
                                                    {
                                                        customers && creating &&
                                                        <Wizard
                                                            editCollection={this.edit}
                                                            updatePageWithEntity={this.goToPageWithEntity}
                                                            closeWizard={this.cancelCreation.bind(this)}
                                                            organisation_id={this.props.organisation_id}
                                                            firebase={this.props.firebase}
                                                            customers={customers}
                                                            products={Object.values(products || {})}
                                                        />
                                                    }
                                                    {
                                                        editing && Boolean(edited_request)  && customers && <Wizard
                                                            key={`wizard_${editing}`}
                                                            editCollection={this.edit}
                                                            updatePageWithEntity={this.goToPageWithEntity}
                                                            closeWizard={this.cancelEditing}
                                                            organisation_id={this.props.organisation_id}
                                                            firebase={this.props.firebase}
                                                            customers={customers}
                                                            products={Object.values(products || {})}
                                                            request={edited_request}
                                                            collection_request_id={editing}
                                                        />
                                                    }
                                                    {
                                                        editing && !edited_request &&
                                                        <LoadingScreen/>
                                                    }
                                                </div>
                                            }
                                            }
                                        </ContainerMasterCollectionsContext.Consumer>
                                    </div>}

                                </div>


                            }
                        }</ContainerMasterDataContext.Consumer>

                </div>
            </div>
        )
    }
}

export default Collections;