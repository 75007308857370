import React, {useEffect, useRef, useState} from "react";
import Hotbar from "../../../elements/Hotbar";

import DATABASE_REFS from "../../../constants/db_refs";

import {TrackingTable} from "../Tracking";
import {RowCell} from "../../../elements/Common";
import {Button, ButtonGroup, Modal, ModalFooter} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {toast} from "react-toastify";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BulkPickingList from "./modal";
import {ContainerMasterDataContext} from "../../../components/ContainerMaster/ContainerMaster";

import PickingList from "./picking_list";
import Labels from "./labels";

class BulkPickingTable extends TrackingTable {
    has_export = "Export Picking Lists";
    CMContext = BulkPickingListContext;
    key = 'picking_lists';
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState,
            page: 0,
            num_per_page: 10,
            noDetails: true,
            hide_date: true,
            hide_advanced_filters: true
        };

    }
    getCustomButtonPanel() {
        return <ButtonGroup size={'sm'}>
            <Button variant={'success'} onClick={this.props.createBulkList}><FontAwesomeIcon icon={'plus-circle'}/></Button>
        </ButtonGroup>
    }

    deleteItem(pid) {
        const {firebase, organisation_id} = this.props;

        firebase.db.ref(DATABASE_REFS.organisations.bulk.picking(organisation_id)).child(pid).set(null).then(() => {
            toast.error('Deleted bulk picking list');
            this.setState({deleting: null})
        })
    }
    getDeletionModal() {
        if (this.state.deleting) {
            return <Modal onHide={() => this.setState({deleting: null})} size={'lg'} show={true}>
                <ModalHeader className={'bg-primary'}>
                    <h3 className={'text-light'}>{`Deleting picking list ${this.state.deleting.picking_list_number}`}</h3>
                </ModalHeader>
                <ModalFooter className={'bg-light'}>
                    <Button onClick={() => this.setDeleteItem(null)} variant={'secondary'}>Cancel</Button>
                    <Button variant={'danger'}
                            onClick={() => this.deleteItem(this.state.deleting.id)}>Delete</Button>
                </ModalFooter>
            </Modal>
        }
    }



    getAdditionalHeaders() {
        const {firebase, organisation_id} = this.props;
        return [{
            label: 'Actions',
            content: (p) => {
                return <ButtonGroup size={'sm'}>
                  <PickingList collections={p.collections} picking_list={p} firebase={firebase} organisation_id={organisation_id}/>
                  <Labels collections={p.collections} picking_list={p}/>
                    <Button onClick={()=>this.props.setEditItem(p)}>Edit</Button>
                    <Button variant={'danger'} disabled={p.pass_id || p.provisional_pass_id} onClick={()=>this.setDeleteItem(p)}>Delete</Button>
                </ButtonGroup>
            }
        }];
    }
    getAdvancedFilters({updateStatusLabels: onClick, status_filters, selectAll, selectNone, extra_date_filters, updateMovementStatusLabels: onClickMovement, selectAllMovement, selectNoneMovement, movement_status_filters}) {
        return []
    }
    getContent(data, headers) {

        const rows = data.map(collection=><tr key={collection.id} className={'text-left col-12 w-100 m-1 product item-row mb-1 print-block'}>
            {headers.map((h, i) =>  <RowCell  key={`table_row_${collection.id}_${h.key}`}  col={3} title={h.label} alignment={'center'}
                                              header={i===0} noprint={h.noprint}>{h.content(collection)}</RowCell>)}
        </tr>);

        return [...rows]
    }
}

const headers = [{
    label: 'Picking List Number',
    key: 'picking_list_number',
    filter: true,
    size: 2,
    content: (p)=>{
        return  <span className={'bg-primary m-1 p-1 text-light rounded'}>{p.picking_list_number}</span>
    }
},{
    label: 'Collections',
    key: 'collections',
    filter: true,
    filterValue:  pass => Object.values(pass.collections || {}).map(p=>p.collection_ref).join(','),
    size: 3,
    content: (pass) => {
        const collections = Object.values(pass.collections || {}).map(p=>p.collection_ref).join(',').split(',');
        if (collections.length === 0 || collections[0] === "") {
            return '-'
        }
        return <span className={'bg-primary m-1 p-1 text-light rounded'}
                     title={Object.values(pass.collections || {}).map(p=>p.collection_ref).join('\n')}>
                    {collections.pop()}
            {collections.length > 0 && <span style={{
                fontSize: "0.8em",
                fontWeight: 100,
                marginLeft: "0.4em"
            }}>+{collections.length} more</span>}
                </span>
        //return <div style={{maxWidth: "200px", display: "grid"}}>{Object.values(product.collections||{}).map(c => <span key={`collection_cell_${c.id}`} className={'bg-primary m-1 p-1 text-light rounded'}>{c.collection_ref}</span>)}</div>
    }
},{
    label: 'Gate Pass Number',
    key: 'pass_number',
    filter: true,
    size: 2,
    content: (p)=>{
        return p.pass_number ? p.pass_number : '-'
    }
},{
    label: 'Collected On',
    key: 'issued_on',
    filter: true,
    size: 2,
    content: (p)=>{
        return p.issued_on ? new Date(parseInt(p.issued_on)).toLocaleString() : '-'
    }
}];

const BulkPickingListContext = React.createContext({});

const Bulk = (props) => {
    const {organisation_id, firebase, user_name, organisation} = props;

    let loading_timeout = null;
    const setSort = (new_sort) => {
        if (sort !== new_sort) {
            setSortKey(new_sort);
        } else {
            setReverse(reverse*-1)
        }
    };
    const [creating, setCreating] = useState(false);
    const [editing, setEditing] = useState(false);

    const createBulkList = () => {
        setCreating(true)
    };
    const cancel = () => {
        setCreating(false);
        setEditing(false);
    };

    const setEditItem = (picking_list) => {
        setEditing(picking_list)
    };


    const applyFilter = (item) => {

        let matched = headers.filter(h => !!h.filter).find(h => {
            const {key} = h;
            if(h.filterValue) {
                return(h.filterValue(item).toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
            }
            return item[key] && (item[key].toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
        });

        return !!matched
    };
    const [filter, setFilter] = useState('');
    const [loading, setLoading] = useState(true);
    const [sort, setSortKey] = useState('picking_list_number');
    const [reverse, setReverse] = useState(-1);


    const [picking_lists, setPickingList] = useState({});
    const [sorted, setSorted] = useState([]);
    const [data, setData] = useState([]);
    const [exportData, setExportData] = useState([]);
    const inner = useRef();

    const addPickingList = async (picking_list_snap) => {
        const picking_list = picking_list_snap.val();
        picking_list.id = picking_list_snap.key;

        picking_list.customer_name = (await firebase.db.ref(DATABASE_REFS.organisations.customers(organisation_id)).child(picking_list.customer).child('name').once('value')).val();
        if (picking_list.pass_id || picking_list.provisional_pass_id) {
            const gate_pass = await firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).child(picking_list.pass_id || picking_list.provisional_pass_id).once('value');
            Object.assign(picking_list, gate_pass.val() || {})
        }
        await Promise.all(Object.keys(picking_list.collections || {}).map((ckey)=>{
            firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).child(ckey).once('value').then((res)=>{
                if(picking_list.pass_id && !res.val().pass_id) {
                    firebase.db.ref(DATABASE_REFS.organisations.stock_movement.stock_out(organisation_id)).child(ckey).child('pass_id').set(picking_list.pass_id)
                }
                picking_list.collections[ckey] = res.val()
            })
        }));
        setPickingList((picking_lists=>{
            picking_lists[picking_list_snap.key] = picking_list;
            if (loading_timeout) {
                clearTimeout(loading_timeout)
            }
            loading_timeout = setTimeout(()=>setLoading(false), 200);
            return {...picking_lists}
        }))

    };

    const getExportData = () => {
        const exports = [];
        data.map(d => {
            const {collections, issued_on, picking_list_number, pass_number} = d;

            return Object.values(collections || {}).map((p) => {

                const {} = d;
                const res = {};
               res.collection_ref = p.collection_ref;
               res.picking_list_number = picking_list_number;
               res.pass_number = pass_number;
               res.collected_on = issued_on ? new Date(parseInt(issued_on)).toISOString(): '';
               res.total = p.grand_total;
                exports.push(res)
            });
        });
        return exports

    };

    const removePickingList = (completed_snap) => {
        setPickingList((pending=>{
            const new_pending = {};
            Object.keys(pending).map(ckey=>{
                if (ckey !== completed_snap.key) {
                    new_pending[ckey] = pending[ckey]
                }
            });
            return new_pending
        }))
    };
    const fetchPickingLists = async () => {
        const exists = (await firebase.db.ref(DATABASE_REFS.organisations.bulk.picking(organisation_id)).once('value')).exists();
        if (!exists) {
            setLoading(false)
        }
        setPickingList(()=>{
            const ref = firebase.db.ref(DATABASE_REFS.organisations.bulk.picking(organisation_id));
            ref.off();
            ref.on('child_added', addPickingList);
            ref.on('child_changed', addPickingList);
            ref.on('child_removed', removePickingList);
            return {};
        })

    };

    useEffect(()=>{
        fetchPickingLists();
        return () => firebase.db.ref(DATABASE_REFS.organisations.bulk.picking(organisation_id)).off();
    }, [organisation_id]);



    useEffect(() => {
        setSorted(() => {
            return Object.values(picking_lists).sort((a, b) => {

                const split_sort = sort.split(',');
                if (split_sort.length > 1) {
                    const a_val = a[split_sort[0]] === '-' || a[split_sort[0]] === '' ? a[split_sort[1]] : a[split_sort[0]];
                    const b_val = b[split_sort[0]] === '-' || a[split_sort[0]] === '' ? b[split_sort[1]] : b[split_sort[0]];
                    return ((a_val < b_val || !b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
                }
                const a_val = typeof (a[sort]) === 'string' ? a[sort].toLowerCase() : a[sort];
                const b_val = typeof (b[sort]) === 'string' ? b[sort].toLowerCase() : b[sort];
                if (a_val && !b_val) {
                    return -1 * reverse;
                }
                if (b_val && !a_val) {
                    return reverse;
                }
                if (!b_val && !a_val) {
                    return 0;
                }

                return ((a_val < b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
            })
        })
    }, [picking_lists, sort, reverse]);
    useEffect(() => {
        setData(() => {
            return sorted.filter(applyFilter)
        })
    }, [sorted, filter]);
    useEffect(() => {
        setExportData(() => {
            return getExportData()
        })
    }, [data]);
    if(loading) {
        return  <LoadingScreen/>
    }
    return <React.Fragment>
        <Hotbar updatePage={props.updatePage} page={'bulk'}/>
<ContainerMasterDataContext.Consumer>
    {({customers, products}) =>

        <div id={'printarea'} ref={inner} className={'col-11 ml-auto mr-auto bg-light row m-0 p-3 bays print-block'}>
            <h2 className={'w-100 text-center'}>Bulk Picking Lists</h2>
            <div className={'col-12 m-0'}>
                {(creating || editing) && <BulkPickingList products={products} organisation={organisation} user_name={user_name} picking_list_id={editing ? editing.id : null} show={true} customers={Object.values(customers|| {})} onHide={cancel} firebase={firebase} organisation_id={organisation_id}/>}
            <BulkPickingListContext.Provider value={{ picking_lists: {all: picking_lists, sorted, reverse, data, exportData,  filter, sort, setFilter, setSort,  headers: headers}}}>
                <BulkPickingTable setEditItem={setEditItem} customers={customers} createBulkList={createBulkList} cancel={cancel} organisation_id={organisation_id} setFilter={setFilter} firebase={firebase}/>
            </BulkPickingListContext.Provider>
            </div>
        </div>}
</ContainerMasterDataContext.Consumer>
    </React.Fragment>;
};

export default Bulk;