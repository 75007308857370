import app from 'firebase/app';

import {DATABASE_REFS as database_refs} from '../../constants/db_refs';

import firebaseConfigDev from "../../config/firebase_config_dev";
import firebaseConfig from "../../config/firebase_config";
import '@firebase/messaging';
import {toast} from "react-toastify";
import {toastConfig} from "../../config/toast_config";

require('firebase/auth');
require('firebase/database');
require('firebase/storage');
require('firebase/functions');

class Firebase {
    constructor() {
        app.initializeApp(process.env.NODE_ENV === 'production' ? firebaseConfig : firebaseConfigDev);
        this.auth = app.auth();
        this.db = app.database();
        this.storage = app.storage();
        this.functions = app.functions();
        if(app.messaging.isSupported()) {
            this.messaging = app.messaging();
            this.messaging.usePublicVapidKey(process.env.NODE_ENV === 'production'? 'BJJLsFPNi-vkMOVVW_kkADb_rMB_oluCA4sTu11NmkkO-bEutS5sUGTSzk6VREPsR7D9lnPsW3mD6tQn0WGQmCs' : "BH29lMK8vBkRBjQCJd5SChAVZq7r1IFzubASkfyUZQpzKTw3xXw4EhYg6SMvI_KcE_8c7NCfB_rOKvFJ79fyxYk");
            const messaging = this.messaging;
            const that = this;
            Notification.requestPermission()
                .then(async function () {
                    that.token = await messaging.getToken();
                })
                .catch(function (err) {
                    //console.error(err)
                });
            navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
            this.messaging.onMessage((message) => {
                const {notification, data} = message;
                if (notification)
                    toast.info(notification.title, {...toastConfig});
                else
                    toast.info(data.title, {...toastConfig})
            })
        }
    }
    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (name, email, password) =>
        new Promise((resolve, reject) => {
            this.auth.createUserWithEmailAndPassword(email, password).then((authUser) => {
                const profile = {
                    id: authUser.user.uid,
                    email: authUser.user.email,
                    displayName: name
                };

                const ref = database_refs.user.profile(authUser.user.uid);
                this.db.ref(ref).set(profile).then(()=>{
                    resolve(profile);
                }).catch((error) => {
                    reject(error)
                });

            }).catch((error) => {
                reject(error)
            });
        });

    doSignInWithEmailAndPassword = (email, password) =>
        new Promise((resolve, reject) => {
            this.auth.signInWithEmailAndPassword(email, password)
                .then((authUser) => {
                    resolve(authUser.user);
                })
                .catch(error => reject(error));
        });
    doSignOut = () => {return this.auth.signOut()};
    // ORGANISATIONS
    doCreateOrganisation = (uid, name, description) => {
        return new Promise((resolve) => {
           const org = {
               details: {
                   name: name,
                   description: description
               }
           };
           resolve( this.db.ref(database_refs.organisations.all).push(org).catch(err=>{
               console.error(err)
           }));


        });
    };

    // PRODUCTS
    doCreateProducts(organisation_id, customer, products) {
        return products.map((product) => {
            const key = this.db.ref(database_refs.organisations.products(organisation_id)).push().key;
            return this.doCreateProduct(organisation_id, customer, key, product)
        })
    }
    doCreateProduct(organisation_id, customer, key, product) {
        return this.db.ref(database_refs.organisations.products(organisation_id)).child(key).set({...product, customer: customer});
    }


}
export default Firebase;