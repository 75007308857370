import React from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Button from "../../elements/Button";
import Input from "../../elements/Input";
import * as PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {toastConfig} from "../../config/toast_config";

import * as ROUTES from '../../constants/routes';
import {withFirebase} from "../../components/Firebase";
import {withRouter} from "react-router-dom";
import Error from "../../elements/Error";

const INITIAL_STATE = () => ({
    name: "",
    email: "",
    password: "",
    repeat_password: ""

});
class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE();
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange(event) {
        let newState = {};
        newState[event.target.name] = event.target.value;
        this.setState(newState)
    }
    onSubmit(event) {
        const that = this;
        event.preventDefault();
        const { name, email, password } = this.state;
        return this.props.firebase
            .doCreateUserWithEmailAndPassword(name, email, password).then(() => {

                    toast.success("Created account!", {...toastConfig, autoClose: 2000});
                    that.props.history.push(ROUTES.HOME);
                }).catch(error => {
                    console.error(error);
                if(that.errors)
                        that.errors.setError(error);

            });

    }
    render() {
        const {name, email, password, repeat_password, error} =  this.state;
        const isInvalid =
            password !== repeat_password ||
            password === '' ||
            email === '' || name === '' ;
        return (
            <div data-testid={'signup'} className={'row row-eq-height'}>
                <div className={'offset-lg-2 col-12 col-lg-8 bg-primary text-light p-5'}>
                    <h3 className={'text-light'}>
                        <FormattedMessage
                            id={"app.register_heading"}
                            defaultMessage={"Create an account for Container Master"}
                        />
                    </h3>
                    <Error ref={ref =>this.errors=ref} error={error}/>
                    <Input
                        value={this.state.name}
                        label_id={"app.name_label"}
                        name={"name"}
                        handleChange={this.handleChange}
                        required={true}
                    />
                    <Input
                        value={this.state.email}
                        type={"email"}
                        label_id={"app.email_label"}
                        name={"email"}
                        handleChange={this.handleChange}
                        required={true}
                    />
                    <Input
                        value={this.state.password}
                        label_id={"app.password"}
                        type={"password"}
                        name={"password"}
                        handleChange={this.handleChange}
                        required={true}
                    />
                    <Input
                        value={this.state.repeat_password}
                        label_id={"app.repeat_password"}
                        type={"password"}
                        name={"repeat_password"}
                        handleChange={this.handleChange}
                        mustMatch={this.state.password}
                        required={true}
                    />
                   <Button disabled={isInvalid} onClick={this.onSubmit} testid="register.submit" className={"btn-tertiary"} title_id={"app.register"}/>
                </div>


            </div>
        );
    }
}

Register.propTypes = {
    firebase: PropTypes.object
};

export {Register};

export default withRouter(injectIntl(withFirebase(Register)));