const user_refs = {
    organisations:  (id) => `users/${id}/organisations`,
    customers:  (id) => `users/${id}/organisation_customers`,
    invites:  (id) => `users/${id}/invites`,
    profile: (id) => `users/${id}/details`,
    settings: (id) => `users/${id}/settings`
}
const organisation_refs = {
    all: `organisations`,
    details: (id) => `organisations/${id}/details`,
    members: (id) => `organisations/${id}/members`,
    customers: (id) => `organisations/${id}/customers`,
    bays: (id) => `organisations/${id}/bays`,
    products: (id) => `organisations/${id}/products`,
    gate_passes: (id) => `organisations/${id}/gate_passes`,
    devan_gate_passes: (id) => `organisations/${id}/devan_gate_passes`,
    product_events: (id, pid) => `organisations/${id}/data/products/${pid}/events`,
    bay_history: (id, pid) => `organisations/${id}/data/bays/${pid}`,
    request: {
        collection: (id) => `organisations/${id}/requests/collection`,
        devan: (id) => `organisations/${id}/requests/devan`
    },
    tracking: {
        all: (id) => `organisations/${id}/tracking/`,
        totals: (id) => `organisations/${id}/tracking/totals`,
        by_status: (id, status) => `organisations/${id}/tracking/${status}`
    },
    forecasting: {
        record: (id, entity) => `organisations/${id}/forecasting/${entity}`,
        by_entity: (id, entity, entity_id) => `organisations/${id}/forecasting/${entity}/${entity_id}`,
        pending: (id, entity, entity_id) => `organisations/${id}/forecasting/${entity}/${entity_id}/pending`,
        completed: (id, entity, entity_id) => `organisations/${id}/forecasting/${entity}/${entity_id}/completed`,
        totals: (id, entity, entity_id) => `organisations/${id}/forecasting/${entity}/${entity_id}/totals`
    },
    summaries: {
        collection: (id) => `organisations/${id}/summaries/collection`,
        outturn: (id) => `organisations/${id}/summaries/outturn`
    },
    stock_movement: {
        stock_in:  (id) => `organisations/${id}/movements/outturn`,
        stock_out:  (id) => `organisations/${id}/movements/collection`
    },
    bulk: {
        packing:  (id) => `organisations/${id}/bulk/packing`,
        picking:  (id) => `organisations/${id}/bulk/picking`
    },
    archives: (id) => `organisations/${id}/archives`,
    archive_data: {
        tracking: {
            all: (id) => `archives/${id}/tracking/`,
            totals: (id) => `archives/${id}/tracking/totals`,
            by_status: (id, status) => `archives/${id}/tracking/${status}`
        },
    }
}
const DATABASE_REFS = {
    organisations: organisation_refs,
    user: user_refs
}

export {DATABASE_REFS};

export default DATABASE_REFS;