import React from "react";
import * as PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, FormControl, FormGroup, FormLabel, ModalBody} from "react-bootstrap";

import {DateSortableContent, RowCell, TableButtons} from "../../../elements/Common";
import ModalHeader from "react-bootstrap/ModalHeader";
import Modal from "react-bootstrap/Modal";
import ModalFooter from "react-bootstrap/ModalFooter";

import db_refs from '../../../constants/db_refs'
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import EventTable, {labelMappings, ProductBayDefaultsTable, StockLevelGraph} from "../../../elements/EventTable";
import {AdvancedCustomerFilter, AdvancedFilterPanel} from "../../../elements/AdvancedFiltering";
import {VictoryLegend, VictoryPie, VictoryTooltip} from "victory";

const COLORSCALE = ["#2B2D82",
    "#8E2F6E",
    "#BF4240",
    "#66CCC0",
    "#2E143D",
    "#30915A",
    "#CC6E66",
    "#264173",
    "#D581BE",
    "#343D14",
    "#C7B057",
    "#8FCC66",
    "#CCDB94",
    "#0B0613",
    "#E9D6BE"
]

class EditProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: props.editing
        };
        this.editField = this.editField.bind(this);
        this.editItem = this.editItem.bind(this);
    }

    editField(event) {
        const {editing} = this.state;
        editing[event.target.name] = event.target.value;
        this.setState({editing: editing});
    }

    editItem() {
        this.props.editItem(this.state.editing)
    }

    render() {
        const {setEditItem, editItem} = this.props;
        const {editing} = this.state;
        return <Modal size={'lg'} show={true}>
            <ModalHeader className={'bg-primary'}>
                <h3 className={'text-light'}>{`Editing ${this.props.customers[editing.customer].name} products ${editing.code}`}</h3>
            </ModalHeader>
            <ModalBody className={'bg-light'}>
                <FormGroup>
                    <FormLabel column={true}>Product Code</FormLabel>
                    <FormControl onChange={this.editField} value={editing.code} name={'code'}/>
                </FormGroup>
                <FormGroup>
                    <FormLabel column={true}>Product Description</FormLabel>
                    <FormControl onChange={this.editField} value={editing.description} name={'description'}/>
                </FormGroup>
            </ModalBody>
            <ModalFooter className={'bg-light'}>
                <Button onClick={() => setEditItem(null)} variant={'secondary'}>Cancel</Button>
                <Button variant={'success'} onClick={editItem}>Save</Button>
            </ModalFooter>
        </Modal>
    }
}


class ProductDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: {},
            forecasting: {},
            pending: {},
            startDate: props.startDate,
            endDate: props.endDate,
            loading: true
        }
    }

    componentDidMount() {
        this.setUpDataListener();
    }

    setUpDataListener() {
        this.setState({loading: true});
        const {pid, organisation_id, firebase} = this.props;
        firebase.db.ref(db_refs.organisations.forecasting.totals(organisation_id, 'products', pid)).on('value',
            r => {
                const result = r.val();
                if(!result)
                    this.setState({forecasting: {}});
                else {
                    const end_result = {
                        current: (result.current||0),
                        available:  (result.current||0) - ((result.promised||0)+(result.stocking_out||0)),
                        stocking_out:  (result.stocking_out||0),
                        stocking_in:  (result.stocking_in||0),
                        pending:  (result.pending||0),
                        promised:  (result.promised||0),
                        actual_stock: ((result.stocking_in||0)+(result.current||0)) - (result.stocking_out||0)
                    };
                    this.setState({forecasting: end_result})
                }
            }
        );

        firebase.db.ref(db_refs.organisations.forecasting.completed(organisation_id, 'products', pid))
            .orderByChild('eventDate')
            .limitToLast(10)
            .on('value', data => {
                this.setState({events: Object.values(data.val() || {}).reverse(), loading: false})
            });
        firebase.db.ref(db_refs.organisations.forecasting.pending(organisation_id, 'products', pid))
            .on('value', data => {
                this.setState({pending: Object.values(data.val() || {}).reverse(), loading: false})
            })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
            this.setUpDataListener();
        }
    }

    render() {
        const {pid, headers, customer, code, bays, product, all_products, firebase, organisation_id} = this.props;
        const events = Object.values(this.state.events || {});
        const forecast = Object.values(this.state.pending || {});
        const loading = this.state.loading;

        if (!events) {
            return <LoadingScreen/>;
        }
        if (loading) return <tr><td><FontAwesomeIcon icon={'spinner'} spin={true}/></td></tr>;
        const details = this.state.details || this.props.details;
        const graphData = Object.keys(this.state.forecasting).sort().map(k=>({x: labelMappings[k], label: labelMappings[k], y: this.state.forecasting[k]}));

        const data = [

        ];
        const legendData = [

        ];

        Object.values(bays).filter((b)=>b.product===code).sort((a,b)=>a.last_active-b.last_active).map(b=>{
            data.push({x: b.current, y: b.current, label: b.code})
            legendData.push({name: `${b.code} (${b.current})`})
        })
        return [<tr className={'no-print'} key={`table_row_${customer}_${pid}_toggle`}>
            <td colSpan={headers.length} style={{position: 'relative'}}>
                <div onClick={() => this.setState({details: !this.state.details})}
                     style={{position: 'absolute', right: '-15px', top: '-40px'}}>
                    {details && !this.props.details && <FontAwesomeIcon size={'2x'} icon={'caret-down'}/>}
                    {this.props.details && <FontAwesomeIcon title={'Details are locked open'} icon={'ban'}/>}
                    {!details && <FontAwesomeIcon size={'2x'} icon={'caret-up'}/>}
                </div>
            </td>
        </tr>,
            <tr key={`table_row_${customer}_${pid}_details`}>
                {details && <td colSpan={headers.length}>
                    <div className={'col-12 details row'}>
                        <div title={pid} className={'col-6 small'}>
                            <ProductBayDefaultsTable bays={bays} product={product} data={{}} title={"Bay Management"}
                                                     all_products={all_products} firebase={firebase}
                                                     organisation_id={organisation_id}
                            />

                        </div>
                        <div className={'col-6'}>
                            <h3>Bay Distribution</h3>
                            <svg width={600} height={400+(data.length*2)}
                                 style={{ border: "1px solid #ccc" }}
                            >
                                <VictoryPie standalone={false}
                                            width={500} height={400}
                                            padding={{
                                                left: 0, bottom: 50, top: 100
                                            }}
                                            colorScale={COLORSCALE}
                                            data={data}
                                            labels={()=>null}
                                            animate={{
                                                animationWhitelist: ["all"],
                                                onExit: {
                                                    duration: 500,
                                                    before: () => ({ active: false })
                                                },
                                                onEnter: {
                                                    duration: 500,
                                                    before: () => ({ opacity: 0.3, _y: 0 }),
                                                    after: () => ({ active: true })
                                                }
                                            }}
                                            labelComponent={<VictoryTooltip/>}
                                />
                                <VictoryLegend standalone={false}
                                               colorScale={COLORSCALE}
                                               x={490} y={10}
                                               gutter={20}
                                               title="Legend"
                                               centerTitle
                                               style={{ border: { stroke: "black" } }}
                                               data={legendData}
                                />
                            </svg>
                        </div>
                        <div title={pid} className={'col-6 small'}>
                            <EventTable none_found_message={'No product history'} title={'Product History'} data={events} showUser={true} target_id={pid}/>
                            <EventTable title={'Product Forecast'} none_found_message={'No planned product activity'} data={forecast} showUser={false} target_id={pid}/>


                        </div>
                        <div className={'col-6'}>
                            { graphData.length > 0 &&  <StockLevelGraph graphData={graphData}/> }

                        </div>



                    </div>
                </td>}
            </tr>]
    }

}

class ProductTable extends DateSortableContent {
    has_export = 'Export Product Stock';

    constructor(props) {
        super(props);

        this.state = {
            ...this.initialState,
            key: 'products',
            sort: 'code',
            sorted_data: [],
            print_processing: false,
            details: false,
            index: 0,
            page: 0,
            num_per_page: 15,
            deleting: null
        };
    }

    getAdvancedFilters() {
        const {customer_filter} = this.props;
        return <AdvancedFilterPanel>
            <AdvancedCustomerFilter selectAll={customer_filter.selectAll} deselectAll={customer_filter.deselectAll} onClick={customer_filter.updateCustomerFilter} original_list={customer_filter.filter_options} current_list={customer_filter.filter}/>
        </AdvancedFilterPanel>
    }

    getHeaders() {
        return [{
            label: 'Product Code',
            key: 'code',
            size: 1,
            content: (product) => {
                return product.code
            }
        }, {
            label: 'Description',
            key: 'description',
            size: 3,
            content: (product) => {
                return product.description
            }
        },{
            label: 'Debug',
            key: 'debug',
            size: 2,
            noprint: true,
            content: (product) => {
                return<Button onClick={
                    ()=>{
                        const {firebase, organisation_id} = this.props;
                        firebase.functions.httpsCallable('forecasting-checkProduct')({
                            organisation_id,
                            product_id: product.id
                        }).then(()=>{

                        })
                    }
                }>Check</Button>
            }
        }, {
            label: 'Customer',
            key: 'customer',
            size: 2,
            content: (product) => {
                return this.props.customers[product.customer].name
            }
        }, {
            label: 'Last Ref',
            key: 'last_reference',
            size: 2,
            content: (product) => {
                if (!product.last_reference) {
                    product.last_reference = '-'
                }
                return product.last_reference ? product.last_reference : '-'
            }
        }, {
            label: 'In stock',
            key: 'current',
            size: 2,
            content: (product) => {
                const stock = product.current ? product.current : 0;
                return <div className={'w-100 pr-2 text-center'}>
                    <span className={'mr-3'}>{stock}</span>
                    <div style={{pointerEvents: 'none'}} className="float-right mr-2 fa-layers fa-fw small">
                        {product.last_change > 0 ?
                            <FontAwesomeIcon style={{float: 'right', verticalAlign: 'middle'}} size={'3x'}
                                             icon={'caret-up'}
                                             className={'text-success'}/> : product.last_change < 0 ?
                                <FontAwesomeIcon style={{float: 'right', verticalAlign: 'middle'}} size={'3x'}
                                                 icon={'caret-down'} className={'text-danger'}/> :
                                <FontAwesomeIcon style={{float: 'right', verticalAlign: 'middle'}} size={'3x'}
                                                 icon={'minus'} className={'text-warning'}/>}
                    </div>


                </div>
            }
        }, {
            label: '',
            key: false,
            size: 1,
            noprint: true,
            content: (product) => {
                return <TableButtons product={product} setDeleteItem={this.setDeleteItem}
                                     setEditItem={this.setEditItem} allow_delete={product.last_reference === "-"}/>
            }
        }]
    }

    getData() {
        this.setState({
            data: this.state.sorted_data.map(product => {
                return {
                    code: product.code,
                    description: product.description,
                    customer: this.props.customers[product.customer].name,
                    stock: (product.current+product.stocking_in) - product.stocking_out,
                    last_change: product.change
                }
            })
        });
    }

    getDeletionModal() {
        if (this.state.deleting) {
            return <Modal onHide={() => this.setState({deleting: null})} size={'lg'} show={true}>
                <ModalHeader className={'bg-primary'}>
                    <h3 className={'text-light'}>{`Deleting ${this.props.customers[this.state.deleting.customer].name} products ${this.state.deleting.code}`}</h3>
                </ModalHeader>
                <ModalFooter className={'bg-light'}>
                    <Button onClick={() => this.setDeleteItem(null)} variant={'secondary'}>Cancel</Button>
                    <Button variant={'danger'} onClick={this.deleteItem}>Delete</Button>
                </ModalFooter>
            </Modal>
        }
    }

    getEditingModal() {
        if (this.state.editing) {
            return <EditProductForm customers={this.props.customers} editing={this.state.editing}
                                    editItem={this.editItem} setEditItem={this.setEditItem}/>
        }
    }

    getReportTitle() {
        return 'Product Stock Report'
    }

    deleteItem() {
        this.props.firebase.db.ref(db_refs.organisations.products(this.props.organisation_id)).child(this.state.deleting.id).set(null).then(() => this.setState({deleting: null}));
    }

    editItem() {
        this.props.firebase.db.ref(db_refs.organisations.products(this.props.organisation_id)).child(this.state.editing.id).update(this.state.editing).then(() => this.setState({editing: null}));
    }

    getContent() {
        const {bays, firebase, organisation_id, all_products} = this.props;
        const {startDate, endDate, sorted_data} = this.state;
        const headers = this.getHeaders();
        const start = this.state.print_processing ? 0 : this.state.page * this.state.num_per_page;
        const end = this.state.print_processing ? sorted_data.length : ((this.state.page) * this.state.num_per_page) + this.state.num_per_page;
        const paginated_data = sorted_data.slice(start, end);
        return paginated_data.map((product) => {
            const result = [
                <tr key={`table_row_${product.parent}_${product.id}`}
                    className={'col-12 w-100 m-1 product item-row mb-1 print-block'}
                    style={{boxShadow: '-1px 1px 1px lightgray'}}>
                    {headers.map((h, i) => <RowCell key={`table_row_${product.parent}_${product.id}_${h.key}`} col={3}
                                                    title={h.label} alignment={'center'}
                                                    header={!this.props.printing && i === 0}
                                                    noprint={h.noprint}>{h.content(product)}</RowCell>)}

                </tr>];
            if (!this.state.importing) {
                result.push(
                    <ProductDetail key={`product_${product.id}_details`} headers={headers} code={product.code}
                                   details={this.state.details} startDate={startDate} endDate={endDate}
                                   product={product}
                                   firebase={firebase} organisation_id={organisation_id}
                                   customer_id={product.parent} pid={product.id} bays={bays} all_products={all_products}/>
                );

            }
            return result;
        });
    }
}

ProductTable.propTypes = {
    products: PropTypes.array.isRequired,
    bays: PropTypes.object.isRequired,
    organisation_id: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired
};

export {ProductDetail};
export default ProductTable;