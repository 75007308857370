import React, {useEffect, useState} from "react";
import {DateSortableContent, RowCell} from "../../elements/Common";
import {DATABASE_REFS} from '../../constants/db_refs'
import {Button, ButtonGroup, FormControl, Modal, ModalBody, ModalFooter} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import ArchiveReportingTable from "./reporting"
import Col from "react-bootstrap/Col";



const ArchiveList = (props) => {
  const {firebase} = props
  const [archives, setArchives] = useState({})
  const [currentArchive, setCurrentArchive] = useState(null)
  const [archiving, setArchiving] = useState(false)
  const [confirmationText, setConfirmationText] = useState("")
  const [archiving2, setArchiving2] = useState(null)
  const [confirmationText2, setConfirmationText2] = useState("")
  const [archiveName, setArchiveName] = useState("")
  const [filter, setFilter] = useState("")


  useEffect(()=>{
    setConfirmationText("")
  }, [archiving])

  useEffect(()=>{
    props.firebase.db.ref(DATABASE_REFS.organisations.archives(props.organisation_id)).on("value", (snap)=>{
      if (snap.val()) {
        const archives = snap.val()
        Object.keys(archives).map(k=>{
          archives[k].id = k
        })
        setArchives(archives)
      } else {
        setArchives({})
      }
    })
    return () => props.firebase.db.ref(DATABASE_REFS.organisations.archives(props.organisation_id)).off()
  }, [archiving2, archiving])

  const archive = async () => {
    const archiveFunc = firebase.functions.httpsCallable('archiveData3');
    await archiveFunc({organisation_id: props.organisation_id, archiveName})
    setArchiving(false)

  }
  const startRestoring = async (archive_id) => {
    setArchiving2(archive_id)
  }
  const restore = async () => {
    const archive_id = archiving2
    const archiveFunc = firebase.functions.httpsCallable('restoreArchive');
    await archiveFunc({organisation_id: props.organisation_id, archive_id})
    setArchiving2(null)

  }

  return <div className={'col-xs-12 col-sm-11 mr-auto ml-auto bg-primary pt-2 pb-2 mt-1'}>
    {archiving && <Modal show={archiving} size={'lg'} onHide={()=>setArchiving(false)}>
      <ModalHeader className={'bg-primary text-light'}><h3 className={'bg-primary text-light'}>
        Archive all current data
      </h3></ModalHeader>

     <ModalBody>

        <p className={'text-danger font-weight-bold'}>
          Archiving data will remove all the current data and archive it!
        </p>
       Name of archive
       <FormControl value={archiveName} onChange={(event) => {
         setArchiveName(event.target.value ? event.target.value : "")
       }}/>
       <p>Type "I want to archive" exactly as written to allow archiving</p>

        <FormControl value={confirmationText} onChange={(event) => {
          setConfirmationText(event.target.value ? event.target.value : "")
        }}/>
      </ModalBody>
      <ModalFooter>
        <Button onClick={archive} disabled={confirmationText!=="I want to archive" || archiveName.length === 0} variant={"danger"}>Archive</Button>
        <Button variant={'secondary'} onClick={()=>setArchiving(false)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>}
    {archiving2 && <Modal show={archiving2} size={'lg'} onHide={()=>setArchiving2(null)}>
      <ModalHeader className={'bg-primary text-light'}><h3 className={'bg-primary text-light'}>
        Restore this archive
      </h3></ModalHeader>

      <ModalBody>

        <p className={'text-danger font-weight-bold'}>
          Restoring data is permanent and cannot be undone! It is recommended you create an archive to back up your data before restoring.
        </p>
        <p>Type "I want to restore" exactly as written to allow restoration</p>

        <FormControl value={confirmationText2} onChange={(event) => {
          setConfirmationText2(event.target.value ? event.target.value : "")
        }}/>
      </ModalBody>
      <ModalFooter>
        <Button onClick={restore} disabled={confirmationText2!=="I want to restore"} variant={"danger"}>Restore</Button>
        <Button variant={'secondary'} onClick={()=>setArchiving2(null)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>}
    { !currentArchive && <React.Fragment>
 <div className={'text-right p-2'}>{!props.customer && props.role === "owner" && <Button variant={'danger'} onClick={()=>setArchiving(true)}>Archive</Button>}</div>
    <ArchiveTable role={props.role} customer={props.customer} filter={filter || ""} setFilter={(event)=>setFilter(event.target.value)} firebase={firebase} archives={archives} setCurrent={setCurrentArchive} restoreArchive={startRestoring}/>
    </React.Fragment> }
    { currentArchive && <div className={"p-2 m-1 row"}>
      <Col xs={2}><Button variant={"info"} onClick={()=>setCurrentArchive(null)}>Back</Button></Col>
      <Col><h3 className={"w-50 text-light"}>{currentArchive.name}</h3></Col>
      <ArchiveReportingTable archive_id={currentArchive.id} archive_date={currentArchive.date}{...props}/>
    </div> }
  </div>
}

class ArchiveTable extends DateSortableContent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      sort: 'date',
      key: 'archives',
      sorted_data: [],
      print_processing: false,
      details: false,
      index: 0,
      page: 0,
      num_per_page: 12,
      hide_date: true,
      noDetails: true
    };
  }
   setEditItem(archive) {
     this.props.setCurrent(archive);
   }

  getReportTitle() {
    return "Archives"
  }

  restoreArchive(archive_id) {
    this.props.restoreArchive(archive_id)
  }
  getHeaders() {
    return [{
      label: 'Name',
      key: 'name',
      size: 3,
      content: (product) => {
        return product.name
      }
    }, {
      label: 'Date',
      key: 'date',
      size: 2,
      content: (product) => {
        return new Date(product.date).toLocaleString()
      }
    },{
      label: '',
      key: false,
      size: 2,
      noprint: true,
      content: (product) => {
        return  <ButtonGroup>
          <Button size={"sm"} onClick={()=>this.setEditItem(product)}>View Archive</Button>
          {!this.props.customer && this.props.role === "owner" && <Button variant={"success"} size={"sm"} onClick={()=>this.restoreArchive(product.id)}>Restore Archive</Button>}
        </ButtonGroup>
      }
    }]
  }
  getContent() {
    return this.state.sorted_data.filter(arch=>arch.name.toLowerCase().indexOf(this.props.filter.toLowerCase()) > -1).map(
        (customer) => <ArchiveRow
            organisation_id={this.props.organisation_id}
            firebase={this.props.firebase}
            printing={this.state.print_processing}
            headers={this.getHeaders()}
            key={customer.id}
            archive={customer}
        />
    );
  }
}
class ArchiveRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const {archive, headers} = this.props;

    return [
      <tr key={`table_row_${archive.id}`} className={'col-12 w-100 m-1 product item-row mb-1 print-block'}
          style={{boxShadow: '-1px 1px 1px lightgray'}}>
        {headers.map((h, i) =>  <RowCell  key={`table_row_${archive.id}_${h.key}`}  col={3} title={h.key} alignment={'center'}
                                          header={!this.props.printing && i===0} noprint={h.noprint}>{h.content(archive)}</RowCell>)}
      </tr>]
  }
}

export {ArchiveList}