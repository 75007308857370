import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, ButtonGroup, CloseButton, Col, Modal, ModalBody, Nav, Row, Tab, Table} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";

import * as PropTypes from "prop-types";

import DATABASE_REFS from '../../constants/db_refs'
import OrganisationForm from "../OrganisationForm/OrganisationForm";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {toastConfig} from "../../config/toast_config";
import {toast} from "react-toastify";


class OrganisationManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organisations: [],
            loading: true
        };
    }

    fetchOrganisations() {
        const {firebase, organisation_id} = this.props;
        const {uid, default_organisation} = this.props;
        const current = organisation_id ? organisation_id : default_organisation;
        if(!uid) return;
        this.props.firebase.db.ref(DATABASE_REFS.user.organisations(uid)).on('value', (organisation_list_snapshot) => {
            const user_organisations =  organisation_list_snapshot.val() || {};
            this.setState({user_organisations:user_organisations});
            const promises =  (Object.keys(user_organisations)).map(oid => firebase.db.ref(DATABASE_REFS.organisations.details(oid)).once('value'));
            Promise.all(promises).then((results) => {
                Object.keys(user_organisations).map(oid => firebase.db.ref(DATABASE_REFS.organisations.details(oid)).on("value", (organisation)=> {
                    if (!organisation.val()) {
                        return
                    }
                    const organisations = this.state.organisations;
                    const new_organisations = organisations.map((o)=>{
                        if (o.id === organisation.ref.parent.key) {
                            return {...organisation.val(), default:oid === default_organisation, id:organisation.ref.parent.key}
                        }
                        return {...o}
                    });
                    this.setState({"organisations":new_organisations})
                }));
                let current_organisation = null;
                const organisations = results.map(r=> {
                    const organisation =  r.val();
                    const oid = r.ref.parent.key;
                    organisation.role = user_organisations[oid];
                    organisation.selected = oid === organisation_id;
                    organisation.default = oid === default_organisation;
                    organisation.id = oid;
                    if(oid === current) {
                        current_organisation = organisation
                    }
                    return organisation

                });
                this.setState({organisations: organisations, organisation: current_organisation, loading: false})

            })
        })
    }
    setDefault(oid) {
        const {firebase, uid} = this.props;
        firebase.db.ref(DATABASE_REFS.user.settings(uid)).child('default_organisation').set(oid).then(()=>{
            toast.success('Updated default', {...toastConfig});
            this.setState({viewing: false})
        })
    }

    componentDidMount() {
       this.fetchOrganisations();
    }

    componentDidUpdate(prevProps) {
        if(this.props.uid !== prevProps.uid || this.props.organisation_id !== prevProps.organisation_id) {
            if(prevProps.uid) {
                this.props.firebase.db.ref(DATABASE_REFS.user.organisations(prevProps.uid)).off()
            }
            this.fetchOrganisations();
        }
    }

    componentWillUnmount() {
        const {firebase, uid} = this.props;
        firebase.db.ref(DATABASE_REFS.user.organisations(uid)).off();
        if (this.state.user_organisations) {
            Object.keys(this.state.user_organisations).map((oid)=> {
                return firebase.db.ref(DATABASE_REFS.organisations.details(oid)).off("value");
            })
        }
    }

    getContent() {

    }

    render() {
        const {organisation_id, default_organisation, firebase, uid, organisation} = this.props;
        const {organisations, viewing, loading} = this.state;
        return <div>
            <h1 id={'org_manager'} onClick={()=>this.setState({viewing: true})}
                title={'Manage Organisations'}
                className={'interactive p-3 text-light text-right float-right'}>

                {organisation ? organisation.name : 'Not Set'}
                <FontAwesomeIcon className={'show-on-hover small ml-5'} icon={'cog'}/>
            </h1>

            <Modal onHide={()=>this.setState({viewing: false})} size={'lg'} show={viewing }>
                <ModalHeader className={'text-light bg-primary'}>
                    <h3 className={'text-light'}>Organisations</h3>
                    <CloseButton className={'text-light'} onClick={()=>this.setState({viewing: false})}/>
                </ModalHeader>


                <ModalBody style={{minHeight: '400px'}} className={'bg-light'} >
                    { loading && <LoadingScreen/> }
                    {!loading && <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.editing ? "third" : organisations.length > 0 ? "first" : "second"} activeKey={this.state.key} onSelect={(eventKey => this.setState({key:eventKey}))}>
                        <Row>
                            <Col sm={2}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">List</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Create</Nav.Link>
                                    </Nav.Item>
                                    { this.state.editing &&
                                   <Nav.Item>
                                        <Nav.Link eventKey="third">Edit</Nav.Link>
                                    </Nav.Item> }
                                </Nav>
                            </Col>
                            <Col sm={10}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first" className={'bg-light'}>
                                        <Table size={'sm'} variant={'bordered'}>
                                            <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Role</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {organisations.map(o => {
                                                return <tr key={`${o.id}_org_row`} className={organisation_id === o.id ? 'bg-tertiary text-white' : ''}>
                                                    <td>{o.logo && <img alt={"Logo"} className={"img img-thumbnail"} src={o.logo} style={{maxHeight: "50px"}}/> }</td>
                                                    <td>{o.name}</td>
                                                    <td>{o.description}</td>
                                                    <td>{o.role}</td>
                                                    <td>
                                                        <ButtonGroup>
                                                            {!(organisation_id === o.id) && <Button size={'sm'} variant={'primary'} onClick={()=>{
                                                                this.setState({viewing:false});
                                                                this.props.changeOrganisation( o.id);
                                                            }}>Switch</Button>}
                                                            {!(default_organisation === o.id) && <Button size={'sm'} variant={'success'} onClick={()=>this.setDefault(o.id)}>Set Default</Button>}
                                                            {o.role === "admin"  &&<Button size={'sm'} variant={"info"} onClick={()=>this.setState({editing:o, key:"third"})}>Edit</Button>}
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>})
                                            }
                                            </tbody>
                                        </Table>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        {organisations.length === 0 && <h5>You don't have access to any organisations</h5>}
                                        <OrganisationForm uid={uid} fullWidth={true} header={'app.create'} firebase={firebase}/>
                                    </Tab.Pane>
                                    { this.state.editing && <Tab.Pane eventKey="third">
                                        {organisations.length === 0 && <h5>You don't have access to any organisations</h5>}
                                        <OrganisationForm cancel={()=>this.setState({editing:null, key:"first"})} organisation={this.state.editing} uid={uid} fullWidth={true} header={'app.edit'} firebase={firebase}/>
                                    </Tab.Pane> }
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>}

                </ModalBody>
            </Modal>
        </div>
    }
}


OrganisationManager.propTypes = {
    organisation_id: PropTypes.string,
    firebase: PropTypes.object
};

export default OrganisationManager;