import React from 'react';
import {withFirebase} from '../Firebase';
import {DATABASE_REFS} from '../../constants/db_refs'

import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {ContainerMasterCollectionsContext} from "./ContainerMaster";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import _ from "lodash";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// Collections
export const STATUS_LABELS = [
    'Checking',
    'Not Started',
    'In Progress',
    'Ready To Stock Out',
    'Completed',
    'Bay Error'
];

// Requests
export const REQUEST_STATUS_LABELS = [
    'Pending Review',
    'Awaiting collection',
    'Collection in progress',
    'Verification Underway',
    'Stocked Out',
    'Cancelled',
];

// Collections
const CollectionHeaders = [
    {
        label: 'Collection Ref.',
        key: 'collection_ref',
        size: 1,
        filter: true,
        content: (product) => {
            return <Row>
                <Col xs={2} className={"pr-0"}>
                   <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={"envelope"}/>
                       {product.notified && <FontAwesomeIcon icon={"check"} className={"text-success"} size={"sm"}/>}
                       {!product.notified && <FontAwesomeIcon icon={"times"} className={"text-danger"} size={"sm"}/>}
                    </span>
                </Col>
                <Col xs={10}>
                    {product.collection_ref}
                </Col>
            </Row>
        }
    }, {
        label: 'Due',
        key: 'expected',
        sortKey: 'expected',
        size: 1,
        filter: true,
        content: (product) => {
            if (!product.expected || product.expected === '-') {
                return '-'
            }
            return new Date(product.expected).toLocaleDateString()
        }
    }, {
        label: 'Collected',
        key: 'collected_on',
        size: 1,
        content: (product) => {
            if (!product.collected_on || product.collected_on === '-') {
                return '-'
            }
            return new Date(product.collected_on).toLocaleDateString()
        }
    }, {
        label: 'Pass No.',
        key: 'pass_number',
        size: 1,
        filter: true,
        content: (product) => {
            return product.pass_number
        }
    }, {
        label: 'Picking List No',
        key: 'picking_list_number',
        size: 1,
        filter: true,
        content: (product) => {
            return product.picking_list_number
        }
    }, {
        label: 'Status',
        key: 'collection_status',
        size: 1,
        filter: true,
        content: (product) => {
            return product.collection_status
        }
    }, {
        label: 'Crates',
        key: 'crates_val, projected',
        size: 1,
        content: (outturn) => {
            return outturn.crates
        }
    }
];

// Requests
const RequestHeaders = [
    {
        label: 'Collection Ref.',
        key: 'collection_ref',
        size: 2,
        filter: true,
        content: (collection_request) => {

            return <div className={'row'}>
                <div className={'col-1'}>
                    {collection_request.error &&
                    <FontAwesomeIcon title={'Attempt to amend collection in progress!'} className={'text-danger'}
                                     icon={'exclamation-triangle'}/>}
                    {!collection_request.error && collection_request.updated &&
                    <FontAwesomeIcon title={'Updated since last save!'} className={'text-success'} icon={'arrow-up'}/>}
                    {!collection_request.error && collection_request.redirected_to &&
                    <FontAwesomeIcon title={'Duplicate collection reference! Editing will redirect to open request'}
                                     className={'text-info'} icon={'exclamation-triangle'}/>}
                    {collection_request.has_warnings &&
                    <FontAwesomeIcon title={'Parsing error! Please check carefully'} className={'text-warning'}
                                     icon={'exclamation-triangle'}/>}
                    {!collection_request.error && !collection_request.redirected_to && collection_request.invalid_products && collection_request.invalid_products.length > 0 &&
                    <span title={ `Unknown products: \n${collection_request.invalid_products.join('\n')}`} className="fa-layers fa-fw mr-1">
                            <FontAwesomeIcon className={'text-secondary'} size={'sm'} icon={'box'}/>
                            <FontAwesomeIcon className={'text-light'} icon={'question'}/>
                        </span>}
                    {collection_request.out_of_stock && collection_request.out_of_stock.length > 0 && <span title={ `Insufficient Stock: \n${collection_request.out_of_stock.join('\n')}`} className="fa-layers fa-fw mr-1">
                            <FontAwesomeIcon className={'text-secondary'} size={'sm'} icon={'box'}/>
                            <FontAwesomeIcon className={'text-danger'} icon={'ban'}/>
                        </span>}
                </div>
                <div className={'col-10'}>
                    {collection_request.collection_ref}
                </div>
            </div>

        }
    }, {
        label: 'Due',
        key: 'expected',
        size: 1,
        filter: true,
        content: (product) => {
            if (!product.expected || product.expected === '-') {
                return '-'
            }
            return new Date(product.expected).toLocaleDateString()
        }
    }, {
        label: 'Customer',
        key: 'customer_name',
        size: 3,
        filter: true,
        content: (product) => {
            return product.customer_name
        }
    }, {
        label: 'Status',
        key: 'request_status',
        size: 1,
        filter: true,
        content: (product) => {
            return product.request_status
        }
    }, {
        label: 'Crates',
        key: 'projected',
        size: 1,
        filter: true,
        content: (product) => {

            return product.projected
        }
    }
];

const withCollections = Component => {
    class WithCollections extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                start:  moment().subtract(4,'week').toDate().getTime(),
                end:  moment().add(1,'week').toDate().getTime(),
                loading_requests: true,
                loading_collections: true,
                requests: {
                    status_key: 'request_status',
                    all:{},
                    sorted: [],
                    data:[],
                    exportData: [],
                    filter: '',
                    status_filters: [
                        'Pending Review',
                        'Awaiting collection',
                        'Collection in progress',
                        'Verification Underway'
                    ],
                    selectAll: () => {
                        const {requests} = this.state;
                        requests.status_filters = REQUEST_STATUS_LABELS;
                        this.setState({requests: {...requests}}, () => this.filterRequests(requests, () => {
                        }))
                    },
                    selectNone: () => {
                        const {requests} = this.state;
                        requests.status_filters = [];
                        this.setState({requests: {...requests}}, () => this.filterRequests(requests, () => {
                        }))
                    },
                    updateStatusLabels: (sl) => {
                        const {requests} = this.state;
                        const {status_filters} = requests;
                        if (status_filters.indexOf(sl) > -1) {
                            requests.status_filters = status_filters.filter(s => s !== sl)
                        } else {
                            requests.status_filters.push(sl);
                        }
                        this.setState({requests: {...requests}}, () => this.filterRequests(requests, () => {
                        }))

                    },
                    headers: RequestHeaders,
                    sort: 'collection_ref',
                    setSort: (sort) => {
                        const requests = {...this.state.requests};
                        if (requests.sort !== sort) {
                            requests.sort = sort;
                        } else {
                            requests.reverse *= -1;
                        }
                        requests.sorted = this.getSortedData(Object.values(requests.all), requests.sort, requests.reverse);

                        this.setState({requests: requests}, () => {
                            this.filterRequests(requests);
                        })

                    },
                    reverse: 1,
                    setDate: (start, end, callback) => this.setState(
                        {
                            start: start,
                            end: end
                        }, () => {
                            this.unmountDatedSnapshot();
                            this.fetchDatedSnapshot(callback);
                        }
                    )
                },
                collections: {
                    status_key: 'collection_status',
                    all:{},
                    sorted: [],
                    data:[],
                    exportData: [],
                    filter: '',
                    status_filters: [
                        'Not Started',
                        'In Progress',
                        'Ready To Stock Out'
                    ],
                    notificationStates: [
                      "Notified",
                      "Unnotified",
                      "Both"
                    ],
                    notificationState: "Both",
                    changeNotificationFilters: filter=>{
                        const {collections} = this.state;
                        collections.notificationState = filter;
                        this.setState({
                            collections: {...collections}
                        }, () => this.filterCollections(collections, () => {
                        }))
                    },
                    selectAll: () => {
                        const {collections} = this.state;
                        collections.status_filters = STATUS_LABELS.slice(1);
                        this.setState({collections: {...collections}}, () => this.filterCollections(collections, () => {
                        }))
                    },
                    selectNone: () => {
                        const {collections} = this.state;
                        collections.status_filters = [];
                        this.setState({collections: {...collections}}, () => this.filterCollections(collections, () => {
                        }))
                    },
                    updateStatusLabels: (sl) => {
                        const {collections} = this.state;
                        const {status_filters} = collections;
                        if (status_filters.indexOf(sl) > -1) {
                            collections.status_filters = status_filters.filter(s => s !== sl)
                        } else {
                            collections.status_filters.push(sl);
                        }
                        this.setState({collections: {...collections}}, () => this.filterCollections(collections, () => {
                        }))

                    },
                    headers: CollectionHeaders,
                    sort: 'collection_ref',
                    setSort:  (sort) => {
                        const collections = _.cloneDeep(this.state.collections);
                        if (collections.sort !== sort) {
                            collections.sort = sort;
                        } else {
                            collections.reverse *= -1;
                        }
                        collections.sorted = this.getSortedData(Object.values(collections.all), collections.sort, collections.reverse);

                        this.setState({collections: collections}, () => {
                            this.filterCollections(collections);
                        })

                    },
                    reverse: 1,
                    setDate: (start, end, callback) => this.setState(
                        {
                            start: start,
                            end: end
                        }, () => {
                            setTimeout(()=>{this.unmountDatedSnapshot();
                            this.fetchDatedSnapshot(callback)}, 100);
                        }
                    )
                }
            };

            this.filterCollections = this.filterCollections.bind(this)
        }

        applyFilter(item, {headers, filter, status_filters, status_key, notificationState}) {

            let matched = headers.filter(h => !!h.filter).find(h => {
                const {key} = h;

                return item[key] && (item[key].toString().toLowerCase().indexOf(filter.toLowerCase()) > -1)
            });
            const matched2 = !!matched && (status_filters.indexOf(item[status_key]) > -1);
            return matched2 && (!notificationState || notificationState === "Both" || ((notificationState === "Notified") === !!item.notified));
        }

        setCollectionsFilter(filter, callback) {
            const existing_collections = {...this.state.collections};
            existing_collections.filter = filter;
            this.setState({collections: existing_collections}, () => {
                this.filterCollections(callback)
            })
        }

        setRequestsFilter(filter, callback) {
            const existing_collections = {...this.state.requests};
            existing_collections.filter = filter;
            this.setState({requests: existing_collections}, () => {
                this.filterRequests(callback)
            })
        }

        fetchDatedSnapshot(callback) {
            const {firebase, organisation_id} = this.props;
            const {start, end} = this.state;
            if (!organisation_id)
                this.setState({uid: null, loading: false});
            else {
                const {requests, collections} = this.state;
                requests.all = Object.filter(requests.all, r=>parseInt(r.expected) > this.state.start && parseInt(r.expected) < this.state.end );
                requests.sorted = this.getSortedData(Object.values(requests.all), requests.sort, requests.reverse);
                requests.data = this.getFilteredData(requests.sorted, requests.filter,this.applyFilter, requests);
                requests.exportData = this.getExportData(requests.data, requests.headers);
                collections.all = Object.filter(collections.all, r=>parseInt(r.expected) > this.state.start && parseInt(r.expected) < this.state.end );
                collections.sorted = this.getSortedData(Object.values(collections.all), collections.sort, collections.reverse);
                collections.data = this.getFilteredData(collections.sorted, collections.filter,this.applyFilter, collections);
                collections.exportData = this.getExportData(collections.data, collections.headers);
                this.setState({
                    collection_requests: {},
                    requests,
                    collections
                }, ()=>{
                    const ref = firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id))
                        .orderByChild("expected").startAt(start).endAt(end);
                    ref.once('value').then((result_snap) => {
                        this.updateData(result_snap, ()=> {
                            if(callback) {
                                callback();
                            }
                            ref.on('value',(result_snap) => {
                                this.updateData(result_snap, ()=>console.debug("Updated"));
                            });
                        });
                    });

                })
            }
        }
        async checkForReportingErrors() {
            const {firebase, organisation_id} = this.props;
            const collections =  await firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).orderByChild('collected_on').startAt(moment().subtract(7,'days').toDate().getTime()).once('value');
            let total = 0;
            let fixed = 0;
            let collection_status = Object.values(collections.val() || {}).filter(c=>c.stocked_out).map((c)=>{
                return firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed')).child(c.id).once('value').then((res)=> {
                    if (!res.exists()) {
                        total++;
                        if (c.pass_id) {
                            return firebase.functions.httpsCallable('summaries-collections-getSummary')({
                                organisation_id: organisation_id, collection_id: c.id
                            }).then(() => {

                                    setTimeout(() => {
                                        firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed')).child(c.id).once('value').then((res) => {
                                            if (!res.exists()) {
                                                console.error(`Collection: ${c.collection_ref} failed to fix reporting row`);
                                            } else {
                                                console.log(`Collection: ${c.collection_ref} resynced pass ID and corrected reporting`);
                                                fixed++;
                                            }
                                        })

                                    }, 15000)

                            })
                        } else {
                            return firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).orderByChild("collections/" + c.id).once('value').then((pass_snap) => {
                                const passes = pass_snap.val();
                                const pass_id = Object.keys(passes).find(p => passes[p].collections && passes[p].collections[c.id]);

                                if (pass_id) {
                                    return firebase.functions.httpsCallable('resyncGatePass')({
                                        organisation_id: organisation_id,
                                        pass_id: pass_id,
                                        incoming: false
                                    }).then(() => {
                                            setTimeout(() => {
                                                firebase.db.ref(DATABASE_REFS.organisations.tracking.by_status(organisation_id, 'completed')).child(c.id).once('value').then((res) => {
                                                    if (!res.exists()) {
                                                        console.error(`Collection: ${c.collection_ref} failed to fix reporting row`);
                                                    } else {
                                                        console.log(`Collection: ${c.collection_ref} resynced pass ID and corrected reporting`);
                                                        fixed++;
                                                    }
                                                })

                                            }, 15000)
                                        }
                                    );
                                }
                            })
                        }
                    }
                    return res.exists()
                })
            });
            Promise.all(collection_status).then(()=>{
                console.log(`Collection errors found: ${total}`)
                console.log(`Collection errors fixed: ${fixed}`)
            });
        }

        updateData(result_snap, callback){
            const requests = _.cloneDeep(this.state.requests);
            const collections = _.cloneDeep(this.state.collections);

            requests.all = result_snap.val() || {};
            requests.sorted = this.getSortedData(Object.values(requests.all), requests.sort, requests.reverse);

            requests.data = this.getFilteredData(requests.sorted, requests.filter,this.applyFilter, requests);
            requests.exportData = this.getExportData(requests.data, requests.headers);
            collections.all = Object.filter(result_snap.val(), r=>r.accepted);
            collections.sorted = this.getSortedData(Object.values(collections.all), collections.sort, collections.reverse);
            collections.data = this.getFilteredData(collections.sorted, collections.filter,this.applyFilter, collections).filter(c=>{
                return !c.cancelled
            });
            collections.exportData = this.getExportData(collections.data, collections.headers);
            this.setState({
                collection_requests: {},
                requests,
                collections
            }, callback)
        }

        getExportData(data, headers) {
            return data.map(d => {
                const res = {};
                headers.map((h) => {
                    res[h.label] = h.content(d);
                    return res;
                });
                return res
            });
        }

        getSortedData(data_to_use, sort, reverse) {

            return data_to_use.sort((a, b) => {

                const split_sort = sort.split(',');
                if (split_sort.length > 1) {
                    const a_val = a[split_sort[0]] === '-' || a[split_sort[0]] === '' ? a[split_sort[1]] : a[split_sort[0]];
                    const b_val = b[split_sort[0]] === '-' || a[split_sort[0]] === '' ? b[split_sort[1]] : b[split_sort[0]];
                    return ((a_val < b_val || !b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
                }
                const a_val = typeof (a[sort]) === 'string' ? a[sort].toLowerCase() : a[sort];
                const b_val = typeof (b[sort]) === 'string' ? b[sort].toLowerCase() : b[sort];
                if(a_val && !b_val) {
                    return -1 * reverse;
                }
                if(b_val && !a_val) {
                    return 1 * reverse;
                }
                if(!b_val && !a_val) {
                    return 0;
                }

                return ((a_val < b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
            });

        }

        getFilteredData(data_to_filter, filter, filter_function, options) {
            return data_to_filter.filter((a) => filter_function(a, options))
        }

        filterCollections(options, callback) {
            const existing_collections = {...this.state.collections};
            existing_collections.data = this.getFilteredData(existing_collections.sorted, existing_collections.filter, this.applyFilter, existing_collections).filter(c=>{
                return !c.cancelled
            });
            existing_collections.exportData = this.getExportData(existing_collections.data, existing_collections.headers);
            this.setState({collections: existing_collections}, callback)
        }
        filterRequests(options, callback) {
            const existing_collections = {...this.state.requests};
            existing_collections.data = this.getFilteredData( existing_collections.sorted, existing_collections.filter, this.applyFilter, existing_collections);
            existing_collections.exportData = this.getExportData(existing_collections.data, existing_collections.headers);
            this.setState({requests: existing_collections}, callback)
        }

        componentDidMount() {
            this.checkForReportingErrors().then(()=>{});
            this.fetchDatedSnapshot();
        }

        unmountDatedSnapshot() {
            const {firebase, organisation_id} = this.props;
            if (!organisation_id) return;
            firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(organisation_id)).off('value');
        }

        componentWillUnmount() {
            this.unmountDatedSnapshot();
        }

        render() {
            const loading = this.state.loading || this.state.loading;
            if (loading) {
                return <LoadingScreen/>
            }

            return (
                <ContainerMasterCollectionsContext.Provider value={this.state}>
                    <Component {...this.props} filter={{collections:this.state.collections.filter, requests: this.state.requests.filter}}
                               setCollectionsFilter={this.setCollectionsFilter.bind(this)}
                               setCollectionRequestsFilter={this.setRequestsFilter.bind(this)}
                    />
                </ContainerMasterCollectionsContext.Provider>
            );
        }
    }

    return withFirebase(WithCollections);
};

export default withCollections;