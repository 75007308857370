// external
import * as React from "react";
import ReactToPrint from 'react-to-print';
import {Button, ButtonGroup, Dropdown, DropdownButton, FormControl, Image} from "react-bootstrap";
import {toast} from "react-toastify";
import * as PropTypes from 'prop-types';
// Images
import products from '../../../icons/products.png';
// Constants
import db_refs from '../../../constants/db_refs';
import storage_refs from '../../../constants/storage_refs';
import {toastConfig} from "../../../config/toast_config"
//Components
import StepProgressBar from "../../../elements/StepProgressBar";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Table from "react-bootstrap/Table";
import Error from "../../../elements/Error";
import {BayQuantitySelector} from "../../../elements/BayQuantitySelector";
import {DevanGatePassForm, GatePassModal} from "../GatePassIssuer/GatePassForm";
import {sort_bays} from "../../../helpers/wizards";
import container from "../../../icons/container.png";
import {FileModal} from "./Requests/Wizard";
import {productObjectFromArray} from "./utils";
import ProductRemoval from "../../../components/ProductRemoval";

const PRESETS = [
    'Missing Crate(s)',
    'Unexpected Crate(s)',
    'Wrong Product',
    'Other issue'
];

const ProductForm = ({firebase, organisation_id, outturn_id, actual, expected_products, getTotalAcrossBays, handleChange, updateBays, bays, addPresetReason}) => Object.keys(actual || {})
    .filter(pkey=>((actual[pkey].crates) || (expected_products[pkey].crates || []).length > 0) || (actual[pkey] && (expected_products[pkey] && !expected_products[pkey].crates)) )
    .map((pkey) => {
    const crates = actual[pkey].crates || {};
    const actual_num = getTotalAcrossBays(actual[pkey].crates);
    const expected_num =  expected_products[pkey].crates ? expected_products[pkey].crates.length : 0;
    return (
        <div key={`product_${pkey}`} className={'row col-12 col-xl-6 m-0 print-product'}>
            <div className={'col-6 p-1'}>
                {pkey}<br className={'no-print'}/>
                <sub
                    className={'no-print'}>{expected_products[pkey].description}</sub>
                <p className={'m-0 print-only'}>{expected_products[pkey].description}</p>
            </div>
            <div className={'col-1 p-1 text-center no-print'}>
                {(expected_products[pkey].crates || []).length}
            </div>
            <div className={'col-3 p-1 no-print'}>
                <FormControl
                    className={'no-print'}
                    type={'number'}
                    name={'expected/' + pkey + '/total_crates'}
                    value={getTotalAcrossBays(actual[pkey].crates)}
                    readOnly={true}
                    onChange={handleChange}
                />

            </div>
            <div className={'col-2 p-1 no-print'}>
                <ProductRemoval organisation_id={organisation_id} movement_id={outturn_id} product_code={pkey} firebase={firebase}/>

            </div>
            <div className={'row no-print w-100'}>
                {
                    Object.keys(crates).map(crate_bay_key => {

                        return (
                            <BayQuantitySelector
                                key={`qty_selector_${pkey}_${crate_bay_key}`}
                                qty={actual[pkey].crates[crate_bay_key].qty}
                                onChange={updateBays} bays={bays}
                                current_bay={crate_bay_key}
                                pkey={pkey} expected={actual}/>
                        );

                    })
                }
                {
                    (actual_num < expected_num || expected_num === 0) &&
                    <BayQuantitySelector qty={0} onChange={updateBays}
                                         bays={bays} current_bay={''} pkey={pkey}
                                         expected={actual}/>

                }
            </div>
            <div className={'col-12 p-1'}>
                {
                    PRESETS.map((p, i) => {
                        return <Button
                            key={`preset${i}`}
                            name={'actual/' + pkey + '/reason'}
                            data-content={p}
                            className={'bg-tertiary text-light btn-sm m-1 small no-print'}
                            onClick={addPresetReason}
                        >
                            {p}
                        </Button>
                    })
                }

                <br className={'no-print'}/>
                <span className={'no-print'}>Notes:</span>
                <FormControl
                    className={'no-print'}
                    isInvalid={(parseInt(getTotalAcrossBays(actual[pkey].crates)) !== (expected_products[pkey].crates || []).length) && (!actual[pkey].reason || actual[pkey].reason.trim().length === 0)}
                    isValid={parseInt(getTotalAcrossBays(actual[pkey].crates)) === (expected_products[pkey].crates || []).length || !actual[pkey].reason || actual[pkey].reason.trim().length > 0}
                    name={'actual/' + pkey + '/reason'}
                    value={actual[pkey].reason}
                    onChange={handleChange} as="textarea" rows="3"
                />
            </div>

            <hr className={'bg-primary w-100'}/>
        </div>)

});
const CompletionPanel = ({total, issued_on, issued_by, processed_on, processed_by}) => <div className={'col-12'}>

    <Table striped={true} size={'sm'} responsive={true} bordered={true} className={'w-75 ml-auto mr-auto mb-1'}>
        <thead>
        <tr>
            <td>Total Crates</td>
            <td>Status</td>
            <td>Stock In Operative</td>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>{total}</td>
            <td>{issued_on ? `Products delivered on ${new Date(issued_on).toLocaleString()}` : <span>
                    <FontAwesomeIcon icon={"spinner"} spin={true}/><br/>Processing delivery</span>}</td>
            <td>{issued_by ? `${issued_by.displayName}` : '-'}</td>
        </tr>
        <tr>
            <td>{total}</td>
            <td>{processed_on ? `Products added to stock on ${new Date(processed_on).toLocaleString()}` : <span>
                    <FontAwesomeIcon icon={"spinner"} spin={true}/><br/>Processing stock in</span>}</td>
            <td>{processed_by ? `${processed_by.name}` : '-'}</td>
        </tr>
        </tbody>

    </Table>
</div>;
const VerificationButtons = ({react_button, verified, issued_on, pass_id, revertContents, revertStockIn, viewOrIssuePass, authoriseStockIn, viewPass, provisional_pass_id}) => <div
    className={'col-12'}>
    {verified && <Button className={'m-1'} onClick={revertStockIn} variant={'danger'}>Revert Stock In</Button>}
    {verified && react_button}
    {!verified && <Button className={'m-1'} onClick={revertContents} variant={'danger'}>Return to Content
        Verification</Button>}
    {!pass_id && !provisional_pass_id &&
    <Button className={'m-1'} onClick={viewOrIssuePass} variant={'primary'}>Issue Gate Pass</Button>}
    {(pass_id || provisional_pass_id) && <Button className={'m-1'} variant={'primary'} onClick={viewPass}>View Gate Pass</Button>}
    {!verified &&
    <Button disabled={!issued_on} onClick={authoriseStockIn} className={'m-1'} variant={'success'}>Authorise Stock
        In</Button>}
</div>;
const ProductTable = ({bays, expected_products, actual}) => <Table bordered size={'sm'} className={'larger col-6'}>
    <thead>
    <tr>
        <th>Product</th>
        <th>Bay</th>
        <th>Quantity</th>
        <th>Notes</th>
    </tr>
    </thead>
    <tbody>
    {
        Object.keys(expected_products).map((pkey) => {
            const crates = actual[pkey] && actual[pkey].crates ? actual[pkey].crates : {};
            const invalidDefaultBay = actual[pkey] ? actual[pkey].invalidDefaultBay : false;
            return Object.keys(crates || {}).map((bkey, i) => {
                return (<tr key={`outturn_record_${pkey}_${bkey}`}>
                    <td>{pkey}</td>
                    <td>{bays[bkey].code}
                    </td>
                    <td>{crates[bkey].qty}</td>
                    {i === 0 &&
                    <td rowSpan={Object.keys(crates).length}>
                        {actual[pkey] ? actual[pkey].reason : expected_products[pkey].reason}
                        {invalidDefaultBay && <p className={"text-danger"}>Invalid default detected in processing</p>}
                    </td>}
                </tr>);
            })
        })
    }
    </tbody>
</Table>;
const WizardHeading = ({container_number, customer, organisation_name}) => <div className={'col-12 row'}>
    <div className={'col-4 no-print'}>
        <h3 className={'container-number'}>Container No: {container_number}</h3>
    </div>
    <div className={'col-4 no-print'}>
        <h3>Customer: {customer}</h3>
    </div>
    <div className={'col-4 no-print'}>
        <h3>Organisation: {organisation_name}</h3>
    </div>
</div>;
const PhotoView = ({photos}) => <div className={'col-6 row m-0'}>
    <h3>Photos</h3>
    {
        photos && photos.map((photo, i) => {
            return <div key={`image_${i}`} className={'col-12'}><Image className={'img img-fluid m-1'} src={photo}/>
            </div>
        })
    }
</div>;

const OutturnGatePassModal = ({onHide, issuing, organisation_id, firebase, logo, customers}) => <GatePassModal
    onHide={onHide}
    issuing={issuing}
>
    <DevanGatePassForm
        cancel={onHide}
        edit={onHide}
        customers={customers}
        logo={logo || ''}
        firebase={firebase}
        pass_id={issuing}
        organisation_id={organisation_id}
    />
</GatePassModal>;

const ElectronicOutturn = ({customer_code, ship_name, container_number, total, issued_on, actual, completed, expected_products, getTotalAcrossBays, include_images, photos, existing_products}) => <div id={"container"}>
    <h1 id="p-header" className={'mt-0 w-100 text-center'}>Electronic Outturn</h1>
    <table id={"eo-table1"} className={'table-bordered table table-sm text-center w-100'}>
        <tbody>
        <tr>
            <td className={'w-50 bg-secondary text-center'}>Customer</td>
            <td className={'w-50 text-center'}>{customer_code}</td>
        </tr>
        <tr>
            <td className={'bg-secondary text-center'}>Container No.</td>
            <td className={'w-50 text-center'}>{container_number}</td>
        </tr>

        <tr>
            <td className={'bg-secondary text-center'}>Ship Name</td>
            <td className={'w-50 text-center'}>{ship_name}</td>
        </tr>
        <tr>
            <td className={'bg-secondary text-center'}>Total Crates</td>
            <td className={'w-50 text-center'}>{total}</td>
        </tr>
        <tr>
            <td className={'bg-secondary text-center'}>Devan Date</td>
            <td className={'w-50 text-center'}>{issued_on ?  new Date(issued_on).toLocaleString() : ''}</td>
        </tr>
        </tbody>
    </table>
    <table>
        <tbody>


    { existing_products && Object.keys(actual || {}).filter(pkey=>(getTotalAcrossBays(actual[pkey].crates) > 0 || (expected_products[pkey].crates || []).length > 0) ).map((product) => {
        const crates = Object.values(actual[product].crates || {} );
        const existing_product = Object.values(existing_products || {}).find(p=>p.code === product);
        const product_description = existing_product ? existing_product.description : expected_products[product] && expected_products[product].description ? expected_products[product].description : '';
        return <table id={"eo-table2"} style={{tableLayout: 'fixed', marginBottom: "300px"}} key={`complete_printed_product_${product}`} className={'text-center table table-sm table-bordered mb-1'}>
            <tbody>
            <tr>
                <td colSpan={4} className={'text-left'}>{product}</td>
                <td colSpan={12+(4*Math.max(crates.length-3, 0))} className={'emphasize text-left'}>
                   { product_description }
                </td>
                <td className={'emphasize text-center'}>{completed ? getTotalAcrossBays(actual[product].crates) : expected_products[product].crates.length}</td></tr>

            <tr>
                {crates.map((crate) => {
                    return [
                        <td colSpan={2} key={`outturn_${product}_${crate.code}_code`}  className={'bg-secondary'}>{crate.code}</td>,
                        <td colSpan={2} key={`outturn_${product}_${crate.code}_qty`}  >{completed && crate.qty}&nbsp;</td>
                    ]
                })}
                {
                    Array.from(new Array(Math.max(3-crates.length, 0))).map((x,i)=>[
                        <td key={`${product}_crate_${i}_0`} colSpan={2} className={'bg-secondary'}>&nbsp;</td>,
                        <td key={`${product}_crate_${i}_1`} colSpan={2}>&nbsp;</td>
                    ])
                }
                <td colSpan={4} className={'text-right'}>Checked</td>
                <td>&nbsp;</td>
            </tr>
            { actual[product].reason.length > 0 &&
            <tr>
                <td className={'text-left'} colSpan={17+(4*Math.max(crates.length-3, 0))}>
                    { actual[product].reason}
                </td>
            </tr>}

            </tbody>

        </table>
    })}</tbody>
        <tfoot>
        <table className={"table table-sm table-bordered print-footer"}>
            <tbody>
        <tr>
            <td className={'bg-secondary text-center'}>Unloaded by</td>
            <td className={'w-25 text-center'}>&nbsp;</td>
            <td className={'bg-secondary text-center'}>Checked by</td>
            <td className={'w-25 text-center'}>&nbsp;</td>
        </tr>
        <tr>
            <td className={'bg-secondary text-center'}>Total Quantity:</td>
            <td className={'w-25 text-center'}>&nbsp;</td>

            <td colSpan={2} className={'w-50 text-center'}><h1>{total}</h1></td>
        </tr>
        <tr>
            <td colSpan={4}>* Warehouse operatives please ensure the contents of the container match the outturn exactly, any discrepancies or damaged to be noted on the outturn and a supervisor informed immediately. </td>
        </tr></tbody></table>
        </tfoot>
</table>

{include_images &&  photos.length && <h3>Photos</h3>}
    {include_images && photos && photos.map((photo, i) => {
        return <div key={`photo_${i}`} className={'col-12'}><Image className={'img img-fluid m-1'} src={photo}/></div>
    })}

</div>;
class Wizard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: false,
            container_number: false,
            products: [],
            expected: {},
            actual: {},
            loading: true,
            container_checked: false,
            revision: 0,
            newProduct: 0,
            skipping: false,
            outturn_id: props.outturn_id,
            crate_view: false,
            allowPresets: false,
            photos: []
        };
    }

    addProduct() {
        const {expected_products, newProduct, actual, current_product} = this.state;

        expected_products[current_product.code] = {
            description: current_product.description,
            new: true,
            crates: 0
        };
        actual[current_product.code] = {
            total_crates: 0,
            crates: {},
            reason: ''
        };
        this.setState({newProduct: newProduct + 1, expected_products: expected_products, actual: actual})
    }
    processData(snapshot) {
        const {firebase, organisation_id, outturn_id} = this.props;
        if (snapshot.val()) {
            const {
                container_number, provisional_pass_id, customer_name,
                packing_list, crates_val, projected, expected, locked,
                pass_id, customer, processed_on, processed_by, stocked_in,
                delivered_on, issued_by, products, photos, started, ship_name, notified
            } = snapshot.val();
            const formatted_products = productObjectFromArray(products.expected, products.actual);
            const current_outturn_ref = firebase.db.ref(db_refs.organisations.stock_movement.stock_in(organisation_id)).child(outturn_id);
            if (!started) {
                this.startOutturn(formatted_products, container_number, customer, expected, current_outturn_ref, outturn_id);
            }

            this.setState({
                customer: customer,
                customer_name: customer_name,
                container_number: container_number,
                expected_products: formatted_products,
                actual: products.actual,
                issued_on: delivered_on,
                issued_by,
                ship_name,
                notified,
                pass_id,
                provisional_pass_id,
                processed_on,
                processed_by,
                photos: Object.values(photos || {}),
                packing_list,
                crates: crates_val,
                projected,
                completed: locked,
                verified: stocked_in,
                loading: false
            }, () => this.getPackingListUrl());
        }
    }

    startOutturn(formatted_products, container_number, customer, expected, current_outturn_ref, outturn_id) {
        const {firebase, organisation_id, existing_products} = this.props;
        const outturn_products = {};
        const used_bays = [];
        const {bays} = this.props;
        Object.keys(formatted_products).map((pkey) => {
            const defaultBay = Object.values(existing_products).find(p=>p.code===pkey).default_devan_bay;



            let valid_bays_with_products = Object.values(bays).filter(b => {
                const {product} = b;
                return (product === pkey) && used_bays.indexOf(b.id) === -1
            });

            let valid_bays_without_products = Object.values(bays).filter(b => {
                const {product} = b;
                return (!product) && used_bays.indexOf(b.id) === -1
            });
            const validDefault = valid_bays_with_products.find(b=>b.id === defaultBay)
            const validDefaultEmpty = valid_bays_without_products.find(b=>b.id === defaultBay)
            if(defaultBay) {
                if (validDefault) {
                    valid_bays_with_products = valid_bays_with_products.filter(b => b.id !== defaultBay)
                } else if (validDefaultEmpty) {
                    valid_bays_without_products = valid_bays_without_products.filter(b => b.id !== defaultBay)
                } else {
                    toast.error(`The default for product ${pkey} is no longer valid, please update it on the product page`)
                }
            }

            let sorted_bays = valid_bays_with_products.sort(sort_bays).reverse();
            const sorted_bays2 = valid_bays_without_products.sort(sort_bays).reverse();
            if(validDefault) {
                console.log("using default")
                sorted_bays = [validDefault, ...sorted_bays]
            } else if (validDefaultEmpty) {
                console.log("using empty default")
                sorted_bays = [validDefaultEmpty, ...sorted_bays]
            }
            const crates = {};

            let bay = sorted_bays.shift();
            if (!bay) {
                bay = sorted_bays2.shift();
            }
            if (bay) {
                crates[bay.id] = {
                    code: bay.code,
                    qty: formatted_products[pkey].crates.length
                };
                used_bays.push(bay.id);

            }


                outturn_products[pkey] = {
                    description: formatted_products[pkey].description,
                    crates: crates,
                    reason: ''
                };


            });
        const outturn = {
            container_number: container_number,
            expected: expected,
            products: outturn_products,
            customer: customer
        };
        current_outturn_ref.set(outturn).then(() => {
            toast.success(`Started outturn`, {...toastConfig, autoClose: 2000});
        });
        firebase.db.ref(db_refs.organisations.request.devan(organisation_id)).child(outturn_id).child('started').set(true);



    }

    uploadPhoto(event) {
        const {firebase, organisation_id, outturn_id} = this.props;
        const file = event.target.files[0];
        const imgKey = firebase.db.ref(db_refs.organisations.stock_movement.stock_in(organisation_id)).child(outturn_id).child("photos").push().key;
        const fileRef = firebase.storage.ref(storage_refs.outturn_image(organisation_id, outturn_id)).child(imgKey).child(file.name);
        const uploadTask = fileRef.put(file);

        uploadTask.on('state_changed', function () {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        }, function (error) {
            // Handle unsuccessful uploads
            console.error(error)
        }, function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.debug('File available at', downloadURL);
                firebase.db.ref(db_refs.organisations.stock_movement.stock_in(organisation_id)).child(outturn_id).child('photos').push().set(downloadURL).then(() => {
                    toast.success(`Uploaded photo: ${file.name}`, {...toastConfig, autoClose: 2000});
                })

            });
        });

    }

    _handleChange(event, append) {
        let newState = {};
        const nameParts = event.target.name.split('/');
        const existing = this.state[nameParts[0]];
        if (!existing[nameParts[1]]) {
            existing[nameParts[1]] = {
                crates: 0,
                reason: ''
            };
        }
        if (append)
            existing[nameParts[1]][nameParts[2]] = existing[nameParts[1]][nameParts[2]].trim().length > 0 ? existing[nameParts[1]][nameParts[2]] + "\n" + event.target.value : event.target.value;
        else
            existing[nameParts[1]][nameParts[2]] = event.target.value;
        newState[nameParts[0]] = existing;
        this.setState(newState)
    }

    handleChange(event) {
        this._handleChange(event, false);
    }
    addPresetReason(event) {
        this._handleChange({
            target: {
                name: event.target.name,
                value: event.target.dataset.content
            }
        }, true)
    }
    printOutturn() {
        const {customer_name} = this.state;
        const {organisation} = this.props;
        document.title = `Outturn Form for ${customer_name} - ${organisation.name} ${new Date().toDateString()}`;
    }

    finishPrinting() {
        document.title = 'Container Master'
    }

    componentDidMount() {
        const {outturn_id, firebase, organisation_id} = this.props;
        if (outturn_id) {
            const ref = firebase.db.ref(db_refs.organisations.summaries.outturn(organisation_id)).child(outturn_id);
            ref.on('value', this.processData.bind(this));
        }
    }

    componentWillUnmount() {
        const {firebase, organisation_id, outturn_id} = this.props;
        const current_outturn_ref = firebase.db.ref(db_refs.organisations.stock_movement.stock_in(organisation_id)).child(outturn_id);
        current_outturn_ref.off();
    }

    saveAndFinishVerifyContents() {
        this.saveContents(true);
    }

    saveVerifyContents() {
        this.saveContents(false);
        this.props.cancelEditing();
    }

    saveContents(finish) {
        const {firebase} = this.props;
        const actual_result = this.state.actual;
        const ref = firebase.db.ref(db_refs.organisations.stock_movement.stock_in(this.props.organisation_id)).child(this.props.outturn_id);
        ref.update({
            products: actual_result,
            completed: finish,
            container_number: this.state.container_number
        }).then(() => {});
    }

    authoriseStockIn() {
        const {firebase, displayName} = this.props;
        const ref = this.props.firebase.db.ref(db_refs.organisations.stock_movement.stock_in(this.props.organisation_id)).child(this.props.outturn_id);
        ref.update({
            verified: true,
            bay_error: null,
            processed_on: new Date().getTime(),
            processed_by: {
                name: displayName,
                uid: firebase.auth.currentUser.uid,
                email: firebase.auth.currentUser.email
            }
        }).then(() => {
        })
    }

    revertContents() {
        const ref = this.props.firebase.db.ref(db_refs.organisations.stock_movement.stock_in(this.props.organisation_id)).child(this.props.outturn_id);
        ref.child('completed').set(false).then(() => {
        }).catch((e)=>console.error(e))
    }
    revertStockIn() {
        const {organisation_id, outturn_id} = this.props;
        const ref = this.props.firebase.db.ref(db_refs.organisations.stock_movement.stock_in(this.props.organisation_id)).child(this.props.outturn_id);
        ref.update({verified: false, processed_on: null, processed_by: null}).then(() => {
            this.props.firebase.functions.httpsCallable('summaries-outturns-getOutturnSummary')({
                organisation_id, outturn_id
            }).then((res)=>console.log(res))
        })
    }
    notifyContacts(data) {
        const {organisation_id, outturn_id} = this.props;
        const {customer} = this.state;
        this.setState({
            notifying: true
        })


        let modified_data = data
        modified_data = modified_data.replace("</head>", `<style id="react-to-print-1">table.dataTable { width: 180mm; margin: 0 auto; clear: both; border-collapse: separate; border-spacing: 0; }
table.dataTable thead th, table.dataTable tfoot th { font-weight: bold; }
table.dataTable thead th, table.dataTable thead td { padding: 10px 18px; border-bottom: 1px solid rgb(17, 17, 17); }
table.dataTable thead th:active, table.dataTable thead td:active { outline: none; }
table.dataTable tfoot th, table.dataTable tfoot td { padding: 10px 18px 6px; border-top: 1px solid rgb(17, 17, 17); }
table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled { cursor: pointer; background-repeat: no-repeat; background-position: right center; }
table.dataTable thead .sorting { background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7XQMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC"); }
table.dataTable thead .sorting_asc { background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg=="); }
table.dataTable thead .sorting_desc { background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII="); }
table.dataTable thead .sorting_asc_disabled { background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAW0lEQVQoz2NgoCm4w3Vnwh02wspK7/y6k01Ikdadx3f+37l9RxmfIsY7c4GKQHDiHUbcyhzvvIMq+3THBpci3jv7oIpAcMcdduzKEu/8vPMdDn/eiWQYBYMKAAC3ykIEuYQJUgAAAABJRU5ErkJggg=="); }
table.dataTable thead .sorting_desc_disabled { background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAWUlEQVQoz2NgGAWDCtyJvPPzznc4/HknEbsy9js77vyHw313eHGZZ3PnE1TRuzuOuK1lvDMRqmzuHUZ87lO+cxuo6PEdLUIeyb7z604pYf+y3Zlwh4u2YQoAc7ZCBHH4jigAAAAASUVORK5CYII="); }
table.dataTable tbody tr { background-color: white; }
table.dataTable tbody tr.selected { background-color: rgb(176, 190, 217); }
table.dataTable tbody th, table.dataTable tbody td { padding: 8px 10px; }
table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td { border-top: 1px solid rgb(221, 221, 221); }
table.dataTable.row-border tbody tr:first-child th, table.dataTable.row-border tbody tr:first-child td, table.dataTable.display tbody tr:first-child th, table.dataTable.display tbody tr:first-child td { border-top: none; }
table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td { border-top: 1px solid rgb(221, 221, 221); border-right: 1px solid rgb(221, 221, 221); }
table.dataTable.cell-border tbody tr th:first-child, table.dataTable.cell-border tbody tr td:first-child { border-left: 1px solid rgb(221, 221, 221); }
table.dataTable.cell-border tbody tr:first-child th, table.dataTable.cell-border tbody tr:first-child td { border-top: none; }
table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd { background-color: rgb(249, 249, 249); }
table.dataTable.stripe tbody tr.odd.selected, table.dataTable.display tbody tr.odd.selected { background-color: rgb(171, 185, 211); }
table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover { background-color: whitesmoke; }
table.dataTable.hover tbody tr:hover.selected, table.dataTable.display tbody tr:hover.selected { background-color: rgb(169, 183, 209); }
table.dataTable.order-column tbody tr > .sorting_1, table.dataTable.order-column tbody tr > .sorting_2, table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1, table.dataTable.display tbody tr > .sorting_2, table.dataTable.display tbody tr > .sorting_3 { background-color: rgb(249, 249, 249); }
table.dataTable.order-column tbody tr.selected > .sorting_1, table.dataTable.order-column tbody tr.selected > .sorting_2, table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1, table.dataTable.display tbody tr.selected > .sorting_2, table.dataTable.display tbody tr.selected > .sorting_3 { background-color: rgb(172, 186, 212); }
table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 { background-color: rgb(241, 241, 241); }
table.dataTable.display tbody tr.odd > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 { background-color: rgb(243, 243, 243); }
table.dataTable.display tbody tr.odd > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 { background-color: whitesmoke; }
table.dataTable.display tbody tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 { background-color: rgb(166, 179, 205); }
table.dataTable.display tbody tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 { background-color: rgb(167, 181, 206); }
table.dataTable.display tbody tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 { background-color: rgb(169, 182, 208); }
table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 { background-color: rgb(249, 249, 249); }
table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 { background-color: rgb(251, 251, 251); }
table.dataTable.display tbody tr.even > .sorting_3, table.dataTable.order-column.stripe tbody tr.even > .sorting_3 { background-color: rgb(253, 253, 253); }
table.dataTable.display tbody tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 { background-color: rgb(172, 186, 212); }
table.dataTable.display tbody tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 { background-color: rgb(173, 187, 214); }
table.dataTable.display tbody tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 { background-color: rgb(175, 189, 216); }
table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 { background-color: rgb(234, 234, 234); }
table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 { background-color: rgb(235, 235, 235); }
table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 { background-color: rgb(238, 238, 238); }
table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 { background-color: rgb(161, 174, 199); }
table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 { background-color: rgb(162, 175, 200); }
table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 { background-color: rgb(164, 178, 203); }
table.dataTable.no-footer { border-bottom: 1px solid rgb(17, 17, 17); }
table.dataTable.nowrap th, table.dataTable.nowrap td { white-space: nowrap; }
table.dataTable.compact thead th, table.dataTable.compact thead td { padding: 4px 17px; }
table.dataTable.compact tfoot th, table.dataTable.compact tfoot td { padding: 4px; }
table.dataTable.compact tbody th, table.dataTable.compact tbody td { padding: 4px; }
table.dataTable th.dt-left, table.dataTable td.dt-left { text-align: left; }
table.dataTable th.dt-center, table.dataTable td.dt-center, table.dataTable td.dataTables_empty { text-align: center; }
table.dataTable th.dt-right, table.dataTable td.dt-right { text-align: right; }
table.dataTable th.dt-justify, table.dataTable td.dt-justify { text-align: justify; }
table.dataTable th.dt-nowrap, table.dataTable td.dt-nowrap { white-space: nowrap; }
table.dataTable thead th.dt-head-left, table.dataTable thead td.dt-head-left, table.dataTable tfoot th.dt-head-left, table.dataTable tfoot td.dt-head-left { text-align: left; }
table.dataTable thead th.dt-head-center, table.dataTable thead td.dt-head-center, table.dataTable tfoot th.dt-head-center, table.dataTable tfoot td.dt-head-center { text-align: center; }
table.dataTable thead th.dt-head-right, table.dataTable thead td.dt-head-right, table.dataTable tfoot th.dt-head-right, table.dataTable tfoot td.dt-head-right { text-align: right; }
table.dataTable thead th.dt-head-justify, table.dataTable thead td.dt-head-justify, table.dataTable tfoot th.dt-head-justify, table.dataTable tfoot td.dt-head-justify { text-align: justify; }
table.dataTable thead th.dt-head-nowrap, table.dataTable thead td.dt-head-nowrap, table.dataTable tfoot th.dt-head-nowrap, table.dataTable tfoot td.dt-head-nowrap { white-space: nowrap; }
table.dataTable tbody th.dt-body-left, table.dataTable tbody td.dt-body-left { text-align: left; }
table.dataTable tbody th.dt-body-center, table.dataTable tbody td.dt-body-center { text-align: center; }
table.dataTable tbody th.dt-body-right, table.dataTable tbody td.dt-body-right { text-align: right; }
table.dataTable tbody th.dt-body-justify, table.dataTable tbody td.dt-body-justify { text-align: justify; }
table.dataTable tbody th.dt-body-nowrap, table.dataTable tbody td.dt-body-nowrap { white-space: nowrap; }
table.dataTable, table.dataTable th, table.dataTable td { box-sizing: content-box; }
.dataTables_wrapper { position: relative; clear: both; zoom: 1; }
.dataTables_wrapper .dataTables_length { float: left; }
.dataTables_wrapper .dataTables_length select { border: 1px solid rgb(170, 170, 170); border-radius: 3px; background-color: transparent; padding: 4px; }
.dataTables_wrapper .dataTables_filter { float: right; text-align: right; }
.dataTables_wrapper .dataTables_filter input { border: 1px solid rgb(170, 170, 170); border-radius: 3px; padding: 5px; background-color: transparent; margin-left: 3px; }
.dataTables_wrapper .dataTables_info { clear: both; float: left; padding-top: 0.755em; }
.dataTables_wrapper .dataTables_paginate { float: right; text-align: right; padding-top: 0.25em; }
.dataTables_wrapper .dataTables_paginate .paginate_button { box-sizing: border-box; display: inline-block; min-width: 1.5em; padding: 0.5em 1em; margin-left: 2px; text-align: center; cursor: pointer; border: 1px solid transparent; border-radius: 2px; text-decoration: none !important; color: rgb(51, 51, 51) !important; }
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover { border: 1px solid rgb(151, 151, 151); background: linear-gradient(white 0%, gainsboro 100%); color: rgb(51, 51, 51) !important; }
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active { cursor: default; border: 1px solid transparent; background: transparent; box-shadow: none; color: rgb(102, 102, 102) !important; }
.dataTables_wrapper .dataTables_paginate .paginate_button:hover { border: 1px solid rgb(17, 17, 17); background: linear-gradient(rgb(88, 88, 88) 0%, rgb(17, 17, 17) 100%); color: white !important; }
.dataTables_wrapper .dataTables_paginate .paginate_button:active { outline: none; background: linear-gradient(rgb(43, 43, 43) 0%, rgb(12, 12, 12) 100%); box-shadow: rgb(17, 17, 17) 0 0 3px inset; }
.dataTables_wrapper .dataTables_paginate .ellipsis { padding: 0 1em; }
.dataTables_wrapper .dataTables_processing { position: absolute; top: 50%; left: 50%; width: 100%; height: 40px; margin-left: -50%; margin-top: -25px; padding-top: 20px; text-align: center; font-size: 1.0em; background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%); }
.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate { color: rgb(51, 51, 51); }
.dataTables_wrapper .dataTables_scroll { clear: both; }
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody { }
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td { vertical-align: middle; }
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing { height: 0; overflow: hidden; margin: 0 !important; padding: 0 !important; }
.dataTables_wrapper.no-footer .dataTables_scrollBody { border-bottom: 1px solid rgb(17, 17, 17); }
.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable, .dataTables_wrapper.no-footer div.dataTables_scrollBody > table { border-bottom: none; }
.dataTables_wrapper::after { visibility: hidden; display: block; content: ""; clear: both; height: 0; }
@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate { float: none; text-align: center; }
  .dataTables_wrapper .dataTables_paginate { margin-top: 0.5em; }
}
@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter { float: none; text-align: center; }
  .dataTables_wrapper .dataTables_filter { margin-top: 0.5em; }
}
</style><style id="react-to-print-2">:root { --blue: #007bff; --indigo: #6610f2; --purple: #6f42c1; --pink: #e83e8c; --red: #dc3545; --orange: #fd7e14; --yellow: #ffc107; --green: #28a745; --teal: #20c997; --cyan: #17a2b8; --white: #fff; --gray: #6c757d; --gray-dark: #343a40; --primary: #18135c; --secondary: #ADADAD; --success: #28a745; --info: #17a2b8; --warning: #ffc107; --danger: #dc3545; --light: #EFEFEF; --dark: #363636; --tertiary: #646487; --breakpoint-xs: 0; --breakpoint-sm: 576px; --breakpoint-md: 768px; --breakpoint-lg: 992px; --breakpoint-xl: 1200px; --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
*, ::before, ::after { box-sizing: border-box; }
html { font-family: sans-serif; line-height: 1.15; text-size-adjust: 100%; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section { display: block; }
body { margin: 0; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 1rem; font-weight: 400; line-height: 1.5; color: rgb(33, 37, 41); text-align: left; background-color: rgb(255, 255, 255); }
[tabindex="-1"]:focus:not(:focus-visible) { outline: 0 !important; }
hr { box-sizing: content-box; height: 0; overflow: visible; }
h1, h2, h3, h4, h5, h6 { margin-top: 0; margin-bottom: 0.5rem; }
p { margin-top: 0; margin-bottom: 1rem; }
abbr[title], abbr[data-original-title] { text-decoration: underline dotted; cursor: help; border-bottom: 0; text-decoration-skip-ink: none; }
address { margin-bottom: 1rem; font-style: normal; line-height: inherit; }
ol, ul, dl { margin-top: 0; margin-bottom: 1rem; }
ol ol, ul ul, ol ul, ul ol { margin-bottom: 0; }
dt { font-weight: 700; }
dd { margin-bottom: 0.5rem; margin-left: 0; }
blockquote { margin: 0 0 1rem; }
b, strong { font-weight: bolder; }
small { font-size: 80%; }
sub, sup { position: relative; font-size: 75%; line-height: 0; vertical-align: baseline; }
sub { bottom: -0.25em; }
sup { top: -0.5em; }
a { color: rgb(24, 19, 92); text-decoration: none; background-color: transparent; }
a:hover { color: rgb(7, 6, 29); text-decoration: underline; }
a:not([href]):not([class]) { color: inherit; text-decoration: none; }
a:not([href]):not([class]):hover { color: inherit; text-decoration: none; }
pre, code, kbd, samp { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; font-size: 1em; }
pre { margin-top: 0; margin-bottom: 1rem; overflow: auto; }
figure { margin: 0 0 1rem; }
img { vertical-align: middle; border-style: none; }
svg { overflow: hidden; vertical-align: middle; }
table { border-collapse: collapse; }
caption { padding-top: 0.75rem; padding-bottom: 0.75rem; color: rgb(108, 117, 125); text-align: left; caption-side: bottom; }
th { text-align: -webkit-match-parent; }
label { display: inline-block; margin-bottom: 0.5rem; }
button { border-radius: 0; }
button:focus { outline: -webkit-focus-ring-color auto 5px; }
input, button, select, optgroup, textarea { margin: 0; font-family: inherit; font-size: inherit; line-height: inherit; }
button, input { overflow: visible; }
button, select { text-transform: none; }
[role="button"] { cursor: pointer; }
select { overflow-wrap: normal; }
button, [type="button"], [type="reset"], [type="submit"] { appearance: inherit; }
button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) { cursor: pointer; }
input[type="radio"], input[type="checkbox"] { box-sizing: border-box; padding: 0; }
textarea { overflow: auto; resize: vertical; }
fieldset { min-width: 0; padding: 0; margin: 0; border: 0; }
legend { display: block; width: 100%; max-width: 100%; padding: 0; margin-bottom: 0.5rem; font-size: 1.5rem; line-height: inherit; color: inherit; white-space: normal; }
progress { vertical-align: baseline; }
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }
[type="search"] { outline-offset: -2px; appearance: none; }
[type="search"]::-webkit-search-decoration { appearance: none; }
::-webkit-file-upload-button { font: inherit; }
output { display: inline-block; }
summary { display: list-item; cursor: pointer; }
template { display: none; }
[hidden] { display: none !important; }
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { margin-bottom: 0.5rem; font-weight: 500; line-height: 1.2; }
h1, .h1 { font-size: 2.5rem; }
h2, .h2 { font-size: 2rem; }
h3, .h3 { font-size: 1.75rem; }
h4, .h4 { font-size: 1.5rem; }
h5, .h5 { font-size: 1.25rem; }
h6, .h6 { font-size: 1rem; }
.lead { font-size: 1.25rem; font-weight: 300; }
.display-1 { font-size: 6rem; font-weight: 300; line-height: 1.2; }
.display-2 { font-size: 5.5rem; font-weight: 300; line-height: 1.2; }
.display-3 { font-size: 4.5rem; font-weight: 300; line-height: 1.2; }
.display-4 { font-size: 3.5rem; font-weight: 300; line-height: 1.2; }
hr { margin-top: 1rem; margin-bottom: 1rem; border-width: 1px 0 0; border-right-style: initial; border-bottom-style: initial; border-left-style: initial; border-right-color: initial; border-bottom-color: initial; border-left-color: initial; border-image: initial; border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.1); }
small, .small { font-size: 80%; font-weight: 400; }
mark, .mark { padding: 0.2em; background-color: rgb(252, 248, 227); }
.list-unstyled { padding-left: 0; list-style: none; }
.list-inline { padding-left: 0; list-style: none; }
.list-inline-item { display: inline-block; }
.list-inline-item:not(:last-child) { margin-right: 0.5rem; }
.initialism { font-size: 90%; text-transform: uppercase; }
.blockquote { margin-bottom: 1rem; font-size: 1.25rem; }
.blockquote-footer { display: block; font-size: 80%; color: rgb(108, 117, 125); }
.blockquote-footer::before { content: "— "; }
.img-fluid { max-width: 100%; height: auto; }
.img-thumbnail { padding: 0.25rem; background-color: rgb(255, 255, 255); border: 1px solid rgb(222, 226, 230); border-radius: 0.25rem; max-width: 100%; height: auto; }
.figure { display: inline-block; }
.figure-img { margin-bottom: 0.5rem; line-height: 1; }
.figure-caption { font-size: 90%; color: rgb(108, 117, 125); }
code { font-size: 87.5%; color: rgb(232, 62, 140); overflow-wrap: break-word; }
a > code { color: inherit; }
kbd { padding: 0.2rem 0.4rem; font-size: 87.5%; color: rgb(255, 255, 255); background-color: rgb(33, 37, 41); border-radius: 0.2rem; }
kbd kbd { padding: 0; font-size: 100%; font-weight: 700; }
pre { display: block; font-size: 87.5%; color: rgb(33, 37, 41); }
pre code { font-size: inherit; color: inherit; word-break: normal; }
.pre-scrollable { max-height: 340px; overflow-y: scroll; }
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }
@media (min-width: 576px) {
  .container, .container-sm { max-width: 540px; }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md { max-width: 720px; }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg { max-width: 960px; }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl { max-width: 1140px; }
}
.row { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }
.no-gutters { margin-right: 0; margin-left: 0; }
.no-gutters > .col, .no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto { position: relative; width: 100%; padding-right: 15px; padding-left: 15px; }
.col { flex-basis: 0; flex-grow: 1; max-width: 100%; }
.row-cols-1 > * { flex: 0 0 100%; max-width: 100%; }
.row-cols-2 > * { flex: 0 0 50%; max-width: 50%; }
.row-cols-3 > * { flex: 0 0 33.3333%; max-width: 33.3333%; }
.row-cols-4 > * { flex: 0 0 25%; max-width: 25%; }
.row-cols-5 > * { flex: 0 0 20%; max-width: 20%; }
.row-cols-6 > * { flex: 0 0 16.6667%; max-width: 16.6667%; }
.col-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
.col-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
.col-2 { flex: 0 0 16.6667%; max-width: 16.6667%; }
.col-3 { flex: 0 0 25%; max-width: 25%; }
.col-4 { flex: 0 0 33.3333%; max-width: 33.3333%; }
.col-5 { flex: 0 0 41.6667%; max-width: 41.6667%; }
.col-6 { flex: 0 0 50%; max-width: 50%; }
.col-7 { flex: 0 0 58.3333%; max-width: 58.3333%; }
.col-8 { flex: 0 0 66.6667%; max-width: 66.6667%; }
.col-9 { flex: 0 0 75%; max-width: 75%; }
.col-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
.col-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
.col-12 { flex: 0 0 100%; max-width: 100%; }
.order-first { order: -1; }
.order-last { order: 13; }
.order-0 { order: 0; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }
.order-4 { order: 4; }
.order-5 { order: 5; }
.order-6 { order: 6; }
.order-7 { order: 7; }
.order-8 { order: 8; }
.order-9 { order: 9; }
.order-10 { order: 10; }
.order-11 { order: 11; }
.order-12 { order: 12; }
.offset-1 { margin-left: 8.33333%; }
.offset-2 { margin-left: 16.6667%; }
.offset-3 { margin-left: 25%; }
.offset-4 { margin-left: 33.3333%; }
.offset-5 { margin-left: 41.6667%; }
.offset-6 { margin-left: 50%; }
.offset-7 { margin-left: 58.3333%; }
.offset-8 { margin-left: 66.6667%; }
.offset-9 { margin-left: 75%; }
.offset-10 { margin-left: 83.3333%; }
.offset-11 { margin-left: 91.6667%; }
@media (min-width: 576px) {
  .col-sm { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-sm-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-sm-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-sm-3 > * { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .row-cols-sm-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-sm-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-sm-6 > * { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-sm-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-sm-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-sm-2 { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .col-sm-5 { flex: 0 0 41.6667%; max-width: 41.6667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.3333%; max-width: 58.3333%; }
  .col-sm-8 { flex: 0 0 66.6667%; max-width: 66.6667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
  .col-sm-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
  .order-sm-first { order: -1; }
  .order-sm-last { order: 13; }
  .order-sm-0 { order: 0; }
  .order-sm-1 { order: 1; }
  .order-sm-2 { order: 2; }
  .order-sm-3 { order: 3; }
  .order-sm-4 { order: 4; }
  .order-sm-5 { order: 5; }
  .order-sm-6 { order: 6; }
  .order-sm-7 { order: 7; }
  .order-sm-8 { order: 8; }
  .order-sm-9 { order: 9; }
  .order-sm-10 { order: 10; }
  .order-sm-11 { order: 11; }
  .order-sm-12 { order: 12; }
  .offset-sm-0 { margin-left: 0; }
  .offset-sm-1 { margin-left: 8.33333%; }
  .offset-sm-2 { margin-left: 16.6667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.3333%; }
  .offset-sm-5 { margin-left: 41.6667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.3333%; }
  .offset-sm-8 { margin-left: 66.6667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.3333%; }
  .offset-sm-11 { margin-left: 91.6667%; }
}
@media (min-width: 768px) {
  .col-md { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-md-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-md-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-md-3 > * { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .row-cols-md-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-md-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-md-6 > * { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-md-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-md-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-md-2 { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .col-md-5 { flex: 0 0 41.6667%; max-width: 41.6667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.3333%; max-width: 58.3333%; }
  .col-md-8 { flex: 0 0 66.6667%; max-width: 66.6667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
  .col-md-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
  .order-md-first { order: -1; }
  .order-md-last { order: 13; }
  .order-md-0 { order: 0; }
  .order-md-1 { order: 1; }
  .order-md-2 { order: 2; }
  .order-md-3 { order: 3; }
  .order-md-4 { order: 4; }
  .order-md-5 { order: 5; }
  .order-md-6 { order: 6; }
  .order-md-7 { order: 7; }
  .order-md-8 { order: 8; }
  .order-md-9 { order: 9; }
  .order-md-10 { order: 10; }
  .order-md-11 { order: 11; }
  .order-md-12 { order: 12; }
  .offset-md-0 { margin-left: 0; }
  .offset-md-1 { margin-left: 8.33333%; }
  .offset-md-2 { margin-left: 16.6667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.3333%; }
  .offset-md-5 { margin-left: 41.6667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.3333%; }
  .offset-md-8 { margin-left: 66.6667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.3333%; }
  .offset-md-11 { margin-left: 91.6667%; }
}
@media (min-width: 992px) {
  .col-lg { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-lg-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-lg-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-lg-3 > * { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .row-cols-lg-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-lg-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-lg-6 > * { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-lg-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-lg-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-lg-2 { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .col-lg-5 { flex: 0 0 41.6667%; max-width: 41.6667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.3333%; max-width: 58.3333%; }
  .col-lg-8 { flex: 0 0 66.6667%; max-width: 66.6667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
  .col-lg-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
  .order-lg-first { order: -1; }
  .order-lg-last { order: 13; }
  .order-lg-0 { order: 0; }
  .order-lg-1 { order: 1; }
  .order-lg-2 { order: 2; }
  .order-lg-3 { order: 3; }
  .order-lg-4 { order: 4; }
  .order-lg-5 { order: 5; }
  .order-lg-6 { order: 6; }
  .order-lg-7 { order: 7; }
  .order-lg-8 { order: 8; }
  .order-lg-9 { order: 9; }
  .order-lg-10 { order: 10; }
  .order-lg-11 { order: 11; }
  .order-lg-12 { order: 12; }
  .offset-lg-0 { margin-left: 0; }
  .offset-lg-1 { margin-left: 8.33333%; }
  .offset-lg-2 { margin-left: 16.6667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.3333%; }
  .offset-lg-5 { margin-left: 41.6667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.3333%; }
  .offset-lg-8 { margin-left: 66.6667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.3333%; }
  .offset-lg-11 { margin-left: 91.6667%; }
}
@media (min-width: 1200px) {
  .col-xl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-xl-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-xl-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-xl-3 > * { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .row-cols-xl-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-xl-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-xl-6 > * { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-xl-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-xl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-xl-2 { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .col-xl-5 { flex: 0 0 41.6667%; max-width: 41.6667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.3333%; max-width: 58.3333%; }
  .col-xl-8 { flex: 0 0 66.6667%; max-width: 66.6667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
  .col-xl-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
  .order-xl-first { order: -1; }
  .order-xl-last { order: 13; }
  .order-xl-0 { order: 0; }
  .order-xl-1 { order: 1; }
  .order-xl-2 { order: 2; }
  .order-xl-3 { order: 3; }
  .order-xl-4 { order: 4; }
  .order-xl-5 { order: 5; }
  .order-xl-6 { order: 6; }
  .order-xl-7 { order: 7; }
  .order-xl-8 { order: 8; }
  .order-xl-9 { order: 9; }
  .order-xl-10 { order: 10; }
  .order-xl-11 { order: 11; }
  .order-xl-12 { order: 12; }
  .offset-xl-0 { margin-left: 0; }
  .offset-xl-1 { margin-left: 8.33333%; }
  .offset-xl-2 { margin-left: 16.6667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.3333%; }
  .offset-xl-5 { margin-left: 41.6667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.3333%; }
  .offset-xl-8 { margin-left: 66.6667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.3333%; }
  .offset-xl-11 { margin-left: 91.6667%; }
}
.table { width: 100%; margin-bottom: 1rem; color: rgb(33, 37, 41); }
.table th, .table td { padding: 0.75rem; vertical-align: top; border-top: 1px solid rgb(222, 226, 230); }
.table thead th { vertical-align: bottom; border-bottom: 2px solid rgb(222, 226, 230); }
.table tbody + tbody { border-top: 2px solid rgb(222, 226, 230); }
.table-sm th, .table-sm td { padding: 0.3rem; }
.table-bordered { border: 1px solid rgb(222, 226, 230); }
.table-bordered th, .table-bordered td { border: 1px solid rgb(222, 226, 230); }
.table-bordered thead th, .table-bordered thead td { border-bottom-width: 2px; }
.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody { border: 0; }
.table-striped tbody tr:nth-of-type(2n+1) { background-color: rgba(0, 0, 0, 0.05); }
.table-hover tbody tr:hover { color: rgb(33, 37, 41); background-color: rgba(0, 0, 0, 0.075); }
.table-primary, .table-primary > th, .table-primary > td { background-color: rgb(190, 189, 209); }
.table-primary th, .table-primary td, .table-primary thead th, .table-primary tbody + tbody { border-color: rgb(135, 132, 170); }
.table-hover .table-primary:hover { background-color: rgb(175, 174, 199); }
.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th { background-color: rgb(175, 174, 199); }
.table-secondary, .table-secondary > th, .table-secondary > td { background-color: rgb(232, 232, 232); }
.table-secondary th, .table-secondary td, .table-secondary thead th, .table-secondary tbody + tbody { border-color: rgb(212, 212, 212); }
.table-hover .table-secondary:hover { background-color: rgb(219, 219, 219); }
.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th { background-color: rgb(219, 219, 219); }
.table-success, .table-success > th, .table-success > td { background-color: rgb(195, 230, 203); }
.table-success th, .table-success td, .table-success thead th, .table-success tbody + tbody { border-color: rgb(143, 209, 158); }
.table-hover .table-success:hover { background-color: rgb(177, 223, 187); }
.table-hover .table-success:hover > td, .table-hover .table-success:hover > th { background-color: rgb(177, 223, 187); }
.table-info, .table-info > th, .table-info > td { background-color: rgb(190, 229, 235); }
.table-info th, .table-info td, .table-info thead th, .table-info tbody + tbody { border-color: rgb(134, 207, 218); }
.table-hover .table-info:hover { background-color: rgb(171, 221, 229); }
.table-hover .table-info:hover > td, .table-hover .table-info:hover > th { background-color: rgb(171, 221, 229); }
.table-warning, .table-warning > th, .table-warning > td { background-color: rgb(255, 238, 186); }
.table-warning th, .table-warning td, .table-warning thead th, .table-warning tbody + tbody { border-color: rgb(255, 223, 126); }
.table-hover .table-warning:hover { background-color: rgb(255, 232, 161); }
.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th { background-color: rgb(255, 232, 161); }
.table-danger, .table-danger > th, .table-danger > td { background-color: rgb(245, 198, 203); }
.table-danger th, .table-danger td, .table-danger thead th, .table-danger tbody + tbody { border-color: rgb(237, 150, 158); }
.table-hover .table-danger:hover { background-color: rgb(241, 176, 183); }
.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th { background-color: rgb(241, 176, 183); }
.table-light, .table-light > th, .table-light > td { background-color: rgb(251, 251, 251); }
.table-light th, .table-light td, .table-light thead th, .table-light tbody + tbody { border-color: rgb(247, 247, 247); }
.table-hover .table-light:hover { background-color: rgb(238, 238, 238); }
.table-hover .table-light:hover > td, .table-hover .table-light:hover > th { background-color: rgb(238, 238, 238); }
.table-dark, .table-dark > th, .table-dark > td { background-color: rgb(199, 199, 199); }
.table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody { border-color: rgb(150, 150, 150); }
.table-hover .table-dark:hover { background-color: rgb(186, 186, 186); }
.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th { background-color: rgb(186, 186, 186); }
.table-tertiary, .table-tertiary > th, .table-tertiary > td { background-color: rgb(212, 212, 221); }
.table-tertiary th, .table-tertiary td, .table-tertiary thead th, .table-tertiary tbody + tbody { border-color: rgb(174, 174, 193); }
.table-hover .table-tertiary:hover { background-color: rgb(198, 198, 210); }
.table-hover .table-tertiary:hover > td, .table-hover .table-tertiary:hover > th { background-color: rgb(198, 198, 210); }
.table-active, .table-active > th, .table-active > td { background-color: rgba(0, 0, 0, 0.075); }
.table-hover .table-active:hover { background-color: rgba(0, 0, 0, 0.075); }
.table-hover .table-active:hover > td, .table-hover .table-active:hover > th { background-color: rgba(0, 0, 0, 0.075); }
.table .thead-dark th { color: rgb(255, 255, 255); background-color: rgb(52, 58, 64); border-color: rgb(69, 77, 85); }
.table .thead-light th { color: rgb(73, 80, 87); background-color: rgb(233, 236, 239); border-color: rgb(222, 226, 230); }
.table-dark { color: rgb(255, 255, 255); background-color: rgb(52, 58, 64); }
.table-dark th, .table-dark td, .table-dark thead th { border-color: rgb(69, 77, 85); }
.table-dark.table-bordered { border: 0; }
.table-dark.table-striped tbody tr:nth-of-type(2n+1) { background-color: rgba(255, 255, 255, 0.05); }
.table-dark.table-hover tbody tr:hover { color: rgb(255, 255, 255); background-color: rgba(255, 255, 255, 0.075); }
@media (max-width: 575.98px) {
  .table-responsive-sm { display: block; width: 100%; overflow-x: auto; }
  .table-responsive-sm > .table-bordered { border: 0; }
}
@media (max-width: 767.98px) {
  .table-responsive-md { display: block; width: 100%; overflow-x: auto; }
  .table-responsive-md > .table-bordered { border: 0; }
}
@media (max-width: 991.98px) {
  .table-responsive-lg { display: block; width: 100%; overflow-x: auto; }
  .table-responsive-lg > .table-bordered { border: 0; }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl { display: block; width: 100%; overflow-x: auto; }
  .table-responsive-xl > .table-bordered { border: 0; }
}
.table-responsive { display: block; width: 100%; overflow-x: auto; }
.table-responsive > .table-bordered { border: 0; }
.form-control { display: block; width: 100%; height: calc(1.5em + 0.75rem + 2px); padding: 0.375rem 0.75rem; font-size: 1rem; font-weight: 400; line-height: 1.5; color: rgb(73, 80, 87); background-color: rgb(255, 255, 255); background-clip: padding-box; border: 1px solid rgb(206, 212, 218); border-radius: 0.25rem; transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s; }
@media (prefers-reduced-motion: reduce) {
  .form-control { transition: none 0s ease 0s; }
}
.form-control:focus { color: rgb(73, 80, 87); background-color: rgb(255, 255, 255); border-color: rgb(52, 41, 198); outline: 0; box-shadow: rgba(24, 19, 92, 0.25) 0 0 0 0.2rem; }
.form-control::placeholder { color: rgb(108, 117, 125); opacity: 1; }
.form-control:disabled, .form-control[readonly] { background-color: rgb(233, 236, 239); opacity: 1; }
input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control { appearance: none; }
.form-control-file, .form-control-range { display: block; width: 100%; }
.col-form-label { padding-top: calc(0.375rem + 1px); padding-bottom: calc(0.375rem + 1px); margin-bottom: 0; font-size: inherit; line-height: 1.5; }
.col-form-label-lg { padding-top: calc(0.5rem + 1px); padding-bottom: calc(0.5rem + 1px); font-size: 1.25rem; line-height: 1.5; }
.col-form-label-sm { padding-top: calc(0.25rem + 1px); padding-bottom: calc(0.25rem + 1px); font-size: 0.875rem; line-height: 1.5; }
.form-control-plaintext { display: block; width: 100%; padding: 0.375rem 0; margin-bottom: 0; font-size: 1rem; line-height: 1.5; color: rgb(33, 37, 41); background-color: transparent; border-style: solid; border-color: transparent; border-image: initial; border-width: 1px 0; }
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg { padding-right: 0; padding-left: 0; }
.form-control-sm { height: calc(1.5em + 0.5rem + 2px); padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; border-radius: 0.2rem; }
.form-control-lg { height: calc(1.5em + 1rem + 2px); padding: 0.5rem 1rem; font-size: 1.25rem; line-height: 1.5; border-radius: 0.3rem; }
select.form-control[size], select.form-control[multiple] { height: auto; }
textarea.form-control { height: auto; }
.form-group { margin-bottom: 1rem; }
.form-text { display: block; margin-top: 0.25rem; }
.form-row { display: flex; flex-wrap: wrap; margin-right: -5px; margin-left: -5px; }
.form-row > .col, .form-row > [class*="col-"] { padding-right: 5px; padding-left: 5px; }
.form-check { position: relative; display: block; padding-left: 1.25rem; }
.form-check-input { position: absolute; margin-top: 0.3rem; margin-left: -1.25rem; }
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label { color: rgb(108, 117, 125); }
.form-check-label { margin-bottom: 0; }
.form-check-inline { display: inline-flex; align-items: center; padding-left: 0; margin-right: 0.75rem; }
.form-check-inline .form-check-input { position: static; margin-top: 0; margin-right: 0.3125rem; margin-left: 0; }
.valid-feedback { display: none; width: 100%; margin-top: 0.25rem; font-size: 80%; color: rgb(40, 167, 69); }
.valid-tooltip { position: absolute; top: 100%; left: 0; z-index: 5; display: none; max-width: 100%; padding: 0.25rem 0.5rem; margin-top: 0.1rem; font-size: 0.875rem; line-height: 1.5; color: rgb(255, 255, 255); background-color: rgba(40, 167, 69, 0.9); border-radius: 0.25rem; }
.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip { display: block; }
.was-validated .form-control:valid, .form-control.is-valid { border-color: rgb(40, 167, 69); padding-right: calc(1.5em + 0.75rem); background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); background-repeat: no-repeat; background-position: right calc(0.375em + 0.1875rem) center; background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
.was-validated .form-control:valid:focus, .form-control.is-valid:focus { border-color: rgb(40, 167, 69); box-shadow: rgba(40, 167, 69, 0.25) 0 0 0 0.2rem; }
.was-validated textarea.form-control:valid, textarea.form-control.is-valid { padding-right: calc(1.5em + 0.75rem); background-position: right calc(0.375em + 0.1875rem) top calc(0.375em + 0.1875rem); }
.was-validated .custom-select:valid, .custom-select.is-valid { border-color: rgb(40, 167, 69); padding-right: calc(0.75em + 2.3125rem); background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center / 8px 10px no-repeat, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") right 1.75rem center / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat rgb(255, 255, 255); }
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus { border-color: rgb(40, 167, 69); box-shadow: rgba(40, 167, 69, 0.25) 0 0 0 0.2rem; }
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label { color: rgb(40, 167, 69); }
.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip { display: block; }
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label { color: rgb(40, 167, 69); }
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before { border-color: rgb(40, 167, 69); }
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before { border-color: rgb(52, 206, 87); background-color: rgb(52, 206, 87); }
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before { box-shadow: rgba(40, 167, 69, 0.25) 0 0 0 0.2rem; }
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before { border-color: rgb(40, 167, 69); }
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label { border-color: rgb(40, 167, 69); }
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label { border-color: rgb(40, 167, 69); box-shadow: rgba(40, 167, 69, 0.25) 0 0 0 0.2rem; }
.invalid-feedback { display: none; width: 100%; margin-top: 0.25rem; font-size: 80%; color: rgb(220, 53, 69); }
.invalid-tooltip { position: absolute; top: 100%; left: 0; z-index: 5; display: none; max-width: 100%; padding: 0.25rem 0.5rem; margin-top: 0.1rem; font-size: 0.875rem; line-height: 1.5; color: rgb(255, 255, 255); background-color: rgba(220, 53, 69, 0.9); border-radius: 0.25rem; }
.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip { display: block; }
.was-validated .form-control:invalid, .form-control.is-invalid { border-color: rgb(220, 53, 69); padding-right: calc(1.5em + 0.75rem); background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e"); background-repeat: no-repeat; background-position: right calc(0.375em + 0.1875rem) center; background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus { border-color: rgb(220, 53, 69); box-shadow: rgba(220, 53, 69, 0.25) 0 0 0 0.2rem; }
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid { padding-right: calc(1.5em + 0.75rem); background-position: right calc(0.375em + 0.1875rem) top calc(0.375em + 0.1875rem); }
.was-validated .custom-select:invalid, .custom-select.is-invalid { border-color: rgb(220, 53, 69); padding-right: calc(0.75em + 2.3125rem); background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center / 8px 10px no-repeat, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") right 1.75rem center / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat rgb(255, 255, 255); }
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus { border-color: rgb(220, 53, 69); box-shadow: rgba(220, 53, 69, 0.25) 0 0 0 0.2rem; }
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label { color: rgb(220, 53, 69); }
.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip { display: block; }
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label { color: rgb(220, 53, 69); }
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before { border-color: rgb(220, 53, 69); }
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before { border-color: rgb(228, 96, 109); background-color: rgb(228, 96, 109); }
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before { box-shadow: rgba(220, 53, 69, 0.25) 0 0 0 0.2rem; }
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before { border-color: rgb(220, 53, 69); }
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label { border-color: rgb(220, 53, 69); }
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label { border-color: rgb(220, 53, 69); box-shadow: rgba(220, 53, 69, 0.25) 0 0 0 0.2rem; }
.form-inline { display: flex; flex-flow: row wrap; align-items: center; }
.form-inline .form-check { width: 100%; }
@media (min-width: 576px) {
  .form-inline label { display: flex; align-items: center; justify-content: center; margin-bottom: 0; }
  .form-inline .form-group { display: flex; flex: 0 0 auto; flex-flow: row wrap; align-items: center; margin-bottom: 0; }
  .form-inline .form-control { display: inline-block; width: auto; vertical-align: middle; }
  .form-inline .form-control-plaintext { display: inline-block; }
  .form-inline .input-group, .form-inline .custom-select { width: auto; }
  .form-inline .form-check { display: flex; align-items: center; justify-content: center; width: auto; padding-left: 0; }
  .form-inline .form-check-input { position: relative; flex-shrink: 0; margin-top: 0; margin-right: 0.25rem; margin-left: 0; }
  .form-inline .custom-control { align-items: center; justify-content: center; }
  .form-inline .custom-control-label { margin-bottom: 0; }
}
.btn { display: inline-block; font-weight: 400; color: rgb(33, 37, 41); text-align: center; vertical-align: middle; user-select: none; background-color: transparent; border: 1px solid transparent; padding: 0.375rem 0.75rem; font-size: 1rem; line-height: 1.5; border-radius: 0.25rem; transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s; }
@media (prefers-reduced-motion: reduce) {
  .btn { transition: none 0s ease 0s; }
}
.btn:hover { color: rgb(33, 37, 41); text-decoration: none; }
.btn:focus, .btn.focus { outline: 0; box-shadow: rgba(24, 19, 92, 0.25) 0 0 0 0.2rem; }
.btn.disabled, .btn:disabled { opacity: 0.65; }
.btn:not(:disabled):not(.disabled) { cursor: pointer; }
a.btn.disabled, fieldset:disabled a.btn { pointer-events: none; }
.btn-primary { color: rgb(255, 255, 255); background-color: rgb(24, 19, 92); border-color: rgb(24, 19, 92); }
.btn-primary:hover { color: rgb(255, 255, 255); background-color: rgb(16, 12, 60); border-color: rgb(13, 10, 50); }
.btn-primary:focus, .btn-primary.focus { color: rgb(255, 255, 255); background-color: rgb(16, 12, 60); border-color: rgb(13, 10, 50); box-shadow: rgba(59, 54, 116, 0.5) 0 0 0 0.2rem; }
.btn-primary.disabled, .btn-primary:disabled { color: rgb(255, 255, 255); background-color: rgb(24, 19, 92); border-color: rgb(24, 19, 92); }
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(13, 10, 50); border-color: rgb(10, 8, 39); }
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus { box-shadow: rgba(59, 54, 116, 0.5) 0 0 0 0.2rem; }
.btn-secondary { color: rgb(33, 37, 41); background-color: rgb(173, 173, 173); border-color: rgb(173, 173, 173); }
.btn-secondary:hover { color: rgb(33, 37, 41); background-color: rgb(154, 154, 154); border-color: rgb(148, 148, 148); }
.btn-secondary:focus, .btn-secondary.focus { color: rgb(33, 37, 41); background-color: rgb(154, 154, 154); border-color: rgb(148, 148, 148); box-shadow: rgba(152, 153, 153, 0.5) 0 0 0 0.2rem; }
.btn-secondary.disabled, .btn-secondary:disabled { color: rgb(33, 37, 41); background-color: rgb(173, 173, 173); border-color: rgb(173, 173, 173); }
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(148, 148, 148); border-color: rgb(141, 141, 141); }
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus { box-shadow: rgba(152, 153, 153, 0.5) 0 0 0 0.2rem; }
.btn-success { color: rgb(255, 255, 255); background-color: rgb(40, 167, 69); border-color: rgb(40, 167, 69); }
.btn-success:hover { color: rgb(255, 255, 255); background-color: rgb(33, 136, 56); border-color: rgb(30, 126, 52); }
.btn-success:focus, .btn-success.focus { color: rgb(255, 255, 255); background-color: rgb(33, 136, 56); border-color: rgb(30, 126, 52); box-shadow: rgba(72, 180, 97, 0.5) 0 0 0 0.2rem; }
.btn-success.disabled, .btn-success:disabled { color: rgb(255, 255, 255); background-color: rgb(40, 167, 69); border-color: rgb(40, 167, 69); }
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(30, 126, 52); border-color: rgb(28, 116, 48); }
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus { box-shadow: rgba(72, 180, 97, 0.5) 0 0 0 0.2rem; }
.btn-info { color: rgb(255, 255, 255); background-color: rgb(23, 162, 184); border-color: rgb(23, 162, 184); }
.btn-info:hover { color: rgb(255, 255, 255); background-color: rgb(19, 132, 150); border-color: rgb(17, 122, 139); }
.btn-info:focus, .btn-info.focus { color: rgb(255, 255, 255); background-color: rgb(19, 132, 150); border-color: rgb(17, 122, 139); box-shadow: rgba(58, 176, 195, 0.5) 0 0 0 0.2rem; }
.btn-info.disabled, .btn-info:disabled { color: rgb(255, 255, 255); background-color: rgb(23, 162, 184); border-color: rgb(23, 162, 184); }
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(17, 122, 139); border-color: rgb(16, 112, 127); }
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus { box-shadow: rgba(58, 176, 195, 0.5) 0 0 0 0.2rem; }
.btn-warning { color: rgb(33, 37, 41); background-color: rgb(255, 193, 7); border-color: rgb(255, 193, 7); }
.btn-warning:hover { color: rgb(33, 37, 41); background-color: rgb(224, 168, 0); border-color: rgb(211, 158, 0); }
.btn-warning:focus, .btn-warning.focus { color: rgb(33, 37, 41); background-color: rgb(224, 168, 0); border-color: rgb(211, 158, 0); box-shadow: rgba(222, 170, 12, 0.5) 0 0 0 0.2rem; }
.btn-warning.disabled, .btn-warning:disabled { color: rgb(33, 37, 41); background-color: rgb(255, 193, 7); border-color: rgb(255, 193, 7); }
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle { color: rgb(33, 37, 41); background-color: rgb(211, 158, 0); border-color: rgb(198, 149, 0); }
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus { box-shadow: rgba(222, 170, 12, 0.5) 0 0 0 0.2rem; }
.btn-danger { color: rgb(255, 255, 255); background-color: rgb(220, 53, 69); border-color: rgb(220, 53, 69); }
.btn-danger:hover { color: rgb(255, 255, 255); background-color: rgb(200, 35, 51); border-color: rgb(189, 33, 48); }
.btn-danger:focus, .btn-danger.focus { color: rgb(255, 255, 255); background-color: rgb(200, 35, 51); border-color: rgb(189, 33, 48); box-shadow: rgba(225, 83, 97, 0.5) 0 0 0 0.2rem; }
.btn-danger.disabled, .btn-danger:disabled { color: rgb(255, 255, 255); background-color: rgb(220, 53, 69); border-color: rgb(220, 53, 69); }
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(189, 33, 48); border-color: rgb(178, 31, 45); }
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus { box-shadow: rgba(225, 83, 97, 0.5) 0 0 0 0.2rem; }
.btn-light { color: rgb(33, 37, 41); background-color: rgb(239, 239, 239); border-color: rgb(239, 239, 239); }
.btn-light:hover { color: rgb(33, 37, 41); background-color: gainsboro; border-color: rgb(214, 214, 214); }
.btn-light:focus, .btn-light.focus { color: rgb(33, 37, 41); background-color: gainsboro; border-color: rgb(214, 214, 214); box-shadow: rgba(208, 209, 209, 0.5) 0 0 0 0.2rem; }
.btn-light.disabled, .btn-light:disabled { color: rgb(33, 37, 41); background-color: rgb(239, 239, 239); border-color: rgb(239, 239, 239); }
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle { color: rgb(33, 37, 41); background-color: rgb(214, 214, 214); border-color: rgb(207, 207, 207); }
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus { box-shadow: rgba(208, 209, 209, 0.5) 0 0 0 0.2rem; }
.btn-dark { color: rgb(255, 255, 255); background-color: rgb(54, 54, 54); border-color: rgb(54, 54, 54); }
.btn-dark:hover { color: rgb(255, 255, 255); background-color: rgb(35, 35, 35); border-color: rgb(29, 29, 29); }
.btn-dark:focus, .btn-dark.focus { color: rgb(255, 255, 255); background-color: rgb(35, 35, 35); border-color: rgb(29, 29, 29); box-shadow: rgba(84, 84, 84, 0.5) 0 0 0 0.2rem; }
.btn-dark.disabled, .btn-dark:disabled { color: rgb(255, 255, 255); background-color: rgb(54, 54, 54); border-color: rgb(54, 54, 54); }
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(29, 29, 29); border-color: rgb(22, 22, 22); }
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus { box-shadow: rgba(84, 84, 84, 0.5) 0 0 0 0.2rem; }
.btn-tertiary { color: rgb(255, 255, 255); background-color: rgb(100, 100, 135); border-color: rgb(100, 100, 135); }
.btn-tertiary:hover { color: rgb(255, 255, 255); background-color: rgb(84, 84, 113); border-color: rgb(78, 78, 106); }
.btn-tertiary:focus, .btn-tertiary.focus { color: rgb(255, 255, 255); background-color: rgb(84, 84, 113); border-color: rgb(78, 78, 106); box-shadow: rgba(123, 123, 153, 0.5) 0 0 0 0.2rem; }
.btn-tertiary.disabled, .btn-tertiary:disabled { color: rgb(255, 255, 255); background-color: rgb(100, 100, 135); border-color: rgb(100, 100, 135); }
.btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active, .show > .btn-tertiary.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(78, 78, 106); border-color: rgb(73, 73, 98); }
.btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus, .show > .btn-tertiary.dropdown-toggle:focus { box-shadow: rgba(123, 123, 153, 0.5) 0 0 0 0.2rem; }
.btn-outline-primary { color: rgb(24, 19, 92); border-color: rgb(24, 19, 92); }
.btn-outline-primary:hover { color: rgb(255, 255, 255); background-color: rgb(24, 19, 92); border-color: rgb(24, 19, 92); }
.btn-outline-primary:focus, .btn-outline-primary.focus { box-shadow: rgba(24, 19, 92, 0.5) 0 0 0 0.2rem; }
.btn-outline-primary.disabled, .btn-outline-primary:disabled { color: rgb(24, 19, 92); background-color: transparent; }
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(24, 19, 92); border-color: rgb(24, 19, 92); }
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus { box-shadow: rgba(24, 19, 92, 0.5) 0 0 0 0.2rem; }
.btn-outline-secondary { color: rgb(173, 173, 173); border-color: rgb(173, 173, 173); }
.btn-outline-secondary:hover { color: rgb(33, 37, 41); background-color: rgb(173, 173, 173); border-color: rgb(173, 173, 173); }
.btn-outline-secondary:focus, .btn-outline-secondary.focus { box-shadow: rgba(173, 173, 173, 0.5) 0 0 0 0.2rem; }
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled { color: rgb(173, 173, 173); background-color: transparent; }
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle { color: rgb(33, 37, 41); background-color: rgb(173, 173, 173); border-color: rgb(173, 173, 173); }
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus { box-shadow: rgba(173, 173, 173, 0.5) 0 0 0 0.2rem; }
.btn-outline-success { color: rgb(40, 167, 69); border-color: rgb(40, 167, 69); }
.btn-outline-success:hover { color: rgb(255, 255, 255); background-color: rgb(40, 167, 69); border-color: rgb(40, 167, 69); }
.btn-outline-success:focus, .btn-outline-success.focus { box-shadow: rgba(40, 167, 69, 0.5) 0 0 0 0.2rem; }
.btn-outline-success.disabled, .btn-outline-success:disabled { color: rgb(40, 167, 69); background-color: transparent; }
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(40, 167, 69); border-color: rgb(40, 167, 69); }
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus { box-shadow: rgba(40, 167, 69, 0.5) 0 0 0 0.2rem; }
.btn-outline-info { color: rgb(23, 162, 184); border-color: rgb(23, 162, 184); }
.btn-outline-info:hover { color: rgb(255, 255, 255); background-color: rgb(23, 162, 184); border-color: rgb(23, 162, 184); }
.btn-outline-info:focus, .btn-outline-info.focus { box-shadow: rgba(23, 162, 184, 0.5) 0 0 0 0.2rem; }
.btn-outline-info.disabled, .btn-outline-info:disabled { color: rgb(23, 162, 184); background-color: transparent; }
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(23, 162, 184); border-color: rgb(23, 162, 184); }
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus { box-shadow: rgba(23, 162, 184, 0.5) 0 0 0 0.2rem; }
.btn-outline-warning { color: rgb(255, 193, 7); border-color: rgb(255, 193, 7); }
.btn-outline-warning:hover { color: rgb(33, 37, 41); background-color: rgb(255, 193, 7); border-color: rgb(255, 193, 7); }
.btn-outline-warning:focus, .btn-outline-warning.focus { box-shadow: rgba(255, 193, 7, 0.5) 0 0 0 0.2rem; }
.btn-outline-warning.disabled, .btn-outline-warning:disabled { color: rgb(255, 193, 7); background-color: transparent; }
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle { color: rgb(33, 37, 41); background-color: rgb(255, 193, 7); border-color: rgb(255, 193, 7); }
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus { box-shadow: rgba(255, 193, 7, 0.5) 0 0 0 0.2rem; }
.btn-outline-danger { color: rgb(220, 53, 69); border-color: rgb(220, 53, 69); }
.btn-outline-danger:hover { color: rgb(255, 255, 255); background-color: rgb(220, 53, 69); border-color: rgb(220, 53, 69); }
.btn-outline-danger:focus, .btn-outline-danger.focus { box-shadow: rgba(220, 53, 69, 0.5) 0 0 0 0.2rem; }
.btn-outline-danger.disabled, .btn-outline-danger:disabled { color: rgb(220, 53, 69); background-color: transparent; }
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(220, 53, 69); border-color: rgb(220, 53, 69); }
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus { box-shadow: rgba(220, 53, 69, 0.5) 0 0 0 0.2rem; }
.btn-outline-light { color: rgb(239, 239, 239); border-color: rgb(239, 239, 239); }
.btn-outline-light:hover { color: rgb(33, 37, 41); background-color: rgb(239, 239, 239); border-color: rgb(239, 239, 239); }
.btn-outline-light:focus, .btn-outline-light.focus { box-shadow: rgba(239, 239, 239, 0.5) 0 0 0 0.2rem; }
.btn-outline-light.disabled, .btn-outline-light:disabled { color: rgb(239, 239, 239); background-color: transparent; }
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle { color: rgb(33, 37, 41); background-color: rgb(239, 239, 239); border-color: rgb(239, 239, 239); }
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus { box-shadow: rgba(239, 239, 239, 0.5) 0 0 0 0.2rem; }
.btn-outline-dark { color: rgb(54, 54, 54); border-color: rgb(54, 54, 54); }
.btn-outline-dark:hover { color: rgb(255, 255, 255); background-color: rgb(54, 54, 54); border-color: rgb(54, 54, 54); }
.btn-outline-dark:focus, .btn-outline-dark.focus { box-shadow: rgba(54, 54, 54, 0.5) 0 0 0 0.2rem; }
.btn-outline-dark.disabled, .btn-outline-dark:disabled { color: rgb(54, 54, 54); background-color: transparent; }
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(54, 54, 54); border-color: rgb(54, 54, 54); }
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus { box-shadow: rgba(54, 54, 54, 0.5) 0 0 0 0.2rem; }
.btn-outline-tertiary { color: rgb(100, 100, 135); border-color: rgb(100, 100, 135); }
.btn-outline-tertiary:hover { color: rgb(255, 255, 255); background-color: rgb(100, 100, 135); border-color: rgb(100, 100, 135); }
.btn-outline-tertiary:focus, .btn-outline-tertiary.focus { box-shadow: rgba(100, 100, 135, 0.5) 0 0 0 0.2rem; }
.btn-outline-tertiary.disabled, .btn-outline-tertiary:disabled { color: rgb(100, 100, 135); background-color: transparent; }
.btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled).active, .show > .btn-outline-tertiary.dropdown-toggle { color: rgb(255, 255, 255); background-color: rgb(100, 100, 135); border-color: rgb(100, 100, 135); }
.btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-tertiary.dropdown-toggle:focus { box-shadow: rgba(100, 100, 135, 0.5) 0 0 0 0.2rem; }
.btn-link { font-weight: 400; color: rgb(24, 19, 92); text-decoration: none; }
.btn-link:hover { color: rgb(7, 6, 29); text-decoration: underline; }
.btn-link:focus, .btn-link.focus { text-decoration: underline; }
.btn-link:disabled, .btn-link.disabled { color: rgb(108, 117, 125); pointer-events: none; }
.btn-lg, .btn-group-lg > .btn { padding: 0.5rem 1rem; font-size: 1.25rem; line-height: 1.5; border-radius: 0.3rem; }
.btn-sm, .btn-group-sm > .btn { padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; border-radius: 0.2rem; }
.btn-block { display: block; width: 100%; }
.btn-block + .btn-block { margin-top: 0.5rem; }
input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block { width: 100%; }
.fade { transition: opacity 0.15s linear 0s; }
@media (prefers-reduced-motion: reduce) {
  .fade { transition: none 0s ease 0s; }
}
.fade:not(.show) { opacity: 0; }
.collapse:not(.show) { display: none; }
.collapsing { position: relative; height: 0; overflow: hidden; transition: height 0.35s ease 0s; }
@media (prefers-reduced-motion: reduce) {
  .collapsing { transition: none 0s ease 0s; }
}
.dropup, .dropright, .dropdown, .dropleft { position: relative; }
.dropdown-toggle { white-space: nowrap; }
.dropdown-toggle::after { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-width: 0.3em 0.3em 0; border-top-style: solid; border-top-color: initial; border-right-style: solid; border-right-color: transparent; border-bottom-style: initial; border-bottom-color: initial; border-left-style: solid; border-left-color: transparent; }
.dropdown-toggle:empty::after { margin-left: 0; }
.dropdown-menu { position: absolute; top: 100%; left: 0; z-index: 1000; display: none; float: left; min-width: 10rem; padding: 0.5rem 0; margin: 0.125rem 0 0; font-size: 1rem; color: rgb(33, 37, 41); text-align: left; list-style: none; background-color: rgb(255, 255, 255); background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.15); border-radius: 0.25rem; }
.dropdown-menu-left { right: auto; left: 0; }
.dropdown-menu-right { right: 0; left: auto; }
@media (min-width: 576px) {
  .dropdown-menu-sm-left { right: auto; left: 0; }
  .dropdown-menu-sm-right { right: 0; left: auto; }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left { right: auto; left: 0; }
  .dropdown-menu-md-right { right: 0; left: auto; }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left { right: auto; left: 0; }
  .dropdown-menu-lg-right { right: 0; left: auto; }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left { right: auto; left: 0; }
  .dropdown-menu-xl-right { right: 0; left: auto; }
}
.dropup .dropdown-menu { top: auto; bottom: 100%; margin-top: 0; margin-bottom: 0.125rem; }
.dropup .dropdown-toggle::after { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-width: 0 0.3em 0.3em; border-top-style: initial; border-top-color: initial; border-right-style: solid; border-right-color: transparent; border-bottom-style: solid; border-bottom-color: initial; border-left-style: solid; border-left-color: transparent; }
.dropup .dropdown-toggle:empty::after { margin-left: 0; }
.dropright .dropdown-menu { top: 0; right: auto; left: 100%; margin-top: 0; margin-left: 0.125rem; }
.dropright .dropdown-toggle::after { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-width: 0.3em 0 0.3em 0.3em; border-top-style: solid; border-top-color: transparent; border-right-style: initial; border-right-color: initial; border-bottom-style: solid; border-bottom-color: transparent; border-left-style: solid; border-left-color: initial; }
.dropright .dropdown-toggle:empty::after { margin-left: 0; }
.dropright .dropdown-toggle::after { vertical-align: 0; }
.dropleft .dropdown-menu { top: 0; right: 100%; left: auto; margin-top: 0; margin-right: 0.125rem; }
.dropleft .dropdown-toggle::after { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; }
.dropleft .dropdown-toggle::after { display: none; }
.dropleft .dropdown-toggle::before { display: inline-block; margin-right: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid transparent; border-right: 0.3em solid; border-bottom: 0.3em solid transparent; }
.dropleft .dropdown-toggle:empty::after { margin-left: 0; }
.dropleft .dropdown-toggle::before { vertical-align: 0; }
.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] { right: auto; bottom: auto; }
.dropdown-divider { height: 0; margin: 0.5rem 0; overflow: hidden; border-top: 1px solid rgb(233, 236, 239); }
.dropdown-item { display: block; width: 100%; padding: 0.25rem 1.5rem; clear: both; font-weight: 400; color: rgb(33, 37, 41); text-align: inherit; white-space: nowrap; background-color: transparent; border: 0; }
.dropdown-item:hover, .dropdown-item:focus { color: rgb(22, 24, 27); text-decoration: none; background-color: rgb(248, 249, 250); }
.dropdown-item.active, .dropdown-item:active { color: rgb(255, 255, 255); text-decoration: none; background-color: rgb(24, 19, 92); }
.dropdown-item.disabled, .dropdown-item:disabled { color: rgb(108, 117, 125); pointer-events: none; background-color: transparent; }
.dropdown-menu.show { display: block; }
.dropdown-header { display: block; padding: 0.5rem 1.5rem; margin-bottom: 0; font-size: 0.875rem; color: rgb(108, 117, 125); white-space: nowrap; }
.dropdown-item-text { display: block; padding: 0.25rem 1.5rem; color: rgb(33, 37, 41); }
.btn-group, .btn-group-vertical { position: relative; display: inline-flex; vertical-align: middle; }
.btn-group > .btn, .btn-group-vertical > .btn { position: relative; flex: 1 1 auto; }
.btn-group > .btn:hover, .btn-group-vertical > .btn:hover { z-index: 1; }
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active { z-index: 1; }
.btn-toolbar { display: flex; flex-wrap: wrap; justify-content: flex-start; }
.btn-toolbar .input-group { width: auto; }
.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) { margin-left: -1px; }
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn { border-top-right-radius: 0; border-bottom-right-radius: 0; }
.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn { border-top-left-radius: 0; border-bottom-left-radius: 0; }
.dropdown-toggle-split { padding-right: 0.5625rem; padding-left: 0.5625rem; }
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after { margin-left: 0; }
.dropleft .dropdown-toggle-split::before { margin-right: 0; }
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split { padding-right: 0.375rem; padding-left: 0.375rem; }
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split { padding-right: 0.75rem; padding-left: 0.75rem; }
.btn-group-vertical { flex-direction: column; align-items: flex-start; justify-content: center; }
.btn-group-vertical > .btn, .btn-group-vertical > .btn-group { width: 100%; }
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) { margin-top: -1px; }
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn { border-bottom-right-radius: 0; border-bottom-left-radius: 0; }
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn { border-top-left-radius: 0; border-top-right-radius: 0; }
.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn { margin-bottom: 0; }
.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"] { position: absolute; clip: rect(0px, 0, 0, 0); pointer-events: none; }
.input-group { position: relative; display: flex; flex-wrap: wrap; align-items: stretch; width: 100%; }
.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file { position: relative; flex: 1 1 auto; width: 1%; min-width: 0; margin-bottom: 0; }
.input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .form-control-plaintext + .form-control, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file { margin-left: -1px; }
.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label { z-index: 3; }
.input-group > .custom-file .custom-file-input:focus { z-index: 4; }
.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) { border-top-right-radius: 0; border-bottom-right-radius: 0; }
.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) { border-top-left-radius: 0; border-bottom-left-radius: 0; }
.input-group > .custom-file { display: flex; align-items: center; }
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after { border-top-right-radius: 0; border-bottom-right-radius: 0; }
.input-group > .custom-file:not(:first-child) .custom-file-label { border-top-left-radius: 0; border-bottom-left-radius: 0; }
.input-group-prepend, .input-group-append { display: flex; }
.input-group-prepend .btn, .input-group-append .btn { position: relative; z-index: 2; }
.input-group-prepend .btn:focus, .input-group-append .btn:focus { z-index: 3; }
.input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn { margin-left: -1px; }
.input-group-prepend { margin-right: -1px; }
.input-group-append { margin-left: -1px; }
.input-group-text { display: flex; align-items: center; padding: 0.375rem 0.75rem; margin-bottom: 0; font-size: 1rem; font-weight: 400; line-height: 1.5; color: rgb(73, 80, 87); text-align: center; white-space: nowrap; background-color: rgb(233, 236, 239); border: 1px solid rgb(206, 212, 218); border-radius: 0.25rem; }
.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] { margin-top: 0; }
.input-group-lg > .form-control:not(textarea), .input-group-lg > .custom-select { height: calc(1.5em + 1rem + 2px); }
.input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn { padding: 0.5rem 1rem; font-size: 1.25rem; line-height: 1.5; border-radius: 0.3rem; }
.input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select { height: calc(1.5em + 0.5rem + 2px); }
.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn { padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; border-radius: 0.2rem; }
.input-group-lg > .custom-select, .input-group-sm > .custom-select { padding-right: 1.75rem; }
.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) { border-top-right-radius: 0; border-bottom-right-radius: 0; }
.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) { border-top-left-radius: 0; border-bottom-left-radius: 0; }
.custom-control { position: relative; z-index: 1; display: block; min-height: 1.5rem; padding-left: 1.5rem; -webkit-print-color-adjust: exact; }
.custom-control-inline { display: inline-flex; margin-right: 1rem; }
.custom-control-input { position: absolute; left: 0; z-index: -1; width: 1rem; height: 1.25rem; opacity: 0; }
.custom-control-input:checked ~ .custom-control-label::before { color: rgb(255, 255, 255); border-color: rgb(24, 19, 92); background-color: rgb(24, 19, 92); }
.custom-control-input:focus ~ .custom-control-label::before { box-shadow: rgba(24, 19, 92, 0.25) 0 0 0 0.2rem; }
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before { border-color: rgb(52, 41, 198); }
.custom-control-input:not(:disabled):active ~ .custom-control-label::before { color: rgb(255, 255, 255); background-color: rgb(82, 72, 217); border-color: rgb(82, 72, 217); }
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label { color: rgb(108, 117, 125); }
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before { background-color: rgb(233, 236, 239); }
.custom-control-label { position: relative; margin-bottom: 0; vertical-align: top; }
.custom-control-label::before { position: absolute; top: 0.25rem; left: -1.5rem; display: block; width: 1rem; height: 1rem; pointer-events: none; content: ""; background-color: rgb(255, 255, 255); border: 1px solid rgb(173, 181, 189); }
.custom-control-label::after { position: absolute; top: 0.25rem; left: -1.5rem; display: block; width: 1rem; height: 1rem; content: ""; background: 50% center / 50% 50% no-repeat; }
.custom-checkbox .custom-control-label::before { border-radius: 0.25rem; }
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before { border-color: rgb(24, 19, 92); background-color: rgb(24, 19, 92); }
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before { background-color: rgba(24, 19, 92, 0.5); }
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before { background-color: rgba(24, 19, 92, 0.5); }
.custom-radio .custom-control-label::before { border-radius: 50%; }
.custom-radio .custom-control-input:checked ~ .custom-control-label::after { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before { background-color: rgba(24, 19, 92, 0.5); }
.custom-switch { padding-left: 2.25rem; }
.custom-switch .custom-control-label::before { left: -2.25rem; width: 1.75rem; pointer-events: all; border-radius: 0.5rem; }
.custom-switch .custom-control-label::after { top: calc(0.25rem + 2px); left: calc(-2.25rem + 2px); width: calc(1rem - 4px); height: calc(1rem - 4px); background-color: rgb(173, 181, 189); border-radius: 0.5rem; transition: transform 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s; }
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after { transition: none 0s ease 0s; }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after { background-color: rgb(255, 255, 255); transform: translateX(0.75rem); }
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before { background-color: rgba(24, 19, 92, 0.5); }
.custom-select { display: inline-block; width: 100%; height: calc(1.5em + 0.75rem + 2px); padding: 0.375rem 1.75rem 0.375rem 0.75rem; font-size: 1rem; font-weight: 400; line-height: 1.5; color: rgb(73, 80, 87); vertical-align: middle; background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center / 8px 10px no-repeat rgb(255, 255, 255); border: 1px solid rgb(206, 212, 218); border-radius: 0.25rem; appearance: none; }
.custom-select:focus { border-color: rgb(52, 41, 198); outline: 0; box-shadow: rgba(24, 19, 92, 0.25) 0 0 0 0.2rem; }
.custom-select[multiple], .custom-select[size]:not([size="1"]) { height: auto; padding-right: 0.75rem; background-image: none; }
.custom-select:disabled { color: rgb(108, 117, 125); background-color: rgb(233, 236, 239); }
.custom-select-sm { height: calc(1.5em + 0.5rem + 2px); padding-top: 0.25rem; padding-bottom: 0.25rem; padding-left: 0.5rem; font-size: 0.875rem; }
.custom-select-lg { height: calc(1.5em + 1rem + 2px); padding-top: 0.5rem; padding-bottom: 0.5rem; padding-left: 1rem; font-size: 1.25rem; }
.custom-file { position: relative; display: inline-block; width: 100%; height: calc(1.5em + 0.75rem + 2px); margin-bottom: 0; }
.custom-file-input { position: relative; z-index: 2; width: 100%; height: calc(1.5em + 0.75rem + 2px); margin: 0; opacity: 0; }
.custom-file-input:focus ~ .custom-file-label { border-color: rgb(52, 41, 198); box-shadow: rgba(24, 19, 92, 0.25) 0 0 0 0.2rem; }
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label { background-color: rgb(233, 236, 239); }
.custom-file-input:lang(en) ~ .custom-file-label::after { content: "Browse"; }
.custom-file-input ~ .custom-file-label[data-browse]::after { content: attr(data-browse); }
.custom-file-label { position: absolute; top: 0; right: 0; left: 0; z-index: 1; height: calc(1.5em + 0.75rem + 2px); padding: 0.375rem 0.75rem; font-weight: 400; line-height: 1.5; color: rgb(73, 80, 87); background-color: rgb(255, 255, 255); border: 1px solid rgb(206, 212, 218); border-radius: 0.25rem; }
.custom-file-label::after { position: absolute; top: 0; right: 0; bottom: 0; z-index: 3; display: block; height: calc(1.5em + 0.75rem); padding: 0.375rem 0.75rem; line-height: 1.5; color: rgb(73, 80, 87); content: "Browse"; background-color: rgb(233, 236, 239); border-left: inherit; border-radius: 0 0.25rem 0.25rem 0; }
.custom-range { width: 100%; height: 1.4rem; padding: 0; background-color: transparent; appearance: none; }
.custom-range:focus { outline: none; }
.custom-range:focus::-webkit-slider-thumb { box-shadow: rgb(255, 255, 255) 0 0 0 1px, rgba(24, 19, 92, 0.25) 0 0 0 0.2rem; }
.custom-range::-webkit-slider-thumb { width: 1rem; height: 1rem; margin-top: -0.25rem; background-color: rgb(24, 19, 92); border: 0; border-radius: 1rem; transition: background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s; appearance: none; }
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb { transition: none 0s ease 0s; }
}
.custom-range::-webkit-slider-thumb:active { background-color: rgb(82, 72, 217); }
.custom-range::-webkit-slider-runnable-track { width: 100%; height: 0.5rem; color: transparent; cursor: pointer; background-color: rgb(222, 226, 230); border-color: transparent; border-radius: 1rem; }
@media (prefers-reduced-motion: reduce) {
}
@media (prefers-reduced-motion: reduce) {
}
.custom-range:disabled::-webkit-slider-thumb { background-color: rgb(173, 181, 189); }
.custom-range:disabled::-webkit-slider-runnable-track { cursor: default; }
.custom-control-label::before, .custom-file-label, .custom-select { transition: background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s; }
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before, .custom-file-label, .custom-select { transition: none 0s ease 0s; }
}
.nav { display: flex; flex-wrap: wrap; padding-left: 0; margin-bottom: 0; list-style: none; }
.nav-link { display: block; padding: 0.5rem 1rem; }
.nav-link:hover, .nav-link:focus { text-decoration: none; }
.nav-link.disabled { color: rgb(108, 117, 125); pointer-events: none; cursor: default; }
.nav-tabs { border-bottom: 1px solid rgb(222, 226, 230); }
.nav-tabs .nav-item { margin-bottom: -1px; }
.nav-tabs .nav-link { border: 1px solid transparent; border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem; }
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus { border-color: rgb(233, 236, 239) rgb(233, 236, 239) rgb(222, 226, 230); }
.nav-tabs .nav-link.disabled { color: rgb(108, 117, 125); background-color: transparent; border-color: transparent; }
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link { color: rgb(73, 80, 87); background-color: rgb(255, 255, 255); border-color: rgb(222, 226, 230) rgb(222, 226, 230) rgb(255, 255, 255); }
.nav-tabs .dropdown-menu { margin-top: -1px; border-top-left-radius: 0; border-top-right-radius: 0; }
.nav-pills .nav-link { border-radius: 0.25rem; }
.nav-pills .nav-link.active, .nav-pills .show > .nav-link { color: rgb(255, 255, 255); background-color: rgb(24, 19, 92); }
.nav-fill > .nav-link, .nav-fill .nav-item { flex: 1 1 auto; text-align: center; }
.nav-justified > .nav-link, .nav-justified .nav-item { flex-basis: 0; flex-grow: 1; text-align: center; }
.tab-content > .tab-pane { display: none; }
.tab-content > .active { display: block; }
.navbar { position: relative; display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; padding: 0.5rem 1rem; }
.navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl { display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; }
.navbar-brand { display: inline-block; padding-top: 0.3125rem; padding-bottom: 0.3125rem; margin-right: 1rem; font-size: 1.25rem; line-height: inherit; white-space: nowrap; }
.navbar-brand:hover, .navbar-brand:focus { text-decoration: none; }
.navbar-nav { display: flex; flex-direction: column; padding-left: 0; margin-bottom: 0; list-style: none; }
.navbar-nav .nav-link { padding-right: 0; padding-left: 0; }
.navbar-nav .dropdown-menu { position: static; float: none; }
.navbar-text { display: inline-block; padding-top: 0.5rem; padding-bottom: 0.5rem; }
.navbar-collapse { flex-basis: 100%; flex-grow: 1; align-items: center; }
.navbar-toggler { padding: 0.25rem 0.75rem; font-size: 1.25rem; line-height: 1; background-color: transparent; border: 1px solid transparent; border-radius: 0.25rem; }
.navbar-toggler:hover, .navbar-toggler:focus { text-decoration: none; }
.navbar-toggler-icon { display: inline-block; width: 1.5em; height: 1.5em; vertical-align: middle; content: ""; background: center center / 100% 100% no-repeat; }
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl { padding-right: 0; padding-left: 0; }
}
@media (min-width: 576px) {
  .navbar-expand-sm { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav { flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-sm .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl { flex-wrap: nowrap; }
  .navbar-expand-sm .navbar-collapse { flex-basis: auto; display: flex !important; }
  .navbar-expand-sm .navbar-toggler { display: none; }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl { padding-right: 0; padding-left: 0; }
}
@media (min-width: 768px) {
  .navbar-expand-md { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-md .navbar-nav { flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-md .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl { flex-wrap: nowrap; }
  .navbar-expand-md .navbar-collapse { flex-basis: auto; display: flex !important; }
  .navbar-expand-md .navbar-toggler { display: none; }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl { padding-right: 0; padding-left: 0; }
}
@media (min-width: 992px) {
  .navbar-expand-lg { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav { flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-lg .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl { flex-wrap: nowrap; }
  .navbar-expand-lg .navbar-collapse { flex-basis: auto; display: flex !important; }
  .navbar-expand-lg .navbar-toggler { display: none; }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl { padding-right: 0; padding-left: 0; }
}
@media (min-width: 1200px) {
  .navbar-expand-xl { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav { flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-xl .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl { flex-wrap: nowrap; }
  .navbar-expand-xl .navbar-collapse { flex-basis: auto; display: flex !important; }
  .navbar-expand-xl .navbar-toggler { display: none; }
}
.navbar-expand { flex-flow: row nowrap; justify-content: flex-start; }
.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl { padding-right: 0; padding-left: 0; }
.navbar-expand .navbar-nav { flex-direction: row; }
.navbar-expand .navbar-nav .dropdown-menu { position: absolute; }
.navbar-expand .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl { flex-wrap: nowrap; }
.navbar-expand .navbar-collapse { flex-basis: auto; display: flex !important; }
.navbar-expand .navbar-toggler { display: none; }
.navbar-light .navbar-brand { color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus { color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-nav .nav-link { color: rgba(0, 0, 0, 0.5); }
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus { color: rgba(0, 0, 0, 0.7); }
.navbar-light .navbar-nav .nav-link.disabled { color: rgba(0, 0, 0, 0.3); }
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active { color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-toggler { color: rgba(0, 0, 0, 0.5); border-color: rgba(0, 0, 0, 0.1); }
.navbar-light .navbar-toggler-icon { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
.navbar-light .navbar-text { color: rgba(0, 0, 0, 0.5); }
.navbar-light .navbar-text a { color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus { color: rgba(0, 0, 0, 0.9); }
.navbar-dark .navbar-brand { color: rgb(255, 255, 255); }
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus { color: rgb(255, 255, 255); }
.navbar-dark .navbar-nav .nav-link { color: rgba(255, 255, 255, 0.5); }
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus { color: rgba(255, 255, 255, 0.75); }
.navbar-dark .navbar-nav .nav-link.disabled { color: rgba(255, 255, 255, 0.25); }
.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active { color: rgb(255, 255, 255); }
.navbar-dark .navbar-toggler { color: rgba(255, 255, 255, 0.5); border-color: rgba(255, 255, 255, 0.1); }
.navbar-dark .navbar-toggler-icon { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
.navbar-dark .navbar-text { color: rgba(255, 255, 255, 0.5); }
.navbar-dark .navbar-text a { color: rgb(255, 255, 255); }
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus { color: rgb(255, 255, 255); }
.card { position: relative; display: flex; flex-direction: column; min-width: 0; overflow-wrap: break-word; background-color: rgb(255, 255, 255); background-clip: border-box; border: 1px solid rgba(0, 0, 0, 0.125); border-radius: 0.25rem; }
.card > hr { margin-right: 0; margin-left: 0; }
.card > .list-group { border-top: inherit; border-bottom: inherit; }
.card > .list-group:first-child { border-top-width: 0; border-top-left-radius: calc(0.25rem - 1px); border-top-right-radius: calc(0.25rem - 1px); }
.card > .list-group:last-child { border-bottom-width: 0; border-bottom-right-radius: calc(0.25rem - 1px); border-bottom-left-radius: calc(0.25rem - 1px); }
.card > .card-header + .list-group, .card > .list-group + .card-footer { border-top: 0; }
.card-body { flex: 1 1 auto; min-height: 1px; padding: 1.25rem; }
.card-title { margin-bottom: 0.75rem; }
.card-subtitle { margin-top: -0.375rem; margin-bottom: 0; }
.card-text:last-child { margin-bottom: 0; }
.card-link:hover { text-decoration: none; }
.card-link + .card-link { margin-left: 1.25rem; }
.card-header { padding: 0.75rem 1.25rem; margin-bottom: 0; background-color: rgba(0, 0, 0, 0.03); border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
.card-header:first-child { border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
.card-footer { padding: 0.75rem 1.25rem; background-color: rgba(0, 0, 0, 0.03); border-top: 1px solid rgba(0, 0, 0, 0.125); }
.card-footer:last-child { border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }
.card-header-tabs { margin-right: -0.625rem; margin-bottom: -0.75rem; margin-left: -0.625rem; border-bottom: 0; }
.card-header-pills { margin-right: -0.625rem; margin-left: -0.625rem; }
.card-img-overlay { position: absolute; inset: 0; padding: 1.25rem; border-radius: calc(0.25rem - 1px); }
.card-img, .card-img-top, .card-img-bottom { flex-shrink: 0; width: 100%; }
.card-img, .card-img-top { border-top-left-radius: calc(0.25rem - 1px); border-top-right-radius: calc(0.25rem - 1px); }
.card-img, .card-img-bottom { border-bottom-right-radius: calc(0.25rem - 1px); border-bottom-left-radius: calc(0.25rem - 1px); }
.card-deck .card { margin-bottom: 15px; }
@media (min-width: 576px) {
  .card-deck { display: flex; flex-flow: row wrap; margin-right: -15px; margin-left: -15px; }
  .card-deck .card { flex: 1 0 0; margin-right: 15px; margin-bottom: 0; margin-left: 15px; }
}
.card-group > .card { margin-bottom: 15px; }
@media (min-width: 576px) {
  .card-group { display: flex; flex-flow: row wrap; }
  .card-group > .card { flex: 1 0 0; margin-bottom: 0; }
  .card-group > .card + .card { margin-left: 0; border-left: 0; }
  .card-group > .card:not(:last-child) { border-top-right-radius: 0; border-bottom-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header { border-top-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer { border-bottom-right-radius: 0; }
  .card-group > .card:not(:first-child) { border-top-left-radius: 0; border-bottom-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header { border-top-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer { border-bottom-left-radius: 0; }
}
.card-columns .card { margin-bottom: 0.75rem; }
@media (min-width: 576px) {
  .card-columns { column-count: 3; column-gap: 1.25rem; orphans: 1; widows: 1; }
  .card-columns .card { display: inline-block; width: 100%; }
}
.accordion { overflow-anchor: none; }
.accordion > .card { overflow: hidden; }
.accordion > .card:not(:last-of-type) { border-bottom: 0; border-bottom-right-radius: 0; border-bottom-left-radius: 0; }
.accordion > .card:not(:first-of-type) { border-top-left-radius: 0; border-top-right-radius: 0; }
.accordion > .card > .card-header { border-radius: 0; margin-bottom: -1px; }
.breadcrumb { display: flex; flex-wrap: wrap; padding: 0.75rem 1rem; margin-bottom: 1rem; list-style: none; background-color: rgb(233, 236, 239); border-radius: 0.25rem; }
.breadcrumb-item { display: flex; }
.breadcrumb-item + .breadcrumb-item { padding-left: 0.5rem; }
.breadcrumb-item + .breadcrumb-item::before { display: inline-block; padding-right: 0.5rem; color: rgb(108, 117, 125); content: "/"; }
.breadcrumb-item + .breadcrumb-item:hover::before { text-decoration: underline; }
.breadcrumb-item + .breadcrumb-item:hover::before { text-decoration: none; }
.breadcrumb-item.active { color: rgb(108, 117, 125); }
.pagination { display: flex; padding-left: 0; list-style: none; border-radius: 0.25rem; }
.page-link { position: relative; display: block; padding: 0.5rem 0.75rem; margin-left: -1px; line-height: 1.25; color: rgb(24, 19, 92); background-color: rgb(255, 255, 255); border: 1px solid rgb(222, 226, 230); }
.page-link:hover { z-index: 2; color: rgb(7, 6, 29); text-decoration: none; background-color: rgb(233, 236, 239); border-color: rgb(222, 226, 230); }
.page-link:focus { z-index: 3; outline: 0; box-shadow: rgba(24, 19, 92, 0.25) 0 0 0 0.2rem; }
.page-item:first-child .page-link { margin-left: 0; border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem; }
.page-item:last-child .page-link { border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; }
.page-item.active .page-link { z-index: 3; color: rgb(255, 255, 255); background-color: rgb(24, 19, 92); border-color: rgb(24, 19, 92); }
.page-item.disabled .page-link { color: rgb(108, 117, 125); pointer-events: none; cursor: auto; background-color: rgb(255, 255, 255); border-color: rgb(222, 226, 230); }
.pagination-lg .page-link { padding: 0.75rem 1.5rem; font-size: 1.25rem; line-height: 1.5; }
.pagination-lg .page-item:first-child .page-link { border-top-left-radius: 0.3rem; border-bottom-left-radius: 0.3rem; }
.pagination-lg .page-item:last-child .page-link { border-top-right-radius: 0.3rem; border-bottom-right-radius: 0.3rem; }
.pagination-sm .page-link { padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; }
.pagination-sm .page-item:first-child .page-link { border-top-left-radius: 0.2rem; border-bottom-left-radius: 0.2rem; }
.pagination-sm .page-item:last-child .page-link { border-top-right-radius: 0.2rem; border-bottom-right-radius: 0.2rem; }
.badge { display: inline-block; padding: 0.25em 0.4em; font-size: 75%; font-weight: 700; line-height: 1; text-align: center; white-space: nowrap; vertical-align: baseline; border-radius: 0.25rem; transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s; }
@media (prefers-reduced-motion: reduce) {
  .badge { transition: none 0s ease 0s; }
}
a.badge:hover, a.badge:focus { text-decoration: none; }
.badge:empty { display: none; }
.btn .badge { position: relative; top: -1px; }
.badge-pill { padding-right: 0.6em; padding-left: 0.6em; border-radius: 10rem; }
.badge-primary { color: rgb(255, 255, 255); background-color: rgb(24, 19, 92); }
a.badge-primary:hover, a.badge-primary:focus { color: rgb(255, 255, 255); background-color: rgb(13, 10, 50); }
a.badge-primary:focus, a.badge-primary.focus { outline: 0; box-shadow: rgba(24, 19, 92, 0.5) 0 0 0 0.2rem; }
.badge-secondary { color: rgb(33, 37, 41); background-color: rgb(173, 173, 173); }
a.badge-secondary:hover, a.badge-secondary:focus { color: rgb(33, 37, 41); background-color: rgb(148, 148, 148); }
a.badge-secondary:focus, a.badge-secondary.focus { outline: 0; box-shadow: rgba(173, 173, 173, 0.5) 0 0 0 0.2rem; }
.badge-success { color: rgb(255, 255, 255); background-color: rgb(40, 167, 69); }
a.badge-success:hover, a.badge-success:focus { color: rgb(255, 255, 255); background-color: rgb(30, 126, 52); }
a.badge-success:focus, a.badge-success.focus { outline: 0; box-shadow: rgba(40, 167, 69, 0.5) 0 0 0 0.2rem; }
.badge-info { color: rgb(255, 255, 255); background-color: rgb(23, 162, 184); }
a.badge-info:hover, a.badge-info:focus { color: rgb(255, 255, 255); background-color: rgb(17, 122, 139); }
a.badge-info:focus, a.badge-info.focus { outline: 0; box-shadow: rgba(23, 162, 184, 0.5) 0 0 0 0.2rem; }
.badge-warning { color: rgb(33, 37, 41); background-color: rgb(255, 193, 7); }
a.badge-warning:hover, a.badge-warning:focus { color: rgb(33, 37, 41); background-color: rgb(211, 158, 0); }
a.badge-warning:focus, a.badge-warning.focus { outline: 0; box-shadow: rgba(255, 193, 7, 0.5) 0 0 0 0.2rem; }
.badge-danger { color: rgb(255, 255, 255); background-color: rgb(220, 53, 69); }
a.badge-danger:hover, a.badge-danger:focus { color: rgb(255, 255, 255); background-color: rgb(189, 33, 48); }
a.badge-danger:focus, a.badge-danger.focus { outline: 0; box-shadow: rgba(220, 53, 69, 0.5) 0 0 0 0.2rem; }
.badge-light { color: rgb(33, 37, 41); background-color: rgb(239, 239, 239); }
a.badge-light:hover, a.badge-light:focus { color: rgb(33, 37, 41); background-color: rgb(214, 214, 214); }
a.badge-light:focus, a.badge-light.focus { outline: 0; box-shadow: rgba(239, 239, 239, 0.5) 0 0 0 0.2rem; }
.badge-dark { color: rgb(255, 255, 255); background-color: rgb(54, 54, 54); }
a.badge-dark:hover, a.badge-dark:focus { color: rgb(255, 255, 255); background-color: rgb(29, 29, 29); }
a.badge-dark:focus, a.badge-dark.focus { outline: 0; box-shadow: rgba(54, 54, 54, 0.5) 0 0 0 0.2rem; }
.badge-tertiary { color: rgb(255, 255, 255); background-color: rgb(100, 100, 135); }
a.badge-tertiary:hover, a.badge-tertiary:focus { color: rgb(255, 255, 255); background-color: rgb(78, 78, 106); }
a.badge-tertiary:focus, a.badge-tertiary.focus { outline: 0; box-shadow: rgba(100, 100, 135, 0.5) 0 0 0 0.2rem; }
.jumbotron { padding: 2rem 1rem; margin-bottom: 2rem; background-color: rgb(233, 236, 239); border-radius: 0.3rem; }
@media (min-width: 576px) {
  .jumbotron { padding: 4rem 2rem; }
}
.jumbotron-fluid { padding-right: 0; padding-left: 0; border-radius: 0; }
.alert { position: relative; padding: 0.75rem 1.25rem; margin-bottom: 1rem; border: 1px solid transparent; border-radius: 0.25rem; }
.alert-heading { color: inherit; }
.alert-link { font-weight: 700; }
.alert-dismissible { padding-right: 4rem; }
.alert-dismissible .close { position: absolute; top: 0; right: 0; z-index: 2; padding: 0.75rem 1.25rem; color: inherit; }
.alert-primary { color: rgb(12, 10, 48); background-color: rgb(209, 208, 222); border-color: rgb(190, 189, 209); }
.alert-primary hr { border-top-color: rgb(175, 174, 199); }
.alert-primary .alert-link { color: rgb(1, 1, 6); }
.alert-secondary { color: rgb(90, 90, 90); background-color: rgb(239, 239, 239); border-color: rgb(232, 232, 232); }
.alert-secondary hr { border-top-color: rgb(219, 219, 219); }
.alert-secondary .alert-link { color: rgb(65, 65, 65); }
.alert-success { color: rgb(21, 87, 36); background-color: rgb(212, 237, 218); border-color: rgb(195, 230, 203); }
.alert-success hr { border-top-color: rgb(177, 223, 187); }
.alert-success .alert-link { color: rgb(11, 46, 19); }
.alert-info { color: rgb(12, 84, 96); background-color: rgb(209, 236, 241); border-color: rgb(190, 229, 235); }
.alert-info hr { border-top-color: rgb(171, 221, 229); }
.alert-info .alert-link { color: rgb(6, 44, 51); }
.alert-warning { color: rgb(133, 100, 4); background-color: rgb(255, 243, 205); border-color: rgb(255, 238, 186); }
.alert-warning hr { border-top-color: rgb(255, 232, 161); }
.alert-warning .alert-link { color: rgb(83, 63, 3); }
.alert-danger { color: rgb(114, 28, 36); background-color: rgb(248, 215, 218); border-color: rgb(245, 198, 203); }
.alert-danger hr { border-top-color: rgb(241, 176, 183); }
.alert-danger .alert-link { color: rgb(73, 18, 23); }
.alert-light { color: rgb(124, 124, 124); background-color: rgb(252, 252, 252); border-color: rgb(251, 251, 251); }
.alert-light hr { border-top-color: rgb(238, 238, 238); }
.alert-light .alert-link { color: rgb(99, 99, 99); }
.alert-dark { color: rgb(28, 28, 28); background-color: rgb(215, 215, 215); border-color: rgb(199, 199, 199); }
.alert-dark hr { border-top-color: rgb(186, 186, 186); }
.alert-dark .alert-link { color: rgb(3, 3, 3); }
.alert-tertiary { color: rgb(52, 52, 70); background-color: rgb(224, 224, 231); border-color: rgb(212, 212, 221); }
.alert-tertiary hr { border-top-color: rgb(198, 198, 210); }
.alert-tertiary .alert-link { color: rgb(30, 30, 41); }
@keyframes progress-bar-stripes { 
  0% { background-position: 1rem 0; }
  100% { background-position: 0 0; }
}
.progress { display: flex; height: 1rem; overflow: hidden; line-height: 0; font-size: 0.75rem; background-color: rgb(233, 236, 239); border-radius: 0.25rem; }
.progress-bar { display: flex; flex-direction: column; justify-content: center; overflow: hidden; color: rgb(255, 255, 255); text-align: center; white-space: nowrap; background-color: rgb(24, 19, 92); transition: width 0.6s ease 0s; }
@media (prefers-reduced-motion: reduce) {
  .progress-bar { transition: none 0s ease 0s; }
}
.progress-bar-striped { background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); background-size: 1rem 1rem; }
.progress-bar-animated { animation: 1s linear 0s infinite normal none running progress-bar-stripes; }
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated { animation: 0s ease 0s 1 normal none running none; }
}
.media { display: flex; align-items: flex-start; }
.media-body { flex: 1 1 0; }
.list-group { display: flex; flex-direction: column; padding-left: 0; margin-bottom: 0; border-radius: 0.25rem; }
.list-group-item-action { width: 100%; color: rgb(73, 80, 87); text-align: inherit; }
.list-group-item-action:hover, .list-group-item-action:focus { z-index: 1; color: rgb(73, 80, 87); text-decoration: none; background-color: rgb(248, 249, 250); }
.list-group-item-action:active { color: rgb(33, 37, 41); background-color: rgb(233, 236, 239); }
.list-group-item { position: relative; display: block; padding: 0.75rem 1.25rem; background-color: rgb(255, 255, 255); border: 1px solid rgba(0, 0, 0, 0.125); }
.list-group-item:first-child { border-top-left-radius: inherit; border-top-right-radius: inherit; }
.list-group-item:last-child { border-bottom-right-radius: inherit; border-bottom-left-radius: inherit; }
.list-group-item.disabled, .list-group-item:disabled { color: rgb(108, 117, 125); pointer-events: none; background-color: rgb(255, 255, 255); }
.list-group-item.active { z-index: 2; color: rgb(255, 255, 255); background-color: rgb(24, 19, 92); border-color: rgb(24, 19, 92); }
.list-group-item + .list-group-item { border-top-width: 0; }
.list-group-item + .list-group-item.active { margin-top: -1px; border-top-width: 1px; }
.list-group-horizontal { flex-direction: row; }
.list-group-horizontal > .list-group-item:first-child { border-bottom-left-radius: 0.25rem; border-top-right-radius: 0; }
.list-group-horizontal > .list-group-item:last-child { border-top-right-radius: 0.25rem; border-bottom-left-radius: 0; }
.list-group-horizontal > .list-group-item.active { margin-top: 0; }
.list-group-horizontal > .list-group-item + .list-group-item { border-top-width: 1px; border-left-width: 0; }
.list-group-horizontal > .list-group-item + .list-group-item.active { margin-left: -1px; border-left-width: 1px; }
@media (min-width: 576px) {
  .list-group-horizontal-sm { flex-direction: row; }
  .list-group-horizontal-sm > .list-group-item:first-child { border-bottom-left-radius: 0.25rem; border-top-right-radius: 0; }
  .list-group-horizontal-sm > .list-group-item:last-child { border-top-right-radius: 0.25rem; border-bottom-left-radius: 0; }
  .list-group-horizontal-sm > .list-group-item.active { margin-top: 0; }
  .list-group-horizontal-sm > .list-group-item + .list-group-item { border-top-width: 1px; border-left-width: 0; }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active { margin-left: -1px; border-left-width: 1px; }
}
@media (min-width: 768px) {
  .list-group-horizontal-md { flex-direction: row; }
  .list-group-horizontal-md > .list-group-item:first-child { border-bottom-left-radius: 0.25rem; border-top-right-radius: 0; }
  .list-group-horizontal-md > .list-group-item:last-child { border-top-right-radius: 0.25rem; border-bottom-left-radius: 0; }
  .list-group-horizontal-md > .list-group-item.active { margin-top: 0; }
  .list-group-horizontal-md > .list-group-item + .list-group-item { border-top-width: 1px; border-left-width: 0; }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active { margin-left: -1px; border-left-width: 1px; }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg { flex-direction: row; }
  .list-group-horizontal-lg > .list-group-item:first-child { border-bottom-left-radius: 0.25rem; border-top-right-radius: 0; }
  .list-group-horizontal-lg > .list-group-item:last-child { border-top-right-radius: 0.25rem; border-bottom-left-radius: 0; }
  .list-group-horizontal-lg > .list-group-item.active { margin-top: 0; }
  .list-group-horizontal-lg > .list-group-item + .list-group-item { border-top-width: 1px; border-left-width: 0; }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active { margin-left: -1px; border-left-width: 1px; }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl { flex-direction: row; }
  .list-group-horizontal-xl > .list-group-item:first-child { border-bottom-left-radius: 0.25rem; border-top-right-radius: 0; }
  .list-group-horizontal-xl > .list-group-item:last-child { border-top-right-radius: 0.25rem; border-bottom-left-radius: 0; }
  .list-group-horizontal-xl > .list-group-item.active { margin-top: 0; }
  .list-group-horizontal-xl > .list-group-item + .list-group-item { border-top-width: 1px; border-left-width: 0; }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active { margin-left: -1px; border-left-width: 1px; }
}
.list-group-flush { border-radius: 0; }
.list-group-flush > .list-group-item { border-width: 0 0 1px; }
.list-group-flush > .list-group-item:last-child { border-bottom-width: 0; }
.list-group-item-primary { color: rgb(12, 10, 48); background-color: rgb(190, 189, 209); }
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus { color: rgb(12, 10, 48); background-color: rgb(175, 174, 199); }
.list-group-item-primary.list-group-item-action.active { color: rgb(255, 255, 255); background-color: rgb(12, 10, 48); border-color: rgb(12, 10, 48); }
.list-group-item-secondary { color: rgb(90, 90, 90); background-color: rgb(232, 232, 232); }
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus { color: rgb(90, 90, 90); background-color: rgb(219, 219, 219); }
.list-group-item-secondary.list-group-item-action.active { color: rgb(255, 255, 255); background-color: rgb(90, 90, 90); border-color: rgb(90, 90, 90); }
.list-group-item-success { color: rgb(21, 87, 36); background-color: rgb(195, 230, 203); }
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus { color: rgb(21, 87, 36); background-color: rgb(177, 223, 187); }
.list-group-item-success.list-group-item-action.active { color: rgb(255, 255, 255); background-color: rgb(21, 87, 36); border-color: rgb(21, 87, 36); }
.list-group-item-info { color: rgb(12, 84, 96); background-color: rgb(190, 229, 235); }
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus { color: rgb(12, 84, 96); background-color: rgb(171, 221, 229); }
.list-group-item-info.list-group-item-action.active { color: rgb(255, 255, 255); background-color: rgb(12, 84, 96); border-color: rgb(12, 84, 96); }
.list-group-item-warning { color: rgb(133, 100, 4); background-color: rgb(255, 238, 186); }
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus { color: rgb(133, 100, 4); background-color: rgb(255, 232, 161); }
.list-group-item-warning.list-group-item-action.active { color: rgb(255, 255, 255); background-color: rgb(133, 100, 4); border-color: rgb(133, 100, 4); }
.list-group-item-danger { color: rgb(114, 28, 36); background-color: rgb(245, 198, 203); }
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus { color: rgb(114, 28, 36); background-color: rgb(241, 176, 183); }
.list-group-item-danger.list-group-item-action.active { color: rgb(255, 255, 255); background-color: rgb(114, 28, 36); border-color: rgb(114, 28, 36); }
.list-group-item-light { color: rgb(124, 124, 124); background-color: rgb(251, 251, 251); }
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus { color: rgb(124, 124, 124); background-color: rgb(238, 238, 238); }
.list-group-item-light.list-group-item-action.active { color: rgb(255, 255, 255); background-color: rgb(124, 124, 124); border-color: rgb(124, 124, 124); }
.list-group-item-dark { color: rgb(28, 28, 28); background-color: rgb(199, 199, 199); }
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus { color: rgb(28, 28, 28); background-color: rgb(186, 186, 186); }
.list-group-item-dark.list-group-item-action.active { color: rgb(255, 255, 255); background-color: rgb(28, 28, 28); border-color: rgb(28, 28, 28); }
.list-group-item-tertiary { color: rgb(52, 52, 70); background-color: rgb(212, 212, 221); }
.list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus { color: rgb(52, 52, 70); background-color: rgb(198, 198, 210); }
.list-group-item-tertiary.list-group-item-action.active { color: rgb(255, 255, 255); background-color: rgb(52, 52, 70); border-color: rgb(52, 52, 70); }
.close { float: right; font-size: 1.5rem; font-weight: 700; line-height: 1; color: rgb(0, 0, 0); text-shadow: rgb(255, 255, 255) 0 1px 0; opacity: 0.5; }
.close:hover { color: rgb(0, 0, 0); text-decoration: none; }
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus { opacity: 0.75; }
button.close { padding: 0; background-color: transparent; border: 0; }
a.close.disabled { pointer-events: none; }
.toast { flex-basis: 350px; max-width: 350px; font-size: 0.875rem; background-color: rgba(255, 255, 255, 0.85); background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.1); box-shadow: rgba(0, 0, 0, 0.1) 0 0.25rem 0.75rem; opacity: 0; border-radius: 0.25rem; }
.toast:not(:last-child) { margin-bottom: 0.75rem; }
.toast.showing { opacity: 1; }
.toast.show { display: block; opacity: 1; }
.toast.hide { display: none; }
.toast-header { display: flex; align-items: center; padding: 0.25rem 0.75rem; color: rgb(108, 117, 125); background-color: rgba(255, 255, 255, 0.85); background-clip: padding-box; border-bottom: 1px solid rgba(0, 0, 0, 0.05); border-top-left-radius: calc(0.25rem - 1px); border-top-right-radius: calc(0.25rem - 1px); }
.toast-body { padding: 0.75rem; }
.modal-open { overflow: hidden; }
.modal-open .modal { overflow: hidden auto; }
.modal { position: fixed; top: 0; left: 0; z-index: 1050; display: none; width: 100%; height: 100%; overflow: hidden; outline: 0; }
.modal-dialog { position: relative; width: auto; margin: 0.5rem; pointer-events: none; }
.modal.fade .modal-dialog { transition: transform 0.3s ease-out 0s; transform: translate(0px, -50px); }
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog { transition: none 0s ease 0s; }
}
.modal.show .modal-dialog { transform: none; }
.modal.modal-static .modal-dialog { transform: scale(1.02); }
.modal-dialog-scrollable { display: flex; max-height: calc(100% - 1rem); }
.modal-dialog-scrollable .modal-content { max-height: calc(100vh - 1rem); overflow: hidden; }
.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer { flex-shrink: 0; }
.modal-dialog-scrollable .modal-body { overflow-y: auto; }
.modal-dialog-centered { display: flex; align-items: center; min-height: calc(100% - 1rem); }
.modal-dialog-centered::before { display: block; height: min-content; content: ""; }
.modal-dialog-centered.modal-dialog-scrollable { flex-direction: column; justify-content: center; height: 100%; }
.modal-dialog-centered.modal-dialog-scrollable .modal-content { max-height: none; }
.modal-dialog-centered.modal-dialog-scrollable::before { content: none; }
.modal-content { position: relative; display: flex; flex-direction: column; width: 100%; pointer-events: auto; background-color: rgb(255, 255, 255); background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 0.3rem; outline: 0; }
.modal-backdrop { position: fixed; top: 0; left: 0; z-index: 1040; width: 100vw; height: 100vh; background-color: rgb(0, 0, 0); }
.modal-backdrop.fade { opacity: 0; }
.modal-backdrop.show { opacity: 0.5; }
.modal-header { display: flex; align-items: flex-start; justify-content: space-between; padding: 1rem; border-bottom: 1px solid rgb(222, 226, 230); border-top-left-radius: calc(0.3rem - 1px); border-top-right-radius: calc(0.3rem - 1px); }
.modal-header .close { padding: 1rem; margin: -1rem -1rem -1rem auto; }
.modal-title { margin-bottom: 0; line-height: 1.5; }
.modal-body { position: relative; flex: 1 1 auto; padding: 1rem; }
.modal-footer { display: flex; flex-wrap: wrap; align-items: center; justify-content: flex-end; padding: 0.75rem; border-top: 1px solid rgb(222, 226, 230); border-bottom-right-radius: calc(0.3rem - 1px); border-bottom-left-radius: calc(0.3rem - 1px); }
.modal-footer > * { margin: 0.25rem; }
.modal-scrollbar-measure { position: absolute; top: -9999px; width: 50px; height: 50px; overflow: scroll; }
@media (min-width: 576px) {
  .modal-dialog { max-width: 500px; margin: 1.75rem auto; }
  .modal-dialog-scrollable { max-height: calc(100% - 3.5rem); }
  .modal-dialog-scrollable .modal-content { max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered { min-height: calc(100% - 3.5rem); }
  .modal-dialog-centered::before { height: min-content; }
  .modal-sm { max-width: 300px; }
}
@media (min-width: 992px) {
  .modal-lg, .modal-xl { max-width: 800px; }
}
@media (min-width: 1200px) {
  .modal-xl { max-width: 1140px; }
}
.tooltip { position: absolute; z-index: 1070; display: block; margin: 0; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-style: normal; font-weight: 400; line-height: 1.5; text-align: start; text-decoration: none; text-shadow: none; text-transform: none; letter-spacing: normal; word-break: normal; word-spacing: normal; white-space: normal; line-break: auto; font-size: 0.875rem; overflow-wrap: break-word; opacity: 0; }
.tooltip.show { opacity: 0.9; }
.tooltip .arrow { position: absolute; display: block; width: 0.8rem; height: 0.4rem; }
.tooltip .arrow::before { position: absolute; content: ""; border-color: transparent; border-style: solid; }
.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] { padding: 0.4rem 0; }
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow { bottom: 0; }
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before { top: 0; border-width: 0.4rem 0.4rem 0; border-top-color: rgb(0, 0, 0); }
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] { padding: 0 0.4rem; }
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow { left: 0; width: 0.4rem; height: 0.8rem; }
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before { right: 0; border-width: 0.4rem 0.4rem 0.4rem 0; border-right-color: rgb(0, 0, 0); }
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] { padding: 0.4rem 0; }
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow { top: 0; }
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before { bottom: 0; border-width: 0 0.4rem 0.4rem; border-bottom-color: rgb(0, 0, 0); }
.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] { padding: 0 0.4rem; }
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow { right: 0; width: 0.4rem; height: 0.8rem; }
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before { left: 0; border-width: 0.4rem 0 0.4rem 0.4rem; border-left-color: rgb(0, 0, 0); }
.tooltip-inner { max-width: 200px; padding: 0.25rem 0.5rem; color: rgb(255, 255, 255); text-align: center; background-color: rgb(0, 0, 0); border-radius: 0.25rem; }
.popover { position: absolute; top: 0; left: 0; z-index: 1060; display: block; max-width: 276px; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-style: normal; font-weight: 400; line-height: 1.5; text-align: start; text-decoration: none; text-shadow: none; text-transform: none; letter-spacing: normal; word-break: normal; word-spacing: normal; white-space: normal; line-break: auto; font-size: 0.875rem; overflow-wrap: break-word; background-color: rgb(255, 255, 255); background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 0.3rem; }
.popover .arrow { position: absolute; display: block; width: 1rem; height: 0.5rem; margin: 0 0.3rem; }
.popover .arrow::before, .popover .arrow::after { position: absolute; display: block; content: ""; border-color: transparent; border-style: solid; }
.bs-popover-top, .bs-popover-auto[x-placement^="top"] { margin-bottom: 0.5rem; }
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow { bottom: calc(-0.5rem - 1px); }
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before { bottom: 0; border-width: 0.5rem 0.5rem 0; border-top-color: rgba(0, 0, 0, 0.25); }
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after { bottom: 1px; border-width: 0.5rem 0.5rem 0; border-top-color: rgb(255, 255, 255); }
.bs-popover-right, .bs-popover-auto[x-placement^="right"] { margin-left: 0.5rem; }
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow { left: calc(-0.5rem - 1px); width: 0.5rem; height: 1rem; margin: 0.3rem 0; }
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before { left: 0; border-width: 0.5rem 0.5rem 0.5rem 0; border-right-color: rgba(0, 0, 0, 0.25); }
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after { left: 1px; border-width: 0.5rem 0.5rem 0.5rem 0; border-right-color: rgb(255, 255, 255); }
.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] { margin-top: 0.5rem; }
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow { top: calc(-0.5rem - 1px); }
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before { top: 0; border-width: 0 0.5rem 0.5rem; border-bottom-color: rgba(0, 0, 0, 0.25); }
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after { top: 1px; border-width: 0 0.5rem 0.5rem; border-bottom-color: rgb(255, 255, 255); }
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before { position: absolute; top: 0; left: 50%; display: block; width: 1rem; margin-left: -0.5rem; content: ""; border-bottom: 1px solid rgb(247, 247, 247); }
.bs-popover-left, .bs-popover-auto[x-placement^="left"] { margin-right: 0.5rem; }
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow { right: calc(-0.5rem - 1px); width: 0.5rem; height: 1rem; margin: 0.3rem 0; }
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before { right: 0; border-width: 0.5rem 0 0.5rem 0.5rem; border-left-color: rgba(0, 0, 0, 0.25); }
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after { right: 1px; border-width: 0.5rem 0 0.5rem 0.5rem; border-left-color: rgb(255, 255, 255); }
.popover-header { padding: 0.5rem 0.75rem; margin-bottom: 0; font-size: 1rem; background-color: rgb(247, 247, 247); border-bottom: 1px solid rgb(235, 235, 235); border-top-left-radius: calc(0.3rem - 1px); border-top-right-radius: calc(0.3rem - 1px); }
.popover-header:empty { display: none; }
.popover-body { padding: 0.5rem 0.75rem; color: rgb(33, 37, 41); }
.carousel { position: relative; }
.carousel.pointer-event { touch-action: pan-y; }
.carousel-inner { position: relative; width: 100%; overflow: hidden; }
.carousel-inner::after { display: block; clear: both; content: ""; }
.carousel-item { position: relative; display: none; float: left; width: 100%; margin-right: -100%; backface-visibility: hidden; transition: transform 0.6s ease-in-out 0s; }
@media (prefers-reduced-motion: reduce) {
  .carousel-item { transition: none 0s ease 0s; }
}
.carousel-item.active, .carousel-item-next, .carousel-item-prev { display: block; }
.carousel-item-next:not(.carousel-item-left), .active.carousel-item-right { transform: translateX(100%); }
.carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left { transform: translateX(-100%); }
.carousel-fade .carousel-item { opacity: 0; transition-property: opacity; transform: none; }
.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right { z-index: 1; opacity: 1; }
.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right { z-index: 0; opacity: 0; transition: opacity 0s ease 0.6s; }
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right { transition: none 0s ease 0s; }
}
.carousel-control-prev, .carousel-control-next { position: absolute; top: 0; bottom: 0; z-index: 1; display: flex; align-items: center; justify-content: center; width: 15%; color: rgb(255, 255, 255); text-align: center; opacity: 0.5; transition: opacity 0.15s ease 0s; }
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next { transition: none 0s ease 0s; }
}
.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus { color: rgb(255, 255, 255); text-decoration: none; outline: 0; opacity: 0.9; }
.carousel-control-prev { left: 0; }
.carousel-control-next { right: 0; }
.carousel-control-prev-icon, .carousel-control-next-icon { display: inline-block; width: 20px; height: 20px; background: 50% center / 100% 100% no-repeat; }
.carousel-control-prev-icon { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }
.carousel-control-next-icon { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }
.carousel-indicators { position: absolute; right: 0; bottom: 0; left: 0; z-index: 15; display: flex; justify-content: center; padding-left: 0; margin-right: 15%; margin-left: 15%; list-style: none; }
.carousel-indicators li { box-sizing: content-box; flex: 0 1 auto; width: 30px; height: 3px; margin-right: 3px; margin-left: 3px; text-indent: -999px; cursor: pointer; background-color: rgb(255, 255, 255); background-clip: padding-box; border-top: 10px solid transparent; border-bottom: 10px solid transparent; opacity: 0.5; transition: opacity 0.6s ease 0s; }
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li { transition: none 0s ease 0s; }
}
.carousel-indicators .active { opacity: 1; }
.carousel-caption { position: absolute; right: 15%; bottom: 20px; left: 15%; z-index: 10; padding-top: 20px; padding-bottom: 20px; color: rgb(255, 255, 255); text-align: center; }
@keyframes spinner-border { 
  100% { transform: rotate(360deg); }
}
.spinner-border { display: inline-block; width: 2rem; height: 2rem; vertical-align: text-bottom; border-width: 0.25em; border-style: solid; border-color: currentcolor transparent currentcolor currentcolor; border-image: initial; border-radius: 50%; animation: 0.75s linear 0s infinite normal none running spinner-border; }
.spinner-border-sm { width: 1rem; height: 1rem; border-width: 0.2em; }
@keyframes spinner-grow { 
  0% { transform: scale(0); }
  50% { opacity: 1; transform: none; }
}
.spinner-grow { display: inline-block; width: 2rem; height: 2rem; vertical-align: text-bottom; background-color: currentcolor; border-radius: 50%; opacity: 0; animation: 0.75s linear 0s infinite normal none running spinner-grow; }
.spinner-grow-sm { width: 1rem; height: 1rem; }
.align-baseline { vertical-align: baseline !important; }
.align-top { vertical-align: top !important; }
.align-middle { vertical-align: middle !important; }
.align-bottom { vertical-align: bottom !important; }
.align-text-bottom { vertical-align: text-bottom !important; }
.align-text-top { vertical-align: text-top !important; }
.bg-primary { background-color: rgb(24, 19, 92) !important; }
a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus { background-color: rgb(13, 10, 50) !important; }
.bg-secondary { background-color: rgb(173, 173, 173) !important; }
a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus { background-color: rgb(148, 148, 148) !important; }
.bg-success { background-color: rgb(40, 167, 69) !important; }
a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus { background-color: rgb(30, 126, 52) !important; }
.bg-info { background-color: rgb(23, 162, 184) !important; }
a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus { background-color: rgb(17, 122, 139) !important; }
.bg-warning { background-color: rgb(255, 193, 7) !important; }
a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus { background-color: rgb(211, 158, 0) !important; }
.bg-danger { background-color: rgb(220, 53, 69) !important; }
a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus { background-color: rgb(189, 33, 48) !important; }
.bg-light { background-color: rgb(239, 239, 239) !important; }
a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus { background-color: rgb(214, 214, 214) !important; }
.bg-dark { background-color: rgb(54, 54, 54) !important; }
a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus { background-color: rgb(29, 29, 29) !important; }
.bg-tertiary { background-color: rgb(100, 100, 135) !important; }
a.bg-tertiary:hover, a.bg-tertiary:focus, button.bg-tertiary:hover, button.bg-tertiary:focus { background-color: rgb(78, 78, 106) !important; }
.bg-white { background-color: rgb(255, 255, 255) !important; }
.bg-transparent { background-color: transparent !important; }
.border { border: 1px solid rgb(222, 226, 230) !important; }
.border-top { border-top: 1px solid rgb(222, 226, 230) !important; }
.border-right { border-right: 1px solid rgb(222, 226, 230) !important; }
.border-bottom { border-bottom: 1px solid rgb(222, 226, 230) !important; }
.border-left { border-left: 1px solid rgb(222, 226, 230) !important; }
.border-0 { border: 0 !important; }
.border-top-0 { border-top: 0 !important; }
.border-right-0 { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0 { border-left: 0 !important; }
.border-primary { border-color: rgb(24, 19, 92) !important; }
.border-secondary { border-color: rgb(173, 173, 173) !important; }
.border-success { border-color: rgb(40, 167, 69) !important; }
.border-info { border-color: rgb(23, 162, 184) !important; }
.border-warning { border-color: rgb(255, 193, 7) !important; }
.border-danger { border-color: rgb(220, 53, 69) !important; }
.border-light { border-color: rgb(239, 239, 239) !important; }
.border-dark { border-color: rgb(54, 54, 54) !important; }
.border-tertiary { border-color: rgb(100, 100, 135) !important; }
.border-white { border-color: rgb(255, 255, 255) !important; }
.rounded-sm { border-radius: 0.2rem !important; }
.rounded { border-radius: 0.25rem !important; }
.rounded-top { border-top-left-radius: 0.25rem !important; border-top-right-radius: 0.25rem !important; }
.rounded-right { border-top-right-radius: 0.25rem !important; border-bottom-right-radius: 0.25rem !important; }
.rounded-bottom { border-bottom-right-radius: 0.25rem !important; border-bottom-left-radius: 0.25rem !important; }
.rounded-left { border-top-left-radius: 0.25rem !important; border-bottom-left-radius: 0.25rem !important; }
.rounded-lg { border-radius: 0.3rem !important; }
.rounded-circle { border-radius: 50% !important; }
.rounded-pill { border-radius: 50rem !important; }
.rounded-0 { border-radius: 0 !important; }
.clearfix::after { display: block; clear: both; content: ""; }
.d-none { display: none !important; }
.d-inline { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block { display: block !important; }
.d-table { display: table !important; }
.d-table-row { display: table-row !important; }
.d-table-cell { display: table-cell !important; }
.d-flex { display: flex !important; }
.d-inline-flex { display: inline-flex !important; }
@media (min-width: 576px) {
  .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: flex !important; }
  .d-sm-inline-flex { display: inline-flex !important; }
}
@media (min-width: 768px) {
  .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: flex !important; }
  .d-md-inline-flex { display: inline-flex !important; }
}
@media (min-width: 992px) {
  .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: flex !important; }
  .d-lg-inline-flex { display: inline-flex !important; }
}
@media (min-width: 1200px) {
  .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: flex !important; }
  .d-xl-inline-flex { display: inline-flex !important; }
}
@media print {
  .d-print-none { display: none !important; }
  .d-print-inline { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block { display: block !important; }
  .d-print-table { display: table !important; }
  .d-print-table-row { display: table-row !important; }
  .d-print-table-cell { display: table-cell !important; }
  .d-print-flex { display: flex !important; }
  .d-print-inline-flex { display: inline-flex !important; }
}
.embed-responsive { position: relative; display: block; width: 100%; padding: 0; overflow: hidden; }
.embed-responsive::before { display: block; content: ""; }
.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video { position: absolute; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; border: 0; }
.embed-responsive-21by9::before { padding-top: 42.8571%; }
.embed-responsive-16by9::before { padding-top: 56.25%; }
.embed-responsive-4by3::before { padding-top: 75%; }
.embed-responsive-1by1::before { padding-top: 100%; }
.flex-row { flex-direction: row !important; }
.flex-column { flex-direction: column !important; }
.flex-row-reverse { flex-direction: row-reverse !important; }
.flex-column-reverse { flex-direction: column-reverse !important; }
.flex-wrap { flex-wrap: wrap !important; }
.flex-nowrap { flex-wrap: nowrap !important; }
.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
.flex-fill { flex: 1 1 auto !important; }
.flex-grow-0 { flex-grow: 0 !important; }
.flex-grow-1 { flex-grow: 1 !important; }
.flex-shrink-0 { flex-shrink: 0 !important; }
.flex-shrink-1 { flex-shrink: 1 !important; }
.justify-content-start { justify-content: flex-start !important; }
.justify-content-end { justify-content: flex-end !important; }
.justify-content-center { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-around { justify-content: space-around !important; }
.align-items-start { align-items: flex-start !important; }
.align-items-end { align-items: flex-end !important; }
.align-items-center { align-items: center !important; }
.align-items-baseline { align-items: baseline !important; }
.align-items-stretch { align-items: stretch !important; }
.align-content-start { align-content: flex-start !important; }
.align-content-end { align-content: flex-end !important; }
.align-content-center { align-content: center !important; }
.align-content-between { align-content: space-between !important; }
.align-content-around { align-content: space-around !important; }
.align-content-stretch { align-content: stretch !important; }
.align-self-auto { align-self: auto !important; }
.align-self-start { align-self: flex-start !important; }
.align-self-end { align-self: flex-end !important; }
.align-self-center { align-self: center !important; }
.align-self-baseline { align-self: baseline !important; }
.align-self-stretch { align-self: stretch !important; }
@media (min-width: 576px) {
  .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-sm-fill { flex: 1 1 auto !important; }
  .flex-sm-grow-0 { flex-grow: 0 !important; }
  .flex-sm-grow-1 { flex-grow: 1 !important; }
  .flex-sm-shrink-0 { flex-shrink: 0 !important; }
  .flex-sm-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; }
}
@media (min-width: 768px) {
  .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-md-fill { flex: 1 1 auto !important; }
  .flex-md-grow-0 { flex-grow: 0 !important; }
  .flex-md-grow-1 { flex-grow: 1 !important; }
  .flex-md-shrink-0 { flex-shrink: 0 !important; }
  .flex-md-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; }
}
@media (min-width: 992px) {
  .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-lg-fill { flex: 1 1 auto !important; }
  .flex-lg-grow-0 { flex-grow: 0 !important; }
  .flex-lg-grow-1 { flex-grow: 1 !important; }
  .flex-lg-shrink-0 { flex-shrink: 0 !important; }
  .flex-lg-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; }
}
@media (min-width: 1200px) {
  .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-xl-fill { flex: 1 1 auto !important; }
  .flex-xl-grow-0 { flex-grow: 0 !important; }
  .flex-xl-grow-1 { flex-grow: 1 !important; }
  .flex-xl-shrink-0 { flex-shrink: 0 !important; }
  .flex-xl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; }
}
.float-left { float: left !important; }
.float-right { float: right !important; }
.float-none { float: none !important; }
@media (min-width: 576px) {
  .float-sm-left { float: left !important; }
  .float-sm-right { float: right !important; }
  .float-sm-none { float: none !important; }
}
@media (min-width: 768px) {
  .float-md-left { float: left !important; }
  .float-md-right { float: right !important; }
  .float-md-none { float: none !important; }
}
@media (min-width: 992px) {
  .float-lg-left { float: left !important; }
  .float-lg-right { float: right !important; }
  .float-lg-none { float: none !important; }
}
@media (min-width: 1200px) {
  .float-xl-left { float: left !important; }
  .float-xl-right { float: right !important; }
  .float-xl-none { float: none !important; }
}
.user-select-all { user-select: all !important; }
.user-select-auto { user-select: auto !important; }
.user-select-none { user-select: none !important; }
.overflow-auto { overflow: auto !important; }
.overflow-hidden { overflow: hidden !important; }
.position-static { position: static !important; }
.position-relative { position: relative !important; }
.position-absolute { position: absolute !important; }
.position-fixed { position: fixed !important; }
.position-sticky { position: sticky !important; }
.fixed-top { position: fixed; top: 0; right: 0; left: 0; z-index: 1030; }
.fixed-bottom { position: fixed; right: 0; bottom: 0; left: 0; z-index: 1030; }
@supports (position: sticky) {
  .sticky-top { position: sticky; top: 0; z-index: 1020; }
}
.sr-only { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0px, 0, 0, 0); white-space: nowrap; border: 0; }
.sr-only-focusable:active, .sr-only-focusable:focus { position: static; width: auto; height: auto; overflow: visible; clip: auto; white-space: normal; }
.shadow-sm { box-shadow: rgba(0, 0, 0, 0.075) 0 0.125rem 0.25rem !important; }
.shadow { box-shadow: rgba(0, 0, 0, 0.15) 0 0.5rem 1rem !important; }
.shadow-lg { box-shadow: rgba(0, 0, 0, 0.176) 0 1rem 3rem !important; }
.shadow-none { box-shadow: none !important; }
.w-25 { width: 25% !important; }
.w-50 { width: 50% !important; }
.w-75 { width: 75% !important; }
.w-100 { width: 100% !important; }
.w-auto { width: auto !important; }
.h-25 { height: 25% !important; }
.h-50 { height: 50% !important; }
.h-75 { height: 75% !important; }
.h-100 { height: 100% !important; }
.h-auto { height: auto !important; }
.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }
.min-vw-100 { min-width: 100vw !important; }
.min-vh-100 { min-height: 100vh !important; }
.vw-100 { width: 100vw !important; }
.vh-100 { height: 100vh !important; }
.m-0 { margin: 0 !important; }
.mt-0, .my-0 { margin-top: 0 !important; }
.mr-0, .mx-0 { margin-right: 0 !important; }
.mb-0, .my-0 { margin-bottom: 0 !important; }
.ml-0, .mx-0 { margin-left: 0 !important; }
.m-1 { margin: 0.25rem !important; }
.mt-1, .my-1 { margin-top: 0.25rem !important; }
.mr-1, .mx-1 { margin-right: 0.25rem !important; }
.mb-1, .my-1 { margin-bottom: 0.25rem !important; }
.ml-1, .mx-1 { margin-left: 0.25rem !important; }
.m-2 { margin: 0.5rem !important; }
.mt-2, .my-2 { margin-top: 0.5rem !important; }
.mr-2, .mx-2 { margin-right: 0.5rem !important; }
.mb-2, .my-2 { margin-bottom: 0.5rem !important; }
.ml-2, .mx-2 { margin-left: 0.5rem !important; }
.m-3 { margin: 1rem !important; }
.mt-3, .my-3 { margin-top: 1rem !important; }
.mr-3, .mx-3 { margin-right: 1rem !important; }
.mb-3, .my-3 { margin-bottom: 1rem !important; }
.ml-3, .mx-3 { margin-left: 1rem !important; }
.m-4 { margin: 1.5rem !important; }
.mt-4, .my-4 { margin-top: 1.5rem !important; }
.mr-4, .mx-4 { margin-right: 1.5rem !important; }
.mb-4, .my-4 { margin-bottom: 1.5rem !important; }
.ml-4, .mx-4 { margin-left: 1.5rem !important; }
.m-5 { margin: 3rem !important; }
.mt-5, .my-5 { margin-top: 3rem !important; }
.mr-5, .mx-5 { margin-right: 3rem !important; }
.mb-5, .my-5 { margin-bottom: 3rem !important; }
.ml-5, .mx-5 { margin-left: 3rem !important; }
.p-0 { padding: 0 !important; }
.pt-0, .py-0 { padding-top: 0 !important; }
.pr-0, .px-0 { padding-right: 0 !important; }
.pb-0, .py-0 { padding-bottom: 0 !important; }
.pl-0, .px-0 { padding-left: 0 !important; }
.p-1 { padding: 0.25rem !important; }
.pt-1, .py-1 { padding-top: 0.25rem !important; }
.pr-1, .px-1 { padding-right: 0.25rem !important; }
.pb-1, .py-1 { padding-bottom: 0.25rem !important; }
.pl-1, .px-1 { padding-left: 0.25rem !important; }
.p-2 { padding: 0.5rem !important; }
.pt-2, .py-2 { padding-top: 0.5rem !important; }
.pr-2, .px-2 { padding-right: 0.5rem !important; }
.pb-2, .py-2 { padding-bottom: 0.5rem !important; }
.pl-2, .px-2 { padding-left: 0.5rem !important; }
.p-3 { padding: 1rem !important; }
.pt-3, .py-3 { padding-top: 1rem !important; }
.pr-3, .px-3 { padding-right: 1rem !important; }
.pb-3, .py-3 { padding-bottom: 1rem !important; }
.pl-3, .px-3 { padding-left: 1rem !important; }
.p-4 { padding: 1.5rem !important; }
.pt-4, .py-4 { padding-top: 1.5rem !important; }
.pr-4, .px-4 { padding-right: 1.5rem !important; }
.pb-4, .py-4 { padding-bottom: 1.5rem !important; }
.pl-4, .px-4 { padding-left: 1.5rem !important; }
.p-5 { padding: 3rem !important; }
.pt-5, .py-5 { padding-top: 3rem !important; }
.pr-5, .px-5 { padding-right: 3rem !important; }
.pb-5, .py-5 { padding-bottom: 3rem !important; }
.pl-5, .px-5 { padding-left: 3rem !important; }
.m-n1 { margin: -0.25rem !important; }
.mt-n1, .my-n1 { margin-top: -0.25rem !important; }
.mr-n1, .mx-n1 { margin-right: -0.25rem !important; }
.mb-n1, .my-n1 { margin-bottom: -0.25rem !important; }
.ml-n1, .mx-n1 { margin-left: -0.25rem !important; }
.m-n2 { margin: -0.5rem !important; }
.mt-n2, .my-n2 { margin-top: -0.5rem !important; }
.mr-n2, .mx-n2 { margin-right: -0.5rem !important; }
.mb-n2, .my-n2 { margin-bottom: -0.5rem !important; }
.ml-n2, .mx-n2 { margin-left: -0.5rem !important; }
.m-n3 { margin: -1rem !important; }
.mt-n3, .my-n3 { margin-top: -1rem !important; }
.mr-n3, .mx-n3 { margin-right: -1rem !important; }
.mb-n3, .my-n3 { margin-bottom: -1rem !important; }
.ml-n3, .mx-n3 { margin-left: -1rem !important; }
.m-n4 { margin: -1.5rem !important; }
.mt-n4, .my-n4 { margin-top: -1.5rem !important; }
.mr-n4, .mx-n4 { margin-right: -1.5rem !important; }
.mb-n4, .my-n4 { margin-bottom: -1.5rem !important; }
.ml-n4, .mx-n4 { margin-left: -1.5rem !important; }
.m-n5 { margin: -3rem !important; }
.mt-n5, .my-n5 { margin-top: -3rem !important; }
.mr-n5, .mx-n5 { margin-right: -3rem !important; }
.mb-n5, .my-n5 { margin-bottom: -3rem !important; }
.ml-n5, .mx-n5 { margin-left: -3rem !important; }
.m-auto { margin: auto !important; }
.mt-auto, .my-auto { margin-top: auto !important; }
.mr-auto, .mx-auto { margin-right: auto !important; }
.mb-auto, .my-auto { margin-bottom: auto !important; }
.ml-auto, .mx-auto { margin-left: auto !important; }
@media (min-width: 576px) {
  .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 0.25rem !important; }
  .m-sm-2 { margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 0.5rem !important; }
  .m-sm-3 { margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 1rem !important; }
  .m-sm-4 { margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 1.5rem !important; }
  .m-sm-5 { margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 3rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 0.25rem !important; }
  .p-sm-2 { padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 0.5rem !important; }
  .p-sm-3 { padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 1rem !important; }
  .p-sm-4 { padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 1.5rem !important; }
  .p-sm-5 { padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 3rem !important; }
  .m-sm-n1 { margin: -0.25rem !important; }
  .mt-sm-n1, .my-sm-n1 { margin-top: -0.25rem !important; }
  .mr-sm-n1, .mx-sm-n1 { margin-right: -0.25rem !important; }
  .mb-sm-n1, .my-sm-n1 { margin-bottom: -0.25rem !important; }
  .ml-sm-n1, .mx-sm-n1 { margin-left: -0.25rem !important; }
  .m-sm-n2 { margin: -0.5rem !important; }
  .mt-sm-n2, .my-sm-n2 { margin-top: -0.5rem !important; }
  .mr-sm-n2, .mx-sm-n2 { margin-right: -0.5rem !important; }
  .mb-sm-n2, .my-sm-n2 { margin-bottom: -0.5rem !important; }
  .ml-sm-n2, .mx-sm-n2 { margin-left: -0.5rem !important; }
  .m-sm-n3 { margin: -1rem !important; }
  .mt-sm-n3, .my-sm-n3 { margin-top: -1rem !important; }
  .mr-sm-n3, .mx-sm-n3 { margin-right: -1rem !important; }
  .mb-sm-n3, .my-sm-n3 { margin-bottom: -1rem !important; }
  .ml-sm-n3, .mx-sm-n3 { margin-left: -1rem !important; }
  .m-sm-n4 { margin: -1.5rem !important; }
  .mt-sm-n4, .my-sm-n4 { margin-top: -1.5rem !important; }
  .mr-sm-n4, .mx-sm-n4 { margin-right: -1.5rem !important; }
  .mb-sm-n4, .my-sm-n4 { margin-bottom: -1.5rem !important; }
  .ml-sm-n4, .mx-sm-n4 { margin-left: -1.5rem !important; }
  .m-sm-n5 { margin: -3rem !important; }
  .mt-sm-n5, .my-sm-n5 { margin-top: -3rem !important; }
  .mr-sm-n5, .mx-sm-n5 { margin-right: -3rem !important; }
  .mb-sm-n5, .my-sm-n5 { margin-bottom: -3rem !important; }
  .ml-sm-n5, .mx-sm-n5 { margin-left: -3rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; }
}
@media (min-width: 768px) {
  .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 0.25rem !important; }
  .m-md-2 { margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 0.5rem !important; }
  .m-md-3 { margin: 1rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 1rem !important; }
  .m-md-4 { margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 1.5rem !important; }
  .m-md-5 { margin: 3rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 3rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 0.25rem !important; }
  .p-md-2 { padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 0.5rem !important; }
  .p-md-3 { padding: 1rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 1rem !important; }
  .p-md-4 { padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 1.5rem !important; }
  .p-md-5 { padding: 3rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 3rem !important; }
  .m-md-n1 { margin: -0.25rem !important; }
  .mt-md-n1, .my-md-n1 { margin-top: -0.25rem !important; }
  .mr-md-n1, .mx-md-n1 { margin-right: -0.25rem !important; }
  .mb-md-n1, .my-md-n1 { margin-bottom: -0.25rem !important; }
  .ml-md-n1, .mx-md-n1 { margin-left: -0.25rem !important; }
  .m-md-n2 { margin: -0.5rem !important; }
  .mt-md-n2, .my-md-n2 { margin-top: -0.5rem !important; }
  .mr-md-n2, .mx-md-n2 { margin-right: -0.5rem !important; }
  .mb-md-n2, .my-md-n2 { margin-bottom: -0.5rem !important; }
  .ml-md-n2, .mx-md-n2 { margin-left: -0.5rem !important; }
  .m-md-n3 { margin: -1rem !important; }
  .mt-md-n3, .my-md-n3 { margin-top: -1rem !important; }
  .mr-md-n3, .mx-md-n3 { margin-right: -1rem !important; }
  .mb-md-n3, .my-md-n3 { margin-bottom: -1rem !important; }
  .ml-md-n3, .mx-md-n3 { margin-left: -1rem !important; }
  .m-md-n4 { margin: -1.5rem !important; }
  .mt-md-n4, .my-md-n4 { margin-top: -1.5rem !important; }
  .mr-md-n4, .mx-md-n4 { margin-right: -1.5rem !important; }
  .mb-md-n4, .my-md-n4 { margin-bottom: -1.5rem !important; }
  .ml-md-n4, .mx-md-n4 { margin-left: -1.5rem !important; }
  .m-md-n5 { margin: -3rem !important; }
  .mt-md-n5, .my-md-n5 { margin-top: -3rem !important; }
  .mr-md-n5, .mx-md-n5 { margin-right: -3rem !important; }
  .mb-md-n5, .my-md-n5 { margin-bottom: -3rem !important; }
  .ml-md-n5, .mx-md-n5 { margin-left: -3rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; }
}
@media (min-width: 992px) {
  .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 0.25rem !important; }
  .m-lg-2 { margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 0.5rem !important; }
  .m-lg-3 { margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 1rem !important; }
  .m-lg-4 { margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 1.5rem !important; }
  .m-lg-5 { margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 3rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 0.25rem !important; }
  .p-lg-2 { padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 0.5rem !important; }
  .p-lg-3 { padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 1rem !important; }
  .p-lg-4 { padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 1.5rem !important; }
  .p-lg-5 { padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 3rem !important; }
  .m-lg-n1 { margin: -0.25rem !important; }
  .mt-lg-n1, .my-lg-n1 { margin-top: -0.25rem !important; }
  .mr-lg-n1, .mx-lg-n1 { margin-right: -0.25rem !important; }
  .mb-lg-n1, .my-lg-n1 { margin-bottom: -0.25rem !important; }
  .ml-lg-n1, .mx-lg-n1 { margin-left: -0.25rem !important; }
  .m-lg-n2 { margin: -0.5rem !important; }
  .mt-lg-n2, .my-lg-n2 { margin-top: -0.5rem !important; }
  .mr-lg-n2, .mx-lg-n2 { margin-right: -0.5rem !important; }
  .mb-lg-n2, .my-lg-n2 { margin-bottom: -0.5rem !important; }
  .ml-lg-n2, .mx-lg-n2 { margin-left: -0.5rem !important; }
  .m-lg-n3 { margin: -1rem !important; }
  .mt-lg-n3, .my-lg-n3 { margin-top: -1rem !important; }
  .mr-lg-n3, .mx-lg-n3 { margin-right: -1rem !important; }
  .mb-lg-n3, .my-lg-n3 { margin-bottom: -1rem !important; }
  .ml-lg-n3, .mx-lg-n3 { margin-left: -1rem !important; }
  .m-lg-n4 { margin: -1.5rem !important; }
  .mt-lg-n4, .my-lg-n4 { margin-top: -1.5rem !important; }
  .mr-lg-n4, .mx-lg-n4 { margin-right: -1.5rem !important; }
  .mb-lg-n4, .my-lg-n4 { margin-bottom: -1.5rem !important; }
  .ml-lg-n4, .mx-lg-n4 { margin-left: -1.5rem !important; }
  .m-lg-n5 { margin: -3rem !important; }
  .mt-lg-n5, .my-lg-n5 { margin-top: -3rem !important; }
  .mr-lg-n5, .mx-lg-n5 { margin-right: -3rem !important; }
  .mb-lg-n5, .my-lg-n5 { margin-bottom: -3rem !important; }
  .ml-lg-n5, .mx-lg-n5 { margin-left: -3rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; }
}
@media (min-width: 1200px) {
  .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 0.25rem !important; }
  .m-xl-2 { margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 0.5rem !important; }
  .m-xl-3 { margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 1rem !important; }
  .m-xl-4 { margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 1.5rem !important; }
  .m-xl-5 { margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 3rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 0.25rem !important; }
  .p-xl-2 { padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 0.5rem !important; }
  .p-xl-3 { padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 1rem !important; }
  .p-xl-4 { padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 1.5rem !important; }
  .p-xl-5 { padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 3rem !important; }
  .m-xl-n1 { margin: -0.25rem !important; }
  .mt-xl-n1, .my-xl-n1 { margin-top: -0.25rem !important; }
  .mr-xl-n1, .mx-xl-n1 { margin-right: -0.25rem !important; }
  .mb-xl-n1, .my-xl-n1 { margin-bottom: -0.25rem !important; }
  .ml-xl-n1, .mx-xl-n1 { margin-left: -0.25rem !important; }
  .m-xl-n2 { margin: -0.5rem !important; }
  .mt-xl-n2, .my-xl-n2 { margin-top: -0.5rem !important; }
  .mr-xl-n2, .mx-xl-n2 { margin-right: -0.5rem !important; }
  .mb-xl-n2, .my-xl-n2 { margin-bottom: -0.5rem !important; }
  .ml-xl-n2, .mx-xl-n2 { margin-left: -0.5rem !important; }
  .m-xl-n3 { margin: -1rem !important; }
  .mt-xl-n3, .my-xl-n3 { margin-top: -1rem !important; }
  .mr-xl-n3, .mx-xl-n3 { margin-right: -1rem !important; }
  .mb-xl-n3, .my-xl-n3 { margin-bottom: -1rem !important; }
  .ml-xl-n3, .mx-xl-n3 { margin-left: -1rem !important; }
  .m-xl-n4 { margin: -1.5rem !important; }
  .mt-xl-n4, .my-xl-n4 { margin-top: -1.5rem !important; }
  .mr-xl-n4, .mx-xl-n4 { margin-right: -1.5rem !important; }
  .mb-xl-n4, .my-xl-n4 { margin-bottom: -1.5rem !important; }
  .ml-xl-n4, .mx-xl-n4 { margin-left: -1.5rem !important; }
  .m-xl-n5 { margin: -3rem !important; }
  .mt-xl-n5, .my-xl-n5 { margin-top: -3rem !important; }
  .mr-xl-n5, .mx-xl-n5 { margin-right: -3rem !important; }
  .mb-xl-n5, .my-xl-n5 { margin-bottom: -3rem !important; }
  .ml-xl-n5, .mx-xl-n5 { margin-left: -3rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; }
}
.stretched-link::after { position: absolute; inset: 0; z-index: 1; pointer-events: auto; content: ""; background-color: rgba(0, 0, 0, 0); }
.text-monospace { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }
.text-justify { text-align: justify !important; }
.text-wrap { white-space: normal !important; }
.text-nowrap { white-space: nowrap !important; }
.text-truncate { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }
@media (min-width: 576px) {
  .text-sm-left { text-align: left !important; }
  .text-sm-right { text-align: right !important; }
  .text-sm-center { text-align: center !important; }
}
@media (min-width: 768px) {
  .text-md-left { text-align: left !important; }
  .text-md-right { text-align: right !important; }
  .text-md-center { text-align: center !important; }
}
@media (min-width: 992px) {
  .text-lg-left { text-align: left !important; }
  .text-lg-right { text-align: right !important; }
  .text-lg-center { text-align: center !important; }
}
@media (min-width: 1200px) {
  .text-xl-left { text-align: left !important; }
  .text-xl-right { text-align: right !important; }
  .text-xl-center { text-align: center !important; }
}
.text-lowercase { text-transform: lowercase !important; }
.text-uppercase { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }
.font-weight-light { font-weight: 300 !important; }
.font-weight-lighter { font-weight: lighter !important; }
.font-weight-normal { font-weight: 400 !important; }
.font-weight-bold { font-weight: 700 !important; }
.font-weight-bolder { font-weight: bolder !important; }
.font-italic { font-style: italic !important; }
.text-white { color: rgb(255, 255, 255) !important; }
.text-primary { color: rgb(24, 19, 92) !important; }
a.text-primary:hover, a.text-primary:focus { color: rgb(7, 6, 29) !important; }
.text-secondary { color: rgb(173, 173, 173) !important; }
a.text-secondary:hover, a.text-secondary:focus { color: rgb(135, 135, 135) !important; }
.text-success { color: rgb(40, 167, 69) !important; }
a.text-success:hover, a.text-success:focus { color: rgb(25, 105, 44) !important; }
.text-info { color: rgb(23, 162, 184) !important; }
a.text-info:hover, a.text-info:focus { color: rgb(15, 102, 116) !important; }
.text-warning { color: rgb(255, 193, 7) !important; }
a.text-warning:hover, a.text-warning:focus { color: rgb(186, 139, 0) !important; }
.text-danger { color: rgb(220, 53, 69) !important; }
a.text-danger:hover, a.text-danger:focus { color: rgb(167, 29, 42) !important; }
.text-light { color: rgb(239, 239, 239) !important; }
a.text-light:hover, a.text-light:focus { color: rgb(201, 201, 201) !important; }
.text-dark { color: rgb(54, 54, 54) !important; }
a.text-dark:hover, a.text-dark:focus { color: rgb(16, 16, 16) !important; }
.text-tertiary { color: rgb(100, 100, 135) !important; }
a.text-tertiary:hover, a.text-tertiary:focus { color: rgb(67, 67, 91) !important; }
.text-body { color: rgb(33, 37, 41) !important; }
.text-muted { color: rgb(108, 117, 125) !important; }
.text-black-50 { color: rgba(0, 0, 0, 0.5) !important; }
.text-white-50 { color: rgba(255, 255, 255, 0.5) !important; }
.text-hide { font-size: 0; color: transparent; text-shadow: none; background-color: transparent; border: 0; }
.text-decoration-none { text-decoration: none !important; }
.text-break { word-break: break-word !important; overflow-wrap: break-word !important; }
.text-reset { color: inherit !important; }
.visible { visibility: visible !important; }
.invisible { visibility: hidden !important; }
@media print {
  *, ::before, ::after { text-shadow: none !important; box-shadow: none !important; }
  a:not(.btn) { text-decoration: underline; }
  abbr[title]::after { content: " (" attr(title) ")"; }
  pre { white-space: pre-wrap !important; }
  pre, blockquote { border: 1px solid rgb(173, 181, 189); break-inside: avoid; }
  thead { display: table-header-group; }
  tr, img { break-inside: avoid; }
  p, h2, h3 { orphans: 3; widows: 3; }
  h2, h3 { break-after: avoid; }
  @page { size: a3; }
  body { min-width: 992px !important; }
  .container { min-width: 992px !important; }
  .navbar { display: none; }
  .badge { border: 1px solid rgb(0, 0, 0); }
  .table { border-collapse: collapse !important; }
  .table td, .table th { background-color: rgb(255, 255, 255) !important; }
  .table-bordered th, .table-bordered td { border: 1px solid rgb(222, 226, 230) !important; }
  .table-dark { color: inherit; }
  .table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody { border-color: rgb(222, 226, 230); }
  .table .thead-dark th { color: inherit; border-color: rgb(222, 226, 230); }
}
html { box-sizing: border-box; }
*, ::before, ::after { box-sizing: inherit; }
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }
@media (min-width: 576px) {
  .container, .container-sm { max-width: 540px; }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md { max-width: 720px; }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg { max-width: 960px; }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl { max-width: 1140px; }
}
.row { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }
.no-gutters { margin-right: 0; margin-left: 0; }
.no-gutters > .col, .no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto { position: relative; width: 100%; padding-right: 15px; padding-left: 15px; }
.col { flex-basis: 0; flex-grow: 1; max-width: 100%; }
.row-cols-1 > * { flex: 0 0 100%; max-width: 100%; }
.row-cols-2 > * { flex: 0 0 50%; max-width: 50%; }
.row-cols-3 > * { flex: 0 0 33.3333%; max-width: 33.3333%; }
.row-cols-4 > * { flex: 0 0 25%; max-width: 25%; }
.row-cols-5 > * { flex: 0 0 20%; max-width: 20%; }
.row-cols-6 > * { flex: 0 0 16.6667%; max-width: 16.6667%; }
.col-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
.col-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
.col-2 { flex: 0 0 16.6667%; max-width: 16.6667%; }
.col-3 { flex: 0 0 25%; max-width: 25%; }
.col-4 { flex: 0 0 33.3333%; max-width: 33.3333%; }
.col-5 { flex: 0 0 41.6667%; max-width: 41.6667%; }
.col-6 { flex: 0 0 50%; max-width: 50%; }
.col-7 { flex: 0 0 58.3333%; max-width: 58.3333%; }
.col-8 { flex: 0 0 66.6667%; max-width: 66.6667%; }
.col-9 { flex: 0 0 75%; max-width: 75%; }
.col-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
.col-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
.col-12 { flex: 0 0 100%; max-width: 100%; }
.order-first { order: -1; }
.order-last { order: 13; }
.order-0 { order: 0; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }
.order-4 { order: 4; }
.order-5 { order: 5; }
.order-6 { order: 6; }
.order-7 { order: 7; }
.order-8 { order: 8; }
.order-9 { order: 9; }
.order-10 { order: 10; }
.order-11 { order: 11; }
.order-12 { order: 12; }
.offset-1 { margin-left: 8.33333%; }
.offset-2 { margin-left: 16.6667%; }
.offset-3 { margin-left: 25%; }
.offset-4 { margin-left: 33.3333%; }
.offset-5 { margin-left: 41.6667%; }
.offset-6 { margin-left: 50%; }
.offset-7 { margin-left: 58.3333%; }
.offset-8 { margin-left: 66.6667%; }
.offset-9 { margin-left: 75%; }
.offset-10 { margin-left: 83.3333%; }
.offset-11 { margin-left: 91.6667%; }
@media (min-width: 576px) {
  .col-sm { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-sm-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-sm-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-sm-3 > * { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .row-cols-sm-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-sm-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-sm-6 > * { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-sm-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-sm-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-sm-2 { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .col-sm-5 { flex: 0 0 41.6667%; max-width: 41.6667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.3333%; max-width: 58.3333%; }
  .col-sm-8 { flex: 0 0 66.6667%; max-width: 66.6667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
  .col-sm-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
  .order-sm-first { order: -1; }
  .order-sm-last { order: 13; }
  .order-sm-0 { order: 0; }
  .order-sm-1 { order: 1; }
  .order-sm-2 { order: 2; }
  .order-sm-3 { order: 3; }
  .order-sm-4 { order: 4; }
  .order-sm-5 { order: 5; }
  .order-sm-6 { order: 6; }
  .order-sm-7 { order: 7; }
  .order-sm-8 { order: 8; }
  .order-sm-9 { order: 9; }
  .order-sm-10 { order: 10; }
  .order-sm-11 { order: 11; }
  .order-sm-12 { order: 12; }
  .offset-sm-0 { margin-left: 0; }
  .offset-sm-1 { margin-left: 8.33333%; }
  .offset-sm-2 { margin-left: 16.6667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.3333%; }
  .offset-sm-5 { margin-left: 41.6667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.3333%; }
  .offset-sm-8 { margin-left: 66.6667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.3333%; }
  .offset-sm-11 { margin-left: 91.6667%; }
}
@media (min-width: 768px) {
  .col-md { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-md-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-md-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-md-3 > * { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .row-cols-md-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-md-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-md-6 > * { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-md-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-md-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-md-2 { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .col-md-5 { flex: 0 0 41.6667%; max-width: 41.6667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.3333%; max-width: 58.3333%; }
  .col-md-8 { flex: 0 0 66.6667%; max-width: 66.6667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
  .col-md-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
  .order-md-first { order: -1; }
  .order-md-last { order: 13; }
  .order-md-0 { order: 0; }
  .order-md-1 { order: 1; }
  .order-md-2 { order: 2; }
  .order-md-3 { order: 3; }
  .order-md-4 { order: 4; }
  .order-md-5 { order: 5; }
  .order-md-6 { order: 6; }
  .order-md-7 { order: 7; }
  .order-md-8 { order: 8; }
  .order-md-9 { order: 9; }
  .order-md-10 { order: 10; }
  .order-md-11 { order: 11; }
  .order-md-12 { order: 12; }
  .offset-md-0 { margin-left: 0; }
  .offset-md-1 { margin-left: 8.33333%; }
  .offset-md-2 { margin-left: 16.6667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.3333%; }
  .offset-md-5 { margin-left: 41.6667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.3333%; }
  .offset-md-8 { margin-left: 66.6667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.3333%; }
  .offset-md-11 { margin-left: 91.6667%; }
}
@media (min-width: 992px) {
  .col-lg { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-lg-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-lg-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-lg-3 > * { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .row-cols-lg-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-lg-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-lg-6 > * { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-lg-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-lg-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-lg-2 { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .col-lg-5 { flex: 0 0 41.6667%; max-width: 41.6667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.3333%; max-width: 58.3333%; }
  .col-lg-8 { flex: 0 0 66.6667%; max-width: 66.6667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
  .col-lg-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
  .order-lg-first { order: -1; }
  .order-lg-last { order: 13; }
  .order-lg-0 { order: 0; }
  .order-lg-1 { order: 1; }
  .order-lg-2 { order: 2; }
  .order-lg-3 { order: 3; }
  .order-lg-4 { order: 4; }
  .order-lg-5 { order: 5; }
  .order-lg-6 { order: 6; }
  .order-lg-7 { order: 7; }
  .order-lg-8 { order: 8; }
  .order-lg-9 { order: 9; }
  .order-lg-10 { order: 10; }
  .order-lg-11 { order: 11; }
  .order-lg-12 { order: 12; }
  .offset-lg-0 { margin-left: 0; }
  .offset-lg-1 { margin-left: 8.33333%; }
  .offset-lg-2 { margin-left: 16.6667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.3333%; }
  .offset-lg-5 { margin-left: 41.6667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.3333%; }
  .offset-lg-8 { margin-left: 66.6667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.3333%; }
  .offset-lg-11 { margin-left: 91.6667%; }
}
@media (min-width: 1200px) {
  .col-xl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .row-cols-xl-1 > * { flex: 0 0 100%; max-width: 100%; }
  .row-cols-xl-2 > * { flex: 0 0 50%; max-width: 50%; }
  .row-cols-xl-3 > * { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .row-cols-xl-4 > * { flex: 0 0 25%; max-width: 25%; }
  .row-cols-xl-5 > * { flex: 0 0 20%; max-width: 20%; }
  .row-cols-xl-6 > * { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-xl-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-xl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-xl-2 { flex: 0 0 16.6667%; max-width: 16.6667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.3333%; max-width: 33.3333%; }
  .col-xl-5 { flex: 0 0 41.6667%; max-width: 41.6667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.3333%; max-width: 58.3333%; }
  .col-xl-8 { flex: 0 0 66.6667%; max-width: 66.6667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.3333%; max-width: 83.3333%; }
  .col-xl-11 { flex: 0 0 91.6667%; max-width: 91.6667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
  .order-xl-first { order: -1; }
  .order-xl-last { order: 13; }
  .order-xl-0 { order: 0; }
  .order-xl-1 { order: 1; }
  .order-xl-2 { order: 2; }
  .order-xl-3 { order: 3; }
  .order-xl-4 { order: 4; }
  .order-xl-5 { order: 5; }
  .order-xl-6 { order: 6; }
  .order-xl-7 { order: 7; }
  .order-xl-8 { order: 8; }
  .order-xl-9 { order: 9; }
  .order-xl-10 { order: 10; }
  .order-xl-11 { order: 11; }
  .order-xl-12 { order: 12; }
  .offset-xl-0 { margin-left: 0; }
  .offset-xl-1 { margin-left: 8.33333%; }
  .offset-xl-2 { margin-left: 16.6667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.3333%; }
  .offset-xl-5 { margin-left: 41.6667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.3333%; }
  .offset-xl-8 { margin-left: 66.6667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.3333%; }
  .offset-xl-11 { margin-left: 91.6667%; }
}
.d-none { display: none !important; }
.d-inline { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block { display: block !important; }
.d-table { display: table !important; }
.d-table-row { display: table-row !important; }
.d-table-cell { display: table-cell !important; }
.d-flex { display: flex !important; }
.d-inline-flex { display: inline-flex !important; }
@media (min-width: 576px) {
  .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: flex !important; }
  .d-sm-inline-flex { display: inline-flex !important; }
}
@media (min-width: 768px) {
  .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: flex !important; }
  .d-md-inline-flex { display: inline-flex !important; }
}
@media (min-width: 992px) {
  .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: flex !important; }
  .d-lg-inline-flex { display: inline-flex !important; }
}
@media (min-width: 1200px) {
  .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: flex !important; }
  .d-xl-inline-flex { display: inline-flex !important; }
}
@media print {
  .d-print-none { display: none !important; }
  .d-print-inline { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block { display: block !important; }
  .d-print-table { display: table !important; }
  .d-print-table-row { display: table-row !important; }
  .d-print-table-cell { display: table-cell !important; }
  .d-print-flex { display: flex !important; }
  .d-print-inline-flex { display: inline-flex !important; }
}
.flex-row { flex-direction: row !important; }
.flex-column { flex-direction: column !important; }
.flex-row-reverse { flex-direction: row-reverse !important; }
.flex-column-reverse { flex-direction: column-reverse !important; }
.flex-wrap { flex-wrap: wrap !important; }
.flex-nowrap { flex-wrap: nowrap !important; }
.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
.flex-fill { flex: 1 1 auto !important; }
.flex-grow-0 { flex-grow: 0 !important; }
.flex-grow-1 { flex-grow: 1 !important; }
.flex-shrink-0 { flex-shrink: 0 !important; }
.flex-shrink-1 { flex-shrink: 1 !important; }
.justify-content-start { justify-content: flex-start !important; }
.justify-content-end { justify-content: flex-end !important; }
.justify-content-center { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-around { justify-content: space-around !important; }
.align-items-start { align-items: flex-start !important; }
.align-items-end { align-items: flex-end !important; }
.align-items-center { align-items: center !important; }
.align-items-baseline { align-items: baseline !important; }
.align-items-stretch { align-items: stretch !important; }
.align-content-start { align-content: flex-start !important; }
.align-content-end { align-content: flex-end !important; }
.align-content-center { align-content: center !important; }
.align-content-between { align-content: space-between !important; }
.align-content-around { align-content: space-around !important; }
.align-content-stretch { align-content: stretch !important; }
.align-self-auto { align-self: auto !important; }
.align-self-start { align-self: flex-start !important; }
.align-self-end { align-self: flex-end !important; }
.align-self-center { align-self: center !important; }
.align-self-baseline { align-self: baseline !important; }
.align-self-stretch { align-self: stretch !important; }
@media (min-width: 576px) {
  .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-sm-fill { flex: 1 1 auto !important; }
  .flex-sm-grow-0 { flex-grow: 0 !important; }
  .flex-sm-grow-1 { flex-grow: 1 !important; }
  .flex-sm-shrink-0 { flex-shrink: 0 !important; }
  .flex-sm-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; }
}
@media (min-width: 768px) {
  .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-md-fill { flex: 1 1 auto !important; }
  .flex-md-grow-0 { flex-grow: 0 !important; }
  .flex-md-grow-1 { flex-grow: 1 !important; }
  .flex-md-shrink-0 { flex-shrink: 0 !important; }
  .flex-md-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; }
}
@media (min-width: 992px) {
  .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-lg-fill { flex: 1 1 auto !important; }
  .flex-lg-grow-0 { flex-grow: 0 !important; }
  .flex-lg-grow-1 { flex-grow: 1 !important; }
  .flex-lg-shrink-0 { flex-shrink: 0 !important; }
  .flex-lg-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; }
}
@media (min-width: 1200px) {
  .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-xl-fill { flex: 1 1 auto !important; }
  .flex-xl-grow-0 { flex-grow: 0 !important; }
  .flex-xl-grow-1 { flex-grow: 1 !important; }
  .flex-xl-shrink-0 { flex-shrink: 0 !important; }
  .flex-xl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; }
}
.m-0 { margin: 0 !important; }
.mt-0, .my-0 { margin-top: 0 !important; }
.mr-0, .mx-0 { margin-right: 0 !important; }
.mb-0, .my-0 { margin-bottom: 0 !important; }
.ml-0, .mx-0 { margin-left: 0 !important; }
.m-1 { margin: 0.25rem !important; }
.mt-1, .my-1 { margin-top: 0.25rem !important; }
.mr-1, .mx-1 { margin-right: 0.25rem !important; }
.mb-1, .my-1 { margin-bottom: 0.25rem !important; }
.ml-1, .mx-1 { margin-left: 0.25rem !important; }
.m-2 { margin: 0.5rem !important; }
.mt-2, .my-2 { margin-top: 0.5rem !important; }
.mr-2, .mx-2 { margin-right: 0.5rem !important; }
.mb-2, .my-2 { margin-bottom: 0.5rem !important; }
.ml-2, .mx-2 { margin-left: 0.5rem !important; }
.m-3 { margin: 1rem !important; }
.mt-3, .my-3 { margin-top: 1rem !important; }
.mr-3, .mx-3 { margin-right: 1rem !important; }
.mb-3, .my-3 { margin-bottom: 1rem !important; }
.ml-3, .mx-3 { margin-left: 1rem !important; }
.m-4 { margin: 1.5rem !important; }
.mt-4, .my-4 { margin-top: 1.5rem !important; }
.mr-4, .mx-4 { margin-right: 1.5rem !important; }
.mb-4, .my-4 { margin-bottom: 1.5rem !important; }
.ml-4, .mx-4 { margin-left: 1.5rem !important; }
.m-5 { margin: 3rem !important; }
.mt-5, .my-5 { margin-top: 3rem !important; }
.mr-5, .mx-5 { margin-right: 3rem !important; }
.mb-5, .my-5 { margin-bottom: 3rem !important; }
.ml-5, .mx-5 { margin-left: 3rem !important; }
.p-0 { padding: 0 !important; }
.pt-0, .py-0 { padding-top: 0 !important; }
.pr-0, .px-0 { padding-right: 0 !important; }
.pb-0, .py-0 { padding-bottom: 0 !important; }
.pl-0, .px-0 { padding-left: 0 !important; }
.p-1 { padding: 0.25rem !important; }
.pt-1, .py-1 { padding-top: 0.25rem !important; }
.pr-1, .px-1 { padding-right: 0.25rem !important; }
.pb-1, .py-1 { padding-bottom: 0.25rem !important; }
.pl-1, .px-1 { padding-left: 0.25rem !important; }
.p-2 { padding: 0.5rem !important; }
.pt-2, .py-2 { padding-top: 0.5rem !important; }
.pr-2, .px-2 { padding-right: 0.5rem !important; }
.pb-2, .py-2 { padding-bottom: 0.5rem !important; }
.pl-2, .px-2 { padding-left: 0.5rem !important; }
.p-3 { padding: 1rem !important; }
.pt-3, .py-3 { padding-top: 1rem !important; }
.pr-3, .px-3 { padding-right: 1rem !important; }
.pb-3, .py-3 { padding-bottom: 1rem !important; }
.pl-3, .px-3 { padding-left: 1rem !important; }
.p-4 { padding: 1.5rem !important; }
.pt-4, .py-4 { padding-top: 1.5rem !important; }
.pr-4, .px-4 { padding-right: 1.5rem !important; }
.pb-4, .py-4 { padding-bottom: 1.5rem !important; }
.pl-4, .px-4 { padding-left: 1.5rem !important; }
.p-5 { padding: 3rem !important; }
.pt-5, .py-5 { padding-top: 3rem !important; }
.pr-5, .px-5 { padding-right: 3rem !important; }
.pb-5, .py-5 { padding-bottom: 3rem !important; }
.pl-5, .px-5 { padding-left: 3rem !important; }
.m-n1 { margin: -0.25rem !important; }
.mt-n1, .my-n1 { margin-top: -0.25rem !important; }
.mr-n1, .mx-n1 { margin-right: -0.25rem !important; }
.mb-n1, .my-n1 { margin-bottom: -0.25rem !important; }
.ml-n1, .mx-n1 { margin-left: -0.25rem !important; }
.m-n2 { margin: -0.5rem !important; }
.mt-n2, .my-n2 { margin-top: -0.5rem !important; }
.mr-n2, .mx-n2 { margin-right: -0.5rem !important; }
.mb-n2, .my-n2 { margin-bottom: -0.5rem !important; }
.ml-n2, .mx-n2 { margin-left: -0.5rem !important; }
.m-n3 { margin: -1rem !important; }
.mt-n3, .my-n3 { margin-top: -1rem !important; }
.mr-n3, .mx-n3 { margin-right: -1rem !important; }
.mb-n3, .my-n3 { margin-bottom: -1rem !important; }
.ml-n3, .mx-n3 { margin-left: -1rem !important; }
.m-n4 { margin: -1.5rem !important; }
.mt-n4, .my-n4 { margin-top: -1.5rem !important; }
.mr-n4, .mx-n4 { margin-right: -1.5rem !important; }
.mb-n4, .my-n4 { margin-bottom: -1.5rem !important; }
.ml-n4, .mx-n4 { margin-left: -1.5rem !important; }
.m-n5 { margin: -3rem !important; }
.mt-n5, .my-n5 { margin-top: -3rem !important; }
.mr-n5, .mx-n5 { margin-right: -3rem !important; }
.mb-n5, .my-n5 { margin-bottom: -3rem !important; }
.ml-n5, .mx-n5 { margin-left: -3rem !important; }
.m-auto { margin: auto !important; }
.mt-auto, .my-auto { margin-top: auto !important; }
.mr-auto, .mx-auto { margin-right: auto !important; }
.mb-auto, .my-auto { margin-bottom: auto !important; }
.ml-auto, .mx-auto { margin-left: auto !important; }
@media (min-width: 576px) {
  .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 0.25rem !important; }
  .m-sm-2 { margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 0.5rem !important; }
  .m-sm-3 { margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 1rem !important; }
  .m-sm-4 { margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 1.5rem !important; }
  .m-sm-5 { margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 3rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 0.25rem !important; }
  .p-sm-2 { padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 0.5rem !important; }
  .p-sm-3 { padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 1rem !important; }
  .p-sm-4 { padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 1.5rem !important; }
  .p-sm-5 { padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 3rem !important; }
  .m-sm-n1 { margin: -0.25rem !important; }
  .mt-sm-n1, .my-sm-n1 { margin-top: -0.25rem !important; }
  .mr-sm-n1, .mx-sm-n1 { margin-right: -0.25rem !important; }
  .mb-sm-n1, .my-sm-n1 { margin-bottom: -0.25rem !important; }
  .ml-sm-n1, .mx-sm-n1 { margin-left: -0.25rem !important; }
  .m-sm-n2 { margin: -0.5rem !important; }
  .mt-sm-n2, .my-sm-n2 { margin-top: -0.5rem !important; }
  .mr-sm-n2, .mx-sm-n2 { margin-right: -0.5rem !important; }
  .mb-sm-n2, .my-sm-n2 { margin-bottom: -0.5rem !important; }
  .ml-sm-n2, .mx-sm-n2 { margin-left: -0.5rem !important; }
  .m-sm-n3 { margin: -1rem !important; }
  .mt-sm-n3, .my-sm-n3 { margin-top: -1rem !important; }
  .mr-sm-n3, .mx-sm-n3 { margin-right: -1rem !important; }
  .mb-sm-n3, .my-sm-n3 { margin-bottom: -1rem !important; }
  .ml-sm-n3, .mx-sm-n3 { margin-left: -1rem !important; }
  .m-sm-n4 { margin: -1.5rem !important; }
  .mt-sm-n4, .my-sm-n4 { margin-top: -1.5rem !important; }
  .mr-sm-n4, .mx-sm-n4 { margin-right: -1.5rem !important; }
  .mb-sm-n4, .my-sm-n4 { margin-bottom: -1.5rem !important; }
  .ml-sm-n4, .mx-sm-n4 { margin-left: -1.5rem !important; }
  .m-sm-n5 { margin: -3rem !important; }
  .mt-sm-n5, .my-sm-n5 { margin-top: -3rem !important; }
  .mr-sm-n5, .mx-sm-n5 { margin-right: -3rem !important; }
  .mb-sm-n5, .my-sm-n5 { margin-bottom: -3rem !important; }
  .ml-sm-n5, .mx-sm-n5 { margin-left: -3rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; }
}
@media (min-width: 768px) {
  .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 0.25rem !important; }
  .m-md-2 { margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 0.5rem !important; }
  .m-md-3 { margin: 1rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 1rem !important; }
  .m-md-4 { margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 1.5rem !important; }
  .m-md-5 { margin: 3rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 3rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 0.25rem !important; }
  .p-md-2 { padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 0.5rem !important; }
  .p-md-3 { padding: 1rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 1rem !important; }
  .p-md-4 { padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 1.5rem !important; }
  .p-md-5 { padding: 3rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 3rem !important; }
  .m-md-n1 { margin: -0.25rem !important; }
  .mt-md-n1, .my-md-n1 { margin-top: -0.25rem !important; }
  .mr-md-n1, .mx-md-n1 { margin-right: -0.25rem !important; }
  .mb-md-n1, .my-md-n1 { margin-bottom: -0.25rem !important; }
  .ml-md-n1, .mx-md-n1 { margin-left: -0.25rem !important; }
  .m-md-n2 { margin: -0.5rem !important; }
  .mt-md-n2, .my-md-n2 { margin-top: -0.5rem !important; }
  .mr-md-n2, .mx-md-n2 { margin-right: -0.5rem !important; }
  .mb-md-n2, .my-md-n2 { margin-bottom: -0.5rem !important; }
  .ml-md-n2, .mx-md-n2 { margin-left: -0.5rem !important; }
  .m-md-n3 { margin: -1rem !important; }
  .mt-md-n3, .my-md-n3 { margin-top: -1rem !important; }
  .mr-md-n3, .mx-md-n3 { margin-right: -1rem !important; }
  .mb-md-n3, .my-md-n3 { margin-bottom: -1rem !important; }
  .ml-md-n3, .mx-md-n3 { margin-left: -1rem !important; }
  .m-md-n4 { margin: -1.5rem !important; }
  .mt-md-n4, .my-md-n4 { margin-top: -1.5rem !important; }
  .mr-md-n4, .mx-md-n4 { margin-right: -1.5rem !important; }
  .mb-md-n4, .my-md-n4 { margin-bottom: -1.5rem !important; }
  .ml-md-n4, .mx-md-n4 { margin-left: -1.5rem !important; }
  .m-md-n5 { margin: -3rem !important; }
  .mt-md-n5, .my-md-n5 { margin-top: -3rem !important; }
  .mr-md-n5, .mx-md-n5 { margin-right: -3rem !important; }
  .mb-md-n5, .my-md-n5 { margin-bottom: -3rem !important; }
  .ml-md-n5, .mx-md-n5 { margin-left: -3rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; }
}
@media (min-width: 992px) {
  .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 0.25rem !important; }
  .m-lg-2 { margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 0.5rem !important; }
  .m-lg-3 { margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 1rem !important; }
  .m-lg-4 { margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 1.5rem !important; }
  .m-lg-5 { margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 3rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 0.25rem !important; }
  .p-lg-2 { padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 0.5rem !important; }
  .p-lg-3 { padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 1rem !important; }
  .p-lg-4 { padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 1.5rem !important; }
  .p-lg-5 { padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 3rem !important; }
  .m-lg-n1 { margin: -0.25rem !important; }
  .mt-lg-n1, .my-lg-n1 { margin-top: -0.25rem !important; }
  .mr-lg-n1, .mx-lg-n1 { margin-right: -0.25rem !important; }
  .mb-lg-n1, .my-lg-n1 { margin-bottom: -0.25rem !important; }
  .ml-lg-n1, .mx-lg-n1 { margin-left: -0.25rem !important; }
  .m-lg-n2 { margin: -0.5rem !important; }
  .mt-lg-n2, .my-lg-n2 { margin-top: -0.5rem !important; }
  .mr-lg-n2, .mx-lg-n2 { margin-right: -0.5rem !important; }
  .mb-lg-n2, .my-lg-n2 { margin-bottom: -0.5rem !important; }
  .ml-lg-n2, .mx-lg-n2 { margin-left: -0.5rem !important; }
  .m-lg-n3 { margin: -1rem !important; }
  .mt-lg-n3, .my-lg-n3 { margin-top: -1rem !important; }
  .mr-lg-n3, .mx-lg-n3 { margin-right: -1rem !important; }
  .mb-lg-n3, .my-lg-n3 { margin-bottom: -1rem !important; }
  .ml-lg-n3, .mx-lg-n3 { margin-left: -1rem !important; }
  .m-lg-n4 { margin: -1.5rem !important; }
  .mt-lg-n4, .my-lg-n4 { margin-top: -1.5rem !important; }
  .mr-lg-n4, .mx-lg-n4 { margin-right: -1.5rem !important; }
  .mb-lg-n4, .my-lg-n4 { margin-bottom: -1.5rem !important; }
  .ml-lg-n4, .mx-lg-n4 { margin-left: -1.5rem !important; }
  .m-lg-n5 { margin: -3rem !important; }
  .mt-lg-n5, .my-lg-n5 { margin-top: -3rem !important; }
  .mr-lg-n5, .mx-lg-n5 { margin-right: -3rem !important; }
  .mb-lg-n5, .my-lg-n5 { margin-bottom: -3rem !important; }
  .ml-lg-n5, .mx-lg-n5 { margin-left: -3rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; }
}
@media (min-width: 1200px) {
  .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 0.25rem !important; }
  .m-xl-2 { margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 0.5rem !important; }
  .m-xl-3 { margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 1rem !important; }
  .m-xl-4 { margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 1.5rem !important; }
  .m-xl-5 { margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 3rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 0.25rem !important; }
  .p-xl-2 { padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 0.5rem !important; }
  .p-xl-3 { padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 1rem !important; }
  .p-xl-4 { padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 1.5rem !important; }
  .p-xl-5 { padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 3rem !important; }
  .m-xl-n1 { margin: -0.25rem !important; }
  .mt-xl-n1, .my-xl-n1 { margin-top: -0.25rem !important; }
  .mr-xl-n1, .mx-xl-n1 { margin-right: -0.25rem !important; }
  .mb-xl-n1, .my-xl-n1 { margin-bottom: -0.25rem !important; }
  .ml-xl-n1, .mx-xl-n1 { margin-left: -0.25rem !important; }
  .m-xl-n2 { margin: -0.5rem !important; }
  .mt-xl-n2, .my-xl-n2 { margin-top: -0.5rem !important; }
  .mr-xl-n2, .mx-xl-n2 { margin-right: -0.5rem !important; }
  .mb-xl-n2, .my-xl-n2 { margin-bottom: -0.5rem !important; }
  .ml-xl-n2, .mx-xl-n2 { margin-left: -0.5rem !important; }
  .m-xl-n3 { margin: -1rem !important; }
  .mt-xl-n3, .my-xl-n3 { margin-top: -1rem !important; }
  .mr-xl-n3, .mx-xl-n3 { margin-right: -1rem !important; }
  .mb-xl-n3, .my-xl-n3 { margin-bottom: -1rem !important; }
  .ml-xl-n3, .mx-xl-n3 { margin-left: -1rem !important; }
  .m-xl-n4 { margin: -1.5rem !important; }
  .mt-xl-n4, .my-xl-n4 { margin-top: -1.5rem !important; }
  .mr-xl-n4, .mx-xl-n4 { margin-right: -1.5rem !important; }
  .mb-xl-n4, .my-xl-n4 { margin-bottom: -1.5rem !important; }
  .ml-xl-n4, .mx-xl-n4 { margin-left: -1.5rem !important; }
  .m-xl-n5 { margin: -3rem !important; }
  .mt-xl-n5, .my-xl-n5 { margin-top: -3rem !important; }
  .mr-xl-n5, .mx-xl-n5 { margin-right: -3rem !important; }
  .mb-xl-n5, .my-xl-n5 { margin-bottom: -3rem !important; }
  .ml-xl-n5, .mx-xl-n5 { margin-left: -3rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; }
}
.data-table-wrapper { width: 100%; }
html { height: 100%; }
body { background: rgb(54, 54, 54); height: auto; font-size: 1em; text-align: left; margin-top: 100px; }
button:disabled { cursor: not-allowed; }
legend { width: auto; }
.outer fieldset { border-color: rgb(24, 19, 92); display: flex; border-width: 2px; box-shadow: rgb(24, 19, 92) 2px 5px 5px; border-style: solid; padding: 10px; width: 100%; height: 88%; text-align: center; }
.outer fieldset > .row > div:not(:first-child) { overflow-y: auto; }
.bulk_labels > div { line-height: 5mm; padding: 3mm; height: 30mm; width: 70mm; margin: 0; display: inline-grid; text-align: center !important; }
.bulk_labels > div > h5 { width: 100%; text-align: center !important; }
.outer .btn { overflow: hidden; text-overflow: ellipsis; line-height: 1em; font-size: 1.0em; padding: 0.2em; width: 90%; margin: 0.2em; }
.livetracker-popup .btn { overflow: hidden; text-overflow: ellipsis; }
.outer .btn > small { font-size: 0.5em; }
.outer .btn.hidden { opacity: 0; }
@keyframes fade-in { 
  0% { opacity: 0.5; background-color: rgb(173, 173, 173); border-color: rgb(173, 173, 173); }
  100% { opacity: 1; background-color: rgb(24, 19, 92); border-color: rgb(24, 19, 92); }
}
.fade-in { animation-name: fade-in; animation-iteration-count: 1; animation-duration: 3500ms; }
.outer fieldset h3 { margin-right: 5px; }
.outer fieldset .row { margin: 0; }
.outer fieldset .row > div:not(:first-child) { border-radius: 10px; border: 1px solid rgb(239, 239, 239); height: 90%; margin-left: -1px; margin-right: -1px; }
.outer fieldset .row > div h3 { text-align: center; width: 100%; color: rgb(239, 239, 239); }
.outer legend { height: auto; }
.outer { height: 80vh; }
.pointer { cursor: pointer; }
#root { height: inherit; }
@keyframes slideInFromLeft { 
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0px); }
}
@keyframes slideInFromRight { 
  0% { transform: translateX(100%); }
  100% { transform: translateX(0px); }
}
table.print-body > tbody > tr.bay-row > td:first-child, table.print-body > tbody > tr.bay-row > td { font-size: 30px !important; }
td > span.smaller-span, td:first-child > span.smaller-span { font-size: 0.7em !important; }
.not-rounded { border-radius: 0 !important; }
.not-rounded-left { border-bottom-left-radius: 0 !important; border-top-left-radius: 0 !important; }
.popover { max-width: 600px; min-width: 600px; height: 500px; overflow-y: auto; }
@media only screen and (max-width: 1200px) {
  .popover { max-width: 300px; min-width: 300px; height: 300px; }
}
table.dataTable tbody tr { background-color: transparent; }
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active, .paginate_button, .dataTables_wrapper .dataTables_paginate .paginate_button { border-radius: 10px; background-color: rgb(24, 19, 92); color: rgb(239, 239, 239) !important; }
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover { background: none rgb(100, 100, 135); color: rgb(239, 239, 239) !important; }
.dataTables_wrapper input, .dataTables_wrapper select { color: rgb(54, 54, 54); background-color: white !important; }
table.dataTable tbody tr td:first-child, table.dataTable tbody tr td:first-child h2 { background-color: transparent; color: rgb(239, 239, 239); }
table.dataTable tbody tr td:not(:first-child) { background-color: rgb(239, 239, 239); color: rgb(54, 54, 54); border-right: 1px solid rgb(24, 19, 92); }
.active-bay, .active_bay:hover { box-shadow: rgb(40, 167, 69) 0 0 10px !important; }
.popover .nav-link[data-rb-event-key="product"] { border-bottom: 2px solid rgb(40, 167, 69); }
.popover .nav-link[data-rb-event-key="empty"] { border-bottom: 2px solid rgb(220, 53, 69); }
.popover-header { background-color: rgb(24, 19, 92); }
.moveButtons { animation: 1s ease-out 0s 1 normal none running slideInFromLeft; }
.proceedButtons { animation: 1s ease-out 0s 1 normal none running slideInFromRight; }
h1, h2, h3, h4, h5, h6 { color: rgb(24, 19, 92); font-family: Agency, Arial, serif; text-align: left; }
@keyframes blinker { 
  50% { top: 1em; right: 3em; }
}
.rotate-45-right { right: 4em; z-index: 1030; top: 2em; font-size: 2em; position: fixed; color: rgb(173, 173, 173); animation: 2s ease 0s infinite normal none running blinker; transform: rotate(45deg); pointer-events: none; }
:hover > .show-on-hover { visibility: visible; }
* > .show-on-hover { visibility: hidden; }
#navbar-toggle:focus, .navbar a.nav-link:focus { outline: none; background-color: rgb(24, 19, 92) !important; }
#basic-navbar-nav { background-color: rgb(24, 19, 92) !important; }
.navbar { height: 100px; font-family: Agency, serif; font-size: 1.5em; }
.navbar-brand img { width: 200px; }
.navbar-collapse.collapse, .navbar-collapse.collapsing, .navbar-collapse.collapsed { width: 100%; text-align: center; border-width: 0 23px 15px; border-color: rgb(173, 173, 173); border-style: solid; padding: 15px 15px 3px; margin: 0 -16px; }
@media (min-width: 992px) {
  .navbar-collapse.collapse, .navbar-collapse.collapsing, .navbar-collapse.collapsed { border: none; }
}
.navbar-brand { text-align: left; }
.navbar a:focus, .navbar button:focus { background-color: rgb(100, 100, 135) !important; }
.navbar a, .navbar button { font-size: 1.5rem; }
.noselect { user-select: none; }
.errors > p { color: white; background: darkred; padding: 15px; text-align: left; }
.Toastify__toast-container { min-width: 320px; text-align: center; width: 30% !important; }
.toastmsg { width: 100%; }
.menu-button { background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-color: rgb(24, 19, 92); display: inline-block; padding: 38px; margin: 0; color: white; font-size: 2em; width: 99.999%; position: relative; border: 3px solid rgba(255, 255, 255, 0) !important; background-clip: padding-box !important; }
.dashboard-menu { text-align: center; filter: none; }
.dashboard-menu section { padding-bottom: 5px !important; }
.dashboard-menu section.bg-success { background-color: rgb(216, 246, 223) !important; }
.dashboard-menu section.bg-danger { background-color: rgb(250, 227, 229) !important; }
.gate-pass-header { font-size: 3em; text-align: center; }
.dashboard-menu h4 { padding: 0 10px; font-size: 1.5em; font-weight: bold; text-align: center; }
fieldset.livetracker-popup { display: inline; }
fieldset { border-color: rgb(239, 239, 239); display: grid; border-width: 1px; border-style: solid; padding: 10px; width: 25%; text-align: center; vertical-align: top; }
legend { font-size: 12pt; width: auto; }
.menu-button.small { width: 33.3333%; }
.menu-button.medium { width: 50%; }
.print-footer { font-size: 25px; position: fixed; bottom: 10px; border: 1px solid black; border-collapse: collapse; }
.print-footer td, .print-footer tr, .print-footer { border: 1px solid black !important; }
.print-footer td.bg-secondary { text-align: right !important; }
table.print-body > tbody > tr > td { text-align: center !important; font-size: 30px !important; border: 1px solid black !important; }
table.print-body > tbody > tr > td:first-child { font-size: 25px !important; }
@media only screen and (max-width: 1650px) {
  fieldset { width: 33%; }
}
@media only screen and (max-width: 1200px) {
  fieldset { width: 50%; }
}
@media only screen and (max-width: 800px) {
  fieldset { width: 100%; }
  fieldset .small { font-size: 0.75rem !important; }
  #org_manager, #org-manager { position: fixed; right: 10%; top: 110px; font-size: 0.9em; background-color: rgb(23, 162, 184); border-radius: 15px; padding: 5px !important; }
  .banner { z-index: 20000; height: 210px !important; }
  #org_manager svg, #org-manager svg { display: none; }
  #org-manager > h1 { padding: 3px !important; }
  .menu-button { width: 98%; font-size: 1.5em; }
  .menu-button.small { width: 98%; }
  .menu-button.medium { width: 98%; }
}
.menu-button.grey { background: rgb(100, 100, 135); }
.menu-icon { width: 50px; position: absolute; bottom: 5px; right: 5px; }
.interactive { cursor: pointer; }
.nav-link.selected { text-decoration: underline; }
.banner { position: fixed; top: 0; background: rgb(100, 100, 135); left: 0; width: 100%; height: 100px; padding: 10px; line-height: 80px; color: rgb(239, 239, 239); text-align: center; font-size: 1.3em; z-index: 20000; }
.error_message { text-align: center; background: rgb(24, 19, 92); padding: 45px; color: rgb(239, 239, 239) !important; }
.error_message h3 { text-align: center; width: 100%; color: rgb(239, 239, 239) !important; }
.error_message p { font-size: 0.9em; text-align: justify; }
.customers { font-size: 1em; }
.customer > div { padding-left: 0 !important; padding-right: 0 !important; }
.products { font-size: 1em; }
.mobile-label { display: none; font-weight: bold; }
.show-text-on-hover { padding: 3px 0.5em; }
.show-text-on-hover > span { display: none; margin-left: 0.5em; background-color: rgb(239, 239, 239); color: rgb(24, 19, 92); border-radius: 0.5em; padding-left: 0.2em; padding-right: 0.2em; font-size: 0.8em; }
.show-text-on-hover > * { pointer-events: none; }
:hover > .show-text-on-hover > span { display: inline-block; }
@media only screen and (max-width: 800px) {
  th { display: none; }
  td.text-center { text-align: left !important; }
  td, tr { display: block; width: 100%; }
  div[role="pagination"] { width: 100% !important; margin-top: 5px !important; }
  .cm-progress-bar { font-size: 0.8em; }
  .customers { font-size: 0.8em; }
  .customers > div:not(.customer) { display: none; }
  .products { font-size: 0.8em; }
  .products > div:not(.product) { display: none; }
  .mobile-label { display: inline; }
  .header-underline { display: none; }
  .not-mobile { display: none; }
  h3 { font-size: 18pt !important; }
}
.search-input:focus { border-color: inherit; box-shadow: none; }
.search-input { border: none; caret-color: rgb(24, 19, 92); }
.search-input-append { border: none; }
.clear-button { background: white; border: none; }
.toggle { background: rgb(24, 19, 92); height: 20px; width: 20px; display: inline-block; border-radius: 10px; transition: background-color 0.8s ease 0s; padding: 0; color: white; border: 1px solid rgb(24, 19, 92); }
.toggle > div { display: inline; }
.toggle-outer { width: 40px; height: 20px; background: rgb(40, 167, 69); transition: background-color 0.8s ease 0s; border-radius: 10px; display: inline-block; margin: auto auto auto 3px; }
.toggle-outer.off { background-color: rgb(220, 53, 69); }
.toggle-inner { width: 100%; transition: width 0.8s ease 0s; text-align: right; }
.off > .toggle-inner { width: 0; }
.image_icon { max-width: 30px; }
.RSPBstep { min-width: 20%; }
.crate-delete { position: absolute; top: 3px; right: 3px; }
.print-only { display: none; }
#inner-content-table { overflow: hidden scroll; width: 100%; }
#inner-content-table table { width: 100%; }
@media print {
  div { display: block; overflow: visible; }
  style, script { display: none; }
  button { color: rgb(54, 54, 54) !important; font-size: 0.7em !important; background-color: rgb(255, 255, 255) !important; }
  div { background: none !important; }
  h3 { font-size: 1.3em !important; }
  .no-print { display: none; }
  .print-only { display: block; }
  .bays { font-size: 1.2em; background: none !important; width: 100% !important; height: auto !important; }
  .bays .row { width: 100% !important; }
  .gate-pass-info-table h3 { margin-bottom: 3px !important; }
  .progress-bar { font-size: 2em; background-color: rgb(24, 19, 92); }
  .progress { height: 2.2em; }
  #printarea, .print-12 { height: auto; padding-left: 10mm; padding-right: 10mm; width: 95% !important; background: none !important; }
  table { font-size: 1em; }
  table.history { font-size: 0.7em; }
  html, body { height: auto; margin-top: 0; }
  div.print-product { margin: 5mm; break-inside: avoid; }
  div.print-section { break-after: page; }
  input { height: 20mm !important; }
  .col-2 > input { height: 15mm !important; }
  .small-text { font-size: 0.7em; }
  @page { margin: 10mm; }
  #footer { opacity: 0.95; padding: 0; position: static; bottom: 0; width: 100%; }
  #bulk_list { margin-bottom: 1in; bottom: 1in; break-inside: auto; }
  #footer > table { width: 100%; clear: both; font-size: 17pt !important; }
  p { font-size: 12pt !important; }
  .w-75 { width: 90% !important; }
  .w-20 { width: 15% !important; }
  .w-5 { width: 10% !important; }
  footer { position: fixed; bottom: 0; text-align: center; width: 100%; }
  .container-number { margin: 0; font-size: 3em !important; }
  .inline-block, .inline-block div { display: inline-block; }
  .col-2 { display: inline-block; font-size: 12pt; width: 14% !important; }
  .col-2.no-print { display: none; }
  h5 { font-family: Arial, serif; }
  .inner-content-table { width: auto; padding-left: 10mm; padding-right: 10mm; display: block; max-height: 10000px !important; height: auto !important; overflow: hidden auto !important; background: none !important; }
  tr, table.history { break-inside: avoid !important; }
  thead, tbody { display: table-row-group; }
  div::-webkit-scrollbar { width: 0 !important; }
  .item-row.product { margin-bottom: 5px; break-inside: avoid; background-color: white !important; }
  .row { display: flex !important; }
  td { text-align: left !important; font-size: 0.9em !important; }
  td.big-letters span { font-size: 30px !important; }
  td.text-center, th.text-center { text-align: left !important; }
  table.text-center > tbody td { border-color: black !important; border-width: 1px !important; font-size: 1.2em !important; }
  table.text-center td.emphasize { font-size: 1.2em !important; }
  table.no-border, table.text-center table.no-border td { border-bottom-width: 0 !important; border-top-width: 0 !important; }
  table.text-center table.no-border td:first-child { border-left-width: 0 !important; }
  table.text-center table.no-border td:last-child { border-right-width: 0 !important; }
  table.text-center td.text-center { text-align: center !important; }
  td.bg-secondary { background-color: rgb(238, 238, 238) !important; }
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { appearance: none; margin: 0; }
input[type="number"] { }
@media print {
  .police-gate-pass table:not(:last-child) { text-align: center !important; }
  table.police-gate-pass:last-child * { vertical-align: top; padding-top: 2mm; text-align: left !important; }
  table.police-gate-pass:last-child { width: 95% !important; margin: auto !important; }
  .police-gate-pass p { font-size: 1.2em; text-align: left !important; }
  .police-gate-pass table:last-child td { vertical-align: top; padding: 2mm; }
  .police-gate-pass table:last-child tr { height: 70px; }
  .police-gate-pass.footer tr { height: 170px; }
  .police-gate-pass h3 { text-align: center; width: 100%; font-size: 3em !important; }
  .police-gate-pass td:first-child { font-size: 1.4em !important; background-color: rgb(239, 239, 239) !important; }
  .police-gate-pass.middle td { text-align: center !important; }
  .police-gate-pass td:last-child { font-size: 2em !important; }
  .police-gate-pass.middle td:last-child { font-size: 1.4em !important; }
  table:last-child.police-gate-pass tr { height: 70px; min-height: 70px; }
  table:last-child.police-gate-pass tr:last-child { height: 200px; min-height: 200px; }
}
.back-button { position: fixed; top: 115px; left: 15px; z-index: 100; }
.out-arrow { transform: rotate(-90deg); }
</style><style id="react-to-print-3">.App { overflow-y: auto; height: inherit; }
.dropdown-menu { max-height: 500px; overflow-y: scroll; }
</style><style id="react-to-print-4">.Toastify__toast-container { z-index: 9999; transform: translate3d(0px, 0, 9999px); position: fixed; padding: 4px; width: 320px; box-sizing: border-box; color: rgb(255, 255, 255); }
.Toastify__toast-container--top-left { top: 1em; left: 1em; }
.Toastify__toast-container--top-center { top: 1em; left: 50%; margin-left: -160px; }
.Toastify__toast-container--top-right { top: 1em; right: 1em; }
.Toastify__toast-container--bottom-left { bottom: 1em; left: 1em; }
.Toastify__toast-container--bottom-center { bottom: 1em; left: 50%; margin-left: -160px; }
.Toastify__toast-container--bottom-right { bottom: 1em; right: 1em; }
@media only screen and (max-width: 480px) {
  .Toastify__toast-container { width: 100vw; padding: 0; left: 0; margin: 0; }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right { top: 0; }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right { bottom: 0; }
  .Toastify__toast-container--rtl { right: 0; left: initial; }
}
.Toastify__toast { position: relative; min-height: 64px; box-sizing: border-box; margin-bottom: 1rem; padding: 8px; border-radius: 1px; box-shadow: rgba(0, 0, 0, 0.1) 0 1px 10px 0, rgba(0, 0, 0, 0.05) 0 2px 15px 0; display: flex; justify-content: space-between; max-height: 800px; overflow: hidden; font-family: sans-serif; cursor: pointer; direction: ltr; }
.Toastify__toast--rtl { direction: rtl; }
.Toastify__toast--default { background: rgb(255, 255, 255); color: rgb(170, 170, 170); }
.Toastify__toast--info { background: rgb(52, 152, 219); }
.Toastify__toast--success { background: rgb(7, 188, 12); }
.Toastify__toast--warning { background: rgb(241, 196, 15); }
.Toastify__toast--error { background: rgb(231, 76, 60); }
.Toastify__toast-body { margin: auto 0; flex: 1 1 0; }
@media only screen and (max-width: 480px) {
  .Toastify__toast { margin-bottom: 0; }
}
.Toastify__close-button { color: rgb(255, 255, 255); font-weight: bold; font-size: 14px; background: transparent; outline: none; border: none; padding: 0; cursor: pointer; opacity: 0.7; transition: all 0.3s ease 0s; align-self: flex-start; }
.Toastify__close-button--default { color: rgb(0, 0, 0); opacity: 0.3; }
.Toastify__close-button:hover, .Toastify__close-button:focus { opacity: 1; }
@keyframes Toastify__trackProgress { 
  0% { transform: scaleX(1); }
  100% { transform: scaleX(0); }
}
.Toastify__progress-bar { position: absolute; bottom: 0; left: 0; width: 100%; height: 5px; z-index: 9999; opacity: 0.7; background-color: rgba(255, 255, 255, 0.7); transform-origin: left center; }
.Toastify__progress-bar--animated { animation: 0s linear 0s 1 normal forwards running Toastify__trackProgress; }
.Toastify__progress-bar--controlled { transition: transform 0.2s ease 0s; }
.Toastify__progress-bar--rtl { right: 0; left: initial; transform-origin: right center; }
.Toastify__progress-bar--default { background: linear-gradient(to right, rgb(76, 217, 100), rgb(90, 200, 250), rgb(0, 122, 255), rgb(52, 170, 220), rgb(88, 86, 214), rgb(255, 45, 85)); }
@keyframes Toastify__bounceInRight { 
  0%, 60%, 75%, 90%, 100% { animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% { opacity: 0; transform: translate3d(3000px, 0, 0); }
  60% { opacity: 1; transform: translate3d(-25px, 0, 0); }
  75% { transform: translate3d(10px, 0, 0); }
  90% { transform: translate3d(-5px, 0, 0); }
  100% { transform: none; }
}
@keyframes Toastify__bounceOutRight { 
  20% { opacity: 1; transform: translate3d(-20px, 0, 0); }
  100% { opacity: 0; transform: translate3d(2000px, 0, 0); }
}
@keyframes Toastify__bounceInLeft { 
  0%, 60%, 75%, 90%, 100% { animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% { opacity: 0; transform: translate3d(-3000px, 0, 0); }
  60% { opacity: 1; transform: translate3d(25px, 0, 0); }
  75% { transform: translate3d(-10px, 0, 0); }
  90% { transform: translate3d(5px, 0, 0); }
  100% { transform: none; }
}
@keyframes Toastify__bounceOutLeft { 
  20% { opacity: 1; transform: translate3d(20px, 0, 0); }
  100% { opacity: 0; transform: translate3d(-2000px, 0, 0); }
}
@keyframes Toastify__bounceInUp { 
  0%, 60%, 75%, 90%, 100% { animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% { opacity: 0; transform: translate3d(0px, 3000px, 0); }
  60% { opacity: 1; transform: translate3d(0px, -20px, 0); }
  75% { transform: translate3d(0px, 10px, 0); }
  90% { transform: translate3d(0px, -5px, 0); }
  100% { transform: translate3d(0px, 0, 0); }
}
@keyframes Toastify__bounceOutUp { 
  20% { transform: translate3d(0px, -10px, 0); }
  40%, 45% { opacity: 1; transform: translate3d(0px, 20px, 0); }
  100% { opacity: 0; transform: translate3d(0px, -2000px, 0); }
}
@keyframes Toastify__bounceInDown { 
  0%, 60%, 75%, 90%, 100% { animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% { opacity: 0; transform: translate3d(0px, -3000px, 0); }
  60% { opacity: 1; transform: translate3d(0px, 25px, 0); }
  75% { transform: translate3d(0px, -10px, 0); }
  90% { transform: translate3d(0px, 5px, 0); }
  100% { transform: none; }
}
@keyframes Toastify__bounceOutDown { 
  20% { transform: translate3d(0px, 10px, 0); }
  40%, 45% { opacity: 1; transform: translate3d(0px, -20px, 0); }
  100% { opacity: 0; transform: translate3d(0px, 2000px, 0); }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left { animation-name: Toastify__bounceInLeft; }
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right { animation-name: Toastify__bounceInRight; }
.Toastify__bounce-enter--top-center { animation-name: Toastify__bounceInDown; }
.Toastify__bounce-enter--bottom-center { animation-name: Toastify__bounceInUp; }
.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left { animation-name: Toastify__bounceOutLeft; }
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right { animation-name: Toastify__bounceOutRight; }
.Toastify__bounce-exit--top-center { animation-name: Toastify__bounceOutUp; }
.Toastify__bounce-exit--bottom-center { animation-name: Toastify__bounceOutDown; }
@keyframes Toastify__zoomIn { 
  0% { opacity: 0; transform: scale3d(0.3, 0.3, 0.3); }
  50% { opacity: 1; }
}
@keyframes Toastify__zoomOut { 
  0% { opacity: 1; }
  50% { opacity: 0; transform: scale3d(0.3, 0.3, 0.3); }
  100% { opacity: 0; }
}
.Toastify__zoom-enter { animation-name: Toastify__zoomIn; }
.Toastify__zoom-exit { animation-name: Toastify__zoomOut; }
@keyframes Toastify__flipIn { 
  0% { transform: perspective(400px) rotate3d(1, 0, 0, 90deg); animation-timing-function: ease-in; opacity: 0; }
  40% { transform: perspective(400px) rotate3d(1, 0, 0, -20deg); animation-timing-function: ease-in; }
  60% { transform: perspective(400px) rotate3d(1, 0, 0, 10deg); opacity: 1; }
  80% { transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  100% { transform: perspective(400px); }
}
@keyframes Toastify__flipOut { 
  0% { transform: perspective(400px); }
  30% { transform: perspective(400px) rotate3d(1, 0, 0, -20deg); opacity: 1; }
  100% { transform: perspective(400px) rotate3d(1, 0, 0, 90deg); opacity: 0; }
}
.Toastify__flip-enter { animation-name: Toastify__flipIn; }
.Toastify__flip-exit { animation-name: Toastify__flipOut; }
@keyframes Toastify__slideInRight { 
  0% { transform: translate3d(110%, 0, 0); visibility: visible; }
  100% { transform: translate3d(0px, 0, 0); }
}
@keyframes Toastify__slideInLeft { 
  0% { transform: translate3d(-110%, 0, 0); visibility: visible; }
  100% { transform: translate3d(0px, 0, 0); }
}
@keyframes Toastify__slideInUp { 
  0% { transform: translate3d(0px, 110%, 0); visibility: visible; }
  100% { transform: translate3d(0px, 0, 0); }
}
@keyframes Toastify__slideInDown { 
  0% { transform: translate3d(0px, -110%, 0); visibility: visible; }
  100% { transform: translate3d(0px, 0, 0); }
}
@keyframes Toastify__slideOutRight { 
  0% { transform: translate3d(0px, 0, 0); }
  100% { visibility: hidden; transform: translate3d(110%, 0, 0); }
}
@keyframes Toastify__slideOutLeft { 
  0% { transform: translate3d(0px, 0, 0); }
  100% { visibility: hidden; transform: translate3d(-110%, 0, 0); }
}
@keyframes Toastify__slideOutDown { 
  0% { transform: translate3d(0px, 0, 0); }
  100% { visibility: hidden; transform: translate3d(0px, 500px, 0); }
}
@keyframes Toastify__slideOutUp { 
  0% { transform: translate3d(0px, 0, 0); }
  100% { visibility: hidden; transform: translate3d(0px, -500px, 0); }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left { animation-name: Toastify__slideInLeft; }
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right { animation-name: Toastify__slideInRight; }
.Toastify__slide-enter--top-center { animation-name: Toastify__slideInDown; }
.Toastify__slide-enter--bottom-center { animation-name: Toastify__slideInUp; }
.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left { animation-name: Toastify__slideOutLeft; }
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right { animation-name: Toastify__slideOutRight; }
.Toastify__slide-exit--top-center { animation-name: Toastify__slideOutUp; }
.Toastify__slide-exit--bottom-center { animation-name: Toastify__slideOutDown; }
</style><style id="react-to-print-5">.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow { margin-left: -8px; position: absolute; }
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before { box-sizing: content-box; position: absolute; border: 8px solid transparent; height: 0; width: 1px; }
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before { content: ""; z-index: -1; border-width: 8px; left: -8px; border-bottom-color: rgb(174, 174, 174); }
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle { top: 0; margin-top: -8px; }
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before { border-top: none; border-bottom-color: rgb(240, 240, 240); }
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before { top: -1px; border-bottom-color: rgb(174, 174, 174); }
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow { bottom: 0; margin-bottom: -8px; }
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before { border-bottom: none; border-top-color: rgb(255, 255, 255); }
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before { bottom: -1px; border-top-color: rgb(174, 174, 174); }
.react-datepicker-wrapper { display: inline-block; padding: 0; border: 0; }
.react-datepicker { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 0.8rem; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0); border: 1px solid rgb(174, 174, 174); border-radius: 0.3rem; display: inline-block; position: relative; }
.react-datepicker--time-only .react-datepicker__triangle { left: 35px; }
.react-datepicker--time-only .react-datepicker__time-container { border-left: 0; }
.react-datepicker--time-only .react-datepicker__time { border-radius: 0.3rem; }
.react-datepicker--time-only .react-datepicker__time-box { border-radius: 0.3rem; }
.react-datepicker__triangle { position: absolute; left: 50px; }
.react-datepicker-popper { z-index: 1; }
.react-datepicker-popper[data-placement^="bottom"] { margin-top: 10px; }
.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle { left: auto; right: 50px; }
.react-datepicker-popper[data-placement^="top"] { margin-bottom: 10px; }
.react-datepicker-popper[data-placement^="right"] { margin-left: 8px; }
.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle { left: auto; right: 42px; }
.react-datepicker-popper[data-placement^="left"] { margin-right: 8px; }
.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle { left: 42px; right: auto; }
.react-datepicker__header { text-align: center; background-color: rgb(240, 240, 240); border-bottom: 1px solid rgb(174, 174, 174); border-top-left-radius: 0.3rem; border-top-right-radius: 0.3rem; padding-top: 8px; position: relative; }
.react-datepicker__header--time { padding-bottom: 8px; padding-left: 5px; padding-right: 5px; }
.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll { display: inline-block; margin: 0 2px; }
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header { margin-top: 0; color: rgb(0, 0, 0); font-weight: bold; font-size: 0.944rem; }
.react-datepicker-time__header { text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
.react-datepicker__navigation { background: none; line-height: 1.7rem; text-align: center; cursor: pointer; position: absolute; top: 10px; padding: 0; border: 0.45rem solid transparent; z-index: 1; height: 10px; width: 10px; text-indent: -999em; overflow: hidden; }
.react-datepicker__navigation--previous { left: 10px; border-right-color: rgb(204, 204, 204); }
.react-datepicker__navigation--previous:hover { border-right-color: rgb(179, 179, 179); }
.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover { border-right-color: rgb(230, 230, 230); cursor: default; }
.react-datepicker__navigation--next { right: 10px; border-left-color: rgb(204, 204, 204); }
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) { right: 80px; }
.react-datepicker__navigation--next:hover { border-left-color: rgb(179, 179, 179); }
.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover { border-left-color: rgb(230, 230, 230); cursor: default; }
.react-datepicker__navigation--years { position: relative; top: 0; display: block; margin-left: auto; margin-right: auto; }
.react-datepicker__navigation--years-previous { top: 4px; border-top-color: rgb(204, 204, 204); }
.react-datepicker__navigation--years-previous:hover { border-top-color: rgb(179, 179, 179); }
.react-datepicker__navigation--years-upcoming { top: -4px; border-bottom-color: rgb(204, 204, 204); }
.react-datepicker__navigation--years-upcoming:hover { border-bottom-color: rgb(179, 179, 179); }
.react-datepicker__month-container { float: left; }
.react-datepicker__year-container { margin: 0.4rem; text-align: center; display: flex; flex-wrap: wrap; }
.react-datepicker__year-container-text { display: inline-block; cursor: pointer; flex: 1 0 30%; width: 12px; padding: 2px; }
.react-datepicker__month { margin: 0.4rem; text-align: center; }
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text { display: inline-block; width: 4rem; margin: 2px; }
.react-datepicker__input-time-container { clear: both; width: 100%; float: left; margin: 5px 0 10px 15px; text-align: left; }
.react-datepicker__input-time-container .react-datepicker-time__caption { display: inline-block; }
.react-datepicker__input-time-container .react-datepicker-time__input-container { display: inline-block; }
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input { display: inline-block; margin-left: 10px; }
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input { width: 85px; }
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button { appearance: none; margin: 0; }
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] { }
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter { margin-left: 5px; display: inline-block; }
.react-datepicker__time-container { float: right; border-left: 1px solid rgb(174, 174, 174); width: 85px; }
.react-datepicker__time-container--with-today-button { display: inline; border: 1px solid rgb(174, 174, 174); border-radius: 0.3rem; position: absolute; right: -72px; top: 0; }
.react-datepicker__time-container .react-datepicker__time { position: relative; background: white; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box { width: 85px; overflow-x: hidden; margin: 0 auto; text-align: center; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list { list-style: none; margin: 0; height: calc(195px + 0.85rem); overflow-y: scroll; padding-right: 0; padding-left: 0; width: 100%; box-sizing: content-box; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item { height: 30px; padding: 5px 10px; white-space: nowrap; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover { cursor: pointer; background-color: rgb(240, 240, 240); }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected { background-color: rgb(33, 107, 165); color: white; font-weight: bold; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover { background-color: rgb(33, 107, 165); }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled { color: rgb(204, 204, 204); }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover { cursor: default; background-color: transparent; }
.react-datepicker__week-number { color: rgb(204, 204, 204); display: inline-block; width: 1.7rem; line-height: 1.7rem; text-align: center; margin: 0.166rem; }
.react-datepicker__week-number.react-datepicker__week-number--clickable { cursor: pointer; }
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover { border-radius: 0.3rem; background-color: rgb(240, 240, 240); }
.react-datepicker__day-names, .react-datepicker__week { white-space: nowrap; }
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name { color: rgb(0, 0, 0); display: inline-block; width: 1.7rem; line-height: 1.7rem; text-align: center; margin: 0.166rem; }
.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range { border-radius: 0.3rem; background-color: rgb(33, 107, 165); color: rgb(255, 255, 255); }
.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover { background-color: rgb(29, 93, 144); }
.react-datepicker__month--disabled, .react-datepicker__quarter--disabled { color: rgb(204, 204, 204); pointer-events: none; }
.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover { cursor: default; background-color: transparent; }
.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text { cursor: pointer; }
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover { border-radius: 0.3rem; background-color: rgb(240, 240, 240); }
.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today { font-weight: bold; }
.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted { border-radius: 0.3rem; background-color: rgb(61, 204, 74); color: rgb(255, 255, 255); }
.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover { background-color: rgb(50, 190, 63); }
.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1 { color: magenta; }
.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2 { color: green; }
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range { border-radius: 0.3rem; background-color: rgb(33, 107, 165); color: rgb(255, 255, 255); }
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover { background-color: rgb(29, 93, 144); }
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected { border-radius: 0.3rem; background-color: rgb(42, 135, 208); color: rgb(255, 255, 255); }
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover { background-color: rgb(29, 93, 144); }
.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range { background-color: rgba(33, 107, 165, 0.5); }
.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range, .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range { background-color: rgb(240, 240, 240); color: rgb(0, 0, 0); }
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled { cursor: default; color: rgb(204, 204, 204); }
.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover { background-color: transparent; }
.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover { background-color: rgb(33, 107, 165); }
.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover { background-color: rgb(240, 240, 240); }
.react-datepicker__input-container { position: relative; display: inline-block; width: 100%; }
.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view { border: 1px solid transparent; border-radius: 0.3rem; }
.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover { cursor: pointer; }
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow { border-top-color: rgb(179, 179, 179); }
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow { border-top-color: rgb(204, 204, 204); float: right; margin-left: 20px; top: 8px; position: relative; border-width: 0.45rem; }
.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown { background-color: rgb(240, 240, 240); position: absolute; width: 50%; left: 25%; top: 30px; z-index: 1; text-align: center; border-radius: 0.3rem; border: 1px solid rgb(174, 174, 174); }
.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover { cursor: pointer; }
.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable { height: 150px; overflow-y: scroll; }
.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option { line-height: 20px; width: 100%; display: block; margin-left: auto; margin-right: auto; }
.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type { border-top-left-radius: 0.3rem; border-top-right-radius: 0.3rem; }
.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type { user-select: none; border-bottom-left-radius: 0.3rem; border-bottom-right-radius: 0.3rem; }
.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover { background-color: rgb(204, 204, 204); }
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming { border-bottom-color: rgb(179, 179, 179); }
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous { border-top-color: rgb(179, 179, 179); }
.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected { position: absolute; left: 15px; }
.react-datepicker__close-icon { cursor: pointer; background-color: transparent; border: 0; outline: 0; padding: 0 6px 0 0; position: absolute; top: 0; right: 0; height: 100%; display: table-cell; vertical-align: middle; }
.react-datepicker__close-icon::after { cursor: pointer; background-color: rgb(33, 107, 165); color: rgb(255, 255, 255); border-radius: 50%; height: 16px; width: 16px; padding: 2px; font-size: 12px; line-height: 1; text-align: center; display: table-cell; vertical-align: middle; content: "×"; }
.react-datepicker__today-button { background: rgb(240, 240, 240); border-top: 1px solid rgb(174, 174, 174); cursor: pointer; text-align: center; font-weight: bold; padding: 5px 0; clear: left; }
.react-datepicker__portal { position: fixed; width: 100vw; height: 100vh; background-color: rgba(0, 0, 0, 0.8); left: 0; top: 0; justify-content: center; align-items: center; display: flex; z-index: 2147483647; }
.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name { width: 3rem; line-height: 3rem; }
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name { width: 2rem; line-height: 2rem; }
}
.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header { font-size: 1.44rem; }
.react-datepicker__portal .react-datepicker__navigation { border: 0.81rem solid transparent; }
.react-datepicker__portal .react-datepicker__navigation--previous { border-right-color: rgb(204, 204, 204); }
.react-datepicker__portal .react-datepicker__navigation--previous:hover { border-right-color: rgb(179, 179, 179); }
.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover { border-right-color: rgb(230, 230, 230); cursor: default; }
.react-datepicker__portal .react-datepicker__navigation--next { border-left-color: rgb(204, 204, 204); }
.react-datepicker__portal .react-datepicker__navigation--next:hover { border-left-color: rgb(179, 179, 179); }
.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover { border-left-color: rgb(230, 230, 230); cursor: default; }
</style><style id="react-to-print-6">.RSPBprogressBar { height: 10px; line-height: 1; border-radius: 10px; position: relative; background-color: rgba(211, 211, 211, 0.6); display: flex; justify-content: space-between; align-items: center; z-index: 0; }
.RSPBprogressBar .RSPBstep { display: inline-flex; justify-content: center; align-items: center; z-index: 0; position: absolute; transform: translateX(-50%); transition-property: all; transition-timing-function: ease; }
.RSPBprogressBar .RSPBprogressBarText { color: white; font-size: 10px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
.RSPBprogressBar .RSPBprogression { position: absolute; transition: width 0.3s ease 0s; left: 0; top: 0; bottom: 0; border-radius: 10px; background: rgba(0, 116, 217, 0.8); z-index: -1; }
</style><style id="react-to-print-7">.react-clock { display: block; position: relative; }
.react-clock, .react-clock *, .react-clock ::before, .react-clock ::after { box-sizing: border-box; }
.react-clock__face { position: absolute; inset: 0; border: 1px solid black; border-radius: 50%; }
.react-clock__hand { position: absolute; inset: 0 50%; }
.react-clock__hand__body { position: absolute; background-color: black; transform: translateX(-50%); }
.react-clock__mark { position: absolute; inset: 0 50%; }
.react-clock__mark__body { position: absolute; background-color: black; transform: translateX(-50%); }
.react-clock__mark__number { position: absolute; left: -40px; width: 80px; text-align: center; }
.react-clock__second-hand__body { background-color: red; }
table, #p-header {
width: 500pt !important;

}
table {font-size: 1.0em !important;} #eo-table1, #eo-table2 {font-size: 1.0em !important;} #container {background: blue; width: 500pt !important;}
</style></head>`)
        modified_data = modified_data.replace(`.react-clock__second-hand__body { background-color: red; }`, `.react-clock__second-hand__body { background-color: red; }
table, #p-header {
width: 500pt !important;

} html {
  zoom: 0.75;
}
 h1 { font-family: Agency;}
table {font-size: 1.0em !important;} #eo-table1, #eo-table2 {font-size: 1.0em !important;} #container {background: blue; width: 500pt !important;}`)
        modified_data = modified_data.replace("@page { size: auto;", "@page { size: A4;")
        modified_data = modified_data.replace("1.2em", "1.0em")
        modified_data = modified_data.replace("mt-0 w-100", "mt-0")
        modified_data = modified_data.replace("text-size-adjust: 100%", "text-size-adjust: 70%")
        modified_data = modified_data.replace("text-center w-100", "text-center")
        modified_data = modified_data.replace('</h1><table class="table-bordered table table-sm text-center w-100"', '</h1><table class="table-bordered table table-sm text-center"  style="width: 545.276pt !important;"')
        modified_data = modified_data.replace('<style id="react-to-print-1">table.dataTable { width: 100%;', '<style id="react-to-print-1">table.dataTable { width: 180mm;')
        modified_data = modified_data.replace("mt-0 text-center w-100", "mt-0 text-center")
        modified_data = modified_data.replace("<body>", '<body  style="background: white; margin: 15mm; width: 180mm !important;; font-size: 10pt !important;">')
        this.props.firebase.functions.httpsCallable('notifyContactsDevan')({
            organisation_id, outturn_id: outturn_id, customer_id: customer, data: modified_data
        }).then((res)=>{
            if (res.data.error) {
                toast.error(res.data.error, toastConfig);
            }
            if (res.data.success) {
                toast.success(`${res.data.success} notifications sent`, toastConfig);
            }
            this.setState({
                notifying: false
            })
        })
    }

    updateBays(old_bay, pkey, new_bay, qty) {
        const {actual, expected_products} = this.state;
        const {bays} = this.props;
        const bay = bays[new_bay];
        if (old_bay.length > 0 && old_bay !== new_bay) {
            delete actual[pkey].crates[old_bay];
        }
        let actual_qty = qty;

        if (new_bay.length > 0) {
            if (!actual[pkey].crates) {
                actual[pkey].crates = {};
            }
            if (Object.keys(actual[pkey].crates).length === 0) {
                actual_qty = parseInt(expected_products[pkey].crates.length);
            } else {
                if (!actual[pkey].crates[new_bay]) {
                    const current = this.getTotalAcrossBays(actual[pkey].crates);
                    if (current < parseInt(expected_products[pkey].crates.length)) {
                        actual_qty = parseInt(expected_products[pkey].crates.length) - current;
                    }
                }
            }
            actual[pkey].crates[new_bay] = {
                code: bay.code,
                qty: actual_qty
            };
        }
        this.setState({actual: actual})
    }

    updatePageWithEntity() {
        const {outturn_id} = this.props;
        if (outturn_id) {
            this.props.updatePageWithEntity('devan_requests', outturn_id)
        }
    }

    getPackingListUrl() {
        const {firebase} = this.props;
        const {packing_list} = this.state;
        if (packing_list) {
            firebase.storage.ref(packing_list).getDownloadURL().then(url => {
                this.setState({
                    url: url
                })
            })
        } else {
            this.setState({url: null})
        }
    }

    getSteps() {
        const {completed, expected} = this.state;
        return [
            {
                src: products,
                label: "Verify Contents",
                complete: !!expected
            },
            {
                src: container,
                label: "Stock In",
                complete: !!completed
            },
            {
                src: products,
                label: "View",
                complete: false
            }
        ];
    }

    getCurrent() {
        const {completed, verified} = this.state;
        let current = 0;
        if (completed) {
            current = 1
        }
        if (verified) {
            current = 2
        }
        return current;
    }

    getTotalAcrossBays(crates) {
        if(!crates) return 0;
        return Object.keys(crates).map((crate) => {
            return parseInt(crates[crate].qty)
        }).reduce((a,b) => a + b, 0)
    }

    getEmptyPass() {
        const {container_number, outturn_id, crates, projected, customer} = this.state;
        const pass_outturns = {};

        pass_outturns[outturn_id] = {id: outturn_id, container_number, crates: crates ? crates : projected};
        return {
            customer: customer,
            vehicle_reg: '',
            haulier: '',
            driver: '',
            pass_number: '',
            outturns: pass_outturns,
            revoked: false,
            total: crates ? crates : projected
        }
    }

    render() {
        const {customers, firebase, organisation_id, bays, organisation, existing_products, cancelEditing} = this.props;
        const {name, logo} = (organisation || {});
        const {
            issuing, container_number, showPackingList, url, crates,
            loading, completed, verified, provisional_pass_id, current_product,
            pass_id, customer, processed_on, processed_by, include_images,
            issued_on, issued_by, actual, expected_products, photos, ship_name, notifying, notified, outturn_id
        } = this.state;

        const current = this.getCurrent();
        const steps = this.getSteps();

        const empty_pass_with_outturn = this.getEmptyPass();

        return (

            <div className={'row m-0'}>
                <div className={'col-xs-12 col-sm-12 p-1 pb-0 pt-1'}>
                    <Error ref={ref => this.error = ref}/>
                    <div
                        className={'cm-progress-bar col-10 col-lg-8 pt-1 pl-1 pr-1 mr-auto ml-auto mt-5 mb-3 no-print not-mobile'}>
                        <StepProgressBar steps={steps} current={current}/>
                    </div>
                    {url && showPackingList && <FileModal
                        showPackingList={showPackingList}
                        url={url}
                        onHide={() => this.setState({showPackingList: false})}
                    />}
                    {(loading || !bays) && <LoadingScreen/>}
                    {!loading && bays && <div className={'bg-light col-12 col-lg-12 p-3 mt-5 text-center'}>
                        {issuing && <OutturnGatePassModal
                            onHide={() => this.setState({issuing: false})} issuing={issuing}
                            organisation_id={organisation_id}
                            firebase={firebase} logo={logo} customers={Object.values(customers)}
                        />}
                        <ButtonGroup size={'sm'}>
                            <Button size={'sm'} className={'btn btn-danger text-light no-print'}
                                    onClick={cancelEditing.bind(this)}>Return to list</Button>
                            { url && <Button variant={'primary'}
                                            onClick={() => this.setState({showPackingList: !showPackingList})}>
                                {showPackingList ? 'Hide Packing List' : 'Show Packing List'}
                            </Button> }

                            <ReactToPrint
                                trigger={() => <Button className={'no-print not-mobile'}>Print</Button>}
                                content={() => this.componentRef}
                                onBeforePrint={this.printOutturn.bind(this)}
                                onAfterPrint={this.finishPrinting.bind(this)}
                            />
                            <Button size={'sm'} className={'btn btn-default text-light no-print'}
                                    onClick={() => this.setState({include_images: !include_images})}>
                                Print Images <FontAwesomeIcon icon={include_images ? 'check' : 'times'}
                                                              className={include_images ? 'text-success ml-1' : 'text-danger ml-1'}/>
                            </Button>

                            <input ref={ref => this.photo_upload = ref} type={'file'} onChange={this.uploadPhoto.bind(this)}
                                   style={{visibility: 'hidden', width: 0}}/>
                            {!completed &&
                            <Button onClick={() => this.photo_upload.click()} className={'no-print'}
                                    variant={'tertiary'}>
                                <FontAwesomeIcon icon={'camera'}/>
                            </Button>}


                            {!completed &&
                            <Button disabled={!current_product} variant={'tertiary'} onClick={this.addProduct.bind(this)}
                                    className={'no-print'}>
                                Add Product
                            </Button>}
                            {!completed && <DropdownButton as={ButtonGroup} size={'sm'} title={current_product ? current_product.description : 'Select Product To Add'}
                                                           id="bg-nested-dropdown">
                                {
                                    Object.values(existing_products || {}).filter((p) => {
                                        return Object.keys(actual || {}).indexOf(p.code) === -1 && p.customer === customer
                                    }).sort((a, b) => a.code < b.code ? -1 : 1).map((p) => {
                                            return <Dropdown.Item onClick={() => this.setState({current_product: p})}
                                                                  eventKey={`existing_${p.code}`} key={`existing_${p.code}`}
                                                                  value={p.id}>{p.code} - {p.description} </Dropdown.Item>
                                        }
                                    )
                                }

                            </DropdownButton>}

                            {!completed &&
                            <Button onClick={this.saveVerifyContents.bind(this)} className={'no-print'} variant={'tertiary'}>
                                Save
                            </Button>}
                            {!completed &&
                            <Button onClick={this.saveAndFinishVerifyContents.bind(this)} className={'no-print'}>
                                Save and Finish
                            </Button>}
                            {completed &&
                            <Button variant={'primary'} onClick={this.updatePageWithEntity.bind(this)}>View Devan Request</Button>}
                        </ButtonGroup>
                        <br/>
                        <div style={{display: 'none'}}>
                            <div ref={ref => this.componentRef = ref}>
                                <ElectronicOutturn
                                    issued_on={issued_on}
                                    ship_name={ship_name}
                                    getTotalAcrossBays={this.getTotalAcrossBays.bind(this)}
                                    existing_products={existing_products}
                                    actual={actual}
                                    expected_products={expected_products}
                                    photos={photos}
                                    container_number={container_number}
                                    total={crates} completed={completed}
                                    customer_code={customer ? customers[customer].code : ''}
                                    include_images={include_images}
                                />
                            </div>
                        </div>
                        {!completed && <div className={'row mt-5 bg-light h-auto'}>
                            <WizardHeading container_number={container_number} customer={customers[customer].name}
                                           organisation_name={name}/>
                            <hr className={'w-100 bg-primary'}/>
                            <ProductForm
                                    organisation_id={organisation_id}
                                    outturn_id={outturn_id}
                                bays={bays}
                                    firebase={firebase}
                                expected_products={expected_products}
                                actual={actual}
                                handleChange={this.handleChange.bind(this)}
                                getTotalAcrossBays={this.getTotalAcrossBays.bind(this)}
                                updateBays={this.updateBays.bind(this)}
                                addPresetReason={this.addPresetReason.bind(this)}
                            />
                            <span className={'print-only'}>Notes</span>
                            <FormControl className={'print-only'} as={'textarea'} rows={5}/>
                            <PhotoView photos={photos}/>
                        </div>}

                        {completed && <div className={'bg-light col-12 row p-3 ml-0 mr-0 text-center'}>
                            <VerificationButtons
                                authoriseStockIn={this.authoriseStockIn.bind(this)}
                                revertStockIn={this.revertStockIn.bind(this)}
                                notifyContacts={this.notifyContacts.bind(this)}
                                notifying={notifying}
                                notified={notified}
                                react_button={<ReactToPrint
                                    trigger={() => <Button disabled={notifying} className={'no-print not-mobile'}>{!notifying && !notified && "Notify"}{!notifying && notified && "Resend Notification"}{notifying && "Sending"}</Button>}
                                    content={() => this.componentRef}

                                    print={(res)=>{
                                        this.notifyContacts(res.contentDocument.children[0].outerHTML)
                                        return Promise.resolve(true)
                                    }}
                                />}
                                componentRef={this.componentRef}
                                viewOrIssuePass={() => {
                                    if (!provisional_pass_id) {
                                        firebase.db.ref(db_refs.organisations.devan_gate_passes(organisation_id)).push(empty_pass_with_outturn).then(res => {
                                            this.setState({issuing: res.key})

                                        })
                                    } else {
                                        this.setState({issuing: provisional_pass_id})
                                    }
                                }}
                                pass_id={pass_id}
                                provisional_pass_id={provisional_pass_id}
                                issued_on={issued_on}
                                verified={verified}
                                revertContents={this.revertContents.bind(this)}
                                viewPass={() => this.setState({issuing: pass_id || provisional_pass_id})}/>
                            <WizardHeading
                                customer={customers[customer].name}
                                organisation_name={name}
                                container_number={container_number}/>
                            {verified && <CompletionPanel
                                issued_by={issued_by}
                                issued_on={issued_on}
                                processed_by={processed_by} processed_on={processed_on}
                                total={crates}/>}
                            <ProductTable actual={actual} expected_products={expected_products} bays={bays}/>
                            <PhotoView photos={photos}/>
                        </div>}
                    </div>}

                </div>

            </div>

        );
    }
}

Wizard.propTypes = {
    bays: PropTypes.object.isRequired,
    customers: PropTypes.object.isRequired,
    organisation_id: PropTypes.string.isRequired,
    existing_products: PropTypes.object.isRequired,
    firebase: PropTypes.object.isRequired,
    outturn_id: PropTypes.string.isRequired
};

export default Wizard;