import React from "react";
import * as PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";

import db_refs from '../../../constants/db_refs';
import InlineInput from "../../../elements/InlineInput";
import Button from "../../../elements/Button";
import Error from "../../../elements/Error";
import {toastConfig} from "../../../config/toast_config";

const INITIAL_STATE = () => {
    return {
        code: '',
        description: '',
        error: false,
        customer: '',
        showForm: false
    }
};

class ProductForm extends React.Component {
    constructor(props) {
        super(props);
        let state = INITIAL_STATE();
        state.customer = Object.keys(props.customers).length > 0 ? Object.keys(props.customers)[0] : '';
        this.state = state;
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
    }

    handleChange(event) {
        let newState = {};
        newState[event.target.name] = event.target.value;
        this.setState(newState)
    }

    toggleForm() {
        this.setState({showForm: !this.state.showForm});
    }

    onSubmit() {
        const that = this;
        const {products, customers, firebase, organisation_id} = this.props;
        const {code, customer, description} = this.state;
        if(this.isInvalid()) {
            this.errors.setError({message: "You must complete the fields!"});
            return
        }
        if(products.filter(product => product.code === code && product.parent === customer).length > 0) {
            this.errors.setError({message: `${customers[customer].name} already has a product with this code!`});
            return
        }
        firebase.db.ref(db_refs.organisations.products(organisation_id)).push({
            code: code,
            description: description,
            customer: customer
        }).then(() => {
            toast.success(`Created product ${that.state.code}!`, {...toastConfig, autoClose: 2000});
            const customer = customer;
            let resetState = INITIAL_STATE();
            resetState.customer = customer;
            that.setState(resetState);
            that.errors.setError(false);
        }).catch((err) => {
            console.error(err);
            that.errors.setError(err);
        })
    }

    isInvalid() {
        return !(this.state.code.length > 0 && this.state.description.length > 0)
    }

    render() {
        const {showForm, customer, error, code, description} = this.state;
        const {customers} = this.props;
        return (
            <div className={'row'}>

                <div className={'col-xs-12 col-sm-12 bg-tertiary p-0 pb-0 pt-0 no-print'}>
                    <div onClick={this.toggleForm} className={'col-12 text-right pt-3 pr-3 text-light'}>
                        <FontAwesomeIcon icon={showForm ? 'minus-circle' : 'plus-circle'} size={'1x'}/>
                    </div>
                   <div className={'row m-0 pr-2 pl-2 pb-0 pt-0 text-center'}>
                      <div className={'col-12'}>
                          <Error ref={ref => this.errors = ref} error={error}/>
                      </div>
                       {showForm && [<div className={'small text-left text-light font-weight-bold col-xs-12 col-lg-3'}>
                            Customer
                            <select className={'form-control'} name={'customer'} value={customer} onChange={this.handleChange}>
                                { Object.keys(customers).map((customer) => <option key={`customers_${customer}`} value={customer}>
                                    {customers[customer].name}
                                </option>)}
                            </select>
                        </div>,
                    <div className={'small text-left text-light font-weight-bold col-xs-12 col-lg-3'}>
                        &nbsp;
                        <InlineInput
                            value={code}
                            required={true}
                            label_id={"app.product_code"}
                            handleChange={this.handleChange}
                            name={'code'}
                            prefix={'product_form'}
                        />
                    </div>,
                    <div className={'small text-left text-light font-weight-bold col-xs-12 col-lg-3'}>
                        &nbsp;
                        <InlineInput
                            value={description}
                            required={true}
                            label_id={"app.product_description"}
                            handleChange={this.handleChange}
                            name={'description'}
                            prefix={'product_form'}
                        />
                    </div>,
                    <div className={'small text-left text-light font-weight-bold col-xs-12 col-lg-3 text-center'}>
                        <br/>
                        <Button
                            testid={'add_product'}
                            onClick={this.onSubmit}
                            disabled={this.isInvalid()}
                            title_id={'app.add_customer'}
                            className={'btn btn-primary text-light'}
                            icon={<FontAwesomeIcon icon={'plus-circle'}/>}
                        />
                    </div>]}
                    </div>
                </div>
            </div>)
    }
}

ProductForm.propTypes = {
    organisation_id: PropTypes.string.isRequired,
    firebase: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    customers: PropTypes.object.isRequired,
};

export {ProductForm};
export default ProductForm;