import PageTemplate from "../PageTemplate";
import React from "react";
import Table from "./Table";

import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import ErrorMessage from "../../../elements/ErrorMessage";
import Wizard from "./Wizard";
import {ContainerMasterDataContext} from "../../../components/ContainerMaster/ContainerMaster";
import BulkPickingList from "../../../components/BulkPickingListModal";
import Hotbar from "../../../elements/Hotbar";

class Outturns extends PageTemplate {

    constructor(props) {
        super(props);
        this.state = {
            editing: props.entity,
            filter: '',
            loading: false
        };
        this.edit = this.edit.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);
    }

    edit(id) {
        this.setState({editing: id})
    }

    cancelEditing() {
        this.setState({editing: false})
    }

    render() {
        const {user_name} = this.props;
        const {editing, loading} = this.state;
        return (
            <div>
                <Hotbar updatePage={this.props.updatePage} page={'outturns'}/>

                <div className={'col-xs-12 col-sm-11 bg-primary pt-2 pb-2 mt-1 ml-auto mr-auto'}>
                    <h3 className={'text-light text-center no-print'}>Outturns</h3>
                    <ContainerMasterDataContext.Consumer>
                        {
                            ({customers, products, bays, devan_gate_passes, bulk_picking_lists}) => {

                                if (!customers || loading)
                                    return <LoadingScreen/>;
                                return <div>
                                    {loading && <LoadingScreen/>}
                                    {!loading && <div className={''}>
                                        {
                                            customers.length === 0 && <ErrorMessage
                                                message={"Whoops, no customers"}
                                                details={"You must create a customer before you can start devanning."}
                                                link={
                                                    <button
                                                        className={'btn btn-tertiary'}
                                                        data-page='customers'
                                                        onClick={this.props.updatePage}
                                                    >
                                                        Create one now!
                                                    </button>}

                                            />
                                        }
                                        {bulk_picking_lists && this.state.bulk_edit && <BulkPickingList
                                            onHide={() => this.setState({bulk_edit: null})}
                                            show={Boolean(this.state.bulk_edit)}
                                            customers={Object.values(customers || {})}
                                            organisation_id={this.props.organisation_id}
                                            firebase={this.props.firebase}
                                            existing={bulk_picking_lists[this.state.bulk_edit]}
                                        />}
                                        {
                                            customers && !editing && <div className={'text-right'}>
                                                <Table
                                                    editBulk={(id) => this.setState({bulk_edit: id})}
                                                    customers={customers}
                                                    gate_passes={devan_gate_passes}

                                                    firebase={this.props.firebase}

                                                    organisation_id={this.props.organisation_id}

                                                    editOutturn={this.edit}
                                                    filter={this.props.filter.collections}
                                                    setFilter={(value, callback) => {
                                                        this.setState({loading: false}, () => {
                                                            this.props.setOutturnsFilter(value, () => this.setState({loading: false}, callback))
                                                        });
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            customers && bays && bulk_picking_lists && editing &&
                                            <div className={'text-right'}>
                                                <div className={'row'}>
                                                    <div
                                                        className={'col-xs-12 col-sm-10 offset-sm-1 bg-tertiary p-0 pb-0 pt-1 print-12'}>

                                                        <Wizard
                                                            editBulk={(id) => {
                                                                this.setState({bulk_edit: id})
                                                            }}
                                                            displayName={user_name}
                                                            cancelEditing={this.cancelEditing}
                                                            gate_passes={devan_gate_passes}
                                                            updatePageWithEntity={this.goToPageWithEntity}
                                                            bays={bays}
                                                            customers={customers}
                                                            organisation={this.props.organisation}
                                                            outturn_id={editing}
                                                            outturns={{}}
                                                            organisation_id={this.props.organisation_id}
                                                            firebase={this.props.firebase}
                                                            existing_products={products}/>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>}

                                </div>


                            }
                        }</ContainerMasterDataContext.Consumer>

                </div>
            </div>
        )
    }
}

export default Outturns;