import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router";

import Error from "../../elements/Error";
import {Button, FormControl} from "react-bootstrap";
import ErrorMessage from "../../elements/ErrorMessage";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {toast} from "react-toastify";


const CustomerAccept = ({updateUserData, uid, firebase}) => {
    const history  = useHistory();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [organisation, setOrganisation] = useState(null);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [badToken, setBadToken] = useState(false);
    const [isInvalid, setInvalid] = useState(true);
    const {token} =  useParams();
    useEffect(()=>{
        const checkFunction = firebase.functions.httpsCallable('checkInviteToken');
        console.log(checkFunction({token}))
        checkFunction({token:token}).then((result) => {
            const {error, organisation} = result.data;
            if (error) {
                setBadToken(true);
                return;
            }
            setOrganisation(organisation)
        })
    }, [uid, token]);

    const activate = async () => {
        const result = await firebase.functions.httpsCallable('acceptCustomerInvite')({token})
        toast.success(result.data)
        if (updateUserData) updateUserData()
        history.push("/")


    };

    const registerAndActivate = async () => {
        if (isInvalid) {
            return;
        }
        const result = await firebase.doCreateUserWithEmailAndPassword(name, email, password)

        await activate()

    };

    if (!token) {
       setBadToken(true);
    }

    if (badToken) {
        return <ErrorMessage details={'Activation token missing or incorrect'} message={'Unable to start activation'}/>
    }
    if (!organisation) {
        return  <LoadingScreen/>
    }
    const checkInvalid = () => {
        if (!name || name.trim().length === 0) {
            setInvalid(true);
            return;
        }
        if (!password || password.trim().length === 0) {
            setInvalid(true);
            return;
        }
        if (password !== password2) {
            setInvalid(true);
            return;
        }
        if (!email || email.trim().length === 0) {
            setInvalid(true);
            return;
        }
        setInvalid(false)
    };
    return (
        <div className={'row row-eq-height'}>
            { !uid && <div className={'offset-lg-3 col-12 col-lg-6 bg-primary text-light p-5'}>
                <h3 className={'text-light'}>Register and Activate your customer account for {organisation.name}@ContainerMaster</h3>
                <p>Activations remaining: {organisation.activations}</p>
                <FormControl
                    className={'mb-2'}
                    value={name}
                    name={"name"}
                    placeholder={"Full name"}
                    onChange={(event)=>{
                        setName(event.target.value);
                        checkInvalid()
                    }}
                    required={true}
                />
                <FormControl
                    className={'mb-2'}
                    value={email}
                    type={"email"}
                    name={"email"}
                    placeholder={"Email Address"}
                    onChange={(event)=>{
                        setEmail(event.target.value);
                        checkInvalid()
                    }}
                    onKeyup={(event)=>{
                        setEmail(event.target.value);
                        checkInvalid()
                    }}
                    required={true}
                />
                <FormControl
                    className={'mb-2'}
                    value={password}
                    placeholder={"Password"}
                    type={"password"}
                    name={"password"}
                    onChange={(event)=>{
                        setPassword(event.target.value);
                        checkInvalid()
                    }}
                    required={true}
                />
                <FormControl
                    className={'mb-2'}
                    value={password2}
                    type={"password"}
                    placeholder={"Repeat Password"}
                    name={"repeat_password"}
                    onChange={(event)=>{
                        setPassword2(event.target.value);
                        checkInvalid()
                    }}
                    required={true}
                />
                <Button onClick={registerAndActivate} disabled={isInvalid || organisation.activations < 1} className={"btn-tertiary"}>Activate Account</Button>
            </div>}
            {uid &&  <div className={'offset-lg-3 col-12 col-lg-6 bg-primary text-light p-5'}>
                <h3 className={'text-light'}>Activate your customer account for {organisation.name}@ContainerMaster</h3>
                <p>Activations remaining: {organisation.activations}</p>
                <Button onClick={activate} disabled={organisation.activations < 1}  className={"btn-tertiary"}>Activate Account</Button>
            </div>}

        </div>
    );

};

CustomerAccept.propTypes = {

};

export default CustomerAccept;