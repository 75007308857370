import {Button, ButtonGroup, Table} from "react-bootstrap";
import React, {useState} from "react";
import {VictoryAxis, VictoryBar, VictoryChart} from 'victory';
import {BayPopover} from "./BayQuantitySelector";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {faExclamationTriangle, faTrash} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import db_refs from '../constants/db_refs'

const EventTable = ({data, title, target_id, showUser, none_found_message, with_product, on_delete}) => <Table size={'sm'} variant={'bordered'}>
    <thead>
    <tr>
        <td colSpan={5}><h3>{title}</h3></td>
    </tr>
    <tr>
        <td>Event Date</td>
        {with_product && <td>Product</td>}
        <td>Source</td>
        <td>Destination</td>
        <td>Quantity</td>
        {showUser && <td>Actioning User</td>}
        {on_delete && <td>Delete Entry</td>}
    </tr>
    </thead>
    <tbody>
    {Object.values(data).length === 0 && <tr>
        <td className={'text-center'} colSpan={5}>{none_found_message}</td>
    </tr>}
    {Object.keys(data).sort((d1, d2)=>parseInt(data[d1].eventDate) - parseInt(data[d2].eventDate)).reverse().map((eventKey) => {
        const event = data[eventKey];
        const {change, eventDate, destination, source} = event;
        console.log(on_delete)
        return <tr key={`${target_id}_event_${eventKey}`}>
            <td>{new Date(parseInt(eventDate)).toLocaleString()}</td>
            {with_product && <td>{event.product_code}</td>}
            <td>{source}</td>
            <td>{destination}</td>

            <td className={event.event.toLowerCase() !== 'collection' && event.event.toLowerCase() !== 'movement_out' ? 'text-success' : 'text-danger'}>{event.event.toLowerCase() !== 'collection' && event.event.toLowerCase() !== 'movement_out' ? '+' + change : '-'+change}</td>
            {showUser && <td>{event.updatedBy ? event.updatedBy.displayName : '-'}</td>}
            {on_delete && <td><span onClick={()=>on_delete(event)} ><FontAwesomeIcon icon={faTrash} className={"text-danger"}/></span></td>}
        </tr>
    })}
    </tbody>
</Table>;

const ProductBayDefaultsTable = ({firebase, title, bays, all_products, product, organisation_id}) => {
    const [filter, setFilter] = useState("");

    const valid_bays = Object.values(bays).filter(b=>(b.product===product.code || !(b.product)));
    const sortBy = [
        {
            prop:'last_active',
            direction: 1
        }];
    const sorted_bays = valid_bays.sort((a,b) => {
        let i = 0, result = 0;
        while(i < sortBy.length && result === 0) {
            let a_val = a[ sortBy[i].prop ];
            let b_val = b[ sortBy[i].prop ];
            if(sortBy[i].string) {
                result = sortBy[i].direction*(a_val < b_val ? -1 : (a_val > b_val ? 1 : 0));
            } else {
                a_val = parseInt(a_val);
                b_val = parseInt(b_val);
                result = sortBy[i].direction*(a_val - b_val);
            }
            i++;
        }
        return result;
    }).reverse();

    const changeBayDevan = (bay) => {
        firebase.db.ref(db_refs.organisations.products(organisation_id)).child(product.id).child("default_devan_bay").set(bay)
    }
    const resetBayDevan = () => {
        firebase.db.ref(db_refs.organisations.products(organisation_id)).child(product.id).child("default_devan_bay").set(null)
    }

    const changeBayCollection = (bay) => {
        firebase.db.ref(db_refs.organisations.products(organisation_id)).child(product.id).child("default_collection_bay").set(bay)
    }
    const resetBayCollection = () => {
        firebase.db.ref(db_refs.organisations.products(organisation_id)).child(product.id).child("default_collection_bay").set(null)
    }
    const baysWithProducts = sorted_bays.filter(b=>b.product === product.code || b.id === product.default_devan_bay || b.id === product.default_collection_bay)
    const baysWithoutProducts = sorted_bays.filter(b=>!(b.product) && Object.values(all_products).filter(p=>p.default_devan_bay === b.id).length ===0)
    const mostRecentDevan = baysWithProducts.length > 0 ? baysWithProducts[0] : baysWithoutProducts.length > 0 ? baysWithoutProducts[0] : null;
    const mostRecentCollection = baysWithProducts.length > 0 ? baysWithProducts[0]  : null;
    const {default_devan_bay, default_collection_bay} = product;
    return <Table size={'sm'} variant={'bordered'}>
        <thead>
        <tr>
            <td colSpan={3}><h3>{title}</h3></td>
        </tr>
        <tr>
            <th>Movement Type</th>
            <th>Default Bay</th>
            <th>Selection Mode</th>
            <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>Devan</td>
            <td>{default_devan_bay ? bays[default_devan_bay].code : mostRecentDevan ? mostRecentDevan.code : <span><FontAwesomeIcon icon={faExclamationTriangle} className={"text-danger"}/> None found!</span>}</td>
            <td>{default_devan_bay ? "Specified default" : "Most Recent"}</td>
            <td>
                <ButtonGroup size={"sm"}>
                    <OverlayTrigger className={'bg-primary'} trigger={"click"} placement="right" rootClose
                                    overlay={(props) => <BayPopover props={props} current_bay={default_devan_bay } bays={bays}
                                                                    changeBay={changeBayDevan} adjustments={{}}
                                                                    setFilter={(val)=>setFilter(val)}
                                                                    filter={filter}
                                                                    bays_with_products={baysWithProducts.filter(b=>b.code.toLowerCase().indexOf(filter.toLowerCase())> -1)}
                                                                    bays_without_products={baysWithoutProducts.filter(b=>b.code.toLowerCase().indexOf(filter.toLowerCase())> -1)}/>}>
                        <Button title={'Change bay?'} variant={"primary"}>Set</Button>
                    </OverlayTrigger>
                    <Button variant={"danger"} onClick={resetBayDevan}>Reset</Button>
                </ButtonGroup>
            </td>
        </tr>
        <tr>
            <td>Collection</td>
            <td>{default_collection_bay ? bays[default_collection_bay].code : mostRecentCollection ? mostRecentCollection.code :<span><FontAwesomeIcon icon={faExclamationTriangle} className={"text-danger"}/> None found!</span>}</td>
            <td>{default_collection_bay ? "Specified default" : "Most Recent"}</td>
            <td>
                <ButtonGroup size={"sm"}>
                    <OverlayTrigger className={'bg-primary'} trigger={"click"} placement="right" rootClose
                                    overlay={(props) => <BayPopover props={props} current_bay={default_devan_bay } bays={bays}
                                                                    changeBay={changeBayCollection} adjustments={{}}
                                                                    setFilter={(val)=>setFilter(val)}
                                                                    filter={filter}
                                                                    bays_with_products={baysWithProducts.filter(b=>b.code.toLowerCase().indexOf(filter.toLowerCase())> -1)}
                                                                    bays_without_products={[]}/>}>
                        <Button title={'Change bay?'} variant={"primary"}>Set</Button>
                    </OverlayTrigger>
                    <Button variant={"danger"} onClick={resetBayCollection}>Reset</Button>
                </ButtonGroup>
            </td>

        </tr>
        </tbody>
    </Table>;
}
const StockLevelGraph = ({graphData}) => <VictoryChart key={JSON.stringify(graphData)+new Date().getTime()}
                                                       domainPadding={10}
>
    <VictoryAxis
        style={{
            axis: {stroke: "#756f6a"},
            tickLabels: {fontSize: 10, padding: 5}
        }}
        // tickValues specifies both the number of ticks and where
        // they are placed on the axis
        tickValues={graphData.map(gd => gd.y)}
        tickFormat={graphData.map(gd => `${gd.y}`)}

    />

    <VictoryBar
        style={{
            data: {fill: "#c43a31"}, labels: {
                fontSize: 10
            }, height: "200px"
        }}
        data={graphData}
    />
</VictoryChart>;
export default EventTable;
const labelMappings = {
    "current": "Recorded Stock",
    "actual_stock": "Actual Stock",
    "available": "Available",
    "pending": "Incoming",
    "promised": "Outgoing",
    "stocking_in": "Stocking In",
    "stocking_out": "Stocking Out"
};
export {labelMappings, StockLevelGraph, ProductBayDefaultsTable}