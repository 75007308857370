import {
    Badge,
    Button,
    ButtonGroup,
    FormCheck,
    FormControl,
    FormLabel,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ToggleButton
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";

import * as PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";
import {SearchInput} from "../../elements/SearchInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as DATABASE_REFS from "../../constants/db_refs";
import db_refs from "../../constants/db_refs";
import DatePicker from "react-datepicker";
import moment from "moment";
import {ElectronicPickingList} from "../../elements/Common";
import ReactToPrint from "react-to-print";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import _ from "lodash";


const BulkPickingList = ({show, customers, onHide, existing, firebase, organisation_id}) => {
    const [filter, setFilter] = useState('');
    const [productView, setProductView] = useState(false);
    const saved = !!existing;
    const [loading, setLoading] = useState(true);
    let initialStart = moment(new Date()).startOf('week').toDate().getTime();
    const [startDate, setStart] = useState(initialStart);
    const [endDate, setEnd] = useState(moment(new Date()).endOf('week').toDate().getTime());

    const [customer, setCustomer] = useState(existing ? existing.customer : customers.length > 0 ? customers[0].id : null);
    const [recipient, setRecipient] = useState(existing ? existing.recipient : 'false');
    const [added_collections, setAddedCollections] = useState({});
    const [open_collections, setOpenCollections] = useState({});
    useEffect(() => {
        if(existing && existing.pass_id) {
            let promises = [];
            if (existing.collections) {

                Object.values(existing.collections || {}).forEach(c=>{
                    promises.push(firebase.db.ref(db_refs.organisations.summaries.collection(organisation_id)).child(c.id).once('value').then((res)=>{
                        existing.collections[c.id] = res.val();
                        existing.collections[c.id].customer = customer;
                    }))
                });
            }
            Promise.all(promises).then(()=>{
                setOpenCollections(existing.collections);
                setAddedCollections(existing.collections);
                setLoading(false);

            });
            return;
        }
        firebase.db.ref(db_refs.organisations.summaries.collection(organisation_id))
            .orderByChild("can_be_picked")
            .equalTo(true)
            .on('value',
            (requests_snap) => {

                let result = requests_snap.val() || {};
                const added = {};
                let promises = [];
                if (existing && existing.collections) {


                    Object.values(existing.collections || {}).forEach(c=>{
                        promises.push(firebase.db.ref(db_refs.organisations.summaries.collection(organisation_id)).child(c.id).once('value').then((res)=>{
                            result[c.id] = res.val();
                            added[c.id] = res.val();
                        }))
                    });
                }
                Promise.all(promises).then(()=>{
                    setOpenCollections(result);
                    setAddedCollections(added);
                    setLoading(false);
                })
            });
        return () => firebase.db.ref(db_refs.organisations.summaries.collection(organisation_id))
            .orderByChild("can_be_picked")
            .equalTo(true).off();
    }, [setOpenCollections, firebase.db, organisation_id, existing]);

    const printRef = React.createRef();

    if (loading) {
        return <Modal size={'lg'} show={show} onHide={onHide}>
            <ModalHeader className={'bg-primary text-light'}><h3 className={'bg-primary text-light'}>Bulk Picking List </h3>
            </ModalHeader><ModalBody><LoadingScreen/></ModalBody></Modal>
    }
    const collections = existing ? Object.values({...open_collections, ...existing.collections}) : Object.values({...open_collections});

    const recipients = [...new Set(collections.filter(c => c.customer === customer && c.recipient_ref).map(c => c.recipient_ref))];
    const valid_collections = Object.values(
        Object.filter(open_collections || {},
                r=>(r.customer === customer && (parseInt(r.expected) > startDate && parseInt(r.expected) < endDate)) || (existing && existing.collections && existing.collections[r.id])
        )
    ).filter(vc=>vc.collection_ref.indexOf(filter) > -1);
    return <Modal size={'lg'} show={show} onHide={onHide}>
        <ModalHeader className={'bg-primary text-light'}><h3 className={'bg-primary text-light'}>Bulk Picking List {existing && existing.pass_id && <span className={'text-danger ml-2'}>Gate pass Issued</span>}</h3>
        </ModalHeader>
        {<ModalBody className={'bg-light'}>
            <div style={{display: 'none'}}>
                {productView && <div ref={printRef}>
                    {open_collections && customer && <ElectronicPickingList
                        collections={added_collections}
                        productView={productView}
                        customer={customers.find(c => c.id === customer)}
                        photos={null} total={Object.values(added_collections).reduce((a, b) => a + (b.crates ? b.crates : b.projected), 0)}
                        requests={open_collections}
                        is_bulk={true}
                    />}
                </div>}
                {!productView && <div ref={printRef}>
                    {open_collections && <ElectronicPickingList
                        is_bulk={true}
                        requests={open_collections}
                        collections={added_collections}
                        customer={customers.find(c => c.id === customer)}
                        photos={null} total={Object.values(added_collections).reduce((a, b) => a +  (b.crates ? b.crates : b.projected), 0)}
                    />}
                </div>}
            </div>
            <div>
                {existing && <Button onClick={()=>{
                   firebase.functions.httpsCallable('resyncPickingList')({
                       organisation_id,
                       picking_id: existing.id
                   }).then(()=>console.debug('Synced')).catch((e)=>console.error(e));
                }}>Resync</Button>}
                <Badge title={'Total collections in order'} className={'m-1 p-1 col-2 float-right'}
                       variant={'tertiary'}><h3 className={'m-0 text-light'}><FontAwesomeIcon className={'mr-1'}
                                                                                              icon={'file'}/>{Object.values(added_collections).length}
                </h3></Badge>
                <Badge title={'Total crates in order'} className={'m-1 p-1 col-2 float-right'} variant={'secondary'}><h3
                    className={'m-0'}><FontAwesomeIcon className={'mr-1'}
                                                       icon={'boxes'}/> {Object.values(added_collections).reduce((a, b) => a +  (b.crates ? b.crates : b.projected), 0)}
                </h3></Badge>
                <h3 className={'w-25'}>List ID: {saved ? existing.picking_list_number : 'Not saved'}</h3>
                <h3 className={'w-50'}><span>Group By:  </span><ButtonGroup className={'mb-1'} toggle size={'sm'}>
                    <ToggleButton variant={'info'} onChange={() => setProductView(true)} type="radio"
                                  checked={productView} name="radio" defaultChecked value="1">
                        {productView && <FontAwesomeIcon icon={'check'} className={'mr-1'}/>} Product
                    </ToggleButton>
                    <ToggleButton variant={'info'} onChange={() => setProductView(false)} type="radio"
                                  checked={!productView} defaultChecked name="radio" value="3">
                        {!productView && <FontAwesomeIcon icon={'check'} className={'mr-1'}/>} Collection Ref
                    </ToggleButton>
                </ButtonGroup></h3>
            </div>
            <InputGroup className="mb-3">
                <InputGroup.Prepend style={{minWidth: '15%'}}>
                    <InputGroup.Text style={{width: '-webkit-fill-available'}}
                                     id="basic-addon1">Customer</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl as={'select'} defaultValue={customer} onChange={(e) => {
                    setCustomer(e.target.value);
                    setRecipient('false');
                    setAddedCollections({})
                }}>
                    <option className={'text-danger border-danger'} value={null}>-</option>
                    {customers.map(customer => <option key={`${customer.id}`}
                                                       value={customer.id}>{customer.name}</option>)}
                </FormControl>
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Prepend style={{minWidth: '15%'}}>
                    <InputGroup.Text style={{width: '-webkit-fill-available'}}
                                     id="basic-addon1">Recipient</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl as={'select'} defaultValue={recipient} onChange={(e) => {
                    setRecipient(e.target.value);
                    setAddedCollections({})
                }}>
                    <option value={false}>-</option>
                    {recipients.map(recipient => <option key={`${recipient}`} value={recipient}>{recipient}</option>)}
                </FormControl>
            </InputGroup>

            <h3>Collections</h3>
            <div className={'row'}>
                <div className={'no-print col-12 col-md-5'}>
                    <span style={{float: 'left', minWidth: '10%'}}>Filter: </span>
                    <SearchInput setFilter={(e) => setFilter(e.target.value)}/>
                </div>
                {/* Date filter */}
                {<div className={'no-print col-12 col-md-5 row p-0 pr-1 pl-1 inline-form'}>
                    <div className={'col-6'}>
                        <span style={{float: 'left', minWidth: '10%'}}>From: </span><DatePicker
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        maxDate={new Date(endDate)}
                        className={'form-control float-right'}
                        selected={new Date(startDate)}
                        onChange={(date) => setStart(date.getTime())}
                        onSelect={(date) => setStart(date.getTime())}

                    />

                    </div>
                    <div className={'col-6'}>
                        <span style={{minWidth: '10%'}}>To: </span><DatePicker
                        className={'form-control'} name={'endDate'}
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        minDate={new Date(startDate)}

                        selected={new Date(endDate)}
                        onChange={(date) => setEnd(date.getTime())}
                        onSelect={(date) => setEnd(date.getTime())}
                    />
                    </div>
                </div>}
            </div>
            {valid_collections.map((vc) =>
                <FormLabel className={'badge-lg badge badge-primary m-1'} style={{fontSize: '1.1em'}}
                           key={`${vc.customer}_${vc.id}`} column={false}>
                    <FormCheck
                        disabled={existing && existing.pass_id}
                        checked={!!added_collections[vc.id]}
                        onChange={() => {
                            if (!added_collections[vc.id]) {
                                added_collections[vc.id] = vc;
                                setAddedCollections(_.cloneDeep(added_collections));
                                return;
                            }
                            setAddedCollections(Object.filter(added_collections, c => c.id !== vc.id))
                        }}
                    />
                    {vc.collection_ref} <br/><Badge variant={'light'} className={'text-dark m-1'}>
                    <FontAwesomeIcon className={'mr-1'} icon={'boxes'}/>
                    {vc.crates ? vc.crates : vc.projected}</Badge><br/><Badge variant={'info'} className={'text-dark m-1'}>
                    <FontAwesomeIcon className={'mr-1'} icon={'clock'}/>
                    {new Date(parseInt(vc.expected)).toLocaleDateString()}</Badge></FormLabel>
            )}
        </ModalBody>}
        <ModalFooter className={'bg-primary text-light'}>
            {
                !saved && <ButtonGroup size={'sm'}>
                    <Button variant={'tertiary'} onClick={() => {
                        const collections_to_save = {};
                        Object.values(added_collections).map(c => {
                            const {id, products, crates, projected, collection_ref, locked, expected} = c;
                            return collections_to_save[c.id] = {id, products, grand_total: crates ? crates : projected, collection_ref, completed: locked, expected}
                        });
                        firebase.db.ref(DATABASE_REFS.DATABASE_REFS.organisations.bulk.picking(organisation_id)).push({
                            customer,
                            recipient,
                            collections: collections_to_save
                        }).then(() => onHide())
                    }}>Save</Button>
                    <Button variant={'danger'} onClick={onHide}>Cancel</Button>
                </ButtonGroup>
            }
            {
                saved && <ButtonGroup>
                    <ReactToPrint
                        trigger={() => <Button className={'no-print not-mobile'}>Print</Button>}
                        content={() => {
                            return printRef.current
                        }}

                    />
                    <Button variant={'tertiary'} onClick={() => {
                        const collections_to_save = {};
                        Object.values(added_collections).map(c => {
                            const {id, products, crates, projected, collection_ref, locked, grand_total} = c;
                            return collections_to_save[c.id] = {id, products, grand_total: grand_total ? grand_total : crates ? crates : projected, collection_ref,
                                completed: !!locked}
                        });
                        firebase.db.ref(DATABASE_REFS.DATABASE_REFS.organisations.bulk.picking(organisation_id)).child(existing.id).update({
                            customer,
                            recipient,
                            collections: collections_to_save
                        }).then(() => onHide())
                    }} disabled={Object.keys(added_collections).length < 1}>Update</Button>
                    <Button variant={'secondary'} onClick={onHide}>Close</Button>
                </ButtonGroup>
            }
        </ModalFooter>
    </Modal>
};


BulkPickingList.propTypes = {
    show: PropTypes.bool.isRequired,
    customers: PropTypes.array.isRequired,
    onHide: PropTypes.func.isRequired,
    existing: PropTypes.object,
    firebase: PropTypes.object.isRequired,
    organisation_id: PropTypes.string.isRequired
};
export default BulkPickingList;