import {Button, CloseButton, Col, FormControl, Modal, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ModalHeader from "react-bootstrap/ModalHeader";
import {toast} from "react-toastify";
import {toastConfig} from "../../config/toast_config";
import FormCheck from "react-bootstrap/FormCheck";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const CustomerImportTool = ({firebase, organisation_id, show, setImporting, organisation, customerList}) => {
    const [customers, setCustomers] = useState(null)
    const [removeAccess, setRemoveAccess] = useState(false)
    const [working, setWorking] = useState(false)
    const [selectedCustomers, setSelectedCustomers] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState(null)

    useEffect(() => {
        firebase.functions.httpsCallable('getImportableCustomers')({
            organisation_id: organisation_id,
        }).then((data) => {
            setCustomers(data.data)
        }).catch(err => toast.error(err, {...toastConfig, autoClose: 5000}));
    }, [organisation_id])
    return <Modal size={"xl"} show={show} onHide={() => setImporting(false)}>
        <ModalHeader className={'bg-primary'}><h3 className={'text-light'}>Import Users to {organisation.name}</h3>
            <CloseButton className={'text-light'} onClick={() => setImporting(false)}/>
        </ModalHeader>
        <Modal.Body>
            {customers && <div>
                <Table bordered={true}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Customer(s)</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {Object.values(customers).map((c => <tr key={c.id}>
                    <td>{c.displayName}</td>
                    <td>{c.email}</td>
                    <td> {Object.values(c.organisations).map(o => `${o.customer}@${o.name}`).join(",")}</td>
                    <td>
                        <FormCheck defaultChecked={selectedCustomers.indexOf(c.id) > -1} onChange={(val) => {
                            const {checked} = val.target;
                            if(checked) {
                                if(selectedCustomers.indexOf(c.id) === -1) {
                                    setSelectedCustomers([...selectedCustomers, c.id])
                                }
                            } else {
                                setSelectedCustomers([...selectedCustomers.filter(customer=>customer!==c.id)])
                            }
                        }} label={"Import"}/>
                    </td>
                </tr>))}
                </tbody>
            </Table>
                <Col xs={12} className={"text-center"}><FormControl as={"select"} defaultValue={selectedCustomer}
                    onChange={(val)=>setSelectedCustomer(val.target.value)}
                ><option value={null}>--</option>
                    {Object.values(customerList || {}).map(c=><option value={c.id} key={c.id}>{c.name}</option>)}
                </FormControl></Col>
                <Col xs={12} className={"text-right"}>
                    <FormCheck defaultChecked={removeAccess} label={<b>Remove previous access</b>}
                    onChange={(val)=> {
                        const {checked} = val.target;
                        return setRemoveAccess(checked);
                    }}/>
                    {removeAccess && <span className={'text-danger font-weight-bold text-center'}>
                        Access will be removed from all registered organisations
                        for {selectedCustomers.length} users before importing, <br/>
                        to restore access to previous organisations you will
                        need to import or invite the users again.
                    </span>}
                </Col>

            </div>}
            {!customers && <LoadingScreen/>}
        </Modal.Body>
        <Modal.Footer>
            <Button variant={"secondary"} onClick={()=>setImporting(false)}>Close</Button>
            {!working && <Button variant={"primary"} disabled={selectedCustomers.length === 0 || selectedCustomer === null}
                onClick={()=>{
                    setWorking(true)
                    firebase.functions.httpsCallable('importCustomerUsers')({
                        organisation_id: organisation_id,
                        selectedCustomers,
                        customers,
                        removeAccess,
                        selectedCustomer
                    }).then(() => {
                        setWorking(false)
                        setImporting(false);
                    }).catch(err => {
                        toast.error(err.message, {...toastConfig, autoClose: 5000})
                        setWorking(false)
                    });
                }
                }
            >Import ({selectedCustomers.length})</Button>}
            {working && <Button disabled={true}>Working <FontAwesomeIcon icon={faSpinner} spin={true}/></Button>}
        </Modal.Footer>
    </Modal>
}

export default CustomerImportTool;