import React, {useEffect, useRef, useState} from 'react';
import ReactToPrint from "react-to-print";
import {Button} from "react-bootstrap";
import PrintedGatePass from "../GatePassIssuer/PrintedGatePass";
import DATABASE_REFS from "../../../constants/db_refs";
import {ContainerMasterDataContext} from "../../../components/ContainerMaster/ContainerMaster";



const Labels = ({collections, picking_list}) => {
    const labels = useRef();
    const getLabels = (products) => {
        const labels_to_print = [];
        Object.values(collections || {}).map(c=>Object.keys(c.products.actual || c.products.expected || {}).map(p=>{
            const total = c.products.actual ? Object.values(c.products.actual[p].crates || {}).reduce((a, b)=>a+parseInt(b.qty), 0)
                : parseInt(c.products.expected[p].crates);
            const code = c.products.actual ? p : c.products.expected[p].code;
            const product = Object.values(products||{}).find(p=>p.code === code)
            return [...new Array(total)].map((a, i)=>{
                labels_to_print.push(<div key={`${p}_${i}_${(picking_list||{}).picking_list_number}_${c.collection_ref}_label`}>
                    <h5 className={'mb-0 small'}>{c.collection_ref}</h5>
                    <div style={{lineHeight: '5mm'}}><small>{product ? product.description : code}</small></div>
                    <div><small>{1+i} of {total}</small></div>
                    <div><small>{c.collected_on ? new Date(parseInt(c.collected_on)).toLocaleString() : new Date().toLocaleString()}</small></div>
                </div>)
            })

        }))
        return labels_to_print;
    }


    return <ContainerMasterDataContext.Consumer>
            { ({products}) => <React.Fragment>
        <ReactToPrint content={()=>labels.current} trigger={()=><Button size={'sm'} variant={'tertiary'}>Print Labels</Button>}/>
        <div style={{display: 'none'}}>
            <div ref={labels} className={'bulk_labels'}>
                {getLabels(products)}
            </div>
        </div>
        </React.Fragment>}
        </ContainerMasterDataContext.Consumer>


};


const PrintListPass = ({user_name, pass_id, customer, firebase, organisation_id, picking_list, list_total, organisation}) => {
    const labels = useRef();
    const [pass, setPass] = useState(null);

    const fetchPass= async () => {
        const pass_snap = await firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).child(pass_id).once('value');
        if(pass_snap.val()) {
            setPass(pass_snap.val())
        }
    };
   useEffect(()=>{
       fetchPass();
   }, [pass_id])
    return <React.Fragment>
        <ReactToPrint content={()=>labels.current} trigger={()=><Button size={'sm'} variant={'tertiary'}>Print Gate Pass</Button>}/>
        <div style={{display: 'none'}}>
            <div ref={labels} className={'gate_pass'}>
                {pass && <PrintedGatePass user_name={user_name} list_total={list_total} picking_lists={{}} customer={customer} logo={organisation.logo} gate_pass={pass}/>}
            </div>
        </div>
    </React.Fragment>
};

export {PrintListPass}

export default Labels;