import React from 'react';
// noinspection ES6CheckImport
import PageTemplate from "../PageTemplate";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import BayForm from "./BayForm";
import BayTable from "./BayTable";
import {ContainerMasterDataContext} from "../../../components/ContainerMaster/ContainerMaster";
import ManualMovement from "../../../components/ManualMovement/ManualMovement";
import { DATABASE_REFS} from "../../../constants/db_refs";
import {Button} from "react-bootstrap";
import Hotbar from "../../../elements/Hotbar";

class Bays extends PageTemplate {
    constructor(props) {
        super(props);
        this.state = {
            filter: ''
        }

    }

    applyFilter(bays, customers, customer_filter) {
        let filteredBays = Object.values(bays || {}).filter((bay) => {
            const {filter} = this.state || '';
            const bay_customer_matches = (bay.customer && customers[bay.customer].name.toLowerCase().indexOf(filter.toLowerCase()) > -1);
            const bay_code_matches = bay.code.toLowerCase().indexOf(filter.toLowerCase()) > -1;
            const bay_product_matches =  (bay.product && bay.product.toLowerCase().indexOf(filter.toLowerCase()) > -1);
            const bay_container_matches =  (bay.source && bay.source.toLowerCase().indexOf(filter.toLowerCase()) > -1);
            const bay_collection_matches =  (bay.destination && bay.destination.toLowerCase().indexOf(filter.toLowerCase()) > -1);
           return bay_code_matches || bay_customer_matches || bay_product_matches || bay_container_matches || bay_collection_matches;
        });
        const filteredBays2 = filteredBays.filter(bay=>{
            if(!(bay.customer)) {
                return  customer_filter.filter.indexOf('Empty') > -1
            }
            const customer_name = customers[bay.customer].name;
            return customer_filter.filter.indexOf(customer_name) > -1;
        })
        return filteredBays2.sort((b1, b2) => {
           return b1.code <  b2.code ? -1 : 1;
        });
    }
    downloadAllBayData() {
        const {firebase, organisation_id} = this.props;
        const products_ref = DATABASE_REFS.organisations.forecasting.record(organisation_id, 'bays');
        firebase.db.ref(products_ref).once('value', (result) => {

            let output = [['status', "date", 'event', 'source', 'destination', 'change', 'product']];
            const items = result.val();
            Object.keys(items).map(product_id => {
                return Object.keys(items[product_id]).filter(k=>k!=="totals").map(status=>{
                    return Object.keys(items[product_id][status]).map(movement_id => {


                        const {eventDate, event, source, destination, change, product_code} = items[product_id][status][movement_id];
                        output.push( [status, new Date(eventDate).toISOString(), event, source.replace(/,/g, ":"), destination, change, product_code].join(',').replace('\n', ''))
                    })
                })
            })
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(output.join('\n')));
            element.setAttribute('download', 'bay_dump_'+(new Date().toISOString())+'.csv');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        })
    }
    setMovementItem(pid) {
        this.setState({moving: pid})
    }
    cancelMovementItem() {
        this.setState({moving: null})
    }

    getBayKeys() {
        return Object.values(this.props.bays || {}).map(p => p.code);
    }

    render() {
        const {organisation_id, updatePage, firebase} = this.props;
        const loading = false;
        const {moving} = this.state;

        return <div>
            <Hotbar updatePage={updatePage} page={'bays'}/>
            <ContainerMasterDataContext.Consumer>
                { ({customers, bays, products, bay_filters}) => {
                    return <div className={'col-xs-12 col-sm-11 ml-auto mr-auto bg-primary pt-2 pb-2 mt-1'}>
                        {moving && <ManualMovement organisation_id={organisation_id} firebase={firebase} products={products} show={true} bays={bays} bay_id={moving} cancelMovementItem={this.cancelMovementItem.bind(this)}/>}
                        <h3 className={'text-light text-center'}>Bays</h3>

                        {loading && <LoadingScreen/>}

                        {!loading && <Button variant={"info"} onClick={this.downloadAllBayData.bind(this)}>Download All Data</Button>}

                        { !loading && customers && <BayForm
                            bayKeys={this.getBayKeys()}
                            organisation_id={organisation_id}
                            firebase={firebase}
                            customers={Object.values(customers)}
                        /> }
                        { !loading && customers && <BayTable
                            setMovementItem={this.setMovementItem.bind(this)}
                            setFilter={this.setFilter}
                            organisation_id={organisation_id}
                            firebase={firebase}
                            customers={customers}
                            products={products}
                            filter={this.state.filter}
                            customer_filter={bay_filters.customer_filter}
                            all_bays={Object.values(bays)}
                            bays={this.applyFilter(bays, customers, bay_filters.customer_filter)}
                            total={Object.keys(bays || {}).length}/>
                        }
                    </div>
                }}

            </ContainerMasterDataContext.Consumer>

        </div>
    }
}

export default Bays;