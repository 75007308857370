import React from "react";
import moment from "moment";
import SearchInput from "./SearchInput";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import {ButtonGroup, InputGroup, Table} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ReactToPrint from "react-to-print";
import {CSVLink} from "react-csv";
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PageSizeControls, PaginationControls, TableHeaders} from "./Common";

const Header = ({
                    setPages,
                    num_per_page,
                    hide_date,
                    start, end,
                    setDate,
                    startCreation,
                    noDetails,
                    details,
                    toggleDetails,
                    custom_button_panel,
                    pagination_controls,
                    print_buttons,
                    has_export,
                    data,
                    dataKey,
                    advanced_filters,
                    show_advanced_filters,
                    toggleAdvancedFilters

                }) => {

    return <div className={'row m-0 w-100 no-print'}>
        {/* Print button */}
        <ButtonGroup>
            {print_buttons()}

            {has_export && <CSVLink

                data={data}
                filename={`${dataKey}_export.csv`}
                className="m-auto btn btn-info btn-sm no-print not-mobile"
                target="_blank"
            >
                {data.length > 0 ? has_export : 'Nothing to export'}
            </CSVLink>}

            {advanced_filters && <Button onClick={toggleAdvancedFilters} size={'sm'}
                                         className={'m-auto no-print not-mobile'}>{show_advanced_filters ? 'Hide advanced filters' : 'Show advanced filters'}</Button>}
        </ButtonGroup>

        {/* Date filter */}
        {!hide_date &&
        <ButtonGroup size={'sm'} className={'mt-auto mb-auto ml-1'}>


            <InputGroup.Prepend className={'pl-1 pr-1 no-print small m-auto text-center border-tertiary bg-tertiary text-light'}
                                style={{borderWidth: '1px 0 1px 1px', borderStyle: 'solid', lineHeight: '2.3em', borderBottomLeftRadius: '0.2rem', borderTopLeftRadius: '0.2rem'}} >From</InputGroup.Prepend>
            <DatePicker
                dateFormat="dd/MM/yyyy"
                selectsStart
                maxDate={new Date(parseInt(end))}
                style={{borderRadius: 0}}
                className={'form-control form-control-sm not-rounded'}
                selected={new Date(parseInt(start))}
                onChange={(date) => setDate('startDate', date)}
                onSelect={(date) => setDate('startDate', date)}

            />


            <InputGroup.Prepend className={'pl-1 pr-1 no-print small m-auto text-center border-tertiary bg-tertiary text-light'}
                                style={{borderWidth: '1px 0 1px 1px', borderStyle: 'solid', lineHeight: '2.3em'}} >To</InputGroup.Prepend>
            <DatePicker
            className={'form-control form-control-sm not-rounded-left'} name={'endDate'}
            dateFormat="dd/MM/yyyy"
            selectsEnd
            minDate={new Date(parseInt(start))}

            selected={new Date(parseInt(end))}
            onChange={(date) => setDate('endDate', date)}
            onSelect={(date) => setDate('endDate', date)}
        />

        </ButtonGroup>}
        {startCreation && <button className={'btn no-print btn-sm btn-success text-light m-auto'}
                                  onClick={startCreation}>
            <FontAwesomeIcon icon={'plus-circle'}/>
        </button>}
        {/* details toggle */}
        {!noDetails && <Button onClick={toggleDetails} size={'sm'}
                               className={'m-auto no-print not-mobile'}>{details ? 'Hide details' : 'Show details'}</Button>}

        {custom_button_panel && custom_button_panel()}
        <PageSizeControls setPages={setPages} page_size={num_per_page}/>
        {
            pagination_controls && pagination_controls()
        }

    </div>
};
const Body = ({small, hide_date, start, end, setSort, sort, reverse, headers, report_title, email, content, visible, dataKey}) => {
    return <div className={'inner-content-products w-100 row m-0'}>
        {/* Report heading */}
        <h3 className={'print-only w-100'}>{report_title}</h3>
        <br/>
        <p className={'small ml-3 print-only'}>Created
            by {email}</p>
        <hr className={'w-100 bg-primary'}/>
        {!hide_date &&
        <h5 className={'w-100 print-only mb-1 text-center'}>From {new Date(parseInt(start)).toLocaleDateString()} to {new Date(parseInt(end)).toLocaleDateString()}</h5>}
        {/* table headers */}

        <div id='inner-content-table' className={'row m-auto'}>
            <Table size={'sm'} responsive={true}>
                <thead style={small ? {fontSize: '0.95em'} : {}}>
                <TableHeaders headers={headers}
                              setSort={setSort}
                              sort={sort}
                              reverse={reverse}
                />
                </thead>
                <tbody>
                {content}

                </tbody>
            </Table>
            {visible === 0 &&
            <div className={'text-center col-12 bg-light pb-3'}>
                {`No matching ${dataKey.replace(/_/g, ' ')} found!`}
                <br/>
                <FontAwesomeIcon icon={'exclamation-triangle'} size={'4x'}/>
            </div>}
        </div>
    </div>
};

const Outer = ({children, tabs, print_processing, setFilter, filter}) => {
    return (
        <div className={'row'}>
            <div className={'col-12 bg-tertiary pt-1'}>
                {tabs && tabs()}
                <SearchInput prefix={'product'}
                             className={'input-sm form-control'}
                             setFilter={setFilter}
                             value={filter}/>
            </div>
            {/* Loading screen when printing*/}
            {
                print_processing &&
                <div className={'col-12 col-md-10 offset-sm-1 bg-tertiary p-3'}>
                    <div className={'col-12 bg-light row m-0 p-3 bays'}>
                        <h3>{print_processing}</h3>
                        <LoadingScreen/>
                    </div>
                </div>
            }
            {children}
        </div>)
};

class SearchableTable extends React.Component {
    initialState = {
        startDate: moment().startOf('week').toDate().getTime(),
        endDate: moment().toDate().getTime(),
        reverse: 1,
        sorted_data: []
    };

    constructor(props) {
        super(props);
        this.toggleDetails = this.toggleDetails.bind(this);
        this.pageUp = this.pageUp.bind(this);
        this.pageDown = this.pageDown.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.setPrintProcessing = this.setPrintProcessing.bind(this);
        this.updatePrintProcessing = this.updatePrintProcessing.bind(this);
        this.endPrintProcessing = this.endPrintProcessing.bind(this);
        if (this.deleteItem) {
            this.deleteItem = this.deleteItem.bind(this);

        }
        if (this.editItem) {
            this.editItem = this.editItem.bind(this);

        }
        this.setDeleteItem = this.setDeleteItem.bind(this);
        this.setEditItem = this.setEditItem.bind(this);
        this.setFilter = this.setFilter.bind(this);
    }

    setFilter(event) {
        this.setState({filter: event.target.value})
    }

    setDeleteItem(pid) {
        this.setState({deleting: pid})
    }

    setEditItem(pid) {
        let editing = pid;
        if (editing) {
            editing = {...editing};
        }
        this.setState({editing: editing})
    }

    setPrintProcessing() {
        this.componentRef.style.maxHeight = `none`;
        this.setState({print_processing: "Processing data."}, () => {
            this.printButton.click();
        });
    }

    updatePrintProcessing() {
        this.setState({print_processing: "Opening print dialog"})
    }

    endPrintProcessing() {
        this.componentRef.style.maxHeight = `${(window.innerHeight - this.componentRef.getBoundingClientRect().top) * 0.9}px`;
        this.setState({print_processing: false});
    }

    toggleDetails() {
        const newState = !this.state.details;
        this.setState({details: newState});
    }

    componentDidMount() {
        if (!this.inner) return
        window.addEventListener('resize', () => {
            if (!this.inner) return
                this.inner.style.maxHeight = `${(window.innerHeight - this.inner.getBoundingClientRect().top) * 0.95}px`;
        });
        this.inner.style.maxHeight = `${(window.innerHeight - this.inner.getBoundingClientRect().top) * 0.93}px`;
        this.inner.style.minHeight = `${(window.innerHeight - this.inner.getBoundingClientRect().top) * 0.93}px`;
        this.inner.style.overflowY = `scroll`;
    }

    totalPages(total) {
        const {num_per_page} = this.state;
        return Math.ceil((total - 1) / num_per_page);
    }

    pageUp(total_pages) {
        const {page} = this.state;
        if (page + 1 >= total_pages) {
            return;
        }
        this.setState({page: page + 1})
    }

    pageDown() {
        const {page} = this.state;
        if (page - 1 < 0) {
            return;
        }
        this.setState({page: page - 1})
    }

    firstPage() {
        this.setState({page: 0})
    }

    lastPage(total_pages) {
        this.setState({page: total_pages - 1})
    }

    componentDidUpdate() {
        if (this.inner) {
            this.inner.style.maxHeight = `${(window.innerHeight - this.inner.getBoundingClientRect().top) * 0.9}px`;
            this.inner.style.overflowY = `scroll`;
            if (!this.state.print_processing) this.inner.style.maxHeight = `${(window.innerHeight - this.inner.getBoundingClientRect().top) * 0.9}px`;
        }

    }


    handleDateChange(name, date, setter, start, end) {
        let newDate = null;
        if (name === "startDate") {
            newDate = moment(date).startOf("day");
        } else {
            newDate = moment(date).endOf("day");
        }
        if (isNaN(newDate.toDate().getTime())) {
            return date
        } else {
            newDate = newDate.toDate().getTime();
        }
        this.setState({loading: true, page: 0}, () => {
            setter(name === "startDate" ? newDate : start, name !== "startDate" ? newDate : end, () => this.setState({loading: false}));
        })

    }

    render() {
        const {props, tabs, CMContext, key, state} = this;
        const {page, num_per_page} = state;
        const {setFilter} = this;
        const {small} = props;
        return (
            <CMContext.Consumer>
                {(context_data) => {
                    const {start, end} = context_data;
                    if (!context_data[key].sorted) return <LoadingScreen/>;
                    const {
                        setDate, filter, setSort, sort, reverse, data, sorted, headers, status_filters,
                        updateStatusLabels, exportData, selectAll, selectNone, extra_date_filters,
                        updateMovementStatusLabels, selectAllMovement, selectNoneMovement, movement_status_filters,
                        notificationStates, changeNotificationFilters, notificationState, customer_filter
                    } = context_data[key];
                    const grand_total = sorted.length;
                    const total = data.length;
                    return <Outer key={`${props.filter}_filter`} filter={filter} value={filter} print_processing={state.print_processing}
                   tabs={tabs} setFilter={setFilter.bind(this)} className={'row'}>
                <div style={state.print_processing ? {display: 'none'} : {}}
                     className={'col-12 bg-tertiary p-3'}>
                    <div id={'printarea'} className={'col-12 bg-light row m-0 p-3 bays print-block'}
                         ref={ref => this.componentRef = ref}>
                                <div className={'w-100'}>
                                    <Header
                                        small={small}
                                        data={exportData}
                                        advanced_filters={!!this.getAdvancedFilters && !this.state.hide_advanced_filters}
                                        show_advanced_filters={this.state.advanced_filters}
                                        toggleAdvancedFilters={() => this.setState({advanced_filters: !this.state.advanced_filters})}
                                        key={`${start}_${end}_header`}
                                        details={this.state.details}
                                        noDetails={this.state.noDetails}
                                        start={start}
                                        end={end}
                                        setDate={(name, date) => this.handleDateChange(name, date, setDate, start, end)}
                                        total={total} startCreation={this.props.startCreation}
                                        toggleDetails={this.toggleDetails}
                                        hide_date={this.state.hide_date}
                                        dataKey={key}
                                        num_per_page={num_per_page}
                                        pagination_controls={() => <PaginationControls
                                            firstPage={this.firstPage}
                                            pageDown={() => this.pageDown(total)}
                                            pageUp={() => this.pageUp(total)}
                                            lastPage={() => this.lastPage(this.totalPages(total))}
                                            page={page}
                                            totalPages={this.totalPages(total)}
                                            total={total}
                                            grand_total={grand_total}
                                            num_per_page={num_per_page}
                                        />}
                                        setPages={(event) => {
                                            this.setState({num_per_page: parseInt(event.target.value)})
                                        }}
                                        has_export={this.has_export}
                                        print_buttons={() => [
                                            <Button key={'print_start'} onClick={this.setPrintProcessing} size={'sm'}
                                                    className={'m-auto no-print not-mobile'}>Print</Button>,
                                            <ReactToPrint
                                                key={'rtp'}
                                                trigger={() => <Button style={{display: "none"}} ref={ref => {
                                                    this.printButton = ref
                                                }} size={'sm'}
                                                                       className={'m-auto no-print not-mobile'}>Print</Button>}
                                                content={() => this.componentRef}
                                                onBeforePrint={this.updatePrintProcessing}
                                                onAfterPrint={this.endPrintProcessing}
                                            />
                                        ]}
                                        custom_button_panel={() => this.getCustomButtonPanel ? this.getCustomButtonPanel() : false}
                                    />
                                    {this.state.advanced_filters && this.getAdvancedFilters && this.getAdvancedFilters(
                                        {
                                            updateStatusLabels, status_filters, selectAll, selectNone,
                                            customer_filter,
                                            extra_date_filters,  updateMovementStatusLabels, selectAllMovement,
                                            selectNoneMovement, movement_status_filters, notificationStates, changeNotificationFilters, currentNotificationState: notificationState
                                        })}
                                    <div ref={ref => this.inner = ref}>
                                        {!this.state.loading && <Body
                                            small={small}
                                            key={`${start}_${end}_body`}
                                            content={this.getContent(data.slice(page * num_per_page, (page + 1) * num_per_page),this.getAdditionalHeaders  ? [...headers, ...this.getAdditionalHeaders()] : headers)}
                                            end={end}
                                            start={start}
                                            email={this.props.firebase.auth.currentUser.email}
                                            sort={sort}
                                            dataKey={key}
                                            report_title={this.getReportTitle()}
                                            headers={this.getAdditionalHeaders  ? [...headers, ...this.getAdditionalHeaders()] : headers} reverse={reverse}
                                            setSort={setSort} visible={data.length} hide_date={this.state.hide_date}

                                        />}
                                        {this.state.loading && <LoadingScreen/>}
                                    </div>
                                    {this.state.deleting && this.getDeletionModal(data[this.state.deleting])}
                                    {this.state.editing && this.getEditingModal(data[this.state.editing])}
                                    {this.state.revoking && this.getAdditionalModal(data[this.state.revoking])}
                                </div>
                    </div>

                </div>
            </Outer> }}
            </CMContext.Consumer>)
    }

}

export default SearchableTable;