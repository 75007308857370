import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";


class Button extends React.Component {
    render() {
        const {intl, title_id, disabled, className, onClick, testid, icon} = this.props;
        const title =  intl.formatMessage({ id: title_id });
        return (
            disabled ?
                <button disabled data-testid={testid} title={title} className={'btn '+className}>{icon && icon} { title }</button>
                :
                <button onClick={onClick} data-testid={testid} title={title} className={'btn '+className}>{icon && icon} { title }</button>
        );
    }
}

Button.propTypes = {
    title_id: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    testid: PropTypes.string,
    icon: PropTypes.object,
    intl: PropTypes.object
};
Button.defaultProps = {
    testid: "button"
};

export default injectIntl(Button);