import * as React from "react";
import db_refs from '../../../constants/db_refs';
import {TrackingTable} from "../Tracking";
import {
    MOVEMENT_STATUS_LABELS2,
    STATUS_LABELS
} from "../../../components/ContainerMaster/withForecast";
import {
    AdvancedFilterPanel,
    AdvancedFilter,
    AdvancedDateFilter,
    AdvancedRadioFilter
} from "../../../elements/AdvancedFiltering";
import Hotbar from "../../../elements/Hotbar";

class Tracking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totals: null
        }

    }

    componentDidMount() {
        const {firebase, organisation_id} = this.props;
        firebase.db.ref(db_refs.organisations.tracking.totals(organisation_id))

    }

    render() {
        const { firebase} = this.props;
        return <div className={'col-xs-12 col-sm-11 ml-auto mr-auto bg-primary pt-2 pb-2 mt-1'}>

            <Hotbar updatePage={this.props.updatePage} page={'reporting'}/>
            <h3 className={'text-light text-center'}>Reporting</h3>
<div className={'col-12'}>
               <CompletedTable filter={this.props.filter.completed}
                               setFilter={(value, callback) => {
                                   this.setState({loading: true}, () => {
                                       this.props.setCompleted(value, () => this.setState({loading: false}, callback))
                                   });
                               }} small={true} firebase={firebase} />
</div>
            <div className={'col-6'}>

            </div>
        </div>
    }
}

class CompletedTable extends TrackingTable {
    key = 'completed';
    has_export = 'Export Movement';
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState,
            page: 0,
            num_per_page: 10,
            noDetails: true,
            hide_date: false
        };

    }
    getAdditionalHeaders() {
        return [];
    }

    getAdvancedFilters({updateStatusLabels: onClick, status_filters, selectAll, selectNone, extra_date_filters,
                           updateMovementStatusLabels: onClickMovement, selectAllMovement,
                           selectNoneMovement, movement_status_filters,
                           notificationStates, changeNotificationFilters, currentNotificationState}) {
        return <AdvancedFilterPanel>
            <AdvancedRadioFilter
                legend_text={"Notification Status"}
                list={notificationStates}
                onClick={changeNotificationFilters}
                selection={currentNotificationState}
            />
            <AdvancedFilter
            legend_text={'Movement Type'}
            onClick={onClick}
            selectAll={selectAll}
            deselectAll={selectNone}
            original_list={[...STATUS_LABELS]}
            current_list={status_filters}
        />

            <AdvancedFilter
                legend_text={'Movement Status'}
                onClick={onClickMovement}
                selectAll={selectAllMovement}
                deselectAll={selectNoneMovement}
                original_list={[...MOVEMENT_STATUS_LABELS2]}
                current_list={movement_status_filters}
            />
            {Object.values(extra_date_filters).map((edf,i)=><AdvancedDateFilter key={`${Object.keys(extra_date_filters || {})[i]}_adf`} {...edf} legend_text={edf.label}/>)}
        </AdvancedFilterPanel>
    }

}
export {CompletedTable};
export default Tracking;