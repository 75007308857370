import React, {useEffect, useRef, useState} from "react";
import {Table} from "react-bootstrap";
const $ = require('jquery');
$.DataTable = require('datatables.net');

const columns = [
    {
        title: 'Product Code',
        data: 'code',
        width: "15%",
        render: (data) => `<h2 class="mb-0">${data}</h2>`
    },{
        title: 'Description',
        width: "30%",
        data: 'description'
    },{
        title: 'Incoming',
        data: 'in',
        className: 'text-center',
        width: "8%",
        render: (data) => `<h2 class="text-center text-success">${data}</h2>`
    },{
        title: 'Current',
        data: 'current',
        className: 'text-center',
        width: "8%",
        render: (data) => `<h2 class="text-center text-primary">${data}</h2>`
    },{
        title: 'Outgoing',
        data: 'out',
        className: 'text-center',
        width: "8%",
        render: (data) => `<h2 class="text-center text-danger">${data}</h2>`
    },{
        title: 'Last Active',
        data: 'last_active',
        width: "12%",
        render: (data) => `<h2>${data}</h2>`
    },
]


const ProductView = ({products}) => {
    const [datatable, setTable] = useState(null)
    const table = useRef();
    useEffect(()=>{
        const tbl = $(table.current).DataTable({
            data: products,
            columns,
            processing: true,
            dom: '<"row"<"col-3"l><"col.mb-1"p><"col-2"i><"col"f>>t'
        });
        setTable(tbl)
        return () => {
            if (datatable)
                datatable.destroy(true);
        }
    }, [])

    useEffect(()=>{
        if (datatable){
            datatable.clear();
            datatable.rows.add(products)
            datatable.draw(null, false)
        }

    }, [products])

    return <div className={'col-12'}>
        <Table className={'bg-primary text-light'} size={'lg'} variant={'dark'} id="myTable" ref={table}/>
    </div>
};
export default ProductView