import React from "react";

import * as PropTypes from 'prop-types';
import moment from "moment";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import {Badge, ButtonGroup, FormControl, Image, InputGroup, Table} from "react-bootstrap";
import {CSVLink} from "react-csv";
import SortButton from "./SortButton";
import SearchInput from "./SearchInput";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/en-GB';
import {ContainerMasterDataContext} from "../components/ContainerMaster/ContainerMaster";

registerLocale('en-GB', es);
setDefaultLocale('en-GB');


const headingStyles = (col, alignment, header, noprint, muted) => `item-row pt-1 pl-2 pr-2 text-${alignment || 'center'}${header ? ` bg-${muted ? 'danger' : 'primary'} text-light rounded text-center` : ''}${noprint ? ' no-print' : ''}${muted ? ' muted' : ''}`;
const RowCell = ({muted, col, title, children, alignment, header, noprint}) => {
    return <td className={headingStyles(col, alignment, header, noprint, muted)}><span
        className={'mobile-label'}>{title ? `${title}:` : ''} </span> {children}</td>
};

const TableButtons = ({product, setEditItem, setDeleteItem, setMovementItem, allow_delete, disableItem, enableItem}) => {
    return <ButtonGroup>
        {setMovementItem && <Button key={`movement_button_${product.parent}_${product.id}`} title={'Move stock in or out of this bay?'} item={product.id}
                                    onClick={()=>setMovementItem(product.id)}
                  variant={'info'} size={'sm'}><span className="fa-layers fa-fw">
  <FontAwesomeIcon style={{right: '10px', top: '10px'}} icon={'caret-down'} size='lg' />
  <FontAwesomeIcon style={{left: '10px', bottom: '10px'}} icon={'caret-up'} size='lg' />
</span></Button>}
        <Button onClick={() => {
            setEditItem(product)
        }} key={`edit_button_${product.parent}_${product.id}`} title={'Edit?'} data-item={product} variant={'primary'}
                size={'sm'}><FontAwesomeIcon icon={'edit'}/></Button>
        {disableItem && <Button key={`disable_button_${product.parent}_${product.id}`} title={'Disable?'} item={product.id}
                variant={'warning'} size={'sm'} onClick={disableItem}><FontAwesomeIcon icon={'ban'}/></Button>}
        {enableItem && <Button key={`enable_button_${product.parent}_${product.id}`} title={'Enable?'} item={product.id}
                                variant={'success'}  onClick={enableItem} size={'sm'}><FontAwesomeIcon icon={'undo'}/></Button>}
        <Button onClick={() => {
            setDeleteItem(product)
        }} key={`delete_button_${product.parent}_${product.id}`} title={'Delete?'} disabled={!allow_delete}
                data-item={product} variant={'danger'} size={'sm'}><FontAwesomeIcon icon={'trash'}/></Button>

    </ButtonGroup>
};

const PageSizeControls = ({setPages, page_size}) => {
    return <ButtonGroup size={'sm'} className={'ml-auto mt-auto mb-auto mr-1'}>
        <InputGroup.Prepend className={'pl-1 pr-1 no-print small m-auto text-center border-tertiary bg-tertiary text-light'}
                            style={{borderWidth: '1px 0 1px 1px', borderStyle: 'solid', lineHeight: '2.3em', borderBottomLeftRadius: '0.2rem', borderTopLeftRadius: '0.2rem'}} >Per page</InputGroup.Prepend>
        <Button onClick={()=>setPages({target: {value: 10}})} className={"btn-" + (page_size === 10 ? 'info' : 'primary')}>10</Button>
        <Button onClick={()=>setPages({target: {value: 25}})} className={"btn-" +( page_size === 25 ? 'info' : 'primary')}>25</Button>
        <Button onClick={()=>setPages({target: {value: 50}})} className={"btn-" + (page_size === 50 ? 'info' : 'primary')}>50</Button>
        <Button onClick={()=>setPages({target: {value: 100}})} className={"btn-" + (page_size === 100 ? 'info' : 'primary')}>100</Button>
    </ButtonGroup>
};

const PaginationControls = ({firstPage, pageDown, page, totalPages, pageUp, lastPage, num_per_page, total}) => {
    const start = page;
    return <ButtonGroup size={'sm'} role={'pagination'} className={'mt-auto mb-auto mr-0'}>
        <Button disabled={page === 0} key='first_button' onClick={firstPage} size={'sm'}

                className={'p-1 m-auto no-print'}>
            <FontAwesomeIcon icon={'fast-backward'} fixedWidth={true}/>
        </Button>
        <Button disabled={page === 0} key='previous_button' onClick={pageDown} size={'sm'}

                className={'m-auto no-print'}>
            <FontAwesomeIcon icon={'caret-left'} fixedWidth={true}/>
        </Button>
        <InputGroup.Append className={'pl-1 pr-1 no-print small m-auto text-center border-primary'}
                           style={{borderWidth: '1px 0', borderStyle: 'solid', lineHeight: '2.3em'}}
                           key={'pagination_label'}>Showing {(start * num_per_page) + 1} to {(start * num_per_page) + num_per_page < total ? (start * num_per_page) + num_per_page : total} of {total}</InputGroup.Append>
        <Button disabled={page + 1 === totalPages} key={'next_button'} onClick={pageUp} size={'sm'}

                className={'m-auto no-print'}>
            <FontAwesomeIcon fixedWidth={true} icon={'caret-right'}/>
        </Button>
        <Button disabled={page + 1 === totalPages} key={'last_button'} onClick={lastPage} size={'sm'}

                className={'m-auto no-print'}>
            <FontAwesomeIcon fixedWidth={true} icon={'fast-forward'}/>
        </Button>

    </ButtonGroup>;
};

PaginationControls.propTypes = {
    firstPage: PropTypes.func.isRequired,
    pageDown: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    num_per_page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    grand_total: PropTypes.number.isRequired,
    pageUp: PropTypes.func.isRequired,
    lastPage: PropTypes.func.isRequired
};

const TableHeaders = ({headers, reverse, sort, setSort}) => {
    return <tr>
        {
            headers.map((header, i) => {
                const width = `${header.size * 9}%`;
                const headerProps = {
                    className: `${header.key ? 'interactive ' : ''}${header.key==='debug' ? 'no-print ' : ''}font-weight-bold text-center`,
                    key: `header_${i}`
                };
                if (header.key) {
                    headerProps['data-sort'] = header.key;
                    headerProps.onClick = (e)=>setSort(e.target.dataset.sort);
                    headerProps.style = {boxShadow: '1px 1px 2px lightgray', width: width, verticalAlign: 'top'};
                }
                return <th {...headerProps} data-sort={header.key}>
                    <div className={'row'} style={{pointerEvents: 'none'}}><div className={'col-8'}>{header.label}</div>
                        <div className={'col-4'}>{header.key && <SortButton reverse={reverse} current_sort={sort} sort={header.key}/>}</div>
                    </div>
                </th>
            })}
        <th>&nbsp;</th>
    </tr>;
};
TableHeaders.propTypes = {
    headers: PropTypes.array.isRequired,
    reverse: PropTypes.number.isRequired,
    setSort: PropTypes.func.isRequired,
    sort: PropTypes.string.isRequired
};

class DateSortableContent extends React.Component {
    initialState = {
        startDate: moment().startOf('month').toDate().getTime(),
        endDate: moment().toDate().getTime(),
        reverse: 1,
        sorted_data: []
    };

    constructor(props) {
        super(props);
        this.toggleDetails = this.toggleDetails.bind(this);
        this.pageUp = this.pageUp.bind(this);
        this.pageDown = this.pageDown.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.setSort = this.setSort.bind(this);
        this.setPrintProcessing = this.setPrintProcessing.bind(this);
        this.updatePrintProcessing = this.updatePrintProcessing.bind(this);
        this.endPrintProcessing = this.endPrintProcessing.bind(this);
        if (this.deleteItem) {
            this.deleteItem = this.deleteItem.bind(this);

        }
        if (this.editItem) {
            this.editItem = this.editItem.bind(this);

        }
        this.setDeleteItem = this.setDeleteItem.bind(this);
        this.setEditItem = this.setEditItem.bind(this);
        this.setFilter = this.setFilter.bind(this);

    }

    setFilter(event) {
        this.setState({filter: event.target.value})
    }

    setDeleteItem(pid) {
        this.setState({deleting: pid})
    }

    setEditItem(pid) {
        let editing = pid;
        if (editing) {
            editing = {...editing};
        }
        this.setState({editing: editing})
    }

    setPrintProcessing() {
        this.inner.style.maxHeight = `none`;
        this.setState({print_processing: "Processing data."}, () => {
            this.printButton.click();
        });
    }

    updatePrintProcessing() {
        this.setState({print_processing: "Opening print dialog"})
    }

    endPrintProcessing() {
        this.inner.style.maxHeight = `${(window.innerHeight - this.inner.getBoundingClientRect().top) * 0.9}px`;
        this.setState({print_processing: false});
    }

    toggleDetails() {
        const newState = !this.state.details;
        this.setState({details: newState});
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            if (this.inner)
                this.inner.style.maxHeight = `${(window.innerHeight - this.inner.getBoundingClientRect().top) * 0.9}px`;
        });
        this.props.firebase.db.ref(`processes/organisations/${this.props.organisation_id}/importing`).on('value', (importing) => {
            if (!importing.val())
                this.props.firebase.db.ref('history/bays/' + this.props.organisation_id).once('value').then(() => {
                    this.setState({importing: importing.val()})
                });
            else
                this.setState({importing: importing.val()})
        });

        let sorted_data = [];
        if (this.state.key) {
            sorted_data = Object.values(this.props[this.state.key]).sort((a, b) => {
                return ((a.code < b.code || !b.code) ? -1 : a.code > b.code ? 1 : 0);
            });
        }
        this.setSort();
        this.inner.style.maxHeight = `${(window.innerHeight - this.inner.getBoundingClientRect().top) * 0.9}px`;
        //this.inner.style.minHeight = `${(window.innerHeight - this.inner.getBoundingClientRect().top) * 0.9}px`;
        this.setState({sorted_data: sorted_data})
    }

    totalPages() {
        const {num_per_page, sorted_data} = this.state;
        if (this.subFilter) {
            return Math.ceil(this.subFilter(sorted_data).length / num_per_page);
        }
        return Math.ceil(sorted_data.length / num_per_page);
    }

    pageUp() {
        const {page} = this.state;
        if (page + 1 >= this.totalPages()) {
            return;
        }
        this.setState({page: page + 1})
    }

    pageDown() {
        const {page} = this.state;
        if (page - 1 < 0) {
            return;
        }
        this.setState({page: page - 1})
    }

    firstPage() {
        this.setState({page: 0})
    }

    lastPage() {
        this.setState({page: this.totalPages() - 1})
    }

    componentDidUpdate(prevProps) {
        if (this.inner)
            this.inner.style.maxHeight = `${(window.innerHeight - this.inner.getBoundingClientRect().top) * 0.9}px`;
        if (!this.state.print_processing) this.inner.style.maxHeight = `${(window.innerHeight - this.inner.getBoundingClientRect().top) * 0.9}px`;
        const pages = this.totalPages();
        if (pages < this.state.page) {
            this.setState({page: pages > 0 ? this.totalPages() - 1 : 0})
        }
        if (this.props[this.state.key] !== prevProps[this.state.key]) {
            this.setSort();
        }
    }

    setSort(event) {
        const sort = event ? event: this.state.sort;
        const reverse = this.state.sort !== sort || !event ? this.state.reverse : this.state.reverse * -1;
        if (this.state.sort !== sort || !event) {
            this.setState({data: null})
        }

        if (this.state.sort !== sort || reverse !== this.state.reverse || !event) {
            let data_to_use = null;
            const {getItems} = this;
            if (getItems) {
                data_to_use = Object.values(getItems())
            } else {
                data_to_use = Object.values(this.props[this.state.key] || {})
            }
            let sorted_data = data_to_use.sort((a, b) => {

                const split_sort = sort.split(',');
                if (split_sort.length > 1) {
                    let a_val = a[split_sort[0]] === '-' || a[split_sort[0]] === '' ? a[split_sort[1]] : a[split_sort[0]];
                    let b_val = b[split_sort[0]] === '-' || a[split_sort[0]] === '' ? b[split_sort[1]] : b[split_sort[0]];
                    a_val = typeof (a_val) === 'string' ? a_val.toLowerCase() : a_val;
                    b_val = typeof (b_val )=== 'string' ? b_val.toLowerCase() : b_val;
                    return ((a_val < b_val || !b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
                }
                if(!isNaN(parseInt(a[sort])) && !isNaN(parseInt(a[sort]))) {
                    const a_val = typeof (a[sort]) === 'string' ? a[sort].toLowerCase() : a[sort] ? a[sort] : 0;
                    const b_val = typeof (b[sort]) === 'string' ? b[sort].toLowerCase() : b[sort] ? b[sort] : 0;
                    return ((a_val < b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
                } else {
                    const a_val =  a[sort] ? a[sort] : 0;
                    const b_val = b[sort] ? b[sort] : 0;
                    return ((a_val < b_val) ? -1 : a_val > b_val ? 1 : 0) * reverse;
                }
            });
            if(this.subFilter) {
                this.setState({
                    sort: sort,
                    reverse: reverse,
                    sorted_data: this.subFilter(sorted_data)
                });
                return
            }

            this.setState({
                sort: sort,
                reverse: reverse,
                sorted_data: sorted_data
            })
        }
    }


    handleDateChange(name, date) {
        let newDate = null;
        if (name === "startDate") {
            newDate = moment(date).startOf("day");
        } else {
            newDate = moment(date).endOf("day");
        }
        if (isNaN(newDate.toDate().getTime())) {
            return date
        } else {
            newDate = newDate.toDate().getTime();
        }
        if(this.props.setDatesOverride) {
            const {setter, start, end} = this.props.setDatesOverride;
            setter(name==="startDate" ? newDate : start, name!=="startDate" ? newDate : end);
            return;
        }
        let newState = {
            startDate: this.state.startDate.toString(),
            endDate: this.state.endDate.toString(),
            data: null
        };
        newState[name] = newDate.toString();

        this.setState(newState)
    }

    render() {
        const {total} = this.props;
        const {sorted_data, num_per_page} = this.state;
        const startDate = this.props.setDatesOverride ? this.props.setDatesOverride.start : this.state.startDate;
        const endDate = this.props.setDatesOverride ? this.props.setDatesOverride.end : this.state.endDate;
        // noinspection JSUnusedGlobalSymbols
        return (
            <div className={'row'}>
                <div className={'col-12  bg-tertiary pt-1 no-print'}>
                    {this.props.tabs && this.props.tabs()}
                    <SearchInput prefix={'product'}
                                                                                 className={'input-sm form-control'}
                                 setFilter={(e) => {
                                     this.props.setFilter(e);
                                     this.setSort()
                                 }}/>
                </div>
                {/* Loading screen when printing*/}
                {
                    this.state.print_processing &&
                    <div className={'col-12 col-sm-12 offset-sm-1 bg-tertiary p-3'}>
                        <div className={'col-12 bg-light row m-0 p-3 bays'}>
                            <h3>{this.state.print_processing}</h3>
                            <LoadingScreen/>
                        </div>
                    </div>
                }

                <div style={this.state.print_processing ? {display: 'none'} : {}}
                     className={'col-12 col-sm-12 bg-tertiary p-3'}>
                    <div id={'printarea'} className={'col-12 bg-light row m-0 p-3 bays print-block'}
                         ref={ref => this.componentRef = ref}>
                        {/* HEADER */}
                        <div className={'row m-0 w-100 no-print'}>
                            {/* Print button */}
                            <ButtonGroup >

                                {this.has_export2 && <CSVLink
                                    ref={ref => this.export = ref}
                                    disabled={true}
                                    data={this.state.sorted_data.map(datum=>(datum.product  ?
                                        {
                                            Bay: datum.code,
                                            Customer: (this.props.customers[datum.customer] || {}).name,
                                            "Product Code": datum.product,
                                            "Product Description": (Object.values(this.props.products || {}).find(p=>p.code===datum.product) || {}).description,
                                            Qty: (datum.current || 0),
                                        } :
                                        {
                                            Bay: datum.code,
                                            Customer: '-',
                                            "Product Code": '-',
                                            "Product Description": '-',
                                            Qty: (datum.current || 0),
                                        })) || ""}
                                    filename={`${this.state.key}_export.csv`}
                                    className="m-auto btn btn-primary btn-sm no-print"
                                    target="_blank"
                                >
                                    {this.state.sorted_data.length > 0 ? this.has_export2 : 'Nothing to export'}
                                </CSVLink>}
                                {this.has_export && this.state.data && <CSVLink
                                    ref={ref => this.export = ref}
                                    disabled={true}
                                    data={this.state.data || ""}
                                    filename={`${this.state.key}_export.csv`}
                                    className="m-auto btn btn-info btn-sm no-print"
                                    target="_blank"
                                >
                                    {this.state.data.length > 0 ? this.has_export : 'Nothing to export'}
                                </CSVLink>}

                                {this.has_export && !this.state.data && <Button

                                    onClick={() => this.getData()}
                                    className="m-auto btn btn-info btn-sm no-print"
                                >
                                    {this.has_export2 ? "Generate Full Report" :"Generate Report"}
                                </Button>}
                                {this.getAdvancedFilters && <Button onClick={()=>this.setState({advanced_filters: !this.state.advanced_filters})} size={'sm'}
                                                                    className={'m-auto no-print not-mobile'}>{this.state.advanced_filters ? 'Hide advanced filters' : 'Show advanced filters'}</Button>}
                            </ButtonGroup>

                            {/* Date filter */}
                            {!this.state.hide_date &&
                            <div className={'no-print col-12 col-md-5 row p-0 pr-1 pl-1 inline-form'}>
                                <div className={'no-print col-6'}>
                                    <span style={{minWidth: '10%'}}>From: </span><DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selectsStart
                                    maxDate={new Date(parseInt(endDate))}
                                    className={'form-control no-print'}
                                    selected={new Date(parseInt(startDate))}
                                    onChange={(date) => this.handleDateChange("startDate", date)}

                                />

                                </div>
                                <div className={'no-print col-6'}>
                                    <span style={{minWidth: '10%'}}>To: </span><DatePicker
                                    className={'form-control'} name={'endDate'}
                                    dateFormat="dd/MM/yyyy"
                                    selectsEnd
                                    minDate={new Date(parseInt(startDate))}

                                    selected={new Date(parseInt(endDate))}
                                    onChange={(date) => this.handleDateChange("endDate", date)}
                                    onSelect={(date) => this.handleDateChange("endDate", date)}
                                />
                                </div>
                            </div>}
                            {this.props.startCreation && <button className={'btn no-print btn-sm btn-success text-light m-auto'}
                                                                 onClick={this.props.startCreation}>
                                <FontAwesomeIcon icon={'plus-circle'}/>
                            </button>}
                            {/* details toggle */}
                            {!this.state.noDetails && <Button onClick={this.toggleDetails} size={'sm'}
                                                              className={'m-auto no-print not-mobile'}>{this.state.details ? 'Hide details' : 'Show details'}</Button>}

                            {this.getCustomButtonPanel && this.getCustomButtonPanel()}
                            {
                                this.totalPages() > 0 &&
                                <PaginationControls
                                    firstPage={this.firstPage}
                                    pageDown={this.pageDown}
                                    pageUp={this.pageUp}
                                    lastPage={this.lastPage}
                                    page={this.state.page}
                                    totalPages={this.totalPages()}
                                    total={this.subFilter ? this.subFilter(sorted_data).length : sorted_data.length}
                                    grand_total={total}
                                    num_per_page={num_per_page}
                                />
                            }

                        </div>
                        {this.state.advanced_filters && this.getAdvancedFilters()}
                        {/* END HEADER */}
                        <div className={'inner-content-products w-100 row m-0'}>
                            {/* Report heading */}
                            <h3 className={'print-only w-100'}>{this.getReportTitle()}</h3>
                            <br/>
                            <p className={'small ml-3 print-only'}>Created
                                by {this.props.firebase.auth.currentUser.email}</p>
                            <hr className={'w-100 bg-primary'}/>
                            {!this.state.hide_date &&
                            <h5 className={'w-100 print-only mb-1 text-center'}>From {new Date(parseInt(startDate)).toLocaleDateString()} to {new Date(parseInt(endDate)).toLocaleDateString()}</h5>}
                            {/* table headers */}


                            <div id='inner-content-table' className={'row m-auto'}
                                 ref={ref => this.inner = ref}>
                                <Table size={'sm'}>
                                    <thead>
                                    <TableHeaders headers={this.getHeaders()}
                                                  setSort={this.setSort}
                                                  sort={this.state.sort}
                                                  reverse={this.state.reverse}
                                    />
                                    </thead>
                                    <tbody>
                                    {this.getContent()}
                                    </tbody>
                                </Table>
                                {(this.subFilter ? this.subFilter(sorted_data).length : sorted_data.length) === 0 &&
                                <div className={'text-center col-12 bg-light pb-3'}>
                                    {total > 0 ? `No matching ${this.state.key.replace(/_/g, ' ')} found!` : `No ${this.state.key.replace(/_/g, ' ')} yet!`}
                                    <br/>
                                    <FontAwesomeIcon icon={'exclamation-triangle'} size={'4x'}/>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {this.state.deleting && this.getDeletionModal()}
                    {this.state.editing && this.getEditingModal()}

                </div>
            </div>)
    }

}

class BayQuantitySelector extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange() {
        this.props.onChange(this.props.current_bay, this.props.pkey, this.bay.value, this.qty.value)
    }

    filterBays(bays) {
        let used_bays = [];
        if (this.props.expected) {
            Object.keys(this.props.expected).map(pkey => {
                used_bays.push(...Object.keys(this.props.expected[pkey].crates || {}));

                return true;
            })

        }
        if (!bays) return [];
        return Object.keys(bays).filter((bkey) => {
            return (!bays[bkey].product || bays[bkey].product === this.props.pkey) && (used_bays.indexOf(bkey) === -1 || bkey === this.props.current_bay)
        });
    }

    getBayQtys(bay) {
        const adjustments = this.props.adjustments;
        const bay_qtys = [
            <option key={this.props.current_bay + "_none"} value={0}>0</option>
        ];
        if(bay) {
            let available = (bay.current || 0) - ((bay.promised || 0) + bay.stocking_out || 0);
            if(adjustments && adjustments[bay.id]) {
                available+=adjustments[bay.id].adjustment;
            }
            for (let i = 1; i <= parseInt(available || 0); i++) {
                bay_qtys.push(<option key={this.props.current_bay + "_" + i} value={i}>{i}</option>)
            }
        }
        return bay_qtys;
    }

    render() {
        const {bays, pkey, current_bay, qty, selector} = this.props;

        return <div className={'col-12 col-md-6 row'}>
            <div className={'col-6'}>
                Bay:
                <FormControl onChange={this.onChange} ref={ref => this.bay = ref} as="select" value={current_bay}>
                    <option value={''}>-</option>
                    {
                        this.filterBays(bays).map((bkey) => <option
                            key={`${pkey}_${bkey}`}
                            value={bkey}>{bays[bkey].code}</option>)
                    }
                </FormControl>
            </div>
            {selector && current_bay && <div className={'col-6'}>
                Qty: <select value={qty} onChange={this.onChange} ref={ref => this.qty = ref}
                             className={'form-control'}>
                {this.getBayQtys(bays[current_bay])}
            </select>
            </div>}
            {(!current_bay || !selector) && <div className={'col-6'}>
                Qty: <input value={qty} onChange={this.onChange} ref={ref => this.qty = ref}
                            className={'form-control'}/>
            </div>}

        </div>
    }
}

const ElectronicPickingList = ({customer, collections, completed, total, photos, requests, productView, is_bulk}) => {
    const collection_refs = Object.values(collections || {}).map(c => c.collection_ref);

    return <div>
        <h1 className={'mt-0 w-100 text-center'}>Electronic Collection</h1>
        <ElectronicPickingListTable {...{customer, collections, completed, total, collection_refs}}/>
        {!productView && <PickingListCollectionView collections={collections} requests={requests} is_bulk={is_bulk}/>}
        {productView && <PickingListProductView collections={collections} requests={requests}/>}
        {photos && <h3>Photos</h3>}
        {photos && photos.map((photo) => {
            return <div className={'col-12'}><Image className={'img img-fluid m-1'} src={photo}/></div>
        })}
        <table className={'table table-sm table-bordered print-footer'}>
            <tbody>
        <tr>
            <td className={'bg-secondary text-center'}>Loaded by</td>
            <td className={'w-25 text-center'}>&nbsp;</td>
            <td className={'bg-secondary text-center'}>Checked by</td>
            <td className={'w-25 text-center'}>&nbsp;</td>
        </tr>
            <tr>
                <td className={'bg-secondary text-center'}>Haulier Crate Qty agreed*:</td>
                <td className={'w-25 text-center'}><span className={'small'} style={{verticalAlign: 'super'}}>Sign</span>&nbsp;</td>
                <td className={'w-25 text-center'}><span className={'small'} style={{verticalAlign: 'super'}}>Print</span>&nbsp;</td>

                <td className={'w-25 text-center'}><h1>Crate Total: {total}</h1></td>
            </tr>
        <tr><td style={{border: 'none !important'}} colSpan={4}>* Hauliers please ensure you check the crate quantity of your load, you are signing for the stated amount, once signed and lorry left the compound you have taken responsibility of the exact quantity of crates.</td></tr>
            </tbody>
        </table>
    </div>
};
const ElectronicPickingListTable = ({customer, collections, collection_refs}) => {
    return <ContainerMasterDataContext.Consumer>
        {
            ({gate_passes}) => {
                const gate_pass = gate_passes && Object.values(collections).length > 0 ? gate_passes[Object.values(collections)[0].pass_id] : null;
                return <table id={"ec-table1"} className={'table-bordered table table-sm text-center'}>
                    <tbody>
                    <tr>
                        <td className={'w-50 bg-secondary text-center'}>Customer</td>
                        <td className={'w-50 text-center'}>{customer.code}</td>
                    </tr>
                    <tr>
                        <td className={'bg-secondary text-center'}>Collection Ref{collection_refs.length > 1 ? 's' : ''}.
                        </td>
                        <td className={'w-50 text-center'}>{collection_refs.join(",")}</td>
                    </tr>

                    {gate_pass && <tr>
                        <td className={'bg-secondary text-center'}>Collection Date</td>
                        <td className={'w-50 text-center'}>

                            <span>{new Date(parseInt(gate_pass.issued_on)).toLocaleString()}</span>

                        </td>
                    </tr>}
                    {gate_pass && <tr>
                        <td className={'bg-secondary text-center'}>Pass Number</td>
                        <td className={'w-50 text-center'}>
                            {gate_pass && <span>{gate_pass.pass_number}</span>}

                        </td>
                    </tr>}
                    </tbody>
                </table>;
            }
        }

    </ContainerMasterDataContext.Consumer>
};

const PickingListCollectionView = ({collections, requests, is_bulk}) => {
    const getTotalForBays = (bays) => {
        return Object.values(bays || {}).reduce((a, b) => a + parseInt(b.qty), 0);
    };

    return Object.keys(collections||{}).map(c => {

        return <ContainerMasterDataContext.Consumer key={`picking_view_${c}`}>
            {
                (data) => {
                    const products = collections[c].products.actual;

                    const all_products = data.products;
                    const bays = data.bays;
                    return <table key={`print_table_product_${c}`}
                                  className={'text-center table table-sm table-bordered mb-1 print-body'}>
                        {Object.keys(collections ||{}).length > 1 && <tr>
                            <td colSpan={8}><Badge style={{fontSize: '1.3em'}}>{collections[c].collection_ref}</Badge>
                            </td>
                        </tr>}

                        {Object.keys(products || {}).map((product) => {
                            const num_bays = Object.keys(products[product].crates || {}).length;
                            let filtered_bays = Object.keys(bays).filter((bkey) => {
                                return (bays[bkey].product === product) || (collections[c].verified)
                            });
                            if (Object.keys(products[product].crates || {}).length > 0) {
                                filtered_bays = filtered_bays.filter(b => {
                                    return Object.keys(products[product].crates).indexOf(b.id) === -1
                                })
                            }

                            if (num_bays > 0 && (getTotalForBays(products[product].crates) >= requests[c].products.expected.find(p=>p.code===product) ? requests[c].products.expected.find(p=>p.code===product).crates  :-1)) {
                                filtered_bays = filtered_bays.filter(b => (!!products[product].crates) && (!!products[product].crates[b]))
                            }
                            if (!is_bulk)
                                return <tbody key={'picking_list_product_'+product}>
                                <tr className={'text-center'}>

                                    <td colSpan={5} className={'w-50 emphasize text-left bg-secondary'}>{Object.values(all_products || {}).find(ap => {
                                        return ap.code === product && ap.customer === collections[c].customer
                                    }).description}</td>




                                    <td style={{width: "7%"}} className={'emphasize text-center bg-secondary'}>{num_bays > 0 ? getTotalForBays(products[product].crates || {}):  requests[c].products.expected.find(p=>p.code===product) ? requests[c].products.expected.find(p=>p.code===product).crates : 0}</td>

                                </tr>
                                <tr className={'bay-row'}>
                                    {filtered_bays.sort((a, b) => {
                                        return a.last_change - b.last_change
                                    }).slice(0, 3).map((b) => {
                                        const bay = bays[b];
                                        return [<td style={{width: "7%", fontSize: '1.5em !important'}} key={`${bay.id}_printed_box_label`}
                                                    className={'bg-secondary'}> <span className={'smaller-span'} style={{fontSize: '0.7em !important'}}>{bay.code}</span></td>,
                                            <td style={{width: "7%"}} key={`${bay.id}_printed_box_entry`}>
                                                {products[product].crates && products[product].crates[b] &&
                                                <span  className={'smaller-span'} style={{fontSize: '0.7em !important'}}>{products[product].crates[b].qty}</span>}
                                            </td>]
                                    })
                                    }
                                    {filtered_bays.length < 2 && [<td style={{width: "7%", fontSize: '1.5em !important'}}
                                                                      key={`1dummy_printed_box_label`}
                                                                      className={'bg-secondary'}>&nbsp;</td>,
                                        <td style={{width: "7%", fontSize: '1.5em !important'}} key={`1dummy_printed_box_entry`}>&nbsp;</td>]}
                                    {filtered_bays.length < 3 && [<td style={{width: "7%", fontSize: '1.5em !important'}}
                                                                      key={`2dummy_printed_box_label`}
                                                                      className={'bg-secondary'}>&nbsp;</td>,
                                        <td style={{width: "7%", fontSize: '1.5em !important'}} key={`2dummy_printed_box_entry`}>&nbsp;</td>]}
                                </tr>
                                </tbody>
                            return <tbody key={'picking_list_product_'+product}>
                            <tr className={'text-center'}>

                                <td className={'w-50 emphasize text-left'}>{Object.values(all_products || {}).find(ap => {
                                    return ap.code === product && ap.customer === collections[c].customer
                                }).description}</td>

                                            {filtered_bays.sort((a, b) => {
                                                return a.last_change - b.last_change
                                            }).slice(0, 3).map((b) => {
                                                const bay = bays[b];
                                                return [<td style={{width: "7%"}} key={`${bay.id}_printed_box_label`}
                                                            className={'bg-secondary'}>{bay.code}</td>,
                                                    <td style={{width: "7%"}} key={`${bay.id}_printed_box_entry`}>
                                                        {products[product].crates && products[product].crates[b] &&
                                                        <span>{products[product].crates[b].qty}</span>}
                                                    </td>]
                                            })
                                            }
                                            {filtered_bays.length < 2 && [<td style={{width: "7%"}}
                                                                              key={`1dummy_printed_box_label`}
                                                                              className={'bg-secondary'}>&nbsp;</td>,
                                                <td style={{width: "7%"}} key={`1dummy_printed_box_entry`}>&nbsp;</td>]}
                                            {filtered_bays.length < 3 && [<td style={{width: "7%"}}
                                                                              key={`2dummy_printed_box_label`}
                                                                              className={'bg-secondary'}>&nbsp;</td>,
                                                <td style={{width: "7%"}} key={`2dummy_printed_box_entry`}>&nbsp;</td>]}


                                <td style={{width: "7%"}} className={'emphasize text-center'}>{num_bays > 0 ? getTotalForBays(products[product].crates || {}):  requests[c].products.expected.find(p=>p.code===product) ? requests[c].products.expected.find(p=>p.code===product).crates : 0}</td>

                            </tr>
                            </tbody>
                        })}
                    </table>
                }

            }

        </ContainerMasterDataContext.Consumer>


    });
};

const PickingListProductView = ({collections, requests}) => {
    const getTotalForBays = (bays) => {
        return Object.values(bays || {}).reduce((a, b) => a + parseInt(b.qty), 0);
    };

    const products = {};
    Object.values(collections).map(c => {
        const list_products = c.products.actual ? c.products.actual : c.products.expected;
        Object.keys(list_products).map((p)=>{
            if(!products[p]) {
                products[p] = {collections:{},
                customer: c.customer, total: 0}
            }
            products[p].collections[c.id] = {crates:list_products[p].crates, collection_ref: c.collection_ref};
            const qty = getTotalForBays(list_products[p].crates || {});
            products[p].total+= qty > 0 ? qty : list_products[p].crates;
            return true;
        });
        return true;
    });
    return Object.keys(products).map(p => {

        return <ContainerMasterDataContext.Consumer>
            {
                (data) => {
                    const {collections, customer} = products[p];

                    const all_products = data.products;
                    const bays = data.bays;
                    return <table key={`print_table_product_${p}`}
                                  className={'text-center table table-sm table-bordered mb-1'}>
                         <tr>
                            <td className={'emphasize text-left'} colSpan={2}>
                                {Object.values(all_products).find(ap => ap.code === p && ap.customer === customer).description}
                            </td>
                             <td style={{maxWidth: '100px'}}>
                                 Total: {products[p].total}
                             </td>
                        </tr>

                        {Object.keys(collections).map((c) => {
                            const num_bays = Object.keys(collections[c].crates || {}).length;
                            let filtered_bays = Object.keys(bays).filter((bkey) => {
                                return bays[bkey].product === p
                            });
                            if (Object.keys(collections[c].crates || {}).length > 0) {
                                filtered_bays = filtered_bays.filter(b => {
                                    return Object.keys(collections[c].crates).indexOf(b.id) === -1
                                })
                            }

                            if (num_bays > 0 && (getTotalForBays(collections[c].crates) >=products[p].crates)) {
                                filtered_bays = filtered_bays.filter(b => (!!collections[c].crates) && (!!collections[c].crates[b]))
                            }

                            return <tbody>
                            <tr>
                                <td className={'w-25 text-left'}>{p}</td>
                                <td className={'emphasize text-left'}>{collections[c].collection_ref}</td>
                                <td className={'emphasize text-center'}>{num_bays > 0 ? getTotalForBays(collections[c].crates):  requests[c].products.find(prod=>prod.code===p).crates}</td>
                            </tr>
                            <tr>
                                <td colSpan={4} className={'p-0'}>
                                    <table className={'table table-sm m-0 no-border'}>
                                        <tbody>
                                        <tr>
                                            {filtered_bays.sort((a, b) => {
                                                return a.total - b.total
                                            }).slice(0, 4).map((b) => {
                                                const bay = bays[b];
                                                return [<td style={{width: "8%"}} key={`${bay.id}_printed_box_label`}
                                                            className={'bg-secondary'}>{bay.code}</td>,
                                                    <td style={{width: "8%"}} key={`${bay.id}_printed_box_entry`}>
                                                        {collections[c].crates && collections[c].crates[b] &&
                                                        <span>{collections[c].crates[b].qty}</span>}
                                                    </td>]
                                            })
                                            }
                                            {filtered_bays.length < 2 && [<td style={{width: "8%"}}
                                                                              key={`1dummy_printed_box_label`}
                                                                              className={'bg-secondary'}>&nbsp;</td>,
                                                <td style={{width: "8%"}} key={`1dummy_printed_box_entry`}>&nbsp;</td>]}
                                            {filtered_bays.length < 3 && [<td style={{width: "8%"}}
                                                                              key={`2dummy_printed_box_label`}
                                                                              className={'bg-secondary'}>&nbsp;</td>,
                                                <td style={{width: "8%"}} key={`2dummy_printed_box_entry`}>&nbsp;</td>]}
                                            {filtered_bays.length < 4 && [<td style={{width: "8%"}}
                                                                              key={`3dummy_printed_box_label`}
                                                                              className={'bg-secondary'}>&nbsp;</td>,
                                                <td style={{width: "8%"}} key={`3dummy_printed_box_entry`}>&nbsp;</td>]}
                                            <td className={'w-25 text-right'}>Checked</td>
                                            <td style={{width: '5%'}}>&nbsp;</td>

                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        })}
                    </table>
                }

            }

        </ContainerMasterDataContext.Consumer>


    });
};

export {RowCell, DateSortableContent, TableButtons, BayQuantitySelector, ElectronicPickingList, PaginationControls, TableHeaders, PageSizeControls};