import React from "react";
import {Route, Switch} from "react-router-dom";

import * as PropTypes from "prop-types";

import * as ROUTES from '../../constants/routes';
import Landing from "../../pages/Landing";
import Dashboard from "../../pages/authenticatedPages/Dashboard";
import Profile from "../../pages/authenticatedPages/Profile";
import Register from "../../pages/Register";

import ErrorMessage from "../../elements/ErrorMessage";

import AuthUserContext from "../Session/context";
import InviteListener from "../InviteManager";
import OrganisationManager from "../OrganisationManager";
import {withAuthentication} from "../Session/";
import Navigation from "../Navigation/Navigation";

import withData from "./withData";
import withCollections from "./withCollections";
import withOutturns from "./withOutturns";
import withForecast from "./withForecast";
import withGatePasses from "./withGatePasses";
import CustomerAccept from "../../pages/CustomerAccept";
import CustomerApp from "../CustomerApp";

const ContainerMasterDataContext = React.createContext(
    {}
);
const ContainerMasterCollectionsContext = React.createContext(
    {
        requests: {},
        collections: {}
    }
);
const ContainerMasterOutturnsContext = React.createContext(
    {
        requests: {},
        outturns: {}
    }
);
const ContainerMasterForecastContext = React.createContext(
    {
        forecast: {}
    }
);
const ContainerMasterArchiveContext = React.createContext(
    {
      completed: {}
    }
);
const ContainerMasterGatePassContext = React.createContext(
    {
        devan_gate_passes: {},
        gate_passes: {}
    }
);

class ContainerMaster extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {firebase, uid, default_organisation, email, displayName, loading, role, organisation, organisation_id} = this.props;

        let WithData = Dashboard;
        if(organisation_id) {
            WithData = withData(withCollections(withOutturns(withForecast(withGatePasses(Dashboard)))));
        }
        if(role === "customer") {
            WithData = CustomerApp;
        }
      const {PROFILE, PASSWORD_FORGET, CUSTOMER_ACCEPT, SIGN_UP, HOME} = ROUTES;
      if(uid) {
            return <div>
                <Navigation uid={uid}/>
                <div style={{width: '20%',position: 'fixed', top: '0', right: '0', zIndex: 1030}} >


                    <OrganisationManager role={role} uid={uid} changeOrganisation={this.props.changeOrganisation} organisation={organisation} firebase={firebase} default_organisation={default_organisation} organisation_id={organisation_id}/>
                    <InviteListener firebase={firebase} uid={uid}/>


                </div>
                <Switch>
                 <Route exact path={HOME} component={() => <WithData role={role} displayName={displayName} loading={loading} firebase={firebase} organisation={organisation} organisation_id={organisation_id} default_organisation={default_organisation} uid={uid}/>}/>
                    <Route exact path={PROFILE} component={() => <Profile uid={uid} email={email} displayName={displayName}/>}/>
                    <Route exact path={CUSTOMER_ACCEPT} component={() => <CustomerAccept uid={uid} email={email} displayName={displayName} { ...this.props }/>}/>
                    <Route component={() => <ErrorMessage message={"Page not found"}
                                                          details={"The page you are looking for could not be found."}/>}/>
                </Switch>
            </div>
        } else
            {
            return <div>
                <Navigation />
                {!loading && <Switch>
                    <Route exact path={PASSWORD_FORGET}/>
                    <Route exact path={SIGN_UP} component={Register}/>
                    <Route exact path={CUSTOMER_ACCEPT} component={() => <CustomerAccept { ...this.props }/>}/>
                    <Route exact path={HOME} component={() => <Landing {...this.props}/>}/>
                    <Route component={() =>  <Landing {...this.props}/>}/>}/>
                </Switch>}
            </div>
        }
    }
}
ContainerMaster.propTypes = {
  firebase: PropTypes.object.isRequired
};

const withContainerMaster = Component => props => <AuthUserContext.Consumer>
    {
        (context) => {
            const {default_organisation} = context.settings || {};

            return <Component {...context} {...props} default_organisation={default_organisation}/>
        }
    }
</AuthUserContext.Consumer>;


export {ContainerMaster, ContainerMasterDataContext, ContainerMasterCollectionsContext, ContainerMasterOutturnsContext,
ContainerMasterForecastContext, ContainerMasterGatePassContext, ContainerMasterArchiveContext};
export default withAuthentication(withContainerMaster(ContainerMaster))
