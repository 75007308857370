import React from "react";
import * as PropTypes from 'prop-types';
import {Button, ButtonGroup} from "react-bootstrap";

import {RowCell} from "../../../../elements/Common";
import SearchableTable from "../../../../elements/SearchableTable";
import {REQUEST_STATUS_LABELS} from "../../../../components/ContainerMaster/withCollections";

import {ContainerMasterCollectionsContext} from "../../../../components/ContainerMaster/ContainerMaster";
import DATABASE_REFS from "../../../../constants/db_refs";
import {toast} from "react-toastify";
import {toastConfig} from "../../../../config/toast_config";
import {AdvancedFilterPanel, AdvancedFilter} from "../../../../elements/AdvancedFiltering";

const CollectionRow = ({collection, headers, printing}) => <tr className={'col-12 w-100 m-1 product item-row mb-1 print-block'}>
    {headers.map((h, i) =>  <RowCell  key={`table_row_${collection.id}_${h.key}`}  col={3} title={h.label} alignment={'center'}
                                      header={!printing && i===0} noprint={h.noprint}>{h.content(collection)}</RowCell>)}
</tr>;


class Table extends SearchableTable {
    has_export = 'Export Collections';
    // noinspection JSUnusedGlobalSymbols
    CMContext = ContainerMasterCollectionsContext;
    key = "requests";

    constructor(props) {
        super(props);
        this.has_export = 'Export Collections';
        this.state = {
            ...this.initialState,
            page: 0,
            num_per_page: 10,
            noDetails: true
        };
    }

    getReportTitle() {
        return 'Collection Requests';
    }

    setFilter(filter, callback) {
        const {status_filters} = this.state;
        this.props.setFilter(filter.target.value, status_filters, [], callback)
    }

    cancelCollection(id) {
        this.props.firebase.db.ref(DATABASE_REFS.organisations.request.collection(this.props.organisation_id)).child(id).child("cancelled").set(true).then(()=>{
            toast.error("Request Cancelled", {...toastConfig});
        })
    }
    reopenCollection(id) {
        this.props.firebase.db.ref(DATABASE_REFS.organisations.request.collection(this.props.organisation_id)).child(id).child("cancelled").set(null).then(()=>{
            toast.success("Request Reopened", {...toastConfig});
        })
    }
    deleteRequest(id) {
        this.props.firebase.db.ref(DATABASE_REFS.organisations.request.collection(this.props.organisation_id)).child(id).set(null).then(()=>{
            toast.error("Request Deleted", {...toastConfig});
        })
    }

    getAdditionalHeaders() {
        return [ {
            label: '',
            key: false,
            size: 2,
            noprint: true,
            content: (product) => {
                if(product.status || product.locked) {
                    return  <ButtonGroup><Button size={'sm'} variant={'success'}  onClick={() => {this.props.editDevan(product.id)}}>View</Button></ButtonGroup>
                }
                return <ButtonGroup>
                    {!product.cancelled && <Button
                        onClick={() => {this.props.editDevan(product.id)}}
                        key={`edit_button_${product.parent}_${product.id}`}
                        title={'Edit?'}
                        data-item={product} variant={'primary'} size={'sm'}>Edit</Button>
                    }
                    {!product.cancelled &&
                    <Button
                        onClick={() => {this.cancelCollection(product.id)}}
                        key={`delete_button_${product.parent}_${product.id}`}
                        title={'Cancel?'}  data-item={product} variant={'danger'} size={'sm'}>
                        Cancel
                    </Button>
                    }
                    {
                        product.cancelled && <Button
                            onClick={() => {this.reopenCollection(product.id)}}
                            key={`reopen_button_${product.parent}_${product.id}`} title={'Reopen?'}
                            data-item={product} variant={'success'} size={'sm'}>
                            Reopen
                        </Button>
                    }
                    {
                        product.cancelled && !product.verified && <Button
                            onClick={() => {this.deleteRequest(product.id)}}
                            key={`delete_button_${product.parent}_${product.id}`} title={'Reopen?'}
                            data-item={product} variant={'danger'} size={'sm'}>
                            Delete
                        </Button>
                    }
                </ButtonGroup>
            }
        }]
    }

    getAdvancedFilters({updateStatusLabels, status_filters, selectAll, selectNone}) {
        console.log("jkdnflkd")
        return<AdvancedFilterPanel><AdvancedFilter
            legend_text={'Collection Status'}
            onClick={updateStatusLabels}
            selectAll={selectAll}
            deselectAll={selectNone}
            original_list={REQUEST_STATUS_LABELS}
            current_list={status_filters}
        /></AdvancedFilterPanel>
    }


    getContent(data, headers) {
        return data.map(
            (collection) =><CollectionRow
                headers={headers}
                printing={this.state.print_processing}
                key={collection.id}
                collection={collection}
            />)
    }
}

Table.propTypes = {
    firebase: PropTypes.object.isRequired,
    organisation_id: PropTypes.string.isRequired,
    editCollection: PropTypes.func
};
export {CollectionRow};
export default Table;