// external
import * as React from "react";
import ReactToPrint from 'react-to-print';
import {Button, ButtonGroup, Dropdown, DropdownButton, FormControl, Image, Modal, ModalBody} from "react-bootstrap";
import {toast} from "react-toastify";
import * as PropTypes from 'prop-types';
// Images
import products from '../../../icons/products.png';
import collection from '../../../icons/collections.png';
// Constants
import db_refs from '../../../constants/db_refs';
import storage_refs from '../../../constants/storage_refs';
import {toastConfig} from "../../../config/toast_config"
//Components
import StepProgressBar from "../../../elements/StepProgressBar";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Table from "react-bootstrap/Table";
import Error from "../../../elements/Error";
import {ElectronicPickingList} from "../../../elements/Common";
import {BayQuantitySelector} from "../../../elements/BayQuantitySelector";
import GatePassForm from "../GatePassIssuer/GatePassForm";
import {ContainerMasterCollectionsContext} from "../../../components/ContainerMaster/ContainerMaster";
import PrintedGatePass from "../GatePassIssuer/PrintedGatePass";

const PRESETS = [
    'Missing Crate(s)',
    'Unexpected Crate(s)',
    'Wrong Product',
    'Other issue'
];
function titleCase(str) {
    let sentence = str.toLowerCase().split(" ");
    for(let i = 0; i< sentence.length; i++){
        sentence[i] =  sentence[i].length === 0 ? '' : sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
}
class Wizard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: false,
            collection_ref: false,
            products: [],
            expected:{},
            loading: true,
            container_checked: false,
            revision: 0,
            newProduct: 0,
            skipping: false,
            collection_id: props.collection_id,
            crate_view: false,
            allowPresets: false,
            issuing: null,
            photos: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.togglePickingList = this.togglePickingList.bind(this);
        this.processData = this.processData.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.addPresetReason = this.addPresetReason.bind(this);
        this.printCollection = this.printCollection.bind(this);
        this.finishPrinting = this.finishPrinting.bind(this);
        this.toggleCrateView = this.toggleCrateView.bind(this);
        this.togglePresets = this.togglePresets.bind(this);
        this.compareCollection = this.compareCollection.bind(this);
        this.uploadPhoto = this.uploadPhoto.bind(this);
        this.updateBays = this.updateBays.bind(this);
        this.saveAndFinishVerifyContents = this.saveAndFinishVerifyContents.bind(this);
        this.saveVerifyContents = this.saveVerifyContents.bind(this);
        this.revertContents = this.revertContents.bind(this);
        this.authoriseStockOut = this.authoriseStockOut.bind(this);
    }

    toggleCrateView(event) {
        this.setState({crate_view: event.target.checked})
    }
    togglePresets(event) {
        this.setState({allowPresets: event.target.checked})
    }

    addProduct() {
        const {actual: products, newProduct, expected_products, current: value} = this.state;
        const {existing_products} = this.props;
        let description = '';

        console.log(value.code);
        console.log(existing_products);
        Object.values(existing_products).map((p) => {

            if (p.code === value.code) {
               description = p.description;
           }
           return true;
        });
        expected_products[value.code] = {
            description: value.description,
            new: true,
            crates: []
        };
        products[value.code] = {
            total_crates: 0,
            crates: {},
            reason: ''
        };
        this.setState({newProduct: newProduct + 1, actual: products, expected_products})
    }

    getCustomer() {
        const {customer} = this.state;
        return this._getCustomer(customer);
    }

    _getCustomer(customer) {
        const {customers} = this.props;
        for (let c in customers) {
            // noinspection JSUnfilteredForInLoop
            if (customer === customers[c].id) {
                // noinspection JSUnfilteredForInLoop
                return customers[c];
            }
        }
    }

    fetchRequest(request_id) {
        this.endRequest();
        const {firebase, organisation_id} = this.props;
        const stockOutRef = db_refs.organisations.request.collection(organisation_id);
        const ref = firebase.db.ref(stockOutRef).child(request_id);
        this.setState({collection_id: request_id, current_ref: ref});
        ref.on('value', this.processData);
    }

    endRequest() {
        if(this.state.current_ref) {
            this.state.current_ref.off('value', this.processData);
        }
    }

    processData(snapshot) {
        const {bays, existing_products} = this.props;
        if (snapshot.val()) {
                const revision = Object.keys(snapshot.val());
                const {collection_ref, products, customer, customer_name, recipient, recipient_ref, expected} = snapshot.val();
                const formatted_products = {};
                products.map((product) => {
                    if (!formatted_products[product.code]) {
                        formatted_products[product.code] = {
                            description: product.description,
                            crates: product.crates
                        };
                    } else {
                        formatted_products[product.code].crates = product.crates
                    }
                    return true;
                });
                const current_collection_ref = this.props.firebase.db.ref(db_refs.organisations.stock_movement.stock_out(this.props.organisation_id)).child(this.props.collection_id);

                current_collection_ref.once('value').then((collection_snapshot) => {
                    if(!collection_snapshot.val()) {
                        const collection_products = {};
                        Object.keys(formatted_products).map((pkey) => {
                            const defaultBay = Object.values(existing_products).find(p=>p.code===pkey).default_collection_bay;
                            let valid_bays = Object.values(bays).filter(b=>(b.product===pkey && b.current - (b.promised+b.stocking_out) > 0));
                            const validDefault = valid_bays.find(b=>b.id === defaultBay)
                            if(defaultBay) {
                                if (validDefault) {
                                    valid_bays = valid_bays.filter(b => b.id !== defaultBay)
                                } else {
                                    toast.error(`The default for product ${pkey} is no longer valid, please update it on the product page`)
                                }
                            }
                            const sortBy = [
                                {
                                prop:'last_active',
                                direction: 1
                            }];
                            let sorted_bays = valid_bays.sort((a,b) => {
                                let i = 0, result = 0;
                                while(i < sortBy.length && result === 0) {
                                    let a_val = a[ sortBy[i].prop ];
                                    let b_val = b[ sortBy[i].prop ];
                                    if(sortBy[i].string) {
                                        result = sortBy[i].direction*(a_val < b_val ? -1 : (a_val > b_val ? 1 : 0));
                                    } else {
                                        a_val = parseInt(a_val);
                                        b_val = parseInt(b_val);
                                        result = sortBy[i].direction*(a_val - b_val);
                                    }
                                    i++;
                                }
                                return result;
                            }).reverse();
                            if(validDefault) {
                                sorted_bays = [validDefault, ...sorted_bays]
                            }

                            let allocated = 0;
                            const crates = {};
                            while(allocated < formatted_products[pkey].crates) {
                                const bay = sorted_bays.shift();
                                if(!bay) {
                                    break
                                }
                                crates[bay.id] = {
                                    code: bay.code,
                                    qty: Math.min(formatted_products[pkey].crates - allocated, (bay.current - (bay.promised+bay.stocking_out)))
                                };
                                allocated +=  Math.min(formatted_products[pkey].crates - allocated, (bay.current - (bay.promised+bay.stocking_out)));
                            }

                            collection_products[pkey] = {
                                crates: crates,
                                reason: ''
                            };
                            return true;
                        });
                        const collection = {
                            expected,
                            collection_ref,
                            products: collection_products,
                            customer,
                            recipient: recipient ? recipient : '',
                            recipient_ref: recipient_ref ? recipient_ref : ''
                        };
                        current_collection_ref.set(collection).then(() => {
                            this.props.firebase.db.ref(db_refs.organisations.request.collection(this.props.organisation_id)).child(this.props.collection_id).child('started').set(true).then(() => {});
                            toast.success(`Started collection`, {...toastConfig, autoClose: 2000});
                        });

                    }
                    this.setState({
                        customer: customer,
                        customer_name: customer_name,
                        revision: revision,
                        collection_ref: collection_ref,
                        expected_products: formatted_products
                    }, () => {
                        this.startListening(current_collection_ref);
                    });
                    this.getPickingListUrl();

                    return true;
                });
            }
    }
    startListening(current_collection_ref) {
        const that = this;
        current_collection_ref.child('products').on('value', this.compareCollection);
        current_collection_ref.child('photos').on('value', (snapshot)=>{
            if(snapshot.val()) {
                that.setState({photos: Object.values(snapshot.val())})
            }
        });

        current_collection_ref.child('notified').on('value', (snapshot)=>{
            that.setState({notified: snapshot.val()})
        });
        current_collection_ref.child('completed').on('value', (snapshot)=>{
            that.setState({completed: snapshot.val()})
        });
        current_collection_ref.child('verified').on('value', (snapshot)=>{
            that.setState({verified: snapshot.val()})
        });
       current_collection_ref.child('error').on('value', (snapshot)=>{
           if(that.error)
            that.error.setError(snapshot.val() ?  { message: snapshot.val()} : false);
        });
        current_collection_ref.child('processed_on').on('value', (snapshot)=>{
            that.setState({processed_on: snapshot.val()})
        });
        current_collection_ref.child('processed_by').on('value', (snapshot)=>{
            that.setState({processed_by: snapshot.val()})
        });
        current_collection_ref.child('pass_id').on('value', (snapshot)=>{
            that.setState({pass_id: snapshot.val()})
            const gate_pass_ref = db_refs.organisations.gate_passes(that.props.organisation_id);
            if (snapshot.val()) {
                that.props.firebase.db.ref(gate_pass_ref).child(snapshot.val()).once('value').then(pass=>{
                    const thePass = pass.val();
                    Object.keys(thePass.picking_lists || {}).map(pl=>{
                        if (that.props.picking_lists[pl]) {
                            thePass.picking_lists[pl] = that.props.picking_lists[pl];
                        }
                    })
                    that.setState({
                        fullPass: thePass
                    })
                })
            }
        });
        current_collection_ref.child('provisional_pass_id').on('value', (snapshot)=>{
            that.setState({provisional_pass_id: snapshot.val()})
        });
        current_collection_ref.child('picking_list_id').on('value', (snapshot)=>{
            if(!snapshot.val()) return;
            const newState = {picking_list_id: snapshot.val()};
            if(that.props.picking_lists[snapshot.val()]) {
                let plist = that.props.picking_lists[snapshot.val()];
                newState.pass_id = plist.pass_id;
                newState.provisional_pass_id = plist.provisional_pass_id;
            }
            if(!this.props.picking_lists[snapshot.val()]) {
                this.props.firebase.db.ref(db_refs.organisations.bulk.picking(this.props.organisation_id)).child(snapshot.val()).once("value").then(bp_snap => {
                    const bp = bp_snap.val();
                    that.setState({actual_bp: bp});
                })
            }

            that.setState(newState);
            this.props.firebase.db.ref(db_refs.organisations.bulk.picking(this.props.organisation_id)).child(snapshot.val()).child("pass_id").on('value', (snapshot)=>{
                that.setState({pass_id: snapshot.val()})
            });
            this.props.firebase.db.ref(db_refs.organisations.bulk.picking(this.props.organisation_id)).child(snapshot.val()).child("provisional_pass_id").on('value', (snapshot)=>{
                that.setState({provisional_pass_id: snapshot.val()})
            });
        });

    }
    uploadPhoto(event) {
        const {firebase, organisation_id, collection_id} = this.props;
        const file = event.target.files[0];
        const {pickup_image} = storage_refs;
        const fileRef = firebase.storage.ref(pickup_image(organisation_id, collection_id, file.name));
        const uploadTask = fileRef.put(file);

        uploadTask.on('state_changed', function(){
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        }, function(error) {
            // Handle unsuccessful uploads
            console.error(error)
        }, function() {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                firebase.db.ref(db_refs.organisations.stock_movement.stock_out(organisation_id)).child(collection_id).child('photos').push(downloadURL).then(() => {
                    toast.success(`Uploaded photo: ${file.name}`, {...toastConfig, autoClose: 2000});
                })

            });
        });

    }

    async compareCollection(collection_snapshot) {
        const outturn =  collection_snapshot.val() || {};
        Object.keys(this.state.expected_products || {}).map((pkey) => {
            if(!outturn[pkey]) {
                outturn[pkey] = {
                    total_crates: 0,
                    crates: {},
                    reason: ''
                }
            }
            if(!outturn[pkey].crates) {
                outturn[pkey].crates = {}
            }
            return true;
        });
        const bays = [];
        const newProducts = this.state.expected_products || {};
        Object.keys(outturn).map((pkey) => {
            bays.push(...Object.keys(outturn[pkey].crates));
            if(!newProducts[pkey]) {
                newProducts[pkey] = {
                    crates: 0,
                    description: outturn[pkey].description
                }
            }
            return true;
        });
        // Check and set any  bay totals
        const bay_adjustments = bays.map((c) =>{
                return this.getBayAdjustments(c)
            });
        const res = await Promise.all(bay_adjustments);
        const adjustments = {};
        res.map(r=> {
            adjustments[r.bay] =  r
        });
        this.setState({
            loading: false,
            actual: outturn,
            expected_products: newProducts,
            adjustments: adjustments

        })
    }

    async getBayAdjustments(bay_id) {
        const {collection_id, organisation_id, firebase} = this.props;
        const completed_record_snap = await firebase.db.ref(db_refs.organisations.forecasting.completed(organisation_id, 'bays', bay_id)).child(collection_id).once('value');
        const pending_record_snap = await firebase.db.ref(db_refs.organisations.forecasting.pending(organisation_id, 'bays', bay_id)).child(collection_id).once('value');
        const completed_record = completed_record_snap.val();
        const pending_record = pending_record_snap.val();
        const adjustment = {
            bay: bay_id,
            adjustment: 0
        };
        if(completed_record) {
            adjustment.adjustment+=parseInt(completed_record.change);
        }
        if(pending_record) {
            adjustment.adjustment+=parseInt(pending_record.change);
        }
        return adjustment;
    }

    addPresetReason(event) {
        this._handleChange({
            target: {
                name: event.target.name,
                value: event.target.dataset.content
            }
        }, true)
    }

    getTotalAcrossBays(crates) {
        if(!crates) return 0;
        try {
            return Object.keys(crates).map((crate) => {
                return parseInt(crates[crate].qty)
            }).reduce((a, b) => a + b, 0)
        } catch (e) {
            return 0
        }
    }

    _handleChange(event, append) {
        let newState = {};
        const nameParts = event.target.name.split('/');
        const existing = this.state[nameParts[0]];
        if(!existing[nameParts[1]]) {
            existing[nameParts[1]] = {
                crates: 0,
                reason: ''
            };
        }
        if(append)
            existing[nameParts[1]][nameParts[2]] = existing[nameParts[1]][nameParts[2]].trim().length > 0 ? existing[nameParts[1]][nameParts[2]] +"\n"+event.target.value : event.target.value;
        else
            existing[nameParts[1]][nameParts[2]] = titleCase(event.target.value);
        newState[nameParts[0]] = existing;
        this.setState(newState)
    }

    handleChange(event) {
        this._handleChange(event, false);
    }

    printCollection() {
        document.title = `Collection Form for ${this.getCustomer().name} - ${this.props.organisation.name} ${new Date().toDateString()}`;
    }

    finishPrinting() {
        document.title = 'Container Master'
    }

    componentDidMount() {
        const {collection_id} = this.props;
        if (collection_id) {
            this.fetchRequest(collection_id);
        }
    }

    componentWillUnmount() {
        this.endRequest();
    }
    saveAndFinishVerifyContents(){
        this.saveContents(true);
    }
    saveVerifyContents(){
        this.saveContents(false);
    }
    saveContents(finish) {
        const {firebase} = this.props;
        const actual_result = this.state.actual;
        const ref =  firebase.db.ref(db_refs.organisations.stock_movement.stock_out(this.props.organisation_id)).child(this.props.collection_id);
        ref.update({
            products: actual_result,
            completed: finish,
            collection_ref: this.state.collection_ref
        }).then(() => {});
    }
    authoriseStockOut() {
        const {firebase, displayName} = this.props;
        firebase.db.ref(db_refs.organisations.stock_movement.stock_out(this.props.organisation_id))
            .child(this.props.collection_id)
            .update({
                verified: true,
                bay_error: null,
                processed_on: new Date().getTime(),
                processed_by: {
                    name: displayName,
                    uid: firebase.auth.currentUser.uid,
                    email: firebase.auth.currentUser.email
                }
            })

            .catch(err=>console.error(err))
    }
    revertContents() {
        const {firebase} = this.props;
        firebase.db.ref(db_refs.organisations.stock_movement.stock_out(this.props.organisation_id))
            .child(this.props.collection_id)
            .child('completed')
            .set(false)
            .catch(err=>console.error(err))
    }
    revertStockOut() {
        const {organisation_id, collection_id} = this.props;
        const ref = this.props.firebase.db.ref(db_refs.organisations.stock_movement.stock_out(this.props.organisation_id)).child(this.props.collection_id);
        ref.update({verified: false, processed_on: null, processed_by: null}).then(() => {
            this.props.firebase.functions.httpsCallable('summaries-collections-getSummary')({
                organisation_id, collection_id
            }).then((res)=>console.log(res))
        })
    }

    notifyContacts(data) {
        const {organisation_id, collection_id} = this.props;
        const {customer} = this.state;
        console.log(data);
        this.setState({
            notifying: true
        })
        let modified_data = data.contentDocument.children[0].outerHTML

        let reg = /<head>[\s\S]*<\/head>/g
        modified_data = modified_data.replace(reg, `<head>
<title>Gate Pass</title>
<style id="react-to-print-2">

html { font-family: Arial,serif; zoom: 0.75;}
.table { width: 100%; margin-bottom: 1rem; color: rgb(33, 37, 41); border-collapse: collapse;}
.table th, .table td { padding: 0.75rem; vertical-align: top; border-top: 1px solid rgb(222, 226, 230); }
.table thead th { vertical-align: bottom; border-bottom: 2px solid rgb(222, 226, 230); }
.table tbody + tbody { border-top: 2px solid rgb(222, 226, 230); }
.table-sm th, .table-sm td { padding: 0.3rem; }
.table-bordered { border: 1px solid rgb(222, 226, 230); }
.table-bordered th, .table-bordered td { border: 1px solid rgb(222, 226, 230); }
.table-bordered thead th, .table-bordered thead td { border-bottom-width: 2px; }
@media print {
  .police-gate-pass table td:not(:last-child) { text-align: center !important; }
  .police-gate-pass table:last-child td:last-child { text-align: center !important; }
  .police-gate-pass table p { text-align: center !important; }
  
  .police-gate-pass table { margin: 5mm auto auto;}
  .police-gate-pass p { font-size: 1em !important; text-align: left !important; }
  .police-gate-pass table:last-child td { vertical-align: top; padding: 1mm; }

  .police-gate-pass.footer tr:last-child { height: 120px; }
  .police-gate-pass h3 { text-align: center; width: 190mm; font-size: 3em !important; color: #18135c; font-family: Arial, serif}
  .police-gate-pass td:first-child { font-size: 1em !important; background-color: rgb(239, 239, 239) !important; }
  .police-gate-pass.middle td { text-align: center !important; }
  .police-gate-pass td:last-child { font-size: 1em !important; }
  .police-gate-pass.middle td:last-child { font-size: 1em !important; }
  table:last-child.police-gate-pass tr:last-child { height: 100px; min-height: 100px; }
}

table, #p-header {
width: 500pt !important;
}
table {font-size: 1.0em !important;} 
</style>
</head>`)

        modified_data = modified_data.replace("<body>", '<body  style="background: white; margin: 10mm; width: 180mm !important;; font-size: 11pt !important;">')
        console.log(modified_data)
        this.props.firebase.functions.httpsCallable('notifyContactsCollection')({
            organisation_id, collection_id: collection_id, customer_id: customer, data: modified_data
        }).then((res)=>{
            if (res.data.error) {
                toast.error(res.data.error, toastConfig);
            }
            if (res.data.success) {
                toast.success(`${res.data.success} notifications sent`, toastConfig);
            }
            this.setState({
                notifying: false
            })
        })
    }

    updateBays(old_bay, pkey, new_bay, qty) {
        const bay = this.props.bays[new_bay];
        const actual = this.state.actual;
        if(old_bay.length > 0 && old_bay !== new_bay) {
            delete actual[pkey].crates[old_bay];
        }
        let actual_qty = qty;
        if(Object.keys(actual[pkey].crates).length === 0) {
            actual_qty = this.state.expected_products[pkey].crates;
        } else {
            if(old_bay !== new_bay) {
                const current = this.getTotalAcrossBays(Object.filter(actual[pkey].crates, c=>!bay || c.code!==bay.code));
                if(current < this.state.expected_products[pkey].crates) {
                    actual_qty = this.state.expected_products[pkey].crates - current;
                }
            } else {
                actual_qty = qty
            }
        }
        if(new_bay.length > 0)
            actual[pkey].crates[new_bay] = {
                code: bay.code,
                qty: actual_qty
            };
        this.setState({actual: actual})
    }
    getTotalExpected() {
        const crates = this.state.verified || this.state.completed ? this.state.actual : this.state.expected_products;
        if(!crates) return 0;
        const x =  Object.keys(crates).map((crate) => {
            return parseInt(this.state.verified || this.state.completed ? this.getTotalAcrossBays(crates[crate].crates) : crates[crate].crates)
        }).reduce((a,b) => a + b, 0);
        if(isNaN(x)) return 0;
        return x
    }
    togglePickingList() {
        this.setState({showPickingList: !this.state.showPickingList});
    }
    getPickingListUrl() {
        this.props.firebase.db.ref(db_refs.organisations.request.collection(this.props.organisation_id)).child(this.state.collection_id).child("picking_list").on('value', (snap) => {
            if(snap.val()) {
                this.props.firebase.storage.ref(snap.val()).getDownloadURL().then(url => {
                    this.setState({
                        url: url
                    })
                })
            } else {
                this.setState({url: null})
            }
        })
    }
    render() {

        let current = 0;
        if(this.state.completed) {
            current = 1
        }
        if(this.state.verified) {
            current = 2
        }
        const steps = [
            {
                src: products,
                label: "Verify Contents",
                complete: !!this.state.actual
            },
            {
                src: collection,
                label: "Stock Out",
                complete: !!this.state.completed
            },
            {
                src: products,
                label: "View",
                complete: false
            }
        ];
        const {gate_passes, customers, firebase, organisation_id, collections, editBulk, picking_lists} = this.props;
        const {issuing, picking_list_id, notifying, notified} = this.state;
        const open_collections = {};
        Object.keys(collections || {}).filter(
            col => !collections[col].pass_id
        ).map((col)=>{
            open_collections[col] = collections[col];
            return true;
        });
        const id = this.props.collection_id;
        const {collection_ref} = this.state;
        const pass_collections = {};
        const pass_lists = {};
        let x = this.getTotalExpected();
        if(!picking_list_id) {
            pass_collections[id] = {id, collection_ref, crates: this.getTotalExpected()};
        } else {
            if (picking_lists[picking_list_id]) {
                const {id, picking_list_number, collections} = picking_lists[picking_list_id];
                x = Object.values(collections).reduce((a, b) => {

                    return a + (b.crates_val || b.grand_total)
                }, 0);
                pass_lists[picking_list_id] = {id, picking_list_number, collections};
            } else if(this.state.actual_bp) {
                const {id, picking_list_number, collections} = this.state.actual_bp;
                x = Object.values(collections).reduce((a, b) => {

                    return a + (b.crates_val || b.grand_total)
                }, 0);
                pass_lists[picking_list_id] = {id, picking_list_number, collections};
            } else {
                return <LoadingScreen/>
            }
        }
        console.log(x);
        const empty_pass_with_collection = {
            customer: this.state.customer,
            vehicle_reg: '',
            haulier: '',
            driver: '',
            pass_number: '',
            collections: pass_collections,
            picking_lists: pass_lists,
            revoked: false,
            total: x
        };
        const {issued_by, issued_on} = gate_passes[this.state.pass_id] || {};
        const {displayName} = issued_by || {};
        return (
            <ContainerMasterCollectionsContext.Consumer>
                {(data)=><div className={'row m-0'}>
                    {data.requests.all && data.collections.all && data.requests.all[this.props.collection_id] && data.collections.all[this.props.collection_id] &&
                <div className={'col-xs-12 col-sm-12 p-1 pb-0 pt-1'}>
                    {this.state.url &&
                    <div>
                        { this.state.showPickingList &&
                        <Modal onHide={() => this.setState({showPickingList: false})} show={this.state.showPickingList} size={'lg'}>
                            <ModalBody style={{maxWidth: '1440px'}} className={'w-100'}>
                                <iframe title={'Container Master Packing List Viewer'} height={"500"} className={'w-100'} src={this.state.url}/>
                            </ModalBody>
                        </Modal>}
                    </div>}
                    {issuing &&  <Modal show={!!issuing} onHide={()=>this.setState({issuing: false})} size={'lg'} >
                        <Modal.Header className={'bg-primary text-light'}>
                            <h3 className={'text-light w-100 text-center'}>
                               Gate Pass
                            </h3>
                        </Modal.Header>
                        <Modal.Body className={'bg-light'}>
                            <GatePassForm
                                key={issuing}
                                logo={this.props.organisation.logo || ''}
                                edit={()=>this.setState({issuing: false})}
                                cancel={()=>{this.setState({issuing: null})}}
                                customers={customers || {}}
                                firebase={firebase}
                                pass_id={issuing}
                                organisation_id={this.props.organisation_id}
                                picking_lists={picking_lists}
                            />


                        </Modal.Body>
                    </Modal>}
                    <Error ref={ref => this.error = ref}/>
                    <div className={'cm-progress-bar col-10 col-lg-8 pt-1 pl-1 pr-1 mr-auto ml-auto mt-5 mb-3 no-print not-mobile'}>
                        <StepProgressBar steps={steps} current={current}/>
                    </div>
                    {this.state.loading && <LoadingScreen/>}
                    {!this.state.loading && this.props.bays && <div className={'bg-light col-12 col-lg-12 p-3 mt-5 text-center small'}>
                        <ButtonGroup size={'sm'}>
                            <Button size={'sm'} className={'btn btn-danger text-light no-print'} onClick={this.props.cancelEditing}>Return to list</Button>
                            {this.state.url &&<Button variant={'tertiary'} onClick={this.togglePickingList}>{this.state.showPickingList ? 'Hide Picking List' : 'Show Picking List'}</Button>}

                            {!this.state.picking_list_id && <ReactToPrint
                                trigger={() => <Button className={'no-print not-mobile'}>Print</Button>}
                                content={() => this.componentRef}
                                onBeforePrint={this.printOutturn}
                                onAfterPrint={this.finishPrinting}
                            />}
                            {this.state.picking_list_id && <Button onClick={()=>editBulk(this.state.picking_list_id)} variant={'info'}>View Bulk Picking List</Button> }
                            <Button size={'sm'} className={'btn btn-default text-light no-print'} onClick={()=>this.setState({include_images: !this.state.include_images})}>
                                Print Images <FontAwesomeIcon icon={this.state.include_images ? 'check' : 'times'} className={this.state.include_images ? 'text-success ml-1' : 'text-danger ml-1'}/>

                            </Button>

                            <input ref={ref => this.photo_upload = ref} type={'file'} onChange={this.uploadPhoto} style={{visibility: 'hidden', width: 0}}/>
                            {!this.state.completed && <Button onClick={() => this.photo_upload.click()} className={'no-print'} variant={'tertiary'}>
                                <FontAwesomeIcon icon={'camera'}/>
                            </Button>}



                            {!this.state.completed && <Button disabled={!this.state.current}  variant={'tertiary'} onClick={this.addProduct} className={'no-print'}>
                                Add Product
                            </Button>}
                            {!this.state.completed && <DropdownButton ref={ref=>this.add_product = ref} as={ButtonGroup}  size={'sm'} title={this.state.current  ?this.state.current.description : 'Select Product To Add'} id="bg-nested-dropdown">
                                {
                                    Object.values(this.props.existing_products || {}).filter((p) =>
                                    {
                                        return Object.keys(this.state.actual).indexOf(p.code) === -1 && p.customer===this.state.customer
                                    }).sort((a,b)=>a.code < b.code ? -1 : 1).map((p) => {
                                            return <Dropdown.Item onClick={() => this.setState({current: p})} eventKey={`existing_${p.code}`} key={`existing_${p.code}`} value={p.id}>{p.code} - {p.description} </Dropdown.Item>
                                        }
                                    )
                                }

                            </DropdownButton>}
                            {!this.state.completed && <Button onClick={this.saveVerifyContents} className={'no-print'} variant={'tertiary'}>
                                Save
                            </Button>}
                            {!this.state.completed && <Button onClick={this.saveAndFinishVerifyContents} className={'no-print'}>
                                Save and Finish
                            </Button>}
                            {this.state.completed && <Button variant={'primary'} onClick={() =>this.props.updatePageWithEntity('collection_requests', this.state.collection_id)} >View Collection Request</Button>}
                        </ButtonGroup>
                        <br/>
                        <div style={{display: 'none'}}>
                            <div ref={ref=>this.componentRef=ref}>
                                {data.collections.all[this.props.collection_id] &&<ElectronicPickingList
                                    requests={data.collections.all}
                                   collections={{[this.props.collection_id]:{...this.state, products: {actual: this.state.actual}}}}
                                   gate_pass={this.state.gate_passes && this.state.pass_id && this.state.gate_passes[this.state.pass_id] ? this.state.gate_passes[this.state.pass_id] : null}
                                   customer={this.state.customer ? customers[this.state.customer] : null} completed={this.state.completed} photos={this.state.photos && this.state.include_images ? this.state.photos : null} total={this.getTotalExpected()}
                                   />}
                                {!data.collections.all[this.props.collection_id] && data.requests.all[this.props.collection_id] &&<ElectronicPickingList
                                    requests={data.requests.all}
                                    collections={{[this.props.collection_id]:{...this.state}}}
                                    gate_pass={this.state.gate_passes && this.state.pass_id && this.state.gate_passes[this.state.pass_id] ? this.state.gate_passes[this.state.pass_id] : null}
                                    customer={this.state.customer ? customers[this.state.customer] : null} completed={this.state.completed} photos={this.state.photos && this.state.include_images ? this.state.photos : null} total={this.getTotalExpected()}
                                />}
                            </div>
                        </div>
                        { !this.state.crate_view && !this.state.completed && <div id="printarea" className={'row mt-5 bg-light h-auto'}>


                            <div className={'row col-12 m-0 '}>
                                {this.state.pass_id && <Error error={{message: "Changing the quantity of this collection will invalidate the associated gate pass. The pass will be revoked and an updated one generated. You will need to issue the new pass before you can complete the stock in process"}}/>}
                                <div className={'col-4 no-print'}>
                                    {this.getCustomer() && <h3>Customer: {this.getCustomer().name}</h3>}
                                </div>
                                <div className={'col-4 no-print'}>
                                    {this.props.organisation  && <h3>Organisation: {this.props.organisation.name}</h3>}
                                </div>
                                <div className={'col-4 no-print'}>
                                    {this.state.collection_ref  && <h3 className={'container-number'}>Collection Ref: {this.state.collection_ref}</h3>}
                                </div>
                                <div className={'col-12 print-only'}>
                                    {this.state.collection_ref  && <h1 className={'container-number'}>Collection Ref: {this.state.collection_ref}</h1>}
                                </div>
                            </div>
                            <hr className={'w-100 bg-primary'}/>
                            {Object.keys(this.state.expected_products).map((pkey) => {
                                return (
                                    <div key={`product_${pkey}`} className={'row col-12 col-xl-6 m-0 print-product'}>
                                        <div className={'col-6 p-1'}>
                                            {pkey}<br className={'no-print'}/>
                                            <sub
                                                className={'no-print'}>{this.state.expected_products[pkey].description}</sub>
                                            <p className={'m-0 print-only'}>{this.state.expected_products[pkey].description}</p>
                                        </div>
                                        <div className={'col-3 p-1 text-center no-print'}>
                                            {this.state.expected_products[pkey].crates}
                                        </div>
                                        <div className={'col-6 p-1 pl-3 text-right print-only'}>
                                            <h1 className={'text-center print-only'}>Requested: {this.state.expected_products[pkey].crates}</h1>
                                        </div>
                                        <div className={'col-3 p-1 no-print'}>
                                            <FormControl
                                                className={'no-print'}
                                                type={'number'}
                                                name={'actual/' + pkey + '/total_crates'}
                                                value={this.getTotalAcrossBays(this.state.actual[pkey].crates)}
                                                readOnly={true}
                                                onChange={this.handleChange}
                                            />

                                        </div>
                                        <div className={'row no-print w-100'}>
                                            {
                                                Object.keys(this.state.actual[pkey].crates || {}).map(crate_bay_key => {
                                                    return (
                                                        <BayQuantitySelector
                                                            key={`${pkey}_${crate_bay_key}`}
                                                            selector
                                                            qty={this.state.actual[pkey].crates[crate_bay_key].qty}
                                                            onChange={this.updateBays} bays={Object.filter(this.props.bays, r=>r.product === pkey)}
                                                            adjustments={this.state.adjustments}
                                                            current_bay={crate_bay_key} pkey={pkey}/>
                                                    );

                                                })
                                            }
                                            {
                                                (this.getTotalAcrossBays(this.state.actual[pkey].crates)  === 0 || this.getTotalAcrossBays(this.state.actual[pkey].crates) < this.state.expected_products[pkey].crates || this.state.expected_products[pkey].crates === 0) &&
                                                <BayQuantitySelector selector qty={0} onChange={this.updateBays}
                                                                     adjustments={this.state.adjustments}
                                                                     bays={Object.filter(this.props.bays, r=>r.product === pkey)} current_bay={''}
                                                                     pkey={pkey}/>

                                            }
                                        </div>

                                        <div className={'row print-only'}>
                                            <div className={'col-2'}>
                                                Bay: <input className={'form-control'}/>
                                            </div>
                                            <div className={'col-2'}>
                                                Quantity: <input className={'form-control'}/>
                                            </div>
                                            <div className={'col-2'}>
                                                Bay: <input className={'form-control'}/>
                                            </div>
                                            <div className={'col-2'}>
                                                Quantity: <input className={'form-control'}/>
                                            </div>
                                            <div className={'col-2'}>
                                                Bay: <input className={'form-control'}/>
                                            </div>
                                            <div className={'col-2'}>
                                                Quantity: <input className={'form-control'}/>
                                            </div>

                                        </div>
                                        <div className={'col-12 p-1'}>
                                            {
                                                PRESETS.map((p, i) => {
                                                    return <Button
                                                        key={`preset${i}`}
                                                        name={'expected/' + pkey + '/reason'}
                                                        data-content={p}
                                                        className={'bg-tertiary text-light btn-sm m-1 small no-print'}
                                                        onClick={this.addPresetReason}
                                                    >
                                                        {p}
                                                    </Button>
                                                })
                                            }

                                            {
                                                this.state.allowPresets && <div className={'row m-0 p-0 print-only'}>
                                                    {
                                                        PRESETS.map((p, i) => {
                                                            return <div key={'preset-print-' + i}
                                                                        className={'col-4 small-text print-only'}>
                                                                {p}
                                                                <FontAwesomeIcon
                                                                    className={'text-dark ml-3 float-right print-only'}
                                                                    size={'1x'} icon={['far', 'square']}
                                                                    prefix={'far'}/>
                                                            </div>
                                                        })
                                                    }

                                                </div>

                                            }

                                            <br className={'no-print'}/>
                                            <span className={'no-print'}>Notes:</span>
                                            <FormControl
                                                className={'no-print'}
                                                isInvalid={(parseInt(this.getTotalAcrossBays(this.state.actual[pkey].crates).toString()) !== this.state.expected_products[pkey].crates) && ( !this.state.actual[pkey].reason || this.state.actual[pkey].reason.trim().length=== 0)}
                                                isValid={this.state.actual[pkey].reason.trim().length > 0}
                                                name={'actual/' + pkey + '/reason'}
                                                value={this.state.actual[pkey].reason}
                                                onChange={this.handleChange} as="textarea" rows="3"
                                            />
                                        </div>

                                        <hr className={'bg-primary w-100'}/>
                                    </div>)

                            })}
                            <span className={'print-only'}>Notes</span>
                            <FormControl className={'print-only'} as={'textarea'} rows={5}/>
                        </div>}
                        { !this.state.crate_view && !this.state.completed && <div className={'w-75 m-auto'}>
                            <h3>Photos</h3>
                            {
                                this.state.photos && this.state.photos.map((photo, i) => {
                                    return <Image key={this.collection_ref + `_photo_${i}`}
                                                  className={'img img-fluid m-1'} src={photo}/>
                                })
                            }
                        </div>}
                    </div>}
                    {
                        !this.state.loading && this.state.completed &&
                    <div className={'bg-light col-12 row p-3 mb-3 ml-0 mr-0 text-center'}>

                        {!this.state.verified && <div className={'col-12'}>

                           <Button className={'m-1'} onClick={this.revertContents} variant={'danger'}>Return to Load Verification</Button>
                            {!this.state.pass_id && <Button className={'m-1'} onClick={() => {
                                if(!this.state.provisional_pass_id) {
                                    this.props.firebase.db.ref(db_refs.organisations.gate_passes(organisation_id)).push(empty_pass_with_collection).then(res => {
                                        this.setState({issuing: res.key})

                                    })
                                } else {
                                    this.setState({issuing:this.state.provisional_pass_id})
                                }
                            }} variant={'primary'}>{this.state.provisional_pass_id ? 'View Gate Pass' : 'Issue Gate Pass'}</Button>}

                            {this.state.pass_id && <Button className={'m-1'} variant={'primary'} onClick={()=>this.setState({issuing: this.state.pass_id})}>View Gate Pass</Button>}

                            {this.state.fullPass && <ReactToPrint
                                trigger={() => <Button disabled={notifying} className={'no-print not-mobile'}>{!notifying && !notified && "Notify"}{!notifying && notified && "Resend Notification"}{notifying && "Sending"}</Button>}
                                content={() => this.passRef}

                                print={(res)=>{
                                    this.notifyContacts(res)
                                    return Promise.resolve(true)
                                }}
                            /> }
                            {this.state.fullPass && <div style={{display: "none"}}>
                                <div ref={(ref)=>this.passRef = ref}>
                                    <PrintedGatePass list_total={x} generating={true} picking_lists={this.state.fullPass.picking_lists} collections={this.state.fullPass.collections} gate_pass={this.state.fullPass} customer={this.getCustomer().name} logo={this.props.organisation.logo}/>
                                </div>
                            </div>}

                            <Button onClick={this.authoriseStockOut} className={'m-1'} variant={'success'} disabled={!this.state.pass_id}>Authorise Stock Out</Button>
                        </div>}

                        <div className={'col-12 row'}>
                            <div className={'col-4 no-print'}>
                                {this.state.collection_ref  && <h3 className={'container-number'}>Collection Ref: {this.state.collection_ref}</h3>}
                            </div>
                            <div className={'col-4 no-print'}>
                                {this.getCustomer() && <h3>Customer: {this.getCustomer().name}</h3>}
                            </div>
                            <div className={'col-4 no-print'}>
                                {this.props.organisation  && <h3>Organisation: {this.props.organisation.name}</h3>}
                            </div>
                        </div>

                        {this.state.verified && <div className={'col-12'}>


                            {this.state.verified  && <Button className={'m-1'} onClick={this.revertStockOut.bind(this)} variant={'danger'}>Revert Stock Out</Button>}

                            {this.state.pass_id && <Button className={'m-1'} variant={'primary'} onClick={()=>this.setState({issuing: this.state.pass_id})}>View Gate Pass</Button>}
                            {this.state.fullPass &&  <ReactToPrint
                                trigger={() => <Button disabled={notifying} className={'no-print not-mobile'}>{!notifying && !notified && "Notify"}{!notifying && notified && "Resend Notification"}{notifying && "Sending"}</Button>}
                                content={() => this.passRef}

                                print={(res)=>{
                                    this.notifyContacts(res)
                                    return Promise.resolve(true)
                                }}
                            /> }
                            {this.state.fullPass && <div style={{display: "none"}}>
                                <div ref={(ref)=>this.passRef = ref}>
                                    <PrintedGatePass list_total={x} generating={true} picking_lists={this.state.fullPass.picking_lists}  gate_pass={this.state.fullPass} customer={this.getCustomer().name} logo={this.props.organisation.logo}/>
                                </div>
                            </div>}

                            <Table striped={true} responsive={true} className={'w-75 m-auto mb-2'}>
                                <thead>
                                <tr><td>Status</td><td>Stock In Operative</td></tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{ this.state.pass_id && gate_passes[this.state.pass_id] ? `Products collected on ${new Date(issued_on).toLocaleString()}` : <span><FontAwesomeIcon icon={"spinner"} spin={true}/><br/>Processing stock out</span>}</td>
                                    <td>{ this.state.pass_id && gate_passes[this.state.pass_id] && issued_by ? `${displayName}` : '-'}</td>
                                </tr>
                                <tr>
                                    <td>{ this.state.processed_on ? `Products processed on ${new Date(this.state.processed_on).toLocaleString()}` : <span><FontAwesomeIcon icon={"spinner"} spin={true}/><br/>Processing stock out</span>}</td>
                                    <td>{ this.state.processed_by ? `${this.state.processed_by.name}` : '-'}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>}
                        <Table bordered size={'sm'} className={'larger col-6'}>
                            <thead>
                            <tr>
                                <th>Product</th>
                                <th>Bay</th>
                                <th>Quantity</th>
                                <th>Notes</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(this.state.actual).map((pkey) => {
                                    const invalidDefaultBay = this.state.actual[pkey] ? this.state.actual[pkey].invalidDefaultBay : false;
                                    return Object.keys(this.state.actual[pkey].crates).map((bkey) => {
                                        return (<tr key={`${pkey}_${bkey}`}>
                                            <td>{pkey}</td>
                                            <td>{this.props.bays[bkey].code}
                                            </td>
                                            <td>{this.state.actual[pkey].crates[bkey].qty}</td>
                                            <td>{this.state.actual[pkey].reason}
                                                {invalidDefaultBay && <p className={"text-danger"}>Invalid default detected in processing</p>}
                                            </td>
                                        </tr>);
                                    })
                                })
                            }
                            </tbody>
                        </Table>

                        <div className={'col-6 row m-0'}>
                            <h3>Photos</h3>
                            {
                                this.state.photos && this.state.photos.map((photo, i) => {
                                    return <div key={`collections_photo_${i}`} className={'col-12'}><Image className={'img img-fluid m-1'} src={photo}/></div>
                                })
                            }
                        </div>
                        </div>
                    }
                    </div>}
            </div>}
            </ContainerMasterCollectionsContext.Consumer>

        );
    }
}

Wizard.propTypes = {
    organisation_id: PropTypes.string.isRequired,
    existing_products: PropTypes.object.isRequired,
    firebase: PropTypes.object.isRequired,
    collection_id: PropTypes.string.isRequired
};

export default Wizard;