import {RowCell} from "../../../elements/Common";
import moment from "moment";
import {Button, ButtonGroup, ModalBody} from "react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import {toastConfig} from "../../../config/toast_config";
import ReactToPrint from "react-to-print";
import DATABASE_REFS from "../../../constants/db_refs";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import PrintedGatePass from "./PrintedGatePass";
import SearchableTable from "../../../elements/SearchableTable";
import {ContainerMasterGatePassContext} from "../../../components/ContainerMaster/ContainerMaster";
import {AdvancedCustomerFilter, AdvancedDateFilter, AdvancedFilterPanel} from "../../../elements/AdvancedFiltering";


class GatePassRow extends React.Component {

    render() {
        const {headers} = this.props;
        const pass = {...this.props.pass};
        return <tr className={'col-12 w-100 m-1 product item-row mb-1 print-block'}>
            {headers.map((h, i) => <RowCell muted={pass.revoked} key={`table_row_${pass.id}_${h.key}_${pass.number}`}
                                            col={3} title={h.label} alignment={'center'}
                                            header={!this.props.printing && i === 0}
                                            noprint={h.noprint}>{h.content(pass)}</RowCell>)}
        </tr>;
    }
}

class GatePassTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState,
            key: 'gate_passes',
            startDate: moment().subtract(1, 'week').toDate().getTime(),
            endDate: moment().add(1, 'week').toDate().getTime(),
            start: moment().subtract(1, 'week').toDate().getTime(),
            end: moment().add(1, 'week').toDate().getTime(),
            sort: 'pass_number',
            reverse: -1,
            page: 0,
            num_per_page: 10,
            noDetails: true
        };
        this.pass_refs = {};
    }

    getGatePassDeletionModal() {
        if (this.state.deleting) {
            return <Modal onHide={() => this.setState({deleting: null})} size={'lg'} show={true}>
                <ModalHeader className={'bg-primary'}>
                    <h3 className={'text-light'}>{`Deleting gate pass ${this.state.deleting.pass_number}`}</h3>
                </ModalHeader>
                <ModalFooter className={'bg-light'}>
                    <Button onClick={() => this.setDeleteItem(null)} variant={'secondary'}>Cancel</Button>
                    <Button variant={'danger'}
                            onClick={() => this.deleteItem(this.state.deleting.id, this.state.deleting)}>Delete</Button>
                </ModalFooter>
            </Modal>
        }
    }
    getGatePassRevocationModal() {
        if (this.state.revoking) {

            let can_revoke = moment(new Date(parseInt(this.state.revoking.issued_on))).endOf('day').add(12, 'hours') > moment(new Date());

            return <Modal onHide={() => this.setState({revoking: null})} size={'lg'} show={true}>
                <ModalHeader className={'bg-primary'}>
                    <h3 className={'text-light'}>{`Revoking gate pass ${this.state.revoking.pass_number}`}</h3>
                </ModalHeader>
                <ModalBody>
                    {can_revoke && <p>Are you sure you wish to revoke this gate pass?</p>}
                    {!can_revoke && <p>This pass can no longer be revoked</p>}
                </ModalBody>
                <ModalFooter className={'bg-light'}>
                    <Button onClick={() => this.setState({revoking: null})} variant={'secondary'}>Cancel</Button>
                    <Button disabled={!can_revoke} variant={'danger'}
                            onClick={() => {
                                this.revokePass(this.state.revoking.id, this.state.revoking);
                                this.setState({revoking: null})
                            }}>Revoke</Button>
                </ModalFooter>
            </Modal>
        }
    }

    deleteItem(id) {
        this.props.firebase.db.ref(this.pass_ref(this.props.organisation_id)).child(id).set(null).then(() => toast.error('Deleted gate pass'));
        this.setState({deleting: false})
    }

    subFilter(passes) {

        return passes.filter(p => {
            let issued_in_date = false;
            if (p.issued_on)
                if (parseInt(p.issued_on) > parseInt(this.state.startDate) && parseInt(p.issued_on) < parseInt(this.state.endDate)) {
                    issued_in_date = true;
                }
            let revoked_in_date = false;
            if (p.revoked_on)
                if (parseInt(p.revoked_on) > parseInt(this.state.startDate) && parseInt(p.revoked_on) < parseInt(this.state.endDate)) {
                    revoked_in_date = true;
                }
            const neither = !p.revoked_on && !p.issued_on;
            let matched = !p.collection_string && !p.picking_lists;
            if (p.collection_string && p.collection_string.toLowerCase().indexOf(this.props.filter.toLowerCase()) > -1) {
                matched = true;
            }
            if (p.picking_lists && Object.values(p.picking_lists).map(pl => pl.collection_string).join(",").indexOf(this.props.filter.toLowerCase()) > -1) {
                matched = true;
            }
            if (p.picking_lists && Object.values(p.picking_lists).map(pl => pl.picking_list_number).join(",").indexOf(this.props.filter.toLowerCase()) > -1) {
                matched = true;
            }
            let matched2 = !p.outturns;
            if (p.outturns) {
                const stringRes = Object.values(p.outturns || {}).map(o => o.container_number).join(",").toLowerCase();
                if (stringRes.indexOf(this.props.filter.toLowerCase()) > -1) {
                    matched2 = true;
                }
            }
            let matched3 = false;
            if ((p.pass_number || "").toLowerCase().indexOf(this.props.filter.toLowerCase()) > -1) {
                matched3 = true;
            }
            return (issued_in_date || revoked_in_date || neither) && ((matched && matched2) || matched3)
        })
    }

    getGatePassCustomButtonPanel(empty_pass) {
        const {firebase, organisation_id} = this.props;

        return <Button onClick={() => {

            const key = firebase.db.ref(this.pass_ref(this.props.organisation_id)).push().key;
            firebase.db.ref(this.pass_ref(organisation_id)).child(key).set(empty_pass)
                .then(() => {
                    this.props.edit(key);
                });


        }
        } variant={'success'} size={'sm'} className={'m-auto no-print'}>
            <FontAwesomeIcon icon={'plus-circle'}/>
        </Button>
    }

    revokePass(id, pass) {
        const {firebase} = this.props;
        firebase.functions.httpsCallable(this.revoke_function)(
            {
                organisation_id: this.props.organisation_id,
                gate_pass_id: id,
                picking_lists: pass.picking_lists ? pass.picking_lists : {},
                collections: pass.collections ? pass.collections : {},
                outturns: pass.outturns ? pass.outturns : {}
            }).then(() => {
            toast.error('Pass revoked', {...toastConfig});
        }).catch(err => toast.error(err.message, {...toastConfig}));
    }
    getAdvancedFilters({ extra_date_filters, customer_filter}) {
        return <AdvancedFilterPanel>
            <AdvancedCustomerFilter selectAll={customer_filter.selectAll} deselectAll={customer_filter.deselectAll} onClick={customer_filter.updateCustomerFilter} original_list={customer_filter.filter_options} current_list={customer_filter.filter}/>
            {Object.values(extra_date_filters).map((edf,i)=><AdvancedDateFilter key={`${Object.keys(extra_date_filters)[i]}_adf`} {...edf} legend_text={Object.keys(extra_date_filters)[i]}/>)}
        </AdvancedFilterPanel>
    }

    getContent(data, headers) {
        return data.map(
            (gate_pass) => <GatePassRow headers={headers} firebase={this.props.firebase}
                                        organisation_id={this.props.organisation_id}
                                        key={`${gate_pass.id}_${gate_pass.pass_number}`} pass={gate_pass}/>)
    }
}

class CollectionGatePassTable extends GatePassTable {
    has_export = 'Export Collection Gate Passes';
    pass_ref = DATABASE_REFS.organisations.gate_passes;
    revoke_function = 'revokeGatePass';
    // noinspection JSUnusedGlobalSymbols
    CMContext = ContainerMasterGatePassContext;
    key = 'gate_passes';

    getData() {
        const results = [];
        this.subFilter(this.state.sorted_data).filter(d => d.customer).map(d => {
            const {customer, pass_number, haulier, vehicle_reg, issued_on, revoked_on, driver, total, collection_string, picking_lists} = d;
            const res = collection_string.split(',').map(gpc => {
                return {
                    customer: customer ? this.props.customers[customer].name : '-',
                    pass_number: pass_number,
                    issued_on: issued_on ? new Date(parseInt(issued_on)).toLocaleString() : '-',
                    revoked_on: revoked_on ? new Date(parseInt(revoked_on)).toLocaleString() : '-',
                    driver,
                    haulier,
                    vehicle_reg,
                    total,
                    collection: gpc,
                    picking_list: '-'

                }
            });
            results.push(...res);
            Object.values(picking_lists).map(pl => {
                const res2 = pl.collection_string.split(',').map(gpc => {
                    return {
                        customer: customer ? this.props.customers[customer].name : '-',
                        pass_number: pass_number,
                        issued_on: issued_on ? new Date(parseInt(issued_on)).toLocaleString() : '-',
                        revoked_on: revoked_on ? new Date(parseInt(revoked_on)).toLocaleString() : '-',
                        driver,
                        haulier,
                        vehicle_reg,
                        total,
                        collection: gpc,
                        picking_list: pl.picking_list_number

                    }
                });
                results.push(...res2);
                return res2
            });
            return true;
        });
        this.setState({data: results})
    }

    getReportTitle() {
        return "Gate Passes"
    }
    setFilter(filter, callback) {
        const {status_filters} = this.state;
        this.props.setFilter(filter.target.value, status_filters, [], callback)
    }

    getAdditionalHeaders() {
        return [{
            label: '',
            key: false,
            size: 2,
            noprint: true,
            content: (gate_pass) => {
                const {id, issued, revoked, can_revoke} = gate_pass;
                return <ButtonGroup>
                    {
                        revoked && <Button
                            onClick={() => {
                                this.props.edit(id)
                            }}
                            key={`edit_button_${id}`}
                            title={'Edit?'}
                            data-item={id}
                            variant={'info'}
                            size={'sm'}>View</Button>


                    }
                    {
                        !(issued || revoked) && <Button
                            onClick={() => {
                                this.props.edit(id)
                            }}
                            key={`edit_button_${id}`}
                            title={'Edit?'}
                            data-item={id}
                            variant={'primary'}
                            size={'sm'}>Edit</Button>
                    }
                    {
                        !(issued || revoked) && <Button
                            onClick={() => {
                                this.props.edit(id)
                            }}
                            key={`issue_button_${id}`}
                            title={'Issue?'}
                            data-item={id}
                            variant={'success'}
                            size={'sm'}>Issue</Button>
                    }
                    {
                        (issued && !revoked) && <Button
                            onClick={() => {
                                this.props.edit(id)
                            }}
                            key={`view_button_${id}`}
                            title={'View?'}
                            data-item={id}
                            variant={'info'}
                            size={'sm'}>View</Button>
                    }
                    {
                        (issued && !revoked) && <ReactToPrint
                            trigger={() => <Button

                                key={`print_button_${id}`}
                                title={'Print?'}
                                data-item={id}
                                variant={'primary'}
                                size={'sm'}>Print</Button>}
                            content={() => this.pass_refs[id]}
                            onBeforePrint={this.updatePrintProcessing}
                            onAfterPrint={this.endPrintProcessing}
                        />
                    }
                    {
                        (issued && !revoked) && <div style={{display: 'none'}}>
                            <div ref={ref => this.pass_refs[id] = ref}>
                                <PrintedGatePass

                                    gate_pass={gate_pass}
                                    customer={this.props.customers[gate_pass.customer].code}
                                    collections={gate_pass.collections}
                                    picking_lists={gate_pass.picking_lists}
                                    logo={this.props.organisation.logo}
                                />
                            </div>
                        </div>
                    }
                    {
                        !(issued || revoked) && <Button
                            onClick={() => this.setState({deleting: gate_pass})}
                            key={`delete_button_${id}`}
                            title={'Issue?'}
                            data-item={id}
                            variant={'danger'}
                            size={'sm'}>Delete</Button>
                    }
                    {
                        (issued && !revoked) && <Button
                            disabled={!can_revoke}
                            onClick={() => {
                                this.setState({revoking: gate_pass})
                            }}
                            key={`edit_button_${id}`}
                            title={can_revoke ? 'Revoke?' : 'Attached movements already processed!'}
                            data-item={id}
                            variant={'danger'}
                            size={'sm'}>Revoke</Button>
                    }

                </ButtonGroup>
            }
        }]
    }

    getDeletionModal() {
        return this.getGatePassDeletionModal();
    }

    getAdditionalModal() {
        return this.getGatePassRevocationModal();
    }

    getCustomButtonPanel() {
        const empty_pass = {
            vehicle_reg: '',
            haulier: '',
            driver: '',
            pass_number: '',
            collections: {},
            picking_lists: {},
            revoked: false,
            total: 0
        };
        return this.getGatePassCustomButtonPanel(empty_pass);
    }
}

class DevanGatePassTable extends GatePassTable {
    has_export = 'Export Outturn Gate Passes';
    pass_ref = DATABASE_REFS.organisations.devan_gate_passes;
    revoke_function = 'revokeDevanGatePass';
    // noinspection JSUnusedGlobalSymbols
    CMContext = ContainerMasterGatePassContext;
    key = 'devan_gate_passes';
    setFilter(filter, callback) {
        this.props.setFilter(filter.target.value, callback, true)
    }
    getAdditionalModal() {
        return this.getGatePassRevocationModal();
    }
    getData() {
        this.setState({
            data: this.subFilter(this.state.sorted_data).map(d => {
                const {customer, pass_number, outturns, haulier, vehicle_reg, issued_on, revoked_on, driver, total} = d;
                return {
                    customer: customer ? this.props.customers[customer].name : '-',
                    pass_number: pass_number,
                    issued_on: issued_on ? new Date(parseInt(issued_on)).toLocaleString() : '-',
                    revoked_on: revoked_on ? new Date(parseInt(revoked_on)).toLocaleString() : '-',
                    driver,
                    haulier,
                    vehicle_reg,
                    total,
                    outturns: Object.values(outturns || {}).map(c => c.container_number).join(',')
                }
            })
        })
    }

    getDeletionModal() {
        return this.getGatePassDeletionModal(this.props.devan_gate_passes);
    }

    getCustomButtonPanel() {
        const empty_pass = {
            vehicle_reg: '',
            haulier: '',
            driver: '',
            pass_number: '',
            outturns: {},
            revoked: false,
            total: 0
        };
        return this.getGatePassCustomButtonPanel(empty_pass);

    }

    getReportTitle() {
        return "Devan Gate Passes"
    }

    getAdditionalHeaders() {
        return [ {
            label: '',
            key: false,
            size: 2,
            noprint: true,
            content: (gate_pass) => {
                const {id, issued, revoked, can_revoke} = gate_pass;
                return <ButtonGroup>
                    {
                        revoked && <Button
                            onClick={() => {
                                this.props.edit(id)
                            }}
                            key={`edit_button_${id}`}
                            title={'Edit?'}
                            data-item={id}
                            variant={'info'}
                            size={'sm'}>View</Button>


                    }
                    {
                        !(issued || revoked) && <Button
                            onClick={() => {
                                this.props.edit(id)
                            }}
                            key={`edit_button_${id}`}
                            title={'Edit?'}
                            data-item={id}
                            variant={'primary'}
                            size={'sm'}>Edit</Button>
                    }
                    {
                        !(issued || revoked) && <Button
                            onClick={() => {
                                this.props.edit(id)
                            }}
                            key={`issue_button_${id}`}
                            title={'Issue?'}
                            data-item={id}
                            variant={'success'}
                            size={'sm'}>Issue</Button>
                    }
                    {
                        (issued && !revoked) && <Button
                            onClick={() => {
                                this.props.edit(id)
                            }}
                            key={`view_button_${id}`}
                            title={'View?'}
                            data-item={id}
                            variant={'info'}
                            size={'sm'}>View</Button>
                    }
                    {
                        (issued && !revoked) && <ReactToPrint
                            trigger={() => <Button

                                key={`print_button_${id}`}
                                title={'Print?'}
                                data-item={id}
                                variant={'primary'}
                                size={'sm'}>Print</Button>}
                            content={() => this.pass_refs[id]}
                            onBeforePrint={this.updatePrintProcessing}
                            onAfterPrint={this.endPrintProcessing}
                        />
                    }
                    {
                        (issued && !revoked) && <div style={{display: 'none'}}>
                            <div ref={ref => this.pass_refs[id] = ref}>
                                <PrintedGatePass

                                    gate_pass={gate_pass}
                                    customer={this.props.customers[gate_pass.customer] ? this.props.customers[gate_pass.customer].code : ''}

                                    outturns={gate_pass.outturns}
                                    logo={this.props.organisation.logo}
                                />
                            </div>
                        </div>
                    }
                    {
                        !(issued || revoked) && <Button
                            onClick={() => this.setState({deleting: gate_pass})}
                            key={`delete_button_${id}`}
                            title={'Issue?'}
                            data-item={id}
                            variant={'danger'}
                            size={'sm'}>Delete</Button>
                    }
                    {
                        (issued && !revoked) && <Button
                            onClick={() => {
                               this.setState({revoking: gate_pass})
                            }}
                            disabled={!can_revoke}
                            key={`edit_button_${id}`}
                            title={can_revoke ? 'Revoke?' : 'Attached movements already processed!'}
                            data-item={id}
                            variant={'danger'}
                            size={'sm'}>Revoke</Button>
                    }

                </ButtonGroup>
            }
        }]
    }
}

export {DevanGatePassTable, CollectionGatePassTable}
