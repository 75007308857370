import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, ButtonGroup, CloseButton, Modal, ModalBody, Table} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import {DATABASE_REFS} from "../../constants/db_refs";

class InviteListener extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invites: []
        };
    }

    acceptInvite(id) {
        this.props.firebase.functions.httpsCallable('acceptInvite')({invite_id: id}).then(()=>{}).catch(err=>console.log(err));
    }
    declineInvite(id) {
        this.props.firebase.functions.httpsCallable('acceptInvite')({invite_id: id}).then(()=>{}).catch(err=>console.log(err));
    }

    componentDidMount() {
        const {uid} = this.props;
        if(!uid) return;
        this.props.firebase.db.ref(DATABASE_REFS.user.invites(uid)).on('value', (invite_snapshot) => {
            const invites =  invite_snapshot.val();
            this.setState({invites: Object.keys(invites || {}).map(invite => {return {...invite_snapshot.val()[invite], id: invite}})});
        })
    }

    componentWillUnmount() {
        this.props.firebase.db.ref(`users/${this.props.uid}/invites`).off();
    }

    render() {
        return <div className={'p-4 text-light mr-4 interactive text-right float-right'}>
            <Modal onHide={()=>this.setState({viewing: false})} show={this.state.viewing}>
                <ModalHeader className={'text-light bg-primary'}>
                    <h3 className={'text-light'}>Pending Invites</h3>
                    <CloseButton className={'text-light'} onClick={()=>this.setState({viewing: false})}/>
                </ModalHeader>
                <ModalBody className={'bg-light'}>
                    <Table size={'sm'} bordered={false}>
                        <tbody>
                    {this.state.invites.map(invite=>{
                        return <tr key={invite.id}><td className={'w-75'}>{`You have been invited to join ${invite.organisation}`}</td><td>
                            <ButtonGroup className={'m-auto'}>
                                <Button onClick={()=>this.acceptInvite(invite.id)} variant={'success'} title={'Accept'}>
                                    <FontAwesomeIcon fixedWidth={true} icon={'check'}/>
                                </Button>
                                <Button onClick={()=>this.declineInvite(invite.id)}  variant={'danger'} title={'Decline'}>
                                    <FontAwesomeIcon  fixedWidth={true} icon={'times'}/>
                                </Button>
                            </ButtonGroup>
                        </td></tr>
                    })}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter className={'bg-light'}>
                    <Button onClick={()=>this.setState({viewing: false})} variant={'secondary'}>Close</Button>
                </ModalFooter>
            </Modal>
            {this.state.invites.length > 0 && <FontAwesomeIcon onClick={()=>this.setState({viewing: true})} className={'text-warning'} size={'2x'} icon={'exclamation-triangle'} title={'You have pending invites!'}/>}
        </div>
    }
}

export default InviteListener;