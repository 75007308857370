import React from 'react';
import {toast} from "react-toastify";
import * as PropTypes from 'prop-types';
import Input from "../../elements/Input";
import Error from "../../elements/Error";
import {toastConfig} from "../../config/toast_config";
import TextArea from "../../elements/TextArea";
import {FormattedMessage} from "react-intl";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import db_refs from "../../constants/db_refs"
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {Button} from "react-bootstrap";

const INITIAL_STATE = () => {
    return {
        name: '',
        description: '',
        logo: ''

    }
};

class OrganisationForm extends React.Component {
    constructor(props) {
        super(props);
        if(props.organisation) {
            const {name, description} = props.organisation;
            this.state = {...{name,description}, logo:""}
        } else {
            this.state = INITIAL_STATE();
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.errors = React.createRef();
    }

    handleChange(event) {
        let newState = {};
        if (event.target.files && event.target.files.length) {
            newState["logo"] = event.target.files[0];
            newState["logo_path"] = URL.createObjectURL(event.target.files[0])
        } else {
            newState[event.target.name] = event.target.value;
        }

        this.setState(newState)
    }
    onSubmit() {
        const {name, description, logo} = this.state;
        this.setState({loading: true});
        if (!this.props.organisation) {
            return this.props.firebase.doCreateOrganisation(this.props.uid, name, description).then((org) => {
                setTimeout(()=>{
                    this.props.firebase.storage.ref(`logos/${org.key}/${logo.name}`).put(logo).then((upload) => {
                        upload.ref.getDownloadURL().then((url) => {
                            this.props.firebase.db.ref(db_refs.organisations.details(org.key)).child("logo").set(url).then(() => {
                                toast.success(`Created organisation: ${this.state.name}!`, {
                                    ...toastConfig,
                                    autoClose: 2000
                                });
                                this.setState({loading: false, ...INITIAL_STATE(), logo_path: ''});

                            }).catch(error => {
                                console.log(error)
                            })
                        }).catch(error => {
                            console.log(error)
                        })
                    }).catch(error => {
                        console.log(error)
                    });
                }, 10000);

            }).catch(error => {
                console.log(error)
            })
        } else {
            return this.props.firebase.db.ref(db_refs.organisations.details(this.props.organisation.id)).update({name, description}).then(()=>{
                if(logo) {
                    this.props.firebase.storage.ref(`logos/${this.props.organisation.id}/${logo.name}`).put(logo).then((upload) => {
                        upload.ref.getDownloadURL().then((url) => {
                            this.props.firebase.db.ref(db_refs.organisations.details(this.props.organisation.id)).child("logo").set(url).then(() => {
                                this.setState({loading: false}, ()=> {
                                    this.props.cancel()
                                });
                            })
                        })
                    })
                } else {
                    this.setState({loading:false}, ()=> {
                        this.props.cancel()
                    })
                }
            })
        }
    }

    render() {
        const {error} = this.state;
        if(this.state.loading)
            return <LoadingScreen/>;
        return (
            <div data-testid="organisation_form" className={`col-12 bg-primary text-light${!this.props.fullWidth ? ' p-5 offset-lg-2 col-lg-8': ' p-2'}`}>
                <h3 className={'text-light'}><FormattedMessage id={this.props.header}/></h3>

                <Error ref={this.errors} error={error}/>

                <Input
                    name={"name"}
                    label_id={"app.organisation_name"}
                    handleChange={this.handleChange}
                    value={this.state.name}
                    required={true}
                    type={'email'}
                />
                <TextArea
                    name={"description"}
                    label_id={"app.organisation_description"}
                    type={"text"}
                    handleChange={this.handleChange}
                    value={this.state.description}
                    required={false}
                />
                {this.props.organisation && this.props.organisation.logo && <div><img alt={"Current Logo"} className={"img img-thumbnail mb-1"} src={this.props.organisation.logo} style={{maxHeight: "50px"}}/>Current Logo</div>}
                <br/>
                {this.state.logo_path && <div><img alt={"New Logo"} className={"img img-thumbnail mb-1"} src={this.state.logo_path} style={{maxHeight: "50px"}}/>{this.props.organisation ? "New Logo" : ""}</div>}
                <Input
                    name={"logo"}
                    label_id={"app.organisation_logo"}
                    type={'file'}
                    handleChange={this.handleChange}
                    required={false}
                />
                <ButtonGroup className={"w-100"} size={"sm"}>
                    <Button onClick={this.onSubmit} disabled={!this.state.name} className={"btn-tertiary text-light form-control"}>{this.props.organisation ? "Save" : "Create"}</Button>
                    {this.props.organisation && <Button onClick={this.props.cancel} className={"btn-danger text-light form-control"}>Cancel</Button>}
                </ButtonGroup>
            </div>

        );
    }
}

OrganisationForm.propTypes = {
    uid: PropTypes.string.isRequired,
    firebase: PropTypes.object.isRequired,
    header: PropTypes.string.isRequired,
    organisation: PropTypes.object
};

export default OrganisationForm;