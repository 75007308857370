import {CompletedTable} from "../../pages/authenticatedPages/Reports";
import withArchive from "../ContainerMaster/withArchive";
import {ContainerMasterArchiveContext} from "../ContainerMaster/ContainerMaster"
import React from "react";
import {
  AdvancedDateFilter,
  AdvancedFilter,
  AdvancedFilterPanel
} from "../../elements/AdvancedFiltering";
import {MOVEMENT_STATUS_LABELS2, STATUS_LABELS} from "../ContainerMaster/withForecast";

class ArchiveReportTable extends CompletedTable {
  key = "completed"
  CMContext = ContainerMasterArchiveContext
  getAdvancedFilters({updateStatusLabels: onClick, status_filters, selectAll, selectNone, extra_date_filters,
                       updateMovementStatusLabels: onClickMovement, selectAllMovement,
                       selectNoneMovement, movement_status_filters,
                       notificationStates, changeNotificationFilters, currentNotificationState}) {
    return <AdvancedFilterPanel>
      <AdvancedFilter
          legend_text={'Movement Type'}
          onClick={onClick}
          selectAll={selectAll}
          deselectAll={selectNone}
          original_list={[...STATUS_LABELS]}
          current_list={status_filters}
      />

      <AdvancedFilter
          legend_text={'Movement Status'}
          onClick={onClickMovement}
          selectAll={selectAllMovement}
          deselectAll={selectNoneMovement}
          original_list={[...MOVEMENT_STATUS_LABELS2]}
          current_list={movement_status_filters}
      />
      {Object.values(extra_date_filters).map((edf,i)=><AdvancedDateFilter key={`${Object.keys(extra_date_filters || {})[i]}_adf`} {...edf} legend_text={edf.label}/>)}
    </AdvancedFilterPanel>
  }
}

const ArchiveReport = (props) => {
  return <ArchiveReportTable filter={props.filter.completed}
                             setFilter={(value, callback) => {

                                 props.setCompleted(value,  callback)

                             }}{...props}/>
}

export default withArchive(ArchiveReport)