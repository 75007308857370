import React from "react";
import DashboardContent from "../PageTemplate";
import customers from '../../../icons/customers.png';
import bays from '../../../icons/bays.png';
import collection_request from '../../../icons/collection_request.png';
import collections from '../../../icons/collections.png';
import products from '../../../icons/products.png';
import devan_request from '../../../icons/devan_request.png';
import users from '../../../icons/users.png';
import gate_pass from '../../../icons/gate_pass.png';
import forecast from '../../../icons/forecast.png';
import reports from '../../../icons/reports.png';
import outturns from '../../../icons/outturns.png';
import Image from "../../../elements/Image";

const MenuButton = ({color, updatePage, page, label, icon}) => {
    return <div
        className={`menu-button square interactive${color}`}
        onClick={updatePage}
        data-page={page}>
        {label}
        {icon && <Image src={icon} alt_id={"app.customers"} className={'menu-icon'}/>}
    </div>;
};

class Menu extends DashboardContent {

    render() {
        const {updatePage} = this.props;
        // noinspection PointlessBooleanExpressionJS
        return (
            <div className={'dashboard-menu row m-0'}>
                <section className={'bg-light p-1 col-6'}>
                    <h4>Stock In</h4>
                    <MenuButton label={"Devan Requests"} icon={devan_request} updatePage={updatePage} color={" grey medium"} page={"devan_requests"}/>
                    <MenuButton label={"Stock In"} icon={outturns} updatePage={updatePage} color={" medium"} page={"outturns"}/>
                </section>
                <section className={'bg-light p-1 col-6'}>
                    <h4>Stock Out</h4>
                    <MenuButton label={"Collection Requests"} icon={collection_request} updatePage={updatePage} color={" medium border-danger"} page={"collection_requests"}/>
                    <MenuButton label={"Stock Out"} icon={collections} updatePage={updatePage} color={" grey medium border-danger"} page={"collections"}/>

                </section>
                <section className={'bg-light p-1 col-12'}>
                <MenuButton label={"Issue Gate Pass"} icon={gate_pass} updatePage={updatePage} color={" border-danger"} page={"reports"}/>
                </section>
                <section className={'bg-light p-1 col-12'}>
                    <h4>Tracking and Forecasting</h4>
                    <MenuButton label={"Reporting"} icon={reports} updatePage={updatePage} color={" medium  border-danger"} page={"reporting"}/>
                    <MenuButton label={"Forecast"} icon={forecast} updatePage={updatePage} color={" medium grey border-danger"} page={"tracking"}/>

                </section>
                <section className={'bg-light p-1 col-12'}>
                    <h4>Admin</h4>

                    <MenuButton label={"Products"} icon={products} updatePage={updatePage} color={" medium border-secondary"} page={"products"}/>
                    <MenuButton label={"Bays"} icon={bays} updatePage={updatePage} color={" grey medium border-secondary"} page={"bays"}/>

                </section>
                <section className={'bg-light p-1 col-12'}>
                    <MenuButton label={"Customers"} icon={customers} updatePage={updatePage} color={" medium grey border-secondary"} page={"customers"}/>
                <MenuButton label={"Users"} icon={users} updatePage={updatePage} color={" medium grey border-secondary"} page={"users"}/>
                </section>
            </div>)
    }
}

export {MenuButton};

export default Menu;