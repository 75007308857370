import React from "react";
import * as PropTypes from 'prop-types';
import {Button, FormControl, FormGroup, FormLabel, ModalBody, ProgressBar} from "react-bootstrap";
import {DateSortableContent, RowCell, TableButtons} from "../../../elements/Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import db_refs from '../../../constants/db_refs'
import UnitInput from "../../../elements/UnitInput";
import EventTable, {labelMappings, StockLevelGraph} from "../../../elements/EventTable";
import moment from "moment";
import {AdvancedCustomerFilter, AdvancedFilterPanel} from "../../../elements/AdvancedFiltering";


class EditBayForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: props.editing
        };
        this.editField = this.editField.bind(this);
        this.editItem = this.editItem.bind(this);
    }

    editField(event) {
        const {editing} = this.state;
        editing[event.target.name] = event.target.value;
        this.setState({editing: editing});
    }

    editItem() {
        this.props.editItem(this.state.editing)
    }

    render() {
        const {setEditItem} = this.props;
        return <Modal show={true}>
            <ModalHeader>{`Editing bay ${this.state.editing.code}`}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <FormLabel column={true}>Bay Identifier</FormLabel>
                    <FormControl onChange={this.editField} value={this.state.editing.code} name={'code'}/>
                </FormGroup>
                <FormGroup>
                    <FormLabel column={true}>Capacity</FormLabel>
                    <UnitInput
                        unitName={'capacity_units'}
                        value={this.state.editing.capacity}
                        unit={this.state.editing.capacity_units}
                        handleChange={this.editField}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => setEditItem(null)} variant={'secondary'}>Cancel</Button>
                <Button variant={'success'} onClick={this.editItem}>Save</Button>
            </ModalFooter>
        </Modal>
    }
}

class BayDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: {
                data: {},
                graph_data: []
            },
            forecasting: [],
            startDate: props.startDate,
            endDate: props.endDate,
            loading: true,
            historyToDelete: null,
            historyDeleteConfirmation: "",
            historyDeleteConfirmation2: ""
        }
        this.onDelete = this.onDelete.bind(this)
    }

    componentDidMount() {
        this.setUpDataListener();
    }

    setUpDataListener() {
        const {bay, organisation_id, firebase} = this.props;
        firebase.db.ref(db_refs.organisations.forecasting.totals(organisation_id, 'bays', bay.id)).on('value',r=>{
                const result = r.val();
                if(!result)
                    this.setState({forecasting: {}});
                else {
                    result.available = result.current - (result.promised+result.stocking_out);
                    result.actual_stock = result.current - (result.promised);
                    this.setState({forecasting: result})
                }
            });
        firebase.db.ref(db_refs.organisations.forecasting.completed(organisation_id, 'bays', bay.id))
            .orderByChild('eventDate')
            .limitToLast(10)
            .on('value', data => {
                const eventData = data.val()
                const events = Object.keys(eventData || {}).map((ek)=>{
                    const values = eventData[ek]
                    values.eventKey = ek
                    return values
                })
                console.log(events)
                this.setState({events: events.reverse(), loading: false})
            });
        firebase.db.ref(db_refs.organisations.forecasting.pending(organisation_id, 'bays', bay.id))
            .on('value', data => {
                this.setState({pending: Object.values(data.val() || {}).reverse(), loading: false})
            })
    }

    onDelete(event) {

        this.setState({
            historyToDelete: event
        })
    }

    deleteHistoryEvent(event) {
        const {bay, organisation_id, firebase} = this.props;
        firebase.db.ref(db_refs.organisations.forecasting.completed(organisation_id, 'bays', bay.id)).child(event.eventKey).set(null).then(()=>{
            const key = firebase.db.ref("deleted_events").push().key
            firebase.db.ref("organisations/"+organisation_id+"/deleted_events").child(key).child(this.state.historyToDelete.eventKey).set(this.state.historyToDelete).then(()=>{
                this.setState({historyToDelete: null, historyDeleteConfirmation: "", historyDeleteConfirmation2:""})
            })

        })


    }

    componentWillUnmount() {
        const {bay, organisation_id, firebase} = this.props;
        firebase.db.ref(db_refs.organisations.forecasting.totals(organisation_id, 'bays', bay.id)).off();
        firebase.db.ref(db_refs.organisations.forecasting.completed(organisation_id, 'bays', bay.id)).off();
        firebase.db.ref(db_refs.organisations.forecasting.pending(organisation_id, 'bays', bay.id)).off()
    }


    render() {
        const {bay} = this.props;
        const events = Object.values(this.state.events || {});
        const forecast = Object.values(this.state.pending || {});
        const graphData = Object.keys(this.state.forecasting).sort().map(k=>({label: labelMappings[k], x: labelMappings[k], y: this.state.forecasting[k]}));
        const details = this.props.details || this.state.details;
        const history = this.state.history.data;

        return [
            <tr key={`${bay.code}_row`} className={'no-print'}>
                <td colSpan={7} style={{position: 'relative'}}>
                    <div onClick={() => this.setState({details: !this.state.details})}
                         style={{position: 'absolute', right: '-15px', top: '-40px'}}>
                        {details && !this.props.details && <FontAwesomeIcon size={'2x'} icon={'caret-down'}/>}
                        {this.props.details && <FontAwesomeIcon title={'Details are locked open'} icon={'ban'}/>}
                        {!details && <FontAwesomeIcon size={'2x'} icon={'caret-up'}/>}
                    </div>
                </td>
            </tr>,
            <tr key={`${bay.code}_details`}>
                {
                    details &&
                    <td colSpan={12} style={{display: 'table-cell'}}>
                        <div className={'col-12 details row small'}>
                            <div className={'col-6'}>
                                {bay.id}
                                {!history && <p>No history yet!</p>}
                                <EventTable with_product={true} none_found_message={'No bay history'} title={'Bay History'} data={events} showUser={true} target_id={bay.id} on_delete={this.onDelete}/>
                                <EventTable with_product={true} none_found_message={'No planned activity'} title={'Bay Forecast'} data={forecast} showUser={false} target_id={bay.id}/>

                            </div>
                            <div className={'col-6'}>
                                 { graphData.length > 0 &&  <StockLevelGraph graphData={graphData}/> }
                            </div>
                        </div>
                        {!history && <p>No history yet!</p>}
                        <hr className={'bg-tertiary w-100 no-print'}/>
                    </td>}
                {this.state.historyToDelete && <Modal show={this.state.historyToDelete } size={'lg'} onHide={()=>this.setState({historyToDelete: null, historyDeleteConfirmation: "", historyDeleteConfirmation2: ""})}>
                    <ModalHeader className={'bg-primary text-light'}><h3 className={'bg-primary text-light'}>
                        Delete this event
                    </h3></ModalHeader>

                    <ModalBody>

                        <p className={'text-danger font-weight-bold'}>
                            Deleting this row is permanent and cannot be reversed!
                        </p>

                        <EventTable title={"Event to be deleted"} data={[this.state.historyToDelete]} showUser={true}/>

                        <p>Type the source and destination of the movement exactly as written to allow deletion</p>

                        <span>Source</span>
                        <FormControl value={this.state.historyDeleteConfirmation} onChange={(event) => {
                            this.setState({historyDeleteConfirmation: event.target.value ? event.target.value : ""})
                        }}/>
                        <span>Destination</span>
                        <FormControl value={this.state.historyDeleteConfirmation2} onChange={(event) => {
                            this.setState({historyDeleteConfirmation2: event.target.value ? event.target.value : ""})
                        }}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={()=>this.deleteHistoryEvent(this.state.historyToDelete)} disabled={
                            this.state.historyDeleteConfirmation!==this.state.historyToDelete.source ||
                            this.state.historyDeleteConfirmation2!==this.state.historyToDelete.destination
                        } variant={"danger"}>Delete</Button>
                        <Button variant={'secondary'} onClick={()=>this.setState({historyToDelete: null, historyDeleteConfirmation: "", historyDeleteConfirmation2: "" })}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>}

            </tr>]
    }
}

class BayTable extends DateSortableContent {
    constructor(props) {
        super(props);
        this.has_export = 'Export Stock Movements';
        this.has_export2 = "Export Bay Status";
        this.state = {
            ...this.initialState,
            key: 'bays',
            sort: 'code',
            sorted_data: [],
            print_processing: false,
            details: false,
            index: 0,
            page: 0,
            num_per_page: 12
        };
    }

    getHeaders() {
        return [{
            label: 'Bay',
            key: 'code',
            size: 1,
            content: (bay) => {
                return this.state.importing ?
                    <span><FontAwesomeIcon icon={'spinner'} spin={true}/>{bay.code}</span> : `${bay.code}`
            }
        },{
            label: 'Debug',
            key: 'code',
            size: 1,
            content: (bay) => {
                return <Button onClick={
                    ()=>{
                        const {firebase, organisation_id} = this.props;
                        firebase.functions.httpsCallable('forecasting-checkBay')({
                            organisation_id,
                            bay_id: bay.id
                        }).then(()=>{

                        })
                    }
                }
                               title={`Last processed on: ${new Date(parseInt(bay.last_processed)).toLocaleString()}`}
                >Check</Button>
            }
        }, {
            label: 'Customer',
            key: 'customer',
            size: 2,
            content: (bay) => {
                return bay.customer ? this.props.customers[bay.customer].name : '-'
            }
        }, {
            label: 'Product',
            key: 'product',
            size: 2,
            content: (bay) => {
                return bay.product
            }
        }, {
            label: 'Stock',
            key: 'current',
            size: 3,
            content: (bay) => {
                return [
                    <span key={`bay_total_${bay.id}`}
                          className={this.state.print_processing ? '' : 'small float-right'}>
                        {
                            bay.product ?
                                `${bay.current ? `${bay.current} (${(bay.pending || 0) + (bay.stocking_in || 0)} inbound, ${(bay.promised || 0) + (bay.stocking_out || 0)} outbound)` : 
                                    `0 (${(bay.pending || 0) + (bay.stocking_in || 0)} inbound)`}` : isNaN(bay.current) || bay.current === 0 ? 'Empty' : bay.current
                        }
                    </span>,
                    <ProgressBar key={`bay_total_progress_${bay.id}`} className={'no-print bg-secondary large w-75'}
                                 now={bay.product ? Math.floor((bay.current / parseInt(bay.capacity)) * 100) : 0}/>
                ]
            }
        }, {
            label: 'Last Used',
            key: 'last_active',
            size: 2,
            noprint: false,
            content: (product) => {
                return product.last_active ? moment(new Date(parseInt(product.last_active))).fromNow() : 'Never';
            }
        }, {
            label: '',
            key: false,
            size: 1,
            noprint: true,
            content: (bay) => {
                return <TableButtons disableItem={ !bay.disabled && (isNaN(bay.current) || bay.current === 0) ? () => this.disableBay(bay.id) : false}
                                     enableItem={ bay.disabled  ? () => this.enableBay(bay.id) : false} product={bay} setDeleteItem={this.setDeleteItem} allow_delete={!bay.last_active}
                                     setEditItem={this.setEditItem} setMovementItem={this.props.setMovementItem}/>
            }
        }]
    }



    getAdvancedFilters() {
        const {customer_filter} = this.props;
        return <AdvancedFilterPanel>
            <AdvancedCustomerFilter selectAll={customer_filter.selectAll} deselectAll={customer_filter.deselectAll} onClick={customer_filter.updateCustomerFilter} original_list={customer_filter.filter_options} current_list={customer_filter.filter}/>
        </AdvancedFilterPanel>
    }

    getDeletionModal() {
        if (this.state.deleting) {
            return <Modal onHide={()=>{}} show={true}>
                <ModalHeader>{`Deleting bay ${this.state.deleting.code}`}</ModalHeader>
                <ModalFooter>
                    <Button onClick={() => this.setDeleteItem(null)} variant={'secondary'}>Cancel</Button>
                    <Button variant={'danger'} onClick={this.deleteItem}>Delete</Button>
                </ModalFooter>
            </Modal>
        }
    }

    getEditingModal() {
        if (this.state.editing) {
            return <EditBayForm editing={this.state.editing} editItem={this.editItem} setEditItem={this.setEditItem}/>
        }
    }

    deleteItem() {
        this.props.firebase.db.ref(db_refs.organisations.bays(this.props.organisation_id)).child(this.state.deleting.id).set(null).then(() => this.setState({deleting: null}));
    }
    disableBay(bay_id) {
        this.props.firebase.db.ref(db_refs.organisations.bays(this.props.organisation_id)).child(bay_id).child('disabled').set(true);
    }
    enableBay(bay_id) {
        this.props.firebase.db.ref(db_refs.organisations.bays(this.props.organisation_id)).child(bay_id).child('disabled').set(null);
    }

    editItem(editing) {
        this.props.firebase.db.ref(db_refs.organisations.bays(this.props.organisation_id)).child(editing.id).update(editing).then(() => this.setState({editing: null}));
    }

    getReportTitle() {
        return 'Bay Report'
    }

    async getData() {
        let data = [];
        const {startDate, endDate} = this.state;
        const {customers, firebase, organisation_id, customer_filter, all_bays} = this.props;

        this.setState({data: null});

        const promises = all_bays.map(bay => firebase.db.ref(db_refs.organisations.forecasting.completed(organisation_id, 'bays', bay.id)).orderByChild('eventDate').startAt(parseInt(startDate)).endAt(parseInt(endDate)).once('value'));
        const results = await Promise.all(promises);

            results.map((res,i) => {
                if (!res.val()) return true;
                const bay_data = res.val();
                Object.keys(bay_data).map(d => {
                    const info = bay_data[d];
                    data.push({
                        code: all_bays[i].code,
                        date: new Date(parseInt(info.eventDate)).toLocaleString(),
                        customer: info.customer ? customers[info.customer].name : '-',
                        product: info.product_code,
                        source: info.source,
                        destination: info.destination,
                        quantity: info.change,
                        event: info.event
                    });
                    return true
                });
                return true;

            });
            data = data.filter(b=>{
                console.log(data);
                if(b.customer === '-') {
                    return customer_filter.filter.indexOf('Empty') > -1;
                }
                return customer_filter.filter.indexOf(b.customer) > -1;
            });
            data = data.filter((bay) => {
                const {filter} = this.props || '';
                const bay_customer_matches = (bay.customer.toLowerCase().indexOf(filter.toLowerCase()) > -1);
                const bay_code_matches = bay.code.toLowerCase().indexOf(filter.toLowerCase()) > -1;
                const bay_product_matches =  (bay.product && bay.product.toLowerCase().indexOf(filter.toLowerCase()) > -1);
                const bay_container_matches =  (bay.source && bay.source.toLowerCase().indexOf(filter.toLowerCase()) > -1);
                const bay_collection_matches =  (bay.destination && bay.destination.toLowerCase().indexOf(filter.toLowerCase()) > -1);
                return bay_code_matches || bay_customer_matches || bay_product_matches || bay_container_matches || bay_collection_matches;
            });
            this.setState({data: data}, () => {
            });
            return data

    }

    getContent() {
        const {sorted_data, startDate, endDate, page, num_per_page, details, print_processing} = this.state;
        const {customers, firebase, organisation_id} = this.props;
        const start = page * num_per_page;
        const end = (page * num_per_page) + num_per_page;
        const paginated_data = sorted_data.slice(start, end);
        return (paginated_data.map((bay) => {
            const result = [
                <tr key={`${bay.code}_data`} className={'col-12 w-100 m-1 product item-row mb-1 print-block no-print'}
                    style={{boxShadow: '-1px 1px 1px lightgray'}}>
                    {this.getHeaders().map((h, i) => <RowCell key={`table_row_${i}_${bay.id}_${h.key}`} col={3}
                                                              title={h.label} alignment={'center'}
                                                              header={!print_processing && i === 0}
                                                              noprint={h.noprint}>{h.content(bay)}</RowCell>)}
                </tr>

            ];

            if (!this.state.importing) {
                result.push(<BayDetails
                    key={`${bay.id}_details`}
                    customers={customers}
                    firebase={firebase}
                    organisation_id={organisation_id}
                    bay={bay}
                    customer={bay.customer ? customers[bay.customer].name : '-'}
                    startDate={startDate}
                    endDate={endDate}
                    details={details}
                    printing={print_processing}
                />)
            }
            return result;

        }))
    }
}

BayTable.propTypes = {
    organisation_id: PropTypes.string.isRequired,
    bays: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    firebase: PropTypes.object.isRequired,
    customers: PropTypes.object.isRequired
};
export default BayTable;