import React from 'react';

import {withFirebase} from '../Firebase';
import {DATABASE_REFS} from '../../constants/db_refs'

import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {ContainerMasterDataContext} from "./ContainerMaster";

import moment from "moment";


const GatePassHeaders =  [{
    label: 'Pass Number',
    key: 'pass_number',
    size: 2,
    content: (product) => {
        return  product.pass_number
    }
},{
    label: 'Issued on',
    key: 'issued_on',
    size: 2,
    content: (product) => {
        return product.issued_on ? new Date(product.issued_on).toLocaleString() : '-'
    }
}, {
    label: 'Revoked On',
    key: 'revoked_on',
    size: 2,
    content: (product) => {
        return product.revoked_on ? new Date(product.revoked_on).toLocaleString() : '-'
    }
}, {
    label: 'Collections',
    key: 'collections',
    size: 2,
    content: (product) => {
        return <div style={{maxWidth: "200px", display: "grid"}}>{Object.values(product.collections||{}).map(c => <span key={`collection_cell_${c.id}`} className={'bg-primary m-1 p-1 text-light rounded'}>{c.collection_ref}</span>)}</div>
    }
},{
    label: 'Picking Lists',
    key: 'picking_lists',
    size: 2,
    content: (product) => {
        return Object.values(product.picking_lists||{}).map(c => <span key={`pl_cell_${c.id}`} className={'bg-primary m-1 p-1 text-light rounded'}>{c.picking_list_number}</span>)
    }
}];

const withData = Component => {
    class WithData extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
                bay_filters: {
                    customer_filter: {
                        filter: ['all'],
                        filter_options: ['all'],
                        updateCustomerFilter: (filter) => {
                            const {bay_filters} = this.state;
                            if (bay_filters.customer_filter.filter.indexOf(filter) > -1) {
                                bay_filters.customer_filter.filter = bay_filters.customer_filter.filter.filter(s => s !== filter)
                            } else {
                                bay_filters.customer_filter.filter.push(filter);
                            }
                            this.setState({bay_filters: bay_filters})
                        },
                        selectAll: () => {
                            const {bay_filters} = this.state;

                            bay_filters.customer_filter.filter = bay_filters.customer_filter.filter_options;

                            this.setState({bay_filters: bay_filters})
                        },
                        deselectAll: () => {
                            const {bay_filters} = this.state;

                            bay_filters.customer_filter.filter = [];

                            this.setState({bay_filters: bay_filters})
                        }
                    },
                },
                product_filters: {
                    customer_filter: {
                        filter: ['all'],
                        filter_options: ['all'],
                        updateCustomerFilter: (filter) => {
                            const {product_filters} = this.state;
                            if (product_filters.customer_filter.filter.indexOf(filter) > -1) {
                                product_filters.customer_filter.filter = product_filters.customer_filter.filter.filter(s => s !== filter)
                            } else {
                                product_filters.customer_filter.filter.push(filter);
                            }
                            this.setState({product_filters: product_filters})
                        },
                        selectAll: () => {
                            const {product_filters} = this.state;

                            product_filters.customer_filter.filter = product_filters.customer_filter.filter_options;

                            this.setState({product_filters: product_filters})
                        },
                        deselectAll: () => {
                            const {product_filters} = this.state;

                            product_filters.customer_filter.filter = [];

                            this.setState({product_filters: product_filters})
                        }
                    },
                },
                gate_passes: {
                    start:  moment().subtract(1,'week').toDate().getTime(),
                    end:  moment().add(1,'week').toDate().getTime(),
                    filter: '',
                    status_filters: [
                        'Pending Review',
                        'Awaiting collection',
                        'Collection in progress',
                        'Verification Underway'
                    ],
                    headers: GatePassHeaders,
                    sort: 'pass_number',
                    reverse: 1,
                    setDate: (start, end) => {
                        const {gate_passes} = this.state;
                        gate_passes.start = start;
                        gate_passes.end = end;
                        this.setState(
                            {
                                gate_passes
                            }, () => {
                                this.unmountDatedSnapshot();
                                this.fetchDatedSnapshot();
                            }
                        )
                    }
                }
            };
            this.updateData = this.updateData.bind(this)
        }
        unmountDatedSnapshot() {
            const {firebase, organisation_id} = this.props;
            firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).off();

        }
        fetchDatedSnapshot() {
            const {firebase, organisation_id} = this.props;
            const {start, end} = this.state.gate_passes;
            firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id))
                .orderByChild('created_on').startAt(start).endAt(end)
                .on('value', (data) => { this.updateData(data, this.processGatePasses.bind(this)).then(()=>console.debug('Gate passes updated'))});

        }

        fetchInitialData() {
            const {firebase, organisation_id} = this.props;
            if(!organisation_id)
                this.setState({uid: null, loading: false});
            else {
                console.log("ufhdihfd")
                try {
                    firebase.db.ref(`notifications/${firebase.auth.currentUser.uid}/errors`).on("child_added", (res) => {
                        console.log(res.val())
                    })
                } catch (e) {
                    console.error(e)
                }
                const promises = [

                ];
                Promise.all(promises).then(results => {
                    const newState = {loading: false};
                    results.map(r=>newState[r.key] = r.val());

                    firebase.db.ref(DATABASE_REFS.organisations.customers(organisation_id)).on('value', this.updateData);
                    firebase.db.ref(DATABASE_REFS.organisations.bays(organisation_id)).on('value', this.updateData);
                    firebase.db.ref(DATABASE_REFS.organisations.products(organisation_id)).on('value', this.updateData);
                    firebase.db.ref(DATABASE_REFS.organisations.bulk.picking(organisation_id)).on('value', this.updateData);
                    this.setState(newState)
                })

            }
        }

        async processGatePasses(passes, bulk) {
            if(Object.keys(passes||{}).length < 1) {
                return passes;
            }
            let processed_bulk = [];
            let processed_bulk2 = [];
            Object.values(passes || {}).map((pass)=>{
                pass.can_issue = true;
                pass.collection_string = "";
                const collection_codes = [];

                Object.keys(pass.collections || {}).map(c=>{
                    collection_codes.push(pass.collections[c].collection_ref);
                        });
                pass.collection_string = collection_codes.join(",");

                if(!bulk) {
                    const picking_lists = pass.picking_lists || {};
                    processed_bulk.push(this.processGatePasses(picking_lists, true).then(res=>pass.picking_lists = res));
                } else {
                      Object.keys(pass.collections || {}).map(c=>{
                       processed_bulk2.push(this.props.firebase.db.ref(DATABASE_REFS.organisations.summaries.collection(this.props.organisation_id)).child(c).child("crates_val").once("value").then(res=>{
                           pass.collections[c].crates_val = res.val();
                       }));
                    });
                }
            return true;

            });

            await Promise.all(processed_bulk);
            await Promise.all(processed_bulk2);
            return passes;
        }

        async updateData(data_snap, processor) {
            const newState = {};
            if(data_snap.key === 'picking') {
                newState['bulk_picking_lists'] = data_snap.val() || {};
                Object.keys(newState["bulk_picking_lists"]).map(item => {
                    newState["bulk_picking_lists"][item].id = item;
                    return true;
                });
                newState["bulk_picking_lists"] = await this.processGatePasses(newState["bulk_picking_lists"], true);
                this.setState(newState);
                return;
            }
            newState[data_snap.key] = data_snap.val() || {};
            Object.keys(newState[data_snap.key]).map(item => {
                newState[data_snap.key][item].id = item;
                return true;
            });
            if(processor) {
                newState[data_snap.key] = await processor(newState[data_snap.key])
            }
            if(data_snap.key === 'gate_passes') {
                newState.gate_passes_loaded = true
            }
            if(data_snap.key === 'devan_gate_passes') {
                newState.devan_gate_passes_loaded = true
            }
            if(data_snap.key === 'bays' || data_snap.key === 'products') {
                // Get bay totals
                const {firebase, organisation_id} = this.props;
                const bay_totals  = Object.keys(newState[data_snap.key]).map(k=>{
                    return firebase.db.ref(DATABASE_REFS.organisations.forecasting.totals(organisation_id, data_snap.key, k)).once('value').then(totals=>{
                        const {current, pending, promised, stocking_in, stocking_out} = totals.val() || {};
                        if (!newState[data_snap.key][k].code) {
                            newState[data_snap.key][k].code = 'deleted'
                        }
                        newState[data_snap.key][k].current = current || 0;
                        newState[data_snap.key][k].pending = pending || 0;
                        newState[data_snap.key][k].promised = promised || 0;
                        newState[data_snap.key][k].stocking_in = stocking_in || 0;
                        newState[data_snap.key][k].stocking_out = stocking_out || 0;
                    })
                });
                await Promise.all(bay_totals);
                const bay_last_event  = Object.keys(newState[data_snap.key]).map(k=>{
                    return firebase.db.ref(DATABASE_REFS.organisations.forecasting.completed(organisation_id, data_snap.key, k)).orderByChild('eventDate').limitToLast(1).once('value').then(totals=>{
                        try {
                            const {eventDate, event, source, destination} = Object.values(totals.val() || {}).pop();
                            let referenceKey = source;
                            if (event === 'collection' || event === 'movement_out') {
                                referenceKey = destination;
                            }
                            newState[data_snap.key][k].last_active = eventDate
                            newState[data_snap.key][k].last_reference = referenceKey
                        } catch (e) {
                            newState[data_snap.key][k].last_active = null
                        }


                    })
                });
                await Promise.all(bay_last_event)
            }
            if(data_snap.key === 'bays' || data_snap.key === 'products') {
                // Get bay totals
                const {firebase, organisation_id} = this.props;
                const bay_totals  = Object.keys(newState[data_snap.key]).map(k=>{
                    return firebase.db.ref(DATABASE_REFS.organisations.forecasting.pending(organisation_id, data_snap.key, k)).once('value').then(pending_reqs=>{
                        newState[data_snap.key][k].outstanding = pending_reqs.val() || {}
                    })
                });
                await Promise.all(bay_totals)
            }
            if(data_snap.key === 'customers') {
                const  {bay_filters, product_filters} = this.state;
                const customer_names = Object.values(data_snap.val() || {}).map(customer=>customer.name);
                bay_filters.customer_filter.filter = [ 'Empty', ...customer_names];
                bay_filters.customer_filter.filter_options = [ 'Empty', ...customer_names];
                product_filters.customer_filter.filter = [ ...customer_names];
                product_filters.customer_filter.filter_options = [...customer_names];

                this.setState({bay_filters: bay_filters, product_filters: product_filters})

            }
            newState[`${data_snap.key}_loaded`] = true;
            if(this._isMounted)
                this.setState(newState);
        }


        componentDidMount() {
            this._isMounted = true;
            this.fetchInitialData();
            this.fetchDatedSnapshot();
        }

        componentWillUnmount() {
            this._isMounted = false;
            const {firebase, organisation_id} = this.props;
            if (!organisation_id) return;
            firebase.db.ref(DATABASE_REFS.organisations.customers(organisation_id)).off('value');
            firebase.db.ref(DATABASE_REFS.organisations.bays(organisation_id)).off('value');
            firebase.db.ref(DATABASE_REFS.organisations.products(organisation_id)).off('value');
            firebase.db.ref(DATABASE_REFS.organisations.gate_passes(organisation_id)).off('value');
            firebase.db.ref(DATABASE_REFS.organisations.devan_gate_passes(organisation_id)).off('value');
            firebase.db.ref(DATABASE_REFS.organisations.bulk.picking(organisation_id)).off('value');
        }

        render() {
            if (this.state.loading) {
                return <LoadingScreen/>
            }
            return (
                <ContainerMasterDataContext.Provider value={this.state}>
                   <Component {...this.props} />
                </ContainerMasterDataContext.Provider>
            );
        }
    }
    return withFirebase(WithData);
};
export default withData;