import React from 'react';
import loading_icon from './1.gif';
import Image from "../../elements/Image";

class LoadingScreen extends React.Component {
    
    render() {
        return (
            <div className={'row'}> 
                <div className={'col-12 col-lg-4 offset-lg-4 bg-light'}>
                    <Image className={'img-fluid img loading-image'} alt_id={'app.loading'} src={loading_icon} />
                </div>
            </div>

        );
    }
}

export default LoadingScreen;